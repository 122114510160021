import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Label, Collapse,Row,Col } from 'reactstrap';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
Chart.register(ChartDataLabels);

const ActionDue = ({ ActionDueList, elementRef }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const labels = ActionDueList.labelsList;
    const data = ActionDueList.costList;

    const dataBar = {
        labels: labels,
        datasets: [
            {
                label: "#Actions",
                backgroundColor: "#B6D137",
                borderColor: "#B6D137",
                borderWidth: 1,
                hoverBackgroundColor: "#B6D137",
                hoverBorderColor: "#B6D137",
                data: data,
            }
        ]
    };

    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: "#008436",
                formatter: function (value) {
                    return value == 0 ? '' : value
                },
                anchor: "end",
                offset: -20,
                align: "start"
            },
            legend: {
                display: true
            },
            
        },
        scales: {
            // to remove the labels
            x: {
                ticks: {
                    display: true,
                    color: "#008436",
                    font: {
                        size: 14,
                        weight: "bold" 
                    }
                },

                // to remove the x-axis grid
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: false,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: false,
                    display: true,
                },
            },
        },
    }

    return (
        <Card className="my-4 ">
            <CardHeader className="item-header item_click" onClick={toggle}>
                <Row>
                    <Col sm="11"><h5>Action Due - next 5 year
                    </h5></Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <div ref={elementRef}>
                        <Bar data={dataBar} options={options} width={100} height={50} />
                    </div>
                    <br />
                    <Label className="form-label-default">
                        Total Estimated Improvement Actions Cost ${ActionDueList.totalEstimatedCost}
                    </Label>
                </CardBody>
            </Collapse>
            
        </Card>
    )
}
export default ActionDue