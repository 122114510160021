import { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Collapse, Button } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaChevronRight } from 'react-icons/fa';

const MapImport = ({ importShapeFilesClick }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    return (
        <Card>
            <CardHeader className="item-header item_click" onClick={toggle}>
                <Row>
                    <Col sm="11">
                        <h5 className="color-dark-teal">Map Import</h5>
                    </Col>
                    <Col sm="1" className="toggleoutline">
                            {isIconUp ? <FaChevronUp /> : <FaChevronDown />}
                    </Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                <Row>
                                    <Col sm="8">
                                        <label className="kmlLabel para-light-sm"> If you have an existing maps of the farm it is easy to import.</label> <br /><br />
                                        <label className="kmlLabel para-light-sm"> Any number of layers can be imported including</label><br />
                                        <label className="kmlAdvanceLabel">  - Boundary/Outline</label><br />
                                        <label className="kmlAdvanceLabel">  - Land Units</label><br />
                                        <label className="kmlAdvanceLabel">  - Infrastructure</label><br />
                                        <label className="kmlAdvanceLabel">  - Waterways</label>
                                    </Col>
                                    <Col sm="4">
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                <Button className="btn-next-page float-end" onClick={importShapeFilesClick}> Import Files <FaChevronRight /> </Button>
                                            </>
                                        }
                                        <label className="kmlAdvanceLabel float-end">  Maps must be KML format to upload.<br /> Other files types can be easily converted.<br /> Please contact support if you need help.</label>
                                    </Col>
                                </Row>
                                <label className="kmlLabel para-light-sm">  Once imported the shapes and items are independent from the source and can be managed from the FWFP system.</label>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}
export default MapImport