import React, { } from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import uuid from 'react-uuid';

const LargeFarmView = ({ largeEnterpriseDetails, handleLMUSelectChange, Section }) => {
    var LMUList = Section == 'Irrigation Frost Protection' ? largeEnterpriseDetails.filteredLMUList : largeEnterpriseDetails.LMUList; 
    if (largeEnterpriseDetails != undefined) {
        if (largeEnterpriseDetails.isLargeEnterprise) {
            return (
                <>
                    <Row>
                        <Col sm="8">
                            <Label>This Property is "Large" - Select separate Land Units to add items if required</Label>
                        </Col>
                        <Col sm="4">
                            <FormGroup>
                                {LMUList != undefined && LMUList != null &&
                                    <Input type="select" className="float-end" onChange={handleLMUSelectChange} name="selectedLMUId" id="selectedLMUId" value={largeEnterpriseDetails.selectedLMUId}>
                                        <option value={0} >Whole Property</option>
                                        {LMUList.filter(lmu => !lmu.isConnectedToAnotherLMU).map(Option =>
                                            <option key={uuid()} value={Option.id}>{Option.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                </>
            )
        }
    }
}
export default LargeFarmView