import React from 'react';
import { Outlet } from "react-router-dom";

const OuterLayout = () => {

    return (
        <main>
            <Outlet />
        </main>
    );
}

export default OuterLayout
