import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Table, Button, FormGroup, Label, Input, Form } from 'reactstrap';
import axios from 'axios';
import uuid from 'react-uuid';
import Select from 'react-select';
import "../ExternalOrganisation/ExternalOrganisation.css";
import Validate from '../Validate/Validate';
import toast, { Toaster } from 'react-hot-toast';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const CreateUser = () => {
    const { state } = useLocation(); const navigate = useNavigate();
    const userRef = useRef();const [loading, setLoading] = useState(true); const [id, setId] = useState(''); 
    const [firstName, setFirstName] = useState(''); const [surName, setSurName] = useState(''); const [userName, setUserName] = useState(''); const [password, setPassword] = useState('');
    const [email, setEmail] = useState(''); const [mobile, setMobile] = useState(''); const [role, setRole] = useState(0); const [regionalCouncil, setRegionalCouncil] = useState(0);
    const [externalOrganisation, setExternalOrganisation] = useState(0); const [userList, setUserList] = useState([]); const [showExternalOrganisation, setShowExternalOrganisation] = useState(false);
    const [isCertifier, setIsCertifier] = useState(false); const [isAuditor, setIsAuditor] = useState(false); const [isAddArchiveItem, setIsAddArchiveItem] = useState(false); const [isAddEditItem, setIsAddEditItem] = useState(false);
    const [isCreateNewAction, setIsCreateNewAction] = useState(false); const [isSubmitEvidenceCloseAction, setIsSubmitEvidenceCloseAction] = useState(false); const [isAddDeleteDocument, setIsAddDeleteDocument] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [regionalAuthorityList, setRegionalAuthorityList] = useState([]); const [regionalAuthorityListSelectedValues, setRegionalAuthorityListSelectedValues] = useState([]);
    const [regionalAuthoritySelectedValues, setRegionalAuthoritySelectedValues] = useState('');
    const [saveRightsForNonItemFieldsAndTables, setSaveRightsForNonItemFieldsAndTables] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams(); const [roleValue, setRoleValue] = useState(0); const [nameValue, setNameValue] = useState('');
    const [showArchivedValue, setShowArchivedValue] = useState(''); const [idValue, setIdValue] = useState(0);
    useEffect(() => {
        userRef.current.focus();
    })

    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            setIdValue(params.get('id'));
            setRoleValue(params.get('role'));
            setNameValue(params.get('name'));
            setShowArchivedValue(params.get('showArchived'));
            if (searchParams.has('id')) {
                searchParams.delete('id');
                setSearchParams(searchParams);
            }
            if (searchParams.has('role')) {
                searchParams.delete('role');
                setSearchParams(searchParams);
            }
            if (searchParams.has('name')) {
                searchParams.delete('name');
                setSearchParams(searchParams);
            }
            if (searchParams.has('showArchived')) {
                searchParams.delete('showArchived');
                setSearchParams(searchParams);
            }
            const response = await axios.get(
                '/api/settings/getusers/', {
                params: {}
            }
            );
            setUserList(response.data.content);
            setRegionalAuthorityList(response.data.content.regionalCouncils);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let form = {
            Id: id,
            FirstName: firstName,
            Surname: surName,
            Username: userName,
            Email: email,
            Password: password,
            MobilePhone: mobile,
            RegionalAuthority: regionalAuthoritySelectedValues.toString(),
            Role: role,
            Organisation: externalOrganisation,
            IsCertifier: isCertifier,
            IsAuditor: isAuditor,
            IsAddArchiveItem: isAddArchiveItem,
            IsAddEditItem: isAddEditItem,
            IsCreateNewAction: isCreateNewAction,
            IsSubmitEvidenceCloseAction: isSubmitEvidenceCloseAction,
            IsAddDeleteDocument: isAddDeleteDocument,
            UserId: userId,
            SaveRightsForNonItemFieldsAndTables: saveRightsForNonItemFieldsAndTables
        }
        if (!Validate(form)) {
            const response = await axios.post(
                '/api/settings/SaveUsers/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("User Created!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.open("/user", "_self");
                    }, 1500);
                }
                else if (response.data.message == 'User already exists in the system') {
                    toast.error("User already exists in the system!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                }
            }
        }
    }

    const backToUserList = async () => {
        window.location = '/user?role=' + roleValue + '&name=' + nameValue + '&showArchived=' + showArchivedValue;
    }

    const handleChangeRole = async (event) => {
        setRole(parseInt(event.target.value));
        if (event.target.selectedOptions[0].text == 'Organization User') {
            setShowExternalOrganisation(true);
        }
        else {
            setShowExternalOrganisation(false);
        }
    }

    const handleRegionalCouncilChange = (e) => {
        setRegionalAuthoritySelectedValues('');
        var regionalAuthoritySelectedValues = '';
        setRegionalAuthorityListSelectedValues(e);
        e.forEach(function (element) {
            if (regionalAuthoritySelectedValues == '') {
                regionalAuthoritySelectedValues = element.value;
            }
            else {
                regionalAuthoritySelectedValues = regionalAuthoritySelectedValues + ',' + element.value;
            }
        });
        setRegionalAuthoritySelectedValues(regionalAuthoritySelectedValues);
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Add User
                </h2>
                <CardBody>
                    <div >
                        <Form onSubmit={handleSubmit}>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Firstname</Label>
                                        <Input className="validate" type="text" ref={userRef} value={firstName} onChange={(e) => setFirstName(e.target.value)} name="FirstName" id="FirstName" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Surname</Label>
                                        <Input type="text" value={surName} onChange={(e) => setSurName(e.target.value)} name="surName" id="surName" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Username</Label>
                                        <Input type="text" className="validate" value={userName} onChange={(e) => setUserName(e.target.value)} name="Username" id="Username" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Password</Label>
                                        <Input type="password" className="validate" value={password} onChange={(e) => setPassword(e.target.value)} name="Password" id="Password" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Email</Label>
                                        <Input type="text" className="validate" value={email} onChange={(e) => setEmail(e.target.value)} name="Email" id="Email" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Mobile</Label>
                                        <Input type="tel" value={mobile} onChange={(e) => setMobile(e.target.value.replace(/\D/, ''))} name="mobile" id="mobile" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Role</Label>
                                        {userList.roles == undefined && <p><em>Loading...</em></p>}
                                        {userList.roles != undefined && userList.roles != null &&
                                            <Input className="validate" type="select" value={role} onChange={e => handleChangeRole(e)} name="Role" id="Role">
                                                {userList.roles.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                                {(isCertifier || isAuditor) && <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Certfier / Auditor - authorised for which Regional Authority(s)</Label>
                                        <Select className="dropdown" placeholder="Select Option" value={regionalAuthorityListSelectedValues} options={regionalAuthorityList} onChange={handleRegionalCouncilChange} isMulti isClearable />
                                    </FormGroup>
                                </Col>}
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        {
                                            showExternalOrganisation && <div>
                                                <Label className="label" for="name">Organisation Association</Label>
                                                {userList.externalOrganisations == undefined && <p><em>Loading...</em></p>}
                                                {userList.externalOrganisations != undefined && userList.externalOrganisations != null &&
                                                    <Input type="select" value={externalOrganisation} onChange={(e) => setExternalOrganisation(e.target.value)} name="externalOrganisation" id="externalOrganisation">
                                                        {userList.externalOrganisations.map(items =>
                                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                                        )}
                                                    </Input>
                                                }
                                            </div>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: `15px` }} xs="2">
                                <Col>
                                    <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>User Rights</u></b></Label>
                                </Col>
                            </Row>
                            <Row>
                                <Table striped bordered hover style={{width:"50%"}}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={{ display:"none" }}>Role Add-Ons</th>
                                            <th colSpan="3">Maps & Pages</th>
                                            <th colSpan="2">Actions</th>
                                            <th colSpan="2">Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ display: "none" }}>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isCertifier} onChange={(e) => setIsCertifier(!isCertifier)} name="isCertifier" id="isCertifier" />
                                                    <br />
                                                    <Label className="label" for="name">Certifier</Label>
                                                </FormGroup>
                                            </td>
                                            <td style={{ display: "none" }}>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isAuditor} onChange={(e) => setIsAuditor(!isAuditor)} name="isAuditor" id="isAuditor" />
                                                    <br />
                                                    <Label className="label" for="name">Auditor</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isAddArchiveItem} onChange={(e) => setIsAddArchiveItem(!isAddArchiveItem)} name="isAddArchiveItem" id="isAddArchiveItem" />
                                                    <br />
                                                    <Label className="label" for="name">Add / Archive Item</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isAddEditItem} onChange={(e) => setIsAddEditItem(!isAddEditItem)} name="isAddEditItem" id="isAddEditItem" />
                                                    <br />
                                                    <Label className="label" for="name">Add / Edit Record</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={saveRightsForNonItemFieldsAndTables} onChange={(e) => setSaveRightsForNonItemFieldsAndTables(!saveRightsForNonItemFieldsAndTables)} name="saveRightsForNonItemFieldsAndTables" id="saveRightsForNonItemFieldsAndTables" />
                                                    <br />
                                                    <Label className="label" for="name">SAVE rights for non-item fields and tables</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isCreateNewAction} onChange={(e) => setIsCreateNewAction(!isCreateNewAction)} name="isCreateNewAction" id="isCreateNewAction" />
                                                    <br />
                                                    <Label className="label" for="name">Create New</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isSubmitEvidenceCloseAction} onChange={(e) => setIsSubmitEvidenceCloseAction(!isSubmitEvidenceCloseAction)} name="isSubmitEvidenceCloseAction" id="isSubmitEvidenceCloseAction" />
                                                    <br />
                                                    <Label className="label" for="name">Submit Evidence and Close</Label>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type="checkbox" checked={isAddDeleteDocument} onChange={(e) => setIsAddDeleteDocument(!isAddDeleteDocument)} name="isAddDeleteDocument" id="isAddDeleteDocument" />
                                                    <br />
                                                    <Label className="label" for="name">Add / Delete</Label>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Col>
                                    <input type="submit" style={{ float: "right" }} value="Save" size="sm" className="btn button-save" />
                                    <Button className="button-normal" style={{ float: "right", marginRight: "10px" }} color="warning" onClick={backToUserList}><FaChevronLeft /> Back To List</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default CreateUser;
