import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Button, Spinner, CardBody, Badge, Table, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import uuid from 'react-uuid';
import { FaPlus, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import Select, { components, ValueContainerProps } from 'react-select';
import FarmAPTThanksTo from '../FarmApt/FarmAPTThanksTo';
import axios from 'axios';
import ReadMoreContentCustom from '../Utilities/Ui/ReadMoreCustom';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <Row style={{ display: "flex", justifyContent:"space-between",whiteSpace:"pre-wrap" }}>
                    <Col sm="12">
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />{" "}
                        <label>{props.label}</label>
                    </Col>
                </Row>
            </components.Option>
        </div>
    );
};

const Option1 = (props) => {
    return (
        <div>
            <components.Option {...props}>
                {/*<Row>*/}
                {/*    <Col sm="1">*/}
                {/*        <input*/}
                {/*            type="checkbox"*/}
                {/*            checked={props.isSelected}*/}
                {/*            onChange={() => null}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*    <Col sm="11">*/}
                {/*        <label  >{props.label}</label>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                    <Col sm="12">
                        {/*<input*/}
                        {/*    type="checkbox"*/}
                        {/*    checked={props.isSelected}*/}
                        {/*    onChange={() => null}*/}
                        {/*/>{" "}*/}
                        <label>{props.label}</label>
                    </Col>
                </Row>
            </components.Option>
        </div>
    );
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFCE18',
    color: 'white',
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    marginBottom: '8px'
};

export interface GroupedOption {
    label: string;
}

const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

const ActivityRiskV1 = ({ actionPopup, handleActivityRiskClose, activityRiskModel, activityRiskPopup, lmuId, handleActivityRiskSelect, farmAPTActionList, ItemAction }) => {
    const [disableCustomRisk, setDisableCustomRisk] = useState(false); const [selectTitle, setSelectTitle] = useState('Select'); const [disableRecommendedAction, setDisableRecommendedAction] = useState(false);
    const [activityRiskValue, setActivityRiskValue] = useState(""); const [activityRiskList, setActivityRiskList] = useState([]);
    const [luValue, setLUValue] = useState(""); const [luList, setLUList] = useState([]);
    const [recommendedActionOptions, setRecommendedActionOptions] = useState([]);const [farmAPTOptions, setFarmAPTOptions] = useState([]);
    const [showRecommendedActionDropdown, setShowRecommendedActionDropdown] = useState(false); const [recommendedActionValue, setRecommendedActionValue] = useState("");
    const [recommendedActionList, setRecommendedActionList] = useState([]); const [showRecommendedActionText, setShowRecommendedActionText] = useState(false);
    const [recommendedActionTextValue, setRecommendedActionTextValue] = useState("");
    const [groupedOptions, setGroupedOptions] = useState([{ label: "Possible Actions", options: recommendedActionOptions }]);
    const [riskChange, setRiskChange] = useState(actionPopup.id == 0 ? true : false);
    const [selectedActivity, setSelectedActivity] = useState({}); const [confirmRiskChangePopup, setConfirmRiskChangePopup] = useState({ showConfirmRiskChangePopup: false, id: 0 });
    const handleLUInputChange = (e) => {
        setLUValue(e);
        let lus = '';
        if (Array.isArray(e)) {
            let luArr = [];
            e.forEach(risk => {
                luArr.push(risk.value);
            })
            lus = luArr.join(",");
        }
        activityRiskPopup.luId = lus;
    }
    const changeActivityRiskinput1 = (e) => {
        let selectedEnterpriseType = '';
        selectedEnterpriseType = e.value;
        activityRiskPopup.activityRiskId = selectedEnterpriseType;
        setActivityRiskValue(e);
        activityRiskPopup.activityRiskText = e.label;
        activityRiskPopup.luId = '';
        setLUValue('');
        var luItems = [];
        activityRiskModel.luList.filter(x => x.parentId == parseInt(e.value)).forEach((obj, index) => {
            luItems.push(obj);
        });
        setLUList(luItems);
        if (ItemAction) {
            //var selectedLU = luItems.filter(x => x.value == lmuId && x.parentId == parseInt(e.value))[0];
            //setLUValue(selectedLU);
            //activityRiskPopup.luId = selectedLU.value.toString();
        }
        activityRiskPopup.recommendedActionId = 0;
        setRecommendedActionValue("");
        setRecommendedActionList([])
        setShowRecommendedActionDropdown(false);
        setShowRecommendedActionText(false);
        activityRiskPopup.recommendedActionText = "";
        activityRiskPopup.recommendedActionDescription = "";
        var farmAPTList = [];
        farmAPTActionList.forEach((obj, index) => {
            var farmAPTObj = { label: obj.name, parentId: 0, value: obj.id, type: 'FarmAPT', description: obj.meta.desc };
            farmAPTList.push(farmAPTObj);
        });
        var recommendedActionList1 = [];
        var recommendedActionCount = activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).length;
        if (recommendedActionCount != 0) {
            activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).forEach((obj, index) => {
                var recommendedActionObj = { label: obj.label, parentId: obj.parentId, value: obj.value, type: 'RecommendedAction', description: obj.description };
                recommendedActionList1.push(recommendedActionObj);
            });
            var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
            recommendedActionList1.push(otherRecommendedActionObj);
            setRecommendedActionList(recommendedActionList1);
            setRecommendedActionOptions(recommendedActionList1);
        }
        else {
            var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
            recommendedActionList1.push(otherRecommendedActionObj);
            setRecommendedActionList(recommendedActionList1);
            setRecommendedActionOptions(recommendedActionList1);
        }
        setGroupedOptions([{ label: "Possible Actions", options: recommendedActionList1 }]);
        setShowRecommendedActionDropdown(true);
    }
    const changeActivityRiskinput = (e) => {
        let selectedEnterpriseType = '';
        selectedEnterpriseType = e.value;
        activityRiskPopup.activityRiskId = selectedEnterpriseType;
        setActivityRiskValue(e);
        activityRiskPopup.activityRiskText = e.label;
        activityRiskPopup.luId = '';
        setLUValue('');
        var luItems = [];
        activityRiskModel.luList.filter(x => x.parentId == parseInt(e.value)).forEach((obj, index) => {
            luItems.push(obj);
        });
        setLUList(luItems);
        if (ItemAction) {
            var selectedLU = luItems.filter(x => x.value == lmuId && x.parentId == parseInt(e.value))[0];
            setLUValue(selectedLU);
            activityRiskPopup.luId = selectedLU.value.toString();
        }
        activityRiskPopup.recommendedActionId = 0;
        setRecommendedActionValue("");
        setRecommendedActionList([])
        setShowRecommendedActionDropdown(false);
        setShowRecommendedActionText(false);
        activityRiskPopup.recommendedActionText = "";
        activityRiskPopup.recommendedActionDescription = "";
        var farmAPTList = [];
        farmAPTActionList.forEach((obj, index) => {
            var farmAPTObj = { label: obj.name, parentId: 0, value: obj.id, type: 'FarmAPT', description: obj.meta.desc };
            farmAPTList.push(farmAPTObj);
        });
        var recommendedActionList1 = [];
        var recommendedActionCount = activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).length;
        if (recommendedActionCount != 0) {
            activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).forEach((obj, index) => {
                var recommendedActionObj = { label: obj.label, parentId: obj.parentId, value: obj.value, type: 'RecommendedAction', description: obj.description };
                recommendedActionList1.push(recommendedActionObj);
            });
            var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
            recommendedActionList1.push(otherRecommendedActionObj);
            setRecommendedActionList(recommendedActionList1);
            setRecommendedActionOptions(recommendedActionList1);
        }
        else {
            var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
            recommendedActionList1.push(otherRecommendedActionObj);
            setRecommendedActionList(recommendedActionList1);
            setRecommendedActionOptions(recommendedActionList1);
        }
        setGroupedOptions([{ label: "Possible Actions", options: recommendedActionList1 }]);
        setShowRecommendedActionDropdown(true);
    }

    const handleActivityRiskInputChange = (e) => {
        if (e == null) {
            activityRiskPopup.activityRiskId = 0;
            setActivityRiskValue('');
            activityRiskPopup.activityRiskText = '';
            activityRiskPopup.luId = '';
            setLUValue('');
            setLUList('');
            activityRiskPopup.recommendedActionId = 0;
            setRecommendedActionValue("");
            setRecommendedActionList([])
            setShowRecommendedActionDropdown(false);
            setShowRecommendedActionText(false);
        }
        else {
            if (!riskChange && e.value != actionPopup.activityRiskId) {
                setSelectedActivity(e);
                setConfirmRiskChangePopup({ ...confirmRiskChangePopup, showConfirmRiskChangePopup: true, id: 0 })
            }
            else {
                let selectedEnterpriseType = '';
                selectedEnterpriseType = e.value;
                activityRiskPopup.activityRiskId = selectedEnterpriseType;
                setActivityRiskValue(e);
                activityRiskPopup.activityRiskText = e.label;
                activityRiskPopup.luId = '';
                setLUValue('');
                var luItems = [];
                activityRiskModel.luList.filter(x => x.parentId == parseInt(e.value)).forEach((obj, index) => {
                    luItems.push(obj);
                });
                setLUList(luItems);
                if (ItemAction) {
                    var luList = []; var luIds = '';
                    luItems.filter(x => lmuId.split(',').filter(y => parseInt(y) == x.value).length > 0 && x.parentId == parseInt(e.value)).map(item => {
                        luList.push(item);
                        if (luIds == '') {
                            luIds = item.value.toString();
                        }
                        else {
                            luIds = luIds + ','+item.value.toString();
                        }
                    });
                    setLUValue(luList);
                    activityRiskPopup.luId = luIds;
                }
                activityRiskPopup.recommendedActionId = 0;
                setRecommendedActionValue("");
                setRecommendedActionList([])
                setShowRecommendedActionDropdown(false);
                setShowRecommendedActionText(false);
                activityRiskPopup.recommendedActionText = "";
                activityRiskPopup.recommendedActionDescription = "";
                var farmAPTList = [];
                farmAPTActionList.forEach((obj, index) => {
                    var farmAPTObj = { label: obj.name, parentId: 0, value: obj.id, type: 'FarmAPT', description: obj.meta.desc };
                    farmAPTList.push(farmAPTObj);
                });
                var recommendedActionList1 = [];
                var recommendedActionCount = activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).length;
                if (recommendedActionCount != 0) {
                    activityRiskModel.recommendedActionList.filter(x => x.parentId == parseInt(e.parentId)).forEach((obj, index) => {
                        var recommendedActionObj = { label: obj.label, parentId: obj.parentId, value: obj.value, type: 'RecommendedAction', description: obj.description };
                        recommendedActionList1.push(recommendedActionObj);
                    });
                    var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
                    recommendedActionList1.push(otherRecommendedActionObj);
                    setRecommendedActionList(recommendedActionList1);
                    setRecommendedActionOptions(recommendedActionList1);
                }
                else {
                    var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: parseInt(e.parentId), value: 0, type: 'Other' };
                    recommendedActionList1.push(otherRecommendedActionObj);
                    setRecommendedActionList(recommendedActionList1);
                    setRecommendedActionOptions(recommendedActionList1);
                }
                setGroupedOptions([
                    { label: "Possible Actions", options: recommendedActionList1 }]);
                setShowRecommendedActionDropdown(true);
            }
        }
        
    }
    const handleCustomRecommendedActionInputChange = (e) => {
        setRecommendedActionValue(e);
        activityRiskPopup.recommendedActionText = e.label;
        activityRiskPopup.recommendedActionId = e.value;
    }
    const handleRecommendedActionInputChange = (e) => {
        setRecommendedActionValue(e);
        if (e.type == 'FarmAPT') {
            activityRiskPopup.recommendedActionId = 0;
            activityRiskPopup.recommendedActionText = e.label;
            activityRiskPopup.recommendedActionDescription = e.description;
            setShowRecommendedActionText(false);
        }
        else {
            setShowRecommendedActionText(false);
            activityRiskPopup.recommendedActionId = e.value;
            activityRiskPopup.recommendedActionText = e.label;
            activityRiskPopup.recommendedActionDescription = e.description;
        }
    }
    const handleRecommendedActionTextChange = (e) => {
        setRecommendedActionTextValue(e.target.value);
        activityRiskPopup.recommendedActionId = 0;
        activityRiskPopup.recommendedActionText = e.target.value;
    };
    useEffect(() => {
        setActivityRiskList(activityRiskModel.riskGroupList);
        if (actionPopup.id != 0) {
            setSelectTitle('Save and update');
            setDisableCustomRisk(true);
            setDisableRecommendedAction(true);
            var type = 'Activity';
            var risk = activityRiskModel.riskModel.riskList.filter(x => x.id == actionPopup.activityRiskId);
            if (risk != undefined && risk.length > 0) {
                var riskObj = risk[0];
                type = riskObj.type;
            }
            var activityRisk = activityRiskModel.activityRiskList.filter(x => x.value == actionPopup.activityRiskId);
            if (activityRisk != undefined && activityRisk.length > 0) {
                changeActivityRiskinput1(activityRisk[0]);
            }
            if (actionPopup.luId != null && actionPopup.luId != "") {
                var lus = actionPopup.luId.split(',');
                var luList = [];
                lus.map((item) => {
                    var lu = activityRiskModel.luList.filter(x => x.value == parseInt(item));
                    if (lu != undefined && lu.length > 0) {
                        luList.push(lu[0]);
                    }
                });
                handleLUInputChange(luList);
            }
            var recommendedAction = activityRiskModel.recommendedActionList.filter(x => x.value == actionPopup.recommendedActionId);
            if (recommendedAction != undefined && recommendedAction.length > 0) {
                handleRecommendedActionInputChange(recommendedAction[0]);
            }
            if (type == 'Custom') {
                var otherRecommendedActionObj = { label: 'Other(Please Specify)', parentId: 0, value: 0, type: 'Other' };
                handleCustomRecommendedActionInputChange(otherRecommendedActionObj);
            }
        }
    }, []);
    const handleConfirmRiskChangePopupClose = () => {
        setConfirmRiskChangePopup({ ...confirmRiskChangePopup, showConfirmRiskChangePopup: false, id: 0 })
    }
    const handleConfirmRiskChange = async (e) => {
        e.preventDefault();
        setConfirmRiskChangePopup({ ...confirmRiskChangePopup, showConfirmRiskChangePopup: false, id: 0 })
        setRiskChange(true);
        changeActivityRiskinput(selectedActivity);
    }
    return (
        <>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Modal size="lg" style={{ maxWidth: '1250px', width: '100%'}} isOpen={activityRiskPopup.showActivityRisk}>
                <ModalHeader toggle={handleActivityRiskClose}>Activity Risk</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <span style={{ fontSize: "110%" }} className="badge rounded-pill badge-success">1</span><label style={{ display:"contents" }} className="outlineLabel para-light-sm ">&nbsp;&nbsp;Select which Risk this action will be mitigating, or helping to mitigate.</label>
                        </Col>
                        <Col sm="12" style={{ marginTop: "10px" }}>
                            <FormGroup>
                                <Select
                                    id="activityRiskId"
                                    name="activityRiskId"
                                    isDisabled={disableCustomRisk}
                                    closeMenuOnSelect={true}
                                    className="dropdown"
                                    placeholder="Select Activity Risk"
                                    value={activityRiskValue}
                                    options={activityRiskList}
                                    onChange={handleActivityRiskInputChange}
                                    isClearable hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    formatGroupLabel={formatGroupLabel}
                                />
                            </FormGroup>
                        </Col>
                        {
                            activityRiskPopup.activityRiskId != 0 &&
                            <>
                                <Row>
                                    <Col sm="6">
                                        <label style={{ display: "contents" }} className="para-light-sm">{activityRiskModel.riskModel.riskList.filter(x => x.id == activityRiskPopup.activityRiskId)[0].description}</label>
                                    </Col>
                                    <Col sm="6">
                                        <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                            <thead style={{ background: '#B6D137', textAlign: 'center' }}>
                                            </thead>
                                            <tbody style={{ textAlign: 'center' }}>
                                                {
                                                    activityRiskModel.riskModel.riskList != undefined && activityRiskModel.riskModel.riskList != null && activityRiskModel.riskModel.riskList.filter(x => x.id == activityRiskPopup.activityRiskId).length > 0 && activityRiskModel.riskModel.riskList.filter(x => x.id == activityRiskPopup.activityRiskId).map(item =>
                                                        <>
                                                            <tr>
                                                                <td colSpan="4">
                                                                    <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                        <thead style={{ background: '#f9d769', textAlign: 'center' }}>
                                                                            <tr>
                                                                                <th colSpan="2" style={{ width: '20%' }}> Land Units and Risk Rating </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ textAlign: 'center' }}>
                                                                            {
                                                                                activityRiskModel.riskModel.luRiskList.filter(x => x.riskUId == item.uId)
                                                                                    .sort((a, b) => a.id > b.id ? 1 : -1)
                                                                                    .map((luItem, idx) =>
                                                                                        <>
                                                                                            <tr key={luItem.id}>
                                                                                                <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}> {luItem.name} </td>
                                                                                                {luItem.lowRisk && 
                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#1fcc65',color:"white" }}> Low </td>    
                                                                                                }
                                                                                                {luItem.mediumRisk &&
                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#ffc107', color: "white" }}> Medium </td>
                                                                                                }
                                                                                                {luItem.highRisk &&
                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#dc3545', color: "white" }}> High </td>
                                                                                                }
                                                                                                {!luItem.highRisk && !luItem.mediumRisk && !luItem.lowRisk &&
                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}></td>
                                                                                                }
                                                                                            </tr>
                                                                                        </>
                                                                                    )}
                                                                        </tbody>
                                                                    </Table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                            
                        }
                        <Col sm="12">
                            <span style={{ fontSize: "110%" }} className="badge rounded-pill badge-success">2</span><label style={{ display: "contents" }} className="outlineLabel para-light-sm ">&nbsp;&nbsp;Select (if not already known) which Land Unit or Land Units this action will be performed across</label>
                        </Col>
                        <Col sm="12" style={{ marginTop: "10px" }}>
                            <FormGroup>
                                <Select
                                    id="luId"
                                    name="luId"
                                    closeMenuOnSelect={false} isMulti
                                    className="dropdown"
                                    placeholder="Select LU"
                                    value={luValue}
                                    options={luList}
                                    onChange={handleLUInputChange}
                                    isClearable hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {(showRecommendedActionDropdown || showRecommendedActionText) &&
                            <Col sm="12">
                                <span style={{ fontSize: "110%" }} className="badge rounded-pill badge-success">3</span><label style={{ display: "contents" }} className="outlineLabel para-light-sm ">&nbsp;&nbsp;A number of risks have a series of "Possible Actions" listed below, which may be useful as a starting point. If the action you are considering is not listed, please select "Other" to create a custom action.</label>
                            </Col>
                        }
                        <Col sm="12" style={{ marginTop: "10px" }}>
                            {showRecommendedActionDropdown && <FormGroup>
                                <Select
                                    id="recommendedActionId"
                                    name="recommendedActionId"
                                    closeMenuOnSelect={true}
                                    isDisabled={disableRecommendedAction}
                                    className="dropdown"
                                    placeholder="Select Possible Action"
                                    value={recommendedActionValue}
                                    options={groupedOptions}
                                    onChange={handleRecommendedActionInputChange}
                                    isClearable hideSelectedOptions={false}
                                    components={{
                                        Option: Option1
                                    }}
                                />
                            </FormGroup>}
                            {showRecommendedActionText && <FormGroup>
                                <Label>Recommended Action(Other)</Label>
                                <textarea name="recommendedActionTextValue"
                                    value={recommendedActionTextValue}
                                    onChange={handleRecommendedActionTextChange}
                                    className="form-control"></textarea>
                            </FormGroup>}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(activityRiskPopup.recommendedActionId != 0 || activityRiskPopup.recommendedActionText != "") && activityRiskPopup.luId != '' &&
                        <Button style={{ float: `right` }} onClick={handleActivityRiskSelect} color="primary" >{selectTitle}</Button>
                    }
                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleActivityRiskClose} color="primary" >Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={confirmRiskChangePopup.showConfirmRiskChangePopup}>
                <ModalHeader toggle={handleConfirmRiskChangePopupClose} >Confirm Risk change</ModalHeader>
                <ModalBody>
                    If you change the Risk this will clear the Action title and description - are you sure you wish to continue ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleConfirmRiskChange}>Yes</Button>
                    <Button color="danger" onClick={handleConfirmRiskChangePopupClose}>No</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default ActivityRiskV1