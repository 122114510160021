import { useState } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Label, Collapse} from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const About = ({ aboutInfo, type }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>About</h6></Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                {aboutInfo != undefined && aboutInfo != null &&
                                    <div>
                                        <Row>
                                            {type != "Catchment" &&
                                                <Col sm="4">{aboutInfo.logoUrl != null && aboutInfo.logoUrl != "" && <img className="logo-image" src={aboutInfo.logoUrl} width="100%"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = "/images/no-image.jpg"
                                                    }} />}</Col>
                                            }
                                            <Col className="logo-name-col">
                                                {aboutInfo.name != null && aboutInfo.name != "" && <Label className="labe-name" for="name">{aboutInfo.name}</Label>}
                                            </Col>
                                        </Row>

                                        <Row className="div-description">
                                            <Col>
                                                {aboutInfo.description != null && aboutInfo.description != "" &&
                                                    <div><Label className="description-label" for="description">{aboutInfo.description}</Label> </div>}
                                            </Col>
                                        </Row>
                                        {type == "Catchment" ?
                                            <Row className="div-description">
                                                <Col sm="8">
                                                    {aboutInfo.externalLink != null && aboutInfo.externalLink != "" &&
                                                        <div><Label className="label" for="externalLink">External Link : </Label>  <a className="linkNews" href={aboutInfo.externalLink} target='_blank' ><u>{aboutInfo.externalLink}</u></a></div>}
                                                </Col>
                                                {aboutInfo.catchmentDocumentRelativePath && <Col sm="4">
                                                    <div className="catchmentDoc-style">
                                                        <a href={aboutInfo.catchmentDocumentRelativePath} target='_blank' title={aboutInfo.catchmentDocumentName}>
                                                            <img width="50px" src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img>
                                                            <br /><span className="clickMe-label">(Click to open)</span></a>
                                                    </div>
                                                </Col>
                                                }
                                            </Row>
                                            : <Row className="div-description">
                                                <Col>
                                                    {aboutInfo.externalLink != null && aboutInfo.externalLink != "" &&
                                                        <div><Label className="label" for="externalLink">External Link : </Label><a className="linkNews" href={aboutInfo.externalLink} target='_blank' ><u>{aboutInfo.externalLink}</u></a></div>}
                                                </Col>
                                            </Row>
                                        }
                                        <Row className="div-description">
                                            <Col>{aboutInfo.imageUrl != null && aboutInfo.imageUrl != "" &&
                                                <div><Label className="label" for="externalLink">Image </Label> <br/>
                                                <img className="about-image" src={aboutInfo.imageUrl} width="100%"
                                                onError={(e) => {
                                                    e.target.onerror = null
                                                    e.target.src = "/images/no-image.jpg"
                                                }
                                                } /></div>
                                            }</Col>
                                        </Row>
                                    </div>
                                }

                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default About;