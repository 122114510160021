import React, { useState, useEffect } from 'react';
import { Col, Row, Form, FormGroup, Input, Button, Spinner, CardBody, CardHeader, Label,Card } from 'reactstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import './CatchmentContext.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const OtherInfo = ({ SectionObj }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [saving, setSaving] = useState('');
    const [memberships, setMemberships] = useState('');
    const [contaminantPriorities, setContaminantPriorities] = useState('');
    const [description, setDescription] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));

    useEffect(() => {
        getOtherInfo();
        
    }, [])

    const getOtherInfo = async () => {
        try {
            const response = await axios.get('/api/catchmentContext/getOtherInfo/', {
                params: {
                    farmId: farmId
                }
            });

            setMemberships(response?.data?.memberships);
            setContaminantPriorities(response?.data?.contaminantPriorities);
            setDescription(response?.data?.description);

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " +  error.message);
            } else {
                toast.error("No server response");
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setSaving(true);

        try {
            const response = await axios.post(
                '/api/catchmentContext/saveOtherInfo/',
                JSON.stringify(
                    {
                        memberships, contaminantPriorities, description, farmId, userId
                    }), {
                headers: { 'Content-Type': 'application/json' }
            });

            setTimeout(() => {
                setSaving(false);
            }, 1000)

            console.log(response);
            toast.success("Other Catchment Info Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });

        } catch (err) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)

            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Other Catchment Info Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Other Catchment Info Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    return (
        <>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <CardHeader className="card-header-dark">
                    <h5><FaInfoCircle data-tooltip-id="my-tooltip-5" />&nbsp;&nbsp;&nbsp;Sub Catchment Membership and Information</h5>
                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                        id="my-tooltip-5"
                        place="top"
                        variant="info"
                        render={({ content, activeAnchor }) => (
                            <>
                                <Row>
                                    <Col>
                                        <p style={{ fontWeight: "lighter" }}>If you are a member of a catchment or subcatchment group, enter the details below.</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    />
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="memberships">
                                                Subcatchment Membership(s)
                                            </Label>
                                            <Input
                                                id="memberships"
                                                name="text"
                                                placeholder=""
                                                type="text"
                                                value={memberships}
                                                onChange={(e) => setMemberships(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="contaminantPriorities">
                                                Subcatchment Contaminant Priorities
                                            </Label>
                                            <Input
                                                id="contaminantPriorities"
                                                name="text"
                                                placeholder=""
                                                type="text"
                                                value={contaminantPriorities}
                                                onChange={(e) => setContaminantPriorities(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup>
                                        <Col>
                                            <Input
                                                id="description"
                                                name="description"
                                                type="textarea"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Row>
                                <br />
                            </Col>
                        </Row>
                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                            <>
                                {saving ?
                                    <Button style={{ float: "right" }}
                                        color="success" className="button-save"
                                        disabled
                                    >
                                        <Row>
                                            <Col sm="8">
                                                <span>
                                                    {' '}Saving
                                                </span>
                                            </Col>
                                            <Col sm="4">
                                                <Spinner size="sm">
                                                    Saving...
                                                </Spinner>
                                            </Col>
                                        </Row>
                                    </Button>
                                    :
                                    <Button className="button-save" style={{ float: "right" }} color="success" size="sm">
                                        Save
                                    </Button>
                                }
                            </>
                        }
                    </Form>
                </CardBody>
            </Card>
            
        </>
    )
}

export default OtherInfo