import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Card, CardBody, Spinner, CardHeader } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { FaPlus, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';

const Contacts = ({ userId, checkAlreadyIsresponsible }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddEditItem = localStorage.getItem("isAddEditItem");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [loading, setLoading] = useState(true);
    const [savingContacts, setSavingContacts] = useState('');
    const [contactRows, setContactRows] = useState([]);
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [contactTypeList, setContactTypeList] = useState([]);

    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/enterprises/GetContacts/', {
                params: { Id: farmId }
            }
            );
            setContactRows(response.data.content.farmContacts);
            setContactTypeList(response.data.content.contactTypes);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, [loading]);

    const handleContactRoleChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].role = parseInt(e.target.value);
        setContactRows(tempRows);
    };

    const handleContactNameChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].name = e.target.value;
        setContactRows(tempRows);
    };

    const handleContactAddress1Change = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].address1 = e.target.value;
        setContactRows(tempRows);
    };

    const handleContactAddress2Change = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].address2 = e.target.value;
        setContactRows(tempRows);
    };

    const handleContactCityChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].city = e.target.value;
        setContactRows(tempRows);
    };

    const handleContactEmailChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].email = e.target.value;
        setContactRows(tempRows);
    };
    const handleContactPhoneChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].phone = e.target.value;
        setContactRows(tempRows);
    };
    const handleContactNZBNChange = idx => e => {
        const tempRows = [...contactRows];
        tempRows[idx].nzbn = e.target.value;
        setContactRows(tempRows);
    };

    const handleIsResponsibleChange = idx => e => {
        const tempRows = [...contactRows];
        let alreadyChecked = checkAlreadyIsresponsibleChecked();
        if (alreadyChecked && e.target.checked == true) {
            e.target.checked = false;
        }
        else {
            tempRows[idx].isResponsible = e.target.checked;
            setContactRows(tempRows);
        }
    };

    const handleIsDeveloperChange = idx => e => {
        const tempRows = [...contactRows];
        let alreadyChecked = checkAlreadyIsDeveloperChecked();
        if (alreadyChecked && e.target.checked == true) {
            e.target.checked = false;
        }
        else {
            tempRows[idx].isDeveloper = e.target.checked;
            setContactRows(tempRows);
        }
    };

    const handleContactRowsAddRow = () => {
        const item = {
            role: 0, name: "", address1: "", address2: "", city: "", email: "", id: 0, isResponsible: false, isDeveloper: false, phone: '', nzbn: ''
        };
        setContactRows([...contactRows, item]);
    };

    const handleContactRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...contactRows]
        tempRows.splice(idx, 1)
        setContactRows(tempRows);
    }

    const checkAlreadyIsresponsibleChecked = () => {
        let alreadyChecked = false;
        let tempContactRows = [...contactRows];

        for (var i = 0; i < tempContactRows.length; i++) {
            if (tempContactRows[i].isResponsible == true) {
                alreadyChecked = true;
            }
        }
        return alreadyChecked;
    }

    const checkAlreadyIsDeveloperChecked = () => {
        let alreadyChecked = false;
        let tempContactRows = [...contactRows];

        for (var i = 0; i < tempContactRows.length; i++) {
            if (tempContactRows[i].isDeveloper == true) {
                alreadyChecked = true;
            }
        }
        return alreadyChecked;
    }

    const handleContactRowsSubmit = async (e) => {
        e.preventDefault()
        setSavingContacts(true);
        let tempContactRows = [...contactRows];
        let form = {
            FarmId: farmId,
            FarmContacts: [],
            UserId: userId
        }
        for (var i = 0; i < tempContactRows.length; i++) {
            var obj = {};
            obj.Id = tempContactRows[i].id;
            obj.FarmId = farmId;
            obj.Email = tempContactRows[i].email;
            obj.Name = tempContactRows[i].name;
            obj.Role = tempContactRows[i].role;
            obj.Address1 = tempContactRows[i].address1;
            obj.Address2 = tempContactRows[i].address2;
            obj.City = tempContactRows[i].city;
            obj.isResponsible = tempContactRows[i].isResponsible;
            obj.isDeveloper = tempContactRows[i].isDeveloper;
            obj.Phone = tempContactRows[i].phone;
            obj.NZBN = tempContactRows[i].nzbn;
            form.FarmContacts.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveFarmContacts/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Contacts Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingContacts(false);
                setContactRows(response.data.content);
                checkAlreadyIsresponsible();
            }
        }
    };

    return (
        <Card className="my-4">
            <CardHeader className="card-header card-header-dark d-flex justify-content-between align-items-center">
                <h5 >
                    Contacts
                </h5>
                {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                    <>
                        <button onClick={handleContactRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                            Add
                        </button>
                    </>
                }
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <p style={{}}>Identify at least one contact for the property. 1 person must be designated as the Farm Operator (person who has ultimate responsibility for this Fresh Water Farm Plan)</p>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm="12">
                        <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                            <thead>
                                <tr>
                                    <th className="text-center"> Role </th>
                                    <th className="text-center"> Name </th>
                                    <th className="text-center"> Postal Address 1 </th>
                                    <th className="text-center"> Postal Address 2 </th>
                                    <th className="text-center"> Town / City </th>
                                    <th className="text-center"> Email </th>
                                    <th className="text-left"> Phone </th>
                                    <th className="text-center"> Farm Operator</th>
                                    <th className="text-center"> Prepared Plan(Developer)</th>
                                    <th />
                                    <th className="text-left"> Operator - enter NZBN (if any)<br /> Developer - enter business name </th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactRows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{ display: "none" }}>{contactRows[idx].id}</td>
                                        <td style={{ width: "10%" }}>
                                            {contactTypeList == undefined && <p><em>Loading...</em></p>}
                                            {contactTypeList != undefined && contactTypeList != null &&
                                                <Input type="select" value={contactRows[idx].role} onChange={handleContactRoleChange(idx)} name="role" id="role">
                                                    {contactTypeList.map(items =>
                                                        <option key={uuid()} value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            }
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="consentNumber"
                                                value={contactRows[idx].name}
                                                onChange={handleContactNameChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="consentNumber"
                                                value={contactRows[idx].address1}
                                                onChange={handleContactAddress1Change(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="consentNumber"
                                                value={contactRows[idx].address2}
                                                onChange={handleContactAddress2Change(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="consentNumber"
                                                value={contactRows[idx].city}
                                                onChange={handleContactCityChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="consentNumber"
                                                value={contactRows[idx].email}
                                                onChange={handleContactEmailChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="phone"
                                                value={contactRows[idx].phone}
                                                onChange={handleContactPhoneChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "8%",textAlign: 'center' }}>
                                            <Input type="checkbox" checked={contactRows[idx].isResponsible} onChange={handleIsResponsibleChange(idx)} name="IsResponsible" id="IsResponsible" />
                                        </td>
                                        <td style={{ width: "8%", textAlign: 'center' }}>
                                            <Input type="checkbox" checked={contactRows[idx].isDeveloper} onChange={handleIsDeveloperChange(idx)} name="IsDeveloper" id="IsDeveloper" />
                                        </td>
                                        <td style={{ width: "5%" }}>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <>
                                                    <button style={{ float: "right" }} onClick={handleContactRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                        <FaTrash />
                                                    </button>
                                                </>
                                            }
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <input
                                                type="text"
                                                name="nzbn"
                                                disabled={!(contactRows[idx].isResponsible || contactRows[idx].isDeveloper)}
                                                value={!(contactRows[idx].isResponsible || contactRows[idx].isDeveloper) ? 'Not Applicable' : ((contactRows[idx].nzbn == null || contactRows[idx].nzbn == undefined) ? "" : contactRows[idx].nzbn)}
                                                onChange={handleContactNZBNChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                            <>
                                {savingContacts ?
                                    <Button style={{ float: "right" }} onClick={handleContactRowsSubmit}
                                        color="success" className="button-save"
                                        disabled
                                    >
                                        <Row>
                                            <Col sm="8">
                                                <span>
                                                    {' '}Saving
                                                </span>
                                            </Col>
                                            <Col sm="4">
                                                <Spinner size="sm">
                                                    Saving...
                                                </Spinner>
                                            </Col>
                                        </Row>
                                    </Button>
                                    :
                                    <Button className="button-save" style={{ float: "right" }} onClick={handleContactRowsSubmit} color="success" size="sm">
                                        Save
                                    </Button>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Contacts;