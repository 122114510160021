import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Card, CardBody, Spinner, CardHeader } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { FaPlus, FaTrash, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const CouncilAndLandInfo = ({ userId, enterprises, regulatoryRequirements, setLandParcelCompleted }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddEditItem = localStorage.getItem("isAddEditItem");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [loading, setLoading] = useState(true); const [saving, setSaving] = useState(''); const [councilAndLandRows, setCouncilAndLandRows] = useState([]); const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [consentTypes, setConsentTypes] = useState([]); const [regionalCouncilList, setRegionalCouncilList] = useState([]); const [advisorList, setAdvisorList] = useState([]);
    const [councilAndLandInfo, setCouncilAndLandInfo] = useState({ RegionalCouncil: 0, AdvisorId: 0, ValuationReference: '', LandParcels: '', OtherRelevantIdentifier: '', ResponsiblePerson: '' });
    const [savingConsents, setSavingConsents] = useState('');
    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/enterprises/GetCouncilAndLandInfo/', {
                params: { Id: farmId }
            }
            );
            if (response.data.content.landParcels != null && response.data.content.landParcels != undefined && response.data.content.landParcels != '') {
                setLandParcelCompleted(true);
            }
            setCouncilAndLandRows(response.data.content.farmCouncilLandInfos);
            setConsentTypes(response.data.content.consentTypes);
            setRegionalCouncilList(response.data.content.regionalCouncilList);
            setAdvisorList(response.data.content.advisorList);
            setCouncilAndLandInfo({
                ...councilAndLandInfo, RegionalCouncil: response.data.content.regionalCouncil, AdvisorId: response.data.content.advisorId
                , ValuationReference: response.data.content.valuationReference, LandParcels: response.data.content.landParcels, OtherRelevantIdentifier: response.data.content.otherRelevantIdentifier
                , ResponsiblePerson: response.data.content.responsiblePerson
            });
            setRegulatoryRequirementDetails({
                ...regulatoryRequirementDetails, FWFPToMeetAnyOtherRegulatoryRequirement: enterprises.fwfpToMeetAnyOtherRegulatoryRequirement,
                RegulatoryRequirementDetails: enterprises.regulatoryRequirementDetails, IsAnyNewPhsyicalWorks: enterprises.isAnyNewPhsyicalWorks,
                AnyNewPhsyicalWorksDetails: enterprises.anyNewPhsyicalWorksDetails,
                nzBusinessNumber: enterprises.nzBusinessNumber, infdpUniqueFreshwaterFarmPlanId: enterprises.infdpUniqueFreshwaterFarmPlanId
            })
            getUploadedDocuments();
            setRegulatoryRequirementsRows(regulatoryRequirements);

            let tempErrors = [];
            response.data.content.farmCouncilLandInfos.forEach(function (element) {
                const error = {
                    fileError: ''
                };
                tempErrors.push(error);
            });
            setFormConsentErrors(tempErrors);

            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const handleCouncilAndLandRowsSubmit = async (e) => {
        e.preventDefault()
        if (councilAndLandInfo.LandParcels == null || councilAndLandInfo.LandParcels == undefined || councilAndLandInfo.LandParcels == '') {
            toast.error("Legal Land titles and parcels field is mandatory!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
        setSaving(true);
        let tempCouncilAndLandRows = [...councilAndLandRows];
        let form = {
            FarmId: farmId,
            FarmCouncilLandInfos: [],
            UserId: userId,
            RegionalCouncil: councilAndLandInfo.RegionalCouncil,
            AdvisorId: councilAndLandInfo.AdvisorId,
            ValuationReference: councilAndLandInfo.ValuationReference,
            LandParcels: councilAndLandInfo.LandParcels,
            OtherRelevantIdentifier: councilAndLandInfo.OtherRelevantIdentifier,
            ResponsiblePerson: councilAndLandInfo.ResponsiblePerson,
            RegulatoryRequirements:[]
        }
        for (var i = 0; i < tempCouncilAndLandRows.length; i++) {
            var obj = {};
            obj.Id = tempCouncilAndLandRows[i].id;
            obj.FarmId = farmId;
            obj.ConsentType = tempCouncilAndLandRows[i].consentType;
            obj.ConsentNumber = tempCouncilAndLandRows[i].consentNumber;
            obj.ConsentConditions = tempCouncilAndLandRows[i].consentConditions;
            obj.ExpiryDateText = tempCouncilAndLandRows[i].expiryDate != null ? tempCouncilAndLandRows[i].expiryDateText : "";
            obj.DocumentName = tempCouncilAndLandRows[i].documentName;
            obj.DocumentPhysicalName = tempCouncilAndLandRows[i].documentPhysicalName;
            obj.DocumentPath = tempCouncilAndLandRows[i].documentPath;
            form.FarmCouncilLandInfos.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveCouncilAndLandInfo/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                setLandParcelCompleted(true);
                toast.success("Council And Land Information Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSaving(false);
            }
        }
    };
    const handleConsentsSubmit = async (e) => {
        e.preventDefault()
        setSavingConsents(true);
        let tempRegulatoryRequirementsRows = [...regulatoryRequirementsRows];
        if (regulatoryRequirementDetails.IsAnyNewPhsyicalWorks == "No") {
            regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails = "";
        }
        let tempCouncilAndLandRows = [...councilAndLandRows];
        let form = {
            FarmId: farmId,
            FarmCouncilLandInfos: [],
            RegulatoryRequirements: [],
            UserId: userId,
            FWFPToMeetAnyOtherRegulatoryRequirement: regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement,
            IsAnyNewPhsyicalWorks: regulatoryRequirementDetails.IsAnyNewPhsyicalWorks,
            AnyNewPhsyicalWorksDetails: regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails,
            NZBusinessNumber: regulatoryRequirementDetails.nzBusinessNumber,
            INFDPUniqueFreshwaterFarmPlanId: regulatoryRequirementDetails.infdpUniqueFreshwaterFarmPlanId
        }
        for (var i = 0; i < tempCouncilAndLandRows.length; i++) {
            var obj = {};
            obj.Id = tempCouncilAndLandRows[i].id;
            obj.FarmId = farmId;
            obj.ConsentType = tempCouncilAndLandRows[i].consentType;
            obj.ConsentNumber = tempCouncilAndLandRows[i].consentNumber;
            obj.ConsentConditions = tempCouncilAndLandRows[i].consentConditions;
            form.FarmCouncilLandInfos.push(obj);
        }
        for (var i = 0; i < tempRegulatoryRequirementsRows.length; i++) {
            var obj = {};
            obj.Id = tempRegulatoryRequirementsRows[i].id;
            obj.RegulatoryRequirement = tempRegulatoryRequirementsRows[i].regulatoryRequirement;
            obj.Condition = tempRegulatoryRequirementsRows[i].condition;
            form.RegulatoryRequirements.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveConsents/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                handleRecordRowsSubmit();
                toast.success("Consents Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingConsents(false);
                window.location.reload(true);
            }
        }
    };
    const handleCouncilAndLandRowsAddRow = () => {
        const item = {
            consentNumber: "",
            consentConditions: "",
            consentType: "", id: 0
        };
        setCouncilAndLandRows([...councilAndLandRows, item]);
        const error = {
            fileError: ''
        };
        setFormConsentErrors([...formConsentErrors, error]);
    };

    const handleCouncilAndLandConsentTypeChange = idx => e => {
        const { name, value } = e.target;
        const tempRows = [...councilAndLandRows];
        tempRows[idx].consentType = value;
        setCouncilAndLandRows(tempRows);
    };

    const handleCouncilAndLandConsentNumberChange = idx => e => {
        const { name, value } = e.target;
        const tempRows = [...councilAndLandRows];
        tempRows[idx].consentNumber = value;
        setCouncilAndLandRows(tempRows);
    };

    const handleCouncilAndLandConsentConditionsChange = idx => e => {
        const { name, value } = e.target;
        const tempRows = [...councilAndLandRows];
        tempRows[idx].consentConditions = value;
        setCouncilAndLandRows(tempRows);
    };

    const handleCouncilAndLandRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...councilAndLandRows]
        tempRows.splice(idx, 1)
        setCouncilAndLandRows(tempRows);
        const tempErrors = [...formConsentErrors];
        tempErrors.splice(idx, 1)
        setFormConsentErrors(tempErrors);
    }

    const [regulatoryRequirementDetails, setRegulatoryRequirementDetails] = useState({ RegulatoryRequirementDetails: '', FWFPToMeetAnyOtherRegulatoryRequirement: 'Yes', IsAnyNewPhsyicalWorks: 'No', AnyNewPhsyicalWorksDetails: '', nzBusinessNumber: '', infdpUniqueFreshwaterFarmPlanId: '' });
    const [savingRegulatoryRequirementDetails, setSavingRegulatoryRequirementDetails] = useState(false); const [regulatoryRequirementsRows, setRegulatoryRequirementsRows] = useState([]);
    const [formErrors, setFormErrors] = useState([]); const [formConsentErrors, setFormConsentErrors] = useState([]);
    const [uploadType, setUploadType] = useState('NewPhsyicalWork');
    const [documentUploadList, setDocumentUploadList] = useState([]);
    const [deleteDocumentPopup, setDeleteDocumentPopup] = useState({ showDeleteDocumentPopup: false, itemId: 0, date: '', daetails: '', fileName: '' });
    let inputFile = []; let inputConsentFile = [];

    const handleRegulatoryRequirementRowsAddRow = () => {
        const item = {
            regulatoryRequirement: "",
            condition: "", id: 0
        };
        setRegulatoryRequirementsRows([...regulatoryRequirementsRows, item]);
    };

    const handleRegulatoryRequirementChange = idx => e => {
        const tempRows = [...regulatoryRequirementsRows];
        tempRows[idx].regulatoryRequirement = e.target.value;
        setRegulatoryRequirementsRows(tempRows);
    };

    const handleRegulatoryRequirementConditionChange = idx => e => {
        const tempRows = [...regulatoryRequirementsRows];
        tempRows[idx].condition = e.target.value;
        setRegulatoryRequirementsRows(tempRows);
    };

    const handleRegulatoryRequirementRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...regulatoryRequirementsRows]
        tempRows.splice(idx, 1)
        setRegulatoryRequirementsRows(tempRows);
    }

    const handleRecordRowsAddRow = () => {
        const item = {
            createdDate: getParsedDate1(new Date()),
            documentDetail: "", id: 0, documentName: '', documentPhysicalName: '', documentPath: '', documentRelativePath: ''
        };
        const error = {
            fileError: ''
        };
        setFormErrors([...formErrors, error]);
        setDocumentUploadList([...documentUploadList, item]);
    };

    const getParsedDate1 = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleDetailsChange = idx => e => {
        const tempRows = [...documentUploadList];
        tempRows[idx].documentDetail = e.target.value;
        setDocumentUploadList(tempRows);
    };

    const handleBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputFile[idx].click();
        return false;
    }
    const handleConsentBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputConsentFile[idx].click();
        return false;
    }
    const handleDateChange = (idx, date) => {
        const tempRows = [...documentUploadList];
        tempRows[idx].uploadDateText = getParsedDate(date);
        tempRows[idx].uploadDate = getDateFromString(getParsedDate(date));
        setDocumentUploadList(tempRows);
    };

    const handleConsentDateChange = (idx, date) => {
        const tempRows = [...councilAndLandRows];
        tempRows[idx].expiryDateText = getParsedDate(date);
        tempRows[idx].expiryDate = getDateFromString(getParsedDate(date));
        setCouncilAndLandRows(tempRows);
    };

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const handleRecordChange = async (e, idx) => {
        e.preventDefault();
        const tempErrors = [...formErrors];
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.fileError = "Only Pdf files can upload!";
            tempErrors[idx].fileError = "Only Pdf files can upload!";
        }
        else {
            tempErrors[idx].fileError = "";
        }
        setFormErrors(tempErrors);
        if (Object.keys(errors).length == 0) {
            if (file.type == 'application/pdf') {
                const formData = new FormData();
                formData.append("FarmId", farmId);
                formData.append("ReferenceId", farmId);
                formData.append("Type", uploadType);
                formData.append("FileName", e.target.files[0].name);
                formData.append("File", e.target.files[0]);
                formData.append("userId", userId);
                fetch('api/uploaddocument/UploadDocument', {
                    method: 'POST',
                    body: formData
                }).then(r => r.json()).then(res => {
                    if (res.status) {
                        const tempRows = [...documentUploadList];
                        tempRows[idx].documentName = res.content.DocumentName;
                        tempRows[idx].documentPhysicalName = res.content.DocumentPhysicalName;
                        tempRows[idx].documentPath = res.content.DocumentPath;
                        tempRows[idx].documentRelativePath = res.content.RelativeFilepath;
                        setDocumentUploadList(tempRows);
                    }
                });
            }
        }
        else {
            e.target.value = null;
        }
    }
    const handleConsentRecordChange = async (e, idx) => {
        e.preventDefault();
        const tempErrors = [...formConsentErrors];
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if ((file.size / (1024 * 1024)) > 100) {
            errors.fileError = "Sorry, uploads are restricted to a maximum of 100mb per item!";
            tempErrors[idx].fileError = "Sorry, uploads are restricted to a maximum of 100mb per item!";
        }
        else {
            tempErrors[idx].fileError = "";
        }
        setFormConsentErrors(tempErrors);
        if (Object.keys(errors).length == 0) {

            const formData = new FormData();
            formData.append("FarmId", farmId);
            formData.append("ReferenceId", farmId);
            formData.append("Type", 'Consent');
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            formData.append("userId", userId);

            const response = await axios.post(
                '/api/uploaddocument/UploadDocument/', formData,
                {
                    headers: { "Content-type": "multipart/form-data", "FarmId": farmId, "ReferenceId": farmId, "Type": 'Consent', "userId": userId, "FileName": e.target.files[0].name },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    const tempRows = [...councilAndLandRows];
                    tempRows[idx].documentName = response.data.content.DocumentName;
                    tempRows[idx].documentPhysicalName = response.data.content.DocumentPhysicalName;
                    tempRows[idx].documentPath = response.data.content.DocumentPath;
                    tempRows[idx].documentRelativePath = response.data.content.RelativeFilepath;
                    setCouncilAndLandRows(tempRows);
                }
            }
        }
        else {
            e.target.value = null;
        }
    }

    const handleRecordRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...documentUploadList]
        const tempErrors = [...formErrors];
        tempRows.splice(idx, 1)
        tempErrors.splice(idx, 1)
        setDocumentUploadList(tempRows);
        setFormErrors(tempErrors);
    }

    const getUploadedDocuments = async () => {
        try {
            const response = await axios.get(
                '/api/upload/GetUploadedDocuments/', {
                params: { Id: farmId, UploadType: uploadType, FarmId: farmId }
            }
            );
            if (response.data.status) {
                setDocumentUploadList(response.data.content);
                let tempErrors = [];
                response.data.content.forEach(function (element) {
                    const error = {
                        fileError: ''
                    };
                    tempErrors.push(error);
                });
                setFormErrors(tempErrors);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const handleRegulatoryRequirementDetailsSubmit = async (e) => {
        e.preventDefault()
        setSavingRegulatoryRequirementDetails(true);

        let tempRegulatoryRequirementsRows = [...regulatoryRequirementsRows];
        if (regulatoryRequirementDetails.IsAnyNewPhsyicalWorks == "No") {
            regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails = "";
        }
        let form = {
            FarmId: farmId,
            RegulatoryRequirements: [],
            UserId: userId,
            FWFPToMeetAnyOtherRegulatoryRequirement: regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement,
            IsAnyNewPhsyicalWorks: regulatoryRequirementDetails.IsAnyNewPhsyicalWorks,
            AnyNewPhsyicalWorksDetails: regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails
        }
        for (var i = 0; i < tempRegulatoryRequirementsRows.length; i++) {
            var obj = {};
            obj.Id = tempRegulatoryRequirementsRows[i].id;
            obj.RegulatoryRequirement = tempRegulatoryRequirementsRows[i].regulatoryRequirement;
            obj.Condition = tempRegulatoryRequirementsRows[i].condition;
            form.RegulatoryRequirements.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveRegulatoryRequirements/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                handleRecordRowsSubmit();
                toast.success("Other Regulatory Requirements Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingRegulatoryRequirementDetails(false);
                setRegulatoryRequirementsRows(response.data.content);
            }
        }

    };

    const handleRecordRowsSubmit = async (e) => {

        let tempRecordUploadList = [...documentUploadList];
        let form = {
            ReferenceId: farmId,
            FarmId: farmId,
            UploadType: uploadType,
            userId: userId,
            Documents: []
        }
        for (var i = 0; i < tempRecordUploadList.length; i++) {
            if (tempRecordUploadList[i].documentName != '' && tempRecordUploadList[i].documentName != null && tempRecordUploadList[i].documentName != undefined) {
                var obj = {};
                obj.Id = tempRecordUploadList[i].id;
                obj.documentDetail = tempRecordUploadList[i].documentDetail;
                obj.documentName = tempRecordUploadList[i].documentName;
                obj.documentPhysicalName = tempRecordUploadList[i].documentPhysicalName;
                obj.documentPath = tempRecordUploadList[i].documentPath;
                obj.UploadDateText = tempRecordUploadList[i].uploadDate != null ? tempRecordUploadList[i].uploadDateText : "";
                form.Documents.push(obj);
            }
        }
        const response = await axios.post(
            '/api/upload/SaveDocuments/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                setTimeout(() => {
                    setDocumentUploadList(response.data.content);
                    let tempErrors = [];
                    setFormErrors(tempErrors);
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }, 2000);
            }
        }
    };

    return (
        <>
            <Card className="my-4">
                <CardHeader className="card-header card-header-dark d-flex justify-content-between align-items-center">
                    <h5>
                        Council & Land Information
                    </h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Regional council</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                {regionalCouncilList == undefined && <p><em>Loading...</em></p>}
                                {regionalCouncilList != undefined && regionalCouncilList != null &&
                                    <Input disabled={true} type="select" value={councilAndLandInfo.RegionalCouncil} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, RegionalCouncil: e.target.value })} name="RegionalCouncil" id="RegionalCouncil">
                                        {regionalCouncilList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Valuation reference</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="text" disabled value={councilAndLandInfo.ValuationReference} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, ValuationReference: e.target.value })} name="ValuationReference" id="ValuationReference" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <FaInfoCircle data-tooltip-id="my-tooltip-9" />&nbsp;&nbsp;&nbsp;
                                <Label className="label" for="name"><label style={{ color: "red" }}>*</label> Legal Land titles and parcels</Label>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    id="my-tooltip-9"
                                    place="top"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>You will find this information on your property's Certificate of Title under "Legal Description"</p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="textarea" value={councilAndLandInfo.LandParcels} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, LandParcels: e.target.value })} name="LandParcels" id="LandParcels" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Other relevant property identifier</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="text" value={councilAndLandInfo.OtherRelevantIdentifier} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, OtherRelevantIdentifier: e.target.value })} name="OtherRelevantIdentifier" id="OtherRelevantIdentifier" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ display: "none" }}>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Person responsible for plan implementation</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="text" value={councilAndLandInfo.ResponsiblePerson} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, ResponsiblePerson: e.target.value })} name="ResponsiblePerson" id="ResponsiblePerson" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ display: "none" }}>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Assigned Advisor</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                {advisorList == undefined && <p><em>Loading...</em></p>}
                                {advisorList != undefined && advisorList != null &&
                                    <Input type="select" value={councilAndLandInfo.AdvisorId} onChange={(e) => setCouncilAndLandInfo({ ...councilAndLandInfo, AdvisorId: e.target.value })} name="AdvisorId" id="AdvisorId">
                                        {advisorList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                <>
                                    <button onClick={handleCouncilAndLandRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                        Add
                                    </button>
                                </>
                            }
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm="12">
                            <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                <thead>
                                    <tr>
                                        <th className="text-center"> Consent Type </th>
                                        <th className="text-center"> Number </th>
                                        <th className="text-center"> Consent Condition(s) relating to Freshwater Farm plan </th>
                                        <th className="text-center"> Expiry Date </th>
                                        <th className="text-center"> Attach Document </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {councilAndLandRows != undefined && councilAndLandRows != null && councilAndLandRows.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                            <td style={{ display: "none" }}>{councilAndLandRows[idx].id}</td>
                                            <td style={{ width: "20%" }}>
                                                <input
                                                    type="text"
                                                    name="consentType"
                                                    value={councilAndLandRows[idx].consentType}
                                                    onChange={handleCouncilAndLandConsentTypeChange(idx)}
                                                    className="form-control"
                                                />
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                <input
                                                    type="text"
                                                    name="consentNumber"
                                                    value={councilAndLandRows[idx].consentNumber}
                                                    onChange={handleCouncilAndLandConsentNumberChange(idx)}
                                                    className="form-control"
                                                />
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <input
                                                    type="textarea"
                                                    name="consentConditions"
                                                    value={councilAndLandRows[idx].consentConditions}
                                                    onChange={handleCouncilAndLandConsentConditionsChange(idx)}
                                                    className="form-control"
                                                />
                                            </td>
                                            <td style={{ width: "15%" }}>
                                                <DatePicker selected={councilAndLandRows[idx].expiryDate != null ? getDateFromString(moment(councilAndLandRows[idx].expiryDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleConsentDateChange(idx, date)} name="expiryDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                            </td>
                                            <td style={{ width: "15%" }}>
                                                {councilAndLandRows[idx].documentRelativePath != null && councilAndLandRows[idx].documentRelativePath != '' ?
                                                    <Row>
                                                        <Col>
                                                            <a href={councilAndLandRows[idx].documentRelativePath} target='_blank'>
                                                                <img src="/images/File_Icon.png" className="PDF_icon" ></img>
                                                            </a>
                                                        </Col>
                                                        <Col>
                                                            <Button style={{ float: `right` }} color="warning" size="sm" onClick={(e) => handleConsentBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Button style={{ float: `right` }} color="success" size="sm" onClick={(e) => handleConsentBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                }
                                                <br />
                                                <input style={{ float: `right`, display: 'none' }}
                                                    ref={input => {
                                                        inputConsentFile[idx] = input;
                                                    }}
                                                    type="file" name="attachment" className="form-control" onChange={(e) => handleConsentRecordChange(e, idx)} />
                                                {formConsentErrors.length > 0 && formConsentErrors[idx] != undefined && formConsentErrors[idx].fileError != undefined &&
                                                    <p className="errorMessage">{formConsentErrors[idx].fileError}</p>
                                                }
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                    <>
                                                        <button style={{ float: "right" }} onClick={handleCouncilAndLandRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                            <FaTrash />
                                                        </button>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    {saving ?
                                        <Button style={{ float: "right" }} onClick={handleCouncilAndLandRowsSubmit}
                                            color="success" className="button-save"
                                            disabled
                                        >
                                            <Row>
                                                <Col sm="8">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="4">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row>
                                        </Button>
                                        :
                                        <Button className="button-save" style={{ float: "right" }} onClick={handleCouncilAndLandRowsSubmit} color="success" size="sm">
                                            Save
                                        </Button>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card className="my-4">
                <CardHeader className="card-header card-header-dark d-flex justify-content-between align-items-center">
                    <h5>
                        Other Regulatory Requirements
                    </h5>
                </CardHeader>
                <br />
                <CardBody>
                    <Row style={{display:"none"}}>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">NZ business number</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="text" value={regulatoryRequirementDetails.nzBusinessNumber} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, nzBusinessNumber: e.target.value })} name="nzBusinessNumber" id="nzBusinessNumber" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">INFDP Unique Freshwater Farm Plan ID</Label>
                                <p>(This will be issued upon certification of the plan)</p>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="text" value={regulatoryRequirementDetails.infdpUniqueFreshwaterFarmPlanId} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, infdpUniqueFreshwaterFarmPlanId: e.target.value })} name="infdpUniqueFreshwaterFarmPlanId" id="infdpUniqueFreshwaterFarmPlanId" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <FaInfoCircle data-tooltip-id="my-tooltip-10" />
                                <Label className="label" for="name">Are you using this Fresh Water Farm plan to meet any other regulatory requirements</Label>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    id="my-tooltip-10"
                                    place="bottom"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>Other regulatory requirements could be from central government or council such as regional plans, national environmental standards, water conservation orders etc. If you indicate yes, detail will be required. </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="select" value={regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, FWFPToMeetAnyOtherRegulatoryRequirement: e.target.value })} name="FWFPToMeetAnyOtherRegulatoryRequirement" id="FWFPToMeetAnyOtherRegulatoryRequirement">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ display: "none" }}>
                        <Col sm="6">
                            <FormGroup>
                                <span className="label-note">Detail any regulatory requirements which this Fresh Water Farm Plan (when certified) will also meet. Examples include Regional Plan Rules, National Environmental Standards, or Regulations made under section 360 of the act</span>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="textarea" value={regulatoryRequirementDetails.RegulatoryRequirementDetails} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, RegulatoryRequirementDetails: e.target.value })} name="RegulatoryRequirementDetails" id="RegulatoryRequirementDetails" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement == "Yes" && <>
                            <Row>
                                <Col>
                                    {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                        <>
                                            <button onClick={handleRegulatoryRequirementRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                Add
                                            </button>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>

                                    <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                        <thead>
                                            <tr>
                                                <th className="text-center"> Regulatory Requirement </th>
                                                <th className="text-center"> Condition </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {regulatoryRequirementsRows != undefined && regulatoryRequirementsRows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td style={{ display: "none" }}>{regulatoryRequirementsRows[idx].id}</td>
                                                    <td style={{ width: "25%" }}>
                                                        <input className="form-control" type="text" value={regulatoryRequirementsRows[idx].regulatoryRequirement} maxLength="1000" onChange={handleRegulatoryRequirementChange(idx)} name="regulatoryRequirement" id="regulatoryRequirement" autoComplete="new-password" />
                                                    </td>
                                                    <td style={{ width: "25%" }}>
                                                        <input className="form-control" type="text" value={regulatoryRequirementsRows[idx].condition} maxLength="1000" onChange={handleRegulatoryRequirementConditionChange(idx)} name="condition" id="condition" autoComplete="new-password" />
                                                    </td>
                                                    <td style={{ width: "5%" }}>
                                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                            <>
                                                                <button style={{ float: "right" }} onClick={handleRegulatoryRequirementRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">
                                                                    <FaTrash />
                                                                </button>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </Col>
                            </Row>
                        </>
                    }
                    <Row style={{ display:"none"}}>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="label" for="name">Are there any new phsyical works being planned for the property?</Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Input type="select" value={regulatoryRequirementDetails.IsAnyNewPhsyicalWorks} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, IsAnyNewPhsyicalWorks: e.target.value })} name="IsAnyNewPhsyicalWorks" id="IsAnyNewPhsyicalWorks">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        1 == 2 && regulatoryRequirementDetails.IsAnyNewPhsyicalWorks == "Yes" && <>
                            <Row>
                                <Col>
                                    <span className="label-note"> Please detail below and attach relevant document if needed.</span>
                                    <FormGroup>
                                        <Input type="textarea" value={regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, AnyNewPhsyicalWorksDetails: e.target.value })} name="AnyNewPhsyicalWorksDetails" id="AnyNewPhsyicalWorksDetails" />
                                    </FormGroup>

                                    <div>
                                        <Row>
                                            <Col>
                                                {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                    <>
                                                        <button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                            Add
                                                        </button>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col sm="12">
                                                <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center"> Date</th>
                                                            <th className="text-center"> Details </th>
                                                            <th className="text-center"> Attachment </th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {documentUploadList.map((item, idx) => (
                                                            <tr id="records" key={idx}>
                                                                <td style={{ display: "none" }}>{documentUploadList[idx].id}</td>
                                                                <td style={{ width: "15%" }}>
                                                                    <DatePicker selected={documentUploadList[idx].uploadDate != null ? getDateFromString(moment(documentUploadList[idx].uploadDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleDateChange(idx, date)} name="uploadDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                                                </td>
                                                                <td style={{ width: "45%" }}>
                                                                    <input type="textarea" name="documentDetail" value={documentUploadList[idx].documentDetail} onChange={handleDetailsChange(idx)} className="form-control" />
                                                                </td>
                                                                <td style={{ width: "15%" }}>
                                                                    {documentUploadList[idx].documentRelativePath != null && documentUploadList[idx].documentRelativePath != '' ?
                                                                        <Row>
                                                                            <Col>
                                                                                <a href={documentUploadList[idx].documentRelativePath} target='_blank'>
                                                                                    <img src="/images/PDF_icon.png" className="PDF_icon" ></img>
                                                                                </a>
                                                                            </Col>
                                                                            <Col>
                                                                                <Button style={{ float: `right` }} color="warning" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                                            </Col>
                                                                        </Row>
                                                                        :
                                                                        <Button style={{ float: `right` }} color="success" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                                    }
                                                                    <br />
                                                                    <input style={{ float: `right`, display: 'none' }}
                                                                        ref={input => {
                                                                            inputFile[idx] = input;
                                                                        }}
                                                                        type="file" name="attachment" className="form-control" onChange={(e) => handleRecordChange(e, idx)} />
                                                                    {formErrors.length > 0 && formErrors[idx] != undefined && formErrors[idx].fileError != undefined &&
                                                                        <p className="errorMessage">{formErrors[idx].fileError}</p>
                                                                    }
                                                                </td>
                                                                <td style={{ width: "5%" }}>
                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                        <>
                                                                            <button style={{ float: "right" }} onClick={handleRecordRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">
                                                                                <FaTrash />
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col>

                            </Row>

                        </>
                    }
                    <Row>
                        <Col>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    {savingConsents ?
                                        <Button style={{ float: "right" }} onClick={handleConsentsSubmit}
                                            color="success" className="button-save"
                                            disabled
                                        >
                                            <Row>
                                                <Col sm="8">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="4">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row>
                                        </Button>
                                        :
                                        <Button className="button-save" style={{ float: "right" }} onClick={handleConsentsSubmit} color="success" size="sm">
                                            Save
                                        </Button>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>

    )
}

export default CouncilAndLandInfo;