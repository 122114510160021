import React, { useRef, useState, useEffect } from 'react';
import useAuth from '../Hooks/useAuth';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, Spinner } from 'reactstrap';
import './Login.css';
import toast, { Toaster } from 'react-hot-toast';
import SpinnerContainer from '../../Utilities/Ui/SpinnerContainer'
import MyBackgroundImage from '../../../images/login_bg.jpg';
const ResetPassword = () => {
    const { setAuth } = useAuth(); const [loading, setLoading] = useState(true); const [saving, setSaving] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [email, setEmail] = useState('');
    const [loginError, setLoginError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        const errors = {};
        if (email == null || email == "") {
            errors.email = "Email can not be blank!";
        }
        setFormDocumentErrors(errors);
        var Email = email;
        if (Object.keys(errors).length == 0) {
            setSaving(true);
            const response = await axios.post(
                '/api/auth/SendResetPasswordEmail/', JSON.stringify(
                    {
                        Email
                    }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (response.data.status) {
                setSaving(false);
                if (response.data.message == 'Success') {
                    toast.success("Please check your email you should receive an email with instruction on how to reset your password!", {
                        duration: 4000, position: 'top-center', icon: '👏', iconTheme: {
                            primary: '#000',
                            secondary: '#fff',
                        },
                    });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 4000);
                }
                else {
                    toast.error("Please check your mailbox. An email has been sent with instructions on how to reset your password", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                }
            }
            else {
                toast.error("Error while updating reason", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }
    useEffect(() => {
        setLoading(false);
    }, [])
    return (

        <div className="login-flex" style={{
            backgroundImage: `url(${MyBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '110vh'
        }} >
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            {
                loading ? <SpinnerContainer /> :
                    <div className="login-container">
                        <Card className="resetpassword-card">
                            <CardBody>
                                <div className="login-logo">
                                    <img className="login-logo" src="/images/login-zespri-logo.png"></img>
                                </div>
                                <Form onSubmit={handleSubmit} className="login-form">
                                    <h2>Reset Password</h2><br />
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="username">
                                                    Enter your Email
                                                </Label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    autoComplete="new-password"
                                                    placeholder="Email"
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </FormGroup>
                                            {formDocumentErrors.email != undefined &&
                                                <p className="errorMessage">{formDocumentErrors.email}</p>
                                            }
                                        </Col>
                                    </Row>
                                    {saving ?
                                        <Button onClick={handleSubmit}
                                            className="btn-login btn-grad"
                                            disabled
                                        >
                                            <Spinner size="sm">
                                                Reseting...
                                            </Spinner>
                                            <span>
                                                {' '}Reseting
                                            </span>
                                        </Button>
                                        :
                                        <Button onClick={handleSubmit} className="btn-login btn-grad" size="sm">
                                            Reset
                                        </Button>
                                    }
                                    {/*<Button className="btn-login btn-grad">*/}
                                    {/*    Reset*/}
                                    {/*</Button>*/}
                                    <div className="login-error mt-3">
                                        {loginError}
                                    </div>
                                    <Row style={{textAlign:"center"}}>
                                        <Col>
                                            <u><a href="/login">Return to Login</a></u>
                                        </Col>
                                    </Row>
                                </Form>

                            </CardBody>
                        </Card>
                    </div>
            }
            
        </div>
    );
}

export default ResetPassword;