import React, {  useEffect, useState } from 'react';
import axios from 'axios';
import useAuth from "../components/Utilities/Hooks/useAuth";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';

const Home = () => {

    const [isOpen1, setIsOpen1] = useState(true);
    const [isIconUp1, setIsIconUp1] = useState(true);
    const toggle1 = () => {
        setIsOpen1(!isOpen1)
        setIsIconUp1(!isIconUp1)
    };
    const [isOpen2, setIsOpen2] = useState(true);
    const [isIconUp2, setIsIconUp2] = useState(true);
    const toggle2 = () => {
        setIsOpen2(!isOpen2)
        setIsIconUp2(!isIconUp2)
    };
    const { auth, setAuth } = useAuth();
    const [modalVisible, setModalVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [saved, setSaved] = useState(false);
    const [tcAccepted, settcAccepted] = useState(false);
    const [useOfMyPersonalInfoAccepted, setUseOfMyPersonalInfoAccepted] = useState(false);
    const [tcChangedSaved, settcChangedSaved] = useState(tcAccepted);
    const [useOfMyPersonalInfoChangedSaved, setUseOfMyPersonalInfoChangedSaved] = useState(useOfMyPersonalInfoAccepted);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const toggle = () => setModalVisible(!modalVisible);
    const [isIconUp, setIsIconUp] = useState(false); //useState(expand);
    const [fwfpInfoList, setFWFPInfoList] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [userinfo, setUserInfo] = useState({});
    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/settings/GetUsers/', {
                params: {}
            }
            );
            if (response.data != null) {
                var user = response.data.content.users.filter(x => x.id == userId);
                setUserInfo(user[0]);
                settcAccepted(user[0].tcAccepted);
                settcChangedSaved(user[0].tcAccepted)
                setUseOfMyPersonalInfoChangedSaved(user[0].useOfMyPersonalInfoAccepted)
                setSaved(user[0].tcAccepted && user[0].useOfMyPersonalInfoAccepted);
            }
        } catch (err) {
            console.log(err);
        }    
    }
    const getFWFPInfo = async (e) => {
        try {
            const response = await axios.get(
                '/api/settings/GetAllFWFPInfo/', {
                params: {}
            }
            );
            if (response.data != null) {
                var fwfpList = response.data.content.fwfpInfoModel;
                setFWFPInfoList(fwfpList)

            }
        } catch (err) {
            console.log(err);
        }
    }
    const toggleInfo = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const openModal = (e) => {
        e.preventDefault()
        setModalVisible(true);
    };
    const handleAccepted = () => {
        const newAccepted = !tcAccepted;
        settcAccepted(newAccepted);
    }
    const handleUseOfMyPersonalInfoAccepted = () => {
        const newAccepted = !useOfMyPersonalInfoAccepted;
        setUseOfMyPersonalInfoAccepted(newAccepted);
    }
    const closeModal = () => {
        setModalVisible(false);
        settcAccepted(tcChangedSaved);
    };
    useEffect(() => {
        OnPageload();
    }, [])
    useEffect(() => {
        validateToken();
    }, [])
    useEffect(() => {
        getFWFPInfo();
    }, [])
    const checkUserFarms = async () => {
        if (userinfo.userFarmList.length > 0) {
            window.open("/outline", "_self");
        }
        else {
            toast.error("Sorry, this user does not have any Orchard allocated to access. Please contact Zespri team", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
    }
    const handleSubmit = async () => {
        const response = await axios.get(
            '/api/settings/UpdateUser/', {
            params: { Id: userId, TC: tcAccepted, UseOfMyPersonalInfoAccepted: useOfMyPersonalInfoAccepted }
        }
        );
        if (response.data != null) {
        }
        toggle();
        settcChangedSaved(tcAccepted);
        setUseOfMyPersonalInfoChangedSaved(useOfMyPersonalInfoAccepted);
        setSaved(tcAccepted && useOfMyPersonalInfoAccepted);
    }
    const validateToken = async () => {
        if (auth != null) {
            let form = {
                Token: auth.token
            }
            const response = await axios.post(
                '/api/Token/Validate/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (!response.data) {
                window.open("/login", "_self");
            }
        }
        else {
            window.open("/login", "_self");
        }
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row style={{ background:"#B6D137"}}>
                <Col>
                    <h3 style={{ textAlign: `left`,color:"white" }} className="video-title">WELCOME ABOARD</h3>
                </Col>
            </Row>
            <Row style={{ width: `100%`, margin: `2%` }}>
                <Col sm="6">
                    <video style={{ width: `100%` }} className="video-player" id="videoControl" controls="controls" muted="muted" autoPlay="autoplay">
                        <source className="video-source" src="/videos/Zespri Kiwifruit. Taste the Obsession _Grower Anthem_.mp4" type="video/webm" />
                    </video>
                </Col>
                <Col sm="1">
                </Col>
                <Col sm="5">
                    <Row>
                        <Col xs="12">
                            <Card className="my-4" style={{ marginRight: "40px" }}>
                                <CardHeader className="align-items-center settings-card-header" style={{ cursor: "pointer" }} onClick={toggle1}>
                                    <Row >
                                        <Col sm="11"><span className="text-uppercase fw-bold color-dark-teal">WHAT TO EXPECT</span></Col>
                                        <Col sm="1" className="toggleoutline">{isIconUp1 ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                    </Row>
                                </CardHeader>
                                <Collapse isOpen={isOpen1}>
                                    <CardBody style={{ height: '250px', overflowY: 'auto', margin: '10px' }} >
                                        <Row>
                                            <Col>
                                                <label className="outlineLabel para-light-sm ">Build a Freshwater Farm Plan using the intuitive step-by-step wizard through each stage</label>
                                                <p><span style={{ fontSize: "9px",marginLeft:"15px" }}>{'\u2B24'}</span>&nbsp;&nbsp;&nbsp;Help identify practical actions to help improve local waterways and overall water quality</p>
                                                <p><span style={{ fontSize: "9px",marginLeft:"15px" }}>{'\u2B24'}</span>&nbsp;&nbsp;&nbsp;Identify areas of risk to fresh water and meeting compliance requirements</p>
                                                <p><span style={{ fontSize: "9px",marginLeft:"15px" }}>{'\u2B24'}</span>&nbsp;&nbsp;&nbsp;Create actions and mitigations and record completed actions</p>
                                                <p><span style={{ fontSize: "9px",marginLeft:"15px" }}>{'\u2B24'}</span>&nbsp;&nbsp;&nbsp;Print and share the plan</p>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                        <Col xs="12">
                            <Card className="my-4" style={{ marginRight: "40px" }}>
                                <CardHeader className="align-items-center settings-card-header" style={{ cursor: "pointer" }} onClick={toggle2}>
                                    <Row >
                                        <Col sm="11"><span className="text-uppercase fw-bold color-dark-teal">WHERE TO GET HELP</span></Col>
                                        <Col sm="1" className="toggleoutline">{isIconUp2 ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                    </Row>
                                </CardHeader>
                                <Collapse isOpen={isOpen2}>
                                    <CardBody style={{ height: '250px', overflowY: 'auto', margin: '10px' }} >
                                        <Row>
                                            <Col>
                                                <h5 style={{ textAlign: `center`, color: "#008436" }}>ZESPRI Environmental Team</h5>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col sm="4">
                                                <Row>
                                                    <Col>
                                                        <h7>Jolene Francis</h7>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{fontSize:"13px"}}>M: +64 (0)27 203 9913</h8>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{ fontSize: "13px" }}>E: <a href="mailto:jolene.francis@zespri.com">jolene.francis@zespri.com</a></h8>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="4">
                                                <Row>
                                                    <Col>
                                                        <h7>Christina Robinson</h7>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{ fontSize: "13px" }}>M: +64 (0)27 203 9913</h8>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{ fontSize: "13px" }}>E: <a href="mailto:christina.robinson@zespri.com">christina.robinson@zespri.com</a></h8>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="4">
                                                <Row>
                                                    <Col>
                                                        <h7>Jayson Benge</h7>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{ fontSize: "13px" }}>M: +64 (0)27 203 9913</h8>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h8 style={{ fontSize: "13px" }}>E: <a href="mailto:jayson.benge@zespri.com">jayson.benge@zespri.com</a></h8>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                        <Col sm="12">
                            <Row>
                                <Col sm="6">
                                    {tcChangedSaved && <label className="outlineLabel para-light-sm ">{userinfo.firstName} {userinfo.surname} accepted Terms and Conditions on {userinfo.tcAcceptedDateText} - <a href='javascript:void(0);' onClick={openModal}>click here to review</a></label>}
                                    {!tcChangedSaved && <label className="outlineLabel para-light-sm ">Please review and accept the Terms and Conditions before continuing</label>}
                                </Col>
                                <Col sm="4">
                                    {!tcChangedSaved && <>
                                        <Button className="btn btn-risk-summary btn btn-secondary" style={{ width: `100%`, marginLeft: `25%`, marginTop: `10px` }} onClick={openModal} >Review Terms and Conditions</Button>
                                    </>}
                                    {tcChangedSaved && <>
                                        <Button className="btn-grad btn-grad-link" style={{ width: `100%`, marginLeft: `25%`, marginTop: `10px` }} onClick={checkUserFarms} >Get Started Now</Button>
                                    </>}
                                </Col>
                            </Row>
                            {/*<h3>TERMS AND CONDITIONS</h3>*/}
                            {/*<a href onClick={openModal} style={{ cursor: "pointer" }}>*/}
                            {/*    Click here for terms and conditions*/}
                            {/*</a>*/}
                            
                            <Modal contentClassName="" size="lg" style={{ maxWidth: '1250px', width: '100%' }} isOpen={modalVisible} toggle={ toggle}>
                                <ModalHeader toggle={toggle}>Terms and Conditions</ModalHeader>
                                <ModalBody>
                                    <div style={{ maxHeight: "75vh", overflowY: "auto",overflowX:"hidden" }}>
                                        <Row>
                                            <Col>
                                                <h1 style={{ color: "#008436" }}>TERMS AND CONDITIONS</h1>
                                                <p>Your use of Zespri Pilot System (the System) is subject to the following terms and all applicable laws. If you do not agree with any of the terms, please do not use this System. By acknowledging that you have read and understand these terms and conditions when you first log in to the System you are deemed to have accepted the Terms and Conditions.</p>

                                                <h4 style={{ color: "#B6D137" }}>1. Use of the System</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>1.1</span> The System is provided for your legitimate personal and business purposes and information only. Except as otherwise expressly authorised in writing in advance by Zespri, you agree not to reproduce, modify, rent, lease, loan, sell, distribute, adapt, translate, create derivative works based (whether in whole or in part) on, reverse engineer, decompile or disassemble any services or software, all or any part of the System.</p>

                                                <h4 style={{ color: "#B6D137" }}>2. Security</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>2.1</span> Access to the System requires users to enter a username and password. Passwords must not contain personal information or include obvious or sequential numbers. You must keep your password secure and must not write it down, store it on your computer or disclose it to any other person. You are solely responsible for the security of your user name and password, including the computer you use to access the System. Zespri disclaims any responsibility or liability for the security of your password or for any access to the System resulting from the unauthorised use of your password.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>2.2</span> For security reasons Zespri may require you to re‐authenticate yourself from time to time including without limitation after a period of inactivity on the connection between your browser and Zespri’s servers.  Zespri is not responsible for any information which is lost or unavailable as a result of your unexpected termination of your session.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>2.3</span> No transmission of data over the Internet is completely secure. It may be possible for third parties to intercept or access transmissions or private communications unlawfully. While Zespri will use best endeavours to protect and keep secure your personal information while stored on its computer system, Zespri cannot ensure or warrant the security of any information you transmit or download. Any such transmission or downloading is not secure and is done at your own risk.</p>

                                                <h4 style={{ color: "#B6D137" }}>3. Information on the System</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>3.1</span> All material on the System, including without limitation, text, images, graphics, layout, look‐and‐feel and any other information contained on or in the System (collectively, “Content”) is subject to copyright and other proprietary rights.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>3.2</span> The System and all Content are provided “as is” without warranty of any kind, whether express or implied. All implied warranties, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, and non‐infringement, are hereby expressly disclaimed. Zespri does not represent or warrant that Content or materials accessed from or through the System will be uninterrupted or free of errors, defects, viruses or other harmful components, or that any such problems which are discovered will be corrected. Zespri does not warrant or make any representations regarding the use of Content (or results of such use) in terms of correctness, accuracy, timeliness, reliability, completeness or otherwise. Zespri reserves the right to correct, update, supplement or alter the information contained on the System at any time without prior notice.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>3.3</span> Decision support tools, applications and calculators within the System are indicative only and Zespri disclaims any liability for the accuracy, completeness or validity of such tools, together with any results, outcomes or any decisions based on the use of such tools.  All decisions are made at your own risk. Information produced by the tools and the methods of calculations may change without notice.  Figures used by these tools, including financial, growth and aggregate data are based on figures current only to the dates specified on the relevant pages and may be averages or aggregated data which differs from the circumstances of your farm.  If you provide information produced by the tools to any other person, you agree to give that person notice of the disclaimer in those paragraphs.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>3.4</span> Zespri reserves the right to monitor and collect any and all information transmitted or received through the System for operational and other purposes at any time, and to use any such information collected for its internal business purposes (which shall include the right to disclose any such information to third parties provided it is in an anonymized and aggregated form) at all times in accordance with the Privacy Statement. You agree that you will not (nor permit any other person to) use the System, including the information provided on it, in any way which (i) breaches any laws, regulations, standards or codes, or infringes a third party's rights or privacy; or (ii) introduces or propagates viruses, spyware/bots, worms, trojan horses or any other disruptive or disabling feature. You agree not to test the vulnerability of the System or any network attached thereto, not to use the System to gain unauthorised access to other computer systems or networks connected to the System or tamper with, hinder the operation of, or make unauthorised modifications to, the System.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>3.5</span> In the unlikely event that an unauthorised person makes changes to the System, Zespri does not accept responsibility for those changes.</p>

                                                <h4 style={{ color: "#B6D137" }}>4. Cookies</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>4.1</span> The System uses "cookies" to collect information which is used to measure activity on the System and to make improvements and updates based on the popularity of each area. This information is not used to identify individual System users. If your browser is set to refuse cookies or to alert you when cookies are being sent, some parts of the System may not function properly. </p>

                                                <h4 style={{ color: "#B6D137" }}>5. Links</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>5.1</span> The System contains links to third‐party websites, which are provided for informational purposes only and are not guaranteed to be accurate or up to date. No linked website is endorsed by Zespri and no responsibility is taken by Zespri for material, content or information accessed through any linked site. Linked sites have their own terms and conditions which you should review, and access to linked sites is done at your risk.</p>

                                                <h4 style={{ color: "#B6D137" }}>6. Intellectual Property</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>6.1</span> Copyright and trademarks in all materials displayed or available on the System are the property of Zespri or their respective owners. All rights to the System, including rights in text, design, graphics, the selection and arrangement are reserved and no right or licence to any intellectual property is granted or implied, as a consequence of the publication of any material on the System. Where the intellectual property of third parties has been used, including trademarks and logos, Zespri has obtained consent from these third parties for the use of their intellectual property on the System.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>6.2</span> Notwithstanding 6.1 above, you are permitted to use any outputs generated through your use of the System for the purpose of creating your Freshwater Farm Plan, and to share and disclose such outputs to third parties as reasonably required.</p>

                                                <h4 style={{ color: "#B6D137" }}>7. Limitation of Liability</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>7.1</span> Use of the System is at your own risk. Subject to any terms implied by law which cannot be excluded, Zespri shall not be responsible or liable, in contract, tort (including negligence), equity or otherwise for damages of any kind, including (without limitation) compensatory, direct, indirect, incidental, special, consequential or punitive damages, loss of data, income, savings or profit, loss of or damage to property, and claims of third parties, arising out of, or in connection with, your access to, or use of, the System or any linked websites, or any Content.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>7.2</span> Zespri makes no  warranty  that  the  System  will  meet  your  requirements,  that  the  System  will  be uninterrupted, timely, secure, or error free. You acknowledge and agree that any information or material obtained through the System is obtained at your discretion and risk.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>7.3</span> All warranties, whether express or implied, as to the accuracy, completeness, currency, fitness for purpose or usefulness of the information contained on the System (including that provided by calculators and/or any decision making tools available on the System) are excluded to the extent permitted by law.</p>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>7.4</span> You  agree  to  defend,  indemnify  and  hold  harmless  Zespri,  its  related  parties  and  their  respective directors, officers, employees and agents from and against any and all claims, actions, suits or proceedings (including those of third parties), as well as any and all losses, liabilities, damages, costs and expenses (including reasonable solicitors fees) arising out of or accruing from: (a) your use of the System, its information (including without limitation the Reports), tools or any other linked website; (b) any material posted or otherwise provided by you that infringes any copyright, trademark, trade secret, patent or other intellectual property right of any person or defames any person or violates their rights of privacy; and (c) any non‐compliance by you with these Terms and Conditions.</p>

                                                <h4 style={{ color: "#B6D137" }}>8. Restriction of Access</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>8.1</span> Zespri may, in its sole discretion, restrict or prevent access to, or use of, the System (or parts of the System) without prior notice and for any reason. Restriction or prevention of access to or use of the System shall not affect any right or relief to which Zespri may be entitled, at law or in equity.</p>

                                                <h4 style={{ color: "#B6D137" }}>9. Monitoring</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>9.1</span> Zespri systems are subject to logging and monitoring of system activities for information assurance purposes, to ensure compliance with legal requirements and to maintain cyber security and integrity of Zespri’s information systems and data. Monitoring of information and data stored on or transmitted via Zespri’s systems may be performed by authorised third parties.</p>

                                                <h4 style={{ color: "#B6D137" }}>10. General</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>10.1</span> The System, Content and these Terms and Conditions are governed by the laws of New Zealand, and you irrevocably submit to the non‐exclusive jurisdiction of the courts of New Zealand in determining any dispute. If you use the System from outside New Zealand you are responsible for complying with any applicable laws (whether in your country of residence or New Zealand). Any Terms and Conditions that are either invalid or unenforceable will be struck out and the remaining Terms and Conditions will remain in force. Any failure by Zespri to act in relation to a breach of these Terms and Conditions does not waive Zespri’s right to act with respect to subsequent or similar breaches.</p>

                                                <h4 style={{ color: "#B6D137" }}>11. Privacy Notice</h4>
                                                <p><span style={{ color: "#B6D137", fontSize: "1.25rem" }}>11.1</span> By signing into the Zespri Freshwater Farm Plan Tool (the System), you agree to Zespri collecting your personal information and using it as outlined in this notice and our Privacy Statement. The information collected through the System will be used by Zespri to facilitate the Pilot, assess the viability of the Platform and for internal reporting purposes. Your information will be held and managed in the QCONZ system and access will be limited to Zespri employees and agents as required. The results of the Pilot may be shared externally in an anonymized format only. We will store your personal information throughout the Pilot and as long as necessary to provide you with the Platform following the completion of the Pilot.</p>
                                                <p>You have rights over the information Zespri holds about you. You are entitled to ask for a copy of the personal information we hold about you, and to ask us to correct it if it's wrong. For information about your privacy rights, please see our Privacy Statement. To make a complaint or to exercise your privacy rights, contact our Global Privacy Team at privacy@zespri.com. You are also entitled to complain to the relevant data protection authority.</p>
                                                <p>Zespri International Limited – November 2023</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    {!tcChangedSaved && <>
                                        <label htmlFor="acceptCheckbox">
                                            <input
                                                type="checkbox"
                                                id="acceptCheckbox"
                                                checked={tcAccepted}
                                                onChange={handleAccepted}

                                            />&nbsp;&nbsp;
                                            I accept the terms and conditions
                                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>}
                                    {!useOfMyPersonalInfoChangedSaved && <>
                                        <label htmlFor="acceptCheckbox">
                                            <input
                                                type="checkbox"
                                                id="acceptCheckbox"
                                                checked={useOfMyPersonalInfoAccepted}
                                                onChange={handleUseOfMyPersonalInfoAccepted}
                                            />&nbsp;&nbsp;
                                            I agree to the use of my personal information as outlined in the above notice and Privacy Statement. 
                                        </label>
                                    </>}
                                    {
                                        !saved && tcAccepted && useOfMyPersonalInfoAccepted && <Button color="primary" onClick={handleSubmit} >Save</Button>
                                    }
                                    <Button color="secondary" onClick={closeModal}>Close</Button>
                                </ModalFooter>
                            </Modal>
                            
                        </Col>

                    </Row>
                </Col>
            </Row>
            
        </div>

    );
}
export default Home
