import { useState } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';

const ItemTypeListViewForMap = ({ Section, itemTypesList, toggleItemTypeListState, handleClearSelection, handleItemClick, handleItemTypeListTogole, isWizardCompleted }) => {

    const [isOpen, setIsOpen] = useState(!isWizardCompleted);
    const [isIconUp, setIsIconUp] = useState(!isWizardCompleted);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleItemTypeListTogole(!isOpen, !isIconUp);
    };
    return (
        <Card className="card-header-outline" >
            <CardHeader className="header card-header-click" onClick={toggle}>
                <Row className="itemTypeList-header-div">
                    {Section == 'Community & Cultural Significance' &&
                        <Col sm="12"><Row> <span className="text-uppercase fw-bold color-dark-teal">Location and details of Sites and Species of Significance</span></Row>
                            <Row><label className="itemTypeList-header-subtext">Click to expand and show items for adding to map</label></Row> 
                            </Col>    
                    }
                    {
                        Section != 'Community & Cultural Significance' &&
                        <Col sm="12"><Row> <span className="text-uppercase fw-bold color-dark-teal">{Section}</span></Row>
                                <Row><label className="itemTypeList-header-subtext">Click to expand and show items for adding to map</label></Row> 
                        </Col>    
                    }
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                <Row>
                                    <Col>
                                        <label className="outlineLabel para-light-sm ">Click an icon then click on the map to add items</label>
                                        <Button className="button-common float-end" color="warning" size="sm" onClick={handleClearSelection}>
                                            Clear Selection
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        {Section == 'Irrigation Frost Protection' &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="12" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                />
                                                                {type.name == 'Irrigation Area' && type.markerType == 'circle' &&
                                                                    <label className="itemTypeStyle"> {type.name} (circle)</label>
                                                                }
                                                                {type.name == 'Irrigation Area' && type.markerType == 'rectangle' &&
                                                                    <label className="itemTypeStyle"> {type.name} (rectangle)</label>
                                                                }
                                                                {type.name == 'Irrigation Area' && type.markerType == 'polygon' &&
                                                                    <label className="itemTypeStyle"> {type.name} (freehand)</label>
                                                                }
                                                                {type.name != 'Irrigation Area' &&
                                                                    <label className="itemTypeStyle"> {type.name}</label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        {Section != 'Irrigation Frost Protection' &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="12" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                /> <label className="itemTypeStyle"> {type.name}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    )
}
export default ItemTypeListViewForMap