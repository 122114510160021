import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input, Label, Spinner } from 'reactstrap';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './Agrichemicals.css';
import { useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import ItemCommonTop from '../ItemPopup/ItemCommonTop';
import ItemCommonBottom from '../ItemPopup/ItemCommonBottom';
import ItemFooter from '../ItemPopup/ItemFooter';
import Action from '../Action/Action';
import MapView from '../Map/MapView';
import NonMapView from '../ItemsView/NonMapView';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';
import AgrichemicalsItemSpecView from './AgrichemicalsItemSpecView'
import Validate from '../Validate/Validate';
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import { useNavigate, useLocation } from "react-router-dom";
import RiskSummary from '../ItemsView/RiskSummary';
import ItemTypeListViewForMap from '../ItemsView/ItemTypeListViewForMap';
import Draggable, { DraggableCore } from 'react-draggable';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Agrichemicals = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName"); const { height, width } = useWindowDimensions(); const [isThisPageCompleted, setIsThisPageCompleted] = useState(false); const [actionsCount, setActionsCount] = useState(0);
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [searchParams, setSearchParams] = useSearchParams(); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const { state } = useLocation(); const [saving, setSaving] = useState(''); const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const navigate = useNavigate(); const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"))
    const [loading, setLoading] = useState(true);
    const [agrichemicals, setAgrichemicals] = useState({
        outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], itemFieldTypes: [], lookUpList: [], Section: 'Agrichemicals', SectionName: 'Agrichemicals', SectionObj: {}, isWizardCompleted: false,
        outlineCenter: {}, farmCenterTypeModel: {}, outlineItemTypes: []
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0, isSmallEnterpriseShow: false })
    const [typeid, setTypeId] = useState(0);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 5, polyType: '', itemsEditable: false, highlighteditemId: 0, isClear: false, showLabel: false
    });
    const [riskLookupList, setRiskLookupList] = useState({ enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
    const [itemPopup, setItemPopup] = useState({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
    const [itemPopupHeader, setItemPopupHeader] = useState({
        page: 'Agrichemicals', lmu: '', LMUId: 0, itemTypeName: '',
        itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", isEdited: false, itemTypeDescription: '', watertakeType: 0, watertakeTypeList: [], LMUIds: ''
    });
    const [itemPopupMaptype, setItemPopupMaptype] = useState({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', });
    const [itemPopupBiodiversity, setItemPopupBiodiversity] = useState({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
    const [itemPopupRisk, setItemPopupRisk] = useState({
        isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '',
        showEnterprise: false
    });
    const [itemPopupAchievements, setItemPopupAchievements] = useState({ isAchievementsNotes: false, achievementsComment: '', isExpand :false });

    const [itemPopupItemFeild, setItemPopupItemFeild] = useState({
        itemTypeId: 0,
        itemFieldTypes: []
    });
    const [itemPopupItemSepcQ, setItemPopupItemSepcQ] = useState({
        BeltHeight: 0,
        BeltThickness: 0,
        BufferWidth: 0,
        Areaperimeterbuffered: 0,
        PredominantSpecies: 0,
        ApproxAgeOfShelter: 0,
        PredominantSpeciesOther: '',
        ShowPredominantSpeciesOther:false
    });

    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0 });
    const [actionPopup, setActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [wholeFarmActionPopup, setWholeFarmActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [toggleItemTypeListState, setToggleItemTypeListState] = useState({ isOpen: false, isIconUp: false });
    const [toggleItemTypeState, setToggleItemTypeState] = useState({itemTypeId : 0, isOpen: false, isIconUp: false });
    const [toggleItemState, setToggleItemState] = useState({ itemId: 0, isOpen: false, isIconUp: false });
    const [riskSummaryPopup, setRiskSummaryPopup] = useState({ showriskSummary :false});
    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/agrichemicals/GetAgrichemicals/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setAgrichemicals({
                ...agrichemicals, outlineList: response.data.outlineList, itemList: response.data.itemList,
                itemTypes: response.data.itemTypes, uniqueItemTypes: response.data.uniqueItemTypes, itemFieldTypes: response.data.itemFeildList, lookUpList: response.data.lookUpList, SectionObj: response.data.section,
                isWizardCompleted: response.data.isFWFPCompleted, outlineCenter: response.data.outlineCenter, farmCenterTypeModel: response.data.farmCenterTypeModel
                , outlineItemTypes: response.data.outlineItemTypes
            });
            if (response.data.wizard.isAgrichemicalsSaved) {
                setIsThisPageCompleted(true);
            }
            else {
                setIsThisPageCompleted(false);
            }
            setActionsCount(response.data.actionCount);
            setRiskLookupList({
                ...riskLookupList, enterpriseTypes: response.data.riskModel.enterpriseTypes, luenterpriseTypes: [], contaminants: response.data.riskModel.contaminants, riskDataGroups: response.data.riskModel.riskDataGroups
                , activityGroups: response.data.riskModel.activityGroups, activitySubGroups: [], tempActivitySubGroups: response.data.riskModel.activitySubGroups, LUEnterpriseTypes: response.data.riskModel.luEnterpriseTypeList
            })
            setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: !response.data.isFWFPCompleted, isIconUp: !response.data.isFWFPCompleted })
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel, polyType: ''  });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise, LMUList: response.data.lmuItemList })
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            setLoading(false);
            setItemPopupHeader({ ...itemPopupHeader, watertakeTypeList: response.data.lookUpList.watertakeTypeList, LMUIds: '' })

            if (state != null) {
                const { itemId, actionId, navigateFrom } = state;
                navigate(state.pathname, { replace: true });
                if (navigateFrom == "Actions") {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
                else {
                    editMapItemFromNavigation(itemId, response.data, navigateFrom) 
                }
            }
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let navigateFrom = params.get('navigateFrom');
            if (navigateFrom == 'Actions') {
                let actionId = params.get('actionId');
                let itemId = params.get('itemId');
                let isWholeFarmAction = params.get('isWholeFarmAction');
                if (searchParams.has('actionId')) {
                    searchParams.delete('actionId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('itemId')) {
                    searchParams.delete('itemId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('isWholeFarmAction')) {
                    searchParams.delete('isWholeFarmAction');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('navigateFrom')) {
                    searchParams.delete('navigateFrom');
                    setSearchParams(searchParams);
                }
                if (isWholeFarmAction == 'true') {
                    handleNavigateOpenWholeActionPopup(itemId, actionId, response.data);
                }
                else {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
            }


        } catch (err) {
            console.log(err);
        }
    }

    const refreshPage = () => {
        setLoading(true);
        setAgrichemicals({ ...agrichemicals, outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], itemFieldTypes: [], lookUpList: [] });
        setRiskLookupList({ ...riskLookupList, enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
        setFormErrors({});
        OnPageload();
    }

    const resetValues = () => {
        setItemPopup({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
        setItemPopupHeader({ page: 'Agrichemicals', lmu: '', itemTypeName: '', itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", isEdited: false, itemTypeDescription: '', watertakeType:0 });
        setItemPopupMaptype({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', });
        setItemPopupBiodiversity({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
        setItemPopupRisk({
            isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: ''
        });
        setItemPopupAchievements({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });
        setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
        setFormErrors({});
        setMapDetails({ ...mapDetails, isClear: true, polyType: '' });
    }

    /* Item select  and Clear*/
    const handleItemClick = (event, typeid, polyType) => {
        setTypeId(typeid);
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
        const itemFieldTypesList = agrichemicals.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == typeid);
        setItemPopupItemFeild({ ...itemPopupItemFeild, itemTypeId: typeid, itemFieldTypes: itemFieldTypesList });
    }

    const handleClearSelection = () => {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
        setItemPopupItemFeild({ ...itemPopupItemFeild, itemTypeId: 0, itemFieldTypes: [] });
    }

    const handleLMUChange = (event) => {
        var lus = '';
        let luArr = [];
        if (typeof event === 'object' && event.constructor === Object) {
            lus = event.value.toString();
        }
        else {
            event.forEach(lmu => {
                luArr.push(lmu.value);
            })
            lus = luArr.join(",");
        }
        setItemPopupHeader({ ...itemPopupHeader, LMUIds: lus });
    }

    /*ItemPopup value change Handle*/
    const handleItemPopupHeaderChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormErrors({});
        setItemPopupHeader({ ...itemPopupHeader, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemPopupBiodiversity = (event) => {
        const { name, value, checked, type } = event.target;
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemPopupRisk = (event) => {
        const { name, value, checked, type } = event.target;
        if (name == 'isRisk' && value == 'Yes') {
            setFormErrors({});
            const errors = {};
            if (itemPopupHeader.LMUId == 0) {
                errors.luid = "Please select LU";
                setFormErrors(errors);
            }
            else {
                setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else if (name == 'isRisk' && value == 'No')
        {
            setItemPopupRisk({ ...itemPopupRisk, isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '' });
        }
        else {
            setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
        }  
    }

    const handleMultiSelectEnterpriseChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, enterprises: e });
    }

    const handleMultiSelectContaminantChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, contaminants: e });
    }

    const handleMultiSelectRiskDataGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, riskDataGroups: e });
    }

    const handleMultiSelectActivityGroupChange = (e) => {

        setRiskLookupList({
            ...riskLookupList, activitySubGroups: []
        })
        var selectedActivitySubGroups = [];
        let myArrayFiltered = [];
        if (itemPopupRisk.activitySubGroups != undefined && itemPopupRisk.activitySubGroups.length > 0) {
            selectedActivitySubGroups = itemPopupRisk.activitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        else {
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        setRiskLookupList({
            ...riskLookupList, activitySubGroups: myArrayFiltered
        })
        setItemPopupRisk({ ...itemPopupRisk, activityGroups: e, activitySubGroups: selectedActivitySubGroups });
    }

    const handleMultiSelectActivitySubGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, activitySubGroups: e });
    }

    const handleItemPopupAchievements = (event) => {
        const { name, value, checked, type } = event.target;
        setItemPopupAchievements({ ...itemPopupAchievements, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemSepcValueChange = (event) => {
        const { name, value, checked, type } = event.target;
        var ShowPredominantSpeciesOther = false;
        if (name == 'PredominantSpecies') {
            var objList = agrichemicals.lookUpList.predominantSpeciesList.filter(x => x.id == parseInt(value));
            if (objList.length > 0) {
                var obj = objList[0];
                if (obj.name == 'Other native' || obj.name == 'Other exotic') {
                    ShowPredominantSpeciesOther = true;
                }
            }
            setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, [name]: type == 'checkbox' ? checked : value, ShowPredominantSpeciesOther, ShowPredominantSpeciesOther, PredominantSpeciesOther:'' });
        }
        else {
            setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, [name]: type == 'checkbox' ? checked : value});
        }
        
    }

    const handleLMUSelectChange = (event) => {
        const { name, value, checked, type } = event.target;
        setLargeEnterpriseDetails({ ...largeEnterpriseDetails, [name]: type == 'checkbox' ? checked : value });
    }

    /*Item Add and Submit*/
    const addMapItem = (mapItem, maptype) => {
        let path, area = 0.0, perimeter = 0.0, coordinates = '';
        var selectedItemType = agrichemicals.itemTypes.filter(item => item.id == typeid)[0];
        const ItemTypeName = selectedItemType.name;
        const isBiodiversityReq = selectedItemType.isBiodiversityReq == true ? true : false;
        let lmuId = largeEnterpriseDetails.selectedLMUId;
        const defaultColour = selectedItemType.defaultColour != null && selectedItemType.defaultColour != '' ? selectedItemType.defaultColour : "#21b546";

        /***********LMU Identification*************/
        if (maptype == 'marker') {
            largeEnterpriseDetails.LMUList.map((obj) => {
                const outlineCoordinates = obj.mapCoordinates.split(':');
                const outlinepositions = [];
                outlineCoordinates.filter(coor => coor != "").map((Coordinate) => {
                const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                outlinepositions.push(pos);
                })
                const outlinePolygon = new window.google.maps.Polygon({
                paths: outlinepositions
                });
                const pos = {lat: mapItem.getPosition().lat(), lng: mapItem.getPosition().lng()}

                const inside = window.google.maps.geometry.poly.containsLocation(
                    pos,
                    outlinePolygon
                ) ? true : false;
                if (inside) {
                    lmuId = obj.id;
                }
            });           
        }
        /***********LMU Identification*************/

        if (maptype == 'marker') {

            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);
        }
        else if (maptype == 'polygon') {
            path = mapItem.getPath();
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'polyline') {
            path = mapItem.getPath();
            area = 0;
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }

        let selectedLUEnterpriseTypes = [];
        if (lmuId != 0) {
            var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == lmuId);
            if (LUEnterpriseTypes.length != 0) {
                let LUEnterpriseTypesArray = [];

                LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                    return LUEnterpriseTypesArray.includes(String(el.value))
                });
                if (selectedLUEnterpriseTypes.length == 0) {
                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return el.label == 'N/A'
                    });
                }
            }
            else {
                selectedLUEnterpriseTypes.push(riskLookupList.enterpriseTypes.filter(x => x.label == 'N/A')[0]);
            }
        }

        setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes})
        setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: 0, itemId: 0, markertype: maptype, canArchive: true });
        setItemPopupMaptype({ ...itemPopupMaptype, markertype: maptype, area: area, perimeter: perimeter, coordinates: coordinates });
        setItemPopupHeader({ ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: typeid, LMUId: lmuId, LMUIds: lmuId.toString(), Name: '', isDragged: false, Description: '', color: defaultColour, isEdited: false, itemTypeDescription: selectedItemType.itemDescription, watertakeType:0 });
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq });
        setItemPopupAchievements({ ...itemPopupAchievements, isExpand: false });
    }

    const handleItemSubmit = async (event) => {
        setFormErrors({});
        setSaving(true);
        const errors = {};
        let enterprises = '';
        let contaminants = '';
        let riskDataGroups = '';
        let activityGroups = '';
        let activitySubGroups = '';

        if (itemPopupRisk.isRisk == "Yes") {
            if (itemPopupRisk.riskComment == null || itemPopupRisk.riskComment == undefined || itemPopupRisk.riskComment == "") {
                errors.riskComment = "This is mandatory!";
            }
            if (Array.isArray(itemPopupRisk.enterprises)) {
                let enterprisesArr = [];
                itemPopupRisk.enterprises.forEach(risk => {
                    enterprisesArr.push(risk.value);
                })
                enterprises = enterprisesArr.join(",");
            } else {
                enterprises = itemPopupRisk.enterprises.toString();
            }
            if (enterprises == null || enterprises == undefined || enterprises == "") {
                errors.enterprises = "This is mandatory!";
            }
            
            if (Array.isArray(itemPopupRisk.contaminants)) {
                let contaminantsArr = [];
                itemPopupRisk.contaminants.forEach(risk => {
                    contaminantsArr.push(risk.value);
                })
                contaminants = contaminantsArr.join(",");
            } else {
                contaminants = itemPopupRisk.contaminants.toString();
            }
            if (contaminants == null || contaminants == undefined || contaminants == "") {
                errors.contaminants = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.riskDataGroups)) {
                let riskDataGroupsArr = [];
                itemPopupRisk.riskDataGroups.forEach(risk => {
                    riskDataGroupsArr.push(risk.value);
                })
                riskDataGroups = riskDataGroupsArr.join(",");
            } else {
                riskDataGroups = itemPopupRisk.riskDataGroups.toString();
            }
            if (riskDataGroups == null || riskDataGroups == undefined || riskDataGroups == "") {
                errors.riskDataGroups = "This is mandatory!";
            }
            
            if (Array.isArray(itemPopupRisk.activityGroups)) {
                let activityGroupsArr = [];
                itemPopupRisk.activityGroups.forEach(risk => {
                    activityGroupsArr.push(risk.value);
                })
                activityGroups = activityGroupsArr.join(",");
            } else {
                activityGroups = itemPopupRisk.activityGroups.toString();
            }
            if (activityGroups == null || activityGroups == undefined || activityGroups == "") {
                errors.activityGroups = "This is mandatory!";
            }
            
            if (Array.isArray(itemPopupRisk.activitySubGroups)) {
                let activitySubGroupsArr = [];
                itemPopupRisk.activitySubGroups.forEach(risk => {
                    activitySubGroupsArr.push(risk.value);
                })
                activitySubGroups = activitySubGroupsArr.join(",");
            } else {
                activitySubGroups = itemPopupRisk.activitySubGroups.toString();
            }
            if (activitySubGroups == null || activitySubGroups == undefined || activitySubGroups == "") {
                errors.activitySubGroups = "This is mandatory!";
            }

            if (itemPopupRisk.likelihood == null || itemPopupRisk.likelihood == undefined || itemPopupRisk.likelihood == "") {
                errors.likelihood = "This is mandatory!";
            }
            if (itemPopupRisk.impact == null || itemPopupRisk.impact == undefined || itemPopupRisk.impact == "") {
                errors.impact = "This is mandatory!";
            }
        }
        if (itemPopupHeader.LMUIds == null || itemPopupHeader.LMUIds == '' || itemPopupHeader.LMUIds == undefined || itemPopupHeader.LMUIds == '0') {
            errors.luid = "This is mandatory!";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            let form = {
                Id: itemPopup.id,
                MapMarkerType: itemPopup.markertype,
                FarmId: itemPopup.farmId,
                ItemId: itemPopup.itemId,
                Name: itemPopupHeader.Name,
                IsDragged: itemPopupHeader.isDragged,
                Description: itemPopupHeader.Description,
                Colour: itemPopupHeader.color,
                LMUId: itemPopupHeader.LMUId,
                ItemTypeId: itemPopupHeader.itemTypeId,
                Area: itemPopupMaptype.area,
                Length: itemPopupMaptype.perimeter,
                MapCoordinates: itemPopupMaptype.coordinates,
                IsAddsToBioDiversity: itemPopupBiodiversity.isItemToBiodiversity,
                BioDiversityComment: itemPopupBiodiversity.biodiversityComment,
                IsItemHaveAnyRisk: itemPopupRisk.isRisk,
                RiskDescription: itemPopupRisk.riskComment,
                IsAchievementOfNote: itemPopupAchievements.isAchievementsNotes,
                AchievementOfNoteComments: itemPopupAchievements.achievementsComment,
                BeltHeight: itemPopupItemSepcQ.BeltHeight,
                PredominantSpecies: itemPopupItemSepcQ.PredominantSpecies,
                PredominantSpeciesOther: itemPopupItemSepcQ.PredominantSpeciesOther,
                ApproxAgeOfShelter: itemPopupItemSepcQ.ApproxAgeOfShelter,
                BeltThickness: itemPopupItemSepcQ.BeltThickness,
                BufferWidth: itemPopupItemSepcQ.BufferWidth,
                Areaperimeterbuffered: itemPopupItemSepcQ.Areaperimeterbuffered,
                userId: userId,
                IsEdited: itemPopupHeader.isEdited,
                WatertakeType: itemPopupHeader.watertakeType,
                ActivityIds: enterprises,
                ContaminantIds: contaminants,
                RiskDataGroupIds: riskDataGroups,
                ActivityGroupIds: activityGroups,
                ActivitySubGroupIds: activitySubGroups,
                RiskLikelihood: itemPopupRisk.likelihood,
                RiskImpact: itemPopupRisk.impact,
                LMUIds: itemPopupHeader.LMUIds
            }
            if (!Validate(form)) {
                try {
                    const response = await axios.post(
                        '/api/agrichemicals/UpdateAgrichemicals/', JSON.stringify(form),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        });
                    if (response.data != null) {
                        await refreshItemDetails();
                        setSaving(false);
                        toast.success("Item details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                        handleItemPopupClose();
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            else {
                setSaving(false);
            }
        }
        else {
            setSaving(false);
        }
    }

    const refreshItemDetails = async () => {
        const response = await axios.get(
            '/api/agrichemicals/GetAgrichemicals/', {
            params: {
                farmId: farmId
            }
        }
        );
        setAgrichemicals({
            ...agrichemicals, itemList: response.data.itemList, outlineCenter: response.data.outlineCenter
        });
        if (response.data.wizard.isAgrichemicalsSaved) {
            setIsThisPageCompleted(true);
        }
        else {
            setIsThisPageCompleted(false);
        }
        setActionsCount(response.data.actionCount);
    }

    const handleItemPopupClose = () => {
        resetValues();
    }

    const handleMapItemEdited = (itemId, type, strValue) => {
        let name = '';
        let value = '';
        if (type == "polygonBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }
        else if (type == "polylineBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }

        const itemList = agrichemicals.itemList.map(item =>
            item.itemId === itemId
                ? { ...item, [name]: value, isEdited: true }
                : item
        )
        setAgrichemicals({ ...agrichemicals, itemList: itemList });
    }

    /*Item Edit and Submit*/
    const editMapItem = (mapItem, itemId, isDragged) => {
        const itemDetails = agrichemicals.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;
        if (isDragged) {
            coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
        }
        let area = 0.0, perimeter = 0.0;
        if (itemDetails.isEdited == true) {
            if (itemDetails.mapMarkerType == "polygon") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polyline") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                const tempPolyline = new window.google.maps.Polyline({
                    paths: positions
                });

                let path = tempPolyline.paths;
                area = itemDetails.area;
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.length;
        }

        const isBiodiversityReq = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: area, perimeter: perimeter, coordinates: coordinates });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                Name: itemDetails.name, isDragged: isDragged, Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription,
                watertakeType: itemDetails.watertakeType, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({
                ...itemPopupBiodiversity, isVisible: isBiodiversityReq,
                isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment
            });

            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))

                        if (selectedLUEnterpriseTypes.length == 0) {
                            selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                                return el.label == 'N/A'
                            });
                        }
                    });
                }
            }
            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: false });

            const itemFieldTypesList = agrichemicals.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            setItemPopupItemFeild({ ...itemPopupItemFeild, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList });

            var ShowPredominantSpeciesOther = false;
            if (itemDetails.predominantSpecies != null) {
                var objList = agrichemicals.lookUpList.predominantSpeciesList.filter(x => x.id == parseInt(itemDetails.predominantSpecies));
                if (objList.length > 0) {
                    var obj = objList[0];
                    if (obj.name == 'Other native' || obj.name == 'Other exotic') {
                        ShowPredominantSpeciesOther = true;
                    }
                }
            }
            setItemPopupItemSepcQ({
                BeltHeight: itemDetails.beltHeight,
                PredominantSpecies: itemDetails.predominantSpecies,
                PredominantSpeciesOther: itemDetails.predominantSpeciesOther,
                ApproxAgeOfShelter: itemDetails.approxAgeOfShelter,
                BeltThickness: itemDetails.beltThickness,
                BufferWidth: itemDetails.bufferWidth,
                Areaperimeterbuffered: itemDetails.areaperimeterbuffered,
                ShowPredominantSpeciesOther: ShowPredominantSpeciesOther
            });

        }
    }

    const editMapItemFromNavigation = (itemId, agrichemicals,navigateFrom) => {
        const itemDetails = agrichemicals.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;

        const isBiodiversityReq = agrichemicals.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: [], farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: itemDetails.area, perimeter: itemDetails.length, coordinates: coordinates });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                Name: itemDetails.name, isDragged: false, Description: itemDetails.description, color: itemDetails.colour, itemTypeDescription: ItemDescription,
                watertakeType: itemDetails.watertakeType, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({
                ...itemPopupBiodiversity, isVisible: isBiodiversityReq,
                isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment
            });
            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))
                    });
                    if (selectedLUEnterpriseTypes.length == 0) {
                        selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                            return el.label == 'N/A'
                        });
                    }
                }
            }
            
            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            let isExpand = false;
            if (navigateFrom == "Achievements")
                isExpand = true
            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: isExpand });

            const itemFieldTypesList = agrichemicals.itemFeildList.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            setItemPopupItemFeild({ ...itemPopupItemFeild, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList });

            var ShowPredominantSpeciesOther = false;
            if (itemDetails.predominantSpecies != null) {
                var objList = agrichemicals.lookUpList.predominantSpeciesList.filter(x => x.id == parseInt(itemDetails.predominantSpecies));
                if (objList.length > 0) {
                    var obj = objList[0];
                    if (obj.name == 'Other native' || obj.name == 'Other exotic') {
                        ShowPredominantSpeciesOther = true;
                    }
                }
            }
            setItemPopupItemSepcQ({
                BeltHeight: itemDetails.beltHeight,
                PredominantSpecies: itemDetails.predominantSpecies,
                PredominantSpeciesOther: itemDetails.predominantSpeciesOther,
                ApproxAgeOfShelter: itemDetails.approxAgeOfShelter,
                BeltThickness: itemDetails.beltThickness,
                BufferWidth: itemDetails.bufferWidth,
                Areaperimeterbuffered: itemDetails.areaperimeterbuffered,
                ShowPredominantSpeciesOther: ShowPredominantSpeciesOther
            });

            setToggleItemTypeState({ itemTypeId: itemDetails.itemTypeId, isOpen: true, isIconUp: true });
            setToggleItemState({ itemId: itemId, isOpen: true, isIconUp: true });
        }
    } 

    const handleItemEditPopup = (itemId) => {
        editMapItem([], itemId, false)
    }

    /*Item Delete*/
    const handleItemDeletePopup = (itemId) => {
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }

    const handleItemDeletePopupClose = () => {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0 });
    }

    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        setItemDeleteSaving(true);
        try {
            const response = await axios.get(
                '/api/agrichemicals/DeleteItem/', {
                params: {
                    itemId: itemid, userId: userId
                }
            }
            );
            if (response.data != null) {
                await refreshItemDetails();
                setItemDeleteSaving(false);
                handleItemDeletePopupClose();
                handleItemPopupClose();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /*Item- Action Popup handle*/
    const handleOpenActionPopup = (itemId, actionId) => {
        const itemDetails = agrichemicals.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleOpenWholeActionPopup = (itemId, actionId) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: agrichemicals.outlineCenter.itemTypeId, itemName: 'Orchard Centre', actionId: actionId });
    };

    const handleActionClose = () => {
        setActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const handleWholeActionClose = () => {
        setWholeFarmActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, agrichemicals) => {
        const itemDetails = agrichemicals.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };
    const handleNavigateOpenWholeActionPopup = (itemId, actionId, agrichemicals) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: agrichemicals.outlineCenter.itemTypeId, itemName: 'NA', actionId: actionId });
    };
    /*Item- MaintenancePlan Popup handle*/
    const handleOpenMaintenancePlanPopup = (itemId) => {
        const itemDetails = agrichemicals.itemList.filter(items => items.itemId == itemId)[0];
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: true, itemId: itemId, itemName: itemDetails.name, itemTypeId: itemDetails.itemTypeId, actionId: 0 });
    }

    const handleMaintenancePlanClose = () => {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*handle Toggle for nonMap View*/
    const handleItemTypeListTogole = (isOpen, isIconUp) => {
        setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: isOpen, isIconUp: isIconUp})
    }

    const handleItemTypeTogole = (itemTypeId,isOpen, isIconUp,) => {
        setToggleItemTypeState({ ...toggleItemTypeState, itemTypeId: itemTypeId, isOpen: isOpen, isIconUp: isIconUp})
    }

    const handleItemTogole = (itemId, isOpen, isIconUp ) => {
        setToggleItemState({ ...toggleItemState, itemId: itemId, isOpen: isOpen, isIconUp: isIconUp})
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    const handleRiskSummaryClose = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: false })
        window.location.reload(true);
    }

    const handleRiskSummaryOpen = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: true })
    }

    /*Avoid unwanted page refresh*/
    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={agrichemicals} width={toggleSwitch == 'map' ? '80vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                mapDetails={mapDetails}
                largeEnterpriseDetails={largeEnterpriseDetails}
                addMapItem={addMapItem}
                editMapItem={editMapItem}
                handleMapItemEdited={handleMapItemEdited}
            ></MapView>
        )
    }, [agrichemicals, mapDetails, largeEnterpriseDetails, toggleSwitch])

    const itemTypeListView = useMemo(() => {

        return (
            <ItemTypeListViewForMap isWizardCompleted={agrichemicals.isWizardCompleted} Section={agrichemicals.Section}
                itemTypesList={agrichemicals.itemTypes}
                toggleItemTypeListState={toggleItemTypeListState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemTypeListTogole={handleItemTypeListTogole}
            >
            </ItemTypeListViewForMap>
        )
    }, [agrichemicals, largeEnterpriseDetails, agrichemicals.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, agrichemicals.outlineCenter])

    const nonMapView = useMemo(() => {
        return (
            <NonMapView itemModel={agrichemicals} isWizardCompleted={agrichemicals.isWizardCompleted}
                toggleItemTypeListState={toggleItemTypeListState}
                toggleItemTypeState={toggleItemTypeState}
                toggleItemState={toggleItemState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemEditPopup={handleItemEditPopup}
                handleItemDeletePopup={handleItemDeletePopup}
                handleOpenActionPopup={handleOpenActionPopup}
                sectionObj={agrichemicals.SectionObj}
                largeEnterpriseDetails={largeEnterpriseDetails}
                handleLMUSelectChange={handleLMUSelectChange}
                handleItemTypeListTogole={handleItemTypeListTogole}
                handleItemTypeTogole={handleItemTypeTogole}
                handleItemTogole={handleItemTogole} handleOpenWholeActionPopup={handleOpenWholeActionPopup}
                handleItemHighlight={handleItemHighlight} outlineCenter={agrichemicals.outlineCenter}
            ></NonMapView>
        )
    }, [agrichemicals, largeEnterpriseDetails, agrichemicals.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, agrichemicals.outlineCenter])

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('googleMap').style.height = '80vh';
            document.getElementById('googleMap').style.width = '80vw';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }
    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }

    const [checkActionPopup, setCheckActionPopup] = useState({ showPopup: false, section: '', method: '' });
    const handleCheckActionPopupClose = () => {
        setCheckActionPopup({ showPopup: false, section: '', method: '' });
    }
    const handleCheckCalculatorDataPopupConfirm = () => {
        if (checkActionPopup.method == 'handleWizardChanged') {
            handleWizardChanged(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleMoveToNextPage') {
            handleMoveToNextPage(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleWizardChangedBack') {
            handleWizardChangedBack(checkActionPopup.section);
        }
    }
    function checkAction(section, method) {
        if (!isThisPageCompleted && actionsCount == 0) {
            setCheckActionPopup({ showPopup: true, section: section, method: method });
        }
        else {
            if (method == 'handleWizardChanged') {
                handleWizardChanged(section);
            }
            else if (method == 'handleMoveToNextPage') {
                handleMoveToNextPage(section);
            }
            else if (method == 'handleWizardChangedBack') {
                handleWizardChangedBack(section);
            }
        }
    }

    return (
        <div>

            <Modal isOpen={checkActionPopup.showPopup}>
                <ModalHeader toggle={handleCheckActionPopupClose} >Confirm</ModalHeader>
                <ModalBody>
                    You have not created any actions on this page. Are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleCheckCalculatorDataPopupConfirm} color="primary">
                        Yes
                    </Button>{' '}
                    <Button color="danger" onClick={handleCheckActionPopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{agrichemicals.SectionName}</h2></Col>
                <Col>
                    {agrichemicals.isWizardCompleted != true && !loading &&
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => checkAction(agrichemicals.Section, 'handleWizardChanged')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(agrichemicals.Section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                        <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(agrichemicals.Section)}><FaChevronLeft /> Back  </Button>
                        </>
                        
                    }
                    {
                        !loading && <Button onClick={() => handleRiskSummaryOpen()} className="btn float-end btn-risk-summary">Risk and Actions summary</Button>
                    }
                </Col>
                
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <Col>
                                <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                            </Col>
                        </div>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col id='mapView'>
                            <Row className="map-layer">
                                <Col>
                                    <Row>
                                        <Col sm="4"></Col>
                                        <Col sm="8">
                                            <div className="map-item-edit-div">
                                                <Label className="map-checkbox-label">Show LU layer</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={largeEnterpriseDetails.isSmallEnterpriseShow} onChange={(e) => setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isSmallEnterpriseShow: !largeEnterpriseDetails.isSmallEnterpriseShow })} name="isSmallEnterpriseShow" id="isSmallEnterpriseShow" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Edit Item</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Show Label</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                            </div>
                                        </Col>
                                    </Row>
                                    {mapView}
                                </Col>
                            </Row>
                        </Col>
                        <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "20vw" }}>
                            <br />
                            {itemTypeListView}
                            <br />
                        </Col>
                        <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block', }}>
                            {nonMapView}
                        </Col>
                    </Row>
                </div>
            }
            <Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>
                <Modal size="lg" isOpen={itemPopup.showItemPopup} backdrop={false} className="itemPopup">
                <Form>
                    <ModalHeader toggle={handleItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <ItemCommonTop Section={agrichemicals.Section} handleLMUChange={handleLMUChange} itemPopupHeader={itemPopupHeader} itemPopupMaptype={itemPopupMaptype} itemPopupBiodiversity={itemPopupBiodiversity} itemPopupRisk={itemPopupRisk}
                            largeEnterpriseDetails={largeEnterpriseDetails} formErrors={formErrors} riskLookupList={riskLookupList}
                            handleItemPopupHeaderChange={handleItemPopupHeaderChange}
                            handleItemPopupBiodiversity={handleItemPopupBiodiversity}
                            handleItemPopupRisk={handleItemPopupRisk}
                            handleMultiSelectEnterpriseChange={handleMultiSelectEnterpriseChange}
                            handleMultiSelectContaminantChange={handleMultiSelectContaminantChange}
                            handleMultiSelectRiskDataGroupChange={handleMultiSelectRiskDataGroupChange}
                            handleMultiSelectActivityGroupChange={handleMultiSelectActivityGroupChange}
                            handleMultiSelectActivitySubGroupChange={handleMultiSelectActivitySubGroupChange}
                        ></ItemCommonTop>
                        <AgrichemicalsItemSpecView handleItemPopupHeaderChange={handleItemPopupHeaderChange} itemPopupHeader={itemPopupHeader} itemPopupItemFeild={itemPopupItemFeild} itemPopupItemSepcQ={itemPopupItemSepcQ} lookUpList={agrichemicals.lookUpList}
                         handleItemSepcValueChange={handleItemSepcValueChange}></AgrichemicalsItemSpecView>
                        <ItemCommonBottom itemPopup={itemPopup} itemPopupAchievements={itemPopupAchievements}
                            handleItemPopupAchievements={handleItemPopupAchievements}></ItemCommonBottom>
                    </ModalBody>

                    <ItemFooter saving={saving} itemPopup={itemPopup} Section={agrichemicals.Section}
                        handleItemDeletePopup={handleItemDeletePopup}
                        handleItemSubmit={handleItemSubmit}
                        handleItemPopupClose={handleItemPopupClose}
                        handleOpenActionPopup={handleOpenActionPopup}
                        handleOpenMaintenancePlanPopup={handleOpenMaintenancePlanPopup}>
                    </ItemFooter>
                </Form>
                </Modal>
            </Draggable>
            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    {itemDeletesaving ?
                        <>
                            <Button onClick={deleteItem} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={deleteItem} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            {actionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={agrichemicals.itemList} redirectPage="/agrichemicals" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleActionClose} itemTypeId={actionPopup.itemTypeId} sectionName={agrichemicals.Section} sectionId={agrichemicals.itemTypes[0].sectionId} itemId={actionPopup.itemId} itemName={actionPopup.itemName}
                showActionPopupModal={actionPopup.showActionPopup} actionId={actionPopup.actionId} itemTypeSelect={agrichemicals.itemTypes} itemTypeDisabled={true} wholeFarmAction={false} ></Action>}

            {wholeFarmActionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={agrichemicals.itemList} redirectPage="/agrichemicals" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleWholeActionClose} itemTypeId={wholeFarmActionPopup.itemTypeId} sectionName={agrichemicals.Section} sectionId={agrichemicals.itemTypes[0].sectionId} itemId={wholeFarmActionPopup.itemId} itemName={wholeFarmActionPopup.itemName}
                showActionPopupModal={wholeFarmActionPopup.showActionPopup} actionId={wholeFarmActionPopup.actionId} itemTypeSelect={agrichemicals.outlineItemTypes} itemTypeDisabled={true} wholeFarmAction={true}></Action>}

            {riskSummaryPopup.showriskSummary && <RiskSummary section={agrichemicals.SectionObj} width={width} height={height} sectionId={agrichemicals.itemTypes[0].sectionId} riskSummaryPopup={riskSummaryPopup} handleRiskSummaryClose={handleRiskSummaryClose}></RiskSummary>}

            {maintenancePlanPopup.showMaintenancePlanPopup && <MaintenancePlan handleMaintenancePlanClose={handleMaintenancePlanClose}
                showMaintenancePlanPopupModal={maintenancePlanPopup.showMaintenancePlanPopup}
                actionId={maintenancePlanPopup.actionId} itemId={maintenancePlanPopup.itemId} itemName={maintenancePlanPopup.itemName} itemTypeId={maintenancePlanPopup.itemTypeId}
                itemTypeSelect={agrichemicals.itemTypes} sectionName={agrichemicals.Section} redirectPage="/agrichemicals">
            </MaintenancePlan>}
        </div>
    )
}
export default Agrichemicals;