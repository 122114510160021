import React, { Component, useState, useEffect } from 'react';
import { FaHome, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';
import './Menu.css';

const Menu = ({ handleSetShowWizard }) => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [supplyCompany, setSupplyCompany] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [ruralProfessionalOrganisation,setRuralProfessionalOrganisation] = useState(false);
    const [isLicensedforEnterpriseDashboard, setIsLicensedforEnterpriseDashboard] = useState(false);
    const [role, setRole] = useState('');
    const [farmCount, setFarmCount] = useState(0);
    const [defaultFarmName, setDefaultFarmName] = useState();
    const [defaultFarmUniqueId, setDefaultFarmUniqueId] = useState();
    const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsIconUp(!isIconUp);
        handleSetShowWizard()
    };
    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/settings/GetMyInfoView/', {
                    params: { Id: farmId, UserId: userId }
            }
            );
            if (response.data.status) {
                setSupplyCompany(response.data.content.supplyCompany);
                setRuralProfessionalOrganisation(response.data.content.ruralProfessionalOrganisation);
                setRole(response.data.content.role);
                setFarmCount(response.data.content.farmCount);
                setIsLicensedforEnterpriseDashboard(response.data.content.isLicensedforEnterpriseDashboard);
            }

        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        setDefaultFarmName(JSON.parse(localStorage.getItem("defaultFarmName")))
        setDefaultFarmUniqueId(JSON.parse(localStorage.getItem("defaultFarmUniqueId")))
        OnPageload();
    })

    const navigate = async () => {
        const response = await axios.get(
            '/api/wizard/GetLandingPage/', {
            params: {
                farmId: farmId == 'null' ? 0 : farmId
            }
        });
        var landingPage = response.data.landingPage;
        window.open(landingPage, "_self");
    }

    return (
        <div>
            <div className="navigation-menu">
                <div className="container" style={{ display: "flex",height:"5vh" }}>
                    <ul id="menu" style={{ width: "100%",marginTop:"5px" }}>
                        {/*<li><a className="link" href="/myinfo?Type=Catchment">My Catchment</a></li>*/}
                        {ruralProfessionalOrganisation && <li><a className="link" href="/myinfo?Type=Rural Professional Organisation">My Rural Professional</a></li>}
                        {role == 'Zespri Admin' &&
                            <li><a className="link" href="/allfarmssummary"> All Orchard Summary</a></li>
                        }
                        {
                            farmCount > 1 && (role == 'Organization User' || role == 'Grower, Land owner, User on Orchard') &&
                            <li><a className="link" href="/allfarmssummary"> All Orchard Summary</a></li>
                        }
                        {isLicensedforEnterpriseDashboard &&
                            <li><a className="link" href="/enterpriseinsights">Enterprise Insights</a></li>
                        }
                        <li><a className="link" href="javascript:void(0)" onClick={navigate}>{defaultFarmName != undefined && defaultFarmUniqueId != null ? defaultFarmName + " | " + defaultFarmUniqueId : ""}</a></li>
                    </ul>
                    <div title="Click to show menu" onClick={toggle} className="toggleoutlineMenuItem">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</div>
                </div>
            </div>
        </div>
    );
}
export default Menu