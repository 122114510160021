import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Label, Input, FormGroup, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Select, { components, ValueContainerProps } from 'react-select';
import './Lmu.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const EnterpriseDetailForm = ({ form, handleFormFieldChange, handleMultiSelectChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    return (
        <Card className="my-3">
            <CardHeader className="card-header-click" onClick={toggle}>
                <Row>
                    <Col sm="11">
                        <h6>Enterprises / Farming Activities</h6>
                    </Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label for="enterpriseType">Select which activity(s) are being done on this land unit</Label>
                                <Select 
                                    id="enterpriseType"
                                    name="enterpriseType"
                                    closeMenuOnSelect={false}
                                    className="dropdown lmuEnterpriseType"
                                    placeholder="Select Enterprise Type"
                                    defaultValue={form.enterpriseType}
                                    options={form.enterpriseTypeList}
                                    onChange={handleMultiSelectChange}
                                    isClearable={false}
                                    isMulti hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        form.ShowFrostProtection && 
                        <Row>
                            <Col sm="12">
                                <FormGroup check>
                                    <Label
                                        check
                                        for="isReceivesFreshWaterIrrigation"
                                    >
                                        Frost Protection
                                    </Label>
                                    <Input
                                        defaultChecked={form.FrostProtection}
                                        checked={form.FrostProtection}
                                        id="FrostProtection"
                                        name="FrostProtection"
                                        type="checkbox"
                                        value={form.FrostProtection}
                                        onChange={handleFormFieldChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Collapse>
        </Card>
    )
}

export default EnterpriseDetailForm