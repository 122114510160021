import { useState} from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button, Spinner, Badge, Collapse } from 'reactstrap';
import MapImport from '../Outline/MapImport';
import OutlineItems from '../Outline/OutlineItems';
import { FaMapMarkerAlt, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';

const OutlineNonMap = ({ outline, mapDetails, saving, isLargeEnterprise,
    handleClearSelection, handleItemClick, handlelargeEnterpriseChange, handleZoomChange, handleFarmDetailSave,
    importShapeFilesClick, handleItemHighlight, handleItemPopup, handleItemDeletePopup, handleDeleteAllImportPopup, findMyFarm }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    const FarmCenter = outline.outlineList.filter(type => type.mapType == 'marker');
    let isFarmCenter = false;
    const Location = { longitude: '', latitude: '' };
    if (FarmCenter != null && FarmCenter.length > 0) {
        isFarmCenter = true;
        Location.longitude = FarmCenter[0].coordinates.split(',')[1];
        Location.latitude = FarmCenter[0].coordinates.split(',')[0];
    }

    return (
        <Row>
            <Col>
                <Card>
                    <CardHeader className="item-header item_click" onClick={toggle}>
                        <Row>
                        <Col sm="11">
                                <h5 className="color-dark-teal">Map the Boundary</h5>
                        </Col>
                            <Col sm="1" className="toggleoutline">
                                {isIconUp ? <FaChevronUp /> : <FaChevronDown />}
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={isOpen}>
                        <CardBody>
                            {/*<Badge style={{ fontSize: "20px" }} pill color="success">{1}</Badge>&nbsp;&nbsp;&nbsp;<label style={{ fontSize: "20px", color: "#008436", fontWeight: "500" }}>Part 1 - Overview</label>*/}
                            <Row>
                                <Col>
                                    <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                        <Row>
                                            <Col sm="1">
                                                <Row>
                                                    <Col style={{ margin: "auto", width: "50%", padding: "10px" }}>
                                                        <Badge style={{ fontSize: "20px" }} pill color="success">{1}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="11">
                                                <Row>
                                                    <Col>
                                                        <label className="outlineLabel form-label" htmlFor="farmaddress">
                                                            Orchard Address
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="9">
                                                        <label className="largelandareaStyle">
                                                            {outline.farmAddress != null && outline.farmAddress != "" ? outline.farmAddress : "There is no address available."}
                                                        </label>
                                                    </Col>
                                                    <Col sm="3">

                                                        {outline.farmAddress != null && outline.farmAddress != "" ?
                                                            <Button className="button-common" color="warning" size="sm" onClick={findMyFarm}>Find My Property</Button> :
                                                            <Button className="button-common" color="warning" size="sm" onClick={findMyFarm}>Find My Property</Button>
                                                        }
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm="1">
                                                <Row>
                                                    <Col style={{ margin: "auto", width: "50%", padding: "10px" }}>
                                                        <Badge style={{ fontSize: "20px" }} pill color="success">{2}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="11">
                                                <Row>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col>
                                                                <label className="outlineLabel para-light-sm ">Click an icon then click on the map to add items</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <br />
                                                    <Col sm="9">
                                                        <Row>
                                                            {outline.itemTypes.map(type =>
                                                                <Col sm="4" key={uuid()}>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            {type.markerType == "marker" ?
                                                                                <>
                                                                                    <img alt=''
                                                                                        className="featureIconNew"
                                                                                        src="/images/Icons/fwfp-icon-area_centre.png"
                                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                                    />{'  '}<label className="itemTypeStyle"> {type.displayName}</label></>
                                                                                : type.markerType == "polyline" ?
                                                                                    <><img alt=''
                                                                                        className="featureIcon"
                                                                                        src="/images/polyline.png"
                                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                                    />{'  '}<label className="itemTypeStyle"> {type.displayName}</label></>
                                                                                    :
                                                                                    <><img alt=''
                                                                                        className="featureIconNew"
                                                                                        src="/images/Icons/fwfp-icon-boundaries.png"
                                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                                    />{'  '}<label className="itemTypeStyle"> {type.displayName}</label></>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            )}
                                                        </Row>
                                                    </Col>
                                                    <Col sm="3">
                                                        <Button className="btnClearSelection button-common" color="warning" size="sm" onClick={handleClearSelection}>
                                                            Clear Selection
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr /> 
                                        <Row>
                                            <Col sm="1">
                                                <Row>
                                                    <Col style={{ margin: "auto", width: "50%", padding: "10px" }}>
                                                        <Badge style={{ fontSize: "20px" }} pill color="success">{3}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="11">
                                                <Row>
                                                    <Col sm="9">
                                                        <label className="largelandareaStyle">
                                                            Tick box if you want to capture multiple orchards under this plan
                                                        </label>
                                                    </Col>
                                                    <Col sm="3">
                                                        <input className="islargeEnterprise form-check-input" id="isLargeEnterprise" value={isLargeEnterprise} checked={isLargeEnterprise} type="checkbox" onChange={handlelargeEnterpriseChange} />
                                                        {' '}
                                                        <label className="outlineLabel form-label" htmlFor="isLargeEnterprise">
                                                            Large Enterprise
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm="1">
                                                <Row>
                                                    <Col style={{ margin: "auto", width: "50%", padding: "10px" }}>
                                                        <Badge style={{ fontSize: "20px" }} pill color="success">{4}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="11">
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <label className="form-label">Zoom Level</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <label className="largelandareaStyle">
                                                                    On the map, ensure centre pin and overall outline fit best to map then take note of the zoom level (see bottom right on the map) then set and save the zoom level of the property here.
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="farmoutline-div-style">
                                                            <Col>
                                                                <select className="form-control input-sm map-popup-input-text" value={mapDetails.zoomLevel} onChange={handleZoomChange}>
                                                                    {outline.zoomLevelList.map(zoomlist =>
                                                                        <option key={uuid()} value={zoomlist.zoomValue}>{zoomlist.zoomText}</option>
                                                                    )}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                            <>
                                                                {saving ?
                                                                    <Button className="button-save"
                                                                        color="success"
                                                                        disabled
                                                                    >
                                                                        <Spinner size="sm">
                                                                            Saving...
                                                                        </Spinner>
                                                                        <span>
                                                                            {' '}Saving
                                                                        </span>
                                                                    </Button>
                                                                    :
                                                                    <Button className="button-save" color="success" size="sm" onClick={handleFarmDetailSave}>
                                                                        Save
                                                                    </Button>
                                                                }
                                                            </>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
                <br />
                <OutlineItems outline={outline}
                    handleItemHighlight={handleItemHighlight}
                    handleItemPopup={handleItemPopup}
                    handleItemDeletePopup={handleItemDeletePopup}
                >
                </OutlineItems>
            </Col>
        </Row>
    );
}
export default OutlineNonMap