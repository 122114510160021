import React, { Component, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse, Button } from 'reactstrap';
import { FaLink, FaWarehouse, FaNewspaper, FaRegBuilding, FaRegUserCircle, FaDoorOpen, FaChevronDown, FaChevronUp, FaHome, FaKey } from 'react-icons/fa';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGear, faUser, faCaretDown, faCaretUp, faRightFromBracket, faIndustry, faLink, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import useAuth from "../Utilities/Hooks/useAuth";
import './Profile.css';
import jwt_decode from "jwt-decode";

library.add(faGear, faUser, faCaretDown, faCaretUp, faRightFromBracket, faIndustry, faLink, faNewspaper)

const Profile = () => {

    const { auth, setAuth } = useAuth(); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const navigate = useNavigate();
    const [caretDown, setCaretDown] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [user, setUser] = useState({});
    const [role, setRole] = useState({});    
    useEffect(() => {
        const currentUser = localStorage.getItem("auth");
        currentUser != null ? setUser(JSON.parse(currentUser)) : setUser({});

        if (auth != null) {
            var decodedToken = jwt_decode(auth.token);
            setRole(decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
        }

    }, [])

    const logout = async () => {
        setAuth({});
        localStorage.clear();
        navigate('/login');
    }

    const handleClick = () => {
        setCaretDown(!caretDown);
        setMenuOpen(!menuOpen)
    }

    const handleBlur = () => {
        setMenuOpen(false);
        setCaretDown(true);
    }

    const openChangePassword = (id, routePath) => {
        navigate('/' + routePath, { state: { id: id } });
    }

    return (
        <div>
            <div className="profile-link" onBlur={handleBlur}>
                <Button className="" style={{ background: "white" }} outline onClick={handleClick} >
                    <div className="profile">
                        {user.profilePictureUrl?.split("ProfilePictures/")[1] != "" ? 
                            <img className="avatar-icon" src={user.profilePictureUrl + ".jpeg"} alt="Profile picture"
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = "/images/default-profile.jpg"
                                }} />
                            :
                            <img className="avatar-icon" src="/images/default-profile.jpg" alt="Profile picture" />
                            }
                    </div>
                    <div className="profile">
                        <div style={{ color:"#008436",fontSize:"large",fontWeight:"600" }}>{user.fullName} </div>
                    </div>
                    <div className="profile caret caretoutline" style={{ outline: "2px solid #B6D137", color:"#B6D137" }}>
                        {caretDown ? <FaChevronDown/> : <FaChevronUp/>}
                    </div>
                </Button>
                <Collapse isOpen={menuOpen}>
                    <div className="dropdown-menu-lg overflow-hidden p-0 dropdown-menu dropdown-menu-right show" id="settingsMenu">
                        <ul className="text-left bg-transparent list-group list-group-flush">
                            <li className="list-group-item">
                                <ul className="nav-neutral-primary flex-column nav nav-pills profile-dropdown">
                                    <li className="nav-item">
                                        <Link to="/welcome" className="nav-link"><FaHome /> Welcome</Link>
                                    </li>
                                </ul>
                            </li>
                            {
                                role == 1 ?
                                    <li className="list-group-item">
                                        <span className="nav-link menu-setting-header">Settings </span>
                                        <ul className="nav-neutral-primary flex-column nav nav-pills profile-dropdown">
                                            <li className="nav-item">
                                                <Link to="/externalorganisation" className="nav-link"><FaRegBuilding /> Organisations</Link>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/user" className="nav-link profile-dropdown-item"><FaRegUserCircle /> Users</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/orchard" className="nav-link"><FaWarehouse /> Orchards</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/userorchard" className="nav-link"><FaLink /> User to Orchard</a>
                                            </li>
                                        </ul>
                                    </li> :
                                    <></>
                            }

                            <li className="list-group-item">
                                <ul className="nav-neutral-primary flex-column nav nav-pills profile-dropdown">
                                    <li className="nav-item">
                                        <a href="/changepassword" onClick={(id, routePath) => openChangePassword(userId, 'changepassword')} className="nav-link"><FaKey /> Change Password</a>
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={logout} className="nav-link logout"><FaDoorOpen /> Logout</button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

export default Profile