import StaticMaps from '../StaticMap';

const StaticMapView = (({ itemModel, mapDetails, farmCentreReq, id, setReportButtonVisible, setPropertyMapReportButtonVisible }) => {
    const outlineList = itemModel.outlineList;
    const itemList = itemModel.itemList
    const data = { outlineList, itemList, mapDetails, farmCentreReq, id };
    const apiKey = 'IDHvGHGU5OPX6ENFh73ROJ1id7dbctWC';

    return (
        <StaticMaps apiKey={apiKey} data={data} setReportButtonVisible={setReportButtonVisible} setPropertyMapReportButtonVisible={setPropertyMapReportButtonVisible} />
    )
})
export default StaticMapView