import { useState, useEffect, useMemo } from 'react';
import {
    Row, Col, Table, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Label, Input, Form, FormGroup, Spinner
} from 'reactstrap';
import { FaChevronRight, FaPlus, FaChevronLeft } from 'react-icons/fa';
import './Lmu.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import uuid from 'react-uuid';
import LmuItem from './LmuItem';
import BiodiversityForm from './BiodiversityForm';
import InherentRisks from './InherentRisks';
import AdditionalInformation from './AdditionalInformation';
import EnterpriseDetailForm from './EnterpriseDetailForm';
import NonContiguousForm from './NonContiguousForm';
import GeneralDetailForm from './GeneralDetailForm';
import ActionsToConsider from '../FarmApt/ActionsToConsider';
import Action from '../Action/Action';
import MapView from '../Map/MapView';
import Photos from '../Uploads/Photos';
import SoilForm from './SoilForm';
import Validate from '../Validate/Validate';
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import { useNavigate, useLocation } from "react-router-dom";
import Documents from '../Uploads/Documents';
import Select, { components } from 'react-select';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const Lmu = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddArchiveItem = localStorage.getItem("isAddArchiveItem"); const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [farmId] = useState(localStorage.getItem("defaultFarmId")); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const [userId] = useState(localStorage.getItem("loggedInUserId")); const [saving, setSaving] = useState(''); const [savingDeleteItem, setSavingDeleteItem] = useState('');
    const [nonKiwifruitEnterprisesOnThisLand, setNonKiwifruitEnterprisesOnThisLand] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [lmu, setLmu] = useState({ outlineList: [], itemList: [], itemTypes: [], section: 'Lmu', sectionName: 'Land Units (LU\'s)', isWizardCompleted: false, lookUpList : [] });
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false,
        isLMUPage: true,
        isCenterReq: false,
        isFarmCenterEditable: false,
        isFarmOutlineEditable: false,
        zoomLevel: 15,
        polyType: '',
        itemsEditable: false,
        highlighteditemId: 0,
        isHiglighted: false, isClear: false, showLabel: false

    });
    const [inherentRisksTempList1, setInherentRisksTempList1] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [masterLmuList, setMasterLmuList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [typeId, setTypeId] = useState(0);
    const [isClosedActionShow, setIsClosedActionShow] = useState(false);
    const [isNonContiguous, setIsNonContiguous] = useState(false);
    const [hasBiodiversity, setHasBiodiversity] = useState(false);
    const [isNonEffective, setIsNonEffective] = useState(false);
    const [blockSummary, setBlockSummary] = useState({ total: 0, effective: 0, totalArea: 0.0, totalEffectiveArea: 0.0 });
    const [itemMapDetail, setItemMapDetail] = useState({ markerType: '', area: 0.0, perimeter: 0.0, coordinates: '', });
    const [itemModal, setItemModal] = useState({ showItemModal: false, itemId: 0, item: [], action: '', showArchiveOption:true });
    const [actionModal, setActionModal] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [itemDeleteModal, setItemDeleteModal] = useState({ showModal: false, itemId: 0 });
    const [formGeneral, setFormGeneral] = useState({
        itemId: 0,
        LmuTypeId: 0,
        Name: '',
        Description: '',
        itemColour: '',
        itemSizeHa: 0.0,
        itemLength:0.0,
        isEdited: false,
        kiwifruitVariety: '',
        rootStockAge: null
    });
    const [formContiguous, setFormContiguous] = useState({ itemId: 0, isNonContiguousItem: false, masterLmuId: 0, isSameAsMaster : false });
    const [formDescription, setFormDescription] = useState({ isBiodiversityReq: false, itemId: 0, isAddsToBiodiversity: false, biodiversityComment: '' });
    const [formBasicDetail, setFormBasicDetail] = useState({
        itemId: 0,
        isNonEffectiveArea: false,
        effectiveArea: 0.0,
        isLeasedBlock: false,
        leasedAreaHa: 0.0,
        isReceivesLiquidEffluent: false,
        isReceivesFreshWaterIrrigation: false,
        useWaterForFrostProtection: false,
        applyAgrichemicalsToLandUnit: false,
        stemCountPerHa: 0.0,
        topographyId: 0,
        soilTypePredominantId: 0,
        soilTypePredominantPercentage: null,
        soilTypeOtherId: 0,
        soilTypeOtherPercentage: null,
        drainabilityPredominant: 0,
        drainabilityOther: 0,
        inherentRisks: '',
        topographyList: [],
        soilTypePredominantList: [],
        soilTypeOtherList: [],
        drainabilityPredominantList: [],
        drainabilityOtherList: [],
        inherentRisksList: []
    });  
    const [formRisk, setFormRisk] = useState({ isRisk: 'No', riskComment: '', isMitigated: false, mitigatedComment: '' });
    const [formEnterpriseDetail, setFormEnterpriseDetail] = useState({
        itemId: 0,
        enterpriseType: '',
        enterpriseDefinition: 0,
        generalFarmingActivities: '',
        enterpriseTypeList: [],
        enterpriseDefinitionList: [],
        IsLUDoesNotHaveAnyFarmingOrGrowingActivities: false, FrostProtection: false, ShowFrostProtection: false
    });
    const [formSoil, setFormSoil] = useState({
        lmuId: 0,
        soilDetail: [],
        soilMapGroupsList: [],
        soilMapOrdersList: [],
    })
    const [blockOnly, setBlockOnly] = useState({
        defaultZoomLevel: 15,
        isViewBlockOnly: false,
        name: '',
        itemId: 0,
        coordinates: '',
        showCenterPinPopup: false,
        actionType: '',
        zoomLevelList: [],
        islargeEnterprise: false
    })
    const [sectionObj, setSectionObj] = useState({});
    const [toggleItemState, setToggleItemState] = useState({ lmuId: 0, isOpen: false, isIconUp: false });
    const [toggleChildItemState, setToggleChildItemState] = useState({ lmuId: 0, isOpen: false, isIconUp: false });
    const [hideChildren, setHideChildren] = useState({ lmuId: 0, childLUDisplay: 0, ishideChildren: true });
    const [gisLayerTopographyDetails, setGISLayerTopographyDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [gisLayerSoilDetails, setGISLayerSoilDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [gisLayerWetlandDetails, setGISLayerWetlandDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [lmuItems, setLMUItems] = useState([]);
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false })
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    useEffect(() => {
        setLoading(true);
        getLmu();
        const auth = localStorage.getItem("auth");
    }, [])



    const getLmu = async () => {

        try {
            const response = await axios.get(
                '/api/lmu/getLmu/', {
                params: {
                    farmId: farmId
                }
            });

            const itemsList = response.data.itemsList;

            itemsList.forEach((item) => {
                item.nonContiguousItems = [];

                itemsList.map(nc => {
                    if (nc.lmuId === item.id) {
                        item.nonContiguousItems.push(nc);
                    }
                })

            })
            setNonKiwifruitEnterprisesOnThisLand(response.data.nonKiwifruitEnterprisesOnThisLand);
            const lmuList = itemsList.map(item => item.lmuItems[0])
            const effectiveLmuList = lmuList.filter(item => !item.isNonEffectiveArea)
            let effectiveAreaTotal = 0;
            let totalArea = 0;

            lmuList.forEach(lmu => {
                totalArea += lmu.sizeHa;
            })
            effectiveLmuList.forEach(lmu => {
                //effectiveAreaTotal += lmu.effectiveAreaHa > 0 ? lmu.effectiveAreaHa : lmu.sizeHa;
                effectiveAreaTotal += lmu.effectiveAreaHa;
            })
            setLMUItems(itemsList);
            setMasterLmuList(response.data.lookUpList.masterLmuList);

            setBlockSummary({ total: lmuList.length, effective: effectiveLmuList.length, totalArea: totalArea, totalEffectiveArea: effectiveAreaTotal })

            setLmu({ ...lmu, outlineList: response.data.outline, itemList: itemsList, itemTypes: response.data.itemTypeList, isWizardCompleted: response.data.isFWFPCompleted, lookUpList: response.data.lookUpList });
            setItemsList(itemsList);

            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel, polyType: '' });
            setLoading(false);
            setSectionObj(response.data.section);
            setBlockOnly({ ...blockOnly, zoomLevelList: response.data.zoomLeveList, showCenterPinPopup: false, name: '', islargeEnterprise: response.data.islargeEnterprise })
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, gisLayerList: response.data.gisLayerList_TopographyMap });
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, gisLayerList: response.data.gisLayerList_SoilMap });
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, gisLayerList: response.data.gisLayerList_WetlandMap });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise })
            setInherentRisksTempList1(response.data.lookUpList.inherentVulnerabilityList)
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            if (state != null) {
                const { itemId, actionId, navigateFrom } = state;
                navigate(state.pathname, { replace: true });
                if (navigateFrom == "Actions") {
                    handleNavigateOpenActionPopup(itemId, actionId, itemsList);
                }
                else {
                    editMapItemFromNavigation(itemId, itemsList, response.data)
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    const resetItemModal = () => {
        setIsNonContiguous(false);
        setHasBiodiversity(false);
        setFormContiguous({ itemId: 0, isNonContiguousItem: false, masterLmuId: 0, isSameAsMaster: false });
        setFormDescription({ isBiodiversityReq: false, itemId: 0, isAddsToBiodiversity: false, biodiversityComment: '' });
        setFormBasicDetail({
            itemId: 0,
            isReceivesLiquidEffluent: false,
            isReceivesFreshWaterIrrigation: false,
            useWaterForFrostProtection: false,
            applyAgrichemicalsToLandUnit: false,
            stemCountPerHa: 0.0,
            topographyId: 0,
            soilTypePredominantId: 0,
            soilTypePredominantPercentage: null,
            soilTypeOtherId: 0,
            soilTypeOtherPercentage: null,
            inherentRisks: '',
            isLeasedBlock: false,
            leasedAreaHa : 0.0,
            topographyList: lmu.lookUpList.topographyList,
            soilTypePredominantList: lmu.lookUpList.soilTypePredominantList,
            soilTypeOtherList: lmu.lookUpList.soilTypeOtherList,
            drainabilityPredominantList: lmu.lookUpList.drainabilityPredominantList,
            drainabilityOtherList: lmu.lookUpList.drainabilityOtherList,
            inherentRisksList: lmu.lookUpList.inherentRisksList,
        });
        
        setFormRisk({
            isRisk: 'No',
            riskComment: '',
            isMitigated: false,
            mitigatedComment: ''
        });
        setFormSoil({
            lmuId: 0,
            soilDetail: [],
            soilMapGroupsList: lmu.lookUpList.soilMapGroupsList,
            soilMapOrdersList: lmu.lookUpList.soilMapOrdersList,
        })
        if (lmu.lookUpList.enterpriseTypeList.length == 1) {
            setFormEnterpriseDetail({
                ...formEnterpriseDetail, enterpriseType: lmu.lookUpList.enterpriseTypeList[0], itemId: 0,enterpriseDefinition: 0,generalFarmingActivities: '',enterpriseTypeList: lmu.lookUpList.enterpriseTypeList,
                enterpriseDefinitionList: lmu.lookUpList.enterpriseDefinitionList,IsLUDoesNotHaveAnyFarmingOrGrowingActivities: false, FrostProtection: false });
        }
        else {
            setFormEnterpriseDetail({
                itemId: 0,
                enterpriseType: '',
                enterpriseDefinition: 0,
                generalFarmingActivities: '',
                enterpriseTypeList: lmu.lookUpList.enterpriseTypeList,
                enterpriseDefinitionList: lmu.lookUpList.enterpriseDefinitionList,
                IsLUDoesNotHaveAnyFarmingOrGrowingActivities: false, FrostProtection: false
            });
        }
    }

    const addMapItem = (mapItem, mapType) => {

        resetItemModal();

        let path,
            area = 0.0,
            perimeter = 0.0,
            coordinates = '',
            polygonCoordinates = '';

        if (mapType == 'polygon') {

            setItemModal({ ...itemModal, showItemModal: true, itemId: 0, item: [], action: 'Added' });

            setIsNonEffective(false);

            if (mapType == 'polygon') {
                path = mapItem.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);

                path.getArray().map((c) => {

                    polygonCoordinates = polygonCoordinates + c.toString().replace('(', '').replace(')', '') + ":";
                })

                coordinates = polygonCoordinates;
            }

            setFormGeneral({
                itemId: 0,
                LmuTypeId: 0,
                Name: '',
                Description: '',
                itemColour: '#0d535d',
                itemSizeHa: area,
                itemLength: perimeter,
                isEdited: false,
                kiwifruitVariety: '',
                rootStockAge: null
            })

            setItemMapDetail({ markerType: mapType, area: area, perimeter: perimeter, coordinates: coordinates });
        }
        else {
            //Block centre pin
            const item = itemsList.filter(item => item.id == blockOnly.itemId)[0];

            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);
            setBlockOnly({ ...blockOnly, name: item.name + ' - Centre Pin', coordinates: coordinates, showCenterPinPopup: true, actionType: 'Add New' })
        }
    }

    const handleMapItemEdited = (itemId, type, strValue) => {
        let name = '';
        let value = '';

        if (type == "polygonBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }
        const itemListTemp = itemsList.map(item =>
            item.id === itemId
                ? { ...item, [name]: value, isEdited: true }
                : item
        )
        setLmu({ ...lmu, itemList: itemListTemp });

    }

    const editMapItem = (mapItem, itemId, isDragged) => {

        const item = itemsList.filter(item => item.id == itemId)[0];
        const itemTemp = lmu.itemList.filter(item => item.id == itemId)[0];
        const lmuItem = item?.lmuItems[0];
        const isNonContiguousItem = item.isConnectedToAnotherLMU == null || item.isConnectedToAnotherLMU == false || item.isConnectedToAnotherLMU == undefined ? false : true
        const isSameAsMaster = lmuItem.isSameAsMaster == null || lmuItem.isSameAsMaster == false || lmuItem.isSameAsMaster == undefined ? false : true
        const isNonEffectiveArea = lmuItem.isNonEffectiveArea == null || lmuItem.isNonEffectiveArea == false || lmuItem.isNonEffectiveArea == undefined ? false : true
        const hasBiodiversity = item.isAddsToBiodiversity == null || item.isAddsToBiodiversity == false || item.isAddsToBiodiversity == undefined ? false : true
        const isBiodiversityReq = hasBiodiversity ? lmu.itemTypes.filter(type => type.id == item.itemTypeId)[0].isBiodiversityReq == true ? true : false : false;

        setTypeId(item.itemTypeId);
        let area = 0.0;
        let coordinates = '';
        if (itemTemp.isEdited == true && itemTemp.mapMarkerType == "polygon") {
            coordinates = itemTemp.mapCoordinates;
            const Coordinates = coordinates.split(':');
            const positions = [];
            Coordinates.filter(coor => coor != "").map((Coordinate) => {
                const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                positions.push(pos);
            })
            const tempPolygon = new window.google.maps.Polygon({
                paths: positions
            });

            let path = tempPolygon.getPath();
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);

        }
        else {
            area = lmuItem.sizeHa;
            coordinates = item.mapCoordinates;
        }

        setItemModal({ showItemModal: true, itemId: itemId, item: item, action: 'Updated', showArchiveOption: item.showArchiveOption });

        const masterLmus = lmu.lookUpList.masterLmuList.filter(item => item.id != itemId);

        setMasterLmuList(masterLmus);

        let kiwifruitVariety = []
        if (lmuItem.kiwifruitVariety != "" && lmuItem.kiwifruitVariety != null) {
            let kiwifruitVarietyArray = [];

            kiwifruitVarietyArray = lmuItem.kiwifruitVariety.split(',');

            lmu.lookUpList.kiwifruitVarietyList.map(type => {
                if (kiwifruitVarietyArray.includes(String(type.value))) {
                    kiwifruitVariety.push({
                        value: type.value,
                        label: type.label
                    })
                }
            })
        }

        setFormGeneral({
            itemId: itemId,
            LmuTypeId: lmuItem.lmuTypeId,
            Name: item.name,
            Description: item.description,
            itemColour: item.colour,
            itemSizeHa: area,
            itemLength: 0.0,
            isEdited: itemTemp.isEdited,
            kiwifruitVariety: kiwifruitVariety,
            rootStockAge: lmuItem.rootStockAge
        });

        setItemMapDetail({ ...itemMapDetail, area: area, coordinates: coordinates });

        setIsNonContiguous(isNonContiguousItem);

        setHasBiodiversity(hasBiodiversity);

        setIsNonEffective(isNonEffectiveArea);

        setFormContiguous({ itemId: itemId, isNonContiguousItem: isNonContiguousItem, masterLmuId: item.lmuId, isSameAsMaster: isSameAsMaster });

        setFormDescription({ isBiodiversityReq: isBiodiversityReq, itemId: itemId, isAddsToBiodiversity: item.isAddsToBiodiversity, biodiversityComment: item.biodiversityComment });

        //Selected inherentRisks
        let inherentRisks = []
        var list = JSON.parse(JSON.stringify(inherentRisksTempList1));
        if (lmuItem.inherentRisksToFreshwater != "" && lmuItem.inherentRisksToFreshwater != null) {
            let inherentRisksArray = [];

            inherentRisksArray = lmuItem.inherentRisksToFreshwater.split(',');

            lmu.lookUpList.inherentRisksList.map(risk => {
                if (inherentRisksArray.includes(String(risk.value))) {
                    inherentRisks.push({
                        value: risk.value,
                        label: risk.label
                    })
                }
            })
            //inherentRisks.forEach(function (element) {
            //    list.forEach(function (element1) {
            //        if (element1.options.filter(x => x.label == element.label && x.value != element.value).length > 0) {
            //            var obj = element1.options.filter(x => x.label == element.label && x.value != element.value)[0];
            //            const findIndex = element1.options.findIndex(a => a.value === obj.value);
            //            findIndex !== -1 && element1.options.splice(findIndex, 1);
            //        }
            //    });
            //});
        }

        setFormBasicDetail({
            itemId: itemId,
            isNonEffectiveArea: isNonEffectiveArea,
            effectiveArea: lmuItem.effectiveAreaHa,
            isReceivesLiquidEffluent: lmuItem.isReceivesLiquidEffluent,
            isReceivesFreshWaterIrrigation: lmuItem.isReceivesFreshWaterIrrigation,
            useWaterForFrostProtection: lmuItem.useWaterForFrostProtection,
            applyAgrichemicalsToLandUnit: lmuItem.applyAgrichemicalsToLandUnit,
            stemCountPerHa: lmuItem.stemCountPerHA,
            topographyId: lmuItem.topographyId,
            soilTypePredominantId: lmuItem.soilTypePredominantId,
            soilTypePredominantPercentage: lmuItem.soilTypePredominantPercentage,
            soilTypeOtherId: lmuItem.soilTypeOther,
            soilTypeOtherPercentage: lmuItem.soilTypeOtherPercentage,
            drainabilityPredominant: lmuItem.drainabilityPredominant,
            drainabilityOther: lmuItem.drainabilityOther,
            inherentRisks: inherentRisks,
            isLeasedBlock: lmuItem.isLeasedBlock,
            leasedAreaHa: lmuItem.leasedAreaHa,
            topographyList: lmu.lookUpList.topographyList,
            soilTypePredominantList: lmu.lookUpList.soilTypePredominantList,
            soilTypeOtherList: lmu.lookUpList.soilTypeOtherList,
            drainabilityPredominantList: lmu.lookUpList.drainabilityPredominantList,
            drainabilityOtherList: lmu.lookUpList.drainabilityOtherList,
            inherentRisksList: lmu.lookUpList.inherentRisksList
        });


        
        //Selected enterprise Type
        let enterpriseTypes = []

        if (lmuItem.enterpriseType != "" && lmuItem.enterpriseType != null) {
            let enterpriseTypesArray = [];

            enterpriseTypesArray = lmuItem.enterpriseType.split(',');

            lmu.lookUpList.allLUsEnterpriseTypeList.filter(x => x.itemId == lmuItem.itemId).map(type => {
                if (enterpriseTypesArray.includes(String(type.value))) {
                    enterpriseTypes.push({
                        value: type.value,
                        label: type.label,
                        enterpriseTypeText: type.enterpriseTypeText,
                        isDisabled: type.isDisabled
                    })
                }
            })
        }
        var showFrostProtection = false;
        if (enterpriseTypes.filter(x => x.enterpriseTypeText == "Horticulture").length > 0) {
            showFrostProtection = true;
        }
        setFormEnterpriseDetail({
            itemId: itemId,
            enterpriseType: enterpriseTypes,
            enterpriseDefinition: lmuItem.enterpriseDefinition,
            generalFarmingActivities: lmuItem.generalFarmingActivities,
            enterpriseTypeList: lmu.lookUpList.allLUsEnterpriseTypeList.filter(x => x.itemId == lmuItem.itemId),
            enterpriseDefinitionList: lmu.lookUpList.enterpriseDefinitionList,
            IsLUDoesNotHaveAnyFarmingOrGrowingActivities: lmuItem.isLUDoesNotHaveAnyFarmingOrGrowingActivities, FrostProtection: lmuItem.frostProtection, ShowFrostProtection: showFrostProtection
        })

        setFormRisk({
            isRisk: item.isItemHaveAnyRisk == 'Yes' ? 'Yes' : 'No',
            riskComment: item.riskDescription == null ? '' : item.riskDescription,
            isMitigated: item.isMitigated,
            mitigatedComment: item.mitigatedDescription == null ? '' : item.mitigatedDescription,
        });

        lmuItem.lmuSoils.forEach(s => {
            delete s.createdOn;
            delete s.updatedOn;
        })


        setFormSoil({
            ...formSoil,
            lmuId: lmuItem.id,
            soilDetail: lmuItem.lmuSoils,
            soilMapGroupsList: lmu.lookUpList.soilMapGroupsList,
            soilMapOrdersList: lmu.lookUpList.soilMapOrdersList,
        });
    }

    const editMapItemFromNavigation = (itemId, itemsList, lmu) => {

        const item = itemsList.filter(item => item.id == itemId)[0];
        const lmuItem = item?.lmuItems[0];
        const isNonContiguousItem = item.isConnectedToAnotherLMU == null || item.isConnectedToAnotherLMU == false || item.isConnectedToAnotherLMU == undefined ? false : true
        const isNonEffectiveArea = lmuItem.isNonEffectiveArea == null || lmuItem.isNonEffectiveArea == false || lmuItem.isNonEffectiveArea == undefined ? false : true
        const isSameAsMaster = lmuItem.isSameAsMaster == null || lmuItem.isSameAsMaster == false || lmuItem.isSameAsMaster == undefined ? false : true
        setTypeId(item.itemTypeId);
        setItemModal({ showItemModal: true, itemId: itemId, item: item, showArchiveOption: item.showArchiveOption });

        const masterLmus = lmu.lookUpList.masterLmuList.filter(item => item.id != itemId);

        setMasterLmuList(masterLmus);

        let kiwifruitVariety = []
        if (lmuItem.kiwifruitVariety != "" && lmuItem.kiwifruitVariety != null) {
            let kiwifruitVarietyArray = [];

            kiwifruitVarietyArray = lmuItem.kiwifruitVariety.split(',');

            lmu.lookUpList.kiwifruitVarietyList.map(type => {
                if (kiwifruitVarietyArray.includes(String(type.value))) {
                    kiwifruitVariety.push({
                        value: type.value,
                        label: type.label
                    })
                }
            })
        }

        setFormGeneral({
            itemId: itemId,
            LmuTypeId: lmuItem.lmuTypeId,
            Name: item.name,
            Description: item.description,
            itemColour: item.colour,
            itemSizeHa: lmuItem.sizeHa,
            itemLength: 0.0,
            kiwifruitVariety: kiwifruitVariety,
            rootStockAge: lmuItem.rootStockAge
        });

        setItemMapDetail({ ...itemMapDetail, area: lmuItem.sizeHa, coordinates: item.mapCoordinates });

        setIsNonContiguous(isNonContiguousItem);

        setIsNonEffective(isNonEffectiveArea);

        setFormContiguous({ itemId: itemId, isNonContiguousItem: isNonContiguousItem, masterLmuId: item.lmuId, isSameAsMaster: isSameAsMaster });

        setFormDescription({ itemId: itemId, isAddsToBiodiversity: item.isAddsToBiodiversity, biodiversityComment: item.biodiversityComment });

        //Selected inherentRisks
        let inherentRisks = []
        var list = JSON.parse(JSON.stringify(inherentRisksTempList1));
        if (lmuItem.inherentRisksToFreshwater != "" && lmuItem.inherentRisksToFreshwater != null) {
            let inherentRisksArray = [];

            inherentRisksArray = lmuItem.inherentRisksToFreshwater.split(',');

            lmu.lookUpList.inherentRisksList.map(risk => {
                if (inherentRisksArray.includes(String(risk.value))) {
                    inherentRisks.push({
                        value: risk.value,
                        label: risk.label
                    })
                }
            })
            //inherentRisks.forEach(function (element) {
            //    list.forEach(function (element1) {
            //        if (element1.options.filter(x => x.label == element.label && x.value != element.value).length > 0) {
            //            var obj = element1.options.filter(x => x.label == element.label && x.value != element.value)[0];
            //            const findIndex = element1.options.findIndex(a => a.value === obj.value);
            //            findIndex !== -1 && element1.options.splice(findIndex, 1);
            //        }
            //    });
            //});
        }

        setFormBasicDetail({
            itemId: itemId,
            isNonEffectiveArea: isNonEffectiveArea,
            effectiveArea: lmuItem.effectiveAreaHa,
            isReceivesLiquidEffluent: lmuItem.isReceivesLiquidEffluent,
            isReceivesFreshWaterIrrigation: lmuItem.isReceivesFreshWaterIrrigation,
            useWaterForFrostProtection: lmuItem.useWaterForFrostProtection,
            applyAgrichemicalsToLandUnit: lmuItem.applyAgrichemicalsToLandUnit,
            stemCountPerHa: lmuItem.stemCountPerHA,
            topographyId: lmuItem.topographyId,
            soilTypePredominantId: lmuItem.soilTypePredominantId,
            soilTypePredominantPercentage: lmuItem.soilTypePredominantPercentage,
            soilTypeOtherId: lmuItem.soilTypeOther,
            soilTypeOtherPercentage: lmuItem.soilTypeOtherPercentage,
            drainabilityPredominant: lmuItem.drainabilityPredominant,
            drainabilityOther: lmuItem.drainabilityOther,
            inherentRisks: inherentRisks,
            isLeasedBlock: lmuItem.isLeasedBlock,
            leasedAreaHa: lmuItem.leasedAreaHa,
            topographyList: lmu.lookUpList.topographyList,
            soilTypePredominantList: lmu.lookUpList.soilTypePredominantList,
            soilTypeOtherList: lmu.lookUpList.soilTypeOtherList,
            drainabilityPredominantList: lmu.lookUpList.drainabilityPredominantList,
            drainabilityOtherList: lmu.lookUpList.drainabilityOtherList,
            inherentRisksList: lmu.lookUpList.inherentRisksList
        });

        //Selected enterprise Type
        let enterpriseTypes = []

        if (lmuItem.enterpriseType != "" && lmuItem.enterpriseType != null) {
            let enterpriseTypesArray = [];

            enterpriseTypesArray = lmuItem.enterpriseType.split(',');

            lmu.lookUpList.allLUsEnterpriseTypeList.map(type => {
                if (enterpriseTypesArray.includes(String(type.value))) {
                    enterpriseTypes.push({
                        value: type.value,
                        label: type.label,
                        enterpriseTypeText: type.enterpriseTypeText,
                        isDisabled: type.isDisabled
                    })
                }
            })
        }

        setFormEnterpriseDetail({
            itemId: itemId,
            enterpriseType: enterpriseTypes,
            enterpriseDefinition: lmuItem.enterpriseDefinition,
            generalFarmingActivities: lmuItem.generalFarmingActivities,
            enterpriseTypeList: lmu.lookUpList.allLUsEnterpriseTypeList.filter(x => x.itemId == lmuItem.itemId),
            enterpriseDefinitionList: lmu.lookUpList.enterpriseDefinitionList, FrostProtection: lmuItem.frostProtection
        })

        setFormRisk({
            isRisk: item.isItemHaveAnyRisk == 'Yes' ? 'Yes' : 'No',
            riskComment: item.riskDescription == null ? '' : item.riskDescription,
            isMitigated: item.isMitigated,
            mitigatedComment: item.mitigatedDescription == null ? '' : item.mitigatedDescription,
        });

        lmuItem.lmuSoils.forEach(s => {
            delete s.createdOn;
            delete s.updatedOn;
        })

        setFormSoil({
            ...formSoil,
            lmuId: lmuItem.id,
            soilDetail: lmuItem.lmuSoils,
            soilMapGroupsList: lmu.lookUpList.soilMapGroupsList,
            soilMapOrdersList: lmu.lookUpList.soilMapOrdersList,
        });

    }

    const handleEditItem = (e) => {
        const itemId = e.target.id;
        editMapItem([], itemId, false);
    }

    const handleDeleteItem = (e) => {
        const itemId = e.target.id;
        setItemDeleteModal({ showModal: true, itemId: itemId })
    }

    const handleItemModalShowHide = () => {

        if (itemModal.showItemModal) {
            getLmu();
        }
        setTypeId(0);
        setItemModal({ showItemModal: !itemModal.showItemModal, itemId: '', item: [] });
        setMapDetails({ ...mapDetails, isClear: true });
    }

    const handleItemDeleteModalClose = () => {
        setItemDeleteModal({ showModal: false, itemId: 0 });
    }

    const handleItemDeleteModalShowHide = () => {

        setItemDeleteModal({ showModal: !itemDeleteModal.showModal, itemId: '' });
    }
    const handleIsLUDoesNotHaveAnyFarmingOrGrowingActivitiesChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormEnterpriseDetail({ ...formEnterpriseDetail, [name]: type == 'checkbox' ? checked : checked });
        if (checked) {
            setFormGeneral({ ...formGeneral, kiwifruitVariety: '', rootStockAge: 0 });
        }
    }
    const handleFormFieldChange = (e) => {

        const { name, value, checked, type } = e.target;
        const masterLmuId = parseInt(formContiguous.masterLmuId);

        if (name == 'soilTypePredominantPercentage' || name == 'soilTypeOtherPercentage') {
            if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
                setFormBasicDetail({ ...formBasicDetail, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else if (name == 'rootStockAge') {
            if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
                setFormGeneral({ ...formGeneral, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else {
            if (name == "isSameAsMaster") {
                if (document.getElementById('labelErr' + e.target.id) != undefined && document.getElementById('labelErr' + e.target.id) != null) {
                    document.getElementById('labelErr' + e.target.id).remove();
                }
                if (document.getElementById('labelErrBr' + e.target.id) != undefined && document.getElementById('labelErrBr' + e.target.id) != null) {
                    document.getElementById('labelErrBr' + e.target.id).remove();
                }
                setResetIsMasterDetails(checked);
                setFormContiguous({ ...formContiguous, [name]: type == 'checkbox' ? masterLmuId > 0 ? checked : false : value });
            }
            else {

                setFormGeneral({ ...formGeneral, [name]: type == 'checkbox' ? checked : value });

                if (name == "isNonContiguousItem" && checked == false) {
                    if (formContiguous.isSameAsMaster) {
                        setResetIsMasterDetails(false);
                    }
                    setFormContiguous({ ...formContiguous, masterLmuId: 0, [name]: type == 'checkbox' ? checked : value, isSameAsMaster: false });
                }
                else if (name == "masterLmuId") {
                    if (formContiguous.isSameAsMaster) {
                        setResetIsMasterDetailsWithLmuId(formContiguous.isSameAsMaster, value);
                    }
                    setFormContiguous({ ...formContiguous, [name]: type == 'checkbox' ? checked : value });
                }
                else if (name == 'isNonContiguousItem') {
                    setFormContiguous({ ...formContiguous, [name]: type == 'checkbox' ? checked : value, isSameAsMaster: true });
                    setFormDescription({ ...formDescription, [name]: type == 'checkbox' ? checked : value });
                    setFormBasicDetail({ ...formBasicDetail, [name]: type == 'checkbox' ? checked : value });
                    setFormEnterpriseDetail({ ...formEnterpriseDetail, [name]: type == 'checkbox' ? checked : value });
                }
                else {
                    setFormContiguous({ ...formContiguous, [name]: type == 'checkbox' ? checked : value});
                    setFormDescription({ ...formDescription, [name]: type == 'checkbox' ? checked : value });
                    setFormBasicDetail({ ...formBasicDetail, [name]: type == 'checkbox' ? checked : value });
                    setFormEnterpriseDetail({ ...formEnterpriseDetail, [name]: type == 'checkbox' ? checked : value });
                }

                name == "isNonContiguousItem" && checked == false ? setIsNonContiguous(false) : setIsNonContiguous(isNonContiguous)
                name == "isNonEffectiveArea" && checked == false ? setIsNonEffective(false) : setIsNonEffective(isNonEffective)
                name == "isAddsToBiodiversity" && checked == false ? setHasBiodiversity(false) : setHasBiodiversity(hasBiodiversity)

                if (name == "isNonContiguousItem" && checked == true) {
                    setIsNonContiguous(true);
                }

                if (name == "isNonEffectiveArea" && checked == true) {
                    setIsNonEffective(true);
                }

                if (name == "isAddsToBiodiversity" && checked == true) {
                    setHasBiodiversity(true);
                }
            }
        }

        if (name == 'isReceivesFreshWaterIrrigation' && checked) {
            toast.success("By ticking this box the system will automatically create a new Irrigation Area shape in the Irrigation & Frost protection page (coming further in the wizard) when this Land Unit is saved for the first time only. Please note that once created this shape is completely independent so any changes to this Land unit will not be updated in the Irrigation Area shape.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
        else if (name == 'isReceivesFreshWaterIrrigation' && !checked) {
            toast.success("If this Land Unit has already been saved unticking this box does not remove the associated Irrigation Area shape in the Irrigation & Frost protection page. If you wish to remove or update the Irrigation Area shape it will need to be done from the Irrigation & Frost Protection page.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
        if (name == 'useWaterForFrostProtection' && checked) {
            toast.success("By ticking this box the system will automatically create a new Frost Protection Area shape in the Irrigation & Frost protection page (coming further in the wizard) when this Land Unit is saved for the first time only. Please note that once created this shape is completely independent so any changes to this Land unit will not be updated in the Frost Protection Area shape.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
        else if (name == 'useWaterForFrostProtection' && !checked) {
            toast.success("If this Land Unit has already been saved unticking this box does not remove the associated Frost Protection Area shape in the Irrigation & Frost protection page. If you wish to remove or update the Frost Protection Area shape it will need to be done from the Irrigation & Frost Protection page.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
        if (name == 'applyAgrichemicalsToLandUnit' && checked) {
            toast.success("By ticking this box the system will automatically create a new Spray Area shape in the Agrichemicals page (coming further in the wizard) when this Land Unit is saved for the first time only. Please note that once created this shape is completely independent so any changes to this Land unit will not be updated in the Spray Area shape.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
        else if (name == 'applyAgrichemicalsToLandUnit' && !checked) {
            toast.success("If this Land Unit has already been saved unticking this box does not remove the associated Spray Area shape in the Agrichemicals page. If you wish to remove or update the Spray Area shape it will need to be done from the Agrichemicals page.", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
    }

    const setResetIsMasterDetails = (checked) =>
    {
        const masterLmuId = parseInt(formContiguous.masterLmuId);
        const itemId = parseInt(formContiguous.itemId);

        if (masterLmuId != 0 && checked == true) {
            const parentLUDetails = itemsList.filter(parentLU => parentLU.id == masterLmuId)[0];
            const parentLmuItem = parentLUDetails?.lmuItems[0];
            const color = parentLUDetails.colour;
            setFormGeneral({ ...formGeneral, itemColour: color })

            setFormBasicDetail({
                ...formBasicDetail,
                isReceivesLiquidEffluent: parentLmuItem.isReceivesLiquidEffluent,
                isReceivesFreshWaterIrrigation: parentLmuItem.isReceivesFreshWaterIrrigation,
                useWaterForFrostProtection: parentLmuItem.useWaterForFrostProtection,
                applyAgrichemicalsToLandUnit: parentLmuItem.applyAgrichemicalsToLandUnit,
                 isLeasedBlock: parentLmuItem.isLeasedBlock,
            });
        }
        else if (checked == false){
            const LUDetails = itemsList.filter(childLU => childLU.id == itemId)[0];
            if (LUDetails != null) {
                const LmuItem = LUDetails?.lmuItems[0];
                const color = LUDetails.colour;
                setFormGeneral({ ...formGeneral, itemColour: color })

                setFormBasicDetail({
                    ...formBasicDetail,
                    isReceivesLiquidEffluent: LmuItem.isReceivesLiquidEffluent,
                    isReceivesFreshWaterIrrigation: LmuItem.isReceivesFreshWaterIrrigation,
                    useWaterForFrostProtection: LmuItem.useWaterForFrostProtection,
                    applyAgrichemicalsToLandUnit: LmuItem.applyAgrichemicalsToLandUnit,
                    isLeasedBlock: LmuItem.isLeasedBlock,
                });
            }
            else {
                setFormGeneral({ ...formGeneral, itemColour: '#0d535d' })
                setFormBasicDetail({
                    ...formBasicDetail,
                    isReceivesLiquidEffluent: false,
                    useWaterForFrostProtection: false,
                    applyAgrichemicalsToLandUnit: false,
                    isLeasedBlock: false,
                });
                
            }
        }
    }
    const setResetIsMasterDetailsWithLmuId = (checked,lmuId) => {
        const masterLmuId = parseInt(lmuId);
        const itemId = parseInt(formContiguous.itemId);

        if (masterLmuId != 0 && checked == true) {
            const parentLUDetails = itemsList.filter(parentLU => parentLU.id == masterLmuId)[0];
            const parentLmuItem = parentLUDetails?.lmuItems[0];
            const color = parentLUDetails.colour;
            setFormGeneral({ ...formGeneral, itemColour: color })

            setFormBasicDetail({
                ...formBasicDetail,
                isReceivesLiquidEffluent: parentLmuItem.isReceivesLiquidEffluent,
                isReceivesFreshWaterIrrigation: parentLmuItem.isReceivesFreshWaterIrrigation,
                useWaterForFrostProtection: parentLmuItem.useWaterForFrostProtection,
                applyAgrichemicalsToLandUnit: parentLmuItem.applyAgrichemicalsToLandUnit,
                isLeasedBlock: parentLmuItem.isLeasedBlock,
            });
        }
        else if (checked == false) {
            const LUDetails = itemsList.filter(childLU => childLU.id == itemId)[0];
            if (LUDetails != null) {
                const LmuItem = LUDetails?.lmuItems[0];
                const color = LUDetails.colour;
                setFormGeneral({ ...formGeneral, itemColour: color })

                setFormBasicDetail({
                    ...formBasicDetail,
                    isReceivesLiquidEffluent: LmuItem.isReceivesLiquidEffluent,
                    isReceivesFreshWaterIrrigation: LmuItem.isReceivesFreshWaterIrrigation,
                    useWaterForFrostProtection: LmuItem.useWaterForFrostProtection,
                    applyAgrichemicalsToLandUnit: LmuItem.applyAgrichemicalsToLandUnit,
                    isLeasedBlock: LmuItem.isLeasedBlock,
                });
            }
            else {
                setFormGeneral({ ...formGeneral, itemColour: '#0d535d' })
                setFormBasicDetail({
                    ...formBasicDetail,
                    isReceivesLiquidEffluent: false,
                    useWaterForFrostProtection: false,
                    applyAgrichemicalsToLandUnit: false,
                    isLeasedBlock: false,
                });

            }
        }
    }

    const handleItemPopupRisk = (e) => {
        const { name, value, checked, type } = e.target;
        setFormRisk({ ...formRisk, [name]: type == 'checkbox' ? checked : value });
    }

    const handleOpenActionPopup = (itemId, actionId) => {
        const itemDetails = lmu.itemList.filter(item => item.id == itemId)[0];
        if (itemDetails != undefined) {
            setActionModal({ ...actionModal, showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
        }
    };

    const handleClosedActionShow = (e) => {
        setIsClosedActionShow(e.target.checked);
    }

    const handleActionClose = () => {
        setActionModal({ ...actionModal, showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, itemList) => {
        const itemDetails = itemList.filter(items => items.itemId == itemId)[0];
        setActionModal({ ...actionModal, showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleDeleteItemConfirm = async (event) => {
        setSavingDeleteItem(true);
        const itemid = itemDeleteModal.itemId;

        try {
            const response = await axios.get(
                '/api/lmu/deleteItem/', {
                params: {
                    itemId: itemid
                }
            });
            if (response.data != null) {
                const response = await axios.get(
                    '/api/lmu/getLmu/', {
                    params: {
                        farmId: farmId
                    }
                });
                const itemsList = response.data.itemsList;
                itemsList.forEach((item) => {
                    item.nonContiguousItems = [];

                    itemsList.map(nc => {
                        if (nc.lmuId === item.id) {
                            item.nonContiguousItems.push(nc);
                        }
                    })

                })
                setLMUItems(itemsList);
                const lmuList = itemsList.map(item => item.lmuItems[0])
                const effectiveLmuList = lmuList.filter(item => !item.isNonEffectiveArea)
                let effectiveAreaTotal = 0;
                let totalArea = 0;

                lmuList.forEach(lmu => {
                    totalArea += lmu.sizeHa;
                })
                effectiveLmuList.forEach(lmu => {
                    effectiveAreaTotal += lmu.effectiveAreaHa > 0 ? lmu.effectiveAreaHa : lmu.sizeHa;
                })
                setMasterLmuList(response.data.lookUpList.masterLmuList);
                setBlockSummary({ total: lmuList.length, effective: effectiveLmuList.length, totalArea: totalArea, totalEffectiveArea: effectiveAreaTotal })
                setLmu({ ...lmu, itemList: itemsList, lookUpList: response.data.lookUpList });
                setItemsList(itemsList);
                toast.success("LMU details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingDeleteItem(false);
                setItemDeleteModal({ showModal: false, itemId: 0 })
                setItemModal({ ...itemModal, showItemModal: false });
                resetItemModal();
                setMapDetails({ ...mapDetails, isClear: true });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleItemSave = async () => {
        setSaving(true);

        if (formContiguous.isSameAsMaster) {
            if (formContiguous.masterLmuId == 0) {
                var parent = document.getElementById('masterLmuId').parentElement;
                var newlabel = document.createElement("Label");
                newlabel.setAttribute("class", 'errorMessage');
                newlabel.setAttribute("id", 'labelErr' + parent.id);
                newlabel.innerHTML = "Please select a Parent LU";
                parent.append(newlabel);
                setSaving(false);
                return false;
            }
            else {
                var label = document.getElementById('labelErr' + document.getElementById('masterLmuId').parentElement.id);
                if (label != undefined && label != null) {
                    label.innerHTML = "";
                }
                setResetIsMasterDetails(true);
            }
        }

        let inherentRisks = '';

        if (Array.isArray(formBasicDetail.inherentRisks)) {
            let inherentRisksArr = [];
            formBasicDetail.inherentRisks.forEach(risk => {
                inherentRisksArr.push(risk.value);
            })
            inherentRisks = inherentRisksArr.join(",");
        } else {
            inherentRisks = formBasicDetail.inherentRisks.toString();
        }

        let kiwifruitVariety = '';
        if (Array.isArray(formGeneral.kiwifruitVariety)) {
            let kiwifruitVarietyArr = [];
            formGeneral.kiwifruitVariety.forEach(type => {
                kiwifruitVarietyArr.push(type.value);
            })
            kiwifruitVariety = kiwifruitVarietyArr.join(",");
        }
        else if (typeof formGeneral.kiwifruitVariety === 'object' && formGeneral.kiwifruitVariety.constructor === Object) {
            kiwifruitVariety = formGeneral.kiwifruitVariety.value.toString();
        }
        else {
            kiwifruitVariety = formGeneral.kiwifruitVariety.toString();
        }

        let enterpriseTypes = '';
        if (formEnterpriseDetail.enterpriseTypeList.length == 1) {
            if (!nonKiwifruitEnterprisesOnThisLand) {
                formEnterpriseDetail.enterpriseType = formEnterpriseDetail.enterpriseTypeList[0];
                if (Array.isArray(formEnterpriseDetail.enterpriseType)) {
                    let enterpriseTypesArr = [];
                    formEnterpriseDetail.enterpriseType.forEach(type => {
                        enterpriseTypesArr.push(type.value);
                    })
                    enterpriseTypes = enterpriseTypesArr.join(",");
                }
                else if (typeof formEnterpriseDetail.enterpriseType === 'object' && formEnterpriseDetail.enterpriseType.constructor === Object)
                {
                    enterpriseTypes = formEnterpriseDetail.enterpriseType.value.toString();
                }
                else {
                    enterpriseTypes = formEnterpriseDetail.enterpriseType.toString();
                }
            }
            else {
                enterpriseTypes = '';
            }
        }
        else {
            if (Array.isArray(formEnterpriseDetail.enterpriseType)) {
                let enterpriseTypesArr = [];
                formEnterpriseDetail.enterpriseType.forEach(type => {
                    enterpriseTypesArr.push(type.value);
                })
                enterpriseTypes = enterpriseTypesArr.join(",");
            }
            else if (typeof formEnterpriseDetail.enterpriseType === 'object' && formEnterpriseDetail.enterpriseType.constructor === Object) {
                enterpriseTypes = formEnterpriseDetail.enterpriseType.value.toString();
            }
            else {
                enterpriseTypes = formEnterpriseDetail.enterpriseType.toString();
            }
        }

        var totalSoilPer = 0.0;
        if (formBasicDetail.soilTypePredominantId != undefined && formBasicDetail.soilTypePredominantId != null && formBasicDetail.soilTypePredominantId != 0) {
            if (formBasicDetail.soilTypePredominantPercentage != undefined && formBasicDetail.soilTypePredominantPercentage != null && formBasicDetail.soilTypePredominantPercentage != 0) {
                totalSoilPer = parseFloat(formBasicDetail.soilTypePredominantPercentage);
            }
        }
        if (formBasicDetail.soilTypeOtherId != undefined && formBasicDetail.soilTypeOtherId != null && formBasicDetail.soilTypeOtherId != 0) {
            if (formBasicDetail.soilTypeOtherPercentage != undefined && formBasicDetail.soilTypeOtherPercentage != null && formBasicDetail.soilTypeOtherPercentage != 0) {
                totalSoilPer = totalSoilPer + parseFloat(formBasicDetail.soilTypeOtherPercentage);
            }
        }
        if (Math.ceil(totalSoilPer, 0) > 100) {
            toast.error("Pre dominant Soil type & Other Soil type can not be greater than 100", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            setSaving(false);
            return false;
        }
        const form = {
            Id: formGeneral.itemId,
            FarmId: farmId,
            ItemTypeId: typeId,
            IsConnectedToAnotherLMU: formContiguous.isNonContiguousItem,
            LMUId: formContiguous.masterLmuId, 
            Name: formGeneral.Name,
            Description: formGeneral.Description,
            Colour: formGeneral.itemColour,
            IsAddsToBioDiversity: formDescription.isAddsToBiodiversity,
            BioDiversityComment: formDescription.biodiversityComment,
            IsItemHaveAnyRisk: formRisk.isRisk,
            RiskDescription: formRisk.riskComment,
            IsMitigated: formRisk.isMitigated,
            MitigatedDescription: formRisk.mitigatedComment,
            MapMarkerType: 'polygon',
            MapCoordinates: itemMapDetail.coordinates,
            IsAchievementOfNote: false,  // todo
            AchievementOfNoteComments: '',  // todo
            IsActive: true,
            UpdatedBy: userId,
            isEdited: formGeneral.isEdited,
            LmuItem: {
                IsNonEffectiveArea: formBasicDetail.isNonEffectiveArea,
                LmuTypeId: parseInt(formGeneral.LmuTypeId),
                EffectiveAreaHa: formBasicDetail.isNonEffectiveArea == false ? formBasicDetail.effectiveArea : 0.0,
                SizeHa: itemMapDetail.area,
                Length: itemMapDetail.perimeter,
                IsReceivesLiquidEffluent: formBasicDetail.isReceivesLiquidEffluent,
                IsReceivesFreshWaterIrrigation: formBasicDetail.isReceivesFreshWaterIrrigation,
                UseWaterForFrostProtection: formBasicDetail.useWaterForFrostProtection,
                ApplyAgrichemicalsToLandUnit: formBasicDetail.applyAgrichemicalsToLandUnit,
                StemCountPerHA: formBasicDetail.stemCountPerHa,
                TopographyId: formBasicDetail.topographyId,
                SoilTypePredominantId: formBasicDetail.soilTypePredominantId,
                SoilTypePredominantPercentage: formBasicDetail.soilTypePredominantPercentage,
                SoilTypeOther: formBasicDetail.soilTypeOtherId,
                SoilTypeOtherPercentage: formBasicDetail.soilTypeOtherPercentage,
                DrainabilityPredominant: formBasicDetail.drainabilityPredominant,
                DrainabilityOther: formBasicDetail.drainabilityOther,
                EnterpriseType: enterpriseTypes,
                EnterpriseDefinition: formEnterpriseDetail.enterpriseDefinition,
                GeneralFarmingActivities: formEnterpriseDetail.generalFarmingActivities,
                InherentRisksToFreshwater: inherentRisks,
                IsLeasedBlock: formBasicDetail.isLeasedBlock,
                LeasedAreaHa: formBasicDetail.isLeasedBlock == true ? formBasicDetail.leasedAreaHa : 0.0,
                IsActive: true,
                UpdatedBy: userId,
                LMUSoils: formSoil.soilDetail,
                IsSameAsMaster: formContiguous.isSameAsMaster,
                IsLUDoesNotHaveAnyFarmingOrGrowingActivities: formEnterpriseDetail.IsLUDoesNotHaveAnyFarmingOrGrowingActivities,
                FrostProtection: formEnterpriseDetail.FrostProtection,
                KiwifruitVariety: kiwifruitVariety,
                rootStockAge: formGeneral.rootStockAge
            },
        }

        try {
            if (!Validate(form)) {
                const response = await axios.post(
                    '/api/lmu/saveItem/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    });
                if (response.data != null) {
                    const response = await axios.get(
                        '/api/lmu/getLmu/', {
                        params: {
                            farmId: farmId
                        }
                    });
                    const itemsList = response.data.itemsList;
                    itemsList.forEach((item) => {
                        item.nonContiguousItems = [];

                        itemsList.map(nc => {
                            if (nc.lmuId === item.id) {
                                item.nonContiguousItems.push(nc);
                            }
                        })

                    })
                    setLMUItems(itemsList);
                    const lmuList = itemsList.map(item => item.lmuItems[0])
                    const effectiveLmuList = lmuList.filter(item => !item.isNonEffectiveArea)
                    let effectiveAreaTotal = 0;
                    let totalArea = 0;

                    lmuList.forEach(lmu => {
                        totalArea += lmu.sizeHa;
                    })
                    effectiveLmuList.forEach(lmu => {
                        effectiveAreaTotal += lmu.effectiveAreaHa > 0 ? lmu.effectiveAreaHa : lmu.sizeHa;
                    })
                    setMasterLmuList(response.data.lookUpList.masterLmuList);
                    setBlockSummary({ total: lmuList.length, effective: effectiveLmuList.length, totalArea: totalArea, totalEffectiveArea: effectiveAreaTotal })
                    setLmu({ ...lmu, itemList: itemsList, lookUpList: response.data.lookUpList });
                    setItemsList(itemsList);
                    setSaving(false);
                    toast.success("LMU details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setItemModal({ ...itemModal, showItemModal: false });
                    resetItemModal();
                    setMapDetails({ ...mapDetails, isClear: true, polyType: '' });
                }
            }
            else setSaving(false);
        } catch (error) {
            setSaving(false);
            console.log(error);
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message, { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("No server response", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleItemClick = (event, typeid, polyType) => {
        setTypeId(typeid);
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
    };

    const handleBlockOnlySelect = (event, itemId, zoomlevel) => {
        const { name, value, checked, type } = event.target;
        let selectedItemId = 0;
        if (name == 'isViewBlockOnly' && checked == true) {
            selectedItemId = itemId;
        }
        const defaultZoomlevel = zoomlevel == null ? mapDetails.zoomLevel : zoomlevel;
        setBlockOnly({ ...blockOnly, itemId: selectedItemId, defaultZoomLevel: defaultZoomlevel, [name]: type == 'checkbox' ? checked : value });
    }

    const handleBlockZoomlevel = (event, itemId) => {
        const { name, value, checked, type } = event.target;

        if (blockOnly.isViewBlockOnly = true && itemId == blockOnly.itemId) {
            setBlockOnly({ ...blockOnly, itemId: itemId, [name]: type == 'checkbox' ? checked : parseInt(value) });
        }
    }

    const handleCenterPinClick = (event, polyType, itemId) => {
        setBlockOnly({ ...blockOnly, itemId: itemId })
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
    }

    const handleCenterPinPopupClose = () => {
        handleClearSelection();
        setBlockOnly({ ...blockOnly, showCenterPinPopup: false, name: '', itemid: 0, coordinates: '', actionType: '' })
        setMapDetails({ ...mapDetails, isClear: true });
    }

    const handleBlockZoomlevelSave = (event, itemId, value) => {
        if (blockOnly.itemId == itemId) {
            setBlockOnly({ ...blockOnly, defaultZoomLevel: value })
            handleZoomLevelSave();
        }
    }

    const handleZoomLevelSave = async () => {

        const form = {
            Id: blockOnly.id,
            ItemId: blockOnly.itemId,
            DefaultZoomlevel: blockOnly.defaultZoomLevel,
            updatedBy: userId
        }
        try {
            const response = await axios.post(
                '/api/lmu/saveDefaultZoomLevel/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                });
            if (response.data != null) {
                getLmu();
                toast.success("LMU Default Zoom level saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            }
        } catch (error) {
            console.log(error);
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message, { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("No server response", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleCenterPinSave = async () => {

        const form = {
            Id: blockOnly.id,
            ItemId: blockOnly.itemId,
            CenterCoordinates: blockOnly.coordinates,
            DefaultZoomlevel: blockOnly.defaultZoomLevel,
            updatedBy: userId
        }
        try {
            const response = await axios.post(
                '/api/lmu/saveCenterPin/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                });
            if (response.data != null) {
                toast.success("LMU Center Pin saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                handleCenterPinPopupClose();
                getLmu();
            }
        } catch (error) {
            console.log(error);
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message, { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("No server response", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const editLMUCenter = (mapItem, itemId, isDrag) => {
        let coordinates = '';
        const item = itemsList.filter(item => item.id == itemId)[0];

        if (isDrag) {
            coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
        }
        else {
            coordinates = item.lmuItems[0].centerCoordinates;
        }

        setBlockOnly({ ...blockOnly, itemId: itemId, name: item.name + ' - Centre Pin', coordinates: coordinates, showCenterPinPopup: true, actionType: 'Update' })

    }

    const refreshPage = () => {
        setLoading(true);
        getLmu();
    }

    const handleCenterPinDelete = async () => {
        const itemid = blockOnly.itemId;

        try {
            const response = await axios.get(
                '/api/lmu/deleteCenterPin/', {
                params: {
                    itemId: itemid
                }
            });

            if (response.data != null) {
                toast.success("LMU Center Pin deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                handleCenterPinPopupClose();
                getLmu();
            }
        } catch (error) {
            console.log(error);
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message, { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("No server response", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleClearSelection = () => {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
    }

    const handleMultiSelectInherentRiskChange = (e) => {
        setFormBasicDetail({ ...formBasicDetail, inherentRisks: '' });
        //var list = JSON.parse(JSON.stringify(inherentRisksTempList1));
        //e.forEach(function (element) {
        //    list.forEach(function (element1) {
        //        if (element1.options.filter(x => x.label == element.label && x.value != element.value).length > 0) {
        //            var obj = element1.options.filter(x => x.label == element.label && x.value != element.value)[0];
        //            const findIndex = element1.options.findIndex(a => a.value === obj.value);
        //            findIndex !== -1 && element1.options.splice(findIndex, 1);
        //        }
        //    });
        //});
        setFormBasicDetail({ ...formBasicDetail, inherentRisks: e });
    }

    const handleMultiKiwifruitVarietyChange = (e) => {
        setFormGeneral({ ...formGeneral, kiwifruitVariety: '' });
        let selectedKiwifruitVariety = '';
        setFormGeneral({ ...formGeneral, kiwifruitVariety: e });
        e.forEach(function (element) {
            if (selectedKiwifruitVariety == '') {
                selectedKiwifruitVariety = element.value;
            }
            else {
                selectedKiwifruitVariety = selectedKiwifruitVariety + ',' + element.value;
            }
        });
        setFormGeneral({ ...formGeneral, kiwifruitVariety: selectedKiwifruitVariety });
    }

    const handleMultiEnterpriseTypeChange = (e) => {
        setFormEnterpriseDetail({ ...formEnterpriseDetail, enterpriseType: '' });
        let selectedEnterpriseType = '';
        setFormEnterpriseDetail({ ...formEnterpriseDetail, enterpriseType: e });
        e.forEach(function (element) {
            if (selectedEnterpriseType == '') {
                selectedEnterpriseType = element.value;
            }
            else {
                selectedEnterpriseType = selectedEnterpriseType + ',' + element.value;
            }
        });
        if (e.filter(x => x.enterpriseTypeText == "Horticulture").length > 0) {
            setFormEnterpriseDetail({ ...formEnterpriseDetail, enterpriseType: selectedEnterpriseType, ShowFrostProtection:true });
        }
        else {
            setFormEnterpriseDetail({ ...formEnterpriseDetail, enterpriseType: selectedEnterpriseType, ShowFrostProtection: false, FrostProtection:false });
        }
    }

    /*handle Toggle for nonMap View*/
    const handleItemToggle = (lmuId, isOpen, isIconUp) => {
        setToggleItemState({ ...toggleItemState, lmuId: lmuId, isOpen: isOpen, isIconUp: isIconUp })
    }
    /*handle Toggle for nonMap View*/
    const handleChildItemToggle = (lmuId, isOpen, isIconUp) => {
        setToggleChildItemState({ ...toggleChildItemState, lmuId: lmuId, isOpen: isOpen, isIconUp: isIconUp })
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        if (mapDetails.isHiglighted === false && itemId !== 0) {
            setMapDetails({ ...mapDetails, highlighteditemId: itemId, isHiglighted: true })
        }
        else if (mapDetails.isHiglighted === true && itemId === 0) {
            setMapDetails({ ...mapDetails, highlighteditemId: itemId, isHiglighted: false })
        }
    }

    /*handle LU Select Change*/
    const handleLUSelectChange = (event) => {
        const { name, value, checked, type } = event.target;
        let isblockOnly = false;
        let defaultZoomlevel = mapDetails.zoomLevel;
        if (value > 0) {
            isblockOnly = true;
            const item = itemsList.filter(item => item.id == value)[0];
            const lmuItem = item?.lmuItems[0];
            defaultZoomlevel = lmuItem.defaultZoomlevel == null ? mapDetails.zoomLevel : lmuItem.defaultZoomlevel;
        }
        setBlockOnly({ ...blockOnly, defaultZoomLevel: defaultZoomlevel  , isViewBlockOnly: isblockOnly, itemId: type == 'checkbox' ? checked : value });
    }

    const handleHideChildrenCheck = (event, lmuId) => {
        const { name, value, checked, type } = event.target;
        let selectedLuId = 0;
        let ishideChildren = true;
        if (name == 'childLUDisplay' && value == 1) {
            selectedLuId = lmuId;
            ishideChildren = false;
        }
        setHideChildren({ ...hideChildren, lmuId: lmuId, ishideChildren: ishideChildren, childLUDisplay : value });
    }

    /*Avoid unwanted page refresh*/
    const mapView = useMemo(() => {
        return (
            <MapView width={toggleSwitch == 'map' ? '80vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                itemModel={lmu}
                mapDetails={mapDetails}
                addMapItem={addMapItem}
                editMapItem={editMapItem}
                blockViewOnly={blockOnly}
                editLMUCenter={editLMUCenter}
                handleMapItemEdited={handleMapItemEdited}
                gisLayerSoilDetails={gisLayerSoilDetails}
                gisLayerTopographyDetails={gisLayerTopographyDetails}
                gisLayerWetlandDetails={gisLayerWetlandDetails}
                largeEnterpriseDetails={largeEnterpriseDetails}
            >
            </MapView>
        )
    }, [lmu, mapDetails, blockOnly, gisLayerSoilDetails, gisLayerTopographyDetails, gisLayerWetlandDetails, toggleSwitch])

    const handleGISLayerTopographyDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: true })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
        }
        else {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
        }

    }

    const handleGISLayerSoilDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: true })
        }
        else {
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
        }

    }

    const handleGISLayerWetlandDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: true })
        }
        else {
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
        }

    }

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }
    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{lmu.sectionName}</h2></Col>
                {lmu.isWizardCompleted != true && !loading &&
                    <Col>
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    <Button className="float-end btn-next-page" onClick={(section) => handleWizardChanged(lmu.section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                                    <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(lmu.section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(lmu.section)}><FaChevronLeft /> Back  </Button>
                        </>
                    </Col>
                }
            </Row>
            {
                loading ?
                    <SpinnerContainer />
                    :
                    <div>
                        <Row>
                            <div style={{ textAlign: '-webkit-center' }}>
                                <Col>
                                    <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                                </Col>
                            </div>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col id='mapView'>
                                <Row className="map-layer">
                                    <Col>
                                        <Row>
                                            <Col sm="7"></Col>
                                            <Col sm="5">
                                                <div className="map-item-edit-div">
                                                    <Label className="map-checkbox-label1">Edit Item</Label>
                                                    <Input className="map-item-edit-checkbox1" style={{}} type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />&nbsp;&nbsp;
                                                    <Label className="map-checkbox-label">Show Label</Label>
                                                    <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                                </div>
                                            </Col>
                                        </Row>
                                        {mapView}
                                    </Col>
                                </Row>
                            </Col>
                            <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "20vw" }}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                                            <Row>
                                                                <Col sm="4" style={{ display:"none" }}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="external-map-title">External Map Layers</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerTopographyDetails.showGISLayer} onChange={handleGISLayerTopographyDetails} name="gisLayerTopographyDetails" id="gisLayerTopographyDetails" />
                                                                            {'   '}<Label className="">Show Topography map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerSoilDetails.showGISLayer} onChange={handleGISLayerSoilDetails} name="gisLayerSoilDetails" id="gisLayerSoilDetails" />
                                                                            {'   '}<Label className="">Show Soil map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerWetlandDetails.showGISLayer} onChange={handleGISLayerWetlandDetails} name="gisLayerWetlandDetails" id="gisLayerWetlandDetails" />
                                                                            {'   '}<Label className="">Show Wetland map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4>Separate property into the Land Units (LU's).</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="info-text">LU's are an area of contiguous or non-contiguous land with similar natural and/ or biophysical characteristics. These units are based on the natural and modified biophysical characteristics of the land. Create separate LU's where distinct differences exist in biophysical characteristics e.g., flatland versus rolling hills, or stony soil versus a deep silt.</Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            { /* Map Functions */}
                                                            <Row className="my-3">
                                                                <Col sm="12" key={uuid()}>
                                                                    <label className="outlineLabel para-light-sm ">Click the icon then click on the farm map to add an LU</label>
                                                                    <br />
                                                                    <Row>
                                                                        {lmu.itemTypes.map(type =>
                                                                            <Col key={uuid()} sm="6" className="style-item-display">
                                                                                <Row>
                                                                                    <Col sm="12">
                                                                                        {type.markerType === "polygon" &&
                                                                                            <>
                                                                                                <img
                                                                                                    className="featureIcon"
                                                                                                    src="/images/Icons/fwfp-icon-land_units.png"
                                                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                                                /> <label className="itemTypeStyle"> Land Units</label>
                                                                                            </>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        <Col sm="6">
                                                                            <Button style={{ float: "right" }} className="my-3 button-common" color="warning" size="sm" onClick={handleClearSelection}>
                                                                                Clear Selection
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                            {
                                                                blockOnly.islargeEnterprise == true &&
                                                                <Row>
                                                                    <Col sm="8">
                                                                        <Label>LARGE FARM - this farm is “large” - select master LU separately</Label>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <FormGroup>
                                                                            {itemsList != undefined && itemsList != null &&
                                                                                <Input type="select" className="float-end" onChange={handleLUSelectChange} name="selectedLMUId" id="selectedLMUId" value={blockOnly.itemId}>
                                                                                    <option value={0} >Entire Farm</option>
                                                                                    {itemsList.filter(lmu => !lmu.isConnectedToAnotherLMU).map(Option =>
                                                                                        <option key={uuid()} value={Option.id}>{Option.name}</option>
                                                                                    )}
                                                                                </Input>
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block', }}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                                            <Row>
                                                                <Col sm="4" style={{ display: "none" }}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="external-map-title">External Map Layers</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerTopographyDetails.showGISLayer} onChange={handleGISLayerTopographyDetails} name="gisLayerTopographyDetails" id="gisLayerTopographyDetails" />
                                                                            {'   '}<Label className="">Show Topography map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerSoilDetails.showGISLayer} onChange={handleGISLayerSoilDetails} name="gisLayerSoilDetails" id="gisLayerSoilDetails" />
                                                                            {'   '}<Label className="">Show Soil map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input className="" type="checkbox" checked={gisLayerWetlandDetails.showGISLayer} onChange={handleGISLayerWetlandDetails} name="gisLayerWetlandDetails" id="gisLayerWetlandDetails" />
                                                                            {'   '}<Label className="">Show Wetland map Layer</Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4>Separate property into the Land Units (LU's).</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="info-text">LU's are an area of contiguous or non-contiguous land with similar natural and/ or biophysical characteristics.
                                                                                These units are based on the natural and modified biophysical characteristics of the land. Create separate LU's where distinct differences exist in biophysical characteristics
                                                                                e.g., flatland versus rolling hills, or stony soil versus a deep silt.</Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                           <hr/>
                                                            { /* Map Functions */}
                                                            <Row className="my-3">
                                                                <Col sm="12" key={uuid()}>
                                                                    <label className="outlineLabel para-light-sm ">Click the icon then click on the property map to add a Land Unit</label>
                                                                    <br />
                                                                    <Row>
                                                                        {lmu.itemTypes.map(type =>
                                                                            <Col key={uuid()} sm="6" className="style-item-display">
                                                                                <Row>
                                                                                    <Col sm="12">
                                                                                        {type.markerType === "polygon" &&
                                                                                            <>
                                                                                                <img
                                                                                                className="featureIconNew"
                                                                                                src="/images/Icons/fwfp-icon-land_units.png"
                                                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                                            /> <label className="itemTypeStyle"> Land Units</label>
                                                                                            </>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        <Col sm="6">
                                                                            <Button style={{float:"right"}} className="my-3 button-common" color="warning" size="sm" onClick={handleClearSelection}>
                                                                                Clear Selection
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                            {
                                                                blockOnly.islargeEnterprise == true &&
                                                                <Row>
                                                                    <Col sm="8">
                                                                            <Label>This Property is Large - Select Land Units separately if required</Label>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <FormGroup>
                                                                            {itemsList != undefined && itemsList != null &&
                                                                                <Input type="select" className="float-end" onChange={handleLUSelectChange} name="selectedLMUId" id="selectedLMUId" value={blockOnly.itemId}>
                                                                                        <option value={0} >Whole Property</option>
                                                                                    {itemsList.filter(lmu => !lmu.isConnectedToAnotherLMU).map(Option =>
                                                                                        <option key={uuid()} value={Option.id}>{Option.name}</option>
                                                                                    )}
                                                                                </Input>
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            { /* LU Items */}
                                                            <Row key={uuid()} >
                                                                <Col>
                                                                    {
                                                                        blockOnly.isViewBlockOnly == true ?
                                                                            itemsList.filter(selected => selected.id == blockOnly.itemId).map(item =>
                                                                                <LmuItem
                                                                                    key={uuid()}
                                                                                    item={item}
                                                                                    itemTypesList={lmu.itemTypes}
                                                                                    mapDetails={mapDetails}
                                                                                    topographyList={lmu.lookUpList.topographyList}
                                                                                    soilTypePredominantList={lmu.lookUpList.soilTypePredominantList}
                                                                                    enterpriseTypeList={lmu.lookUpList.enterpriseTypeList}
                                                                                    handleEditItem={handleEditItem}
                                                                                    handleDeleteItem={handleDeleteItem}
                                                                                    itemsList={itemsList}
                                                                                    blockOnly={blockOnly}
                                                                                    hideChildren={hideChildren}
                                                                                    toggleItemState={toggleItemState}
                                                                                    toggleChildItemState={toggleChildItemState}
                                                                                    isClosedActionShow={isClosedActionShow}
                                                                                    handleBlockOnlySelect={handleBlockOnlySelect}
                                                                                    handleBlockZoomlevel={handleBlockZoomlevel}
                                                                                    handleCenterPinClick={handleCenterPinClick}
                                                                                    handleOpenActionPopup={handleOpenActionPopup}
                                                                                    handleBlockZoomlevelSave={handleBlockZoomlevelSave}
                                                                                    handleItemToggle={handleItemToggle}
                                                                                    handleChildItemToggle={handleChildItemToggle}
                                                                                    handleItemHighlight={handleItemHighlight}
                                                                                    handleClosedActionShow={handleClosedActionShow}
                                                                                    handleHideChildrenCheck={handleHideChildrenCheck }
                                                                                />
                                                                            )
                                                                            :
                                                                            itemsList.map(item =>
                                                                                <LmuItem
                                                                                    key={uuid()}
                                                                                    item={item}
                                                                                    itemTypesList={lmu.itemTypes}
                                                                                    mapDetails={mapDetails}
                                                                                    topographyList={lmu.lookUpList.topographyList}
                                                                                    soilTypePredominantList={lmu.lookUpList.soilTypePredominantList}
                                                                                    enterpriseTypeList={lmu.lookUpList.enterpriseTypeList}
                                                                                    handleEditItem={handleEditItem}
                                                                                    handleDeleteItem={handleDeleteItem}
                                                                                    itemsList={itemsList}
                                                                                    blockOnly={blockOnly}
                                                                                    hideChildren={hideChildren}
                                                                                    toggleItemState={toggleItemState}
                                                                                    toggleChildItemState={toggleChildItemState}
                                                                                    isClosedActionShow={isClosedActionShow}
                                                                                    handleBlockOnlySelect={handleBlockOnlySelect}
                                                                                    handleBlockZoomlevel={handleBlockZoomlevel}
                                                                                    handleCenterPinClick={handleCenterPinClick}
                                                                                    handleOpenActionPopup={handleOpenActionPopup}
                                                                                    handleBlockZoomlevelSave={handleBlockZoomlevelSave}
                                                                                    handleItemToggle={handleItemToggle}
                                                                                    handleChildItemToggle={handleChildItemToggle}
                                                                                    handleItemHighlight={handleItemHighlight}
                                                                                    handleClosedActionShow={handleClosedActionShow}
                                                                                    handleHideChildrenCheck={handleHideChildrenCheck }
                                                                                />
                                                                            )
                                                                    }
                                                                </Col>
                                                            </Row>

                                                            { /* Blocks Summary */}
                                                            <Row>
                                                                <Col sm="12">
                                                                    <h5 className="my-3"> Summary</h5>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <Table bordered>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            LU Name
                                                                                        </th>
                                                                                        <th>
                                                                                            Size (ha)
                                                                                        </th>
                                                                                        {/*<th>*/}
                                                                                        {/*    Effective*/}
                                                                                        {/*</th>*/}
                                                                                        <th>
                                                                                            Predominant Soil Type (%)
                                                                                        </th>
                                                                                        <th>
                                                                                            Secondary Soil Type (%)
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        lmuItems != undefined && lmuItems != null && lmuItems.filter(x => x.isConnectedToAnotherLMU == false).map(item =>
                                                                                        <>
                                                                                                <tr>
                                                                                                    <th scope="row">
                                                                                                        {item.name}
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {item.totalSize == 0 ? '' : item.totalSize}
                                                                                                    </td>
                                                                                                    {/*<td>*/}
                                                                                                    {/*    {item.lmuItems[0].effectiveAreaHa == 0 ? '' : item.lmuItems[0].effectiveAreaHa}*/}
                                                                                                    {/*</td>*/}
                                                                                                    <td>{item.lmuItems[0].soilTypePredominantText != null ? item.lmuItems[0].soilTypePredominantText : ''}{(item.lmuItems[0].soilTypePredominantText != null && item.lmuItems[0].soilTypePredominantPercentage != null && item.lmuItems[0].soilTypePredominantPercentage != 0) ? ' (' + item.lmuItems[0].soilTypePredominantPercentage + '%)' : ''}</td>
                                                                                                    <td>{item.lmuItems[0].soilTypeOtherText != null ? item.lmuItems[0].soilTypeOtherText : ''}{(item.lmuItems[0].soilTypeOtherText != null && item.lmuItems[0].soilTypeOtherPercentage != null && item.lmuItems[0].soilTypeOtherPercentage != 0) ? ' (' + item.lmuItems[0].soilTypeOtherPercentage + '%)' : ''}</td>
                                                                                                </tr>
                                                                                        </>
                                                                                    )}
                                                                                    <tr>
                                                                                        <th scope="row">
                                                                                            Total
                                                                                        </th>
                                                                                        <td>
                                                                                            {+blockSummary.totalArea.toFixed(2)}
                                                                                        </td>
                                                                                        <td>
                                                                                            {/*{blockSummary.totalEffectiveArea  == 0 ? '' : +blockSummary.totalEffectiveArea.toFixed(2)}*/}
                                                                                        </td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>

                                                            {/*Document Upload*/}
                                                            {
                                                                sectionObj.isDocumentUploadReq && <Documents refid={sectionObj.id} uploadType="Section"
                                                                    infoId="my-tooltip-16" showInfo={true} infoMessage="Supporting documents can include any information you've used to help make your decisions on land unit assignment.  Examples include maps that show topography, soil type or irrigation layout, etc."
                                                                    open={false} showArrow={true}></Documents>
                                                            }

                                                            {/*Actions to Consider*/}
                                                            {
                                                                sectionObj.isFarmAPTActionsReq && <ActionsToConsider sectionId={sectionObj.id} />
                                                            }                                                     

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    
            }

            <Modal style={{ maxWidth: '1250px', width: '100%' }}  size="lg" isOpen={itemModal.showItemModal} toggle={handleItemModalShowHide} id={itemModal.itemId} className="itemPopup">
                <Form>
                    <ModalHeader
                        toggle={handleItemModalShowHide}>Add/ Edit Land Unit
                    </ModalHeader>

                    <ModalBody>
                        <GeneralDetailForm form={formGeneral} lmuTypesList={lmu.lookUpList.lmuTypesList} 
                            handleFormFieldChange={(e) => handleFormFieldChange(e)} />

                        {
                            formDescription.isBiodiversityReq && <BiodiversityForm form={formDescription} hasBiodiversity={hasBiodiversity}
                                handleFormFieldChange={(e) => handleFormFieldChange(e)} />
                        }
                        <NonContiguousForm
                            form={formContiguous} masterLmuList={masterLmuList} isNonContiguous={isNonContiguous}
                            handleFormFieldChange={(e) => handleFormFieldChange(e)} />
                        <br />
                        {formContiguous.isSameAsMaster == false &&
                            <InherentRisks form={formBasicDetail} handleMultiSelectChange={handleMultiSelectInherentRiskChange}></InherentRisks>
                        }
                        <AdditionalInformation form={formBasicDetail} isSameAsMaster={formContiguous.isSameAsMaster}
                            handleFormFieldChange={(e) => handleFormFieldChange(e)} > </AdditionalInformation>

                        <Row>
                            <Col sm="12">
                                <Label for="inherentRisks">Land Unit Use</Label>
                            </Col>
                            <Col sm="12">
                                <FormGroup check>
                                    <Label
                                        check
                                        for="IsLUDoesNotHaveAnyFarmingOrGrowingActivities"
                                    >
                                        This LU does not have any Farming or Growing activities
                                    </Label>
                                    <Input
                                        id="IsLUDoesNotHaveAnyFarmingOrGrowingActivities"
                                        name="IsLUDoesNotHaveAnyFarmingOrGrowingActivities"
                                        type="checkbox" checked={formEnterpriseDetail.IsLUDoesNotHaveAnyFarmingOrGrowingActivities}
                                        value={formEnterpriseDetail.IsLUDoesNotHaveAnyFarmingOrGrowingActivities}
                                        onChange={(e) => handleIsLUDoesNotHaveAnyFarmingOrGrowingActivitiesChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <br />
                        {!formEnterpriseDetail.IsLUDoesNotHaveAnyFarmingOrGrowingActivities && <>
                            <Row>
                                <Col sm="12">
                                    <Label for="inherentRisks">Vine Detail</Label>
                                </Col>
                                <Col sm="6">
                                    <Label style={{ fontSize: "11px" }}>
                                        Kiwifruit Variety(s)
                                    </Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        className="dropdown"
                                        placeholder="Select Kiwifruit Variety(s)"
                                        defaultValue={formGeneral.kiwifruitVariety}
                                        options={lmu.lookUpList.kiwifruitVarietyList}
                                        onChange={handleMultiKiwifruitVarietyChange}
                                        isMulti hideSelectedOptions={false}
                                        isClearable
                                        components={{
                                            Option
                                        }}
                                    />
                                    {/*<Input*/}
                                    {/*    bsSize="sm"*/}
                                    {/*    type="select"*/}
                                    {/*    value={formGeneral.kiwifruitVariety}*/}
                                    {/*    onChange={handleFormFieldChange}*/}
                                    {/*    name="kiwifruitVariety"*/}
                                    {/*    id="kiwifruitVariety"*/}
                                    {/*>*/}
                                    {/*    <option value={0} >Select Kiwifruit Variety</option>*/}
                                    {/*    {lmu.lookUpList != undefined && lmu.lookUpList.kiwifruitVarietyList != undefined && lmu.lookUpList.kiwifruitVarietyList.length > 0 && lmu.lookUpList.kiwifruitVarietyList.map(c =>*/}
                                    {/*        <option key={c.id} value={c.id}>{c.value}</option>*/}
                                    {/*    )}*/}
                                    {/*</Input>*/}
                                </Col>
                                <Col sm="6">
                                    <Label style={{ fontSize: "11px" }}>
                                        Root Stock Age
                                    </Label>
                                    <input className="form-control-sm form-control"
                                        type="text"
                                        value={formGeneral.rootStockAge}
                                        onChange={handleFormFieldChange}
                                        name="rootStockAge"
                                        id="rootStockAge"
                                    />
                                </Col>
                            </Row>
                            <br />
                        </>}
                        
                        
                        
                        {
                            nonKiwifruitEnterprisesOnThisLand && lmu.lookUpList.enterpriseTypeList != undefined && lmu.lookUpList.enterpriseTypeList.length > 0 && 
                            <>
                                <EnterpriseDetailForm form={formEnterpriseDetail}
                                    handleFormFieldChange={(e) => handleFormFieldChange(e)} handleMultiSelectChange={handleMultiEnterpriseTypeChange} />
                            </>
                        }
                        <Photos key={uuid()} disabledMode={false} refId={itemModal.itemId} header="Photo" uploadType="Item" ></Photos>
                        
                        {/*{formContiguous.isSameAsMaster == false &&*/}
                        {/*    <SoilForm form={formSoil} />*/}
                            
                        {/*}*/}

                    </ModalBody>

                    <ModalFooter>
                        <div>
                            {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                                <>
                                    {itemModal.showArchiveOption &&
                                        <>
                                            <Button id={itemModal.itemId} className="button-delete" color="danger" onClick={handleDeleteItem} size="sm">
                                                Archive
                                            </Button>
                                            {' '}
                                            {' '}
                                        </>
                                    }
                                    {saving ?
                                        <>
                                            <Button onClick={handleItemSave} color="success" disabled size="sm">
                                                <Row>
                                                    <Col sm="6">
                                                        <span>
                                                            {' '}Saving
                                                        </span>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Spinner size="sm">
                                                            Saving...
                                                        </Spinner>
                                                    </Col>
                                                </Row></Button>{' '}
                                        </>
                                        :
                                        <>
                                            <Button onClick={handleItemSave} color="success" size="sm">
                                                Save
                                            </Button>{' '}
                                        </>
                                    }
                                </>
                            }
                            <Button color="secondary" onClick={handleItemModalShowHide} size="sm">
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={itemDeleteModal.showModal} toggle={handleItemDeleteModalShowHide} id={itemDeleteModal.itemId}>
                <ModalHeader toggle={handleItemDeleteModalClose}>Delete Item</ModalHeader>
                <ModalBody>
                    Do you want to delete this item?
                </ModalBody>
                <ModalFooter>
                    {savingDeleteItem ?
                        <>
                            <Button onClick={handleDeleteItemConfirm} color="danger" disabled size="sm">
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={handleDeleteItemConfirm} color="danger" size="sm">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    {/*<Button color="danger" onClick={handleDeleteItemConfirm}>Yes</Button>*/}
                    <Button color="secondary" onClick={handleItemDeleteModalClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={blockOnly.showCenterPinPopup} backdrop={false} className="centerPinPopup">
                <Form>
                    <ModalHeader toggle={handleCenterPinPopupClose} >Add/Edit Block Centre</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text" name="txtShapeName" id="txtShapeName" value={blockOnly.name} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>GPS Location : </Label><Label id="lblOutlineloaction">{blockOnly.coordinates}</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {blockOnly.actionType != "Add New" ? <Button color="danger" onClick={handleCenterPinDelete} size="sm">Delete</Button> : ''}
                        <Button color="success" onClick={handleCenterPinSave} size="sm">Save</Button>
                        <Button color="primary" onClick={handleCenterPinPopupClose} size="sm">Cancel</Button>

                    </ModalFooter>
                </Form>
            </Modal>

            {actionModal.showActionPopup &&
                <Action redirectPage="/lmu"
                    handleActionClose={handleActionClose}
                    itemTypeId={actionModal.itemTypeId}
                    sectionName={lmu.section}
                    sectionId={lmu.itemTypes[0].sectionId}
                    itemId={actionModal.itemId}
                    itemName={actionModal.itemName}
                    showActionPopupModal={actionModal.showActionPopup}
                    actionId={actionModal.actionId}
                    itemTypeSelect={lmu.itemTypes}
                    itemTypeDisabled={true}>
                </Action>
            }

        </div >
    );
}

export default Lmu;