import React, { useState, useEffect } from 'react';
import { Col, Row, Form, FormGroup, Input, Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import makeAnimated from 'react-select/animated';
import ReadMoreContent from '../Utilities/Ui/ReadMore';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import './CatchmentContext.css';

const animatedComponents = makeAnimated();

const CatchmentOverview = () => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [saving, setSaving] = useState('');
    const [overviewDescription, setOverviewDescription] = useState('');
    const [significantBiodiversity, setSignificantBiodiversity] = useState('');
    const [culturalValuesDescription, setCulturalValuesDescription] = useState('');
    const [freshwaterValuesPrioritiesOutcomes, setFreshwaterValuesPrioritiesOutcomes] = useState('');
    const [overview, setOverview] = useState('');
    const [culturalValues, setCulturalValues] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));

    useEffect(() => {
        getOverview();

    }, [])

    const getOverview = async () => {
        try {
            const response = await axios.get('/api/catchmentContext/getCatchmentOverview/', {
                params: {
                    farmId: farmId
                }
            });

            setOverview(response?.data?.overview);
            setCulturalValues(response?.data?.culturalValues);
            setOverviewDescription(response?.data?.overviewDescription);
            setSignificantBiodiversity(response?.data?.significantBiodiversity);
            setCulturalValuesDescription(response?.data?.culturalValuesDescription);
            setFreshwaterValuesPrioritiesOutcomes(response?.data?.freshwaterValuesPrioritiesOutcomes);

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message);
            } else {
                toast.error("No server response");
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setSaving(true);

        try {
            const response = await axios.post(
                '/api/catchmentContext/saveCatchmentOverview/',
                JSON.stringify(
                    {
                        overviewDescription, culturalValuesDescription, farmId, userId, freshwaterValuesPrioritiesOutcomes, significantBiodiversity
                    }), {
                headers: { 'Content-Type': 'application/json' }
            });

            setTimeout(() => {
                setSaving(false);
            }, 1000)
            toast.success("Catchment Overview Details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });

        } catch (err) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)

            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Catchment Overview Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Catchment Overview Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    return (
        <>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <CardBody>
                <Form onSubmit={handleSubmit} >
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader style={{ height: '60px' }} className="card-header-dark">
                                    <h5>Catchment Information</h5>
                                </CardHeader>
                                <CardBody>
                                    <ReadMoreContent longText="Overview of the catchment including any areas that are sensitive to farming activities. Include biophysical characteristics such as soil, climate, freshwater data, and freshwater bodies" />
                                    <FormGroup>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <Input
                                                    id="overviewDescription"
                                                    name="overviewDescription"
                                                    type="textarea"
                                                    value={overviewDescription}
                                                    onChange={(e) => setOverviewDescription(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm="6">
                            <Card>
                                <CardHeader style={{ height: '60px' }} className="card-header-dark">
                                    <h5>Freshwater Values, Priorities, or Outcomes</h5>
                                </CardHeader>
                                <CardBody>
                                    <ReadMoreContent longText="Include areas identified in applicable district or regional plans, iwi management plans, action plans under the National Policy Statement for Freshwater Manager, or other secondary legislation. " />
                                    <FormGroup>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <Input
                                                    id="freshwaterValuesPrioritiesOutcomes"
                                                    name="freshwaterValuesPrioritiesOutcomes"
                                                    type="textarea"
                                                    value={freshwaterValuesPrioritiesOutcomes}
                                                    onChange={(e) => setFreshwaterValuesPrioritiesOutcomes(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" style={{ display: "none" }}>
                            <Card>
                                <CardHeader className="card-header-dark">
                                    <h5>Cultural Significance</h5>
                                </CardHeader>
                                <CardBody>
                                    <ReadMoreContent longText="Cultural Significance of the catchment, including Te Ao Maori values where appropriate                                                                                                       " />
                                    <FormGroup>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <Input
                                                    id="culturalValuesDescription"
                                                    name="culturalValuesDescription"
                                                    type="textarea"
                                                    value={culturalValuesDescription}
                                                    onChange={(e) => setCulturalValuesDescription(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card>
                                <CardHeader style={{ height: '60px' }} className="card-header-dark">
                                    <h5>Significant Biodiversity</h5>
                                </CardHeader>
                                <CardBody>
                                    <ReadMoreContent longText="Areas identified as having significant biodiversity in regional plan or catchment context document" />
                                    <FormGroup>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <Input
                                                    id="significantBiodiversity"
                                                    name="significantBiodiversity"
                                                    type="textarea"
                                                    value={significantBiodiversity}
                                                    onChange={(e) => setSignificantBiodiversity(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                        </Col>
                        <Col sm="6" style={{ display: "flex" }}>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    {saving ?
                                        <Button style={{ float: "right", marginTop: '10px', display: "inline-block", alignSelf: "flex-end", marginInlineStart: "auto" }}
                                            color="success" className="button-save"
                                            disabled
                                        >
                                            <Row>
                                                <Col sm="8">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="4">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row>
                                        </Button>
                                        :
                                        <Button className="button-save" style={{ float: "right", marginTop: '10px', display: "inline-block", alignSelf: "flex-end", marginInlineStart: "auto" }} color="success" size="sm">
                                            Save
                                        </Button>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        
                    </Row>
                </Form>
            </CardBody>
        </>
    )
}

export default CatchmentOverview