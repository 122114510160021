import { useState, useEffect } from 'react';
import React from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Collapse, Spinner } from 'reactstrap';
import { FaPlus, FaTrash, FaChevronUp, FaChevronDown, FaUpload} from 'react-icons/fa';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const Records = ({ refid, Section, showRecordsInfo, infoRecordsMessage, infoRecordsId }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddEditItem = localStorage.getItem("isAddEditItem");
    const [isOpen, setIsOpen] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [isIconUp, setIsIconUp] = useState(false);
    const [saving, setSaving] = useState('');
    const [loading, setLoading] = useState(true);
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const [recordUploadList, setRecordUploadList] = useState([]);
    const [deleteRecordPopup, setDeleteRecordPopup] = useState({ showDeleteRecordPopup: false, itemId: 0, date: '', daetails: '', fileName: '' });
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    let inputFile = [];

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        if (refid != 0) {
            try {
                const response = await axios.get(
                    '/api/item/GetItemRecords/', {
                        params: { Id: refid, FarmId: farmId }
                }
                );
                if (response.data.status) {
                    setRecordUploadList(response.data.content);
                    let tempErrors = [];
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }

            } catch (err) {
                console.log(err);
            }
        }
        setLoading(false);
    }

    const handleDateChange = (idx, date) => {
        const tempRows = [...recordUploadList];
        tempRows[idx].uploadDateText = getParsedDate(date);
        tempRows[idx].uploadDate = getDateFromString(getParsedDate(date));
        setRecordUploadList(tempRows);
    };

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleRecordRowsAddRow = () => {
        const item = {
            createdDate: getParsedDate1(new Date()),
            recordDetail: "", id: 0, recordName: '', recordPhysicalName: '', recordPath: '', recordRelativePath: '', uploadDate: null, uploadDateText:''
        };
        const error = {
            fileError: ''
        };
        setFormErrors([...formErrors, error]);
        setRecordUploadList([...recordUploadList, item]);
    };
    const handleRecordRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...recordUploadList]
        const tempErrors = [...formErrors];
        tempRows.splice(idx, 1)
        tempErrors.splice(idx, 1)
        setRecordUploadList(tempRows);
        setFormErrors(tempErrors);
    }

    const handleRecordRowsSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        let tempRecordUploadList = [...recordUploadList];
        let form = {
            ItemId: refid,
            FarmId: farmId,
            userId: userId,
            ItemRecords: []
        }
        for (var i = 0; i < tempRecordUploadList.length; i++) {
            if (tempRecordUploadList[i].recordName != '' && tempRecordUploadList[i].recordName != null && tempRecordUploadList[i].recordName != undefined) {
                var obj = {};
                obj.Id = tempRecordUploadList[i].id;
                obj.RecordDetail = tempRecordUploadList[i].recordDetail;
                obj.RecordName = tempRecordUploadList[i].recordName;
                obj.RecordPhysicalName = tempRecordUploadList[i].recordPhysicalName;
                obj.RecordPath = tempRecordUploadList[i].recordPath;
                obj.UploadDateText = tempRecordUploadList[i].uploadDate != null ? tempRecordUploadList[i].uploadDateText : "";
                form.ItemRecords.push(obj);
            }
        }
        const response = await axios.post(
            '/api/item/SaveItemRecords/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Item Records Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSaving(false);
                setTimeout(() => {
                    setRecordUploadList(response.data.content);
                    let tempErrors = [];
                    setFormErrors(tempErrors);
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }, 2000);
            }
        }
    };

    function handleRecordPopupClose() {
        /*setDeleteRecordPopup({ showDeleteRecordPopup: false, itemId: 0, date: '', daetails: '', fileName: '' });*/
    }

    function deleteRecord() {

    }

    const getParsedDate1 = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const handleDetailsChange = idx => e => {
        const tempRows = [...recordUploadList];
        tempRows[idx].recordDetail = e.target.value;
        setRecordUploadList(tempRows);
    };

    const handleRecordChange = async (e, idx) => {
        e.preventDefault();
        const tempErrors = [...formErrors];
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if ((file.size / (1024 * 1024)) > 100) {
            errors.fileError = "Sorry, uploads are restricted to a maximum of 100mb per item!";
            tempErrors[idx].fileError = "Sorry, uploads are restricted to a maximum of 100mb per item!";
        }
        else {
            tempErrors[idx].fileError = "";
        }
        setFormErrors(tempErrors);
        if (Object.keys(errors).length == 0) {

            const formData = new FormData();
            formData.append("FarmId", farmId);
            formData.append("ReferenceId", refid);
            formData.append("Type", "ItemRecord");
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            formData.append("userId", userId);

            const response = await axios.post(
                '/api/uploaddocument/UploadDocument/', formData,
                {
                    headers: { "Content-type": "multipart/form-data", "FarmId": farmId, "ReferenceId": refid, "Type": "ItemRecord", "userId": userId, "FileName": e.target.files[0].name },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    const tempRows = [...recordUploadList];
                    tempRows[idx].recordName = response.data.content.DocumentName;
                    tempRows[idx].recordPhysicalName = response.data.content.DocumentPhysicalName;
                    tempRows[idx].recordPath = response.data.content.DocumentPath;
                    tempRows[idx].recordRelativePath = response.data.content.RelativeFilepath;
                    setRecordUploadList(tempRows);
                }
            }

            //if (file.type == 'application/pdf') {
            //    const formData = new FormData();
            //    formData.append("FarmId", farmId);
            //    formData.append("ItemId", refid);
            //    formData.append("Type", "ItemRecord");
            //    formData.append("FileName", e.target.files[0].name);
            //    formData.append("File", e.target.files[0]);
            //    fetch('api/uploaddocument/UploadDocument', {
            //        method: 'POST',
            //        body: formData
            //    }).then(r => r.json()).then(res => {
            //        if (res.status) {
            //            const tempRows = [...recordUploadList];
            //            tempRows[idx].recordName = res.content.DocumentName;
            //            tempRows[idx].recordPhysicalName = res.content.DocumentPhysicalName;
            //            tempRows[idx].recordPath = res.content.DocumentPath;
            //            tempRows[idx].recordRelativePath = res.content.RelativeFilepath;
            //            setRecordUploadList(tempRows);
            //        }
            //    });
            //}
            //else {
            //    getBase64(file)
            //        .then(result => {
            //            let form = {
            //                FileName: file.name.replace(/\.[^/.]+$/, ""),
            //                Type: "ItemRecord",
            //                Base64Image: result,
            //                RefId: refid,
            //                FarmId: farmId
            //            }
            //            const response = axios.post(
            //                '/api/upload/UploadImage/', JSON.stringify(form),
            //                {
            //                    headers: { 'Content-Type': 'application/json' },
            //                    withCredentials: true
            //                }).then(res => {
            //                    if (res.data != null) {
            //                        if (res.data.status) {
            //                            const tempRows = [...recordUploadList];
            //                            tempRows[idx].recordName = res.data.content.FileName;
            //                            tempRows[idx].recordPhysicalName = res.data.content.PhysicalFilename;
            //                            tempRows[idx].recordPath = res.data.content.PhysicalFilepath;
            //                            tempRows[idx].recordRelativePath = res.data.content.RelativeFilepath;
            //                            setRecordUploadList(tempRows);
            //                        }
            //                    }
            //                });
            //        })
            //        .catch(err => {
            //            console.log(err);
            //        });
            //}
        }
        else {
            e.target.value = null;
        }
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputFile[idx].click();
        return false;
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    function getExtension (filename) {
        if (filename != undefined) {
            const extension = filename.split('.').pop().toLowerCase();
            const imagelist = ['jpg', 'png', 'jpeg'];
        
            if (imagelist.filter(r => r == extension).length > 0) {
                return "image";
            }
            else {
                return "document";
            }
        }
        else {
            return "";
        }
        
    }

    return (
        <div>
            <Card>
                <CardHeader className="card-header-click"  onClick={toggle}>
                    <Row>
                        <Col sm="11">
                            <h6>{showRecordsInfo && <> <FaInfoCircle data-tooltip-id={infoRecordsId} />&nbsp; &nbsp; &nbsp; </>}
                                Records</h6>
                            {showRecordsInfo && <>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    id={infoRecordsId}
                                    place="top" className="overrideMe"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>
                                                        {infoRecordsMessage}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            </>}
                        </Col>
                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        {refid != 0 ?
                            <div>
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                            <>
                                            <button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm btn-pluse-light">
                                                Add
                                            </button>
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="12">
                                        <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                            <thead>
                                                <tr>
                                                    <th className="text-center"> Date</th>
                                                    <th className="text-center"> Details </th>
                                                    <th className="text-center"> Attachment </th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recordUploadList.map((item, idx) => (
                                                    <tr id="records" key={idx}>
                                                        <td style={{ display: "none" }}>{recordUploadList[idx].id}</td>
                                                        <td style={{ width: "15%" }}>
                                                            <DatePicker selected={recordUploadList[idx].uploadDate != null ? getDateFromString(moment(recordUploadList[idx].uploadDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleDateChange(idx, date)} name="uploadDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                                        </td>
                                                        <td style={{ width: "45%" }}>
                                                            <input type="textarea" name="recordDetail" value={recordUploadList[idx].recordDetail} onChange={handleDetailsChange(idx)} className="form-control" />
                                                        </td>
                                                        <td style={{ width: "15%" }}>
                                                        
                                                            {recordUploadList[idx].recordRelativePath != null && recordUploadList[idx].recordRelativePath != '' ?
                                                                 <Row>
                                                                    <Col>
                                                                        {
                                                                           getExtension(recordUploadList[idx].recordName) == "image" ?
                                                                                <img src={recordUploadList[idx].recordRelativePath} className="image-popup-record-thumbnail" 
                                                                                    onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, recordUploadList[idx].recordName, recordUploadList[idx].recordRelativePath)}></img>
                                                                                :  <a href={recordUploadList[idx].recordRelativePath} target='_blank'>
                                                                                    <img src="/images/File_Icon.png" className="PDF_icon" ></img>
                                                                                    </a>                                                                              
                                                                                }
                                                                       </Col>                                                                   
                                                                    <Col>
                                                                        <Button style={{ float: `right` }} color="warning" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload/></Button>
                                                                </Col>
                                                                  </Row>
                                                                :
                                                                <Button style={{ float: `right` }} color="success" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload/></Button>
                                                            }                                                            
                                                            <input style={{ float: `right`, display: 'none' }}
                                                                ref={input => {
                                                                    inputFile[idx] = input;
                                                                }}
                                                                type="file" name="attachment" className="form-control" onChange={(e) => handleRecordChange(e, idx)} />
                                                            {formErrors.length > 0 && formErrors[idx] != undefined && formErrors[idx].fileError != undefined &&
                                                                <p className="errorMessage">{formErrors[idx].fileError}</p>
                                                            }
                                                        </td>
                                                        <td style={{ width: "5%" }}>
                                                            {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                                <>
                                                                    <button style={{ float: "right" }} onClick={handleRecordRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">
                                                                        <FaTrash />
                                                                    </button>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col> {refid != 0 ?

                                        <div>
                                            {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                <>
                                                    {saving ?
                                                        <Button style={{ float: "right" }} onClick={handleRecordRowsSubmit} color="success" size="sm" disabled>
                                                            <Spinner size="sm">
                                                                Saving...
                                                            </Spinner>
                                                            <span>
                                                                {' '}Saving
                                                            </span>
                                                        </Button>
                                                        :
                                                        <Button color="success" size="sm" style={{ float: "right" }} onClick={handleRecordRowsSubmit}>
                                                            Save
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </div> : ''
                                    }
                                    </Col>
                                </Row>
                            </div> : <span>Please save item to add records</span>}
                    </CardBody>
                </Collapse>
            </Card>


            <Modal isOpen={deleteRecordPopup.showDeteteRecordPopup}>
                <ModalHeader toggle={handleRecordPopupClose} >Delete Record</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={deleteRecord}>Yes</Button>
                    <Button color="danger" onClick={handleRecordPopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}
export default Records