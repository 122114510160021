import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Label, Input, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Collapse, FormGroup } from 'reactstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FaUpload, FaTrash, FaChevronUp, FaChevronDown, FaShareAlt,FaEdit } from 'react-icons/fa';
import uuid from 'react-uuid';
import Validate from '../Validate/Validate';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Reporting = ({ farmId, reportDocumentList, exportSummaryToPdf, reportButtonVisible, generateReport }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
    }, [])
    const [reportType, setReportType] = useState(1); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId")); const [saving, setSaving] = useState(false);
    const [autoUploadSaving, setAutoUploadSaving] = useState(false);
    const [reportPopup, setReportPopup] = useState({
        showReportPopup: false, reportTypePopup: 1, notePopup: '', showReportingUser: false, reportingUser: '', reportingUserLabel: ''
        ,certificationOutcome:'Certified',nextCertificationDue:null,auditGrade:'',nextAuditDue:null,type:'',id:0
    }); const [farmName, setFarmName] = useState(JSON.parse(localStorage.getItem("defaultFarmName"))); const [farmUniqueId, setFarmUniqueId] = useState(JSON.parse(localStorage.getItem("defaultFarmUniqueId")));
    const [autoUploadPopup, setAutoUploadPopup] = useState({ showAutoUploadPopup: false, reportTypePopup: 1, notePopup: '', showReportingUser: false, reportingUser: '', reportingUserLabel: '' });
    const [sharePopup, setSharePopup] = useState({ showSharePopup: false, reportRelativePath: '', EmailAddress: '', itemId: 0,isExpired:false,id:0 });
    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0 });
    const [sending, setSending] = useState(false);
    const [reportDate, setReportDate] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    function handleReportPopupChange(event) {
        const { name, value, checked, type } = event.target;
        if (name == 'reportTypePopup') {
            if (value == '2') {
                setReportPopup({ ...reportPopup, showReportingUser: true, reportingUserLabel: 'Certifier Name and ID', reportingUser: '', [name]: type == 'checkbox' ? checked : value,type:'Certifier' });
            }
            else if (value == '3') {
                setReportPopup({ ...reportPopup, showReportingUser: true, reportingUserLabel: 'Auditor Name and ID', reportingUser: '', [name]: type == 'checkbox' ? checked : value, type: 'Auditor' });
            }
            else {
                setReportPopup({ ...reportPopup, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else {
            setReportPopup({ ...reportPopup, [name]: type == 'checkbox' ? checked : value });
        }
    }
    function handleAutoUploadPopupChange(event) {
        const { name, value, checked, type } = event.target;
        if (name == 'reportTypePopup') {
            if (value == '2') {
                setAutoUploadPopup({ ...autoUploadPopup, showReportingUser: true, reportingUserLabel: 'Certifier', reportingUser: '', [name]: type == 'checkbox' ? checked : value });
            }
            else if (value == '3') {
                setAutoUploadPopup({ ...autoUploadPopup, showReportingUser: true, reportingUserLabel: 'Auditor', reportingUser: '', [name]: type == 'checkbox' ? checked : value });
            }
            else {
                setAutoUploadPopup({ ...autoUploadPopup, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else {
            setAutoUploadPopup({ ...autoUploadPopup, [name]: type == 'checkbox' ? checked : value });
        }
    }
    function handleSharePopupChange(event) {
        const { name, value, checked, type } = event.target;
        setSharePopup({ ...sharePopup, [name]: type == 'checkbox' ? checked : value });
    }
    function handleReportPopupClose() {
        setReportPopup({ ...reportPopup, showReportPopup: false});
    }
    function handleAutoUploadPopupClose() {
        setAutoUploadPopup({ ...autoUploadPopup, showAutoUploadPopup: false });
        window.location.reload(true);
    }
    function handleSharePopupClose() {
        setSharePopup({ ...sharePopup, showSharePopup: false, isExpired: false, id: 0 });
    }
    const handleUnshare = async() => {
        const response = await axios.get(
            '/api/Summary/UnshareReportDocument/', {
                params: { id: sharePopup.id, userId: userId }
        }
        );
        if (response.data.status) {
            setSharePopup({ ...sharePopup, showSharePopup: false, isExpired: false });
            toast.success("Document unshared!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            window.location.reload(true);
        }
        else {
            toast.error("Error while processing the request!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            window.location.reload(true);
            setSharePopup({ ...sharePopup, showSharePopup: false, isExpired: false });
        }
    }
    const handleSharePopupOpen = async (e,item) => {
        e.preventDefault();
        var relativePath = item.domain + 'api/FileViewer/Get?fileName=' + item.documentPhysicalName
        setSharePopup({ ...sharePopup, showSharePopup: true, reportRelativePath: relativePath, itemId: item.id, isExpired: item.isExpired,id:item.id });
    }
    const handleReportPopupOpen = async () => {
        setUploadDocumentName('');
        setUploadDocumentPhysicalFilename('');
        setUploadDocumentPhysicalFilepath('');
        setDocumentDownloadUrl('');
        setReportPopup({ ...reportPopup,id:0, showReportPopup: true, reportTypePopup: 1, notePopup: '', showReportingUser: false, reportingUser: '', reportingUserLabel: '' });
    }
    const handleAutoUploadPopupOpen = async () => {
        setAutoUploadPopup({ ...autoUploadPopup, showAutoUploadPopup: true, reportTypePopup: 1, notePopup: '', showReportingUser: false, reportingUser: '', reportingUserLabel: '' });
    }
    const handleReportPopupSave = async (e) => {
        e.preventDefault();
        setFormErrors({});
        const errors = {};
        if (!reportDate) {
            errors.reportDate = "Please enter report date";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            setSaving(true);
            var certificationOutcome = '';
            var nextCertificationDue = '';
            var auditGrade =  '';
            var nextAuditDue = '';
            if (reportPopup.type == 'Certifier') {
                certificationOutcome = reportPopup.certificationOutcome;
                nextCertificationDue = getParsedDate(reportPopup.nextCertificationDue);
            }
            else if (reportPopup.type == 'Auditor') {
                auditGrade = reportPopup.auditGrade;
                nextAuditDue = getParsedDate(reportPopup.nextAuditDue);
            }
            let form = {
                id: reportPopup.id,
                DocumentName: uploadDocumentName,
                DocumentDetail: '',
                DocumentPhysicalName: uploadDocumentPhysicalFilename,
                DocumentPath: uploadDocumentPhysicalFilepath,
                Type: reportPopup.reportTypePopup,
                Notes: reportPopup.notePopup,
                Username: reportPopup.reportingUser,
                FarmId: farmId,
                UserId: userId, ReportDate: getParsedDate(reportDate),
                CertificationOutcome: certificationOutcome, NextCertificationDue: nextCertificationDue, AuditGrade: auditGrade, NextAuditDue: nextAuditDue
            }
            try {
                const response = await axios.post(
                    '/api/summary/SaveReportDocument/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data != null) {
                    if (response.data.status) {
                        toast.success("Document details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                        window.location.reload(true);
                    }
                    else {
                        setSaving(false);
                        toast.error("Error while saving records!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    }

                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        else {

        }
    }
    const handleAutoUploadPopupSave = async () => {
        setAutoUploadSaving(true);
        let form = {
            Type: autoUploadPopup.reportTypePopup,
            Notes: autoUploadPopup.notePopup,
            Username: autoUploadPopup.reportingUser,
            FarmId: farmId,
            UserId: userId
        }
        try {
            const response = await axios.post(
                '/api/summary/SaveAutoUpload/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("Document details Uploaded!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setAutoUploadSaving(false);
                    handleAutoUploadPopupOpen();
                }
                else {
                    setAutoUploadSaving(false);
                    toast.error("Error while saving records!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                }

            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const [documentDownloadUrl, setDocumentDownloadUrl] = useState('');
    const [uploadDocumentName, setUploadDocumentName] = useState('');
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [uploadDocumentPhysicalFilename, setUploadDocumentPhysicalFilename] = useState('');
    const [uploadDocumentPhysicalFilepath, setUploadDocumentPhysicalFilepath] = useState('');
    let inputDocumentFile = '';
    const handleBtnDocumentUploadClick = async (e) => {
        e.preventDefault();
        inputDocumentFile.click();
        return false;
    }
    const handleDocumentChange = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if ((file.size / (1024 * 1024)) > 100) {
            errors.DocumentName = "Sorry, uploads are restricted to a maximum of 100mb per item!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {

            const formData = new FormData();
            formData.append("Type", "Report Document");
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);

            const response = await axios.post(
                '/api/uploaddocument/UploadDocument/', formData,
                {
                    headers: { "Content-type": "multipart/form-data", "FarmId": farmId, "Type": "Report Document", "userId": userId, "FileName": e.target.files[0].name },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {

                    setUploadDocumentName(response.data.content.DocumentName);
                    setUploadDocumentPhysicalFilename(response.data.content.DocumentPhysicalName);
                    setUploadDocumentPhysicalFilepath(response.data.content.DocumentPath);
                    setDocumentDownloadUrl(response.data.content.RelativeFilepath);
                }
            }
        }
        else {
            setUploadDocumentPhysicalFilename('');
            setUploadDocumentPhysicalFilepath('');
        }
    }

    const handleItemEditPopup = async (e, itemId) => {
        e.preventDefault();
        var reportDocument = reportDocumentList.filter(x => x.id == itemId);
        if (reportDocument.length > 0) {
            setReportDate(getDateFromString(moment(reportDocument[0].uploadDate).format("DD/MM/YYYY")));
            if (reportDocument[0].documentPhysicalName != '') {
                setUploadDocumentName(reportDocument[0].documentName);
                setUploadDocumentPhysicalFilename(reportDocument[0].documentPhysicalName);
                setUploadDocumentPhysicalFilepath(reportDocument[0].documentPath);
                setDocumentDownloadUrl(reportDocument[0].documentRelativePath);
            }
            else {
                setUploadDocumentName('');
                setUploadDocumentPhysicalFilename('');
                setUploadDocumentPhysicalFilepath('');
                setDocumentDownloadUrl('');
            }
            if (reportDocument[0].type == 2) {
                var nextCertificationDue = null;
                if (reportDocument[0].nextCertificationDue != null && reportDocument[0].nextCertificationDue != '') {
                    nextCertificationDue = getDateFromString(moment(reportDocument[0].nextCertificationDue).format("DD/MM/YYYY"))
                }
                setReportPopup({
                    ...reportPopup, showReportPopup: true, reportTypePopup: 2, showReportingUser: true,
                    reportingUser: reportDocument[0].username, reportingUserLabel: 'Certifier Name and ID', type: 'Certifier',
                    certificationOutcome: reportDocument[0].certificationOutcome, nextCertificationDue: nextCertificationDue,
                    notePopup: reportDocument[0].notes, id: itemId
                });
            }
            else if (reportDocument[0].type == 3) {
                var nextAuditDue = null;
                if (reportDocument[0].nextAuditDue != null && reportDocument[0].nextAuditDue != '') {
                    nextAuditDue = getDateFromString(moment(reportDocument[0].nextAuditDue).format("DD/MM/YYYY"))
                }
                setReportPopup({
                    ...reportPopup, showReportPopup: true, reportTypePopup: 3, showReportingUser: true,
                    reportingUser: reportDocument[0].username, reportingUserLabel: 'Auditor Name and ID', type: 'Auditor',
                    auditGrade: reportDocument[0].auditGrade, nextAuditDue: nextAuditDue, notePopup: reportDocument[0].notes, id: itemId
                });
            }
            else if (reportDocument[0].type == 1) {
                setReportPopup({ ...reportPopup, showReportPopup: true, id: itemId, reportTypePopup: 1, notePopup: '', showReportingUser: false, reportingUser: '', reportingUserLabel: '' });
            }
        }
    }
    function handleItemDeletePopup(e,itemId) {
        e.preventDefault();
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }
    function handleItemDeletePopupClose() {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0 });
    }
    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        try {
            const response = await axios.get(
                '/api/summary/DeleteItem/', {
                params: {
                    itemId: itemid, userId: userId
                }
            }
            );
            toast.success("Document deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            window.location.reload(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleCopyToClipboard = async (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(sharePopup.reportRelativePath);
        const response = await axios.get(
            '/api/Summary/ShareReportDocument/', {
            params: { id: sharePopup.id, userId: userId }
        });
        if (response.data.status) {
            toast.success("Shared & Copied to Clipboard!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            setTimeout(() => {
                window.location.reload(true);
            }, 2000);
        }
    }
    const handleSendEmail = async (e) => {
        e.preventDefault();
        setSending(true);
        let form = {
            Id: sharePopup.itemId,
            EmailAddress: sharePopup.EmailAddress, UserId: userId
        }
        if (!Validate(form)) {
            try {
                const response = await axios.post(
                    '/api/summary/SendEmail/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data != null) {
                    if (response.data.status) {
                        toast.success("Email Sent!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                        window.location.reload(true);
                    }
                    else {
                        setSending(false);
                        toast.error("Error while sending email!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    }

                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        else {
            setSending(false);
        }
    }
    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }
    const getDateFromString1 = (date) => {
        var dd = date.split('-')[0];
        var mm = date.split('-')[1];
        var yyyy = date.split('-')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }
    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "-" + mm + "-" + yyyy;
        return date.toString();
    }
    const exportAdminDetailPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleAdminDetailToPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }
    const handleAdminDetailToPdf = async (e) => {
        const response = await axios.get(
            '/api/summary/GenerateAdminDetailPdf/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "INFDP Admin - " + farmName.toString() + " - " + farmUniqueId.toString() +".pdf";
            document.body.appendChild(link)
            link.click();
        }

    }
    const exportActionPlanToExcel = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportActionPlanToExcel();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportActionPlanToExcel = async (e) => {
        const response = await axios.get(
            '/api/summary/GenerateReportINFDPActionPlanExcel/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })

            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "INFDP Actions - " + farmName.toString() + " - " + farmUniqueId.toString() +".xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const generateRegulatoryExport = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleRegulatoryExport();
        toast.promise(response, {
            loading: 'Exporting...',
            success: 'Export completed!',
            error: 'Error when generating report',
        });
    }

    const handleRegulatoryExport = async (e) => {
        const response = await axios.get(
            '/api/summary/GenerateRegulatoryExport/', {
            params: { farmId: farmId }
        })

        if (response.data.status) {
            let filename = "Regulatory Export - " + farmName.toString() + " - " + farmUniqueId.toString() + ".geojson";
            let contentType = "application/json;charset=utf-8;";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(response.data.content)))], { type: contentType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var a = document.createElement('a');
                a.download = filename;
                a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(response.data.content));
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    return (
        <>
            <Card className="my-4">
                <CardHeader className="item-header item_click" onClick={toggle}>
                    <Row>
                        <Col sm="11"><h5>Reporting
                        </h5></Col>
                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <Label className="form-label-default">
                                    Create a PDF report of the Fresh Water Farm Plan.
                                </Label>
                            </Col>
                            <Col xs="6">
                                <Row style={{ float: 'right' }}>
                                    <Col>
                                        {reportButtonVisible && <>
                                            {generateReport ?
                                                <Button style={{ float: `right` }} id="btnSave" onClick={exportSummaryToPdf}
                                                    color="success"
                                                    disabled
                                                >
                                                    <Row>
                                                        <Col sm="9">
                                                            <span>
                                                                {' '}Generating
                                                            </span>
                                                        </Col>
                                                        <Col sm="3">
                                                            <Spinner size="sm" style={{ marginRight: "5px" }}>
                                                                Generating...
                                                            </Spinner>
                                                        </Col>
                                                    </Row>
                                                </Button>
                                                :
                                                <Button style={{ float: `right` }} id="btnSave" onClick={exportSummaryToPdf} color="success">
                                                    Generate Report
                                                </Button>
                                            }
                                        </>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        {/*<Row>*/}
                        {/*    <Col xs="6">*/}
                        {/*        <Button style={{ float: 'right' }} color="success" onClick={exportSummaryToPdf} size="sm">Generate Report</Button>{' '}*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<br />*/}
                        <Row style={{display:"none"}}>
                            <Col xs="8">
                                <Label className="form-label-default">
                                    Upload a report to History automatically. The system will generate and save a new copy overnight into the History table.
                                </Label>
                            </Col>
                            <Col xs="4">
                                {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                    <>
                                        {autoUploadSaving ?
                                            <Button style={{ float: `right` }} id="btnSave" onClick={handleAutoUploadPopupSave}
                                                color="primary" size="sm"
                                                disabled
                                            >
                                                <Row>
                                                    <Col sm="9">
                                                        <span>
                                                            Uploading
                                                        </span>
                                                    </Col>
                                                    <Col sm="3">
                                                        <Spinner size="sm">
                                                            Uploading..
                                                        </Spinner>
                                                    </Col>
                                                </Row>
                                            </Button>
                                            :
                                            <Button style={{ float: `right` }} id="btnSave" onClick={handleAutoUploadPopupSave} color="primary" size="sm">
                                                Auto Upload
                                            </Button>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="7">
                                <Label className="form-label-history">
                                    Report History
                                </Label>
                            </Col>
                            <Col xs="5">
                                {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                    <>
                                        <Button style={{ float: 'right' }} color="success" onClick={handleReportPopupOpen} size="sm">Upload Document</Button>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row style={{ height: '250px', overflowY: 'auto' }}>
                            <Col>
                                <Label className="form-label-history-text">
                                    {/*No reports yet submited*/}
                                    <Table className='table'>
                                        <thead className="actionListTable_header">
                                            <tr>
                                                <th> Report Date </th>
                                                <th> Report Detail  </th>
                                                <th> View </th>
                                                <th> Share </th>
                                                <th> Edit </th>
                                                <th> Remove </th>
                                            </tr>
                                        </thead>
                                        <tbody className="actionListTable_body">
                                            {reportDocumentList != undefined && reportDocumentList != null && reportDocumentList.map(item => (
                                                <tr key={uuid()}>
                                                    <td>{item.reportDate}</td>
                                                    <td>
                                                        <div>
                                                            <Row>
                                                                <Col><b>{item.typeText}</b>{item.details != null && item.details != "" && <>{' / '}<b>{item.details}</b></>} </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>{item.notes}</Col>
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {!item.isExpired &&
                                                            <a style={{ background: "none", border: "none" }} value="Export" placeholder="" href={item.documentRelativePath} target='_blank'>
                                                                <img src="/images/PDF_icon.png" className="card_icon" ></img>
                                                            </a>
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        {item.isExpired &&  
                                                            <Button style={{ background: "none", border: "none" }} value="Export" placeholder="" onClick={(e) => handleSharePopupOpen(e, item)}>
                                                                <FaShareAlt size="25" style={{ color: "red" }} />
                                                            </Button>    
                                                        }
                                                        {!item.isExpired &&
                                                            <Button style={{ background: "none", border: "none" }} value="Export" placeholder="" onClick={(e) => handleSharePopupOpen(e, item)}>
                                                                <FaShareAlt size="25" style={{ color: "green" }} />
                                                            </Button>
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        <button type="button" onClick={(e) => handleItemEditPopup(e, item.id)}
                                                            id="btnUpdate"
                                                            className="btn btn-sm btn-success">
                                                            <FaEdit />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button type="button" onClick={(e) => handleItemDeletePopup(e, item.id)}
                                                            id="btnUpdate"
                                                            className="btn btn-sm btn-danger">
                                                            <FaTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Label>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs="12">
                                <Label className="form-label-history">
                                    INFDP Reports
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">
                                <Button style={{ float: 'left' }} color="success" onClick={(e) => exportAdminDetailPdf(e)} size="sm">Admin Detail</Button>
                            </Col>
                            <Col sm="3">
                                <Button style={{ float: 'left' }} color="success" onClick={(e) => exportActionPlanToExcel(e)} size="sm">Action Plan (xlsx)</Button>
                            </Col>
                            <Col sm="6">
                                <Button style={{ float: 'left' }} color="success" onClick={(e) => generateRegulatoryExport(e)} size="sm">Export to GeoJSON</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
            <Modal isOpen={reportPopup.showReportPopup}>
                <ModalHeader toggle={handleReportPopupClose} >Upload Document</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="4">
                            <Label className="form-label-default">
                                Report Date
                            </Label>
                        </Col>
                        <Col xs="8">
                            <FormGroup>
                                <DatePicker style={{ width: `100%` }} selected={reportDate} onChange={(date) => setReportDate(date)}
                                    name="reportDate" dateFormat="dd/MM/yyyy" className="form-control" />
                            </FormGroup>
                            {formErrors.reportDate != undefined &&
                                <p className="errorMessage">{formErrors.reportDate}</p>
                            }
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="4">
                            <Label className="form-label-default">
                                Choose Type
                            </Label>
                        </Col>
                        <Col xs="8">
                            <Input type="select" className="float-end" onChange={handleReportPopupChange} name="reportTypePopup" id="reportTypePopup" value={reportPopup.reportTypePopup}>
                                <option key={1} value={1} >Complete FWFP Report</option>
                                <option key={2} value={2} >Certification</option>
                                <option key={3} value={3} >Audit</option>
                            </Input>
                        </Col>
                    </Row>
                    <br />
                    {reportPopup.showReportingUser && <div>
                        <Row>
                            <Col xs="4">
                                <Label className="form-label-default">
                                    {reportPopup.reportingUserLabel}
                                </Label>
                            </Col>
                            <Col xs="8">
                                <Input type="text" placeholder="Enter..." name="reportingUser" id="reportingUser" value={reportPopup.reportingUser} onChange={handleReportPopupChange} />
                            </Col>
                        </Row>
                        {reportPopup.type == 'Certifier' &&
                            <>
                            <Row>
                                <Col xs="4">
                                    <Label className="form-label-default">
                                        Certification Outcome
                                    </Label>
                                </Col>
                                <Col xs="8">
                                    <Input type="select" className="float-end" onChange={handleReportPopupChange} name="certificationOutcome" id="certificationOutcome" value={reportPopup.certificationOutcome}>
                                        <option key={1} value="Certified">Certified</option>
                                        <option key={2} value="Not Certified" >Not Certified</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="4">
                                    <Label className="form-label-default">
                                        Next Certification Due
                                    </Label>
                                </Col>
                                <Col xs="8">
                                    <DatePicker style={{ width: `100%` }} selected={reportPopup.nextCertificationDue} onChange={(date) => setReportPopup({ ...reportPopup, nextCertificationDue: date })}
                                        name="nextCertificationDue" dateFormat="dd/MM/yyyy" className="form-control" />
                                </Col>
                            </Row>
                        </>

                        }
                        {reportPopup.type == 'Auditor' &&
                            <>
                                <Row>
                                    <Col xs="4">
                                        <Label className="form-label-default">
                                        Audit Grade
                                        </Label>
                                    </Col>
                                    <Col xs="8">
                                        <Input type="text" placeholder="Enter..." name="auditGrade" id="auditGrade" value={reportPopup.auditGrade} onChange={handleReportPopupChange} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs="4">
                                        <Label className="form-label-default">
                                            Next Audit Due
                                        </Label>
                                    </Col>
                                <Col xs="8">
                                    <DatePicker style={{ width: `100%` }} selected={reportPopup.nextAuditDue} onChange={(date) => setReportPopup({ ...reportPopup, nextAuditDue: date })}
                                        name="nextAuditDue" dateFormat="dd/MM/yyyy" className="form-control" />
                                    </Col>
                                </Row>
                            </>

                        }
                        <br />
                    </div>}
                    <Row>
                        <Col>
                            <Input type="textarea" placeholder="Type note..." name="notePopup" id="notePopup" value={reportPopup.notePopup} onChange={handleReportPopupChange} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {documentDownloadUrl && <a href={documentDownloadUrl} target='_blank'>
                                <img src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img> <span >{uploadDocumentName}</span>
                                <br />

                            </a>}
                            <br />
                            {documentDownloadUrl ?
                                <Button color="warning" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Modifiy Document</Button> :
                                <Button color="success" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Upload Document</Button>}
                            <input style={{ display: 'none' }}
                                ref={input => {
                                    inputDocumentFile = input;
                                }}
                                className="fileInput" id="fileUpload" type="file" onChange={handleDocumentChange} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {saving ?
                        <Button style={{ float: `right`, marginLeft: '10px' }} id="btnSave" onClick={handleReportPopupSave}
                            color="success"
                            disabled
                        >
                            <Spinner size="sm">
                                Saving...
                            </Spinner>
                            <span>
                                {' '}Saving
                            </span>
                        </Button>
                        :
                        <Button style={{ float: `right`, marginLeft: '10px' }} id="btnSave" onClick={handleReportPopupSave} color="success">
                            Save
                        </Button>
                    }
                    <Button color="danger" style={{ float: 'right' }} onClick={handleReportPopupClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={sharePopup.showSharePopup} size="lg" style={{ maxWidth: '700px', width: '100%' }}>
                <ModalHeader toggle={handleSharePopupClose}>Share Document</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            Copy hyperlink below to allow access to this report file
                            Or type EMAIL to send link to
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs="10">
                            <Input type="text" className="validate" placeholder="Type email address..." name="EmailAddress" id="EmailAddress" value={sharePopup.EmailAddress} onChange={handleSharePopupChange} />
                            <b>Note</b> : Separate multiple email ids with semicolon(;)
                        </Col>
                        <Col xs="2">
                            {sending ?
                                <Button style={{ float: `right`, marginLeft: '10px' }} id="btnSend" onClick={handleSendEmail}
                                    color="success"
                                    disabled
                                >
                                    <Spinner size="sm">
                                        Sending...
                                    </Spinner>
                                    <span>
                                        {' '}Sending
                                    </span>
                                </Button>
                                :
                                <Button style={{ float: `right`, marginLeft: '10px' }} id="btnSend" onClick={handleSendEmail} color="success">
                                    Share & Send
                                </Button>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" style={{ float: 'right' }} onClick={handleCopyToClipboard}>Share & Copy to Clipboard</Button>
                    <Button color="warning" style={{ float: 'right' }} onClick={handleUnshare}>Unshare</Button>
                    <Button color="danger" style={{ float: 'right' }} onClick={handleSharePopupClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={deleteItem}>Yes</Button>
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={autoUploadPopup.showAutoUploadPopup}>
                <ModalHeader toggle={handleAutoUploadPopupClose} >Auto Upload</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label className="form-label-default">Thank you. A copy of the Freshwater Farm Plan report will be uploaded overnight. Please check back tomorrow.</Label>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ float: `right`, marginLeft: '10px' }} id="btnSave" onClick={handleAutoUploadPopupClose} color="success">
                        Ok
                    </Button>
                    <Button color="danger" style={{ float: 'right' }} onClick={handleAutoUploadPopupClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
        
    )
}
export default Reporting