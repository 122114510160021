import { useState, useEffect } from 'react';
import { Row, Col,Button} from 'reactstrap';
import About from '../MyInfo/About';
import News from '../MyInfo/News';
import Members from '../MyInfo/Members';
import Farms from '../MyInfo/Farms';
import Contaminants from '../MyInfo/Contaminants';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import { FaChevronLeft } from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

const MyInfo = () => {
    const [loading, setLoading] = useState(true); const [myInfo, setMyInfo] = useState({ farmsInfo: [], aboutInfo: {}, filesInfo: [], newsInfo: [], membersInfo: [] });
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [type, setType] = useState('');
    const [catchmentId, setCatchmentId] = useState(-1);
    const [supplyCompanyId, setSupplyCompanyId] = useState(-1);
    const [ruralProfessionalOrganisationId, setRuralProfessionalOrganisationId] = useState(-1);
    const [roleName, setRoleName] = useState(localStorage.getItem("roleName"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const navigate = useNavigate();

    const OnPageload = async (e) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        setType(params.get('Type'));
        try {
            const response = await axios.get(
                '/api/settings/GetMyInfo/', {
                    params: { Id: farmId, Type: params.get('Type'), userId: userId }
            }
            );
            if (response.data.status) {
                setCatchmentId(response.data.content.catchmentId);
                setSupplyCompanyId(response.data.content.supplyCompanyId);
                setRuralProfessionalOrganisationId(response.data.content.ruralProfessionalOrganisationId);
                setMyInfo({ ...myInfo, farmsInfo: response.data.content.farmsInfo, aboutInfo: response.data.content.aboutInfo, filesInfo: response.data.content.filesInfo, newsInfo: response.data.content.newsInfo, membersInfo: response.data.content.membersInfo });
            }

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    return (
        <div>
            {
                loading ? <SpinnerContainer /> :
                    <div>
                        <Row>
                            <Col>
                                <Button onClick={() => navigate(-1)}  color="warning"><FaChevronLeft /> Return to Farm Plan</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <About aboutInfo={myInfo.aboutInfo} type={type}></About>
                                <Farms farmsInfo={myInfo.farmsInfo}></Farms>
                                {/*<Contaminants></Contaminants>*/}
                            </Col>
                            <Col sm="6">
                                {
                                    type == 'Catchment' && catchmentId != -1 && <News type={type} roleName={roleName} catchmentId={catchmentId} newsInfo={myInfo.newsInfo}></News>
                                }
                                {
                                    type == 'Supply Company' && supplyCompanyId != -1 && <News type={type} roleName={roleName} supplyCompanyId={supplyCompanyId} newsInfo={myInfo.newsInfo}></News>
                                }
                                {
                                    type == 'Rural Professional Organisation' && ruralProfessionalOrganisationId != -1 && <News type={type} roleName={roleName} ruralProfessionalOrganisationId={ruralProfessionalOrganisationId} newsInfo={myInfo.newsInfo}></News>
                                }
                                <Members membersInfo={myInfo.membersInfo}></Members>
                            </Col>
                        </Row>
                    </div>
            }
        </div>
        );
}
export default MyInfo;