import React, { useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { Container } from 'reactstrap';
import Header from './Layout/Header';
import Menu  from './Layout/Menu';
import axios from 'axios';
import useAuth from "../components/Utilities/Hooks/useAuth";

const NonWizardLayout = () => {
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        validateToken();
    }, [])
    const validateToken = async () => {
        if (auth != null) {
            let form = {
                Token: auth.token
            }
            const response = await axios.post(
                '/api/Token/Validate/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (!response.data) {
                window.open("/login", "_self");
            }
        }
        else {
            window.open("/login", "_self");
        }
    }
    return (
        <>
            <Header />
            <Menu />
            <Container className="layout-body">
                <Outlet />
            </Container>
        </>
    );
}

export default NonWizardLayout
