import React, {} from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom"

const Unauthorized = () => {

    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div>
            <h2>Unauthorized</h2>
            <Row xs="12">
                <Col>
                    <Card>
                        <CardBody>
                            <p>You don't have permission to access this page.</p>
                            <Button size="sm" color="warning" onClick={goBack}>Go back to login</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Unauthorized