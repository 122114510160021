import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Table, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import uuid from 'react-uuid';
import { FaPlus, FaEdit, FaTrash, FaFileExcel } from 'react-icons/fa';
import "../ExternalOrganisation/ExternalOrganisation.css";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useNavigate, useSearchParams } from "react-router-dom";

const Farm = () => {
    const [farmList, setFarmList] = useState([]);const [loading, setLoading] = useState(true);const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);const [deleteItemId, setDeleteItemId] = useState(0);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId")); const navigate = useNavigate();
    const [name, setName] = useState(''); const [showArchived, setShowArchived] = useState(false);
    const [farmItems, setFarmItems] = useState([]);
    const [farmTempItems, setFarmTempItems] = useState([]);
    const tableRef = useRef(null);
    const [regionalCouncil, setRegionalCouncil] = useState(0); const [regionalCouncilText, setRegionalCouncilText] = useState('All');
    const [catchment, setCatchment] = useState(0); const [catchmentText, setCatchmentText] = useState('All');
    const [subscription, setSubscription] = useState(0); const [subscriptionText, setSubscriptionText] = useState('All');
    const [sorting, setSorting] = useState({ key: "firstName", ascending: true }); const [searchParams, setSearchParams] = useSearchParams();
    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let catchmentValue = params.get('catchment') == null ? 0 : params.get('catchment');
            let regionalCouncilValue = params.get('regionalCouncil') == null ? 0 : params.get('regionalCouncil');
            let nameValue = params.get('name') == null ? '' : params.get('name');
            let showArchivedValue = params.get('showArchived') == null ? false : (params.get('showArchived') === "true");
            setRegionalCouncil(params.get('regionalCouncil') == null ? 0 : params.get('regionalCouncil'));
            setCatchment(params.get('catchment') == null ? 0 : params.get('catchment'));
            setName(params.get('name') == null ? '' : params.get('name'));
            setShowArchived(params.get('showArchived') == null ? false : (params.get('showArchived') === "true"));
            if (searchParams.has('regionalCouncil')) {
                searchParams.delete('regionalCouncil');
                setSearchParams(searchParams);
            }
            if (searchParams.has('catchment')) {
                searchParams.delete('catchment');
                setSearchParams(searchParams);
            }
            if (searchParams.has('name')) {
                searchParams.delete('name');
                setSearchParams(searchParams);
            }
            if (searchParams.has('showArchived')) {
                searchParams.delete('showArchived');
                setSearchParams(searchParams);
            }
            const response = await axios.get(
                '/api/settings/getfarms/', {
                params: {}
            }
            );
            setFarmList(response.data.content);
            setFarmTempItems(response.data.content.farms);
            //setFarmItems(response.data.content.farms.filter(x => x.isActive == !showArchived));
            var catchmentObj = response.data.content.catchmentList.filter(x => x.id == parseInt(catchmentValue));
            var regionalCouncilObj = response.data.content.regionalCouncilList.filter(x => x.id == parseInt(regionalCouncilValue));
            var list = [];
            list = response.data.content.farms;
            if (catchmentObj.length > 0 && catchmentObj[0].id != 0) {
                setCatchmentText(catchmentObj[0].name);
                list = list.filter(x => x.catchmentText == catchmentObj[0].name);
                if (nameValue != '') {
                    list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(nameValue.toLowerCase()) || x.name.toLowerCase().includes(nameValue.toLowerCase())));
                }
                if (regionalCouncilObj[0].name != 'All') {
                    list = await regionalCouncilFilterWithText(list, regionalCouncilObj[0].name);
                }
            }
            else {
                setCatchmentText('All');
                if (nameValue != '') {
                    list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(nameValue.toLowerCase()) || x.name.toLowerCase().includes(nameValue.toLowerCase())));
                }
                if (regionalCouncilObj[0].name != 'All') {
                    list = await regionalCouncilFilterWithText(list, regionalCouncilObj[0].name);
                }
            }
            setFarmItems(list);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    function applySorting(key, ascending) {
        setSorting({ key: key, ascending: ascending });
        const currentFarmsCopy = [...farmItems];

        const sortedCurrentFarms = currentFarmsCopy.sort((a, b) => {
            return (a[key] == null ? '' : a[key]).localeCompare((b[key] == null ? '' : b[key]));
        });
        setFarmItems(
            ascending ? sortedCurrentFarms : sortedCurrentFarms.reverse()
        );
    }

    const confirmDeleteItemPopup = async () => {
        const response = await axios.get(
            '/api/settings/DeleteFarm/', {
            params: { Id: deleteItemId, userId: userId }
        }
        );
        setShowDeleteItemPopup(false);
        populateItemData();
    }

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const handleChangeName = async (event) => {
        setName(event.target.value);
        var list = [];
        list = farmTempItems;
        if (event.target.value == '') {
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
            setFarmItems(list)
        }
        else {
            list = farmTempItems.filter(x => (x.uniqueIdentifier.toLowerCase().includes(event.target.value.toLowerCase()) || x.name.toLowerCase().includes(event.target.value.toLowerCase())));
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
            setFarmItems(list);
        }
    }

    const onRegionalCouncilSelectionChanged = async (e) => {
        var list = [];
        list = farmTempItems;
        setRegionalCouncil(e.target.value);
        setRegionalCouncilText(e.target.selectedOptions[0].text);
        if (e.target.selectedOptions[0].text == 'All') {
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
        }
        else {
            list = list.filter(x => x.regionalCouncilText == e.target.selectedOptions[0].text);
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
        }
        setFarmItems(list);
    }

    const onCatchmentSelectionChanged = async (e) => {
        var list = [];
        list = farmTempItems;
        setCatchment(e.target.value);
        setCatchmentText(e.target.selectedOptions[0].text);
        if (e.target.selectedOptions[0].text == 'All') {
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
        }
        else {
            list = list.filter(x => x.catchmentText == e.target.selectedOptions[0].text);
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
        }
        setFarmItems(list);
    }

    const onSubscriptionSelectionChanged = async (e) => {
        var list = [];
        list = farmTempItems;
        setSubscription(e.target.value);
        setSubscriptionText(e.target.selectedOptions[0].text);
        if (e.target.selectedOptions[0].text == 'All') {
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
        }
        else {
            list = list.filter(x => x.subscriptionText == e.target.selectedOptions[0].text);
            if (name != '') {
                list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
            }
            if (regionalCouncilText != 'All') {
                list = await regionalCouncilFilter(list);
            }
            if (catchmentText != 'All') {
                list = await catchmentFilter(list);
            }
        }
        setFarmItems(list);
    }

    const regionalCouncilFilter = async (list) => {
        return list.filter(x => x.regionalCouncilText == regionalCouncilText);
    }
    const regionalCouncilFilterWithText = async (list,text) => {
        return list.filter(x => x.regionalCouncilText == text);
    }
    const catchmentFilter = async (list) => {
        return list.filter(x => x.catchmentText == catchmentText);
    }
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Orchards',
        sheet: 'Orchards'
    })

    const open = (id, routePath) => {
        window.location = '/' + routePath + '?id=' + id + '&regionalCouncil=' + regionalCouncil + '&name=' + name + '&showArchived=' + showArchived + '&catchment=' + catchment;
    }
    
    const changeShowArchived = async (e) => {
        setShowArchived(e.target.checked);
        var list = [];
        list = farmTempItems;
        list = list.filter(x => x.isActive == !e.target.checked);
        if (name != '') {
            list = list.filter(x => (x.uniqueIdentifier.toLowerCase().includes(name.toLowerCase()) || x.name.toLowerCase().includes(name.toLowerCase())));
        }
        if (regionalCouncil != 'All') {
            regionalCouncilFilter(list);
        }
        if (catchment != 'All') {
            catchmentFilter(list);
        }
        setFarmItems(list);
    }
    return (
        <div>
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Orchards
                    <button type="button" onClick={(id, routePath) => open(0, 'createorchard')} id="btnUpdate" className="btn btn-sm plusIcon-small">
                       Add
                    </button>
                </h2>
                <CardBody>
                    <Row>
                        <Col md="1" className="d-flex justify-content-between align-items-center">
                            <Input type="checkbox" checked={showArchived} onChange={(e) => changeShowArchived(e)} name="showArchived" id="showArchived" />&nbsp;&nbsp;
                            <Label for="name">Show Archived</Label>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Regional Council</Label>
                                {farmList.regionalCouncilList == undefined && <p><em>Loading...</em></p>}
                                {farmList.regionalCouncilList != undefined && farmList.regionalCouncilList != null &&
                                    <Input className="validate" type="select" value={regionalCouncil} onChange={e => {
                                        onRegionalCouncilSelectionChanged(e);
                                    }} name="regionalCouncil" id="regionalCouncil">
                                        {farmList.regionalCouncilList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Catchment</Label>
                                {farmList.catchmentList == undefined && <p><em>Loading...</em></p>}
                                {farmList.catchmentList != undefined && farmList.catchmentList != null &&
                                    <Input className="validate" type="select" value={catchment} onChange={e => {
                                        onCatchmentSelectionChanged(e);
                                    }} name="catchment" id="catchment">
                                        {farmList.catchmentList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Search</Label>
                                <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col md="1">
                            <br />
                            <button onClick={onDownload} style={{ marginLeft: "15px", marginTop: "10px" }} type="button" id="btnUpdate" className="btn btn-sm btn-success">
                                <FaFileExcel /> Export
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th onClick={() => applySorting("uniqueIdentifier", !sorting.ascending)}>Orchard Identifier</th>
                                        <th onClick={() => applySorting("name", !sorting.ascending)}>Name</th>
                                        <th onClick={() => applySorting("regionalCouncilText", !sorting.ascending)}>Regional Council</th>
                                        <th onClick={() => applySorting("catchmentText", !sorting.ascending)}>Catchment</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {farmItems == undefined &&
                                        <tr><td colSpan="8" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {farmItems != undefined && farmItems != null && farmItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.uniqueIdentifier}</td>
                                            <td>{item.name} </td>
                                            <td>{item.regionalCouncilText} </td>
                                            <td>{item.catchmentText} </td>
                                            <td>
                                                <button type="button" onClick={(id, routePath) => open(item.id, 'editorchard')}
                                                    id="btnUpdate"
                                                    color="warning"
                                                    className="btn btn-sm btn-edit button-normal">
                                                    <FaEdit />
                                                </button>
                                            </td>
                                            <td>
                                                <button type="button"
                                                    id="btnUpdate"
                                                    className="btn btn-sm btn-danger button-delete"
                                                    onClick={() => openDeleteItemPopup(item)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <table style={{ display: "none" }} ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Orchard Identifier</th>
                                        <th>Name</th>
                                        <th>Regional Council</th>
                                        <th>Catchment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {farmItems == undefined &&
                                        <tr><td colSpan="8" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {farmItems != undefined && farmItems != null && farmItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.uniqueIdentifier}</td>
                                            <td>{item.name} </td>
                                            <td>{item.regionalCouncilText} </td>
                                            <td>{item.catchmentText} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Farm;
