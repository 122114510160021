import { useState, useEffect } from 'react';
import {
    Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Collapse
} from 'reactstrap';
import uuid from 'react-uuid';
import { FaLink, FaTrash, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';
import FarmAPTThanksTo from '../FarmApt/FarmAPTThanksTo';
import './FarmAPT.css';

const ActionsToConsider = ({sectionId}) => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [actions, setActions] = useState([]); const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false); const toggle = () => { setIsOpen(!isOpen); setIsIconUp(!isIconUp) };
    const [moreInfoPopup, setMoreInfoPopup] = useState({
        showMoreInfoPopup: false, description: '', url: '', cobenefits: '', limitingUptake: '', measurements: '', refs: '', effectiveness_s_value: 0, effectiveness_s_text: ''
        , effectiveness_n_value: 0, effectiveness_n_text: '', effectiveness_b_value: 0, effectiveness_b_text: '', effectiveness_p_value: 0, effectiveness_p_text: ''
        , costeffectiveness_n_text: '', costeffectiveness_n_value: 0, costeffectiveness_b_text: '', costeffectiveness_b_value: 0
        , costeffectiveness_p_text: '', costeffectiveness_p_value: 0, costeffectiveness_s_text: '', costeffectiveness_s_value: 0, capital_cost: '', established_or_developing:''
    });
    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/farmapt/GetActions/', {
                    params: { FarmId: farmId, SectionId: sectionId }
            }
            );
            if (response.data.status) {
                setActions(response.data.content);
            }

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        OnPageload();
    }, [])

    function handleMoreInfoPopupClose() {
        setMoreInfoPopup({ ...moreInfoPopup, showMoreInfoPopup: false });
    }

    const handleShowMoreInfoPopup = (item) => () => {
        var url = item.url;
        var meta = item.meta;
        var desc = meta.desc;
        var cobenefits = meta.cobenefits.map((cobenefits) => {
            return <li key={uuid()}>{cobenefits}</li>;
        });
        var limitingUptake = meta.limitingUptake.map((limitingUptake) => {
            return <li key={uuid()}>{limitingUptake}</li>;
        });
        var measurements = meta.measurements.map((measurements) => {
            return <li key={uuid()}>{measurements}</li>;
        });
        var refs = meta.refs.map((refs) => {
            return <li key={uuid()}>{refs}</li>;
        });
        var name = meta.name;
        var effectiveness_n_value = 0;
        var effectiveness_n_text = '';
        var effectiveness_b_value = 0;
        var effectiveness_b_text = '';
        var effectiveness_p_value = 0;
        var effectiveness_p_text = '';
        var effectiveness_s_value = 0;
        var effectiveness_s_text = '';
        var costeffectiveness_n_value = 0;
        var costeffectiveness_n_text = '';
        var costeffectiveness_b_value = 0;
        var costeffectiveness_b_text = '';
        var costeffectiveness_p_value = 0;
        var costeffectiveness_p_text = '';
        var costeffectiveness_s_value = 0;
        var costeffectiveness_s_text = '';
        if (item.effectiveness != null) {
            if (item.effectiveness.n != null) {
                effectiveness_n_value = Math.round(33 * item.effectiveness.n.score);
                effectiveness_n_text = effectiveness_n_value + '%';
            }
            if (item.effectiveness.b != null) {
                effectiveness_b_value = Math.round(33 * item.effectiveness.b.score);
                effectiveness_b_text = effectiveness_b_value + '%';
            }
            if (item.effectiveness.p != null) {
                effectiveness_p_value = Math.round(33 * item.effectiveness.p.score);
                effectiveness_p_text = effectiveness_p_value + '%';
            }
            if (item.effectiveness.s != null) {
                effectiveness_s_value = Math.round(33 * item.effectiveness.s.score);
                effectiveness_s_text = effectiveness_s_value + '%';
            }
        }
        if (item.cost_effectiveness != null) {
            if (item.cost_effectiveness.n != null) {
                costeffectiveness_n_value = Math.round(33 * item.cost_effectiveness.n.score);
                costeffectiveness_n_text = costeffectiveness_n_value + '%';
            }
            if (item.cost_effectiveness.b != null) {
                costeffectiveness_b_value = Math.round(33 * item.cost_effectiveness.b.score);
                costeffectiveness_b_text = costeffectiveness_b_value + '%';
            }
            if (item.cost_effectiveness.p != null) {
                costeffectiveness_p_value = Math.round(33 * item.cost_effectiveness.p.score);
                costeffectiveness_p_text = costeffectiveness_p_value + '%';
            }
            if (item.cost_effectiveness.s != null) {
                costeffectiveness_s_value = Math.round(33 * item.cost_effectiveness.s.score);
                costeffectiveness_s_text = costeffectiveness_s_value + '%';
            }
        }
        var capital_cost = '';
        if (item.capital_cost != null) {
            capital_cost = item.capital_cost.value;
        }
        setMoreInfoPopup({
            ...moreInfoPopup, showMoreInfoPopup: true, description: desc, url: url, cobenefits: cobenefits, limitingUptake: limitingUptake, measurements: measurements, refs: refs
            , effectiveness_n_text: effectiveness_n_text,effectiveness_n_value: effectiveness_n_value, effectiveness_b_text: effectiveness_b_text, effectiveness_b_value: effectiveness_b_value
            , effectiveness_p_text: effectiveness_p_text, effectiveness_p_value: effectiveness_p_value, effectiveness_s_text: effectiveness_s_text, effectiveness_s_value: effectiveness_s_value
            , costeffectiveness_n_text: costeffectiveness_n_text, costeffectiveness_n_value: costeffectiveness_n_value, costeffectiveness_b_text: costeffectiveness_b_text, costeffectiveness_b_value: costeffectiveness_b_value
            , costeffectiveness_p_text: costeffectiveness_p_text, costeffectiveness_p_value: costeffectiveness_p_value, costeffectiveness_s_text: costeffectiveness_s_text, costeffectiveness_s_value: costeffectiveness_s_value,
            capital_cost: capital_cost, established_or_developing: item.established_or_developing
        });
    }

    return (
        <div style={{ marginTop: "15px" }}>
            <Card className="card-header-outline">
                <CardHeader className="card-header-click" onClick={toggle}>
                    <Row>
                        <Col sm="11">
                            <h5>Actions you may wish to consider</h5>
                        </Col>
                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <FarmAPTThanksTo></FarmAPTThanksTo>
                        <br/>
                        <Row>
                            <Col sm="12">
                                <table className="table table-bordered table-hover" id="tab_logic">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ width: "5%" }}> #</th>
                                            <th className="text-center" style={{ width: "90%" }}> Recommended Action</th>
                                            <th className="text-center" style={{ width: "5%" }}> More Info </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            actions != undefined && actions != null &&
                                            actions.map((item, idx) => (
                                                <tr id="records" key={uuid()}>
                                                    <td style={{ width: "5%" }}>{item.id}</td>
                                                    <td style={{ width: "90%" }}>{item.name}</td>
                                                    <td style={{ width: "5%" }}>
                                                        <button style={{ float: "right" }} onClick={handleShowMoreInfoPopup(item)} type="button" id="btnUpdate" className="btn btn-sm btn-edit">
                                                            <FaLink />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>

            <Modal size="lg" isOpen={moreInfoPopup.showMoreInfoPopup} backdrop={false} className="moreInfoPopup">
                <ModalHeader toggle={handleMoreInfoPopupClose} >More info</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-2">Description :</div>
                        <div className="col-md-10">
                            <p>{moreInfoPopup.description}</p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-2">URL :</div>
                        <div className="col-md-10">
                            <a href={moreInfoPopup.url} target='_blank'>{moreInfoPopup.url}</a>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-2">Co Benefits :</div>
                        <div className="col-md-10">
                            <ul>
                                {moreInfoPopup.cobenefits}
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-2">Limiting Uptake :</div>
                        <div className="col-md-10">
                            <ul>
                                {moreInfoPopup.limitingUptake}
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-2">Measurements :</div>
                        <div className="col-md-10">
                            <ul>
                                {moreInfoPopup.measurements}
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-2">Refs :</div>
                        <div className="col-md-10">
                            <ul>
                                {moreInfoPopup.refs}
                            </ul>
                        </div>
                    </div>
                    <br />
                    <Row>
                        <Col sm="3">
                            <Row>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.effectiveness_n_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.effectiveness_n_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">N</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.effectiveness_b_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.effectiveness_b_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">B</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.effectiveness_p_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.effectiveness_p_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">P</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.effectiveness_s_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.effectiveness_s_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">S</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <label style={{ textAlign: `center`, fontSize: `large` }}>Effectiveness</label>
                            </Row>
                        </Col>
                        <Col sm="3" style={{ marginLeft: "60px", marginRight: "-20px" }}>
                            <Row>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="12">
                                            <span className="badge rounded-pill bg-primary text-dark" style={{ width: "50%", fontSize: 16 }} >{moreInfoPopup.capital_cost}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            Capital Cost
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="12">
                                            <span className="badge rounded-pill bg-primary text-dark" style={{ width: "50%", fontSize: 16 }} >{moreInfoPopup.established_or_developing}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            Established
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="3">
                            <Row>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.costeffectiveness_n_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.costeffectiveness_n_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">N</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.costeffectiveness_b_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.costeffectiveness_b_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">B</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.costeffectiveness_p_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.costeffectiveness_p_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">P</Col>
                                    </Row>
                                </Col>
                                <Col sm="3">
                                    <Row>
                                        <Col sm="12">
                                            <div className="progress progress-bar-vertical">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={moreInfoPopup.costeffectiveness_s_value} aria-valuemin={0} aria-valuemax={100} style={{ height: moreInfoPopup.costeffectiveness_s_text }}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">S</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <label style={{ textAlign: `center`, fontSize: `large` }}>Cost-Effectiveness</label>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleMoreInfoPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ActionsToConsider