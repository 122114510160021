import React, { Component } from 'react';
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from './styles.js';
import uuid from 'react-uuid';

const titleCase = str =>
    str.split(/\s+/).map(w => w[0].toUpperCase() + w.slice(1)).join(' ');

const ClickableLabel = ({ title, onChange, id }) =>
    <SwitchLabel key={uuid()} onClick={() => onChange(title)} className={id}>
        {titleCase(title)}
    </SwitchLabel>;

const ConcealedRadio = ({ value, selected }) =>
    <SwitchRadio key={uuid()} type="radio" name="switch" defaultChecked={selected === value} />;

class ToggleSwitch extends Component {
    state = { selected: this.props.selected, id: this.props.id, changed: this.props.onChange };

    handleChange = val => {
        this.setState({ selected: val });
        if (val == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('googleMap').style.height = '80vh';
            document.getElementById('googleMap').style.width = '80vw';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (val == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    };

    selectionStyle = () => {
        return {
            left: `${this.props.values.indexOf(this.props.selected) / 3 * 100}%`,
        };
    };

    render() {
        const { selected, id, onChange } = this.props;
        return (
            <Switch id={id}>
                {this.props.values.map(val => {
                    return (
                        <span key={uuid()}>
                            <ConcealedRadio value={val} selected={selected} />
                            <ClickableLabel title={val} onChange={onChange} />
                        </span>
                    );
                })}
                <SwitchSelection style={this.selectionStyle()} />
            </Switch>
        );
    }
}

export default ToggleSwitch