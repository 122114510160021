import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Input, Button, CardBody, CardHeader, Spinner, Row } from 'reactstrap';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import './CatchmentContext.css';
import useAuth from "../Utilities/Hooks/useAuth";

const animatedComponents = makeAnimated();


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const PriorityContaminants = () => {

    const [description, setDescription] = useState('');
    const [saving, setSaving] = useState('');
    const [contaminantList, setContaminantList] = useState('');
    const [selectedContaminants, setSelectedContaminants] = useState(''); const [contaminantObj, setContaminantObj] = useState({ contaminants: [], contaminantList: [] })
    const [savedContaminantsList, setSavedContaminantsList] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const { auth } = useAuth();

    useEffect(() => {
        getPriorityContaminants();
    }, [], selectedContaminants)

    const getPriorityContaminants = async () => {
        try {
            const response = await axios.get(
                '/api/catchmentcontext/getprioritycontaminants/', {
                headers: {
                    "Authorization": `Bearer ${auth.token}`
                },
                params: {
                    farmId: farmId
                }
            });

            setDescription(response?.data?.description);
            setSavedContaminantsList(response?.data?.savedContaminantList);
            setContaminantList(response?.data?.contaminantList);

            let contaminantSelectedOptions = [];
            if (response.data.priorityContaminantIds != "" && response.data.priorityContaminantIds != null) {
                let contaminantArray = [];
                contaminantArray = response.data.priorityContaminantIds.split(',');
                response.data.contaminantItems.contaminantList.map(risk => {
                    if (contaminantArray.includes(String(risk.value))) {
                        contaminantSelectedOptions.push({
                            value: risk.value,
                            label: risk.label
                        })
                    }
                })
            }
            setContaminantObj({ ...contaminantObj, contaminants: contaminantSelectedOptions, contaminantList: response.data.contaminantItems.contaminantList });

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message);
            } else {
                toast.error("No server response");
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let priorityContaminantIds = '';
        if (Array.isArray(contaminantObj.contaminants)) {
            let contaminantsArr = [];
            contaminantObj.contaminants.forEach(risk => {
                contaminantsArr.push(risk.value);
            })
            priorityContaminantIds = contaminantsArr.join(",");
        } else {
            priorityContaminantIds = contaminantObj.contaminants.toString();
        }
        setSaving(true);

        try {
            const response = await axios.post(
                '/api/catchmentcontext/saveprioritycontaminants/',
                JSON.stringify(
                    {
                        description, selectedContaminants, farmId, userId, priorityContaminantIds
                    }), {
                headers: { 'Content-Type': 'application/json' }
            });

            setTimeout(() => {
                setSaving(false);
            }, 1000)
            toast.success("Priority Contaminants Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });

            getPriorityContaminants();

        } catch (err) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)


            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Priority Contaminants Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Priority Contaminants Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }
    const handleMultiSelectContaminantChange = (e) => {
        setContaminantObj({ ...contaminantObj, contaminants: e });
    }
    return (
        <>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />

            <CardHeader className="card-header-dark">
                <h5>Priority Contaminants</h5>
            </CardHeader>
            <CardBody>
                {savedContaminantsList != null && savedContaminantsList != "" &&
                    <> 
                        <p>Contaminants of concern based on water quality state and trend data - <b>{savedContaminantsList}</b></p>
                    </>
                }
                {(savedContaminantsList == null || savedContaminantsList == "") &&
                    <>
                        <p>Contaminants of concern based on water quality state and trend data</p>
                    </>
                }

                <Form onSubmit={handleSubmit}>
                    <Col sm={12}>
                        <FormGroup style={{ display: "none" }}>
                            <Input
                                name="priorityContaminant"
                                disabled={true}
                                type="text"
                                value={savedContaminantsList}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>
                        {(savedContaminantsList == null || savedContaminantsList == "") &&
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <div className="contaminants-header">Contaminants </div>
                                            <Select
                                                closeMenuOnSelect={false}
                                                className="dropdown"
                                                placeholder="Select Contaminants"
                                                value={contaminantObj.contaminants}
                                                options={contaminantObj.contaminantList}
                                                onChange={handleMultiSelectContaminantChange}
                                                isMulti hideSelectedOptions={false}
                                                isClearable
                                                components={{
                                                    Option
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </>
                        }
                        {saving ?
                            <Button style={{ float: "right" }}
                                color="success" className="button-save"
                                disabled
                            >
                                <Spinner size="sm">
                                    Saving...
                                </Spinner>
                                <span>
                                    {' '}Saving
                                </span>
                            </Button>
                            :
                            <Button className="button-save" style={{ float: "right" }} color="success" size="sm">
                                Save
                            </Button>
                        }

                    </Col>
                </Form>
            </CardBody>
        </>
    )
}

export default PriorityContaminants;