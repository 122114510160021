import { useState, useRef } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup, Spinner, Collapse, Table } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaFilePdf } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement,Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import axios from 'axios';
import { toPng } from 'html-to-image';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement,Title);

const ProgrammeSummary = ({ programmeSummary, farmsList, allActionsList, actionYearList }) => {
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true); const refQuickSnapshot = useRef(null); const refActionSummary = useRef(null);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '5 Year forecast',
            },
        },
    };
    const barLabels = ['January', 'February', 'March', 'April', 'May'];

    const barData = {
        labels: actionYearList.map(o => o),
        datasets: [
            {
                label: 'Regulatory',
                data: [allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[0]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[0]).length,
                    allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[1]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[1]).length,
                    allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[2]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[2]).length,
                    allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[3]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[3]).length,
                    allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[4]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'R' && o.dueYear == actionYearList[4]).length
                ],
                backgroundColor: 'lightsalmon',
            },
            {
                label: 'Catchment',
                data: [allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[0]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[0]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[1]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[1]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[2]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[2]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[3]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[3]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[4]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'C' && o.dueYear == actionYearList[4]).length
                ],
                backgroundColor: 'lightgreen',
            },
            {
                label: 'Supplementary',
                data: [allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[0]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[0]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[1]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[1]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[2]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[2]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[3]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[3]).length,
                allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[4]).length == 0 ? "" : allActionsList != undefined && allActionsList != null && allActionsList.filter(o => o.category == 'S' && o.dueYear == actionYearList[4]).length
                ],
                backgroundColor: 'lightblue',
            },
        ],
    };

    var farmdata = [farmsList != undefined && farmsList != null && farmsList.filter(x => x.fepNotInPlace == true).length == 0 ? "" : farmsList.filter(x => x.fepNotInPlace == true).length,
        farmsList != undefined && farmsList != null && farmsList.filter(x => x.inProgressFEP == true).length == 0 ? "" : farmsList.filter(x => x.inProgressFEP == true).length,
        farmsList != undefined && farmsList != null && farmsList.filter(x => x.fepReviewedRecently == true).length == 0 ? "" : farmsList.filter(x => x.fepReviewedRecently == true).length,
        farmsList != undefined && farmsList != null && farmsList.filter(x => x.fepReviewedMoreThanYearAgo == true).length == 0 ? "" : farmsList.filter(x => x.fepReviewedMoreThanYearAgo == true).length];
    const pieOptions = {
        legend: {
            display: false,
            position: "right",
            legendCallback: function (chart) {
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    };
    const chartData = {
        labels: ['No ZespriFWFP in place', 'In Progress ZespriFWFP', 'ZespriFWFP Reviewed Recently (last 12 months)','ZespriFWFP Last Reviewed more than a year ago'],
        datasets: [{
            label: 'Farm Summary',
            data: farmdata,
            backgroundColor: [
                'rgb(233, 81, 14, 0.8)',
                'rgb(0, 127, 73, 0.8)',
                'rgb(247, 164, 0, 0.8)',
                'rgb(136, 218, 219, 0.8)'
            ],
            hoverOffset: 4
        }]
    };
    const exportProgrammeSummaryToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var QuickSummary = null; var ActionSummary = null;
        toPng(refQuickSnapshot.current, { cacheBust: true })
            .then((dataUrl) => {
                QuickSummary = dataUrl;
                toPng(refActionSummary.current, { cacheBust: true })
                    .then((dataUrl) => {
                        ActionSummary = dataUrl;
                        const response = handleProgrammeSummaryToPdf(QuickSummary, ActionSummary);
                        toast.promise(response, {
                            loading: 'Generating report...',
                            success: 'Report generated!',
                            error: 'Error when generating report',
                        });
                    })
                    .catch((err) => {
                        const response = handleProgrammeSummaryToPdf(QuickSummary, null);
                        toast.promise(response, {
                            loading: 'Generating report...',
                            success: 'Report generated!',
                            error: 'Error when generating report',
                        });
                    });
            })
            .catch((err) => {
                toPng(refActionSummary.current, { cacheBust: true })
                    .then((dataUrl) => {
                        ActionSummary = dataUrl;
                        const response = handleProgrammeSummaryToPdf(null, ActionSummary);
                        toast.promise(response, {
                            loading: 'Generating report...',
                            success: 'Report generated!',
                            error: 'Error when generating report',
                        });
                    })
                    .catch((err) => {
                        const response = handleProgrammeSummaryToPdf(null, null);
                        toast.promise(response, {
                            loading: 'Generating report...',
                            success: 'Report generated!',
                            error: 'Error when generating report',
                        });
                    });
            });
    }

    const handleProgrammeSummaryToPdf = async (QuickSummary, ActionSummary) => {
        let form = {
            userId: userId, QuickSummary: QuickSummary, ActionSummary: ActionSummary, FarmId: parseInt(farmId)
        };
        const response = await axios.post(
            '/api/allFarmsSummary/ExportProgrammeSummaryPdf/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "ProgrammeSummary.pdf";
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>Programme Summary
                                    <Button style={{ float: "right", background: "none", border: "none", marginRight: "-10px" }} onClick={(e) => exportProgrammeSummaryToPdf(e)} value="Export" placeholder="">
                                        <img src="/images/PDF_icon.png" className="card_icon" ></img>
                                    </Button>
                                </h6></Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    <div className="row mb-3">
                                        <div className="col-md-12" style={{ background:"#f4fcb9" }}>
                                            <span className="sub-header"> Quick Snapshot</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="area-checklist-border">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                                        <table style={{ width: `100%` }} className="fep-table-1">
                                                            <thead>
                                                                <tr>
                                                                    <td>Reviewed Recently (last 12 months)</td>
                                                                    <td className="text-right">{farmsList != undefined && farmsList != null &&
                                                                        farmsList.filter(x => x.fepReviewedRecently == true).length == 0 ? "" : farmsList.filter(x => x.fepReviewedRecently == true).length}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Last Reviewed more than a year ago</td>
                                                                    <td className="text-right">
                                                                        {farmsList != undefined && farmsList != null &&
                                                                            farmsList.filter(x => x.fepReviewedMoreThanYearAgo == true).length == 0 ? "" : farmsList.filter(x => x.fepReviewedMoreThanYearAgo == true).length}
                                                                        {/*{programmeSummary.totalFEPReviewedMoreThanYearAgo == 0 ? "" : programmeSummary.totalFEPReviewedMoreThanYearAgo}*/}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>In Progress</td>
                                                                    <td>
                                                                        {farmsList != undefined && farmsList != null &&
                                                                            farmsList.filter(x => x.inProgressFEP == true).length == 0 ? "" : farmsList.filter(x => x.inProgressFEP == true).length}
                                                                        {/*{programmeSummary.totalInProgressFEP == 0 ? "" : programmeSummary.totalInProgressFEP}*/}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>No FWFP in place</td>
                                                                    <td className="text-right">
                                                                        {farmsList != undefined && farmsList != null &&
                                                                            farmsList.filter(x => x.fepNotInPlace == true).length == 0 ? "" : farmsList.filter(x => x.fepNotInPlace == true).length}
                                                                       {/* {programmeSummary.totalFEPNotInPlace == 0 ? "" : programmeSummary.totalFEPNotInPlace}*/}
                                                                    </td>
                                                                </tr>
                                                                <br/>
                                                                <tr>
                                                                    <td><b>Total Properties</b></td>
                                                                    <td className="text-right">
                                                                        {farmsList != undefined && farmsList != null &&
                                                                            farmsList.length == 0 ? "" : farmsList.length}
                                                                        {/*{programmeSummary.totalSuppliers}*/}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    <div ref={refQuickSnapshot}>
                                                        <div className="col-md-6 col-sm-6 col-xs-6">
                                                            <Pie data={chartData} id="chartProgrammeSummary" options={pieOptions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="area-checklist-border">
                                                <div className="row mb-3" style={{ background: "#f4fcb9" }}>
                                                    <span className="sub-header">Action Summary</span>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="1" width="16%">5 Year forecast</th>
                                                                    <th colSpan="3" width="84%">Improvement Actions & Mitigations</th>
                                                                </tr>
                                                                <tr>
                                                                    <th colSpan="1"></th>
                                                                    <th colSpan="1" style={{ background:"lightsalmon" }}>Regulatory</th>
                                                                    <th colSpan="1" style={{ background:"lightgreen" }}>Catchment</th>
                                                                    <th colSpan="1" style={{ background: "lightblue" }}>Supplementary</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ textAlign: 'center' }}>
                                                                {actionYearList != undefined && actionYearList.length > 0 &&
                                                                    actionYearList.map((year, pidx) =>
                                                                        <>
                                                                            {
                                                                                <>
                                                                                    <tr>
                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {year}</td>
                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                                            {allActionsList != undefined && allActionsList != null && allActionsList.filter(x => x.dueYear == year && x.category == 'R').length == 0 ? "" : allActionsList.filter(x => x.dueYear == year && x.category == 'R').length}
                                                                                        </td>
                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                                            {allActionsList != undefined && allActionsList != null && allActionsList.filter(x => x.dueYear == year && x.category == 'C').length == 0 ? "" : allActionsList.filter(x => x.dueYear == year && x.category == 'C').length}
                                                                                        </td>
                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                                            {allActionsList != undefined && allActionsList != null && allActionsList.filter(x => x.dueYear == year && x.category == 'S').length == 0 ? "" : allActionsList.filter(x => x.dueYear == year && x.category == 'S').length}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    )}
                                                            </tbody>
                                                        </Table>
                                                        <br />
                                                        <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                                            <tbody style={{ textAlign: 'center' }}>
                                                                <tr>
                                                                    <td width="16%" style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> per Property (Total)</td>
                                                                    <td width="28%" style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.total_Regulatory_Actions == 0 ? "" : programmeSummary.total_Regulatory_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.total_Catchment_Actions == 0 ? "" : programmeSummary.total_Catchment_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.total_Supplementary_Actions == 0 ? "" : programmeSummary.total_Supplementary_Actions}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="16%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> per Property (Average)</td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.per_Property_Regulatory_Actions == 0 ? "" : programmeSummary.per_Property_Regulatory_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.per_Property_Catchment_Actions == 0 ? "" : programmeSummary.per_Property_Catchment_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.per_Property_Supplementary_Actions == 0 ? "" : programmeSummary.per_Property_Supplementary_Actions}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="16%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> Overdue Actions</td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.overdue_Regulatory_Actions == 0 ? "" : programmeSummary.overdue_Regulatory_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.overdue_Catchment_Actions == 0 ? "" : programmeSummary.overdue_Catchment_Actions}
                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>
                                                                        {programmeSummary.overdue_Supplementary_Actions == 0 ? "" : programmeSummary.overdue_Supplementary_Actions}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="16%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> Total</td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>

                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>

                                                                    </td>
                                                                    <td width="28%"  style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div ref={refActionSummary}>
                                                        <div className="col-md-9 col-sm-9 col-xs-9">
                                                            <Bar data={barData} id="barProgrammeSummary" options={barOptions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default ProgrammeSummary;