import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody,  Button, FormGroup, Label, Input, Form } from 'reactstrap';
import axios from 'axios';
import "./Login.css";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import SpinnerContainer from "../Ui/SpinnerContainer";
import MyBackgroundImage from '../../../images/login_bg.jpg';
import { FaLock, FaEyeSlash, FaEye } from 'react-icons/fa';

const ChangePwd = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { state } = useLocation(); const navigate = useNavigate(); const [passwordType, setPasswordType] = useState('password');
    const [loading, setLoading] = useState(true); const [id, setId] = useState(''); const [token, setToken] = useState('');
    const [password, setPassword] = useState(''); const [newPassword, setNewPassword] = useState(''); const [newPasswordconfirm, setNewPasswordConfirm] = useState('');
    const [originalPassword, setOriginalPassword] = useState(''); const [formDocumentErrors, setFormDocumentErrors] = useState({ passwordError: '', newPasswordError: '' });
    const [saving, setSaving] = useState(''); const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [newPasswordSuccess, setNewPasswordSuccess] = useState(false); const [newConfirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);
    const handleChangePassword = async (e) => {
        setPassword(e.target.value);
        if (e.target.value != originalPassword) {
            setFormDocumentErrors({ ...formDocumentErrors, passwordError: '' });
            const errors = formDocumentErrors;
            errors.passwordError = "Password is incorrect!";
            setFormDocumentErrors(errors);
            setPasswordSuccess(false);
        }
        else {
            setPasswordSuccess(true);
            setFormDocumentErrors({ ...formDocumentErrors, passwordError: '' });
        }
    }

    const handleChangeNewPassword = async (e) => {
        setNewPassword(e.target.value);
        var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (!e.target.value.match(decimal)) {
            setFormDocumentErrors({ ...formDocumentErrors, newPasswordError: '' });
            const errors = formDocumentErrors;
            errors.newPasswordError = "New Password should contain atleast 8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character!";
            setFormDocumentErrors(errors);
            setNewPasswordSuccess(false);
        }
        else {
            setNewPasswordSuccess(true);
            setFormDocumentErrors({ ...formDocumentErrors, newPasswordError: '' });
        }
    }

    const handleChangeConfirmPassword = async (e) => {
        setNewPasswordConfirm(e.target.value);
        if (e.target.value != newPassword) {
            setFormDocumentErrors({ ...formDocumentErrors, confirmPasswordError: '' });
            const errors = formDocumentErrors;
            errors.confirmPasswordError = "Password is not matching!";
            setFormDocumentErrors(errors);
            setConfirmPasswordSuccess(false);
        }
        else {
            setConfirmPasswordSuccess(true);
            setFormDocumentErrors({ ...formDocumentErrors, confirmPasswordError: '' });
        }
    }

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let Token = params.get('id');
        setToken(Token);
        populateItemData(Token);
    },[])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSaving(true);
        let form = {
            userId: id,
            newPassword: newPassword
        }
        const response = await axios.post(
            '/api/settings/ResetPassword/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("New password is updated, redirecting to login page!", {
                    duration: 4000, position: 'top-center', icon: '👏', iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                });
                setSaving(false);
                setTimeout(() => {
                    window.open("/login", "_self");
                }, 1000);
            }
        }
    }

    const populateItemData = async (id) => {
        try {
            const response = await axios.post(
                '/api/auth/GetUserFromToken/', JSON.stringify(
                    {
                        Token: id
                    }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (response.data.status) {
                if (response.data.message == 'Success') {
                    setId(response.data.content.id);
                    setOriginalPassword(response.data.content.password);
                    setPassword(response.data.content.password);
                    setPasswordSuccess(true);
                }
                else {
                    toast.error(response.data.message, { duration: 4000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.open("/login", "_self");
                    }, 4000);
                }
                setLoading(false);
            }
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }
    const [eye, seteye] = useState(true);
    const [type, settype] = useState(false);
    const Eye = () => {
        if (passwordType == "password") {
            setPasswordType("text");
            seteye(false);
            settype(true);
        }
        else {
            setPasswordType("password");
            seteye(true);
            settype(false);
        }
    }
    return (
        <div className="login-flex" style={{
            backgroundImage: `url(${MyBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '110vh'
        }}>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            {loading ? <SpinnerContainer /> : 
                <div className="login-container">
                    <Card className="resetpassword-card">
                        <CardBody>
                            <div className="login-logo">
                                <img className="login-logo" src="/images/login-zespri-logo.png"></img>
                            </div>
                            <Form onSubmit={handleSubmit} className="login-form">
                                <h2>Reset Password</h2><br />
                                <Row style={{ display: "none" }}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="label" for="name">Current Password</Label>
                                            <Input className="validate" type="password" value={password} onChange={(e) => handleChangePassword(e)} name="password" id="password" autoComplete="new-password" />
                                            
                                        </FormGroup>
                                        {formDocumentErrors.passwordError != undefined &&
                                            <p className="errorMessage">{formDocumentErrors.passwordError}</p>
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="label" for="name">New Password</Label>
                                            <div className="input-text" style={{ position: "relative"}}>
                                                <input type={passwordType} class="ResetPassword" value={newPassword} onChange={(e) => handleChangeNewPassword(e)} placeholder="Enter new password" name="newPassword" id="newPassword" autoComplete="new-password"></input>
                                                {/*<Input type="password" value={newPassword} onChange={(e) => handleChangeNewPassword(e)} name="newPassword" id="newPassword" autoComplete="new-password" />*/}
                                                {/*<FaLock className="ResetPasswordFaLock" />*/}
                                                {
                                                    eye && <FaEyeSlash className="ResetPasswordFaEyeSlash" onClick={Eye} />
                                                }
                                                {
                                                    !eye && <FaEye className="ResetPasswordFaEye"  onClick={Eye} />
                                                }
                                            </div>
                                        </FormGroup>
                                        {formDocumentErrors.newPasswordError != undefined &&
                                            <p className="errorMessage">{formDocumentErrors.newPasswordError}</p>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="label" for="name">Confirm New Password</Label>
                                            <input type="password" class="ResetPassword" value={newPasswordconfirm} onChange={(e) => handleChangeConfirmPassword(e)} placeholder="Enter confirm password" name="newPasswordconfirm" id="newPasswordconfirm" autoComplete="new-password"></input>
                                            {/*<Input type="password" value={newPasswordconfirm} onChange={(e) => handleChangeConfirmPassword(e)} name="newPasswordconfirm" id="newPasswordconfirm" autoComplete="new-password" />*/}
                                        </FormGroup>
                                        {formDocumentErrors.confirmPasswordError != undefined &&
                                            <p className="errorMessage">{formDocumentErrors.confirmPasswordError}</p>
                                        }
                                    </Col>
                                </Row>
                                {
                                    passwordSuccess && newPasswordSuccess && newConfirmPasswordSuccess &&
                                    <>
                                        <Button onClick={handleSubmit} className="btn-login btn-grad" size="sm">
                                            Update Password
                                        </Button>
                                    </>
                                }
                            </Form>
                        </CardBody>
                    </Card>
                </div>
        }
            
        </div>
    );
}

export default ChangePwd;