import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Select, { components } from 'react-select';
import './AllFarmsSummary.css';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import FarmList from '../AllFarmsSummary/FarmList';
import ProgrammeSummary from '../AllFarmsSummary/ProgrammeSummary';
import OpenActionSummary from '../AllFarmsSummary/OpenActionSummary';
import BoundarySummary from '../AllFarmsSummary/BoundarySummary';
import AllFarmRiparianPlantingSummary from '../EnterpriseInsights/AllFarmRiparianPlantingSummary';
import AllFarmMap from '../EnterpriseInsights/AllFarmMap';

const AllFarmsSummary = ({ handleWizardFarmChanged }) => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [catchmentSelect, setCatchmentSelect] = useState([]); const [regionalCouncilSelect, setRegionalCouncilSelect] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allFarmsSummary, setAllFarmsSummary] = useState({ programmeSummaryFarmsList: [],farmsList: [], itemList: [], programmeSummary: {}, openActionSupplierList: [], openActionList: [], openActionsList: [], totalFarmDetails: {}, allActionsList :[]});
    const [tempAllFarmsSummary, setTempAllFarmsSummary] = useState({ programmeSummaryFarmsList: [], allActionsList:[],farmsList: [], itemList: [], programmeSummary: {}, openActionSupplierList: [], openActionList: [], openActionsList: [], totalFarmDetails: {} });
    const [catchments, setCatchments] = useState([]); const [regionalCouncils, setRegionalCouncils] = useState([]);
    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/AllFarmsSummary/GetAllFarmsSummaryDetails/', {
                    params: { UserId: userId, Catchments: '0', RegionalCouncils:'0' }
            }
            );
            setAllFarmsSummary({
                ...allFarmsSummary, farmsList: response.data.farmsList, programmeSummary: response.data.programmeSummary
                , openActionSupplierList: response.data.openActionSupplierList, openActionList: response.data.openActionList, openActionsList: response.data.openActionsList
                , totalFarmDetails: response.data.totalFarmDetails, itemList: response.data.allFarmOutlineList, allActionsList: response.data.allActionsList, actionYearList: response.data.actionYearList,
                programmeSummaryFarmsList: response.data.farmsList
            });
            setTempAllFarmsSummary({
                ...allFarmsSummary, farmsList: response.data.farmsList, programmeSummary: response.data.programmeSummary
                , openActionSupplierList: response.data.openActionSupplierList, openActionList: response.data.openActionList, openActionsList: response.data.openActionsList
                , totalFarmDetails: response.data.totalFarmDetails, itemList: response.data.allFarmOutlineList, allActionsList: response.data.allActionsList, programmeSummaryFarmsList: response.data.farmsList
            });
            setCatchments(response.data.catchments);
            setRegionalCouncils(response.data.regionalCouncils);

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }
    const handleCatchmentChange = async (event) => {
        setCatchmentSelect(event);
        //const tempRegionalCouncils = [...regionalCouncilSelect];
        //var farmList = tempAllFarmsSummary.farmsList;
        //var programmeSummaryFarmsList = tempAllFarmsSummary.programmeSummaryFarmsList;
        //var allActionsList = tempAllFarmsSummary.allActionsList;
        //var openActionList = tempAllFarmsSummary.openActionList;
        //var openActionsList = tempAllFarmsSummary.openActionsList;
        //var openActionSupplierList = tempAllFarmsSummary.openActionSupplierList;
        //var farmArr = [];
        //var farms = [];
        //var programmeSummaryFarms = [];
        //var allActions = [];
        //var openAction = [];
        //var openActions = [];
        //var openActionSupplier = [];
        //if (event.length == 0) {
        //    farms = tempAllFarmsSummary.farmsList;
        //    programmeSummaryFarms = tempAllFarmsSummary.programmeSummaryFarmsList;
        //    allActions = tempAllFarmsSummary.allActionsList;
        //    openAction = tempAllFarmsSummary.openActionList;
        //    openActions = tempAllFarmsSummary.openActionsList;
        //    openActionSupplier = tempAllFarmsSummary.openActionSupplierList;
        //}
        //else {
        //    event.map((item) => {
        //        farmList.filter(x => x.catchmentId == item.value).map((obj) => {
        //            farms.push(obj);
        //        });
        //        programmeSummaryFarmsList.filter(x => x.catchmentId == item.value).map((obj) => {
        //            programmeSummaryFarms.push(obj);
        //        });
        //        allActionsList.filter(x => x.catchmentId == item.value).map((obj) => {
        //            allActions.push(obj);
        //        });
        //        openActionList.filter(x => x.catchmentId == item.value).map((obj) => {
        //            openAction.push(obj);
        //        });
        //        openActionsList.filter(x => x.catchmentId == item.value).map((obj) => {
        //            openActions.push(obj);
        //        });
        //    }
        //    )     

        //    farmArr = [];
        //    openAction.forEach(e => farmArr.push(e.farmId));
        //    openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
        //}
        //var regionalCouncilFarms = farms;
        //var regionalCouncilProgrammeSummaryFarms = programmeSummaryFarms;
        //var regionalCouncilActions = allActions;
        //var regionalCouncilOpenAction = openAction;
        //var regionalCouncilOpenActions = openActions;
        //var regionalCouncilOpenActionSupplier = openActionSupplier;
        //farms = [];
        //programmeSummaryFarms = [];
        //allActions = [];
        //openAction = [];
        //openActions = [];
        //openActionSupplier = [];
        //if (tempRegionalCouncils.length > 0) {
        //    tempRegionalCouncils.map((item) => {
        //        regionalCouncilFarms.filter(x => x.regionalCouncil == item.value).map((obj) => {
        //            farms.push(obj);
        //        });
        //        regionalCouncilProgrammeSummaryFarms.filter(x => x.regionalCouncil == item.value).map((obj) => {
        //            programmeSummaryFarms.push(obj);
        //        });
        //        regionalCouncilActions.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            allActions.push(obj);
        //        });
        //        regionalCouncilOpenAction.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            openAction.push(obj);
        //        });
        //        regionalCouncilOpenActions.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            openActions.push(obj);
        //        });
        //    }
        //    )

        //    farmArr = [];
        //    openAction.forEach(e => farmArr.push(e.farmId));
        //    openActionSupplier = regionalCouncilOpenActionSupplier.filter(x => (farmArr.includes(parseInt(x.id))));

        //    regionalCouncilFarms = farms;
        //    regionalCouncilProgrammeSummaryFarms = programmeSummaryFarms;
        //    regionalCouncilActions = allActions;
        //    regionalCouncilOpenAction = openAction;
        //    regionalCouncilOpenActions = openActions;
        //    regionalCouncilOpenActionSupplier = openActionSupplier;
        //}
        //if (name != '') {
        //    regionalCouncilFarms = regionalCouncilFarms.filter(x => (x.farmUniqueIdentifier.toLowerCase().includes(name) || x.farmname.toLowerCase().includes(name)));
        //}
        //if (actionFarmName != '') {
        //    var list = regionalCouncilOpenAction;
        //    var list2 = regionalCouncilOpenActions;
        //    var list1 = regionalCouncilOpenActionSupplier;
        //    regionalCouncilOpenAction = [];
        //    regionalCouncilOpenActions = [];
        //    regionalCouncilOpenActionSupplier = [];
        //    regionalCouncilOpenAction = list.filter(x => (x.farmName.toLowerCase().includes(actionFarmName.toLowerCase())));
        //    farmArr = [];
        //    regionalCouncilOpenAction.forEach(e => farmArr.push(e.farmId));
        //    regionalCouncilOpenActionSupplier = list1.filter(x => (farmArr.includes(parseInt(x.id))));
        //    regionalCouncilOpenActions = list2.filter(x => x.farmName.toLowerCase().includes(actionFarmName.toLowerCase()));
        //}
        //setAllFarmsSummary({
        //    ...allFarmsSummary, farmsList: regionalCouncilFarms, allActionsList: regionalCouncilActions, programmeSummaryFarmsList: regionalCouncilProgrammeSummaryFarms,
        //    openActionList: regionalCouncilOpenAction, openActionsList: regionalCouncilOpenActions, openActionSupplierList: regionalCouncilOpenActionSupplier
        //});
    }
    const handleRegionalCouncilChange = async (event) => {
        setRegionalCouncilSelect(event);
        //const tempCatchments = [...catchmentSelect];
        //var farmList = tempAllFarmsSummary.farmsList;
        //var programmeSummaryFarmsList = tempAllFarmsSummary.programmeSummaryFarmsList;
        //var allActionsList = tempAllFarmsSummary.allActionsList;
        //var openActionList = tempAllFarmsSummary.openActionList;
        //var openActionsList = tempAllFarmsSummary.openActionsList;
        //var openActionSupplierList = tempAllFarmsSummary.openActionSupplierList;
        //var farmArr = [];
        //var farms = [];
        //var programmeSummaryFarms = [];
        //var allActions = [];
        //var openAction = [];
        //var openActions = [];
        //var openActionSupplier = [];
        //if (event.length == 0) {
        //    farms = tempAllFarmsSummary.farmsList;
        //    programmeSummaryFarms = tempAllFarmsSummary.programmeSummaryFarmsList;
        //    allActions = tempAllFarmsSummary.allActionsList;
        //    openAction = tempAllFarmsSummary.openActionList;
        //    openActions = tempAllFarmsSummary.openActionsList;
        //    openActionSupplier = tempAllFarmsSummary.openActionSupplierList;
        //}
        //else {
        //    event.map((item) => {
        //        farmList.filter(x => x.regionalCouncil == item.value).map((obj) => {
        //            farms.push(obj);
        //        });
        //        programmeSummaryFarmsList.filter(x => x.regionalCouncil == item.value).map((obj) => {
        //            programmeSummaryFarms.push(obj);
        //        });
        //        allActionsList.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            allActions.push(obj);
        //        });
        //        openActionList.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            openAction.push(obj);
        //        });
        //        openActionsList.filter(x => x.regionalCouncilId == item.value).map((obj) => {
        //            openActions.push(obj);
        //        });
        //    }
        //    )
        //    farmArr = [];
        //    openAction.forEach(e => farmArr.push(e.farmId));
        //    openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
        //}

        //var catchmentFarms = farms;
        //var catchmentProgrammeSummaryFarms = programmeSummaryFarms;
        //var regionalCouncilOpenAction = openAction;
        //var regionalCouncilOpenActions = openActions;
        //var regionalCouncilOpenActionSupplier = openActionSupplier;
        //farms = [];
        //programmeSummaryFarms = [];
        //var catchmentActions = allActions;
        //allActions = [];
        //openAction = [];
        //openActions = [];
        //openActionSupplier = [];
        //if (tempCatchments.length > 0) {
        //    tempCatchments.map((item) => {
        //        catchmentFarms.filter(x => x.catchmentId == item.value).map((obj) => {
        //            farms.push(obj);
        //        });
        //        catchmentProgrammeSummaryFarms.filter(x => x.catchmentId == item.value).map((obj) => {
        //            programmeSummaryFarms.push(obj);
        //        });
        //        catchmentActions.filter(x => x.catchmentId == item.value).map((obj) => {
        //            allActions.push(obj);
        //        });
        //        regionalCouncilOpenAction.filter(x => x.catchmentId == item.value).map((obj) => {
        //            openAction.push(obj);
        //        });
        //        regionalCouncilOpenActions.filter(x => x.catchmentId == item.value).map((obj) => {
        //            openActions.push(obj);
        //        });
        //    }
        //    )

        //    farmArr = [];
        //    openAction.forEach(e => farmArr.push(e.farmId));
        //    openActionSupplier = regionalCouncilOpenActionSupplier.filter(x => (farmArr.includes(parseInt(x.id))));
        //    catchmentFarms = farms;
        //    catchmentProgrammeSummaryFarms = programmeSummaryFarms;
        //    catchmentActions = allActions;
        //    regionalCouncilOpenAction = openAction;
        //    regionalCouncilOpenActions = openActions;
        //    regionalCouncilOpenActionSupplier = openActionSupplier;
        //}

        //if (name != '') {
        //    catchmentFarms = catchmentFarms.filter(x => (x.farmUniqueIdentifier.toLowerCase().includes(name) || x.farmname.toLowerCase().includes(name)));
        //}
        //if (actionFarmName != '') {
        //    var list = regionalCouncilOpenAction;
        //    var list2 = regionalCouncilOpenActions;
        //    var list1 = regionalCouncilOpenActionSupplier;
        //    regionalCouncilOpenAction = [];
        //    regionalCouncilOpenActions = [];
        //    regionalCouncilOpenActionSupplier = [];
        //    regionalCouncilOpenAction = list.filter(x => (x.farmName.toLowerCase().includes(actionFarmName.toLowerCase())));
        //    farmArr = [];
        //    regionalCouncilOpenAction.forEach(e => farmArr.push(e.farmId));
        //    regionalCouncilOpenActionSupplier = list1.filter(x => (farmArr.includes(parseInt(x.id))));
        //    regionalCouncilOpenActions = list2.filter(x => x.farmName.toLowerCase().includes(actionFarmName.toLowerCase()));
        //}
        //setAllFarmsSummary({
        //    ...allFarmsSummary, farmsList: catchmentFarms, allActionsList: catchmentActions, programmeSummaryFarmsList: catchmentProgrammeSummaryFarms,
        //    openActionList: regionalCouncilOpenAction, openActionsList: regionalCouncilOpenActions, openActionSupplierList: regionalCouncilOpenActionSupplier
        //});
    }
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const [name, setName] = useState('');
    const handleChangeName = async (event) => {
        setName(event.target.value);
        if (event.target.value == '') {
            setAllFarmsSummary({
                ...allFarmsSummary, farmsList: tempAllFarmsSummary.farmsList
            });
            //setFarmItems(tempFarmsList);
            //setCurrentPage(1);
            //let currentPage = 1;
            //let indexOfLastItem = currentPage * itemsPerPage;
            //let indexOfFirstItem = indexOfLastItem - itemsPerPage;
            //setCurrentitems(tempFarmsList.slice(indexOfFirstItem, indexOfLastItem));

        }
        else {
            var list = [];
            list = tempAllFarmsSummary.farmsList.filter(x => (x.name.toLowerCase().includes(event.target.value.toLowerCase()) || x.uniqueIdentifier.toLowerCase().includes(event.target.value.toLowerCase())));
            setAllFarmsSummary({
                ...allFarmsSummary, farmsList: list
            });
            //setFarmItems(list);
            //setCurrentitems(list.slice(indexOfFirstItem, indexOfLastItem));
        }
    }
    const [actionFarmName, setActionFarmName] = useState('');
    const handleFarmIdChange = (e) => {
        setActionFarmName(e.target.value)
        if ((e.target.value) == '') {
            const tempCatchments = [...catchmentSelect];
            const tempRegionalCouncils = [...regionalCouncilSelect];
            var openActionList = tempAllFarmsSummary.openActionList;
            var openActionsList = tempAllFarmsSummary.openActionsList;
            var openActionSupplierList = tempAllFarmsSummary.openActionSupplierList;
            var farmArr = [];
            var openAction = [];
            var openActions = [];
            var openActionSupplier = [];
            if (tempCatchments.length == 0) {
                openAction = tempAllFarmsSummary.openActionList;
                openActions = tempAllFarmsSummary.openActionsList;
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList;
            }
            else {
                tempCatchments.map((item) => {
                    openActionList.filter(x => x.catchmentId == item.value).map((obj) => {
                        openAction.push(obj);
                    });
                    openActionsList.filter(x => x.catchmentId == item.value).map((obj) => {
                        openActions.push(obj);
                    });
                }
                )

                farmArr = [];
                openAction.forEach(e => farmArr.push(e.farmId));
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
            }
            var regionalCouncilOpenAction = openAction;
            var regionalCouncilOpenActions = openActions;
            var regionalCouncilOpenActionSupplier = openActionSupplier;
            openAction = [];
            openActions = [];
            openActionSupplier = [];
            if (tempRegionalCouncils.length > 0) {
                tempRegionalCouncils.map((item) => {
                    regionalCouncilOpenAction.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                        openAction.push(obj);
                    });
                    regionalCouncilOpenActions.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                        openActions.push(obj);
                    });
                }
                )

                farmArr = [];
                openAction.forEach(e => farmArr.push(e.farmId));
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
                regionalCouncilOpenAction = openAction;
                regionalCouncilOpenActions = openActions;
                regionalCouncilOpenActionSupplier = openActionSupplier;
            }
            setAllFarmsSummary({
                ...allFarmsSummary, openActionList: regionalCouncilOpenAction, openActionsList: regionalCouncilOpenActions, openActionSupplierList: regionalCouncilOpenActionSupplier
            });
        }
        else {
            const tempCatchments = [...catchmentSelect];
            const tempRegionalCouncils = [...regionalCouncilSelect];
            var openActionList = tempAllFarmsSummary.openActionList;
            var openActionsList = tempAllFarmsSummary.openActionsList;
            var openActionSupplierList = tempAllFarmsSummary.openActionSupplierList;
            var farmArr = [];
            var openAction = [];
            var openActions = [];
            var openActionSupplier = [];
            if (tempCatchments.length == 0) {
                openAction = tempAllFarmsSummary.openActionList;
                openActions = tempAllFarmsSummary.openActionsList;
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList;
            }
            else {
                tempCatchments.map((item) => {
                    openActionList.filter(x => x.catchmentId == item.value).map((obj) => {
                        openAction.push(obj);
                    });
                    openActionsList.filter(x => x.catchmentId == item.value).map((obj) => {
                        openActions.push(obj);
                    });
                }
                )

                farmArr = [];
                openAction.forEach(e => farmArr.push(e.farmId));
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
            }
            var regionalCouncilOpenAction = openAction;
            var regionalCouncilOpenActions = openActions;
            var regionalCouncilOpenActionSupplier = openActionSupplier;
            openAction = [];
            openActions = [];
            openActionSupplier = [];
            if (tempRegionalCouncils.length > 0) {
                tempRegionalCouncils.map((item) => {
                    regionalCouncilOpenAction.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                        openAction.push(obj);
                    });
                    regionalCouncilOpenActions.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                        openActions.push(obj);
                    });
                }
                )

                farmArr = [];
                openAction.forEach(e => farmArr.push(e.farmId));
                openActionSupplier = tempAllFarmsSummary.openActionSupplierList.filter(x => (farmArr.includes(parseInt(x.id))));
                regionalCouncilOpenAction = openAction;
                regionalCouncilOpenActions = openActions;
                regionalCouncilOpenActionSupplier = openActionSupplier;
            }

            var list = regionalCouncilOpenAction;
            var list2 = regionalCouncilOpenActions;
            var list1 = regionalCouncilOpenActionSupplier;
            regionalCouncilOpenAction = [];
            regionalCouncilOpenActions = [];
            regionalCouncilOpenActionSupplier = [];
            regionalCouncilOpenAction = list.filter(x => (x.farmName.toLowerCase().includes(e.target.value.toLowerCase()) || x.uniqueIdentifier.toLowerCase().includes(e.target.value.toLowerCase()) ));
            farmArr = [];
            regionalCouncilOpenAction.forEach(e => farmArr.push(e.farmId));
            regionalCouncilOpenActionSupplier = list1.filter(x => (farmArr.includes(parseInt(x.id))));
            regionalCouncilOpenActions = list2.filter(x => x.farmName.toLowerCase().includes(e.target.value.toLowerCase()) || x.uniqueIdentifier.toLowerCase().includes(e.target.value.toLowerCase()) );

            setAllFarmsSummary({
                ...allFarmsSummary, openActionList: regionalCouncilOpenAction, openActionsList: regionalCouncilOpenActions, openActionSupplierList: regionalCouncilOpenActionSupplier
            });
        }
    }
    const handleApplyFilter = async (e) => {
        setLoading(true);
        let catchments = '';
        if (Array.isArray(catchmentSelect)) {
            let catchmentArr = [];
            catchmentSelect.forEach(type => {
                catchmentArr.push(type.value);
            })
            catchments = catchmentArr.join(",");
        }
        else if (typeof catchmentSelect === 'object' && catchmentSelect.constructor === Object) {
            catchments = catchmentSelect.value.toString();
        }
        else {
            catchments = catchmentSelect.toString();
        }
        let regionalCouncils = '';
        if (Array.isArray(regionalCouncilSelect)) {
            let regionalCouncilArr = [];
            regionalCouncilSelect.forEach(type => {
                regionalCouncilArr.push(type.value);
            })
            regionalCouncils = regionalCouncilArr.join(",");
        }
        else if (typeof regionalCouncilSelect === 'object' && regionalCouncilSelect.constructor === Object) {
            regionalCouncils = regionalCouncilSelect.value.toString();
        }
        else {
            regionalCouncils = regionalCouncilSelect.toString();
        }

        try {
            const response = await axios.get(
                '/api/AllFarmsSummary/GetAllFarmsSummaryDetails/', {
                    params: { UserId: userId, Catchments: catchments == '' ? '0' : catchments, RegionalCouncils: regionalCouncils == '' ? '0' : regionalCouncils }
            }
            );
            setAllFarmsSummary({
                ...allFarmsSummary, farmsList: response.data.farmsList, programmeSummary: response.data.programmeSummary
                , openActionSupplierList: response.data.openActionSupplierList, openActionList: response.data.openActionList, openActionsList: response.data.openActionsList
                , totalFarmDetails: response.data.totalFarmDetails, itemList: response.data.allFarmOutlineList, allActionsList: response.data.allActionsList, actionYearList: response.data.actionYearList,
                programmeSummaryFarmsList: response.data.farmsList
            });
            setTempAllFarmsSummary({
                ...allFarmsSummary, farmsList: response.data.farmsList, programmeSummary: response.data.programmeSummary
                , openActionSupplierList: response.data.openActionSupplierList, openActionList: response.data.openActionList, openActionsList: response.data.openActionsList
                , totalFarmDetails: response.data.totalFarmDetails, itemList: response.data.allFarmOutlineList, allActionsList: response.data.allActionsList, programmeSummaryFarmsList: response.data.farmsList
            });
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>All Orchard Summary</h2></Col>
            </Row>
            <br />
            <Row>
                <Col sm="1"><h6>Filter by: </h6></Col>
                <Col sm="3">
                    <FormGroup>
                        <Label className="label" for="catchmentSelect">Catchment</Label>
                        {catchments == undefined && <p><em>Loading...</em></p>}
                        {catchments != undefined && catchments.length > 0 &&
                            <Select
                                id="catchmentSelect" isMulti width="max-content"
                                name="catchmentSelect"
                                closeMenuOnSelect={false}
                                className="dropdown"
                                placeholder="Select Catchment"
                                value={catchmentSelect}
                                options={catchments}
                                onChange={handleCatchmentChange}
                                isClearable hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Label className="label" for="regionalCouncilSelect">Regional Council</Label>
                        {catchments == undefined && <p><em>Loading...</em></p>}
                        {catchments != undefined && catchments.length > 0 &&
                            <Select
                                id="regionalCouncilSelect" isMulti width="max-content"
                                name="regionalCouncilSelect"
                                closeMenuOnSelect={false}
                                className="dropdown"
                                placeholder="Select Regional Council"
                                value={regionalCouncilSelect}
                                options={regionalCouncils}
                                onChange={handleRegionalCouncilChange}
                                isClearable hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <br />
                    <Button style={{ float: "right" }} color="success" onClick={(e) => handleApplyFilter(e)} value="Apply Filter" placeholder="">
                        Apply Filter
                    </Button>
                </Col>
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <Col sm="5">
                            <ProgrammeSummary actionYearList={allFarmsSummary.actionYearList} allActionsList={allFarmsSummary.allActionsList}
                                programmeSummary={allFarmsSummary.programmeSummary} farmsList={allFarmsSummary.programmeSummaryFarmsList}></ProgrammeSummary> <br />
                            
                        </Col>
                        <Col sm="7">
                            <FarmList userId={userId} tempFarmsList={allFarmsSummary.farmsList} farmsList={allFarmsSummary.farmsList}
                                totalFarmDetails={allFarmsSummary.totalFarmDetails} itemModel={allFarmsSummary} handleChangeName={handleChangeName} name={name} setName={setName}
                                handleWizardFarmChanged={handleWizardFarmChanged}></FarmList>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="5">
                            <OpenActionSummary name={actionFarmName} setName={setActionFarmName} handleFarmIdChange={handleFarmIdChange} openActionSupplierList={allFarmsSummary.openActionSupplierList} openActionList={allFarmsSummary.openActionList} openActionsList={allFarmsSummary.openActionsList}></OpenActionSummary>
                        </Col>
                        <Col sm="7">
                            <BoundarySummary userId={userId} tempFarmsList={allFarmsSummary.farmsList} farmsList={allFarmsSummary.farmsList}
                                totalFarmDetails={allFarmsSummary.totalFarmDetails} itemModel={allFarmsSummary}
                                handleWizardFarmChanged={handleWizardFarmChanged}></BoundarySummary>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm="5">
                            <AllFarmRiparianPlantingSummary></AllFarmRiparianPlantingSummary>
                        </Col>
                        
                    </Row>
                    
                </div>
            }
        </div>
    )
}
export default AllFarmsSummary;