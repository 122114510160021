import Header from './Header';
import Biodiversity from './Biodiversity';
import Risk from './Risk';
const ItemCommonTop = ({ handleLMUChange, itemPopupHeader, itemTypeFieldList, itemPopupItemSepcQ, itemPopupMaptype, handleItemPopupHeaderChange, itemPopupBiodiversity, largeEnterpriseDetails, formErrors, riskLookupList,
    handleItemPopupBiodiversity, itemPopupRisk, handleItemPopupRisk, Section, handleMultiSelectEnterpriseChange, handleMultiSelectContaminantChange, handleMultiSelectRiskDataGroupChange, handleMultiSelectActivityGroupChange, handleMultiSelectActivitySubGroupChange }) => {
    return (
        <div>
            <Header Section={Section} handleLMUChange={handleLMUChange} itemPopupHeader={itemPopupHeader} itemTypeFieldList={itemTypeFieldList} itemPopupItemSepcQ={itemPopupItemSepcQ} itemPopupMaptype={itemPopupMaptype} formErrors={formErrors} largeEnterpriseDetails={largeEnterpriseDetails}
                handleItemPopupHeaderChange={handleItemPopupHeaderChange} >
            </Header>
            {Section != 'Community & Cultural Significance' && itemPopupBiodiversity.isVisible ?
                <Biodiversity itemPopupBiodiversity={itemPopupBiodiversity}
                    handleItemPopupBiodiversity={handleItemPopupBiodiversity} >
                </Biodiversity> : ''}
            {Section != 'Community & Cultural Significance' && <>
                <Risk formErrors={formErrors} itemPopupRisk={itemPopupRisk} riskLookupList={riskLookupList}
                    handleItemPopupRisk={handleItemPopupRisk} handleMultiSelectEnterpriseChange={handleMultiSelectEnterpriseChange}
                    handleMultiSelectContaminantChange={handleMultiSelectContaminantChange}
                    handleMultiSelectRiskDataGroupChange={handleMultiSelectRiskDataGroupChange}
                    handleMultiSelectActivityGroupChange={handleMultiSelectActivityGroupChange}
                    handleMultiSelectActivitySubGroupChange={handleMultiSelectActivitySubGroupChange}></Risk>
                <br />
            </>}
            
        </div>
    )
}
export default ItemCommonTop