import React from 'react'
import { useState, useEffect } from 'react';
import { Row, Col, Button, Label, Input, Form, FormGroup, Collapse, Card, CardBody, CardHeader, } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const Achievements = ({ itemPopupAchievements, handleItemPopupAchievements, showAchievementsInfo, infoAchievementsMessage, infoAchievementsId }) => {
    const [isOpen, setIsOpen] = useState(itemPopupAchievements.isExpand == true ? true : false);
    const [isIconUp, setIsIconUp] = useState(itemPopupAchievements.isExpand == true ? true :false);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    return (
        <Card>
            <CardHeader className="card-header-click"  onClick={toggle}>
                <Row>
                    <Col sm="11">
                        <h6>{showAchievementsInfo && <> <FaInfoCircle data-tooltip-id={infoAchievementsId} />&nbsp; &nbsp; &nbsp; </>}Achievements</h6>
                        {showAchievementsInfo && <>
                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                id={infoAchievementsId}
                                place="top" className="overrideMe"
                                variant="info"
                                render={({ content, activeAnchor }) => (
                                    <>
                                        <Row>
                                            <Col>
                                                <p style={{ fontWeight: "lighter" }}>
                                                    {infoAchievementsMessage}
                                                </p>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            />
                        </>}
                    </Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
            <CardBody>               
                <Row>
                    <Col sm="1">
                        <input className="islargeEnterprise form-check-input" name="isAchievementsNotes" type="checkbox" checked={itemPopupAchievements.isAchievementsNotes} onChange={handleItemPopupAchievements} />

                    </Col>
                    <Col sm="5">
                        <label>
                            Is Achievement of Note?
                        </label>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label>Achievement of Note Comments</Label>
                                <Input type="textarea" name="achievementsComment" id="achievementsComment" value={itemPopupAchievements.achievementsComment} onChange={handleItemPopupAchievements} />
                        </FormGroup>
                    </Col>

                </Row>
                </CardBody>
            </Collapse>
        </Card> 
    )

}
export default Achievements