import React, { useState, useEffect, useMemo } from 'react';
import Moment from 'moment';
import { Row, Col, FormGroup, Label, Input, Button, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, CardHeader, Collapse, Modal, Form, ModalHeader, ModalBody, ModalFooter, Spinner, Badge } from 'reactstrap';
import { FaChevronRight, FaPlus, FaChevronUp, FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import uuid from 'react-uuid';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Action from '../Action/Action';
import Documents from '../Uploads/Documents';
import ActionsToConsider from '../FarmApt/ActionsToConsider';
import { useNavigate, useLocation } from "react-router-dom";
import MapView from '../Map/MapView';
import ItemTypeListView from '../ItemsView/ItemTypeListView';
import MapType from '../ItemPopup/MapType';
import Validate from '../Validate/Validate';
import NitrogenBalanceCalc from './NitrogenBalanceCalc'
import RiskSummary from '../ItemsView/RiskSummary';
import moment from "moment";
import './Nutrients.css';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import { useSearchParams } from 'react-router-dom';

const Nutrients = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName"); const [calcValueChanged, setCalcValueChanged] = useState(false);
    const isAddArchiveItem = localStorage.getItem("isAddArchiveItem");
    const isCreateNewAction = localStorage.getItem("isCreateNewAction");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const { state } = useLocation(); const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const navigate = useNavigate();
    const [saving, setSaving] = useState('');const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const [loading, setLoading] = useState(true); const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [nutrients, setNutrients] = useState({
        outlineList: [], itemList: [], itemTypes: [], mapItemTypes: [], Section: 'Nutrients', SectionName: 'Nutrients', SectionObj: {}, isWizardCompleted: false, farmCenter: {},
        actionTypes: [], contaminants: [], goodFarmingPractices: [], goodFarmingPracticeGroups: [], actions: [], seasonList: [], nutrientOLSENPList: [], nutrientCalculatorDetail: {}
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0 })
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 15, polyType: '', itemsEditable: false, highlighteditemId: 0, isClear: false
    });
    const [showActionPopup, setShowActionPopup] = useState(false);
    const [type, setType] = useState(0); const [name, setName] = useState('');
    const [actionType, setActionType] = useState(0);
    const [id, setId] = useState(0);
    const [activeTab, setActiveTab] = useState('3');
    const [nutrientSeasonList, setNutrientSeasonList] = useState([]);
    const [sectionObj, setSectionObj] = useState({});
    const [seasonData, setSeasonData] = useState(
        {
            CurrentSeason: {season:"", nitrogen_Total_loss: null, phosphorous_Total_loss: null, nitrogen_Load_loss: null, phosphorous_Load_loss: null, nitrogen_Conversion_Efficiency_Per: null, n_Purchased_Surplus: null },
            CurrentSeasonMinus1: {season:"", nitrogen_Total_loss: null, phosphorous_Total_loss: null, nitrogen_Load_loss: null, phosphorous_Load_loss: null, nitrogen_Conversion_Efficiency_Per: null, n_Purchased_Surplus: null },
            CurrentSeasonMinus2: {season:"", nitrogen_Total_loss: null, phosphorous_Total_loss: null, nitrogen_Load_loss: null, phosphorous_Load_loss: null, nitrogen_Conversion_Efficiency_Per: null, n_Purchased_Surplus: null }
        });
    const [closedActionCount, setClosedActionCount] = useState(0);
    const [openActionCount, setOpenActionCount] = useState(0);
    const [isClosedActionShow, setIsClosedActionShow] = useState(false);

    const [savingNutrientDetails, setSavingNutrientDetails] = useState('');
    const [riskSummaryPopup, setRiskSummaryPopup] = useState({ showriskSummary: false });

    const [isOpen, setIsOpen] = useState(false);
    const [nLossRiskAssessment, setNLossRiskAssessment] = useState({});
    const [riskTypeList, setRiskTypeList] = useState([]);
    const [annualRainfallList, setAnnualRainfallList] = useState([]);
    const [isIconUp, setIsIconUp] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        populateItemData();
    }, []);

    const [checkCalculatorDataPopup, setCheckCalculatorDataPopup] = useState({ showPopup: false, section: '', method: '' });

    function checkCalculatorData(section,method) {
        if (calcValueChanged) {
            setCheckCalculatorDataPopup({ showPopup: true, section: section, method: method });
        }
        else {
            if (method == 'handleWizardChanged') {
                handleWizardChanged(section);
            }
            else if (method == 'handleMoveToNextPage') {
                handleMoveToNextPage(section);
            }
            else if (method == 'handleWizardChangedBack') {
                handleWizardChangedBack(section);
            }
        }
    }

    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/nutrients/GetNutrients/', {
                params: { farmId: farmId }
            }
            );
            if (response.data.status) {
                if (response.data.section != null) {
                    var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                    if (section.length > 0 != null) {
                        setIsNextButtonVisible(section[0].isSectionCompleted);
                    }
                }
                setLoading(false);
                setNutrients({
                    ...nutrients, outlineList: response.data.content.outlineList, itemList: response.data.content.nutrientItems, itemTypes: response.data.content.itemTypes,
                    mapItemTypes: response.data.content.mapItemTypes, SectionObj: response.data.content.section, isWizardCompleted: response.data.content.isFWFPCompleted, farmCenter: response.data.content.farmCenter,
                    actionTypes: response.data.content.actionTypes, contaminants: response.data.content.contaminants, goodFarmingPractices: response.data.content.goodFarmingPractices,
                    goodFarmingPracticeGroups: response.data.content.goodFarmingPracticeGroups, actions: response.data.content.actions,
                    nutrientCalculatorDetail: response.data.content.nutrientCalculatorDetail
                });

                setMapDetails({ ...mapDetails, zoomLevel: response.data.content.selectedZoomlevel });
                setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.content.islargeEnterprise, LMUList: response.data.content.lmuItemList })
                setNutrientSeasonList(response.data.content.seasonList);
                setSeasonData({
                    ...seasonData,
                    CurrentSeason: response.data.content.currentSeason
                    , CurrentSeasonMinus1: response.data.content.currentSeasonMinus1, CurrentSeasonMinus2: response.data.content.currentSeasonMinus2
                });
                setOpenActionCount(response.data.content.actions.filter(action => action.isCloseout != true).length);
                setClosedActionCount(response.data.content.actions.filter(action => action.isCloseout == true).length);
                setSectionObj(response.data.content.section);
                setNLossRiskAssessment(response.data.content.nLossRiskAssessment);
                setRiskTypeList(response.data.content.riskTypeList);
                setAnnualRainfallList(response.data.content.annualRainfallList);
                if (state != null) {
                    const { itemId, actionId, navigateFrom } = state;
                    navigate(state.pathname, { replace: true });
                    if (navigateFrom == "Actions") {
                        handleNavigateOpenActionPopup(itemId, actionId, response.data.content);
                    }
                }
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let navigateFrom = params.get('navigateFrom');
                if (navigateFrom == 'Actions') {
                    let actionId = params.get('actionId');
                    let itemId = params.get('itemId');
                    let isWholeFarmAction = params.get('isWholeFarmAction');
                    if (searchParams.has('actionId')) {
                        searchParams.delete('actionId');
                        setSearchParams(searchParams);
                    }
                    if (searchParams.has('itemId')) {
                        searchParams.delete('itemId');
                        setSearchParams(searchParams);
                    }
                    if (searchParams.has('isWholeFarmAction')) {
                        searchParams.delete('isWholeFarmAction');
                        setSearchParams(searchParams);
                    }
                    if (searchParams.has('navigateFrom')) {
                        searchParams.delete('navigateFrom');
                        setSearchParams(searchParams);
                    }
                    if (navigateFrom == "Actions") {
                        handleNavigateOpenActionPopup(itemId, actionId, response.data.content);
                    }
                }
            }
            
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, ghg) => {
        setShowActionPopup(true);
        setId(actionId)
    };

    const handleOpenActionPopup = async (e) => {
        setShowActionPopup(true);
        setId(0)
    };

    const handleOpenAction = async (e, item) => {
        setShowActionPopup(true);
        setId(item.id)
    };

    function handleActionClose() {
        setShowActionPopup(false);
    }

    function handleClosedActionShow(event) {
        setIsClosedActionShow(event.target.checked);
    }

    const seasonDataChange = async (event, object, variable) => {
        let tempSeasonData = seasonData;
        if (object == "CurrentSeason") {
            if (variable == "total_nitrogen_added") {
                tempSeasonData.CurrentSeason.total_nitrogen_added = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_removed") {
                tempSeasonData.CurrentSeason.total_nitrogen_removed = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_surplus") {
                tempSeasonData.CurrentSeason.total_nitrogen_surplus = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
        }
        else if (object == "CurrentSeasonMinus1") {
            if (variable == "total_nitrogen_added") {
                tempSeasonData.CurrentSeasonMinus1.total_nitrogen_added = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_removed") {
                tempSeasonData.CurrentSeasonMinus1.total_nitrogen_removed = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_surplus") {
                tempSeasonData.CurrentSeasonMinus1.total_nitrogen_surplus = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
        }
        else if (object == "CurrentSeasonMinus2") {
            if (variable == "total_nitrogen_added") {
                tempSeasonData.CurrentSeasonMinus2.total_nitrogen_added = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_removed") {
                tempSeasonData.CurrentSeasonMinus2.total_nitrogen_removed = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
            else if (variable == "total_nitrogen_surplus") {
                tempSeasonData.CurrentSeasonMinus2.total_nitrogen_surplus = event.target.value == null || event.target.value == '' ? 0 : parseFloat(event.target.value);
            }
        }
        setSeasonData({
            ...seasonData,
            CurrentSeason: tempSeasonData.CurrentSeason, CurrentSeasonMinus1: tempSeasonData.CurrentSeasonMinus1, CurrentSeasonMinus2: tempSeasonData.CurrentSeasonMinus2
        });
    }

    const handleNutrientDetailsSubmit = async (e) => {
        e.preventDefault();
        setSavingNutrientDetails(true);
        let form = {
            FarmId: farmId,
            UserId: userId,
            CurrentSeason: seasonData.CurrentSeason,
            CurrentSeasonMinus1: seasonData.CurrentSeasonMinus1,
            CurrentSeasonMinus2: seasonData.CurrentSeasonMinus2
        }
        try {
            const response = await axios.post(
                '/api/nutrients/SaveNutrientDetails/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                setSavingNutrientDetails(false);
                setSeasonData({
                    ...seasonData,CurrentSeason: response.data.content.currentSeason,CurrentSeasonMinus1: response.data.content.currentSeasonMinus1,CurrentSeasonMinus2: response.data.content.currentSeasonMinus2
                });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRiskSummaryClose = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: false })
        window.location.reload(true);
    }

    const handleRiskSummaryOpen = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: true })
    }

    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={nutrients}
                mapDetails={mapDetails}
                largeEnterpriseDetails={largeEnterpriseDetails} width={'100%'}
            ></MapView>
        )
    }, [nutrients, mapDetails, largeEnterpriseDetails])

    const handleCheckCalculatorDataPopupClose = () => {
        setCheckCalculatorDataPopup({ showPopup: false, section: '', method: '' });
    }
    const handleCheckCalculatorDataPopupConfirm = () => {
        if (checkCalculatorDataPopup.method == 'handleWizardChanged') {
            handleWizardChanged(checkCalculatorDataPopup.section);
        }
        else if (checkCalculatorDataPopup.method == 'handleMoveToNextPage') {
            handleMoveToNextPage(checkCalculatorDataPopup.section);
        }
        else if (checkCalculatorDataPopup.method == 'handleWizardChangedBack') {
            handleWizardChangedBack(checkCalculatorDataPopup.section);
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{nutrients.SectionName}</h2></Col>
                <Col>
                    {nutrients.isWizardCompleted != true && !loading &&
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => checkCalculatorData(nutrients.Section,'handleWizardChanged')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => checkCalculatorData(nutrients.Section,'handleMoveToNextPage')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                        <Button className="float-end btn-next-page" onClick={(section) => checkCalculatorData(nutrients.Section,'handleWizardChangedBack')}><FaChevronLeft /> Back  </Button>
                        </>
                    }
                    {
                        !loading && <Button onClick={() => handleRiskSummaryOpen()} className="btn float-end btn-risk-summary">Risk and Actions summary</Button>
                    }
                </Col>
                
            </Row>
            {
                loading ? <SpinnerContainer /> :
                    <Row xs="2">
                        <Col>
                            <Card className="my-4">
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            {mapView}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <NitrogenBalanceCalc setCalcValueChanged={setCalcValueChanged} annualRainfallList={annualRainfallList} nLossRiskAssessment={nLossRiskAssessment} riskTypeList={riskTypeList} seasonList={nutrientSeasonList} nutrientCalculatorDetail={nutrients.nutrientCalculatorDetail}></NitrogenBalanceCalc>
                            <Card className="my-4 card-header-outline">
                                <CardHeader className="card-header-click" onClick={toggle}>
                                    <Row>
                                        <Col sm="11">
                                            <h5>Actions
                                                {openActionCount > 0 ?
                                                    <span className="risk-badge">
                                                        | <Badge color="primary">
                                                            {openActionCount}
                                                        </Badge> <span className="card-header-label-xs">Open Action(s)</span>
                                                    </span> :
                                                    <></>}
                                            </h5>
                                        </Col>
                                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                    </Row>
                                </CardHeader>
                                <Collapse isOpen={isOpen}>
                                    <CardBody>
                                        {nutrients.farmCenter != null &&
                                            <Row>
                                                <Col sm="8">
                                                </Col>
                                                <Col sm="4">
                                                    {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') && 
                                                        <>
                                                            <Button className="float-end" onClick={handleOpenActionPopup} color="success" size="sm">
                                                                Add Action
                                                            </Button>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col sm="12">
                                                <table className="table borderless">
                                                    <thead></thead>
                                                    <tbody>
                                                        {closedActionCount > 0 ?
                                                            <tr key={uuid()}>
                                                                <td>

                                                                    <label className="lable-sm-upper float-end">Show Closed Actions ({closedActionCount})</label>
                                                                    <input className="float-end closeCheckbox" type="checkbox" checked={isClosedActionShow} onChange={handleClosedActionShow}></input>

                                                                </td>
                                                            </tr>
                                                            : ''}
                                                        {
                                                            nutrients.actions != undefined && nutrients.actions.length != 0 &&
                                                                <tr className="actions-table" key={uuid()}>
                                                                    <td >
                                                                        <Table className='table borderless '>
                                                                            <thead >
                                                                                <tr>
                                                                                    <th> # </th>
                                                                                    <th style={{ textAlign: 'center' }}> Category </th>
                                                                                    <th style={{ width: '10%' }}> Due </th>
                                                                                    <th style={{ width: '20%' }}> Risk  </th>
                                                                                    <th style={{ width: '20%' }}> Title  </th>
                                                                                    <th style={{ width: '30%' }}> Details </th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    nutrients.actions.map(action =>

                                                                                        <tr key={action.id} className={"" + (action.isCloseout === true ? "closedAction" : "openAction") + " " + (!isClosedActionShow && action.isCloseout === true ? "closed-hidden" : "")}>
                                                                                            <td> {action.id} </td>
                                                                                            <th scope="row" style={{ textAlign: 'center' }}>{action.category == null ? "" : <Badge color="danger">{action.category}</Badge>}  </th>
                                                                                            <td> {action.actionType == 46 ? "Ongoing / Management" : Moment(action.dueDate).format('DD/MM/YYYY')} </td>
                                                                                            <td> {action.riskName} </td>
                                                                                            <td> {action.title} </td>
                                                                                            <td> {action.description} </td>
                                                                                            <td>  {action.isCloseout !== true ?
                                                                                                <Button color="warning" size="sm" onClick={(e) => handleOpenAction(e, action)} > Edit </Button> :
                                                                                                <Button color="primary" size="sm" onClick={(e) => handleOpenAction(e, action)} > View </Button>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>
                            </Card>
                            <Documents refid={farmId} uploadType="Nutrients" open={false} showArrow={true}></Documents>
                            {
                                sectionObj.isFarmAPTActionsReq && <ActionsToConsider sectionId={sectionObj.id} />
                            }

                        </Col>

                    </Row>
            }
            
            
            {showActionPopup && <Action itemList={[]} isGHGNutrient={true} selectedLUId={0} redirectPage="/nutrients" handleActionClose={handleActionClose} itemTypeId={nutrients.itemTypes[0].id} sectionName="Nutrients" sectionId={nutrients.itemTypes[0].sectionId} itemId={nutrients.farmCenter.itemId} itemName={nutrients.farmCenter.name}
                showActionPopupModal={showActionPopup} actionId={id} itemTypeSelect={nutrients.itemTypes} itemTypeDisabled={true}></Action>}

            {riskSummaryPopup.showriskSummary && <RiskSummary section={nutrients.SectionObj} sectionId={nutrients.itemTypes[0].sectionId} riskSummaryPopup={riskSummaryPopup} handleRiskSummaryClose={handleRiskSummaryClose}></RiskSummary>}

            <Modal isOpen={checkCalculatorDataPopup.showPopup}>
                <ModalHeader toggle={handleCheckCalculatorDataPopupClose} >Confirm</ModalHeader>
                <ModalBody>
                    You may have unsaved changes on this page, are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleCheckCalculatorDataPopupConfirm} color="primary">
                        Yes
                    </Button>{' '}
                    <Button color="danger" onClick={handleCheckCalculatorDataPopupClose}>No</Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default Nutrients;