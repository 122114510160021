import React from 'react'
import { Row, Col, Label, Input, Form, FormGroup } from 'reactstrap';
const MapType = ({ itemPopupMaptype })   => {
    return (
        <Col sm="4">
            {
                itemPopupMaptype.markertype == "polygon" || itemPopupMaptype.markertype == "circle" || itemPopupMaptype.markertype == "rectangle" ?
                    (<Row>
                        <Col>
                            <FormGroup>
                                <Label>Size (ha)</Label>
                                <Input type="text" name="area" id="area" value={itemPopupMaptype.area} readOnly disabled />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Perimeter (km)</Label>
                                <Input type="text" name="perimeter" id="perimeter" value={itemPopupMaptype.perimeter} readOnly disabled />
                            </FormGroup>
                        </Col>
                    </Row>)
                    : (itemPopupMaptype.markertype == "polyline" ?
                        (<Row>
                            <Col>
                                <FormGroup>
                                    <Label>Length(km)</Label>
                                    <Input type="text" name="perimeter" id="perimeter" value={itemPopupMaptype.perimeter} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>)
                        :
                        (<Row>
                            <Col>
                                <FormGroup>
                                    <Label>Location</Label>
                                    <Input type="text" name="coordinates" id="coordinates" value={itemPopupMaptype.coordinates} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>))
            }
        </Col>

    )

}
export default MapType