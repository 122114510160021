import { useState} from 'react';
import { ModalFooter, Button, Spinner, Row, Col } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
const ItemFooter = ({ itemPopup, handleItemDeletePopup, handleItemSubmit, handleItemPopupClose, handleOpenActionPopup, handleOpenMaintenancePlanPopup, itemPopupHeader, Section, saving }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddArchiveItem = localStorage.getItem("isAddArchiveItem");
    const isCreateNewAction = localStorage.getItem("isCreateNewAction");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    return (
        <ModalFooter className="justify-content-between">
            {itemPopupHeader != undefined && itemPopupHeader.page == 'Irrigation' && itemPopupHeader.itemTypeName == 'Water Take' &&
                <><div></div><div><Button color="primary" onClick={handleItemPopupClose} size="sm">Cancel</Button> </div></>
            }
            {Section == 'Community & Cultural Significance' &&
                <>
                    <div>
                        {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                            <>
                                {itemPopup.itemId !== 0 && Section != 'Community & Cultural Significance' &&
                                    <Button className="button-save" color="primary" onClick={(itemId) => handleOpenActionPopup(itemPopup.itemId)} size="sm">
                                        Add Action
                                    </Button>}
                                {' '}
                            </>
                        }
                    </div>
                    <div>
                        {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                            <>
                                {itemPopup.itemId !== 0 &&
                                    <><Button color="danger" onClick={(itemId) => handleItemDeletePopup(itemPopup.itemId)} size="sm">Archive</Button></>
                                }{' '}
                            </>
                        }
                    
                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') && 
                            <>
                                {saving ?
                                    <>
                                    <Button className="button-save" onClick={handleItemSubmit} color="success" disabled size="sm">
                                            <Row>
                                                <Col sm="6">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="6">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row></Button>{' '}
                                    </>
                                    :
                                    <>
                                    <Button className="button-save" onClick={handleItemSubmit} color="success" size="sm">
                                            Save
                                        </Button>{' '}
                                    </>
                                }
                            </>
                        }                    
                    <Button color="primary" onClick={handleItemPopupClose} size="sm">Cancel</Button>
                        </div>
                </>
            }
            {Section != 'Community & Cultural Significance' && Section != 'Irrigation' &&
                <>
                    <div>
                        {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                            <>
                                {itemPopup.itemId !== 0 && Section != 'Community & Cultural Significance' &&
                                    <Button className="button-save" color="primary" onClick={(itemId) => handleOpenActionPopup(itemPopup.itemId)} size="sm">
                                        Add Action
                                    </Button>}
                                {' '}
                            </>
                        }
                    </div>
                    <div>
                        {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                            <>
                                {itemPopup.itemId !== 0 &&
                                    <>{itemPopup.canArchive && <Button color="danger" onClick={(itemId) => handleItemDeletePopup(itemPopup.itemId)} size="sm">Archive</Button>}</>
                                }{' '}
                            </>
                        }

                        {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                            <>
                                {saving ?
                                    <>
                                        <Button className="button-save" onClick={handleItemSubmit} color="success" disabled size="sm">
                                            <Row>
                                                <Col sm="6">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="6">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row></Button>{' '}
                                    </>
                                    :
                                    <>
                                        <Button className="button-save" onClick={handleItemSubmit} color="success" size="sm">
                                            Save
                                        </Button>{' '}
                                    </>
                                }
                            </>
                        }
                        <Button color="primary" onClick={handleItemPopupClose} size="sm">Cancel</Button>
                    </div>
                </>
            }
            {itemPopupHeader != undefined && itemPopupHeader.page == 'Irrigation' && itemPopupHeader.itemTypeName != 'Water Take' &&
                <>
                    <div>
                        {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                            <>
                            {itemPopup.itemId !== 0 &&
                                <Button className="button-save" color="primary" onClick={(itemId) => handleOpenActionPopup(itemPopup.itemId)} size="sm">
                                    Add Action
                                </Button>}{' '}
                            </>
                        } 
                    </div>
                    <div>
                    {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                        <>
                        {itemPopup.itemId !== 0 && <Button color="danger" onClick={(itemId) => handleItemDeletePopup(itemPopup.itemId)} size="sm">Archive</Button>}{' '}
                        </>
                    }
                    {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                        <>
                        {saving ?
                            <>
                                <Button className="button-save" onClick={handleItemSubmit} color="success" disabled size="sm">
                                    <Row>
                                        <Col sm="6">
                                            <span>
                                                {' '}Saving
                                            </span>
                                        </Col>
                                        <Col sm="6">
                                            <Spinner size="sm">
                                                Saving...
                                            </Spinner>
                                        </Col>
                                    </Row></Button>{' '}
                            </>
                            :
                            <>
                                <Button className="button-save" onClick={handleItemSubmit} color="success" size="sm">
                                    Save
                                </Button>{' '}
                            </>
                        }
                        </>
                    }
                        <Button color="primary" onClick={handleItemPopupClose} size="sm">Cancel</Button>
                    </div>
                </>
            }
        </ModalFooter>
    )
}
export default ItemFooter