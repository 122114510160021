import React, { useState } from 'react';
import Moment from 'moment';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const CertificationStatus = ({ summary, isWizardCompleted, wizardDetails, handleWizardChanged, certificationAndAuditStatus }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false,section:'' });
    const handleItemDeletePopupClose = () => {
        setItemDeletePopup({ showItemDeletePopup: false });
    }
    const handleItemDeletePopupConfirm =  () => {
        setItemDeletePopup({ showItemDeletePopup: false });
        handleWizardChanged(itemDeletePopup.section);
    }
    const handleItemDeletePopupOpen = (Section) => {
        setItemDeletePopup({ showItemDeletePopup: true, section: Section });
    }
    return (
        <>
            <Card className="my-4">
            <CardHeader className="item-header item_click" onClick={toggle}>
                <Row>
                    <Col sm="11"><h5>Certification & Audit Status
                    </h5></Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                    </Row>
                    <Row>
                        <Col sm="12">
                            {!isWizardCompleted ?
                                <div className="progress-bar bg-warning completeBar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" >
                                    Not yet submitted – in progress (Draft mode)
                                </div>
                                :
                                <>
                                    {isWizardCompleted && !certificationAndAuditStatus.isAudited && !certificationAndAuditStatus.isCertified ?
                                        <>
                                            <div className="progress-bar bg-success completeBar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" >
                                                {wizardDetails.status} <br /> submitted by {wizardDetails.updatedBy} on {Moment(wizardDetails.statusUpdatedDate).format('DD/MM/YYYY')}
                                            </div>
                                            <div className="form-label-submitted-text" style={{ marginTop: '10px' }}>
                                                ZespriFWFP has not yet been certified or audited
                                            </div>
                                        </>
                                        :
                                        <>
                                            {certificationAndAuditStatus.isCertified &&
                                                <div className="progress-bar bg-success completeBar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" >
                                                    {certificationAndAuditStatus.certificationNotes}
                                                </div>
                                            }
                                            {certificationAndAuditStatus.isAudited &&
                                                <>
                                                    <br />
                                                    <div className="progress-bar bg-info completeBar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" >
                                                        {certificationAndAuditStatus.auditNotes}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </>

                            }
                        </Col>

                    </Row>
                        <Row>
                            <Col style={{display:"none"}}>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    {isWizardCompleted != true &&
                                        <Col>
                                            <FaInfoCircle data-tooltip-id="my-tooltip-29" />&nbsp;&nbsp;&nbsp;
                                            <Button color="success" className="btn-sm summary-submit-button" onClick={(section) => handleItemDeletePopupOpen(summary.Section)}>Switch to Operational </Button>
                                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                id="my-tooltip-29"
                                                place="top" className="overrideMe"
                                                variant="info"
                                                render={({ content, activeAnchor }) => (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <p style={{ fontWeight: "lighter" }}>
                                                                    Click ONLY after your plan has been certified to operationalise your plan.<br />
                                                                    Parts 1-4 will reverse in order (Summary will show first).
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            />
                                        </Col>
                                    }
                                </>
                            }
                        </Col>

                    </Row>
                </CardBody>
            </Collapse>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1000px', width: '100%' }} isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Confirm</ModalHeader>
                <ModalBody>
                    This will switch the Freshwater Farm Plan from DRAFT mode to OPERATIONAL. This change will adjust the menu layout from a step-by-step wizard to a more logical layout aligned with managing the Freshwater Farm Plan instead of building it. It also applies business rules around actions where they can no longer be deleted and need to be validated by closing instead. All other aspects the Freshwater Farm Plan remain the same and users can still add new infrastrure, add actions, close actions, and other areas system can continue to be edited.
                    Are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleItemDeletePopupConfirm} color="primary">
                        Yes
                    </Button>{' '}
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default CertificationStatus