import {
    Row, Col, Label, Input, FormGroup, Spinner, Collapse,
    ListGroup, ListGroupItem
} from 'reactstrap';
import './Lmu.css';

const BiodiversityForm = ({ form, handleFormFieldChange, hasBiodiversity }) => {
    return (
        <Row className="my-3">
            <Col sm="1">
                 <Input
                        defaultChecked={form.isAddsToBiodiversity}
                        id="isAddsToBiodiversity"
                        name="isAddsToBiodiversity"
                        type="checkbox"
                        value={form.isAddsToBiodiversity}
                        onChange={handleFormFieldChange}
                    />
                
            </Col>
            <Col sm="5">
                <label>
                    Select if this item represents or adds to the Biodiversity of the property. Note: carries this item through to Biodiversity Page.
                </label>
            </Col>
            <Col sm="6">
                {
                    hasBiodiversity &&
                        <FormGroup>
                            <Label for="biodiversityComment">
                                Biodiversity Comment
                            </Label>
                            <Input
                                bsSize="sm"
                                id="biodiversityComment"
                                name="biodiversityComment"
                                type="textarea"
                                value={form.biodiversityComment}
                                onChange={handleFormFieldChange}
                            />
                        </FormGroup>
                }
            </Col>
        </Row>
    )
}

export default BiodiversityForm