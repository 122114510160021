import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label } from 'reactstrap';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import './RiskSummary.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FaChevronUp, FaChevronDown} from 'react-icons/fa';
import uuid from 'react-uuid';
import ReadMoreContentCustom from '../Utilities/Ui/ReadMoreCustom';
import Draggable, { DraggableCore } from 'react-draggable';
import ActionFromSummary from '../Action/ActionFromSummary';

const RiskSummary = ({ width, height, riskSummaryPopup, handleRiskSummaryClose, section }) => {
    const [wholeFarmActionPopup, setWholeFarmActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, riskId :0,luId:0});
    const [outlineCenter, setOutlineCenter] = useState({}); const [outlineItemTypes, setOutlineItemTypes] = useState([]);
    const handleOpenWholeActionPopup = (riskId, luId) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: outlineCenter.itemId, itemTypeId: outlineCenter.itemTypeId, itemName: 'Orchard Centre', actionId: 0, riskId: riskId, luId: luId });
    };
    const handleWholeActionClose = () => {
        setWholeFarmActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, riskId: 0, luId: 0 });
    }
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [itemList, setItemList] = useState([]);
    const [loading, setLoading] = useState(true); const [showByLU, setShowByLU] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [riskList, setRiskList] = useState([]); const [luRiskList, setLURiskList] = useState([]); const [luList, setLUList] = useState([]);
    const [isOpen, setIsOpen] = useState(false); const [activeCategoryList, setActiveCategoryList] = useState([]);
    const [isIconUp, setIsIconUp] = useState(true);
    const [riskSummary, setRiskSummary] = useState([]);
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/Risk/GetRiskWithActions/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setRiskList(response.data.riskList)
            setLURiskList(response.data.luRiskList);
            setRiskSummary(response.data.riskSummary);
            setLUList(response.data.luList);
            setActiveCategoryList(response.data.activeCategoryList);
            setOutlineCenter(response.data.outlineCenter);
            setOutlineItemTypes(response.data.outlineItemTypes);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const [descriptionPopup, setDescriptionPopup] = useState({
        showPopup: false, description: ''
    });
    const handleDescriptionPopupOpen = (description, e) => {
        e.preventDefault();
        setDescriptionPopup({ ...descriptionPopup, showPopup: true, description: description });
    }
    const handleDescriptionPopupClose = (e) => {
        setDescriptionPopup({ ...descriptionPopup, showPopup: false });
    }

    const ShowLandUnit = (item,show) => {
        const tempRows = [...riskList];
        var idx = tempRows.findIndex(obj => item.id === obj.id);
        tempRows[idx].showLandUnit = show;
        setRiskList(tempRows);
    }
    const ShowRisk = (item, show) => {
        const tempRows = [...luList];
        var idx = tempRows.findIndex(obj => item.itemId === obj.itemId);
        tempRows[idx].showRisk = show;
        setLUList(tempRows);
    }

    const showActionMessage = (riskAction) => {
        if (riskAction == 'Create Action') {
            if (showByLU)
                toast.success("To raise an action click the button Show Risks then ADD, or close the Risk Summary and raise an action from either the Mapping Page or Mapped item", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            else
                toast.success("To raise an action click the button Show LU's then ADD, or close the Risk Summary and raise an action from either the Mapping Page or Mapped item", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        } else {
            if (showByLU)
                toast.success("Click the button Show Risks to see detail", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            else
                toast.success("Click the button Show LU's to see detail", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
        }
    }

    const pageReload = async (e) => {
        setLoading(true);
        await OnPageload();
    }

    return (
        <>
            <Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>
                <Modal size="lg" style={{ maxWidth: '1250px', width: '100%' }} isOpen={riskSummaryPopup.showriskSummary}>
                <ModalHeader toggle={handleRiskSummaryClose}>Risk Management</ModalHeader>
                <ModalBody>
                    {loading ? <SpinnerContainer /> :
                        <>
                            <Row>
                                <Col sm="10">
                                    <label style={{ display: "contents" }} className="para-light-sm">Use this table to identify where any gaps may exist when comparing identified Risks to where Actions and Mitigations exist.</label>
                                    <br />
                                    <label style={{ display: "contents" }} className="para-light-sm">Each risks shows a count of Land Units the risk applies to, then a count of Actions and Mitigation applied to the Risk and Land Unit.</label>
                                </Col>
                                <Col sm="2">
                                    <Input type="checkbox" checked={showByLU} onChange={(e) => setShowByLU(!showByLU)} name="showByLU" id="showByLU" />&nbsp;&nbsp;&nbsp;
                                    <Label>Show by Land Unit</Label>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                        <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                            {!showByLU &&
                                                <tbody style={{ textAlign: 'center' }}>
                                                    {activeCategoryList.length > 0 &&
                                                        activeCategoryList.map((item, pidx) =>
                                                            <>
                                                                {
                                                                    <>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#008436', color: 'white', fontSize: "20px" }}> {item} </td>
                                                                        </tr>
                                                                        <tr key={uuid()}>
                                                                            <td>
                                                                                <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                    <tbody style={{ textAlign: 'center' }}>
                                                                                        {riskList.filter(x => x.category == item).length > 0 &&
                                                                                            riskList.filter(x => x.category == item).map((risk, pidx) =>
                                                                                                <>
                                                                                                    {
                                                                                                        <>
                                                                                                            <tr key={uuid()}>
                                                                                                                <td style={{ width: "40%", wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: 'white', color: 'black', fontSize: "18px" }}> {risk.name} </td>
                                                                                                                <td style={{ width: "5%" }}></td>
                                                                                                                <td style={{ width: "30%" }}>{risk.riskActionDescription}</td>
                                                                                                                <td style={{ width: "10%" }}>
                                                                                                                    {!risk.showLandUnit && <Button style={{ marginRight: "0px", background: "lightblue", color: "black" }} size="sm" className="btn-next-page" onClick={(e) => ShowLandUnit(risk, true)}>Show LU's<br /> <FaChevronDown /> </Button>}
                                                                                                                    {risk.showLandUnit && <Button style={{ marginRight: "0px", background: "orange", color: "black" }} size="sm" className="btn-next-page" onClick={(e) => ShowLandUnit(risk, false)}>Hide LU's<br /> <FaChevronUp /> </Button>}
                                                                                                                </td>
                                                                                                                <td onClick={(e) => showActionMessage(risk.riskAction)} style={{ width: "10%" }}><img onClick={(e) => showActionMessage(risk.riskAction)} src={risk.riskActionImage} className="card_icon" ></img><br />{risk.riskAction}</td>
                                                                                                            </tr>
                                                                                                            {risk.showLandUnit &&
                                                                                                                <tr key={uuid()}>
                                                                                                                    <td colSpan="2" ></td>
                                                                                                                    <td colSpan="2" >
                                                                                                                        <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                                                            <thead style={{ background: '#f9d769', textAlign: 'center' }}>
                                                                                                                                <tr>
                                                                                                                                    <th colSpan="1" style={{ width: '40%' }}> Land Units </th>
                                                                                                                                    <th colSpan="1" style={{ width: '20%' }}> Risk rating </th>
                                                                                                                                    <th colSpan="1" style={{ width: '20%' }}> Actions </th>
                                                                                                                                    <th colSpan="1" style={{ width: '20%' }}> Action </th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                                                                {
                                                                                                                                    luRiskList.filter(x => x.riskId == risk.id)
                                                                                                                                        .sort((a, b) => a.id > b.id ? 1 : -1)
                                                                                                                                        .map((luItem, idx) =>
                                                                                                                                            <>
                                                                                                                                                <tr key={luItem.id}>
                                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}> {luItem.name} </td>
                                                                                                                                                    {luItem.lowRisk &&
                                                                                                                                                        <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "center", background: '#1fcc65', color: "white" }}> Low </td>
                                                                                                                                                    }
                                                                                                                                                    {luItem.mediumRisk &&
                                                                                                                                                        <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "center", background: '#ffc107', color: "white" }}> Medium </td>
                                                                                                                                                    }
                                                                                                                                                    {luItem.highRisk &&
                                                                                                                                                        <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "center", background: '#dc3545', color: "white" }}> High </td>
                                                                                                                                                    }
                                                                                                                                                    {!luItem.highRisk && !luItem.mediumRisk && !luItem.lowRisk &&
                                                                                                                                                        <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}></td>
                                                                                                                                                    }
                                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "center" }}>{luItem.totalAction == 0 ? '' : luItem.totalAction}</td>
                                                                                                                                                    <td><Button style={{ marginRight: "0px", background: "#1fcc65", color: "white" }} size="sm" onClick={(e) => handleOpenWholeActionPopup(risk.id, luItem.itemId)} className="btn-next-page" >Add</Button></td>
                                                                                                                                                </tr>
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                            </tbody>
                                                                                                                        </Table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                </tbody>
                                            }
                                            {
                                                showByLU &&
                                                <tbody style={{ textAlign: 'center' }}>
                                                        <tr key={uuid()}>
                                                            <td colSpan="4" style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#008436', color: 'white', fontSize: "20px" }}> Land Units </td>
                                                        </tr>
                                                        {luList.length > 0 && luList.map((item, pidx) =>
                                                            <>
                                                                {
                                                                    <>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ width: "40%", wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: 'white', color: 'black', fontSize: "18px" }}> {item.name} </td>
                                                                            <td style={{ width: "30%" }}>{item.riskActionDescription}</td>
                                                                            <td style={{ width: "10%" }}>
                                                                                {!item.showRisk && <Button style={{ marginRight: "0px", background: "lightblue", color: "black" }} size="sm" className="btn-next-page" onClick={(e) => ShowRisk(item, true)}>Show Risks<br /> <FaChevronDown /> </Button>}
                                                                                {item.showRisk && <Button style={{ marginRight: "0px", background: "orange", color: "black" }} size="sm" className="btn-next-page" onClick={(e) => ShowRisk(item, false)}>Hide Risks<br /> <FaChevronUp /> </Button>}
                                                                            </td>
                                                                            <td onClick={(e) => showActionMessage(item.riskAction)} style={{ width: "10%" }}><img onClick={(e) => showActionMessage(item.riskAction)} src={item.riskActionImage} className="card_icon" ></img><br />{item.riskAction}</td>
                                                                        </tr>
                                                                        {item.showRisk &&
                                                                            <tr key={uuid()}>
                                                                            <td colSpan="2" ></td>
                                                                            <td colSpan="2" >
                                                                                    {item.activeCategoryList.length > 0 && item.activeCategoryList.map((category, pidx) => 
                                                                                            <>
                                                                                                {
                                                                                                    <>
                                                                                                        <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                                            <thead style={{ background: '#f9d769', textAlign: 'center' }}>
                                                                                                                <tr>
                                                                                                                    <th colSpan="1" style={{ width: '40%' }}> {category} </th>
                                                                                                                    <th colSpan="1" style={{ width: '20%' }}> #Actions </th>
                                                                                                                    <th colSpan="1" style={{ width: '20%' }}> Action </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                                            {
                                                                                                                luRiskList.filter(x => x.itemId == item.itemId && x.category == category)
                                                                                                                        .sort((a, b) => a.id > b.id ? 1 : -1)
                                                                                                                        .map((luItem, idx) =>
                                                                                                                            <>
                                                                                                                                <tr key={luItem.id}>
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}> {luItem.riskName} </td>
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "center" }}>{luItem.totalAction == 0 ? '' : luItem.totalAction}</td>
                                                                                                                                    <td><Button style={{ marginRight: "0px", background: "#1fcc65", color: "white" }} size="sm" onClick={(e) => handleOpenWholeActionPopup(luItem.riskId, item.itemId)} className="btn-next-page" >Add</Button></td>
                                                                                                                                </tr>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                            </tbody>
                                                                                                        </Table>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                            </td>
                                                                        </tr>
                                                                            }
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                </tbody>
                                            }
                                    </Table>    
                                </Col>
                            </Row>
                        </>
                        
                    }
                </ModalBody>
                <ModalFooter>
                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleRiskSummaryClose} color="primary" >Close</Button>
                </ModalFooter>
            </Modal>
            </Draggable>
            <Modal contentClassName="" size="lg" style={{ maxWidth: '1250px', width: '100%' }} isOpen={descriptionPopup.showPopup}>
                <ModalHeader toggle={handleDescriptionPopupClose}>Risk Description</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <p>{descriptionPopup.description}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleDescriptionPopupClose} color="primary" >Cancel</Button>
                </ModalFooter>
            </Modal>
            {wholeFarmActionPopup.showActionPopup && <ActionFromSummary refreshRiskSummary={pageReload} pageDisabled={true} selectedRiskId={wholeFarmActionPopup.riskId} width={width} height={height} itemList={[]} redirectPage="/risksummary" selectedLUId={wholeFarmActionPopup.luId}
                handleActionClose={handleWholeActionClose} itemTypeId={wholeFarmActionPopup.itemTypeId} sectionName={section.name} sectionId={section.id}
                itemId={wholeFarmActionPopup.itemId} itemName={wholeFarmActionPopup.itemName}
                showActionPopupModal={wholeFarmActionPopup.showActionPopup} actionId={wholeFarmActionPopup.actionId} itemTypeSelect={outlineItemTypes} itemTypeDisabled={true} wholeFarmAction={true}></ActionFromSummary>}
        </>
    )
}
export default RiskSummary