import { withScriptjs } from "react-google-maps";
import Map from '../Map';
const MapLoader = withScriptjs(Map);

const MapView = ({ height, width, itemModel, mapDetails, addMapItem, editMapItem, kmlUpload, luUpload,shapeUpload,
    largeEnterpriseDetails, blockViewOnly, editLMUCenter, gisLayerDetails, handleMapItemEdited, gisLayerTopographyDetails, gisLayerSoilDetails, gisLayerWetlandDetails, gisLayerStockExclusionLandSlopeDetails }) => {
    const outlineList = itemModel.outlineList;
    const itemList = itemModel.itemList
    const data = { outlineList, itemList, mapDetails, kmlUpload, largeEnterpriseDetails, blockViewOnly, gisLayerDetails, luUpload, shapeUpload, gisLayerTopographyDetails, gisLayerSoilDetails, gisLayerWetlandDetails, gisLayerStockExclusionLandSlopeDetails };

    return (
        <MapLoader width={width} height={height}
            data={data}
            addMapItem={(mapItem, maptype) => addMapItem(mapItem, maptype)}
            editMapItem={(mapItem, itemId, isDragged) => editMapItem(mapItem, itemId, isDragged)}
            editLMUCenter={(mapItem, itemId, isDragged) => editLMUCenter(mapItem, itemId, isDragged)}
            handleMapItemEdited={(itemId, type, value) => handleMapItemEdited(itemId, type, value) }
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYN1ILqaOOYkmN8-rk3T0ImqfQGY07Nfw&libraries=drawing,geometry"
            loadingElement={<div style={{ height: `100%` }} />}
        />
    )
}
export default MapView