import { useState, useEffect } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, Button,Collapse, Table } from 'reactstrap';
import { FaTrash, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const getBase64 = (file) => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

const Photos = ({ refId, uploadType, header, disabledMode, showPhotosInfo, infoPhotosMessage, infoPhotosId }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddEditItem = localStorage.getItem("isAddEditItem");
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [imageUploadList, setImageUploadList] = useState([]);
    const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(0);
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    let inputFile = '';

    const OnPageload = async (e) => {
        refId = refId == undefined ? 0 : refId;
        if (refId != 0) {
            try {
                const response = await axios.get('/api/upload/GetUploadedImages/', { params: { Id: refId, UploadType: uploadType } });
                if (response.data.status) {
                    setImageUploadList(response.data.content);
                }
            } catch (err) {
                console.log(err);
            }
        }
        setLoading(false);
    }

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.type != 'image/png' && file.type != 'image/jpeg' && file.type != 'image/jpg') {
            toast.error("Image uploads must be in .jpg format and less than 5mb in size. Please adjust the file you are trying to upload and try again.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
        else if ((file.size / (1024 * 1024)) > 5) {
            toast.error("Image uploads must be in .jpg format and less than 5mb in size. Please adjust the file you are trying to upload and try again.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: uploadType,
                    Base64Image: result,
                    RefId: refId,
                    userId: userId
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                toast.success("Images Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                                setTimeout(() => {
                                    setImageUploadList(res.data.content);
                                }, 2000);
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            e.target.value = null;
        }

        reader.readAsDataURL(file)
    }

    const handleBtnUploadClick = async (e) => {
        e.preventDefault();
        inputFile.click();
        return false;
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const confirmDeleteItemPopup = async () => {

        const response = await axios.get(
            '/api/upload/DeleteUploadImage/', {
            params: { Id: deleteItemId, userId: userId }
        }
        );
        if (response.data.status) {
            setShowDeleteItemPopup(false);
            setImageUploadList(response.data.content);
        }
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="card-header-click" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>
                                    {showPhotosInfo && <> <FaInfoCircle data-tooltip-id={infoPhotosId} />&nbsp; &nbsp; &nbsp; </>}
                                    {header}
                                </h6>
                                    {showPhotosInfo && <>
                                        <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                            id={infoPhotosId}
                                            place="top" className="overrideMe"
                                            variant="info"
                                            render={({ content, activeAnchor }) => (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <p style={{ fontWeight: "lighter" }}>
                                                                {infoPhotosMessage}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        />
                                    </>}
                                </Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={isOpen}>
                            <CardBody>
                                {refId != 0 ? <div>
                                    <Row>
                                        <Col>
                                            {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                <>
                                                    {!disabledMode && <>
                                                        <Button style={{ float: `right` }} color="success" size="sm" onClick={handleBtnUploadClick} >Upload Image</Button>
                                                        <input style={{ float: `right`, display: 'none' }}
                                                            ref={input => {
                                                                inputFile = input;
                                                            }}
                                                            className="fileInput" id="fileUpload" type="file" onChange={(e) => handleImageChange(e)} /></>
                                                    }
                                                </>
                                            }
                                            
                                        </Col>
                                    </Row>
                                     <Row style={{ marginTop: `15px`, marginBottom: `15px` }}>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Name</th>
                                                                    <th>View</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            {imageUploadList != undefined && imageUploadList != null && imageUploadList.length > 0 &&
                                                                <tbody>
                                                                    {imageUploadList.map(item => (
                                                                        <tr>
                                                                            <td>{item.createdOnText}</td>
                                                                            <td>{item.imageName}</td>
                                                                            <td>
                                                                                <img src={item.imageRelativePath} className="image-popup-thumbnail" alt={item.imageName}
                                                                                    onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, item.imageName, item.imageRelativePath)}></img>
                                                                            </td>
                                                                            <td>
                                                                                {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                                                    <>
                                                                                        {!disabledMode && <button type="button" id="btnUpdate" className="btn btn-sm btn-danger" onClick={() => openDeleteItemPopup(item)}><FaTrash /></button>}
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            }
                                                        </Table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                </div>
                                    : <span>Please save to add Photo</span>}
                            </CardBody>
                        </Collapse>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Photos