import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Table, Button, FormGroup, Label, Input, Form, Spinner } from 'reactstrap';
import axios from 'axios';
import uuid from 'react-uuid';
import Select from 'react-select';
import "../ExternalOrganisation/ExternalOrganisation.css";
import toast, { Toaster } from 'react-hot-toast';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from "react-router-dom";
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';

const ChangePassword = () => {

    const { state } = useLocation(); const navigate = useNavigate();
    const [loading, setLoading] = useState(true); const [id, setId] = useState('');
    const [password, setPassword] = useState(''); const [newPassword, setNewPassword] = useState(''); const [newPasswordconfirm, setNewPasswordConfirm] = useState('');
    const [originalPassword, setOriginalPassword] = useState(''); const [formDocumentErrors, setFormDocumentErrors] = useState({ passwordError: '', newPasswordError: '' });
    const [saving, setSaving] = useState(''); const [passwordSuccess, setPasswordSuccess] = useState(true);
    const [newPasswordSuccess, setNewPasswordSuccess] = useState(false); const [newConfirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);
    //const [passwordType, setPasswordType] = useState("password");
    //const [passwordInput, setPasswordInput] = useState("");
    //const handlePasswordChange = (evnt) => {
    //    setPasswordInput(evnt.target.value);
    //}
    //const togglePassword = () => {
    //    if (passwordType === "password") {
    //        setPasswordType("text")
    //        return;
    //    }
    //    setPasswordType("password")
    //}

    //const handleChangePassword = async (e) => {
    //    setPassword(e.target.value);
    //    if (e.target.value != originalPassword) {
    //        setFormDocumentErrors({ ...formDocumentErrors, passwordError: '' });
    //        const errors = formDocumentErrors;
    //        errors.passwordError = "Password is incorrect!";
    //        setFormDocumentErrors(errors);
    //        setPasswordSuccess(false);
    //    }
    //    else {
    //        setPasswordSuccess(true);
    //        setFormDocumentErrors({ ...formDocumentErrors, passwordError: '' });
    //    }
    //}

    const handleChangeNewPassword = async (e) => {
        setNewPassword(e.target.value);
        var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (!e.target.value.match(decimal)) {
            setFormDocumentErrors({ ...formDocumentErrors, newPasswordError: '' });
            const errors = formDocumentErrors;
            errors.newPasswordError = "New Password should contain atleast 8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character!";
            setFormDocumentErrors(errors);
            setNewPasswordSuccess(false);
        }
        else {
            setNewPasswordSuccess(true);
            setFormDocumentErrors({ ...formDocumentErrors, newPasswordError: '' });
        }
    }

    const handleChangeConfirmPassword = async (e) => {
        setNewPasswordConfirm(e.target.value);
        if (e.target.value != newPassword) {
            setFormDocumentErrors({ ...formDocumentErrors, confirmPasswordError: '' });
            const errors = formDocumentErrors;
            errors.confirmPasswordError = "Password is not matching!";
            setFormDocumentErrors(errors);
            setConfirmPasswordSuccess(false);
        }
        else {
            setConfirmPasswordSuccess(true);
            setFormDocumentErrors({ ...formDocumentErrors, confirmPasswordError: '' });
        }
    }

    useEffect(() => {
        if (state != null) {
            const { id } = state;
            setId(id);
            populateItemData(id);
        }
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSaving(true);
        let form = {
            userId: id,
            newPassword: newPassword
        }
        const response = await axios.post(
            '/api/settings/UpdatePassword/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("New password is updated, redirecting to login page!", {
                    duration: 4000, position: 'top-center', icon: '👏', iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },});
                setSaving(false);
                setTimeout(() => {
                    window.open("/login", "_self");
                }, 4000);
            }
        }
    }

    const populateItemData = async (id) => {
        try {
            const response = await axios.get(
                '/api/settings/getusers/', {
                params: {}
            }
            );
            var user = response.data.content.users.filter(x => x.id == id)[0];
            setOriginalPassword(user.password);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            {loading ? <SpinnerContainer /> :
                <div>
                    <Card className="my-4">
                        <h2 className="card-header d-flex justify-content-between align-items-center">
                            Change Password
                        </h2>
                        <CardBody>
                            <div >
                                <Form onSubmit={handleSubmit}>
                                    {/*<Row sm="2">*/}
                                    {/*    <Col>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <Label className="label" for="name">Current Password</Label>*/}
                                    {/*            <Input className="validate" type="password" value={password} onChange={(e) => handleChangePassword(e)} name="password" id="password" autoComplete="new-password" />*/}
                                    {/*        </FormGroup>*/}
                                    {/*        {formDocumentErrors.passwordError != undefined &&*/}
                                    {/*            <p className="errorMessage">{formDocumentErrors.passwordError}</p>*/}
                                    {/*        }*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row sm="2">
                                        <Col>
                                            <FormGroup>
                                                <Label className="label" for="name">New Password</Label>
                                                <Input type="password" value={newPassword} onChange={(e) => handleChangeNewPassword(e)} name="newPassword" id="newPassword" autoComplete="new-password" />
                                            </FormGroup>
                                            {formDocumentErrors.newPasswordError != undefined &&
                                                <p className="errorMessage">{formDocumentErrors.newPasswordError}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row sm="2">
                                        <Col>
                                            <FormGroup>
                                                <Label className="label" for="name">Confirm New Password</Label>
                                                <Input type="password" value={newPasswordconfirm} onChange={(e) => handleChangeConfirmPassword(e)} name="newPasswordconfirm" id="newPasswordconfirm" autoComplete="new-password" />
                                            </FormGroup>
                                            {formDocumentErrors.confirmPasswordError != undefined &&
                                                <p className="errorMessage">{formDocumentErrors.confirmPasswordError}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row sm="2">
                                        <Col>
                                            {
                                                passwordSuccess && newPasswordSuccess && newConfirmPasswordSuccess &&
                                                <>
                                                    {saving ?
                                                        <Button style={{ float: "right" }} onClick={handleSubmit}
                                                            color="success"
                                                            disabled
                                                        >
                                                            <Spinner size="sm">
                                                                Updating...
                                                            </Spinner>
                                                            <span>
                                                                {' '}Updating
                                                            </span>
                                                        </Button>
                                                        :
                                                        <Button style={{ float: "right" }} onClick={handleSubmit} color="success" size="sm">
                                                            Update Password
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            }
        </div>
    );
}

export default ChangePassword;