import React, { } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container, Row, NavLink, Col, Nav, Navbar, Collapse, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Steps } from 'antd';
import { FaChevronUp, FaCheckCircle } from 'react-icons/fa';
import './WizardMenu.css';

function WizardMenu({ wizardDetails, pageReload }) {
    const location = useLocation();
    var pathname = location.pathname.replace('/', '');
    if (pathname == "")
    {
        window.open('Home', "_self");
    }
    const [selectedTabIndex, setSelectedTabIndex] = useState((pathname == 'catchmentcontext' || pathname == 'enterprises' || pathname == 'mahingakai' || pathname == 'outline') ? 0 : (( pathname == 'lmu' || pathname == 'risk') ? 1 :
        ((pathname == 'infrastructure' || pathname == 'waterways' || pathname == 'landsoil' || pathname == 'nutrients' || pathname == 'freshwaterirrigation' || pathname == 'agrichemicals') ? 2 : 3)));
    const [selectedCompletedTabIndex, setSelectedCompletedTabIndex] = useState((pathname == 'catchmentcontext' || pathname == 'enterprises' || pathname == 'mahingakai' || pathname == 'outline') ? 3 : (( pathname == 'lmu' || pathname == 'risk') ? 2 :
        ((pathname == 'infrastructure' || pathname == 'waterways' || pathname == 'landsoil' || pathname == 'nutrients' || pathname == 'freshwaterirrigation' || pathname == 'agrichemicals') ? 1 : 0)));
    const [pageRefresh] = useState(wizardDetails.pageReload);

    const [selectedMenu, setSelectedMenu] = useState(location.pathname.replace('/', ''));

    const { Step } = Steps;
    
    const onTabChange = async (firstTab) => {
        if (firstTab == 0) {
            window.open('outline', "_self");
        }
        else if (firstTab == 1) {
            window.open('lmu', "_self");
        }
        else if (firstTab == 2) {
            window.open('infrastructure', "_self");
        }
        else if (firstTab == 3) {
            window.open('summary', "_self");
        }
    }
    const onTabChangeCompleted = async (CompletedfirstTab) => {
        if (CompletedfirstTab == 0) {
            window.open('summary', "_self");
        }
        else if (CompletedfirstTab == 1) {
            window.open('infrastructure', "_self");
        }
        else if (CompletedfirstTab == 2) {
            window.open('lmu', "_self");
        }
        else if (CompletedfirstTab == 3) {
            window.open('outline', "_self");
        } 
    }
    const onStepChange = async (menu) => {
        window.open(menu, "_self");
    }
    
    const renderWizard = (Model) => {
        const wizardDetails = Model.wizardDetails;
        const IsWizardCompleted = wizardDetails.isCompleted == true ? true : false;

        //Parent Menu  Enable  & Disable if  IsWizardCompleted = false

        //Step 1
        const part1CompleteddivClass = wizardDetails.isMahingaKaiSaved == true ? "completed-check-show" : "completed-check-not-show";
        const part1CompletedClass = wizardDetails.isMahingaKaiSaved == true ? "react-tabs__tab stepCompleted" : "react-tabs__tab";
        const part2Disabled = wizardDetails.isMahingaKaiSaved == true ? false : true;

        //Step 2   
        const part2CompleteddivClass = wizardDetails.isRiskSummarySaved == true ? "completed-check-show" : "completed-check-not-show";
        const part2CompletedClass = wizardDetails.isRiskSummarySaved == true ? "react-tabs__tab stepCompleted" : "react-tabs__tab";
        const part3Disabled = wizardDetails.isRiskSummarySaved == true ? false : true;

        //Step 3
        const part3CompletedClass = wizardDetails.isNutrientsSaved == true ? "react-tabs__tab stepCompleted" : "react-tabs__tab";
        const part3CompleteddivClass = wizardDetails.isNutrientsSaved == true ? "completed-check-show" : "completed-check-not-show";
        const part4Disabled = wizardDetails.isNutrientsSaved == true ? false : true;

        //Step 4
        const part4CompleteddivClass = wizardDetails.isCompleted == true ? "completed-check-show" : "completed-check-not-show";
        const part4CompletedClass = wizardDetails.isCompleted == true ? "react-tabs__tab stepCompleted" : "react-tabs__tab";

        let current = 0;
        let finishedcurrent = 0;
        if (IsWizardCompleted) {

            const selectedCompletedWizard = wizardDetails.wizardItemList
                .filter(item => item.showInCompletedWizard && item.controllerName == pathname);

            wizardDetails.wizardItemList
                .filter(item => item.showInCompletedWizard && item.parentSection == selectedCompletedWizard[0].parentSection)
                .sort((a, b) => a.wizardCompleteSortOrder > b.wizardCompleteSortOrder ? 1 : -1)
                .map(function (menuItem, index) {
                    if (menuItem.controllerName == pathname) {
                        finishedcurrent = index;
                    }
                })
        }
        else {
            const selectedWizard = wizardDetails.wizardItemList
                .filter(item => item.showInWizard && item.controllerName == pathname)

            wizardDetails.wizardItemList
                .filter(item => item.showInWizard && item.parentSection == selectedWizard[0].parentSection)
                .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                .map(function (menuItem, index) {
                    if (menuItem.controllerName == pathname) {
                        current = index;
                    }
                })
        }

        
        if (IsWizardCompleted != true) {
            return (
                <Container>
                    <Tabs className="Tabs" selectedTabClassName="bg-white" selectedIndex={selectedTabIndex} onSelect={(firstTab) => onTabChange(firstTab)} >
                        <TabList>
                            <Tab className={part1CompletedClass}><label className="step-Name" style={{ color: "black" }}> PART 1 - </label> <label style={{ color: "black" }}  className="step-Name"> Property and Catchment context </label> <FaCheckCircle className={part1CompleteddivClass} size={24} /></Tab>
                            <Tab className={part2CompletedClass} disabled={part2Disabled}><label className="step-Name" style={{ color: "black" }}> PART 2 - </label> <label style={{ color: "black" }} className="step-Name"> Land units and Risks </label><FaCheckCircle className={part2CompleteddivClass} size={24} /></Tab>
                            <Tab className={part3CompletedClass} disabled={part3Disabled}><label className="step-Name" style={{ color: "black" }}> PART 3 - </label> <label style={{ color: "black" }}  className="step-Name"> Orcharding activities and Actions to mitigate risk</label><FaCheckCircle className={part3CompleteddivClass} size={24} /></Tab>
                            <Tab className={part4CompletedClass} disabled={part4Disabled}><label className="step-Name" style={{ color: "black" }}> PART 4 - </label> <label style={{ color: "black" }} className="step-Name"> Summary </label><FaCheckCircle className={part4CompleteddivClass} size={24} /></Tab>
                        </TabList>
                        <TabPanel>
                            <Steps current={current} labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInWizard && item.parentSection == 'PART 1')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        let itemStyle = wizardDetails.wizardStyleList.filter(style => style.wizardId == menuItem.id)[0];
                                        if (itemStyle.stringDisabled == "Completed") {
                                            return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu, index) => onStepChange(menuItem.controllerName, index)} />
                                            );
                                        }
                                        else if (itemStyle.stringDisabled == "wizard-disable") {
                                            return (
                                                <Step key={menuItem.name} status="wait" title={menuItem.name} />
                                            );
                                        }
                                        else {
                                            return (
                                                <Step key={menuItem.name} status="process" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel >
                            <Steps current={current} labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInWizard && item.parentSection == 'PART 2')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        let itemStyle = wizardDetails.wizardStyleList.filter(style => style.wizardId == menuItem.id)[0];
                                        if (itemStyle.stringDisabled == "Completed") {
                                            return (
                                                    <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                        else if (itemStyle.stringDisabled == "wizard-disable") {
                                            return (
                                                <Step key={menuItem.name} status="wait" title={menuItem.name} />
                                            );
                                        }
                                        else {
                                            return (
                                                    <Step key={menuItem.name} status="process" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel>
                            <Steps current={current} labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInWizard && item.parentSection == 'PART 3')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        let itemStyle = wizardDetails.wizardStyleList.filter(style => style.wizardId == menuItem.id)[0];
                                        if (itemStyle.stringDisabled == "Completed") {
                                            return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                        else if (itemStyle.stringDisabled == "wizard-disable") {
                                            return (
                                                <Step key={menuItem.name} status="wait" title={menuItem.name} />
                                            );
                                        }
                                        else {
                                            return (
                                                <Step key={menuItem.name} status="process" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel>
                            <Steps current={current} labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInWizard && item.parentSection == 'PART 4')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        let itemStyle = wizardDetails.wizardStyleList.filter(style => style.wizardId == menuItem.id)[0];
                                        if (itemStyle.stringDisabled == "Completed") {
                                            return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                        else if (itemStyle.stringDisabled == "wizard-disable") {
                                            return (
                                                <Step key={menuItem.name} status="wait" title={menuItem.name} />
                                            );
                                        }
                                        else {
                                            return (
                                                <Step key={menuItem.name} status="process" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                            );
                                        }
                                    })}
                            </Steps>
                        </TabPanel>
                    </Tabs>
                </Container>
            );
        } else {
            return (
                <Container>

                    <Tabs className="Tabs" selectedTabClassName="bg-white" selectedIndex={selectedCompletedTabIndex} onSelect={(CompletedfirstTab) => onTabChangeCompleted(CompletedfirstTab)} >
                        <TabList>
                            <Tab className="react-tabs__tab stepCompleted"> <label className="step-Name"> Summary </label></Tab>
                            <Tab className="react-tabs__tab stepCompleted"> <label className="step-Name"> Orcharding activities and actions to mitigate risk </label></Tab>
                            <Tab className="react-tabs__tab stepCompleted"><label className="step-Name"> Land units and risks </label></Tab>
                            <Tab className="react-tabs__tab stepCompleted"><label className="step-Name"> Property and catchment context </label></Tab>
                        </TabList>
                        <TabPanel>
                            <Steps current={finishedcurrent} progressDot labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInCompletedWizard && item.parentSection == 'PART 4')
                                    .sort((a, b) => a.wizardCompleteSortOrder > b.wizardCompleteSortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        return (
                                                <Step key={index} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                        );
                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel>
                            <Steps current={finishedcurrent} progressDot labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInCompletedWizard && item.parentSection == 'PART 3')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                        );

                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel >
                            <Steps current={finishedcurrent} progressDot labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInCompletedWizard && item.parentSection == 'PART 2')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu) => onStepChange(menuItem.controllerName)} />
                                        );

                                    })}
                            </Steps>
                        </TabPanel>
                        <TabPanel>
                            <Steps current={finishedcurrent} progressDot labelPlacement="vertical">
                                {wizardDetails.wizardItemList
                                    .filter(item => item.showInCompletedWizard && item.parentSection == 'PART 1')
                                    .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                                    .map(function (menuItem, index) {
                                        return (
                                                <Step key={menuItem.name} status="finish" title={menuItem.name} onClick={(menu, index) => onStepChange(menuItem.controllerName, index)} />
                                        );

                                    })}
                            </Steps>
                        </TabPanel>
                    </Tabs>
                </Container>
            );
        }
    }

        return (
            <div>
                <div className="wizard-menu">
                    {renderWizard(wizardDetails)}
                </div>
            </div >
        );
    
}

export default WizardMenu;