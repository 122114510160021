import React from 'react';
import { Row, Col } from 'reactstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';
const FarmAPTThanksTo = () => {
    return (
            <>
                <Row>
                    <Col>
                    <h6 >Data thanks to: &nbsp;&nbsp;&nbsp;<FaInfoCircle data-tooltip-id="my-tooltip-2" /></h6>
                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "50vw", wordBreak: 'normal', overflowWrap: 'break-word', fontWeight: "750" }}
                            id="my-tooltip-2"
                            place="top"
                            variant="info"
                        render={({ content, activeAnchor }) => (
                                <>
                                <Row>
                                    <Col sm="2"><img style={{height:"100px",width:"100px"}} src="images/dairynz-logo.jpg" className="rounded thank-image" alt="DairyNZ logo"></img>                                   
                                    </Col>
                                    <Col sm="2"></Col>
                                    <Col sm="2"><img style={{ height: "140px", width: "140px" }}  src="images/agresearch-logo.png" className="rounded thank-image" alt="AgResearch logo"></img>
                                    </Col>
                                    <Col sm="2"></Col>
                                    <Col sm="2"><img style={{ height: "160px", width: "160px" }}  src="images/national-science-challenges-logo.png" className="rounded thank-image" alt="National Science Challenge logo"></img>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col>
                                        <p style={{ fontWeight: "lighter" }}>The Farm Action Prioritisation Tool (FarmAPT) contains management practices and mitigation actions for dairy.<br />
                                            It was developed by DairyNZ with funding from the DairyNZ dairy farmer levy, in collaboration with AgResearch,<br/> and co-funded by the Our Land and Water National Science Challenge Rural Professionals Fund 2020-21.</p>
                                    </Col>
                                </Row>
                                </>
                            )}
                        />
                    </Col>
                </Row>
                
            </>
        )
}
export default FarmAPTThanksTo