import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import uuid from 'react-uuid';
import Select, { components } from 'react-select';
const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const Risk = ({ formErrors, itemPopupRisk, riskLookupList, handleItemPopupRisk, handleMultiSelectEnterpriseChange, handleMultiSelectContaminantChange,
    handleMultiSelectRiskDataGroupChange, handleMultiSelectActivityGroupChange, handleMultiSelectActivitySubGroupChange }) => {
    //const [showEnterprise, setShowEnterprise] = useState(false);
    const selectOptions = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" }
    ];
    const selectRiskOptions = [
        { value: "", label: "Select" },
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" }
    ];
    const [enterpriseList, setEnterpriseList] = useState([]);
    const [tempEnterpriseList, setTempEnterpriseList] = useState(riskLookupList.luenterpriseTypes);
    useEffect(() => {
        if (riskLookupList.luenterpriseTypes.filter(x => x.label == 'Kiwifruit').length > 0) {
            if (riskLookupList.luenterpriseTypes.length == 1) {
                itemPopupRisk.enterprises = riskLookupList.luenterpriseTypes.filter(x => x.label == 'Kiwifruit');
                itemPopupRisk.showEnterprise = false;
            }
            else {
                itemPopupRisk.showEnterprise = true;
            }
        }
        else {
            itemPopupRisk.showEnterprise = true;
        }
    }, [])
    return (
        <>
            <Row style={{display:"none"}}>
                <Col sm="6">
                    <FormGroup>
                        <Label for="isRisk">Does this Item have any Risk?</Label>
                        <Input
                            bsSize="sm"
                            type="select"
                            value={itemPopupRisk.isRisk}
                            onChange={handleItemPopupRisk}
                            name="isRisk"
                            id="isRisk">
                            {selectOptions.map(Option =>
                                <option key={uuid()} value={Option.id}>{Option.label}</option>
                            )}
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    {itemPopupRisk.isRisk?.toLowerCase() == "yes" ?
                        <FormGroup>
                            <Label for="riskComment">Describe the Risk(s)</Label>
                            <Input
                                type="textarea"
                                name="riskComment"
                                id="riskComment"
                                value={itemPopupRisk.riskComment}
                                onChange={handleItemPopupRisk} />
                        </FormGroup> : ''}
                    {formErrors.riskComment != undefined &&
                        <p className="errorMessage">{formErrors.riskComment}</p>
                    }
                </Col>
            </Row>
            {itemPopupRisk.isRisk?.toLowerCase() == "yes" &&
                <>
                    <Row>
                        <Col sm="6">
                        <FormGroup>
                            <Label for="contaminant">Contaminant(s)</Label>
                            <Select
                                closeMenuOnSelect={false}
                                className="dropdown"
                                placeholder="Select Contaminant(s)"
                                defaultValue={itemPopupRisk.contaminants}
                                value={itemPopupRisk.contaminants}
                                options={riskLookupList.contaminants}
                                onChange={handleMultiSelectContaminantChange}
                                isMulti hideSelectedOptions={false}
                                isClearable
                                components={{
                                    Option
                                }}
                            />
                        </FormGroup>
                        {formErrors.contaminants != undefined &&
                            <p className="errorMessage">{formErrors.contaminants}</p>
                        }
                    </Col>
                        <Col sm="6">
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="likelihood">Likelihood</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        value={itemPopupRisk.likelihood}
                                        onChange={handleItemPopupRisk}
                                        name="likelihood"
                                        id="likelihood">
                                        {selectRiskOptions.map(Option =>
                                            <option key={uuid()} value={Option.id}>{Option.label}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                                {formErrors.likelihood != undefined &&
                                    <p className="errorMessage">{formErrors.likelihood}</p>
                                }
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="impact">Impact</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        value={itemPopupRisk.impact}
                                        onChange={handleItemPopupRisk}
                                        name="impact"
                                        id="impact">
                                        {selectRiskOptions.map(Option =>
                                            <option key={uuid()} value={Option.id}>{Option.label}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                                {formErrors.impact != undefined &&
                                    <p className="errorMessage">{formErrors.impact}</p>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {itemPopupRisk.showEnterprise && 
                    <>
                        <Row>
                            <Col>
                                <label class="itemTypeList-header-subtext">Because this property is multi-enterprise, please select which Enterprises this risk related to.</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col sm="12">
                                <FormGroup>
                                    <Label for="enterprise">Enterprise(s)</Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        className="dropdown"
                                        placeholder="Select Enterprise(s)"
                                        defaultValue={itemPopupRisk.enterprises}
                                        value={itemPopupRisk.enterprises}
                                        options={riskLookupList.luenterpriseTypes}
                                        onChange={handleMultiSelectEnterpriseChange}
                                        isMulti hideSelectedOptions={false}
                                        isClearable
                                        components={{
                                            Option
                                        }}
                                    />
                                </FormGroup>
                                {formErrors.enterprises != undefined &&
                                    <p className="errorMessage">{formErrors.enterprises}</p>
                                }
                            </Col>

                        </Row>
                    </>    
                }
                    <Row>
                        <Col>
                            <label style={{ color: "black", fontSize: "18px", fontWeight: "600" }} class="itemTypeList-header-subtext"><u>Ministry for the Environment - data reporting requirements</u></label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        <Col>
                            <label class="itemTypeList-header-subtext">When a FreshWater Plan is certified Actions and Risks and are required to be reported and associated with Risk Data Groups and Activity Groups. For this custom risk please specify which item(s) in the list below best match this risk.</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                        <Col sm="6">
                            <FormGroup>
                                <Label for="mferiskdatagroup">MfE Risk Data Group(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select MfE Risk Data Group(s)"
                                    defaultValue={itemPopupRisk.riskDataGroups}
                                    value={itemPopupRisk.riskDataGroups}
                                    options={riskLookupList.riskDataGroups}
                                    onChange={handleMultiSelectRiskDataGroupChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formErrors.riskDataGroups != undefined &&
                                <p className="errorMessage">{formErrors.riskDataGroups}</p>
                            }
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label for="mfeactivitygroup">MfE Activity Group(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select MfE Activity Group(s)"
                                    defaultValue={itemPopupRisk.activityGroups}
                                    value={itemPopupRisk.activityGroups}
                                    options={riskLookupList.activityGroups}
                                    onChange={handleMultiSelectActivityGroupChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formErrors.activityGroups != undefined &&
                                <p className="errorMessage">{formErrors.activityGroups}</p>
                            }
                        </Col>
                    </Row>
                    <Row >
                        <Col sm="6">
                        <FormGroup>
                            <Label for="mfeactivitysubgroup">MfE Activity Sub Group(s)</Label>
                            <Select
                                closeMenuOnSelect={false}
                                className="dropdown"
                                placeholder="Select MfE Activity Sub Group(s)"
                                defaultValue={itemPopupRisk.activitySubGroups}
                                value={itemPopupRisk.activitySubGroups}
                                options={riskLookupList.activitySubGroups}
                                onChange={handleMultiSelectActivitySubGroupChange}
                                isMulti hideSelectedOptions={false}
                                isClearable
                                components={{
                                    Option
                                }}
                            />
                        </FormGroup>
                        {formErrors.activitySubGroups != undefined &&
                            <p className="errorMessage">{formErrors.activitySubGroups}</p>
                        }
                    </Col>
                    </Row>
                </>
                
            }

        </>

    )
}
export default Risk