import { useState } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Collapse, CardTitle, CardText, CardSubtitle } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';
import '../MyInfo/News.css';
import Moment from 'moment';
import ReadMoreContent from '../Utilities/Ui/ReadMore';
import { useNavigate } from "react-router-dom";

const News = ({ newsInfo, type, catchmentId, roleName, supplyCompanyId, ruralProfessionalOrganisationId }) => {
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true); const navigate = useNavigate();
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    let organisationId = 0;
    if (catchmentId != undefined) {
        organisationId = catchmentId;
    }
    else if (supplyCompanyId != undefined) {
        organisationId = supplyCompanyId;
    }
    else if (ruralProfessionalOrganisationId != undefined) {
        organisationId = ruralProfessionalOrganisationId;
    }
    const open = (id, routePath) => {
        navigate('/' + routePath, { state: {id:id, organisationId: organisationId } });
    }
    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header" onClick={toggle}>
                            <Row>
                                <Col sm="6"><h6>News</h6></Col>
                                <Col sm="5" className="text-end">
                                    {type == "Catchment" && catchmentId != 0 && roleName == "Organization User" && <button onClick={(id,  routePath) => open(0,  'createnews')} className="btn btn-sm btn-primary">Add News</button>}
                                    {type == "Supply Company" && supplyCompanyId != 0 && roleName == "Organization User" && <button onClick={(id,  routePath) => open(0,  'createnews')} className="btn btn-sm btn-primary">Add News</button>}
                                    {type == "Rural Professional Organisation" && ruralProfessionalOrganisationId != 0 && roleName == "Organization User" && <button onClick={(id,  routePath) => open(0,  'createnews')} className="btn btn-sm btn-primary">Add News</button>}</Col>
                                <Col sm="1" className="toggleoutline">
                                    {isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    {newsInfo != undefined && newsInfo != null && newsInfo.length > 0 &&
                                        newsInfo.map(item => (
                                            <Row key={uuid()}>
                                                <Col key={uuid()} style={{ marginTop: '10px' }}>
                                                    <Card>
                                                        <Row key={uuid()}>
                                                            <Col sm="3">
                                                                {item.newsItemThumbnailName ?
                                                                    <img alt="Card cap" src={item.newsItemThumbnailRelativePath} width="100%"
                                                                        onError={(e) => {
                                                                            e.target.onerror = null
                                                                            e.target.src = "/images/no-image.jpg"
                                                                        }} /> : <img alt="Card cap" src="/images/news-icon.png" width="100%" />
                                                                }
                                                            </Col>
                                                            <Col sm="9">
                                                                <Row>
                                                                    <Col>
                                                                        <CardBody><CardTitle tag="h5">
                                                                            {item.newsItemName}</CardTitle>
                                                                            <CardSubtitle className="mb-2 text-muted" tag="h6">
                                                                                Editor : {item.newsEditorName} on {Moment(item.newsItemDate).format('Do MMMM  YYYY')}
                                                                            </CardSubtitle>
                                                                        </CardBody>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <CardBody>
                                                                            <CardText>
                                                                                <ReadMoreContent longText={item.newsItemDescription} />
                                                                            </CardText>
                                                                            <a href={"/viewnews?id=" + item.id+"&type="+type} target='_blank' >
                                                                                <button className="btn btn-sm btn-primary">View</button>
                                                                            </a>&nbsp;&nbsp;
                                                                            {item.newsItemAttachementName != null && item.newsItemAttachementName != "" &&
                                                                                <a className="linkNews" href={item.newsItemAttachementRelativePath} target='_blank' >
                                                                                    Attachment : <u>{item.newsItemAttachementName}</u> (Click to open)
                                                                                </a>
                                                                            }
                                                                        </CardBody>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default News;