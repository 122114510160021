import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Label, Collapse, Table, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaChevronRight, FaChevronUp, FaChevronDown, FaChevronLeft, FaPlus, FaUpload, FaTrash } from 'react-icons/fa';
import FarmApt from './FarmApt';
import PriorityContaminants from './PriorityContaminants';
import CatchmentOverview from './CatchmentOverview';
import './CatchmentContext.css';
import axios from 'axios';
import OtherInfo from './OtherInfo';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from "../Utilities/Hooks/useAuth";
import Documents from '../Uploads/Documents';
import { toPng } from 'html-to-image';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const getBase64 = (file) => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

const CatchmentContext = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const elementRef = useRef(null); const elementRef1 = useRef(null); const elementRef2 = useRef(null);
    const [isOpen, setIsOpen] = useState(false); const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [loading, setLoading] = useState(true);
    const [catchmentName, setCatchmentName] = useState('');
    const [catchmentContext, setCatchmentContext] = useState({ Section: 'CatchmentContext', SectionName: 'Catchment Context, Challenges and Values', isWizardCompleted: false, SectionObj: {}, });
    const [catchmentDescription, setCatchmentDescription] = useState(''); const [savedContaminantsList, setSavedContaminantsList] = useState('');
    const [catchmentImageUrl, setCatchmentImageUrl] = useState('');
    const [catchmentExternalLink, setCatchmentExternalLink] = useState('');
    const [catchmentDocumentName, setCatchmentDocumentName] = useState('');
    const [catchmentDocumentRelativePath, setCatchmentDocumentRelativePath] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId")); const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const { auth } = useAuth();
    const [notRelevantToThisProperty, setNotRelevantToThisProperty] = useState(false);
    const [externalOrganisationLinkRows, setExternalOrganisationLinkRows] = useState([]);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [imageUploadList, setImageUploadList] = useState([]); const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    let inputFile = [];
    useEffect(() => {
        setLoading(true);
        getCatchmentDetails();

    }, [])
    const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(0);
    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const handleImageChange = async (e, idx) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.type != 'image/jpeg' && file.type != 'image/jpg') {
            toast.error("Image uploads must be in .jpg format and less than 5mb in size. Please adjust the file you are trying to upload and try again.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
        else if ((file.size / (1024 * 1024)) > 5) {
            toast.error("Image uploads must be in .jpg format and less than 5mb in size. Please adjust the file you are trying to upload and try again.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Base64Image: result,
                    farmId: farmId,
                    userId: userId
                }
                const response = axios.post(
                    '/api/catchmentcontext/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                //toast.success("Images Saved!", { duration: 2000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                                const tempRows = [...imageUploadList];
                                tempRows[idx].imageName = res.data.content.FileName;
                                tempRows[idx].imagePhysicalName = res.data.content.PhysicalFilename;
                                tempRows[idx].imagePath = res.data.content.PhysicalFilepath;
                                tempRows[idx].imageRelativePath = res.data.content.RelativeFilepath;
                                setImageUploadList(tempRows);
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            e.target.value = null;
        }

        reader.readAsDataURL(file)
    }

    const handleBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputFile[idx].click();
        return false;
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    const handleNameChange = idx => e => {
        const tempRows = [...imageUploadList];
        tempRows[idx].name = e.target.value;
        setImageUploadList(tempRows);
    };
    const handleDetailChange = idx => e => {
        const tempRows = [...imageUploadList];
        tempRows[idx].detail = e.target.value;
        setImageUploadList(tempRows);
    };

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }
    const handleDateChange = (idx, date) => {
        const tempRows = [...imageUploadList];
        tempRows[idx].uploadDateText = getParsedDate(date);
        tempRows[idx].uploadDate = getDateFromString(getParsedDate(date));
        setImageUploadList(tempRows);
    };
    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const [isOpen1, setIsOpen1] = useState(true); const [isIconUp1, setIsIconUp1] = useState(true);
    const toggle1 = () => {
        setIsOpen1(!isOpen1);
        setIsIconUp1(!isIconUp1);
    };

    const [formErrors, setFormErrors] = useState([]);
    const handleRecordRowsAddRow = () => {
        const item = {
            uploadDate: null,
            detail: "", id: 0, name: '', imagePhysicalName: '', imagePath: '', imageRelativePath: '', imageName: ''
        };
        const error = {
            fileError: ''
        };
        setFormErrors([...formErrors, error]);
        setImageUploadList([...imageUploadList, item]);
    };
    const [saving, setSaving] = useState('');
    const handleRecordRowsSubmit = async (e) => {
        setSaving(true);
        let tempRecordUploadList = [...imageUploadList];
        let form = {
            FarmId: farmId,
            userId: userId,
            Images: []
        }
        for (var i = 0; i < tempRecordUploadList.length; i++) {
            if (tempRecordUploadList[i].imageName != '' && tempRecordUploadList[i].imageName != null && tempRecordUploadList[i].imageName != undefined) {
                var obj = {};
                obj.Id = tempRecordUploadList[i].id;
                obj.Name = tempRecordUploadList[i].name;
                obj.Detail = tempRecordUploadList[i].detail;
                obj.ImageDetail = tempRecordUploadList[i].imageDetail;
                obj.ImageName = tempRecordUploadList[i].imageName;
                obj.ImagePhysicalName = tempRecordUploadList[i].imagePhysicalName;
                obj.ImagePath = tempRecordUploadList[i].imagePath;
                obj.UploadDateText = tempRecordUploadList[i].uploadDate != null ? tempRecordUploadList[i].uploadDateText : "";
                form.Images.push(obj);
            }
        }
        const response = await axios.post(
            '/api/catchmentcontext/SaveRegionalCouncilDetails/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                setImageUploadList(response.data.content);
                let tempErrors = [];
                setFormErrors(tempErrors);
                response.data.content.forEach(function (element) {
                    const error = {
                        fileError: ''
                    };
                    tempErrors.push(error);
                });
                setFormErrors(tempErrors);
                setSaving(false);
            }
        }
    };

    const getCatchmentDetails = async () => {
        try {

            const response = await axios.get(
                '/api/catchmentcontext/GetCatchmentDetails/',
                {
                    headers: {
                        "Authorization": `Bearer ${auth.token}`
                    },
                    params: {
                        farmId: farmId
                    },
                });

            setCatchmentName(response?.data?.name);
            setCatchmentDescription(response?.data?.description);
            setCatchmentImageUrl(response?.data?.imageUrl);
            setCatchmentContext({ ...catchmentContext, isWizardCompleted: response?.data?.isFWFPCompleted, SectionObj: response?.data?.section });
            setCatchmentExternalLink(response?.data?.externalLink);
            setCatchmentDocumentName(response?.data?.catchmentDocumentName);
            setCatchmentDocumentRelativePath(response?.data?.catchmentDocumentRelativePath);
            setSavedContaminantsList(response?.data?.savedContaminantList);
            setNotRelevantToThisProperty(response?.data?.surfaceWaterQualityNotRelevantToThisProperty);
            setIsOpen(!response?.data?.surfaceWaterQualityNotRelevantToThisProperty);
            setExternalOrganisationLinkRows(response?.data?.externalOrganisationLinks);
            setImageUploadList(response.data.regionalCouncilMapImages);
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            setLoading(false);

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message);
            } else {
                toast.error("No server response");
            }
            setLoading(false);
        }
    }

    const handleNotRelevantToThisProperty = async (event) => {
        setNotRelevantToThisProperty(event.target.checked);
        const response = await axios.get(
            '/api/catchmentcontext/UpdateNotRelevantToThisProperty/',
            {
                params: {
                    FarmId: farmId, NotRelevantToThisProperty: event.target.checked
                },
            });
    }

    const htmlToImageConvert = async (section, method) => {
        var WaterTestSitesImage1 = null;
        var WaterTestSitesImage2 = null;
        var WaterTestSitesImage3 = null;
        toPng(elementRef.current, { cacheBust: true })
            .then((dataUrl) => {
                WaterTestSitesImage1 = dataUrl;

                let form = {
                    WaterTestSitesImage1: WaterTestSitesImage1,
                    WaterTestSitesImage2: WaterTestSitesImage2,
                    WaterTestSitesImage3: WaterTestSitesImage3,
                    FarmId: farmId
                }
                const response = axios.post(
                    '/api/upload/UploadCatchemntImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (method == 'handleWizardChanged') {
                            handleWizardChanged(section);
                        }
                        else if (method == 'handleMoveToNextPage') {
                            handleMoveToNextPage(section);
                        }
                        else if (method == 'handleWizardChangedBack') {
                            handleWizardChangedBack(section);
                        }
                    });

            })
            .catch((err) => {
                if (method == 'handleWizardChanged') {
                    handleWizardChanged(section);
                }
                else if (method == 'handleMoveToNextPage') {
                    handleMoveToNextPage(section);
                }
                else if (method == 'handleWizardChangedBack') {
                    handleWizardChangedBack(section);
                }
            });
    };

    const handlePageChange = async (section, method) => {
        await htmlToImageConvert(section, method);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const confirmDeleteItemPopup = async () => {
        const response = await axios.get(
            '/api/catchmentcontext/DeleteRegionalCouncilMapImage/', {
            params: { Id: deleteItemId, userId: userId, FarmId: farmId }
        }
        );
        if (response.data.status) {
            setImageUploadList(response.data.content);
            let tempErrors = [];
            setFormErrors(tempErrors);
            response.data.content.forEach(function (element) {
                const error = {
                    fileError: ''
                };
                tempErrors.push(error);
            });
            setFormErrors(tempErrors);
            setShowDeleteItemPopup(false);
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2><FaInfoCircle className="h2Icon" data-tooltip-id="my-tooltip-4" />&nbsp;&nbsp;&nbsp;{catchmentContext.SectionName}</h2>
                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                        id="my-tooltip-4"
                        place="top"
                        variant="info"
                        render={({ content, activeAnchor }) => (
                            <>
                                <Row>
                                    <Col>
                                        <p style={{ fontWeight: "lighter" }}>Read the prepopulated catchment context, challenges and values information on this page and tailor to your property.<br/>This information was drawn from your regional council's documentation on catchment context.</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    />
                </Col>
                {catchmentContext.isWizardCompleted != true && !loading &&
                    <Col>
                        <>  {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => handlePageChange(catchmentContext.Section,'handleWizardChanged')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                                <Button className="float-end btn-next-page" onClick={(section) => handlePageChange(catchmentContext.Section,'handleMoveToNextPage')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            <Button className="float-end btn-next-page" onClick={(section) => handlePageChange(catchmentContext.Section,'handleWizardChangedBack')}><FaChevronLeft /> Back  </Button>
                        </>
                    </Col>
                }
            </Row>
            {loading ? <SpinnerContainer /> :
                <Row xs="2">
                    <Col>
                        <Card className="my-4">
                            <CardBody>
                                <Row>
                                    <Col sm="8">
                                        <h4>{catchmentName}</h4>
                                        <p>{catchmentDescription}</p>
                                    </Col>
                                    <Col sm="4">
                                        {catchmentImageUrl.split("Organisationalimages/")[1] != "" ?
                                            <img width="150px" src={catchmentImageUrl} className="rounded float-end" alt="Catchment Image"
                                                onError={(e) => {
                                                    e.target.onerror = null
                                                    e.target.src = "/images/no-image.jpg"
                                                }} />
                                            : <></>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>Contaminants of concern based on water quality state and trend data - <b>{savedContaminantsList}</b></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="8">
                                        <div><Label className="label" for="externalLink">External Link : </Label>
                                            <br />
                                            {externalOrganisationLinkRows.map(item =>
                                                <>
                                                    <Label className="" style={{ fontSize: "14px", marginLeft: "15px" }}>
                                                        • <a className="linkNews" href={item.link} target='_blank' ><u>{item.name == null || item.name == '' ? item.link : item.name}</u></a>
                                                    </Label>
                                                    <br />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    {catchmentDocumentRelativePath && <Col sm="4">
                                        <div className="catchmentDoc-style">
                                            <a href={catchmentDocumentRelativePath} target='_blank' title={catchmentDocumentName}>
                                                <img width="50px" src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img>
                                                <br /><span className="clickMe-label">(Click to open)</span></a>
                                        </div>
                                    </Col>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        {/*<Card className="my-4">*/}
                        {/*    <PriorityContaminants />*/}
                        {/*</Card>*/}
                        <Card className="my-4">
                            <CatchmentOverview />
                        </Card>
                        <Row>
                        </Row>
                        <Row>
                            <OtherInfo SectionObj={catchmentContext.SectionObj} />
                        </Row>
                        <Row>
                            {catchmentContext.SectionObj.isDocumentUploadReq &&
                                <Documents refid={catchmentContext.SectionObj.id} uploadType="Section" infoId="my-tooltip-6" showInfo={true} infoMessage="Supporting documents in this section will provide more detail on your catchment context. Examples include reports from your catchment group, maps of your catchment with risk ratings for contaminants, catchment map of land formation, etc."
                                    open={false} showArrow={true}></Documents>
                            }
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Card className="my-4">
                                    <CardHeader className="item-header card-header-click card-header-outline" onClick={toggle1}>
                                        <Row>
                                            <Col sm="11">
                                                <h5>Upload an image of any additional or relevant maps</h5>
                                            </Col>
                                            <Col sm="1" className="toggleoutline">{isIconUp1 ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen1}>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                        Add
                                                    </button>
                                                    {/*<button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm btn-pluse-light">*/}
                                                    {/*    <FaPlus />*/}
                                                    {/*</button>*/}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: `15px`, marginBottom: `15px` }}>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Name</th>
                                                                        <th>Detail</th>
                                                                        <th>View</th>
                                                                        <th></th>
                                                                        <th>{(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                            <> Delete
                                                                            </>}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                {imageUploadList != undefined && imageUploadList != null && imageUploadList.length > 0 &&
                                                                    <tbody>
                                                                        {imageUploadList.map((item, idx) => (
                                                                            <tr>
                                                                                <td style={{ display: "none" }}>{imageUploadList[idx].id}</td>
                                                                                <td style={{ width: "15%" }}>
                                                                                    <DatePicker selected={imageUploadList[idx].uploadDate != null ? getDateFromString(moment(imageUploadList[idx].uploadDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleDateChange(idx, date)} name="uploadDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" name="documentDetail" value={imageUploadList[idx].name} onChange={handleNameChange(idx)} className="form-control" />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="textarea" name="documentDetail" value={imageUploadList[idx].detail} onChange={handleDetailChange(idx)} className="form-control" />
                                                                                </td>
                                                                                <td>
                                                                                    <img src={item.imageRelativePath} className="image-popup-thumbnail" alt={item.imageName}
                                                                                        onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, item.imageName, item.imageRelativePath)}></img>
                                                                                </td>
                                                                                <td style={{ width: "15%" }}>
                                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                        <>
                                                                                            {imageUploadList[idx].imageRelativePath != null && imageUploadList[idx].imageRelativePath != '' ?
                                                                                                <Button style={{ float: `right` }} color="warning" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                                                                :
                                                                                                <Button style={{ float: `right` }} color="success" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                                                            }
                                                                                            <br />
                                                                                            <input style={{ float: `right`, display: 'none' }}
                                                                                                ref={input => {
                                                                                                    inputFile[idx] = input;
                                                                                                }}
                                                                                                type="file" name="attachment" className="form-control" onChange={(e) => handleImageChange(e, idx)} />
                                                                                            {formErrors.length > 0 && formErrors[idx] != undefined && formErrors[idx].fileError != undefined &&
                                                                                                <p className="errorMessage">{formErrors[idx].fileError}</p>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {(roleName == 'Zespri Admin' || imageUploadList[idx].id == 0 || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                        <>
                                                                                            <button type="button" id="btnUpdate" className="btn btn-sm btn-danger" onClick={() => openDeleteItemPopup(item)}><FaTrash /></button>
                                                                                        </>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                }
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                        <>
                                                            {saving ?
                                                                <Button onClick={(e) => handleRecordRowsSubmit(e)} style={{ float: "right", marginTop: '10px' }}
                                                                    color="success"
                                                                    disabled
                                                                >
                                                                    <Row>
                                                                        <Col sm="8">
                                                                            <span>
                                                                                {' '}Saving
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <Spinner size="sm">
                                                                                Saving...
                                                                            </Spinner>
                                                                        </Col>
                                                                    </Row>
                                                                </Button>
                                                                :
                                                                <Button onClick={(e) => handleRecordRowsSubmit(e)} style={{ float: "right", marginTop: '10px' }} color="success" size="sm">
                                                                    Save
                                                                </Button>
                                                            }
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="my-4">
                                    <CardHeader className="item-header card-header-click card-header-outline" onClick={toggle}>
                                        <Row>
                                            <Col sm="6">
                                                <h5><FaInfoCircle data-tooltip-id="my-tooltip-3" />&nbsp;&nbsp;&nbsp;Surface Water Quality</h5>
                                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                    id="my-tooltip-3"
                                                    place="top"
                                                    variant="info"
                                                    render={({ content, activeAnchor }) => (
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <p style={{ fontWeight: "lighter" }}>This information is provided by LAWA (Land Air Water Aotearoa) and is based on the closest testing site to your location.<br/>If no results appear for you, you can visit lawa.org.nz to explore testing sites in your region.</p>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm="5">
                                                <label style={{ float: "right", marginLeft: "10px",color:"white" }} className="outlineLabel form-label" htmlFor="notRelevantToThisProperty">
                                                    Not relevant to this property
                                                </label>
                                                <input style={{ float: "right" }} disabled={ (roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') ? false : true} className="islargeEnterprise form-check-input" id="notRelevantToThisProperty" value={notRelevantToThisProperty} checked={notRelevantToThisProperty} type="checkbox" onChange={handleNotRelevantToThisProperty} />
                                            </Col>
                                            <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen && !notRelevantToThisProperty}>
                                        <CardBody>
                                            <FarmApt elementRef={elementRef} elementRef1={elementRef1} elementRef2={elementRef2} />
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default CatchmentContext;