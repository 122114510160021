import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Card, CardBody, CardHeader,Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown} from 'react-icons/fa';
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';

const AllFarmWaterwayFencingSummary = () => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [waterwayFencing, setWaterwayFencing] = useState({summaryList: [] });
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/EnterpriseInsights/GetAllFarmWaterwayFencingDetails/', {
                params: {
                        UserId: userId
                }
            }
            );
            setWaterwayFencing({
                ...waterwayFencing, summaryList: response.data
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const exportWaterwayFencingOverall = async (e,type) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleWaterwayFencingOverallReport(e,type);
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleWaterwayFencingOverallReport = async (e, type) => {
        const response = await axios.get(
            '/api/Summary/GenerateReportWaterwayFencingOverall/', {
                params: { farmId: farmId,type:type }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            if (type == "Pdf") {
                var file = new Blob([buffer], { type: "application/pdf" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "WaterwayFencingOverall.pdf";
            }
            else {
                var file = new Blob([buffer], { type: "application/vnd.ms-excel" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "WaterwayFencingOverall.xlsx";
            }
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <Card className="my-4">
            <CardHeader className="card-header-dark-click" onClick={toggle}>
                <Row>
                    <Col sm="11"><h6>Waterway Fencing (Overall)
                        {/*<Button style={{ float: "right", background: "none", border: "none" }} onClick={(e) => exportWaterwayFencingOverall(e,'Excel')} value="Export" placeholder="">*/}
                        {/*    <img src="/images/Excel_icon.png" className="card_icon" ></img>*/}
                        {/*</Button>*/}
                        {/*<Button style={{ float: "right", background: "none", border: "none", marginRight: "-20px" }} onClick={(e) => exportWaterwayFencingOverall(e,'Pdf')} value="Export" placeholder="">*/}
                        {/*    <img src="/images/PDF_icon.png" className="card_icon" ></img>*/}
                        {/*</Button>*/}
                    </h6></Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody >
                    <Row>
                        <Col>
                            {!loading &&
                                <table id="tblWaterwayFencingSummary" className="table borderless table-striped border-top-bottom">
                                    <thead className="textAlignCenter">
                                        <tr>
                                            <th rowSpan="2">Type</th>
                                            <th colSpan="1">Length or Perimeter</th>
                                            <th colSpan="2">Fenced</th>
                                            <th colSpan="2">UnFenced</th>
                                        </tr>
                                        <tr>
                                            <th>km</th>
                                            <th>km</th>
                                            <th>%</th>
                                            <th>km</th>
                                            <th>%</th>
                                        </tr>
                                    </thead>
                                    <tbody className="waterwayFencingSummaryList textAlignCenter" id="waterwayFencingSummaryList">
                                        {waterwayFencing.summaryList.map(summary => 

                                            <tr key={uuid()}>
                                                <td>{summary.type}</td>
                                                <td>{summary.totalKm == 0 ? "" : summary.totalKm}</td>
                                                <td>{summary.fencedKm == 0 ? "" : summary.fencedKm}</td>
                                                <td>{summary.fencedPerc == 0 ? "" : summary.fencedPerc}</td>
                                                <td>{summary.unFencedKm == 0 ? "" : summary.unFencedKm}</td>
                                                <td>{summary.unFencedPerc == 0 ? "" : summary.unFencedPerc}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            }
                        </Col>
                    </Row>

                </CardBody>
            </Collapse>
        </Card>
    )
}
export default AllFarmWaterwayFencingSummary

