import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Form, Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import "../Farm/Farm.css";
import Validate from '../Validate/Validate';
import toast, { Toaster } from 'react-hot-toast';
import uuid from 'react-uuid';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

const EditFarm = () => {
    const { state } = useLocation(); const navigate = useNavigate();
    const [farmList, setFarmList] = useState([]);
    const [userFarms, setUserFarms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(0);
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');
    const [isThisPropertyLeased, setIsThisPropertyLeased] = useState('No');
    const [name, setName] = useState('');
    const [createdOn, setCreatedOn] = useState(new Date());
    const [subscription, setSubscription] = useState(0);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
    const [internalNotes, setInternalNotes] = useState('');
    const [catchment, setCatchment] = useState(0);
    const [ruralProfessionalOrganisation, setRuralProfessionalOrganisation] = useState(0);
    const [supplyCompany, setSupplyCompany] = useState(0);
    const [ruralProfessional, setRuralProfessional] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [regionalCouncil, setRegionalCouncil] = useState(0);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [kPin, setKPin] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [yesNoList, setYesNoList] = useState([]);
    const [valuationReference, setValuationReference] = useState(''); const [searchParams, setSearchParams] = useSearchParams();
    const [catchmentValue, setCatchmentValue] = useState(0); const [nameValue, setNameValue] = useState(''); const [regionalCouncilValue, setRegionalCouncilValue] = useState(0);
    const [showArchivedValue, setShowArchivedValue] = useState(''); const [idValue, setIdValue] = useState(0);
    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            setIdValue(params.get('id'));
            setCatchmentValue(params.get('catchment'));
            setRegionalCouncilValue(params.get('regionalCouncil'));
            setNameValue(params.get('name'));
            setShowArchivedValue(params.get('showArchived'));
            if (searchParams.has('id')) {
                searchParams.delete('id');
                setSearchParams(searchParams);
            }
            if (searchParams.has('catchment')) {
                searchParams.delete('catchment');
                setSearchParams(searchParams);
            }
            if (searchParams.has('regionalCouncil')) {
                searchParams.delete('regionalCouncil');
                setSearchParams(searchParams);
            }
            if (searchParams.has('name')) {
                searchParams.delete('name');
                setSearchParams(searchParams);
            }
            if (searchParams.has('showArchived')) {
                searchParams.delete('showArchived');
                setSearchParams(searchParams);
            }
            const response = await axios.get(
                '/api/settings/GetFarmById/', {
                    params: { FarmId: params.get('id') }
            }
            );
            setFarmList(response.data.content);
            setYesNoList(response.data.content.yesNoList);
            setLoading(false);
            var farm = response.data.content.farms.filter(x => x.id == params.get('id'));
            setId(farm[0].id);
            setName(farm[0].name);
            setUniqueIdentifier(farm[0].uniqueIdentifier);
            var createdDate = getDateFromString(moment(farm[0].createdOn).format("DD/MM/YYYY"));
            setCreatedOn(createdDate);
            if (farm[0].subscriptionStartDateText != null && farm[0].subscriptionStartDateText != "") {
                var subscriptionDate = getDateFromString(moment(farm[0].subscriptionStartDate).format("DD/MM/YYYY"));
                setSubscriptionStartDate(subscriptionDate);
            }
            setIsThisPropertyLeased(farm[0].isThisPropertyLeased);
            setValuationReference(farm[0].valuationReference);
            setSubscription(farm[0].subscription);
            setInternalNotes(farm[0].internalNotes);
            setCatchment(farm[0].catchment);
            setRuralProfessionalOrganisation(farm[0].ruralProfessionalOrganisation);
            setSupplyCompany(farm[0].supplyCompany);
            setRuralProfessional(farm[0].ruralProfessional);
            setRegionalCouncil(farm[0].regionalCouncil);
            setAddress1(farm[0].address1);
            setAddress2(farm[0].address2);
            setCity(farm[0].city);
            setPostcode(farm[0].postcode);
            setKPin(farm[0].kPin);
            setIsActive(farm[0].isActive);
            setUserFarms(farm[0].userFarms)
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        var SubscriptionStartDate = null;
        if (subscriptionStartDate != null && subscriptionStartDate != undefined && subscriptionStartDate != '') {
            SubscriptionStartDate = getParsedDate(subscriptionStartDate);
        }
        let form = {
            Id: id, UniqueIdentifier: uniqueIdentifier, Name: name, Catchment: parseInt(catchment), SupplyCompany: 0,
            RuralProfessionalOrganisation: 0, RuralProfessional: '',
            UserId: userId, RegionalCouncil: regionalCouncil, Address1: address1, Address2: address2, City: city, Postcode: postcode,
            isActive: isActive, KPin: kPin,
            IsThisPropertyLeased: isThisPropertyLeased, ValuationReference: valuationReference
        }
        if (!Validate(form)) {
            const response = await axios.post(
                '/api/settings/SaveFarms/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("Orchard Updated!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.open("/orchard", "_self");
                    }, 1500);
                }
                else if (response.data.message == 'Orchard already exists in the system') {
                    toast.error("Orchard already exists in the system!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                }
            }
        }
    }

    const backToFarmList = async () => {
        window.location = '/orchard?catchment=' + catchmentValue + '&name=' + nameValue + '&showArchived=' + showArchivedValue + '&regionalCouncil=' + regionalCouncilValue;
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "-" + mm + "-" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Edit Orchard
                </h2>
                <CardBody>
                    <div >
                        <Form onSubmit={handleSubmit}>
                            <Row style={{ marginTop: `15px` }} xs="2">
                                <Col>
                                    <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>General Information</u></b></Label>
                                </Col>
                            </Row>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Orchard Identifier</Label>
                                        <Input className="validate" type="text" readOnly={true} disabled value={uniqueIdentifier} onChange={(e) => setUniqueIdentifier(e.target.value)} name="UniqueIdentifier" id="UniqueIdentifier" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Orchard Created Date</Label>
                                        <DatePicker readOnly={true} disabled style={{ width: `100%` }}
                                            selected={createdOn}
                                            name="createdOn"
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                <br/>
                                    <FormGroup>
                                        <Input type="checkbox" checked={isActive} onChange={(e) => setIsActive(!isActive)} name="isActive" id="isActive" />
                                        <Label style={{ marginLeft: "10px" }} className="label" for="name">Is Active</Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Is this property leased</Label>
                                        <Input type="select" onChange={(e) => setIsThisPropertyLeased(e.target.value)} name="isThisPropertyLeased" id="isThisPropertyLeased" value={isThisPropertyLeased} >
                                            {yesNoList != undefined && yesNoList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Valuation Reference</Label>
                                        <Input type="text" value={valuationReference} onChange={(e) => setValuationReference(e.target.value)} name="valuationReference" id="valuationReference" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: `15px` }} xs="2">
                                <Col>
                                    <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>Demographics</u></b></Label>
                                </Col>
                            </Row>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Orchard Name</Label>
                                        <Input className="validate" type="text" readOnly={true} disabled value={name} onChange={(e) => setName(e.target.value)} name="Name" id="Name" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="internalNotes">Address 1</Label>
                                        <Input type="textarea" onChange={(e) => setAddress1(e.target.value)} value={address1} name="address1" id="address1" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="internalNotes">Address 2</Label>
                                        <Input type="textarea" onChange={(e) => setAddress2(e.target.value)} value={address2} name="address2" id="address2" />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="internalNotes">Town/City</Label>
                                        <Input type="textarea" onChange={(e) => setCity(e.target.value)} value={city} name="city" id="city" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Postcode</Label>
                                        <Input type="text" onChange={(e) => setPostcode(e.target.value)} value={postcode} name="postcode" id="postcode" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">KPIN</Label>
                                        <Input type="text" onChange={(e) => setKPin(e.target.value)} value={kPin} name="kPin" id="kPin" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: `15px` }} xs="2">
                                <Col>
                                    <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>Organisational Links</u></b></Label>
                                </Col>
                            </Row>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Regional Council</Label>
                                        {farmList.regionalCouncils == undefined && <p><em>Loading...</em></p>}
                                        {farmList.regionalCouncils != undefined && farmList.regionalCouncils != null &&
                                            <Input className="validate" type="select" value={regionalCouncil} onChange={(e) => setRegionalCouncil(e.target.value)} name="RegionalCouncil" id="RegionalCouncil">
                                                {farmList.regionalCouncils.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Catchment</Label>
                                        {farmList.catchments == undefined && <p><em>Loading...</em></p>}
                                        {farmList.catchments != undefined && farmList.catchments != null &&
                                            <Input className="validate" type="select" value={catchment} onChange={(e) => setCatchment(e.target.value)} name="Catchment" id="Catchment">
                                                {farmList.catchments.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row> 
                            <Row style={{ marginTop: `15px` }} xs="2">
                                <Col>
                                    <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>Associated Users</u></b></Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userFarms == undefined &&
                                                <tr><td colSpan="8" style={{ textAlign: "center" }}>Loading...</td></tr>
                                            }
                                            {userFarms != undefined && userFarms != null && userFarms.map(item => (
                                                <tr key={uuid()}>
                                                    <td>{item.userName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input type="submit" style={{ float: "right" }} value="Save" size="sm" className="btn button-save" />
                                    <Button className="button-normal" style={{ float: "right", marginRight: "10px" }} color="warning" onClick={backToFarmList}><FaChevronLeft /> Back To List</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default EditFarm;
