import React, { Component, useEffect, useState, useCallback } from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Polyline,
    Polygon,
    Circle,
    Rectangle,
    InfoWindow 
} from "react-google-maps";

import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import uuid from 'react-uuid';

export class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawingControlEnabled: true,
            marker: [],
            polyline: [],
            polygon: [],
            circle: [],
            rectangle: [],
            visible: true,
            modalshow: false,
            zoomlevel: 15,
            center: {
                lat: - 41.392263, lng: 174.436898
            },
            UnsavedShapes: [],
            infoWindowPosition: {},
            infoItem: {},
            showingInfoWindow: false,
            mapTypeId: 'satellite',
            mapHeight: '60vh',
            showingMarkerWindow: false,
            activePolygon: {},
            activeInfoLatLan: '',
            showingLUInfoWindow: false,
        };
    }

    componentDidMount() {
        this.getFarmCentre()
    }

    getFarmCentre = async () => {
        const outlineList = this.props.data.outlineList;
        const mapConfigDetails = this.props.data.mapDetails
        const isAllFarmDetails = mapConfigDetails != null ? mapConfigDetails.isAllFarmDetails : false;
        this.state.zoomlevel = mapConfigDetails != null ? mapConfigDetails.zoomLevel : 5;
        /* Farm center*/
        if (isAllFarmDetails) {
            this.state.center = { lat: - 41.392263, lng: 174.436898 }
        }
        else {
            if (outlineList.length > 0) {

                let OutlineTypeLength = outlineList.filter(type => type.mapType == 'marker').length;
                if (OutlineTypeLength > 0) {
                    outlineList.filter(type => type.mapType == 'marker').map((item) => {
                        this.state.center = { lat: parseFloat(item.coordinates.split(',')[0]), lng: parseFloat(item.coordinates.split(',')[1]) }
                    })
                }
                else {
                    OutlineTypeLength = outlineList.filter(type => type.mapType == 'polygon').length;
                    if (OutlineTypeLength > 0) {
                        let Polylist = outlineList.filter(type => type.mapType == 'polygon');
                        const Coord = Polylist[0].coordinates.split(':')[0]
                        this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
                    }
                }
            }
        }
    }

    GoogleMapExample = withGoogleMap(props => (
        <GoogleMap center={this.state.center} zoom={this.state.zoomlevel}
            defaultOptions={{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                mapTypeId: this.state.mapTypeId,
                mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "terrain"],
                    style: window.google.maps.MapTypeControlStyle.DEFAULT,
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                },
                disableDoubleClickZoom: true,
            }}
        >
            {props.children}
        </GoogleMap >));

    handleModal() {
        this.setState({ show: !this.state.modalshow })
    }

    onPolygonComplete = Polygon => {
        this.state.UnsavedShapes = Polygon;
        this.props.addMapItem(Polygon, 'polygon')
    }

    onMarkerComplete = Marker => {
        this.state.UnsavedShapes = Marker;
        this.props.addMapItem(Marker, 'marker')

    }

    onPolylineComplete = Polyline => {
        this.state.UnsavedShapes = Polyline;
        this.props.addMapItem(Polyline, 'polyline')
    }

    onCircleComplete = Circle => {
        this.state.UnsavedShapes = Circle;
        this.props.addMapItem(Circle, 'circle')
    }

    onRectangleComplete = Rectangle => {
        this.state.UnsavedShapes = Rectangle;
        this.props.addMapItem(Rectangle, 'rectangle')
    }

    onMarkerClick(e, itemId) {
        this.props.editMapItem(e, itemId, false);
    };

    onPolygonClick(e, itemId) {
        this.props.editMapItem(e, itemId, false);
    };

    onLULabelMouseEnter(e, centerpolygon, items) {

        this.setState({
            activeInfoLatLan: centerpolygon,
            activePolygon: items,
            showingLUInfoWindow: true,
        });
    };

    onLULabelMouseLeave() {
        this.setState({
            activeInfoLatLan: null,
            activePolygon: {},
            showingLUInfoWindow: false,
        });
    };

    onPolylineClick(e, itemId) {
        this.props.editMapItem(e, itemId, false);
    };

    onCircleClick(e, itemId) {
        this.props.editMapItem(e, itemId, false);
    };

    onRectangleClick(e, itemId) {
        this.props.editMapItem(e, itemId, false);
    };

    onMarkerDragEnd(e, itemId) {
        this.props.editMapItem(e, itemId, true);
    }

    handleOverlayComplete(e) {
    };

    onLMUCentreClick(e, itemId) {
        this.props.editLMUCenter(e, itemId, false);
    };

    onLMUCentreDragEnd(e, itemId) {
        this.props.editLMUCenter(e, itemId, true);
    }

    onCenterChanged(itemId) {
        let items = this.state.circle;
        let item = items.filter(type => type._reactInternals.key == itemId)[0];
        if (item != null) {
            const value = item.state.__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.getCenter();
            this.props.handleMapItemEdited(itemId, 'cricleCenterChanges', value);
        }
    }

    onRadiusChanged(itemId) {
        let items = this.state.circle;
        let item = items.filter(type => type._reactInternals.key == itemId)[0];
        if (item != null) {
            const value = item.state.__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.getRadius();
            this.props.handleMapItemEdited(itemId, 'cricleRadiusChanges', value);
        }
    }

    onBoundsChanged(itemId) {
        let items = this.state.rectangle;
        let item = items.filter(type => type._reactInternals.key == itemId)[0];
        if (item != null) {
            const value = item.state.__SECRET_RECTANGLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.getBounds();
            this.props.handleMapItemEdited(itemId, 'rectangleBoundsChanged', value);
        }
    }

    onPolygonChanged(event, itemId, Model) {
        if (event.path != undefined) {
            let items = Model.state.polygon;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                const value = item.state.__SECRET_POLYGON_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.getPaths().getArray()[0];
                this.props.handleMapItemEdited(itemId, 'polygonBoundsChanged', value);
            }
        }
    }

    onPolylineChanged(event, itemId, Model) {
        if (event.edge != undefined || event.vertex != undefined) {
            let items = Model.state.polyline;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                const value = item.state.__SECRET_POLYLINE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.getPath();
                this.props.handleMapItemEdited(itemId, 'polylineBoundsChanged', value);
            }
        }
    }

    pinSymbol(color) {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: color,
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 1,
            scale: 1,
            labelOrigin: new window.google.maps.Point(20, 5)
        }
    }
    pinSymbolBlank(color) {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: color,
            fillOpacity: 0,
            strokeColor: '#000',
            strokeWeight: 0,
            scale: 0,
            labelOrigin: new window.google.maps.Point(20, 5)
        }
    }

    updateRefValue(type, refValue, itemId) {
        if (type == 'cricle') {
            let items = this.state.circle;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                this.state.circle = items.filter(type => type._reactInternals.key != itemId);
            }
            this.state.circle.push(refValue)
        }
        else if (type == 'rectangle') {
            let items = this.state.rectangle;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                this.state.rectangle = items.filter(type => type._reactInternals.key != itemId);
            }
            this.state.rectangle.push(refValue)
        }
        else if (type == 'polygon') {
            let items = this.state.polygon;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                this.state.polygon = items.filter(type => type._reactInternals.key != itemId);
            }
            this.state.polygon.push(refValue)
        }
        else if (type == 'polyline') {
            let items = this.state.polyline;
            let item = items.filter(type => type._reactInternals.key == itemId)[0];
            if (item != null) {
                this.state.polyline = items.filter(type => type._reactInternals.key != itemId);
            }
            this.state.polyline.push(refValue)
        }
    }

    onShowInfoWindow = (e, item) => {
        this.setState({
            infoWindowPosition: e.latLng,
            showingInfoWindow: true,
            infoItem: item
        });
    }

    onInfoWindowClose = () => {
        this.setState({
            infoWindowPosition: {},
            infoItem: {},
            showingInfoWindow: false
        });
    }
    onShowMarkerWindow = (e, item, positions) => {
        this.setState({
            infoWindowPosition: positions,
            showingMarkerWindow: true,
            infoItem: item
        });
    }

    onMarkerWindowClose = () => {
        this.setState({
            infoWindowPosition: null,
            infoItem: {},
            showingMarkerWindow: false
        });
    }

    static renderFarmCenter(google, isOutlineUpload, isFarmCenterReq, outlineList, Model, isFarmCenterEditable, selectedLMUId, highlighteditemId) {
        if (selectedLMUId == 0) {
            return (
                isOutlineUpload != true && isFarmCenterReq == true ? (outlineList.filter(type => type.mapType == "marker").map((FarmCenter) => {
                    const animation = highlighteditemId == FarmCenter.itemId && highlighteditemId != undefined ? window.google.maps.Animation.BOUNCE : '';
                    if (isFarmCenterEditable) {
                        return <Marker key={FarmCenter.id}
                            position={{ lat: parseFloat(FarmCenter.coordinates.split(',')[0]), lng: parseFloat(FarmCenter.coordinates.split(',')[1]) }}
                            onClick={(e) => Model.onMarkerClick(e, FarmCenter.itemId)}
                            draggable={true}
                            /*icon={Model.pinSymbol('red')}*/
                            icon={{
                                url: FarmCenter.iconUrl,
                                scaledSize: new google.maps.Size(30, 30), labelOrigin: new google.maps.Point(-2, -2)
                            }}
                            animation={animation}
                            onDragEnd={(e) => Model.onMarkerDragEnd(e, FarmCenter.itemId)}
                            label={{ text: FarmCenter.name, color: '#fff' }}
                            onRightClick={(e) => Model.onShowInfoWindow(e, FarmCenter)}
                        />
                    }
                    else {
                        return <Marker key={FarmCenter.id}
                            /*icon={Model.pinSymbol('red')}*/
                            icon={{
                                url: FarmCenter.iconUrl,
                                scaledSize: new google.maps.Size(30, 30), labelOrigin: new google.maps.Point(-2, -2)
                            }}
                            animation={animation}
                            position={{ lat: parseFloat(FarmCenter.coordinates.split(',')[0]), lng: parseFloat(FarmCenter.coordinates.split(',')[1]) }}
                            onClick={(e) => Model.onMarkerClick(e, FarmCenter.itemId)}
                            onRightClick={(e) => Model.onShowInfoWindow(e, FarmCenter)}
                            label={{
                                text: FarmCenter.name, color: '#fff'
                            }}
                        />
                    }
                }
                )) : ''
            );
        }
    }

    static renderDrawingManager(google, polytype, Model) {
        return <DrawingManager
            defaultDrawingMode={"none"}
            drawingMode={
                polytype == 'marker' ?
                    google.maps.drawing.OverlayType.MARKER : polytype == 'polygon' ?
                        google.maps.drawing.OverlayType.POLYGON : polytype == 'polyline' ?
                            google.maps.drawing.OverlayType.POLYLINE : polytype == 'circle' ?
                                google.maps.drawing.OverlayType.CIRCLE : polytype == 'rectangle' ?
                                    google.maps.drawing.OverlayType.RECTANGLE : null
            }
            defaultOptions={{
                drawingControl: false,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ["marker", "polyline", "polygon", "circle", "rectangle"]
                },
                polygonOptions: {
                    strokeColor: 'blue',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: 'Transparent',
                    fillOpacity: 0
                }
            }}
            onOverlayComplete={Model.handleOverlayComplete}
            onPolygonComplete={Model.onPolygonComplete} onMarkerComplete={Model.onMarkerComplete} onPolylineComplete={Model.onPolylineComplete}
            onCircleComplete={Model.onCircleComplete} onRectangleComplete={Model.onRectangleComplete} />
    }

    /*Outline*/
    static renderOutline(isOutlineUpload, outlineList, uploadedShapes, Model, isFarmOutlineEditable, selectedLMUId, isOutlinePage, highlighteditemId) {
        return (
            isOutlineUpload != true ?
                (
                    outlineList.filter(type => type.mapType == "polygon").map(FarmOutline => {
                        const Coordinates = FarmOutline.coordinates.split(':');
                        const positions = [];
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })
                        if (isOutlinePage) {
                            const isOutline = FarmOutline.isFarmOutline;
                            let zIndex = 10;
                            if (isOutline) {
                                zIndex = 5;
                            }
                            const strokeWeight = highlighteditemId == FarmOutline.itemId ? 8 : 2;
                            return <Polygon
                                key={FarmOutline.itemId}
                                ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, FarmOutline.itemId) }}
                                path={positions}
                                onClick={(e) => Model.onPolygonClick(e, FarmOutline.itemId)}
                                onMouseUp={(e) => Model.onPolygonChanged(e, FarmOutline.itemId, Model)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, FarmOutline)}
                                options={
                                    {
                                        fillColor: "Transparent",
                                        fillOpacity: 0,
                                        strokeColor: "red",
                                        strokeOpacity: 1,
                                        strokeWeight: strokeWeight,
                                        zIndex: zIndex,
                                        editable: isFarmOutlineEditable
                                    }
                                }
                            />
                        }
                        else {
                            return <Polygon
                                key={FarmOutline.itemId}
                                path={positions}
                                options={
                                    {
                                        fillColor: "Transparent",
                                        fillOpacity: 0,
                                        strokeColor: "red",
                                        strokeOpacity: 1,
                                        strokeWeight: 2
                                    }
                                }
                            />
                        }
                    })
                )
                : (
                    uploadedShapes.map((OutlineItem) => {
                        const Coordinates = OutlineItem.coordinates.split(':');
                        const positions = [];
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })

                        const polygon = <Polygon
                            key={uuid()}
                            path={positions}
                            options={
                                {
                                    fillColor: "Transparent",
                                    fillOpacity: 0,
                                    strokeColor: "red",
                                    strokeOpacity: 1,
                                    strokeWeight: 2
                                }
                            }
                        />
                        return polygon;
                    })
                )
        );
    }

    /*Non editable LMU*/
    static renderLMUItemPolygon(isLMUPage, itemList, selectedLMUId, isSmallEnterpriseShow) {

        if (selectedLMUId > 0 && !isLMUPage) {
            return (
                itemList.filter(type => type.mapMarkerType == "polygon" && type.id == selectedLMUId).map(items => {
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    const color = items.colour;
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })
                    var bounds = new window.google.maps.LatLngBounds();
                    for (var i = 0; i < positions.length; i++) {
                        bounds.extend(positions[i]);
                    }
                    var centerpositions = bounds.getCenter();
                    const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                    return <Polygon
                        key={uuid()}
                        path={positions}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.4,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2
                            }
                        }
                    />
                })
            )
        }
        else if (isSmallEnterpriseShow) {
            return (
                itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    const color = items.colour;
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })
                    var bounds = new window.google.maps.LatLngBounds();
                    for (var i = 0; i < positions.length; i++) {
                        bounds.extend(positions[i]);
                    }
                    var centerpositions = bounds.getCenter();
                    const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                    return <Polygon
                        key={uuid()}
                        path={positions}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.4,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2
                            }
                        }
                    />
                })
            )
        }

    }

    static renderLMUChildItemPolygon = (isLMUPage, itemList, selectedLMUId) => {

        if (selectedLMUId > 0 && !isLMUPage) {
            return (
                itemList.filter(type => type.mapMarkerType == "polygon" && type.lmuId == selectedLMUId).map(items => {
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    const color = items.colour;
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })

                    return <Polygon
                        key={uuid()}
                        path={positions}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.4,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2
                            }
                        }
                    />
                })
            )
        }
    }

    /*Items*/
    static renderItemPolygon(Model, itemList, isLMUPage, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isLUUploadPage, isRiskmapPage, isFarmCenterReq, isAllFarmSummary, showLabel) {
        if (isLUUploadPage == true) {
            return (
                itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    const color = items.colour;
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })

                    const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                    const fillOpacity = highlighteditemId == items.itemId ? 0 : 0.2;
                    return <Polygon
                        key={items.itemId}
                        ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                        path={positions}
                        onClick={(e) => Model.onPolygonClick(e, items.itemId)}
                        onMouseUp={(e) => Model.onPolygonChanged(e, items.itemId, Model)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: fillOpacity,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: strokeWeight,
                                editable: isItemsEditable
                            }
                        }
                    />
                })
            )
        }
        else if (isLMUPage == true) {
            if (selectedLMUId > 0) {
                return (
                    itemList.filter(type => type.mapMarkerType == "polygon" && type.id == selectedLMUId).map(items => {
                        const Coordinates = items.mapCoordinates.split(':');
                        const CenterPin = items.lmuItems[0].centerCoordinates;
                        const centerPositions = CenterPin != null ? { lat: parseFloat(CenterPin.split(',')[0]), lng: parseFloat(CenterPin.split(',')[1]) } : null;
                        const positions = [];
                        const color = items.colour;
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })
                        var bounds = new window.google.maps.LatLngBounds();
                        for (var i = 0; i < positions.length; i++) {
                            bounds.extend(positions[i]);
                        }
                        var centerpositions = bounds.getCenter();
                        const strokeWeight = highlighteditemId == items.id ? 8 : 2;
                        const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                        return (
                            <>
                                <Polygon
                                    key={items.id}
                                    ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.id) }}
                                    path={positions}
                                    onClick={(e) => Model.onPolygonClick(e, items.id)}
                                    onMouseUp={(e) => Model.onPolygonChanged(e, items.id, Model)}
                                    onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                    options={
                                        {
                                            fillColor: color,
                                            fillOpacity: 0.2,
                                            strokeColor: color,
                                            strokeOpacity: 1,
                                            strokeWeight: strokeWeight,
                                            editable: isItemsEditable
                                        }
                                    }
                                    onMouseOver={(e) => !isItemsEditable && Model.onLULabelMouseEnter(e, centerpositions, items)}
                                    onMouseOut={(e) => !isItemsEditable && Model.onLULabelMouseLeave()}
                                />
                                {
                                    CenterPin != null && <Marker key={items.id + 'center'}
                                        position={centerPositions}
                                        icon={Model.pinSymbol(color)}
                                        onClick={(e) => Model.onLMUCentreClick(e, items.id)}
                                        draggable={isItemsEditable}
                                        animation={window.google.maps.Animation.DROP}
                                        onDragEnd={(e) => Model.onLMUCentreDragEnd(e, items.id)}
                                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                    />
                                }
                                {
                                    CenterPin == null && <Marker key={"polygonMarker_" + items.itemId}
                                        position={centerpositions}
                                        icon={Model.pinSymbolBlank(color)}
                                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                    />
                                }
                            </>
                        )
                    })
                )
            }
            else {
                return (
                    itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                        const Coordinates = items.mapCoordinates.split(':');
                        const CenterPin = items.lmuItems[0].centerCoordinates;
                        const centerPositions = CenterPin != null ? { lat: parseFloat(CenterPin.split(',')[0]), lng: parseFloat(CenterPin.split(',')[1]) } : null;
                        const positions = [];
                        const color = items.colour;
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })
                        var bounds = new window.google.maps.LatLngBounds();
                        for (var i = 0; i < positions.length; i++) {
                            bounds.extend(positions[i]);
                        }
                        var centerpositions = bounds.getCenter();
                        const strokeWeight = highlighteditemId === items.id ? 8 : 2;
                        const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                        if (items.isConnectedToAnotherLMU != true) {
                            return (<><Polygon
                                key={items.id}
                                ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.id) }}
                                path={positions}
                                onClick={(e) => Model.onPolygonClick(e, items.id)}
                                onMouseUp={(e) => Model.onPolygonChanged(e, items.id, Model)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                options={
                                    {
                                        fillColor: color,
                                        fillOpacity: 0.2,
                                        strokeColor: color,
                                        strokeOpacity: 1,
                                        strokeWeight: strokeWeight,
                                        editable: isItemsEditable
                                    }
                                }
                                onMouseOver={(e) => !isItemsEditable && Model.onLULabelMouseEnter(e, centerpositions, items)}
                                onMouseOut={(e) => !isItemsEditable && Model.onLULabelMouseLeave()}
                            />
                                {CenterPin != null && <Marker key={items.id + 'center'}
                                    position={centerPositions}
                                    icon={Model.pinSymbol(color)}
                                    onClick={(e) => Model.onLMUCentreClick(e, items.id)}
                                    draggable={isItemsEditable}
                                    animation={window.google.maps.Animation.DROP}
                                    onDragEnd={(e) => Model.onLMUCentreDragEnd(e, items.id)}
                                    label={{ text: items.name, color: '#fff' }}
                                />
                                }
                                {
                                    CenterPin == null && <Marker key={"polygonMarker_" + items.itemId}
                                        position={centerpositions}
                                        icon={Model.pinSymbolBlank(color)}
                                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                    />
                                }
                            </>
                            )
                        }
                        else {
                            return (<><Polygon
                                key={items.id}
                                ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.id) }}
                                path={positions}
                                onClick={(e) => Model.onPolygonClick(e, items.id)}
                                onMouseUp={(e) => Model.onPolygonChanged(e, items.id, Model)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                options={
                                    {
                                        fillColor: color,
                                        fillOpacity: 0.2,
                                        strokeColor: color,
                                        strokeOpacity: 1,
                                        strokeWeight: strokeWeight,
                                        editable: isItemsEditable
                                    }
                                }
                                onMouseOver={(e) => !isItemsEditable && Model.onLULabelMouseEnter(e, centerpositions, items)}
                                onMouseOut={(e) => !isItemsEditable && Model.onLULabelMouseLeave()}
                            />
                                <Marker key={"polygonMarker_" + items.itemId}
                                    position={centerpositions}
                                    icon={Model.pinSymbolBlank(color)}
                                    label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                />
                            </>

                            )
                        }
                    })

                )
            }
        }
        else if (isAllFarmSummary == true)
        {
            return (
                itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    const color = items.colour;
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })
                    const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';
                    const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;

                    var bounds = new window.google.maps.LatLngBounds();
                    for (var i = 0; i < positions.length; i++) {
                        bounds.extend(positions[i]);
                    }

                    var centerpositions = bounds.getCenter();
                    const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                    return (<><Polygon
                        key={items.itemId}
                        ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                        path={positions}
                        onClick={(e) => Model.onPolygonClick(e, items.itemId)}
                        onMouseUp={(e) => Model.onPolygonChanged(e, items.itemId, Model)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.4,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: strokeWeight,
                                editable: isItemsEditable
                            }
                        }
                    />{
                            isFarmCenterReq == true &&
                            <Marker key={"polygonMarker_" + items.itemId}
                                position={centerpositions}
                                icon={{
                                    url: items.iconUrl,
                                    scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                }}
                                label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                onMouseOut={(e) => Model.onMarkerWindowClose()}
                            />
                        }
                    </>)
                })
            )
        }
        else {
            if (selectedLMUId > 0) {
                const selectedLMUList = LMUItemList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
                return (
                    selectedLMUList.map(lmu => {
                        return (
                            itemList.filter(type => type.mapMarkerType == "polygon" && type.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0).map(items => {
                                const Coordinates = items.mapCoordinates.split(':');
                                const positions = [];
                                const color = items.colour;
                                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                                    positions.push(pos);
                                })
                                var bounds = new window.google.maps.LatLngBounds();
                                for (var i = 0; i < positions.length; i++) {
                                    bounds.extend(positions[i]);
                                }
                                var centerpositions = bounds.getCenter();
                                const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                                const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                                return (<><Polygon
                                    key={items.itemId}
                                    ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                                    path={positions}
                                    onClick={(e) => Model.onPolygonClick(e, items.itemId)}
                                    onMouseUp={(e) => Model.onPolygonChanged(e, items.itemId, Model)}
                                    onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                    options={
                                        {
                                            fillColor: color,
                                            fillOpacity: 0.4,
                                            strokeColor: color,
                                            strokeOpacity: 1,
                                            strokeWeight: strokeWeight,
                                            editable: isItemsEditable
                                        }
                                    }
                                />
                                    {
                                        isRiskmapPage == false &&
                                        <Marker key={"polygonMarker_" + items.itemId}
                                            position={centerpositions}
                                            icon={{
                                                url: items.iconUrl,
                                                scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                            }}
                                            label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                            onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                            onMouseOut={(e) => Model.onMarkerWindowClose()}
                                        />
                                    }
                                </>)
                            })
                        )
                    })
                )
            }
            else {
                return (
                    itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                        const Coordinates = items.mapCoordinates.split(':');
                        const positions = [];
                        const color = items.colour;
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })
                        const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';
                        const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;

                        var bounds = new window.google.maps.LatLngBounds();
                        for (var i = 0; i < positions.length; i++) {
                            bounds.extend(positions[i]);
                        }

                        var centerpositions = bounds.getCenter();
                        const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                        return (<><Polygon
                            key={items.itemId}
                            ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                            path={positions}
                            onClick={(e) => Model.onPolygonClick(e, items.itemId)}
                            onMouseUp={(e) => Model.onPolygonChanged(e, items.itemId, Model)}
                            onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                            options={
                                {
                                    fillColor: color,
                                    fillOpacity: 0.4,
                                    strokeColor: color,
                                    strokeOpacity: 1,
                                    strokeWeight: strokeWeight,
                                    editable: isItemsEditable
                                }
                            }
                        />{
                                isRiskmapPage == true &&
                                <Marker key={"risk_" + items.itemId}
                                    position={centerpositions}
                                    draggable={false}
                                    icon={{
                                        url: Model.getRiskMapIcon(ItemRiskDetails),


                                    }}
                                />
                            }
                            {
                                isRiskmapPage == false &&
                                <Marker key={"polygonMarker_" + items.itemId}
                                    position={centerpositions}
                                    icon={{
                                        url: items.iconUrl,
                                        scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                    }}
                                    label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                    onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                    onMouseOut={(e) => Model.onMarkerWindowClose()}
                                />
                            }
                        </>)
                    })
                )
            }
        }
    }

    static renderLayerPolygon = (Model, itemList, isItemsEditable) => {
        return (
            itemList.filter(type => type.mapMarkerType == "polygon").map(items => {
                const Coordinates = items.mapCoordinates.split(':');
                const positions = [];
                const color = items.colour;
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const strokeWeight = 3;

                return (<><Polygon
                    key={items.itemId}
                    ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                    path={positions}
                    onClick={(e) => Model.onPolygonClick(e, items.itemId)}
                    onMouseUp={(e) => Model.onPolygonChanged(e, items.itemId, Model)}
                    onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                    options={
                        {
                            fillColor: color,
                            fillOpacity: 0.2,
                            strokeColor: color,
                            strokeOpacity: 1,
                            strokeWeight: strokeWeight,
                            editable: isItemsEditable
                        }
                    }
                />
                </>)
            })
        )
    }

    static renderChildLmu = (Model, itemList, selectedLMUId, isItemsEditable, showLabel) => {
        return (
            itemList.filter(type => type.mapMarkerType == "polygon" && type.lmuId == selectedLMUId).map(items => {
                const Coordinates = items.mapCoordinates.split(':');
                const positions = [];
                const color = items.colour;
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                var bounds = new window.google.maps.LatLngBounds();
                for (var i = 0; i < positions.length; i++) {
                    bounds.extend(positions[i]);
                }
                var centerpositions = bounds.getCenter();
                const positions1 = { lat: parseFloat(centerpositions.lat()) + 0.00040, lng: parseFloat(centerpositions.lng()) }
                return (<>
                    <Polygon
                        key={items.id}
                        ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.id) }}
                        path={positions}
                        onClick={(e) => Model.onPolygonClick(e, items.id)}
                        onMouseUp={(e) => Model.onPolygonChanged(e, items.id, Model)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.2,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2,
                                editable: isItemsEditable
                            }
                        }
                        onMouseOver={(e) => !isItemsEditable && Model.onLULabelMouseEnter(e, centerpositions, items)}
                        onMouseOut={(e) => !isItemsEditable && Model.onLULabelMouseLeave()}
                    />
                    <Marker key={"polygonMarker_" + items.itemId}
                        position={centerpositions}
                        icon={Model.pinSymbolBlank(color)}
                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                    />
                </>
                )

            })
        )
    }

    static renderItemMarker = (Model, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage, showLabel) => {
        if (selectedLMUId > 0) {
            const selectedLMUList = LMUItemList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
            return (
                selectedLMUList.map(lmu => {
                    return (
                        itemList.filter(type => type.mapMarkerType == "marker" && type.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0).map(items => {
                            const positions = { lat: parseFloat(items.mapCoordinates.split(',')[0]), lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                            const positions1 = { lat: parseFloat(items.mapCoordinates.split(',')[0]) + 0.00040, lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                            const color = items.colour;
                            const animation = highlighteditemId == items.itemId && highlighteditemId != undefined ? window.google.maps.Animation.BOUNCE : '';
                            return <Marker key={items.itemId}
                                position={positions}
                                /*icon={pinSymbol(color)}*/
                                icon={{
                                    url: items.iconUrl,
                                    scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                }}
                                onClick={(e) => Model.onMarkerClick(e, items.itemId)}
                                draggable={isItemsEditable}
                                animation={animation}
                                onDragEnd={(e) => Model.onMarkerDragEnd(e, items.itemId)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                onMouseOut={(e) => Model.onMarkerWindowClose()}
                            />
                        })
                    )
                })
            )
        }
        else {
            return (
                itemList.filter(type => type.mapMarkerType == "marker").map(items => {
                    const positions = { lat: parseFloat(items.mapCoordinates.split(',')[0]), lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                    const positions1 = { lat: parseFloat(items.mapCoordinates.split(',')[0]) + 0.00040, lng: parseFloat(items.mapCoordinates.split(',')[1])}
                    const color = items.colour;
                    const animation = highlighteditemId == items.itemId && highlighteditemId != undefined ? window.google.maps.Animation.BOUNCE : ''
                    const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';

                    return (<><Marker key={items.itemId}
                        position={positions}
                        /*icon={pinSymbol(color)}*/
                        icon={{
                            url: items.iconUrl,
                            scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                        }}
                        onClick={(e) => Model.onMarkerClick(e, items.itemId)}
                        draggable={isItemsEditable}
                        animation={animation}
                        onDragEnd={(e) => Model.onMarkerDragEnd(e, items.itemId)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                        onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                        onMouseOut={(e) => Model.onMarkerWindowClose()}
                    />
                        {
                            isRiskmapPage == true &&
                            <Marker key={"risk_" + items.itemId}
                                position={positions}
                                draggable={false}
                                icon={{
                                    url: Model.getRiskMapIcon(ItemRiskDetails),
                                    anchor: new window.google.maps.Point(16, 72)

                                }}
                            />
                        }
                    </>
                    )

                })
            )

        }
    }

    static renderItemPolyline = (Model, itemList, showGISLayer, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage,showLabel) => {
        if (!showGISLayer) {
            if (selectedLMUId > 0) {
                const selectedLMUList = LMUItemList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
                return (
                    selectedLMUList.map(lmu => {
                        return (
                            itemList.filter(type => type.mapMarkerType == "polyline" && type.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0).map(items => {
                                const color = items.colour;
                                const Coordinates = items.mapCoordinates.split(':');
                                const positions = [];
                                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                                    positions.push(pos);
                                })
                                const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                                const positions1 = { lat: parseFloat(positions[0].lat) + 0.00040, lng: parseFloat(positions[0].lng) }
                                return (<><Polyline
                                    key={items.itemId}
                                    ref={(ref) => { ref != null && Model.updateRefValue('polyline', ref, items.itemId) }}
                                    path={positions}
                                    onClick={(e) => Model.onPolylineClick(e, items.itemId)}
                                    onMouseUp={(e) => Model.onPolylineChanged(e, items.itemId, Model)}
                                    onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                    options={
                                        {
                                            fillColor: "Transparent",
                                            fillOpacity: 0,
                                            strokeColor: color,
                                            strokeOpacity: 1,
                                            strokeWeight: strokeWeight,
                                            editable: isItemsEditable
                                        }
                                    }
                                />
                                    {
                                        isRiskmapPage == false &&
                                        <Marker key={"polylineMarker_" + items.itemId}
                                            position={positions[0]}
                                            icon={{
                                                url: items.iconUrl,
                                                scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                            }}
                                            label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                            onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                            onMouseOut={(e) => Model.onMarkerWindowClose()}
                                        />
                                    }
                                </>)
                            })
                        )
                    })
                )
            }
            else {
                return (
                    itemList.filter(type => type.mapMarkerType == "polyline").map(items => {
                        const color = items.colour;
                        const Coordinates = items.mapCoordinates.split(':');
                        const positions = [];
                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })

                        const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                        const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';
                        const positions1 = { lat: parseFloat(positions[0].lat) + 0.00040, lng: parseFloat(positions[0].lng) }
                        return (<><Polyline
                            key={items.itemId}
                            ref={(ref) => { ref != null && Model.updateRefValue('polyline', ref, items.itemId) }}
                            path={positions}
                            onClick={(e) => Model.onPolylineClick(e, items.itemId)}
                            onMouseUp={(e) => Model.onPolylineChanged(e, items.itemId, Model)}
                            onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                            options={
                                {
                                    fillColor: "Transparent",
                                    fillOpacity: 0,
                                    strokeColor: color,
                                    strokeOpacity: 1,
                                    strokeWeight: strokeWeight,
                                    editable: isItemsEditable
                                }
                            }
                        />
                            {
                                isRiskmapPage == true &&
                                <Marker key={"risk_" + items.itemId}
                                    position={positions[0]}
                                    draggable={false}
                                    icon={{
                                        url: Model.getRiskMapIcon(ItemRiskDetails),


                                    }}
                                />
                            }
                            {
                                isRiskmapPage == false &&
                                <Marker key={"polylineMarker_" + items.itemId}
                                    position={positions[0]}
                                    icon={{
                                        url: items.iconUrl,
                                        scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                    }}
                                    label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                    onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                    onMouseOut={(e) => Model.onMarkerWindowClose()}
                                />
                            }
                        </>
                        )
                    })
                )
            }
        }
        else {
            return (
                itemList.filter(type => type.mapMarkerType == "polyline").map(items => {
                    const color = items.colour;
                    const Coordinates = items.mapCoordinates.split(':');
                    const positions = [];
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })

                    return <Polyline
                        key={items.itemId}
                        path={positions}
                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                        options={
                            {
                                fillColor: "Transparent",
                                fillOpacity: 0,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2,
                            }
                        }
                    />
                })
            )
        }
    }

    static renderItemCircle = (Model, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage,showLabel) => {
        if (selectedLMUId > 0) {
            const selectedLMUList = LMUItemList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
            return (
                selectedLMUList.map(lmu => {
                    return (
                        itemList.filter(type => type.mapMarkerType == "circle" && type.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0).map(items => {
                            const positions = { lat: parseFloat(items.mapCoordinates.split(',')[0]), lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                            const positions1 = { lat: parseFloat(items.mapCoordinates.split(',')[0]) + 0.00040, lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                            const color = items.colour;
                            const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                            const options = {
                                strokeColor: color,
                                strokeOpacity: 0.8,
                                strokeWeight: strokeWeight,
                                fillColor: color,
                                fillOpacity: 0.35,
                                clickable: true,
                                draggable: false,
                                editable: isItemsEditable,
                                visible: true,
                                radius: items.radius,
                                zIndex: 1
                            }

                            return (<><Circle key={items.itemId}
                                ref={(ref) => { ref != null && Model.updateRefValue('cricle', ref, items.itemId) }}
                                center={positions}
                                radius={items.radius}
                                options={options}
                                onClick={(e) => Model.onCircleClick(e, items.itemId)}
                                onCenterChanged={() => Model.onCenterChanged(items.itemId)}
                                onRadiusChanged={() => Model.onRadiusChanged(items.itemId)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                                label={{ text: items.name, color: '#fff' }}
                            />
                                {
                                    isRiskmapPage == false &&
                                    <Marker key={"circleMarker_" + items.itemId}
                                        position={positions}
                                        icon={{
                                            url: items.iconUrl,
                                            scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                        }}
                                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                        onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                        onMouseOut={(e) => Model.onMarkerWindowClose()}
                                    />
                                }
                            </>)
                        })
                    )
                })
            )
        }
        else {
            return (
                itemList.filter(type => type.mapMarkerType == "circle").map(items => {
                    const positions = { lat: parseFloat(items.mapCoordinates.split(',')[0]), lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                    const positions1 = { lat: parseFloat(items.mapCoordinates.split(',')[0]) + 0.00040, lng: parseFloat(items.mapCoordinates.split(',')[1]) }
                    const color = items.colour;
                    const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                    const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';
                    const options = {
                        strokeColor: color,
                        strokeOpacity: 0.8,
                        strokeWeight: strokeWeight,
                        fillColor: color,
                        fillOpacity: 0.35,
                        clickable: true,
                        draggable: false,
                        editable: isItemsEditable,
                        visible: true,
                        radius: items.radius,
                        zIndex: 1
                    }

                    return (<><Circle key={items.itemId}
                        ref={(ref) => { ref != null && Model.updateRefValue('cricle', ref, items.itemId) }}
                        center={positions}
                        radius={items.radius}
                        options={options}
                        onClick={(e) => Model.onCircleClick(e, items.itemId)}
                        onCenterChanged={() => Model.onCenterChanged(items.itemId)}
                        onRadiusChanged={() => Model.onRadiusChanged(items.itemId)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                    />
                        {
                            isRiskmapPage == true &&
                            <Marker key={"risk_" + items.itemId}
                                position={positions}
                                draggable={false}
                                icon={{
                                    url: Model.getRiskMapIcon(ItemRiskDetails),
                                }}
                            />
                        }
                        {
                            isRiskmapPage == false &&
                            <Marker key={"circleMarker_" + items.itemId}
                                position={positions}
                                icon={{
                                    url: items.iconUrl,
                                    scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                }}
                                label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                onMouseOut={(e) => Model.onMarkerWindowClose()}
                            />
                        }
                    </>)
                })
            )
        }
    }

    static renderItemRectangle = (Model, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage,showLabel) => {
        if (selectedLMUId > 0) {
            const selectedLMUList = LMUItemList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
            return (
                selectedLMUList.map(lmu => {
                    return (
                        itemList.filter(type => type.mapMarkerType == "rectangle" && type.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0).map(items => {
                            const northEastCO = items.mapCoordinates.split(':')[0];
                            const southWestCo = items.mapCoordinates.split(':')[1];
                            const bounds = {
                                north: parseFloat(northEastCO.split(',')[0]),
                                south: parseFloat(southWestCo.split(',')[0]),
                                east: parseFloat(northEastCO.split(',')[1]),
                                west: parseFloat(southWestCo.split(',')[1])
                            }
                            const color = items.colour;
                            const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                            const options = {
                                strokeColor: color,
                                strokeOpacity: 0.8,
                                strokeWeight: strokeWeight,
                                fillColor: color,
                                fillOpacity: 0.35,
                                clickable: true,
                                draggable: false,
                                editable: isItemsEditable,
                                visible: true,
                                zIndex: 1
                            }
                            const temprect = new window.google.maps.Rectangle({
                                bounds: bounds
                            });
                            var positions = temprect.getBounds().getCenter();
                            const positions1 = { lat: parseFloat(positions.lat()) + 0.00040, lng: parseFloat(positions.lng()) }
                            return (<><Rectangle key={items.itemId}
                                ref={(ref) => { ref != null && Model.updateRefValue('rectangle', ref, items.itemId) }}
                                options={options}
                                bounds={bounds}
                                onClick={(e) => Model.onRectangleClick(e, items.itemId)}
                                onBoundsChanged={() => Model.onBoundsChanged(items.itemId)}
                                onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                            />
                                {
                                    isRiskmapPage == false &&
                                    <Marker key={"rectangleMarker_" + items.itemId}
                                        position={positions}
                                        icon={{
                                            url: items.iconUrl,
                                            scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                        }}
                                        label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                        onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                        onMouseOut={(e) => Model.onMarkerWindowClose()}
                                    />
                                }
                            </>)
                        })
                    )
                })
            )
        }
        else {
            return (
                itemList.filter(type => type.mapMarkerType == "rectangle").map(items => {
                    const northEastCO = items.mapCoordinates.split(':')[0];
                    const southWestCo = items.mapCoordinates.split(':')[1];
                    const bounds = {
                        north: parseFloat(northEastCO.split(',')[0]),
                        south: parseFloat(southWestCo.split(',')[0]),
                        east: parseFloat(northEastCO.split(',')[1]),
                        west: parseFloat(southWestCo.split(',')[1])
                    }
                    const color = items.colour;
                    const strokeWeight = highlighteditemId == items.itemId ? 8 : 2;
                    const ItemRiskDetails = isRiskmapPage == true ? items.itemActions.riskResult : '';

                    const temprect = new window.google.maps.Rectangle({
                        bounds: bounds
                    });
                    var positions = temprect.getBounds().getCenter();
                    const positions1 = { lat: parseFloat(positions.lat()) + 0.00040, lng: parseFloat(positions.lng()) }
                    const options = {
                        strokeColor: color,
                        strokeOpacity: 0.8,
                        strokeWeight: strokeWeight,
                        fillColor: color,
                        fillOpacity: 0.35,
                        clickable: true,
                        draggable: false,
                        editable: isItemsEditable,
                        visible: true,
                        zIndex: 1
                    }
                    return (<><Rectangle key={items.itemId}
                        ref={(ref) => { ref != null && Model.updateRefValue('rectangle', ref, items.itemId) }}
                        options={options}
                        bounds={bounds}
                        onClick={(e) => Model.onRectangleClick(e, items.itemId)}
                        onBoundsChanged={() => Model.onBoundsChanged(items.itemId)}
                        onRightClick={(e) => Model.onShowInfoWindow(e, items)}
                    />
                        {
                            isRiskmapPage == true &&
                            <Marker key={"risk_" + items.itemId}
                                position={positions}
                                draggable={false}
                                icon={{
                                    url: Model.getRiskMapIcon(ItemRiskDetails),
                                }}
                            />
                        }
                        {
                            isRiskmapPage == false &&
                            <Marker key={"rectangleMarker_" + items.itemId}
                                position={positions}
                                icon={{
                                    url: items.iconUrl,
                                    scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(-2, -2)
                                }}
                                label={{ text: showLabel == true ? items.name : ' ', color: '#fff' }}
                                onMouseOver={(e) => Model.onShowMarkerWindow(e, items, positions1)}
                                onMouseOut={(e) => Model.onMarkerWindowClose()}
                            />
                        }
                    </>)
                })
            )
        }
    }

    static renderluUploadPolygon = (Model, uploadedList) => {

        return (
            uploadedList.filter(type => type.mapMarkerType == "polygon").map(items => {
                const Coordinates = items.mapCoordinates.split(':');
                const positions = [];
                const color = items.colour;
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                return <Polygon
                    key={items.itemId}
                    ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.itemId) }}
                    path={positions}
                    options={
                        {
                            fillColor: color,
                            fillOpacity: 0,
                            strokeColor: color,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }
                    }
                />
            })
        )
    }

    static renderShapeUploadPolygon = (Model, uploadedList, selectedKMLShapeKeys) => {
        return (
            uploadedList.filter(type => type.mapType == "polygon").map(items => {
                if (selectedKMLShapeKeys.includes(items.key)) {
                    const Coordinates = items.coordinates.split(':');
                    const positions = [];
                    const color = "red";
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })

                    return <Polygon
                        key={items.key}
                        ref={(ref) => { ref != null && Model.updateRefValue('polygon', ref, items.key) }}
                        path={positions}
                        options={
                            {
                                fillColor: color,
                                fillOpacity: 0.1,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2,
                            }
                        }
                    />
                }
            })
        )
    }

    static renderShapeUploadPolyline = (Model, uploadedList, selectedKMLShapeKeys) => {
        return (
            uploadedList.filter(type => type.mapType == "polyline").map(items => {
                if (selectedKMLShapeKeys.includes(items.key)) {
                    const Coordinates = items.coordinates.split(':');
                    const positions = [];
                    const color = "blue";
                    Coordinates.filter(coor => coor != "").map((Coordinate) => {
                        const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                        positions.push(pos);
                    })

                    return <Polyline
                        key={items.key}
                        ref={(ref) => { ref != null && Model.updateRefValue('polyline', ref, items.key) }}
                        path={positions}
                        options={
                            {
                                fillColor: "Transparent",
                                fillOpacity: 0,
                                strokeColor: color,
                                strokeOpacity: 1,
                                strokeWeight: 2,
                            }
                        }
                    />
                }
            })
        )
    }

    static renderShapeUploadMarker = (Model, uploadedList, selectedKMLShapeKeys) => {
        return (
            uploadedList.filter(type => type.mapType == "marker").map(items => {
                if (selectedKMLShapeKeys.includes(items.key)) {
                    const positions = { lat: parseFloat(items.coordinates.split(',')[0]), lng: parseFloat(items.coordinates.split(',')[1]) }
                    return <Marker key={items.key}
                        position={positions}
                        draggable={false}
                    />
                }
            })
        )
    }

    static onLUInfoWindowClose = () => {
        this.setState({
            activeInfoLatLan: { lat: 0, lng: 0 },
            showingLUInfoWindow: !this.state.showingLUInfoWindow,
        });
    }

    static renderLUInfoWindow = (Model) => {
        return (
            <InfoWindow
                position={Model.state.activeInfoLatLan}
                visible={Model.state.showingLUInfoWindow}
            >
                <div>
                    <div>
                        <label style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>{Model.state.activePolygon.name}</label><br />
                    </div>
                </div>
            </InfoWindow>
        )
    }

    static renderMarkerWindow = (Model) => {
        const itemDetails = Model.state.infoItem;
        const name = itemDetails.name;
        return (
            <InfoWindow key={uuid()} position={Model.state.infoWindowPosition}
                visible={Model.state.showingMarkerWindow}>
                <div>
                    <div>
                        <h6>{name}</h6>
                    </div>
                </div>
            </InfoWindow>
        )
    }

    static renderInfoWindow = (Model) => {
        const itemDetails = Model.state.infoItem;
        const name = itemDetails.name;
        const maptype = itemDetails.mapType != undefined ? itemDetails.mapType : itemDetails.mapMarkerType;
        let area = 0.0, perimeter = 0.0, coordinates = '';
        if (maptype == 'polyline') {
            perimeter = itemDetails.perimeter != undefined ? itemDetails.perimeter : itemDetails.length;
        }
        else if (maptype == 'marker') {
            coordinates = itemDetails.coordinates != undefined ? itemDetails.coordinates : itemDetails.mapCoordinates;
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.perimeter != undefined ? itemDetails.perimeter : itemDetails.length;
        }

        return (
            <InfoWindow position={Model.infoWindowPosition}
                visible={Model.showingInfoWindow}
                onCloseClick={Model.onInfoWindowClose}>
                <div>
                    <h6>{name}</h6>
                    {
                        maptype == 'polyline' ?
                            perimeter > 0 && <label>Length : {perimeter} km</label> :
                            maptype == 'marker' ?
                                <label>Location : {coordinates}</label> :
                                <> {area > 0 && <label>Area : {area} Ha</label>}<br />
                                    {perimeter > 0 && <label>Perimeter : {perimeter} km</label>}</>
                    }
                </div>

            </InfoWindow>
        )
    }

    static getRiskMapIcon = (riskResult) => {
        const icons = {
            risk: {
                icon: "/images/" + "risk.ico",
            },
            enhancement: {
                icon: "/images/" + "enhancement.ico",
            },
            mitigatedrisk: {
                icon: "/images/" + "mitigatedrisk.ico",
            }
        };
        if (riskResult == "R") {
            return icons['risk'].icon;
        }
        else if (riskResult == "E") {
            return icons['enhancement'].icon;
        }
        else if (riskResult == "M") {
            return icons['mitigatedrisk'].icon;
        }
        //var risk = isMitigated == true ? "mitigatedrisk" : "risk";
        //return icons[risk].icon;

    }

    render() {
        let center = { lat: - 41.392263, lng: 174.436898 };
        const google = window.google;
        let LMUItemList = undefined;
        let GISLayerItemList = undefined;
        let GISLayerTopographyItemList = undefined;
        let GISLayerSoilItemList = undefined;
        let GISLayerWetlandItemList = undefined;
        let GISLayerStockExclusionLandSlopeItemList = undefined;
        let selectedLMUId = 0;
        const outlineList = this.props.data.outlineList;
        const itemList = this.props.data.itemList;

        /*Map Config Details */
        const mapConfigDetails = this.props.data.mapDetails
        const isFarmCenterReq = mapConfigDetails != null ? mapConfigDetails.isCenterReq : false;
        const isAllFarmSummary = mapConfigDetails != null ? (mapConfigDetails.isAllFarmSummary == undefined ? false : mapConfigDetails.isAllFarmSummary) : false;
        const isOutlinePage = mapConfigDetails != null ? mapConfigDetails.isOutlinePage : false;
        const isAllFarmDetails = mapConfigDetails != null ? mapConfigDetails.isAllFarmDetails : false;
        const isLMUPage = mapConfigDetails != null ? mapConfigDetails.isLMUPage : false;
        const isRiskmapPage = mapConfigDetails != null ? (mapConfigDetails.isRiskmapPage == undefined ? false : mapConfigDetails.isRiskmapPage) : false;
        const polytype = mapConfigDetails != null ? mapConfigDetails.polyType : null;
        const isFarmCenterEditable = mapConfigDetails != null ? mapConfigDetails.isFarmCenterEditable : false;
        const isFarmOutlineEditable = mapConfigDetails != null ? mapConfigDetails.isFarmOutlineEditable : false;
        const isItemsEditable = mapConfigDetails != null ? mapConfigDetails.itemsEditable : false;
        const highlighteditemId = mapConfigDetails != null ? mapConfigDetails.highlighteditemId : 0;
        const isLandsoilPage = mapConfigDetails != null ? mapConfigDetails.isLandsoilPage : false;
        const isFindMyFarm = mapConfigDetails != null ? mapConfigDetails.isFindmyFarm : false;
        const isClearReq = mapConfigDetails != null ? mapConfigDetails.isClear : false;
        this.state.zoomlevel = mapConfigDetails != null ? mapConfigDetails.zoomLevel : this.state.zoomlevel;
        const showLabel = mapConfigDetails != null ? (mapConfigDetails.showLabel == undefined ? true : mapConfigDetails.showLabel) : true;

        /* only From LU Upload Page */
        const isLUUploadPage = mapConfigDetails != null ? mapConfigDetails.isLmuUpload : false;
        const luUpload = this.props.data.luUpload;
        const isLuUpload = luUpload != null ? luUpload.isUpload : false
        const uploadedLUShape = isLuUpload == true ? luUpload.uploadedShape : [];

        /* only From Shape Upload Page */
        const isShapeUploadPage = mapConfigDetails != null ? mapConfigDetails.isShapeUpload : false;
        const shapeUpload = this.props.data.shapeUpload;
        const isKMLShapeUpload = shapeUpload != null ? shapeUpload.isUpload : false
        const uploadedKMLShape = isKMLShapeUpload == true ? shapeUpload.uploadedShape : [];
        const selectedKMLShapeKeys = isKMLShapeUpload == true ? shapeUpload.selectedKey : [];

        /*From Outline Page */
        const kmlUpload = this.props.data.kmlUpload;
        const isOutlineUpload = kmlUpload != null ? kmlUpload.isUpload : false;
        const uploadedShapes = kmlUpload != null ? kmlUpload.uploadedShape : [];

        /* From LMU Page*/
        const blockSelectedDetails = this.props.data.blockViewOnly;
        const isViewBlockOnly = blockSelectedDetails != null ? blockSelectedDetails.isViewBlockOnly : false;
        if (isViewBlockOnly == true && isLMUPage == true) {
            selectedLMUId = parseInt(blockSelectedDetails.itemId);
            LMUItemList = itemList;
        }

        /*From Non Outline and LMU page */
        const largeEnterpriseDetails = this.props.data.largeEnterpriseDetails;
        const isLargeEnterprise = largeEnterpriseDetails != null ? largeEnterpriseDetails.isLargeEnterprise : false;
        const isSmallEnterpriseShow = largeEnterpriseDetails != null ? largeEnterpriseDetails.isSmallEnterpriseShow : false;
        if (isLargeEnterprise == true && isLMUPage == false && isOutlinePage == false && isRiskmapPage == false) {
            LMUItemList = largeEnterpriseDetails.LMUList;
            selectedLMUId = parseInt(largeEnterpriseDetails.selectedLMUId);
        }
        else if (isSmallEnterpriseShow == true && isLargeEnterprise == false && isLMUPage == false && isOutlinePage == false && isRiskmapPage == false) {
            LMUItemList = largeEnterpriseDetails.LMUList;
        }

        /*GIS Layer*/
        const gisLayerDetails = this.props.data.gisLayerDetails;
        const showGISLayer = gisLayerDetails != null ? gisLayerDetails.showGISLayer : false;
        if (showGISLayer) {
            GISLayerItemList = gisLayerDetails.gisLayerList;
        }

        const gisLayerTopographyDetails = this.props.data.gisLayerTopographyDetails;
        const showGISTopographyLayer = gisLayerTopographyDetails != undefined ? (gisLayerTopographyDetails != null ? gisLayerTopographyDetails.showGISLayer : false) : false;
        if (showGISTopographyLayer) {
            GISLayerTopographyItemList = gisLayerTopographyDetails.gisLayerList;
        }

        const gisLayerSoilDetails = this.props.data.gisLayerSoilDetails;
        const showGISSoilLayer = gisLayerSoilDetails != undefined ? (gisLayerSoilDetails != null ? gisLayerSoilDetails.showGISLayer : false) : false;
        if (showGISSoilLayer) {
            GISLayerSoilItemList = gisLayerSoilDetails.gisLayerList;
        }

        const gisLayerWetlandDetails = this.props.data.gisLayerWetlandDetails;
        const showGISWetlandLayer = gisLayerWetlandDetails != undefined ? (gisLayerWetlandDetails != null ? gisLayerWetlandDetails.showGISLayer : false) : false;
        if (showGISWetlandLayer) {
            GISLayerWetlandItemList = gisLayerWetlandDetails.gisLayerList;
        }

        const gisLayerStockExclusionLandSlopeDetails = this.props.data.gisLayerStockExclusionLandSlopeDetails;
        const showGISStockExclusionLandSlopeLayer = gisLayerStockExclusionLandSlopeDetails != undefined ? (gisLayerStockExclusionLandSlopeDetails != null ? gisLayerStockExclusionLandSlopeDetails.showGISLayer : false) : false;
        if (showGISStockExclusionLandSlopeLayer) {
            GISLayerStockExclusionLandSlopeItemList = gisLayerStockExclusionLandSlopeDetails.gisLayerList;
        }

        /* Farm center*/
        if (isFindMyFarm & isOutlinePage) {
            const myFarmCenter = mapConfigDetails != null ? mapConfigDetails.myFarmCenter : null;
            this.state.center = myFarmCenter;
        }
        else if (isOutlineUpload == true & isOutlinePage) {
            const Coord = uploadedShapes[0].coordinates.replaceAll('(', '').replaceAll(')', '').split(';')[0];
            this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
        }

        if (selectedLMUId > 0) {
            let selectedLUMDetails = LMUItemList.filter(type => type.id == selectedLMUId);
            let CenterCo = selectedLUMDetails[0].lmuItems[0].centerCoordinates;
            let defaultZoomlevel = selectedLUMDetails[0].lmuItems[0].defaultZoomlevel;
            if (CenterCo != null && CenterCo != '') {
                this.state.center = { lat: parseFloat(CenterCo.split(',')[0]), lng: parseFloat(CenterCo.split(',')[1]) }
            } else {
                const Coord = selectedLUMDetails[0].mapCoordinates.split(':')[0]
                if (Coord != null && Coord != '') {
                    this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
                }
            }
            this.state.zoomlevel = blockSelectedDetails != null ? blockSelectedDetails.defaultZoomLevel : defaultZoomlevel != null && defaultZoomlevel != 0 ? defaultZoomlevel : this.state.zoomlevel;
        }

        this.state.mapTypeId = 'satellite';

        if (isLandsoilPage == true) {
            this.state.mapTypeId = 'terrain';
        }

        if (isShapeUploadPage == true && outlineList.length == 0) {
            let uploadedKMLShapeLength = uploadedKMLShape.filter(type => type.mapType == 'polygon').length;
            if (uploadedKMLShapeLength > 0) {
                let Polylist = uploadedKMLShape.filter(type => type.mapType == 'polygon');
                const Coord = Polylist[0].coordinates.split(':')[0]
                this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
                this.state.zoomlevel = 15;
            }
            else {
                uploadedKMLShapeLength = uploadedKMLShape.filter(type => type.mapType == 'polyline').length;
                if (uploadedKMLShapeLength > 0) {
                    let Polylist = uploadedKMLShape.filter(type => type.mapType == 'polyline');
                    const Coord = Polylist[0].coordinates.split(':')[0]
                    this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
                    this.state.zoomlevel = 15;
                }
            }
        }

        if (isClearReq) {
            this.state.UnsavedShapes.length != undefined || this.state.UnsavedShapes.length != 0 && this.state.UnsavedShapes.setMap(null);
        }

        return (
            <this.GoogleMapExample {...this.props} containerElement={
                <div style={{ height: this.props.height == undefined ? '60vh' : this.props.height, width: this.props.width == undefined ? '50vw' : this.props.width }} />} mapElement={<div style={{ height: `100%`, borderRadius: `10px` }} />}>

                {/* DrawingManager */}
                {Map.renderDrawingManager(google, polytype, this)}

                {/*Farm Center*/}
                {isAllFarmDetails != true && Map.renderFarmCenter(google, isOutlineUpload, isFarmCenterReq, outlineList, this, isFarmCenterEditable, selectedLMUId, highlighteditemId)}

                {/* Outline */}
                {isAllFarmDetails != true && Map.renderOutline(isOutlineUpload, outlineList, uploadedShapes, this, isFarmOutlineEditable, selectedLMUId, isOutlinePage, highlighteditemId)}

                {/*LMU Items for Non outline and LMU page*/}
                {/*Parent*/}
                {LMUItemList != undefined && Map.renderLMUItemPolygon(isLMUPage, LMUItemList, selectedLMUId, isSmallEnterpriseShow)}
                {/*Child*/}
                {LMUItemList != undefined && Map.renderLMUChildItemPolygon(isLMUPage, LMUItemList, selectedLMUId)}

                {/* Items - Polygon*/}
                {itemList != undefined && Map.renderItemPolygon(this, itemList, isLMUPage, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isLUUploadPage, isRiskmapPage, isFarmCenterReq, isAllFarmSummary, showLabel)}

                {/* Items - Child LMU Polygon*/}
                {itemList != undefined && isLMUPage && Map.renderChildLmu(this, itemList, selectedLMUId, isItemsEditable, showLabel)}

                {/* Items - Marker*/}
                {itemList != undefined && !isAllFarmSummary && Map.renderItemMarker(this, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage, showLabel)}

                {/* Items - Polyline*/}
                {itemList != undefined && Map.renderItemPolyline(this, itemList, false, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage, showLabel)}

                {/* Items - Circle*/}
                {itemList != undefined && Map.renderItemCircle(this, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage, showLabel)}

                {/* Items - Rectangle*/}
                {itemList != undefined && Map.renderItemRectangle(this, itemList, selectedLMUId, LMUItemList, isItemsEditable, highlighteditemId, isRiskmapPage, showLabel)}

                {/* Items - GIS Layer*/}
                {GISLayerItemList != undefined && Map.renderItemPolyline(this, GISLayerItemList, true, false)}
                {GISLayerSoilItemList != undefined && Map.renderLayerPolygon(this, GISLayerSoilItemList, false, false)}
                {GISLayerTopographyItemList != undefined && Map.renderLayerPolygon(this, GISLayerTopographyItemList, false, false)}
                {GISLayerWetlandItemList != undefined && Map.renderLayerPolygon(this, GISLayerWetlandItemList, false, false)}
                {GISLayerStockExclusionLandSlopeItemList != undefined && Map.renderLayerPolygon(this, GISLayerStockExclusionLandSlopeItemList, false, false)}

                {/* LU upload */}
                {isLuUpload && isLUUploadPage && Map.renderluUploadPolygon(this, uploadedLUShape)}

                {/* Shape upload */}
                {isKMLShapeUpload && isShapeUploadPage && Map.renderShapeUploadPolygon(this, uploadedKMLShape, selectedKMLShapeKeys)}

                {isKMLShapeUpload && isShapeUploadPage && Map.renderShapeUploadPolyline(this, uploadedKMLShape, selectedKMLShapeKeys)}

                {isKMLShapeUpload && isShapeUploadPage && Map.renderShapeUploadMarker(this, uploadedKMLShape, selectedKMLShapeKeys)}

                {/* InfoWindow */}
                {this.state.showingInfoWindow && Map.renderInfoWindow(this)}
                {this.state.showingMarkerWindow && Map.renderMarkerWindow(this)}
                {this.state.showingLUInfoWindow && Map.renderLUInfoWindow(this)}
            </this.GoogleMapExample>
        );
    }
}
export default Map;