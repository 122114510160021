import { useState } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Collapse, Table } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';

const Farms = ({ farmsInfo }) => {
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>List of Orchards</h6></Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Unique Identifier</th>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {farmsInfo != undefined && farmsInfo != null && farmsInfo.map(item => (
                                                <tr key={uuid()}>
                                                    <td>{item.name} </td>
                                                    <td>{item.uniqueIdentifier}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default Farms;