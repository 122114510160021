function Validate(form) {
    var error = false;
    const elements = document.querySelectorAll('.validate')
    elements.forEach(function (element) {
        var parent = element.parentElement;
        if (document.getElementById('labelErr' + element.id) != undefined && document.getElementById('labelErr' + element.id) != null) {
            document.getElementById('labelErr' + element.id).remove();
        }
        if (document.getElementById('labelErrBr' + element.id) != undefined && document.getElementById('labelErrBr' + element.id) != null) {
            document.getElementById('labelErrBr' + element.id).remove();
        }
        if (element.localName == "select") {
            if (element.id == 'LmuTypeId') {
                if (form.LmuItem[element.id] == '0') {
                    error = true;
                    var newBreak = document.createElement("br");
                    newBreak.setAttribute("id", 'labelErrBr' + element.id);
                    var newlabel = document.createElement("Label");
                    newlabel.setAttribute("class", 'errorMessage');
                    newlabel.setAttribute("id", 'labelErr' + element.id);
                    newlabel.innerHTML = "This is mandatory";
                    if (element.id == 'LMUId') {
                        parent.append(newBreak);
                    }
                    parent.append(newlabel);
                }
            }
            else {
                if (form[element.id] == '0') {
                    error = true;
                    var newBreak = document.createElement("br");
                    newBreak.setAttribute("id", 'labelErrBr' + element.id);
                    var newlabel = document.createElement("Label");
                    newlabel.setAttribute("class", 'errorMessage');
                    newlabel.setAttribute("id", 'labelErr' + element.id);
                    newlabel.innerHTML = "This is mandatory";
                    if (element.id == 'LMUId') {
                        parent.append(newBreak);
                    }
                    parent.append(newlabel);
                }
            }
        }
        else if (element.localName == "input") {
            if (form[element.id] == '' || form[element.id] == null) {
                error = true;
                var newlabel = document.createElement("Label");
                newlabel.setAttribute("class", 'errorMessage');
                newlabel.setAttribute("id", 'labelErr' + element.id);

                if (element.id == "Email") {
                    const regExEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
                    if (form[element.id] == '' || form[element.id] == null) {
                        newlabel.innerHTML = "This is mandatory";
                    }
                    else if (!regExEmail.test(form[element.id])) {
                        newlabel.innerHTML = "Email is invalid!";
                    }
                }
                else {
                    newlabel.innerHTML = "This is mandatory";
                    }

                parent.append(newlabel);
            }
        }
        else if (element.localName == "textarea") {
            if (form[element.id] == '' || form[element.id] == null) {
                error = true;
                var newlabel = document.createElement("Label");
                newlabel.setAttribute("class", 'errorMessage');
                newlabel.setAttribute("id", 'labelErr' + element.id);
                newlabel.innerHTML = "This is mandatory";
                parent.append(newlabel);
            }
        }
        
    })
    return error;
}
export default Validate;