import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './Summary.css';
import Reporting from './Reporting';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import CertificationStatus from './CertificationStatus';
import ActionSummary from './ActionSummary';
import ActionDue from './ActionDue';
import SummaryGMP from './SummaryGMP';
import RiskMap from './RiskMap';
import StatisticalList from './StatisticalList';
import StaticMaps from './StaticMaps';
import QuickDetail from './QuickDetail';
import ContaminantPriorities from './ContaminantPriorities';
import RiskSummary from './RiskSummary';
import MyFarm from './MyFarm';
import ActiontoRiskAnalysis from './ActiontoRiskAnalysis';
import RisktoActionAnalysis from './RisktoActionAnalysis';
import WaterwayFencingSummary from './WaterwayFencingSummary';
import RiparianPlantingSummary from './RiparianPlantingSummary';
import PagebyPagebreakdown from './PagebyPagebreakdown';
import ForestryandBushArea from './ForestryandBushArea';
import RiskManagement from './RiskManagement';
import { toPng } from 'html-to-image';
import StaticMapView from '../Map/StaticMapView';
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Summary = ({ handleWizardChanged, handleWizardChangedBack }) => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const { height, width } = useWindowDimensions();
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId")); const [staticMapLoading, setStaticMapLoading] = useState(true);
    const [quickDetail, setQuickDetail] = useState({ farmName: '', responsiblePerson: '', totalArea: null, leaseArea: null, irrigatedArea: null })
    const [loading, setLoading] = useState(true); const [generateReport, setGenerateReport] = useState(false);
    const [summary, setSummary] = useState({
        outlineList: [], itemList: [], actionList: [], actionTypeList: [], actionDues: '', farmCompleteGMPList: [], GMPList: [],
        Section: 'Summary', SectionName: 'Summary', isWizardCompleted: false, wizardDetails: [], zoomLevel: 15, catchmentContextZoomlevel: 15, reportDocumentList: [], certificationAndAuditStatus: {}
    }); const [farmName, setFarmName] = useState(JSON.parse(localStorage.getItem("defaultFarmName"))); const [farmUniqueId, setFarmUniqueId] = useState(JSON.parse(localStorage.getItem("defaultFarmUniqueId")));
    const [landsoil, setLandSoil] = useState({ outlineList: [], itemList: [] });
    const [waterway, setWaterway] = useState({ outlineList: [], itemList: [] }); const [propertyMapItems, setPropertyMapItems] = useState({ outlineList: [], itemList: [] });
    const [mahingaKai, setMahingaKai] = useState({ outlineList: [], itemList: [] });
    const [lmu, setLmu] = useState({ outlineList: [], itemList: [] }); const [allItems, setAllItems] = useState({ outlineList: [], itemList: [] }); const [itemActionPlanList, setItemActionPlanList] = useState({ outlineList: [], itemList: [] });
    const [itemPhysicalWorksPlanList, setItemPhysicalWorksPlanList] = useState({ outlineList: [], itemList: [] });
    const [infrastructure, setInfrastructure] = useState({ outlineList: [], itemList: [] }); const [agrichemicals, setAgrichemicals] = useState({ outlineList: [], itemList: [] });
    const [freshWaterIrrigation, setFreshWaterIrrigation] = useState({ outlineList: [], itemList: [] });
    const [effluentDisposal, setEffluentDisposal] = useState({ outlineList: [], itemList: [] });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0 });
    const [catchmentContext, setCatchmentContext] = useState({ outlineList: [], itemList: [] });
    const [outline, setOutline] = useState({ outlineList: [], itemList: [] });
    const [riskmap, setRiskmap] = useState({ outlineList: [], itemList: [] });
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 5, polytype: '', itemsEditable: false
    });
    const [actionSummary, setActionSummary] = useState([]);
    const [riskModel, setRiskModel] = useState({});
    const [reportButtonVisible, setReportButtonVisible] = useState(false); const [propertyMapReportButtonVisible, setPropertyMapReportButtonVisible] = useState(false);
    const [catchmentContextMapDetails, setCatchmentContextMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 5, polytype: '', itemsEditable: false
    });
    useEffect(() => {
        setStaticMapLoading(true);
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/summary/GetSummaryDetails/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setOutline({ ...outline, itemList: [], outlineList: response.data.outlineList });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise, LMUList: response.data.lmuItemList })
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel });
            setCatchmentContextMapDetails({ ...mapDetails, zoomLevel: response.data.selectedCatchmentContextZoomlevel });
            setLandSoil({ ...landsoil, itemList: response.data.landsoilList, outlineList: response.data.outlineList });
            setWaterway({ ...waterway, itemList: response.data.waterwaysList, outlineList: response.data.outlineList });
            setMahingaKai({ ...mahingaKai, itemList: response.data.mahingaKaiList, outlineList: response.data.outlineList });
            setLmu({ ...lmu, itemList: response.data.lmuItemList, outlineList: response.data.outlineList });
            setInfrastructure({ ...infrastructure, itemList: response.data.infrastructureList, outlineList: response.data.outlineList });
            setPropertyMapItems({ ...propertyMapItems, itemList: response.data.propertyMapitemList, outlineList: response.data.outlineList });
            setAgrichemicals({ ...infrastructure, itemList: response.data.agrichemicalsList, outlineList: response.data.outlineList });
            setAllItems({ ...allItems, itemList: response.data.allItemsList, outlineList: response.data.outlineList });
            setItemActionPlanList({ ...itemActionPlanList, itemList: response.data.itemActionPlanList, outlineList: response.data.outlineList });
            setItemPhysicalWorksPlanList({ ...itemPhysicalWorksPlanList, itemList: response.data.itemPhysicalWorksPlanList, outlineList: response.data.outlineList });
            setFreshWaterIrrigation({ ...freshWaterIrrigation, itemList: response.data.freshwaterIrrigationList, outlineList: response.data.outlineList });
            setCatchmentContext({ ...catchmentContext, itemList: response.data.catchmentContextList, outlineList: response.data.outlineList });
            setEffluentDisposal({ ...effluentDisposal, itemList: response.data.effluentDisposalList, outlineList: response.data.outlineList });
            setStaticMapLoading(false);
            var riskItems = [];
            response.data.landsoilList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.waterwaysList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.mahingaKaiList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.lmuItemList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.infrastructureList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.freshwaterIrrigationList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            response.data.effluentDisposalList.filter(x => (x.riskLabel == "R")).forEach(item => riskItems.push(item));
            setSummary({
                ...summary, outlineList: response.data.outlineList, itemList: response.data.itemList, actionList: response.data.allActionList,
                farmCompleteGMPList: response.data.farmCompleteGoodManagementPracticesList, GMPList: response.data.goodManagmentPractice,
                actionTypeList: response.data.actionTypeList, isWizardCompleted: response.data.isFWFPCompleted, actionDues: response.data.actionDues,
                wizardDetails: response.data.wizardDetails, zoomLevel: response.data.selectedZoomlevel, catchmentContextZoomlevel: response.data.selectedCatchmentContextZoomlevel,
                reportDocumentList: response.data.reportDocumentList, certificationAndAuditStatus: response.data.certificationAndAuditStatus
            });
            setRiskmap({ ...riskmap, itemList: riskItems, outlineList: response.data.outlineList })
            setQuickDetail(response.data.quickDetail);
            setActionSummary(response.data.actionSummary);
            setRiskModel(response.data.riskModel)
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        } catch (err) {
            console.log(err);
        }
    }
    const staticOutlinemapView = useMemo(() => {
        return (
            <StaticMapView itemModel={outline} mapDetails={mapDetails} farmCentreReq={true} id="FarmDetailImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticInfrastructuremapView = useMemo(() => {
        return (
            <StaticMapView itemModel={infrastructure} mapDetails={mapDetails} farmCentreReq={false} id="InfrastructureMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticAgrichemicalsmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={agrichemicals} mapDetails={mapDetails} farmCentreReq={false} id="AgrichemicalsMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticLUmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={lmu} mapDetails={mapDetails} farmCentreReq={false} id="LandUnitsMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticLUmapTerrainView = useMemo(() => {
        return (
            <StaticMapView itemModel={lmu} mapDetails={mapDetails} farmCentreReq={false} id="LandUnitsMapTerrainImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticWaterwaysmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={waterway} mapDetails={mapDetails} farmCentreReq={false} id="WaterwayMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticLandSoilmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={landsoil} mapDetails={mapDetails} farmCentreReq={false} id="LandAndErosionMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticMahingaKaimapView = useMemo(() => {
        return (
            <StaticMapView itemModel={mahingaKai} mapDetails={mapDetails} farmCentreReq={false} id="MahingKaiMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticFarmAPTmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={catchmentContext} mapDetails={catchmentContextMapDetails} farmCentreReq={false} id="WaterTestSitesImage2" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticAllItemsmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={allItems} mapDetails={mapDetails} farmCentreReq={false} id="AllItemsMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticItemActionPlanListmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={itemActionPlanList} mapDetails={mapDetails} farmCentreReq={false} id="AllActionsMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticItemPhysicalWorksPlanListmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={itemPhysicalWorksPlanList} mapDetails={mapDetails} farmCentreReq={false} id="PhysicalWorksMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticFreshwaterIrrigationmapView = useMemo(() => {
        return (
            <StaticMapView itemModel={freshWaterIrrigation} mapDetails={mapDetails} farmCentreReq={false} id="FreshwaterIrrigationMapImage" setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const staticProertyMapItemsView = useMemo(() => {
        return (
            <StaticMapView itemModel={propertyMapItems} mapDetails={mapDetails} farmCentreReq={false} id="PropertyMapImage" setPropertyMapReportButtonVisible={setPropertyMapReportButtonVisible} setReportButtonVisible={setReportButtonVisible} />
        )
    })
    const getBase64Image = async (img) => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    }
    const elementRef = useRef(null);

    const exportSummaryToPdf = async () => {
        setGenerateReport(true);
        var ActionDue = null;
        toPng(elementRef.current, { cacheBust: true })
            .then((dataUrl) => {
                ActionDue = dataUrl;
                const response = generateSummaryReport(ActionDue);
            })
            .catch((err) => {
                const response = generateSummaryReport(null);
            });


    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const generateSummaryReport = async (ActionDue) => {
        var FarmDetailImage = await getBase64Image(document.getElementById('FarmDetailImage'));
        var InfrastructureMapImage = await getBase64Image(document.getElementById('InfrastructureMapImage'));
        var AgrichemicalsMapImage = await getBase64Image(document.getElementById('AgrichemicalsMapImage'));
        var LandUnitsMapImage = await getBase64Image(document.getElementById('LandUnitsMapImage'));
        var WaterwayMapImage = await getBase64Image(document.getElementById('WaterwayMapImage'));
        var LandAndErosionMapImage = await getBase64Image(document.getElementById('LandAndErosionMapImage'));
        var MahingKaiMapImage = await getBase64Image(document.getElementById('MahingKaiMapImage'));
        var WaterTestSitesImage2 = await getBase64Image(document.getElementById('WaterTestSitesImage2'));
        var AllItemsMapImage = await getBase64Image(document.getElementById('AllItemsMapImage'));
        var EffluentDisposalMapImage = null;
        var FreshwaterIrrigationMapImage = await getBase64Image(document.getElementById('FreshwaterIrrigationMapImage'));
        var AllActionsMapImage = await getBase64Image(document.getElementById('AllActionsMapImage'));
        var PhysicalWorksMapImage = await getBase64Image(document.getElementById('PhysicalWorksMapImage'));
        //var LandUnitsMapTerrainImage = await getBase64Image(document.getElementById('LandUnitsMapTerrainImage'));
        let form = {
            FarmDetailImage: FarmDetailImage,
            InfrastructureMapImage: InfrastructureMapImage,
            AgrichemicalsMapImage: AgrichemicalsMapImage,
            LandUnitsMapImage: LandUnitsMapImage,
            WaterwayMapImage: WaterwayMapImage,
            LandAndErosionMapImage: LandAndErosionMapImage,
            MahingKaiMapImage: MahingKaiMapImage,
            WaterTestSitesImage2: null,
            AllItemsMapImage: null,
            FreshwaterIrrigationMapImage: null,
            EffluentDisposalMapImage: null,
            AllActionsMapImage: null,
            PhysicalWorksMapImage: null,
            ActionDue: null,
            FarmId: farmId,
            LandUnitsMapTerrainImage:null
        }

        let response = await axios.post(
            '/api/summary/ExportFWFPReport1/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });

        if (response.data.status) {
            form = {
                FarmDetailImage: null,
                InfrastructureMapImage: null,
                AgrichemicalsMapImage: null,
                LandUnitsMapImage: null,
                WaterwayMapImage: null,
                LandAndErosionMapImage: null,
                MahingKaiMapImage: null,
                WaterTestSitesImage2: WaterTestSitesImage2,
                AllItemsMapImage: AllItemsMapImage,
                FreshwaterIrrigationMapImage: FreshwaterIrrigationMapImage,
                EffluentDisposalMapImage: EffluentDisposalMapImage,
                AllActionsMapImage: AllActionsMapImage,
                PhysicalWorksMapImage: PhysicalWorksMapImage,
                ActionDue: ActionDue,
                FarmId: farmId,
                LandUnitsMapTerrainImage: null
            }
            response = await axios.post(
                '/api/summary/ExportFWFPReport2/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });

            if (response.data.status) {
                setGenerateReport(false);
                toast.success("Report generated!", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                var buffer = base64ToArrayBuffer(response.data.content)
                var file = new Blob([buffer], { type: "application/pdf" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "FWFP Summary - " + farmName.toString() + " - " + farmUniqueId.toString() + ".pdf";
                document.body.appendChild(link)
                link.click();
            }
            else {
                setGenerateReport(false);
                toast.error("Error when generating report", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
        else {
            setGenerateReport(false);
            toast.error("Error when generating report", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        return response;
    }

    const [generatePropertyMapReport, setGeneratePropertyMapReport] = useState(false);
    const generatePropertyMapReportPdf = async () => {
        setGeneratePropertyMapReport(true);
        setTimeout(() => {
            exportPropertyMapToPdf();
        }, 3000);
    }

    const exportPropertyMapToPdf = async () => {
        var PropertyMapImage = await getBase64Image(document.getElementById('PropertyMapImage'));
        let form = {
            PropertyMapImage: PropertyMapImage,
            FarmId: farmId
        }
        let response = await axios.post(
            '/api/summary/ExportPropertyMapReport/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data.status) {
            setGeneratePropertyMapReport(false);
            toast.success("Report generated!", { duration: 3000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "Property Map - " + farmName.toString() + " - " + farmUniqueId.toString() + ".pdf";
            document.body.appendChild(link)
            link.click();
        }
        else {
            setGeneratePropertyMapReport(false);
            toast.error("Error when generating report", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row className="heading-underline">
                <Col><h2>{summary.SectionName}</h2></Col>
                <Col>
                    {summary.isWizardCompleted != true && !loading &&
                        <>
                            <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(summary.Section)}><FaChevronLeft /> Back  </Button>
                        </>

                    }
                </Col>
            </Row>
            {!staticMapLoading &&
                <Row style={{ display: "none" }}>
                    <Col>
                        <div style={{ display: "none" }}>
                            {staticOutlinemapView}<br />
                            {staticInfrastructuremapView}<br />
                            {staticAgrichemicalsmapView}<br />
                            {staticLUmapView}<br />
                            {/*{staticLUmapTerrainView}<br />*/}
                            {staticWaterwaysmapView}<br />
                            {staticLandSoilmapView}<br />
                            {staticMahingaKaimapView}<br />
                            {staticFarmAPTmapView}<br />
                            {staticAllItemsmapView}<br />
                            {staticFreshwaterIrrigationmapView}<br />
                            {staticItemActionPlanListmapView}<br />
                            {staticItemPhysicalWorksPlanListmapView}<br />
                            {staticProertyMapItemsView}
                        </div>
                    </Col>
                </Row>
            }
            {loading ? <SpinnerContainer /> :
                <>
                    <Row>
                        <Col sm="8" style={{ maxHeight: "120vh", overflowY: "auto" }}>
                            <MyFarm propertyMapReportButtonVisible={propertyMapReportButtonVisible} quickDetail={quickDetail} reportButtonVisible={reportButtonVisible} generatePropertyMapReport={generatePropertyMapReport} exportPropertyMapToPdf={generatePropertyMapReportPdf}></MyFarm>
                        </Col>
                        <Col sm="4">
                            <Row>
                                <RiskManagement height={height} width={width}></RiskManagement>
                            </Row>
                            <Row>
                                <Reporting generateReport={generateReport} reportButtonVisible={reportButtonVisible} exportSummaryToPdf={exportSummaryToPdf} farmId={farmId} reportDocumentList={summary.reportDocumentList}></Reporting>
                            </Row>
                            <Row>
                                <CertificationStatus certificationAndAuditStatus={summary.certificationAndAuditStatus}
                                    summary={summary}
                                    isWizardCompleted={summary.isWizardCompleted}
                                    wizardDetails={summary.wizardDetails}
                                    handleWizardChanged={handleWizardChanged}
                                ></CertificationStatus>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="heading-underline">
                        <Col><h4>Risk and Action Management</h4></Col>
                    </Row>
                    <Row>
                        <Col sm="8" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <ActionSummary riskModel={riskModel}></ActionSummary>
                        </Col>
                        <Col sm="4">
                            <ActionDue elementRef={elementRef} ActionDueList={summary.actionDues}></ActionDue>
                        </Col>
                    </Row>
                    
                    <Row className="heading-underline">
                        <Col><h3>Insights</h3></Col>
                    </Row>
                    <br />
                    <Row>
                        {/*<Col sm="8" style={{ maxHeight: "100vh", overflowY: "auto" }}>*/}
                        {/*    <WaterwayFencingSummary userId={userId}></WaterwayFencingSummary>*/}
                        {/*    <ForestryandBushArea></ForestryandBushArea>*/}

                        {/*</Col>*/}
                        <Col sm="6" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <RiparianPlantingSummary></RiparianPlantingSummary>
                        </Col>
                        <Col sm="6" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <PagebyPagebreakdown></PagebyPagebreakdown>
                        </Col>
                    </Row>
                </>
                
            }
        </div>
    )
}
export default Summary;