import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import './EnterpriseInsights.css';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import AllFarmMap from '../EnterpriseInsights/AllFarmMap';
import ProgrammeSummary from '../EnterpriseInsights/ProgrammeSummary';
import AllFarmRiparianPlantingSummary from './AllFarmRiparianPlantingSummary';
import AllFarmForestryandBushArea from './AllFarmForestryandBushArea';
import AllFarmWaterwayFencingSummary from './AllFarmWaterwayFencingSummary';
import GFPSummaryReport from './GFPSummaryReport';

const EnterpriseInsights = ({ handleWizardFarmChanged }) => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));

    const [loading, setLoading] = useState(true);
    const [enterpriseInsights, setEnterpriseInsights] = useState({ farmsList: [], itemList: [], programmeSummary: {}, openActionSupplierList: [], openActionList: [], openActionsList: [], totalFarmDetails: {} });
    const [isGFPSummaryReportVisible, setIsGFPSummaryReportVisible] = useState(false);
    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/EnterpriseInsights/GetEnterpriseInsightsDetails/', {
                params: { UserId: userId }
            }
            );
            setEnterpriseInsights({
                ...enterpriseInsights, farmsList: response.data.farmsList, programmeSummary: response.data.programmeSummary
                , openActionSupplierList: response.data.openActionSupplierList, openActionList: response.data.openActionList, openActionsList: response.data.openActionsList
                , totalFarmDetails: response.data.totalFarmDetails, itemList: response.data.allFarmOutlineList
            });
            setIsGFPSummaryReportVisible(response.data.isGFPSummaryReportVisible);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>Enterprise Insights</h2></Col>
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <Col sm="5">
                            <ProgrammeSummary programmeSummary={enterpriseInsights.programmeSummary}></ProgrammeSummary> 
                            {
                                isGFPSummaryReportVisible && <GFPSummaryReport></GFPSummaryReport>
                            }
                            <AllFarmRiparianPlantingSummary></AllFarmRiparianPlantingSummary>
                        </Col>
                        <Col sm="7">
                            <AllFarmMap itemModel={enterpriseInsights}></AllFarmMap>
                            <AllFarmWaterwayFencingSummary></AllFarmWaterwayFencingSummary>
                            <AllFarmForestryandBushArea></AllFarmForestryandBushArea>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}
export default EnterpriseInsights;