import { useState, useEffect } from 'react';
import React from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Card, CardBody, CardHeader, Collapse, Spinner } from 'reactstrap';
import { FaPlus, FaTrash, FaChevronUp, FaChevronDown, FaUpload} from 'react-icons/fa';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const Documents = ({ refid, uploadType, open, showArrow, cardClass, headerClass1, headerClass2, showInfo, infoMessage, infoId }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddDeleteDocument = localStorage.getItem("isAddDeleteDocument");
    if (cardClass === undefined) {
        cardClass = "my-4 card-header-outline";
    }
    if (headerClass1 === undefined) {
        headerClass1 = "";
    }
    if (headerClass2 === undefined) {
        headerClass2 = "card-header d-flex justify-content-between align-items-center";
    }
    const [isOpen, setIsOpen] = useState(open);
    const [formErrors, setFormErrors] = useState([]);
    const [isIconUp, setIsIconUp] = useState(false);
    const [saving, setSaving] = useState('');
    const [loading, setLoading] = useState(true);
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [documentUploadList, setDocumentUploadList] = useState([]);
    const [deleteDocumentPopup, setDeleteDocumentPopup] = useState({ showDeleteDocumentPopup: false, itemId: 0, date: '', daetails: '', fileName: '' });
    let inputFile = [];

    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        if (refid != 0) {
            try {
                const response = await axios.get(
                    '/api/upload/GetUploadedDocuments/', {
                        params: { Id: refid, UploadType: uploadType, FarmId: farmId }
                }
                );
                if (response.data.status) {
                    setDocumentUploadList(response.data.content);
                    let tempErrors = [];
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }

            } catch (err) {
                console.log(err);
            }
        }
        setLoading(false);
    }

    const handleRecordRowsAddRow = () => {
        const item = {
            createdDate: getParsedDate1(new Date()),
            documentDetail: "", id: 0, documentName: '', documentPhysicalName: '', documentPath: '', documentRelativePath: ''
        };
        const error = {
            fileError: ''
        };
        setFormErrors([...formErrors, error]);
        setDocumentUploadList([...documentUploadList, item]);
    };

    const handleRecordRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...documentUploadList]
        const tempErrors = [...formErrors];
        tempRows.splice(idx, 1)
        tempErrors.splice(idx, 1)
        setDocumentUploadList(tempRows);
        setFormErrors(tempErrors);
    }

    const handleRecordRowsSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        let tempRecordUploadList = [...documentUploadList];
        let form = {
            ReferenceId: refid,
            FarmId: farmId,
            UploadType: uploadType,
            userId: userId,
            Documents: []
        }
        for (var i = 0; i < tempRecordUploadList.length; i++) {
            if (tempRecordUploadList[i].documentName != '' && tempRecordUploadList[i].documentName != null && tempRecordUploadList[i].documentName != undefined) {
                var obj = {};
                obj.Id = tempRecordUploadList[i].id;
                obj.documentDetail = tempRecordUploadList[i].documentDetail;
                obj.documentName = tempRecordUploadList[i].documentName;
                obj.documentPhysicalName = tempRecordUploadList[i].documentPhysicalName;
                obj.documentPath = tempRecordUploadList[i].documentPath;
                obj.UploadDateText = tempRecordUploadList[i].uploadDate != null ? tempRecordUploadList[i].uploadDateText : "";
                form.Documents.push(obj);
            }
        }
        const response = await axios.post(
            '/api/upload/SaveDocuments/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Documents Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSaving(false);
                setTimeout(() => {
                    setDocumentUploadList(response.data.content);
                    let tempErrors = [];
                    setFormErrors(tempErrors);
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }, 2000);
            }
        }
    };

    function handleRecordPopupClose() {
    }

    function handleDeleteRecordPopup() {
    }

    function deleteRecord() {

    }

    const handleDateChange = (idx, date) => {
        const tempRows = [...documentUploadList];
        tempRows[idx].uploadDateText = getParsedDate(date);
        tempRows[idx].uploadDate = getDateFromString(getParsedDate(date));
        setDocumentUploadList(tempRows);
    };

    const getParsedDate1 = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleDetailsChange = idx => e => {
        const tempRows = [...documentUploadList];
        tempRows[idx].documentDetail = e.target.value;
        setDocumentUploadList(tempRows);
    };

    const handleRecordChange = async (e, idx) => {
        e.preventDefault();
        const tempErrors = [...formErrors];
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if ((file.size / (1024 * 1024)) > 100) {
            errors.fileError = "Sorry, uploads are restricted to a maximum of 100 per item!";
            tempErrors[idx].fileError = "Sorry, uploads are restricted to a maximum of 100mb per item!";
        }
        else {
            tempErrors[idx].fileError = "";
        }
        setFormErrors(tempErrors);
        if (Object.keys(errors).length == 0) {
            const formData = new FormData();
            formData.append("FarmId", farmId);
            formData.append("ReferenceId", refid);
            formData.append("Type", uploadType);
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            formData.append("userId", userId);

            const response = await axios.post(
                '/api/uploaddocument/UploadDocument/', formData,
            {
                headers: { "Content-type": "multipart/form-data", "FarmId": farmId, "ReferenceId": refid, "Type": uploadType, "userId": userId, "FileName": e.target.files[0].name },
                withCredentials: true
            });
            if (response.data != null) {
                if (response.data.status) {
                    const tempRows = [...documentUploadList];
                    tempRows[idx].documentName = response.data.content.DocumentName;
                    tempRows[idx].documentPhysicalName = response.data.content.DocumentPhysicalName;
                    tempRows[idx].documentPath = response.data.content.DocumentPath;
                    tempRows[idx].documentRelativePath = response.data.content.RelativeFilepath;
                    setDocumentUploadList(tempRows);
                }
            }
        }
        else {
            e.target.value = null;
        }
    }

    const handleBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputFile[idx].click();
        return false;
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className={cardClass}>
                {showArrow && <CardHeader className={headerClass1} onClick={toggle}>
                    <Row>
                        <Col sm="11">
                            <h5>
                                {showInfo && <><FaInfoCircle data-tooltip-id={infoId} /> &nbsp;&nbsp;&nbsp;</>}
                                Supporting Documents</h5>
                            {showInfo && <>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    id={infoId}
                                    place="top"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>{infoMessage}</p>
                                                </Col>
                                            </Row>
                                        </>
                                    )} />
                            </>}
                        </Col>
                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>}
                {!showArrow &&
                    <h5 className={headerClass2} >Supporting Documents
                        {saving ?
                            <Button className="button-save" style={{ float: "right" }} onClick={handleRecordRowsSubmit}
                                color="success"
                                disabled
                            >
                                <Row>
                                    <Col sm="8">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="4">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row>
                            </Button>
                            :
                            <Button className="button-save" style={{ float: "right" }} onClick={handleRecordRowsSubmit} color="success" size="sm">
                                Save
                            </Button>
                        }
                    </h5>
                }
                
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        {refid != 0 ?
                            <div>
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || isAddDeleteDocument == 'true') &&
                                            <>
                                                <button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                    Add
                                                </button>
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="12">
                                        <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                            <thead>
                                                <tr>
                                                    <th className="text-center"> Date</th>
                                                    <th className="text-center"> Details </th>
                                                    <th className="text-center"> Attachment </th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documentUploadList.map((item, idx) => (
                                                    <tr id="records" key={idx}>
                                                        <td style={{ display: "none" }}>{documentUploadList[idx].id}</td>
                                                        <td style={{ width: "15%" }}>
                                                            <DatePicker selected={documentUploadList[idx].uploadDate != null ? getDateFromString(moment(documentUploadList[idx].uploadDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleDateChange(idx, date)} name="uploadDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                                        </td>
                                                        <td style={{ width: "45%" }}>
                                                            <input type="textarea" name="documentDetail" value={documentUploadList[idx].documentDetail} onChange={handleDetailsChange(idx)} className="form-control" />
                                                        </td>
                                                        <td style={{ width: "15%" }}>
                                                            {documentUploadList[idx].documentRelativePath != null && documentUploadList[idx].documentRelativePath != '' ?
                                                                <Row>
                                                                    <Col>
                                                                        <a href={documentUploadList[idx].documentRelativePath} target='_blank'>
                                                                            <img src="/images/File_Icon.png" className="PDF_icon" ></img>
                                                                        </a>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button style={{ float: `right` }} className="button-upload" color="warning" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                <Button style={{ float: `right` }} className="button-upload" color="success" size="sm" onClick={(e) => handleBtnUploadClick(e,idx)} ><FaUpload /></Button>
                                                            }
                                                            <br />
                                                            <input style={{ float: `right`, display: 'none' }}
                                                                ref={input => {
                                                                    inputFile[idx] = input;
                                                                }}
                                                                type="file" name="attachment" className="form-control" onChange={(e) => handleRecordChange(e, idx)} />
                                                            {formErrors.length > 0 && formErrors[idx] != undefined && formErrors[idx].fileError != undefined &&
                                                                <p className="errorMessage">{formErrors[idx].fileError}</p>
                                                            }
                                                        </td>
                                                        <td style={{ width: "5%" }}>
                                                            {(roleName == 'Zespri Admin' || isAddDeleteDocument == 'true') &&
                                                                <>
                                                                    <button style={{ float: "right" }} onClick={handleRecordRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                                        <FaTrash />
                                                                    </button>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col> {refid != 0 ?
                                        showArrow &&
                                        <div>
                                            {saving ?
                                                    <Button className="button-save" style={{ float: "right" }} onClick={handleRecordRowsSubmit} color="success" size="sm" disabled>
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Button>
                                                :
                                                <Button className="button-save" color="success" size="sm" style={{ float: "right" }} onClick={handleRecordRowsSubmit}>
                                                    Save
                                                </Button>
                                            }
                                        </div> : ''
                                    }
                                    </Col>
                                </Row>
                            </div> : <span>Please save item to add records</span>}
                    </CardBody>
                </Collapse>
            </Card>


            <Modal isOpen={deleteDocumentPopup.showDeteteRecordPopup}>
                <ModalHeader toggle={handleRecordPopupClose} >Delete Record</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={deleteRecord}>Yes</Button>
                    <Button color="danger" onClick={handleRecordPopupClose}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}
export default Documents