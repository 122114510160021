import React from 'react'
import { Row, Col,  Label, Input,  FormGroup } from 'reactstrap';
const Biodiversity = ({ itemPopupBiodiversity, handleItemPopupBiodiversity }) => {
    return (
            <Row>
                <Col>
                    <Row>
                        <Col sm="1">
                        <input className="islargeEnterprise form-check-input" name="isItemToBiodiversity" type="checkbox" checked={itemPopupBiodiversity.isItemToBiodiversity} onChange={handleItemPopupBiodiversity } />

                        </Col>
                        <Col sm="5">
                            <label>
                                Select if this item represents or adds to the Biodiversity of the property. Note: carries this item through to Biodiversity Page.
                            </label>
                    </Col>
                    {
                        itemPopupBiodiversity.isItemToBiodiversity && <Col sm="6">
                            <FormGroup>
                                <Label>Biodiversity Comment</Label>
                                <Input type="textarea" name="biodiversityComment" id="biodiversityComment" value={itemPopupBiodiversity.biodiversityComment} onChange={handleItemPopupBiodiversity} />
                            </FormGroup>
                        </Col>
                    }   
                    </Row>
                    {
                        !itemPopupBiodiversity.isItemToBiodiversity && <br />
                    }
                </Col>
            </Row>

    )

}
export default Biodiversity