import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Card, CardBody, CardHeader, Collapse, Tooltip } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';

const InfrastructureItemSpecView = ({ itemPopupItemFeild, itemPopupItemSepcQ, lookUpList, handleItemSepcValueChange, itemPopupHeader, handleItemPopupHeaderChange }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const itemTypeFeildList = itemPopupItemFeild.itemFieldTypes;
    const itemFeildCount = itemTypeFeildList.length;

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen)
    };

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        handleHideAllDiv(itemTypeFeildList);

    }, [])

    const handleHideAllDiv = (itemTypeFeildList) => {
        document.querySelectorAll(".item-field").forEach(element => {
            element.style.display = 'none';
        })

        itemTypeFeildList.map(itemTypeFeild => {
            let className = "." + itemTypeFeild.className;
            document.querySelectorAll(className).forEach(element => {
                element.style.display = 'block';
            })
        })
    }

    const WaterTakenOptions = lookUpList.otherWaterTakesLookupList;
    const PondSolidsSpreadTypes = lookUpList.liquidEffluentAppliedLookupList;
    const StructureCleanedOn = lookUpList.structureCleanedOnLookupList
    const WaterMeterTypes = lookUpList.waterMeterTypeLookupList
    const DataCaptureTypes = lookUpList.waterMeterDataCaptureLookupList
    const PurposeOfWatertakeList = lookUpList.purposeOfWatertakeList

    if (itemFeildCount > 0) {
        return (
            <>
                <Card>
                    {/*<CardHeader className="card-header-click" onClick={toggle}>*/}
                    {/*    <Row>*/}
                    {/*        <Col sm="11">*/}
                    {/*            <h6>Additional Information</h6>*/}
                    {/*        </Col>*/}
                    {/*        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>*/}
                    {/*    </Row>*/}
                    {/*</CardHeader>*/}
                    {/*<Collapse isOpen={isOpen}>*/}
                        <CardBody>

                            {/* Ag chemical storage*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field ag-chemical-spill-prevention'>
                                        <Label>Controls in place to contain spills (bunded)</Label>
                                        <Input type="textarea" name="AgChemicalSpillPrevention" id="AgChemicalSpillPrevention" value={itemPopupItemSepcQ.AgChemicalSpillPrevention} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Farm dairy*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field dairy-size'>
                                        <Label>Dairy Size</Label>
                                        <Input type="number" name="DairySize" id="DairySize" value={itemPopupItemSepcQ.DairySize} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field is-yard-roofed'>
                                        <Label>Is Yard Roofed?</Label> <br />
                                        <input className="form-check-input" name="IsYardRoofed" type="checkbox" checked={itemPopupItemSepcQ.IsYardRoofed} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field in-shed-feeding'>
                                        <Label>In Shed Feeding?</Label><br />
                                        <input className="form-check-input" name="InShedFeeding" type="checkbox" checked={itemPopupItemSepcQ.InShedFeeding} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Fertiliser storage*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field fertiliser-runoff-prevention'>
                                        <Label>Controls in place to prevent runoff i.e. roofed, silo</Label>
                                        <Input type="textbox" name="FertiliserRunoffPrevention" id="FertiliserRunoffPrevention" value={itemPopupItemSepcQ.FertiliserRunoffPrevention} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* Fuel storage*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field leakage-controls'>
                                        <Label>Leakage Controls</Label>
                                        <Input type="textarea" name="LeakageControls" id="LeakageControls" value={itemPopupItemSepcQ.LeakageControls} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Individual Housing barns*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field barn-size'>
                                        <Label>Barn Size (m2)</Label>
                                        <Input type="number" name="BarnSize" id="BarnSize" value={itemPopupItemSepcQ.BarnSize} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field barn-outdoor-area-size'>
                                        <Label>Barn Outdoor Area size (m2)</Label>
                                        <Input type="number" name="BarnOutdoorAreaSize" id="BarnOutdoorAreaSize" value={itemPopupItemSepcQ.BarnOutdoorAreaSize} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Other water takes*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field other-water-takes'>
                                        <Label>How water is taken</Label> {' '}
                                        <span style={{ textDecoration: 'underline' }} href="#" id="watertakes">
                                            ?
                                        </span>
                                        <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="watertakes" toggle={toggleTooltip}>
                                            Locate water takes from waterways, springs etc for stock, dairy plant or irrigation use.
                                        </Tooltip>
                                        {WaterTakenOptions != undefined && WaterTakenOptions != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="OtherWaterTakes" id="OtherWaterTakes" value={itemPopupItemSepcQ.OtherWaterTakes}>
                                                <option value={0} >Select type</option>
                                                {WaterTakenOptions.map(Option =>
                                                    <option key={uuid()} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/*Water Take*/}
                            <Row >
                                <Col sm="6">
                                    {itemPopupHeader.itemTypeName == 'Water Take' &&
                                        <>
                                            <FormGroup>
                                                <Label>Type</Label>
                                                <Input type="select" className="float-end validate" onChange={handleItemPopupHeaderChange} name="watertakeType" id="watertakeType" value={itemPopupHeader.watertakeType}>
                                                    <option key={0} value={0} >Select Type</option>
                                                    {itemPopupHeader.watertakeTypeList != undefined && itemPopupHeader.watertakeTypeList != null && itemPopupHeader.watertakeTypeList.map(Option =>
                                                        <>
                                                            <option key={Option.id} value={Option.id}>{Option.value}</option>
                                                        </>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </>
                                    }
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field purpose-of-water-take'>
                                        <Label>Purpose of water take</Label> {' '}
                                        {PurposeOfWatertakeList != undefined && PurposeOfWatertakeList != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="PurposeOfWatertake" id="PurposeOfWatertake" value={itemPopupItemSepcQ.PurposeOfWatertake}>
                                                <option key={0} value={0} >Select Purpose</option>
                                                {PurposeOfWatertakeList.map(Option =>
                                                    <option key={Option.id} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Pad or Wintering barn*/}
                            <Row >

                                <Col>
                                    <FormGroup className='item-field the-structure-lined'>
                                        <Label> Is the structure lined?</Label> <br />
                                        <input className="form-check-input" name="IsStructureLined" type="checkbox" checked={itemPopupItemSepcQ.IsStructureLined} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field the-structure-covered'>
                                        <Label>Is the structure covered?</Label><br />
                                        <input className="form-check-input" name="IsStructureCovered" type="checkbox" checked={itemPopupItemSepcQ.IsStructureCovered} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field where-liquid-effluent-applied'>
                                        <Label>Where is the liquid effluent applied?</Label>
                                        {PondSolidsSpreadTypes != undefined && PondSolidsSpreadTypes != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="LiquidEffluentAppliedIn" id="LiquidEffluentAppliedIn" value={itemPopupItemSepcQ.LiquidEffluentAppliedIn} >
                                                <option value={0} >Select How water is take</option>
                                                {PondSolidsSpreadTypes.map(Option =>
                                                    <option key={uuid()} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >

                                <Col>
                                    <FormGroup className='item-field are-solids-seperated-from-liquid-effluent'>
                                        <Label>Are solids separated from liquid effluent?</Label> <br />
                                        <input className="form-check-input" name="IsSolidsSeperatedFromLiquidEffluent" type="checkbox" checked={itemPopupItemSepcQ.IsSolidsSeperatedFromLiquidEffluent} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field is-liquid-effluent-captured-same-as-dairy-effluent'>
                                        <Label>Is liquid effluent captured and treated the same as dairy effluent?</Label><br />
                                        <input className="form-check-input" name="IsLiquidEffluentTreatedSameAsDairyEffluent" type="checkbox" checked={itemPopupItemSepcQ.IsLiquidEffluentTreatedSameAsDairyEffluent} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field what-is-the-lining-material'>
                                        <Label>What is the lining material?</Label>
                                        <Input type="textbox" name="LiningMaterial" id="LiningMaterial" value={itemPopupItemSepcQ.LiningMaterial} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <FormGroup className='item-field how-often-is-this-structure-cleaned'>
                                        <Label>How often is this structure cleaned?</Label> <br />
                                        {StructureCleanedOn != undefined && StructureCleanedOn != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="StructureCleanedOn" id="StructureCleanedOn" value={itemPopupItemSepcQ.StructureCleanedOn}>
                                                <option value={0} >Select</option>
                                                {StructureCleanedOn.map(Option =>
                                                    <option key={uuid()} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup className='item-field is-the-pad-flushed-with-water-or-scraped'>
                                        <Label>Is the pad flushed with water or scraped?</Label>
                                        <Row>
                                            <Col>
                                                <input type="radio" name="PadType" value="Flushed" onChange={handleItemSepcValueChange} checked={itemPopupItemSepcQ.PadType === "Flushed"} /> {' '}
                                                <Label>Flushed</Label>
                                            </Col>
                                            <Col>
                                                <input type="radio" name="PadType" value="Scraped" onChange={handleItemSepcValueChange} checked={itemPopupItemSepcQ.PadType === "Scraped"} /> {' '}
                                                <Label>Scraped</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Sheds*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field shed-use'>
                                        <Label>Shed Use</Label>
                                        <Input type="textbox" name="ShedUse" id="ShedUse" value={itemPopupItemSepcQ.ShedUse} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Silage pits*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field silage-leachate-controls'>
                                        <Label>Silage Leachate Controls</Label>
                                        <Input type="textbox" name="SilageLeachateControls" id="SilageLeachateControls" value={itemPopupItemSepcQ.SilageLeachateControls} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Stock yards (include wool sheds etc)*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field stock-yard-type'>
                                        <Label>Stock Yard Type</Label>
                                        <Input type="textbox" name="StockYardType" id="StockYardType" value={itemPopupItemSepcQ.StockYardType} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field yard-effluent-controls'>
                                        <Label>Yard Effluent Controls</Label>
                                        <Input type="textbox" name="YardEffluentControls" id="YardEffluentControls" value={itemPopupItemSepcQ.YardEffluentControls} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Underpasses*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field underpass-effluent-management'>
                                        <Label>Controls in place to manage effluent</Label>
                                        <Input type="textbox" name="UnderpassEffluentManagement" id="UnderpassEffluentManagement" value={itemPopupItemSepcQ.UnderpassEffluentManagement} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Waste management*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field recycling'>
                                        <Label>Recycling</Label>
                                        <Input type="textbox" name="Recycling" id="Recycling" value={itemPopupItemSepcQ.Recycling} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className='item-field waste-collection'>
                                        <Label>Waste Collection</Label>
                                        <Input type="textbox" name="WasteCollection" id="WasteCollection" value={itemPopupItemSepcQ.WasteCollection} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* Water meter*/}
                            <Row >
                                <Col>
                                    <FormGroup className='item-field water-meter-type'>
                                        <Label>Meter Type</Label> <br />
                                        {WaterMeterTypes != undefined && WaterMeterTypes != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="WatermeterType" id="WatermeterType" value={itemPopupItemSepcQ.WatermeterType}>
                                                <option value={0} >Select</option>
                                                {WaterMeterTypes.map(Option =>
                                                    <option key={uuid()} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup className='item-field data-capture-type'>
                                        <Label>Data Capture Type</Label>
                                        {DataCaptureTypes != undefined && DataCaptureTypes != null &&
                                            <Input type="select" onChange={handleItemSepcValueChange} name="DataCaptureType" id="DataCaptureType" value={itemPopupItemSepcQ.DataCaptureType}>
                                                <option value={0} >Select</option>
                                                {DataCaptureTypes.map(Option =>
                                                    <option key={uuid()} value={Option.id}>{Option.value}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <FormGroup className='item-field is-consent-required'>
                                        <Label> Required by consent</Label> <br />
                                        <input className="form-check-input" name="IsConsentRequired" type="checkbox" checked={itemPopupItemSepcQ.IsConsentRequired} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <FormGroup className='item-field is-discharge-contained'>
                                        <Label>Is Discharge Contained</Label> <br />
                                        <input className="form-check-input" name="IsDischargeContained" type="checkbox" checked={itemPopupItemSepcQ.IsDischargeContained} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    {/*</Collapse>*/}
                </Card>
                <br />
            </>
        )
    }

}
export default InfrastructureItemSpecView