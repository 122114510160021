import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Card, CardBody, CardHeader, Collapse, Tooltip } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const LandSoilItemSpecView = ({ itemPopupItemField, itemPopupItemSepcQ, handleItemSepcValueChange, treeSpeciesList, forestryTypeList, blockInETSList, handleItemDatePlantedDate, drainageTypeList, handleItemDateStripDate }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const itemTypeFieldList = itemPopupItemField.itemFieldTypes;
    const itemFieldCount = itemTypeFieldList.length;

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        handleHideAllDiv(itemTypeFieldList);
    }, [])

    const handleHideAllDiv = (itemTypeFieldList) => {
        document.querySelectorAll(".item-field").forEach(element => {
            element.style.display = 'none';
        })

        itemTypeFieldList.map(itemTypeField => {
            let className = "." + itemTypeField.className;
            document.querySelectorAll(className).forEach(element => {
                element.style.display = 'block';
            })
        })
    }

    if (itemFieldCount > 0) {
        return (
            <>
                <Card>
                    {/*<CardHeader className="card-header-click" onClick={toggle}>*/}
                    {/*    <Row>*/}
                    {/*        <Col sm="11">*/}
                    {/*            <h6>Additional Information</h6>*/}
                    {/*        </Col>*/}
                    {/*        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>*/}
                    {/*    </Row>*/}
                    {/*</CardHeader>*/}
                    <CardBody>
                        {/*<Collapse isOpen={isOpen}>*/}
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field detention-dam'>
                                        <Label>Detention Dam</Label>
                                        <Input type="text" name="DetentionDam" id="DetentionDam" value={itemPopupItemSepcQ.DetentionDam} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field debris-dam'>
                                        <Label>Debris Dam</Label>
                                        <Input type="text" name="DebrisDam" id="DebrisDam" value={itemPopupItemSepcQ.DebrisDam} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field drop-flume'>
                                        <Label>Drop Flume</Label>
                                        <Input type="text" name="DropFlume" id="DropFlume" value={itemPopupItemSepcQ.DropFlume} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field dam'>
                                        <Label>Dam</Label>
                                        <Input type="text" name="Dam" id="Dam" value={itemPopupItemSepcQ.Dam} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field plantings'>
                                        <Label>Plantings</Label>
                                        <Input type="text" name="Plantings" id="Plantings" value={itemPopupItemSepcQ.Plantings} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field age'>
                                        <Label>Age</Label>
                                        <Input type="number" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} name="Age" id="Age" value={itemPopupItemSepcQ.Age} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field plant-date'>
                                        <Label>Planted Date</Label>
                                    <DatePicker showYearDropdown yearDropdownItemNumber={15} scrollableYearDropdown selected={itemPopupItemSepcQ.PlantedDate} onChange={(date) => handleItemDatePlantedDate(date)} name="PlantedDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field forestry-type'>
                                        <Label>Forestry Type</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="ForestryType" id="ForestryType" value={itemPopupItemSepcQ.ForestryType} >
                                            {forestryTypeList != undefined && forestryTypeList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field tree-species'>
                                        <Label>Tree Species</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="TreeSpecies" id="TreeSpecies" value={itemPopupItemSepcQ.TreeSpecies} >
                                            {treeSpeciesList != undefined && treeSpeciesList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field been-harvested'>
                                        <Label>Has been harvested in the last 10 years</Label><br />
                                        <input className="form-check-input" name="IsBeenHarvestedLast10Years" type="checkbox" checked={itemPopupItemSepcQ.IsBeenHarvestedLast10Years} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field block-in-ets'>
                                        <Label>Is this block in the ETS</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="BlockInETS" id="BlockInETS" value={itemPopupItemSepcQ.BlockInETS} >
                                            {blockInETSList != undefined && blockInETSList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field stem-count-per-ha'>
                                        <Label>Stem Count Per Ha</Label>
                                        <Input onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} type="number" name="StemCountPerHA" id="StemCountPerHA" value={itemPopupItemSepcQ.StemCountPerHA} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field covenentad'>
                                        <Label>Covenented</Label><br />
                                        <input className="form-check-input" name="Covenentad" type="checkbox" checked={itemPopupItemSepcQ.Covenentad} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field drainage-type'>
                                        <Label>What type of drainage used</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="DrainageType" id="DrainageType" value={itemPopupItemSepcQ.DrainageType} >
                                            {drainageTypeList != undefined && drainageTypeList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <FormGroup className='item-field is-CSA-within-intensive-winter-grazing-area'>
                                        <Label>Is CSA Within Intensive Winter Grazing Area</Label> <br />
                                        <input className="form-check-input" name="IsCSAWithinIntensiveWinterGrazingArea" type="checkbox" checked={itemPopupItemSepcQ.IsCSAWithinIntensiveWinterGrazingArea} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>    
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field fertiliser-strip-date'>
                                        <Label>Date</Label>
                                        <DatePicker selected={itemPopupItemSepcQ.StripDate} onChange={(date) => handleItemDateStripDate(date)} name="StripDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field optimal-olsen-prange'>
                                        <Label>Optimal Olsen P Range</Label>
                                        <Input type="text" name="OptimalRange" id="OptimalRange" value={itemPopupItemSepcQ.OptimalRange} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field latest-olsen-pvalue-range'>
                                        <Label>Latest OLSEN P Value Range</Label>
                                        <Input type="text" name="OlsenpValueRange" id="OlsenpValueRange" value={itemPopupItemSepcQ.OlsenpValueRange} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        {/*</Collapse>*/}
                    </CardBody>
                </Card>
                <br />
            </>
        )
    }

}
export default LandSoilItemSpecView