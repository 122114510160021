import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Table, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup,Label,Input } from 'reactstrap';
import { FaPlus, FaEdit, FaTrash, FaFileExcel } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';
import { useDownloadExcel } from 'react-export-table-to-excel';
import "../ExternalOrganisation/ExternalOrganisation.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const ExternalOrganisation = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [externalOrganisationList, setExternalOrganisationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(0);
    const [name, setName] = useState('');
    const tableRef = useRef(null);
    const [organisationType, setOrganisationType] = useState(0);
    const [organisationTypeText, setOrganisationTypeText] = useState('');
    const [externalOrganisationItems, setExternalOrganisationItems] = useState([]);
    const [externalOrganisationTempItems, setExternalOrganisationTempItems] = useState([]);
    const navigate = useNavigate();
    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let organisationTypeValue = params.get('organisationType') == null ? 0 : params.get('organisationType');
            let nameValue = params.get('name') == null ? '' : params.get('name');
            setOrganisationType(params.get('organisationType') == null ? 0 : params.get('organisationType'));
            setName(params.get('name') == null ? '' : params.get('name'));
            if (searchParams.has('organisationType')) {
                searchParams.delete('organisationType');
                setSearchParams(searchParams);
            }
            if (searchParams.has('name')) {
                searchParams.delete('name');
                setSearchParams(searchParams);
            }
            const response = await axios.get(
                '/api/settings/GetExternalOrganisations/', {
                params: {}
            }
            );
            setExternalOrganisationList(response.data.content);
            setExternalOrganisationItems(response.data.content.externalOrganisations);
            setExternalOrganisationTempItems(response.data.content.externalOrganisations);
            var externalOrganisation = response.data.content.organisationTypeList.filter(x => x.id == parseInt(organisationTypeValue));
            if (externalOrganisation.length > 0 && externalOrganisation[0].id != 0) {
                setOrganisationTypeText(externalOrganisation[0].name);
                if (nameValue != '') {
                    var list = [];
                    list = response.data.content.externalOrganisations.filter(x => (x.name.toLowerCase().includes(nameValue.toLowerCase())));
                    list = list.filter(x => (x.organisationTypeText.toLowerCase().includes(externalOrganisation[0].name.toLowerCase())));
                    setExternalOrganisationItems(list);
                }
                else {
                    var list = [];
                    list = response.data.content.externalOrganisations.filter(x => (x.organisationTypeText.toLowerCase().includes(externalOrganisation[0].name.toLowerCase())));
                    setExternalOrganisationItems(list);
                }
            }
            else {
                setOrganisationTypeText('All')
                if (nameValue == '') {
                    setExternalOrganisationItems(response.data.content.externalOrganisations)
                }
                else {
                    var list = [];
                    list = response.data.content.externalOrganisations.filter(x => (x.name.toLowerCase().includes(nameValue.toLowerCase())));
                    setExternalOrganisationItems(list)
                }
            }
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {
                
            } else if (err.response?.status === 400) {
                
            } else {
                
            }
        }
    }

    useEffect(() => {
        populateItemData();
    },[])

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const confirmDeleteItemPopup = async () => {
        const response = await axios.get(
            '/api/settings/DeleteExternalOrganisation/', {
                params: { Id: deleteItemId, userId: userId }
        }
        );
        setShowDeleteItemPopup(false);
        populateItemData();
    }
    const handleChangeName = async (event) => {
        setName(event.target.value);
        if (event.target.value == '') {
            if (organisationTypeText == 'All') {
                setExternalOrganisationItems(externalOrganisationTempItems)
            }
            else {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.organisationTypeText.toLowerCase().includes(organisationTypeText.toLowerCase())));
                setExternalOrganisationItems(list)
            }
        }
        else {
            if (organisationTypeText != 'All') {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.name.toLowerCase().includes(event.target.value.toLowerCase()) || x.organisationTypeText.toLowerCase().includes(event.target.value.toLowerCase())));
                list = list.filter(x => (x.organisationTypeText.toLowerCase().includes(organisationTypeText.toLowerCase())));
                setExternalOrganisationItems(list);
            }
            else {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.name.toLowerCase().includes(event.target.value.toLowerCase()) || x.organisationTypeText.toLowerCase().includes(event.target.value.toLowerCase())));
                setExternalOrganisationItems(list);
            }
        }
    }
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'External Organisations',
        sheet: 'ExternalOrganisation'
    })

    const handleChangeOrganisationType = async (event) => {
        setOrganisationType(parseInt(event.target.value));
        setOrganisationTypeText(event.target.selectedOptions[0].text);
        if (event.target.selectedOptions[0].text == 'All') {
            if (name == '') {
                setExternalOrganisationItems(externalOrganisationTempItems)
            }
            else {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.name.toLowerCase().includes(name.toLowerCase())));
                setExternalOrganisationItems(list)
            }
            
        }
        else {
            if (name != '') {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.name.toLowerCase().includes(name.toLowerCase())));
                list = list.filter(x => (x.organisationTypeText.toLowerCase().includes(event.target.selectedOptions[0].text.toLowerCase())));
                setExternalOrganisationItems(list);
            }
            else {
                var list = [];
                list = externalOrganisationTempItems.filter(x => (x.organisationTypeText.toLowerCase().includes(event.target.selectedOptions[0].text.toLowerCase())));
                setExternalOrganisationItems(list);
            }
            
        }
    }

    const open = (id, routePath) => {
        window.location = '/' + routePath + '?id=' + id + '&organisationType=' + organisationType + '&name=' + name;
        //navigate('/' + routePath, { state: { id: id } });
    }

    return (
        <div>
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Organisations
                    <button type="button" onClick={(id, routePath) => open(0, 'createexternalorganisation')} id="btnUpdate" className="btn btn-sm plusIcon-small">
                        Add
                    </button>
                </h2>
                <CardBody>
                    <Row>
                        <Col md="7">
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Type</Label>
                                {externalOrganisationList.organisationTypeList == undefined && <p><em>Loading...</em></p>}
                                {externalOrganisationList.organisationTypeList != undefined && externalOrganisationList.organisationTypeList != null &&
                                    <Input className="validate" type="select" value={organisationType} onChange={e => handleChangeOrganisationType(e)} name="OrganisationType" id="OrganisationType">
                                        {externalOrganisationList.organisationTypeList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Search</Label>
                                <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col md="1">
                            <br />
                            <button onClick={onDownload} style={{ marginLeft: "15px", marginTop: "10px" }} type="button" id="btnUpdate" className="btn btn-sm btn-success">
                                <FaFileExcel /> Export
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th width="40%">Description</th>
                                        <th width ="5%">Edit</th>
                                        <th width="5%">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {externalOrganisationItems == undefined &&
                                        <tr><td colSpan="5" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {externalOrganisationItems != undefined && externalOrganisationItems != null && externalOrganisationItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.name}</td>
                                            <td>{item.organisationTypeText} </td>
                                            <td>{item.description} </td>
                                            <td>
                                                <button type="button" onClick={(id, routePath) => open(item.id, 'editexternalorganisation')}
                                                    id="btnUpdate"
                                                    color="warning"
                                                    className="btn btn-sm btn-edit button-normal">
                                                    <FaEdit />
                                                </button>
                                            </td>
                                            <td>

                                                <button type="button"
                                                    id="btnUpdate"
                                                    className="btn btn-sm btn-danger button-delete"
                                                    onClick={() => openDeleteItemPopup(item)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <table style={{ display: "none" }} ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {externalOrganisationItems == undefined &&
                                        <tr><td colSpan="5" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {externalOrganisationItems != undefined && externalOrganisationItems != null && externalOrganisationItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.name}</td>
                                            <td>{item.organisationTypeText} </td>
                                            <td>{item.description} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    ); 
}

export default ExternalOrganisation;