import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaExclamationCircle } from 'react-icons/fa';
import uuid from 'react-uuid';
const WaterwaysItemSpecView = ({ riparianPlantingList, handleAlertPopupOpen, formErrors, itemPopupItemField, itemPopupItemSepcQ, handleItemSepcValueChange, percentageFencedList, averageRiparianMarginPlantedList, waterwayWidthList, percentageOfLengthRiparianPlantedList }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const itemTypeFieldList = itemPopupItemField.itemFieldTypes;
    const itemFieldCount = itemTypeFieldList.length;

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        handleHideAllDiv(itemTypeFieldList);
    }, [])

    const handleHideAllDiv = (itemTypeFieldList) => {
        document.querySelectorAll(".item-field").forEach(element => {
            element.style.display = 'none';
        })

        itemTypeFieldList.map(itemTypeField => {
            let className = "." + itemTypeField.className;
            document.querySelectorAll(className).forEach(element => {
                element.style.display = 'block';
            })
        })
    }

    if (itemFieldCount > 0) {
        return (
            <>
                <Card>
                    {/*<CardHeader className="card-header-click" onClick={toggle}>*/}
                    {/*    <Row>*/}
                    {/*        <Col sm="11">*/}
                    {/*            <h6>Additional Information</h6>*/}
                    {/*        </Col>*/}
                    {/*        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>*/}
                    {/*    </Row>*/}
                    {/*</CardHeader>*/}
                    <CardBody>
                        {/*<Collapse isOpen={isOpen}>*/}
                            <Row >
                                <Col sm="3">
                                    <FormGroup className='item-field boundary-waterway'>
                                        <Label>Boundary Waterway</Label><br />
                                        <input className="form-check-input" name="IsBoundaryPresent" type="checkbox" checked={itemPopupItemSepcQ.IsBoundaryPresent} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="3"></Col>
                                <Col sm="6">
                                    <FormGroup className='item-field waterway-width'>
                                        <Label>Waterway Width</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="WaterwayWidth" id="WaterwayWidth" value={itemPopupItemSepcQ.WaterwayWidth} >
                                            {waterwayWidthList != undefined && waterwayWidthList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="3">
                                    <FormGroup className='item-field fenced'>
                                        <Label>Fenced</Label><br />
                                        <input className="form-check-input" name="IsFenced" type="checkbox" checked={itemPopupItemSepcQ.IsFenced} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="3"></Col>
                                <Col sm="6">
                                    <FormGroup className='item-field percentage-fenced'>
                                        {/*{formErrors.FencingPercentage != '' &&*/}
                                        {/*    <button type="button" key={uuid()} style={{ border: "none", background: "none", fontSize: "14px", marginLeft: "-40px", marginRight: "10px" }} className="btn btn-sm btn-danger" onClick={() => handleAlertPopupOpen("FencingPercentage",formErrors.FencingPercentage)}><FaExclamationCircle color="red" fontSize="1.5em" /></button>*/}
                                        {/*}*/}
                                        <Label>Percentage Fenced</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="FencingPercentage" id="FencingPercentage" value={itemPopupItemSepcQ.FencingPercentage} >
                                            {percentageFencedList != undefined && percentageFencedList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="3">
                                    <FormGroup className='item-field riparian-planted'>
                                        <Label>Riparian Planted</Label><br />
                                        <input className="form-check-input" name="IsRiparianPlanted" type="checkbox" checked={itemPopupItemSepcQ.IsRiparianPlanted} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="3"></Col>
                                <Col sm="6">
                                    <FormGroup className='item-field average-riparian-margin-planted'>
                                        <Label>Average Riparian Margin Planted</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="RiprianAvg" id="RiprianAvg" value={itemPopupItemSepcQ.RiprianAvg} >
                                            {averageRiparianMarginPlantedList != undefined && averageRiparianMarginPlantedList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field percentage-of-length-riparian-planted'>
                                        <Label>% of Length Riparian Planted</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="PercentageOfLengthRiparianPlanted" id="PercentageOfLengthRiparianPlanted" value={itemPopupItemSepcQ.PercentageOfLengthRiparianPlanted} >
                                            {percentageOfLengthRiparianPlantedList != undefined && percentageOfLengthRiparianPlantedList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                            </Col>
                            {itemPopupItemField.itemTypeName != 'Culvert' && 
                                <Col sm="6">
                                    <FormGroup className=''>
                                        <Label>Riparian Planting</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="RiparianPlanting" id="RiparianPlanting" value={itemPopupItemSepcQ.RiparianPlanting} >
                                            <option key={uuid()} value="">Select</option>
                                            {riparianPlantingList != undefined && riparianPlantingList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            }
                            </Row>
                            <Row >
                                <Col sm="3">
                                    <FormGroup className='item-field freshwater-site-of-significance'>
                                        <Label>Freshwater Site of Significance</Label><br />
                                        <input className="form-check-input" name="IsFreshwaterSiteofSignificance" type="checkbox" checked={itemPopupItemSepcQ.IsFreshwaterSiteofSignificance} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="3"></Col>
                                <Col sm="6">
                                    <FormGroup className='item-field stem-count-per-ha'>
                                        <Label>Stem Count Per Ha</Label>
                                        <Input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} type="number" name="StemCountPerHA" id="StemCountPerHA" value={itemPopupItemSepcQ.StemCountPerHA} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className='item-field bridged-water-crossings-controls'>
                                        <Label>Bridged Water Crossings Controls</Label>
                                        <Input type="text" name="BridgedWaterCrossingsControls" id="BridgedWaterCrossingsControls" value={itemPopupItemSepcQ.BridgedWaterCrossingsControls} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className='item-field culverted-crossings-controls'>
                                        <Label>Culverted Crossings Controls</Label>
                                        <Input type="text" name="CulvertedCrossingsControls" id="CulvertedCrossingsControls" value={itemPopupItemSepcQ.CulvertedCrossingsControls} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="3">
                                    <FormGroup className='item-field is-fish-screen'>
                                        <Label>Fish Screen</Label><br />
                                        <input className="form-check-input" name="IsFishScreen" type="checkbox" checked={itemPopupItemSepcQ.IsFishScreen} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                        </Row>
                        {/*</Collapse>*/}
                    </CardBody>
                </Card>
                <br />
            </>
        )
    }

}
export default WaterwaysItemSpecView