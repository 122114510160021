import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Spinner, Card, CardBody, Table, Badge, Label, CardHeader, CardText, CardTitle, CardFooter, Button } from 'reactstrap';
import uuid from 'react-uuid';
import './CatchmentContext.css';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import OutlineBase from '../Outline/OutlineBase';
import MapView from '../Map/MapView';
import FarmAPTThanksTo from '../FarmApt/FarmAPTThanksTo';
import { Tooltip as ReactTooltip } from "react-tooltip";

const FarmApt = ({ elementRef, elementRef1, elementRef2 }) => {
    const [nitrogen, setNitrogen] = useState({});
    const [phosphorus, setPhosphorus] = useState({});
    const [bacteria, setBacteria] = useState({});
    const [sediment, setSediment] = useState({});
    const [sites, setSites] = useState({});
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [farmAPT, setFarmAPT] = useState({ outlineList: [], itemList: [], zoomLevelList: [] });
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: true, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 15, polytype: '', itemsEditable: false, showDetails: false
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0 })
    const [loading, setLoading] = useState(true); const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        setLoading(true);
        getApt();
    }, [])

    const getApt = async () => {
        try {
            const response = await axios.get('/api/catchmentcontext/getfarmapt/', {
                params: { FarmId: farmId },
                headers: { 'Content-Type': 'application/json' }
            });
            setShowDetails(response.data.showDetails);
            if (response.data.apiResponse != null && response.data.apiResponse.wq != null) {
                setNitrogen(response.data.apiResponse.wq.n)
                setPhosphorus(response.data.apiResponse.wq.p)
                setBacteria(response.data.apiResponse.wq.b)
                setSediment(response.data.apiResponse.wq.s)
                setSites(response.data.apiResponse.wq.sites)
            }
            setFarmAPT({ ...farmAPT, outlineList: response.data.outlineList, itemList: response.data.itemList, zoomLevelList: response.data.zoomLevelList })
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel })
            setLoading(false);

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message);
            } else {
                toast.error("No server response");
            }
        }
    }

    const getRatingBadge = (value) => {
        let grade = "";
        if (value == "A")
            grade = "success";
        else if (value == "B")
            grade = "warning";
        else if (value == "C")
            grade = "primary";
        else if (value == "D")
            grade = "danger";
        else if (value == "E")
            grade = "dark";
        else
            grade = "secondary";

        return (<Badge color={grade}>{value}</Badge>);
    }

    const handleZoomChange = async (event) => {
        let form = {
            FarmId: parseInt(farmId),
            UserId: userId,
            ZoomLevel: parseInt(event.target.value)
        }
        try {
            const response = await axios.post(
                '/api/CatchmentContext/UpdateCatchmentContext/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data.status) {
                setMapDetails({ ...mapDetails, zoomLevel: parseInt(response.data.content.zoomLevel) });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            {loading ?
                <Spinner color="success"> Loading... </Spinner>
                : <div>
                    {
                        showDetails && <div>
                            <p>Latest Water quality Assessments from nearest recording stations</p>
                            <p>This information comes from LAWA (Land Air Water Aoreatoa - see <a target="_blank" href="https://www.lawa.org.nz/">https://www.lawa.org.nz/</a>).</p>
                            <div ref={elementRef}>
                                <Row>
                                    <Col sm="9">
                                        <Row className="farm-atp-table">
                                            <Col sm="12" className="my-2">

                                                <Card>
                                                    <CardBody>
                                                        <h6>Nitrogen</h6>
                                                        {nitrogen != undefined && nitrogen != null && sites != null ?
                                                            <Table size="sm">
                                                                {
                                                                    <tbody>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ width: "15%" }} key={uuid()}> {nitrogen.ton?.indicator} </td>
                                                                            <td style={{ width: "20%" }} key={uuid()}> {nitrogen.ton?.median?.value} {nitrogen.ton?.median?.units} </td>
                                                                            <td style={{ width: "5%" }} key={uuid()}> {getRatingBadge(nitrogen.ton?.band)} </td>

                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {nitrogen.ton?.site_description}</td>
                                                                        </tr>
                                                                        <tr key={uuid()}>
                                                                            <td key={uuid()}> {nitrogen.nh4?.indicator} </td>
                                                                            <td key={uuid()}> {nitrogen.nh4?.median?.value} {nitrogen.nh4?.median?.units} </td>
                                                                            <td key={uuid()}> {getRatingBadge(nitrogen.nh4?.band)} </td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {nitrogen.nh4?.site_description}</td>
                                                                        </tr>
                                                                        <tr key={uuid()}>
                                                                            <td key={uuid()}> {nitrogen.tn?.indicator} </td>
                                                                            <td key={uuid()}> {nitrogen.tn?.median?.value} {nitrogen.tn?.median?.units} </td>
                                                                            <td key={uuid()}> <Label className="text-warning">Q{nitrogen.tn?.quartile} </Label></td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {nitrogen.tn?.site_description}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                }
                                                            </Table> : <span>No data available</span>
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="12" className="my-2">
                                                <Card>
                                                    <CardBody>
                                                        <h6>Phosphorus</h6>
                                                        {phosphorus != undefined && phosphorus != null && sites != null ?
                                                            <Table size="sm">
                                                                {
                                                                    <tbody>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ width: "15%" }} key={uuid()}> {phosphorus.drp?.indicator} </td>
                                                                            <td style={{ width: "20%" }} key={uuid()}> {phosphorus.drp?.median?.value} {phosphorus.drp?.median?.units} </td>
                                                                            <td style={{ width: "5%" }} key={uuid()}> {getRatingBadge(phosphorus.drp?.band)} </td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {phosphorus.drp?.site_description}</td>
                                                                        </tr>
                                                                        <tr key={uuid()}>
                                                                            <td key={uuid()}> {phosphorus.tp?.indicator} </td>
                                                                            <td key={uuid()}> {phosphorus.tp?.median?.value} {phosphorus.drp?.median?.units} </td>
                                                                            <td key={uuid()}> <Label className="text-warning">Q{phosphorus.tp?.quartile}</Label> </td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {phosphorus.tp?.site_description}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                }
                                                            </Table> : <span>No data available</span>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="12" className="my-2">
                                                <Card>
                                                    <CardBody>
                                                        <h6>Bacteria</h6>
                                                        {bacteria != undefined && bacteria != null && sites != null ?
                                                            <Table size="sm">
                                                                {
                                                                    <tbody>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ width: "15%" }} key={uuid()}> {bacteria.ecoli?.indicator} </td>
                                                                            <td style={{ width: "20%" }} key={uuid()}> {bacteria.ecoli?.median?.value} {bacteria.ecoli?.median?.units} </td>
                                                                            <td style={{ width: "5%" }} key={uuid()}> {getRatingBadge(bacteria.ecoli?.band)} </td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {bacteria.ecoli?.site_description}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                }
                                                            </Table> : <span>No data available</span>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="12" className="my-2">
                                                <Card>
                                                    <CardBody>
                                                        <h6>Sediment</h6>
                                                        {sediment != undefined > 0 && sediment != null && sites != null ?
                                                            <Table size="sm">
                                                                {
                                                                    <tbody>
                                                                        <tr key={uuid()}>
                                                                            <td style={{ width: "15%" }} key={uuid()}> {sediment.bdisc?.indicator} </td>
                                                                            <td style={{ width: "20%" }} key={uuid()}> {sediment.bdisc?.median?.value} {sediment.bdisc?.median?.units} </td>
                                                                            <td style={{ width: "5%" }} key={uuid()}> {getRatingBadge(sediment.bdisc?.band)} </td>
                                                                            <td style={{ width: "60%" }} key={uuid()}>Recorded at site {sediment.bdisc?.site_description}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                }
                                                            </Table> : <span>No data available</span>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm="3">
                                    <Card style={{ backgroundColor: 'lightgrey', borderColor: 'lightgrey' }}>
                                        <CardHeader style={{ textAlign: "center" }}><b>Key</b></CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3">
                                                    <Badge color="success">A</Badge>
                                                </Col>
                                                <Col sm="9">
                                                    <span>Good Quality</span>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:"5px"}}>
                                                <Col sm="3">
                                                    <Badge color="warning">B</Badge>
                                                </Col>
                                                <Col sm="9">
                                                    <span>Moderate Quality</span>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "5px" }}>
                                                <Col sm="3">
                                                    <Badge color="primary">C</Badge>
                                                </Col>
                                                <Col sm="9">
                                                    <span>Needs improvement</span>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "5px" }}>
                                                <Col sm="3">
                                                    <Badge color="danger">D</Badge>
                                                </Col>
                                                <Col sm="9">
                                                    <span>Significantly degraded</span>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "5px" }}>
                                                <Col sm="3">
                                                    <Badge color="dark">E</Badge>
                                                </Col>
                                                <Col sm="9">
                                                    <span>Need significant improvement - high health risk</span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <Row>
                                                <Col>
                                                    <span style={{ fontSize: "12px" }}><b>Note:</b> E.coli is the only recording that has a 5 band rating system (A-E). All others use 4 bands (A-D or Q1-Q4)</span>
                                                </Col>
                                            </Row>
                                            </CardFooter>
                                    </Card>
                                </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col className="col-md-3">
                                    <Row>
                                        <Col>
                                            <label className="form-label"><b>Zoom Level</b></label>
                                        </Col>
                                    </Row>
                                    <Row className="farmoutline-div-style">
                                        <Col>
                                            <select className="form-control input-sm map-popup-input-text" value={mapDetails.zoomLevel} onChange={handleZoomChange}>
                                                {farmAPT.zoomLevelList.map(zoomlist =>
                                                    <option key={uuid()} value={zoomlist.zoomValue}>{zoomlist.zoomText}</option>
                                                )}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div ref={elementRef1}>
                                <Row>
                                    <Col className="my-4">
                                        <MapView itemModel={farmAPT} mapDetails={mapDetails} largeEnterpriseDetails={largeEnterpriseDetails} width={'100%'}></MapView>
                                    </Col>
                                </Row>
                            </div>
                            {/*<Row>*/}
                            {/*    <Col className="my-4">*/}
                            {/*        <MapView itemModel={farmAPT} mapDetails={mapDetails} largeEnterpriseDetails={largeEnterpriseDetails} width={'100%'}></MapView>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </div>
                    }
                    {!showDetails && <div>
                        <p>Unfortunately, no data is available for water quality</p>
                    </div>}
                    <div ref={elementRef2}>
                        <FarmAPTThanksTo></FarmAPTThanksTo>
                    </div>
                  </div>
            }
            

        </>

    )
}

export default FarmApt