import { useState, useMemo } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse, Table, FormGroup, Label, Input } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import MapView from '../Map/MapView';
const AllFarmMap = ({ itemModel }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true); 
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false, isAllFarmDetails: true,
        isFarmOutlineEditable: false, zoomLevel: 5, polytype: '', itemsEditable: false, highlighteditemId: 0
    });
    const toggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    /*Avoid unwanted page refresh*/
    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={itemModel}
                mapDetails={mapDetails}
            ></MapView>
        )
    }, [itemModel, mapDetails])

    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={toggle}>
                            <Row>
                                <Col sm="11">
                                    <h6>All Farms Map
                                       
                                    </h6>
                                </Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div> 
                                    <Row>
                                        {mapView}
                                    </Row>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default AllFarmMap;