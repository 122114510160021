import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Label, Input, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import moment from "moment";
import uuid from 'react-uuid';

const OpenActionSummaryItem = ({ itemObj, itemObjList, expand }) => {
    const [isOpen, setIsOpen] = useState(expand);
    const [isIconUp, setIsIconUp] = useState(expand);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    let divId = "divOpenAction" + itemObj.id;

    return (
        <div id={divId}>
        <Card className="my-4">
            <CardHeader className="" onClick={toggle}>
                <Row>
                        {/*<Col sm="11"><span className="text-uppercase fw-bold color-dark-teal">{'Unique Identifier: '+ itemObj.name}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-uppercase">{'#Open Actions: '+itemObjList[0].totalOpenAction}</span></Col>*/}
                        <Col sm="7">
                            <span className="text-uppercase fw-bold color-dark-teal">{itemObjList[0].farmName} - {itemObj.name}</span>
                        </Col>
                        <Col sm="4">
                            <span className="text-uppercase fw-bold color-dark-teal">{'#Open Actions: ' + itemObjList[0].totalOpenAction}</span>
                        </Col>
                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody >
                    <Row>
                        <Col>
                            <table id="tblDocumentList" className="table borderless table-striped border-top-bottom">
                                <thead className="textAlignCenter">
                                    <tr>
                                        <th>Due Date</th>
                                        <th>Item</th>
                                        <th>Description</th>
                                    </tr>

                                </thead>
                                <tbody className="documentList textAlignCenter" id="documentList">
                                    {itemObjList[0] != undefined && itemObjList[0].openActionsList != undefined && itemObjList[0].openActionsList.length > 0 && itemObjList[0].openActionsList.map(summary =>
                                        <tr key={uuid()}>
                                            <td>{summary.actionTypeName == "Improvement" ? moment(summary.dueDate).format('DD/MM/YYYY') : 'Ongoing / Management' }</td>
                                            <td>{summary.itemName}</td>
                                            <td>{summary.description}</td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </CardBody>
            </Collapse>
        </Card>
        </div>
    )
}
export default OpenActionSummaryItem