import { useState, useEffect, useMemo} from 'react';
import { Row, Col } from 'reactstrap';
import './Outline.css';
import axios from 'axios';
import { withScriptjs } from "react-google-maps";
import toast, { Toaster } from 'react-hot-toast';
import { Map } from '../Map';
const MapLoader = withScriptjs(Map);


const OutlineBase = () => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [outlineList, setOutlineList] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(15);
    const [mapDetails, setMapDetails] = useState({ isOutlinePage: false, isLMUPage: false, isCenterReq: true, isFarmCenterEditable: false, isFarmOutlineEditable: false, zoomLevel: 15, polytype: '' });

    useEffect(() => {
        onPageload();
    }, [])

    const onPageload = async () => {
        try {
            const response = await axios.get('/api/outline/getOutlineBase/', {
                params: {
                    farmId: farmId
                }
            }, {
                headers: { 'Content-Type': 'application/json' }
            });

            console.log(response);
            setMapDetails({ ...mapDetails, zoomLevel: response?.data?.selectedZoomlevel });
            setOutlineList(response?.data?.outlineList);

        } catch (error) {
            if (!error?.response) {
                toast.error(error.response?.status + ": " + error.message);
            } else {
                toast.error("No server response");
            }
        }
    }

    const loadMapItems = () => {
        let polytype = '';
        let kmlUpload = [];

        const data = { polytype, kmlUpload, outlineList, mapDetails, zoomLevel };

        return (
            <MapLoader
                data={data}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYN1ILqaOOYkmN8-rk3T0ImqfQGY07Nfw&libraries=drawing,geometry"
                loadingElement={<div style={{ height: `100%` }} />}
            />
        );
    }

    const mapView = useMemo(() => loadMapItems(), [outlineList, mapDetails, zoomLevel]);

    return (
        <>
            <Row>
                <Col>
                    {mapView}
                </Col>
            </Row>
        </>
    );
}
export default OutlineBase