import { useState, useEffect } from 'react';
import {
    Row, Col, Table, Card, CardBody, CardHeader, Button, Input, Collapse,
    ListGroup, ListGroupItem, Badge, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import './Lmu.css';
import LmuChildItem from './LmuChildItem';
import { FaChevronUp, FaChevronDown, FaMapMarkerAlt, FaDrawPolygon, FaPencilAlt, FaPlus } from 'react-icons/fa';
import uuid from 'react-uuid';
import Moment from 'moment';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';

const LmuItem = ({ item, itemTypesList, mapDetails, topographyList, soilTypePredominantList, enterpriseTypeList, toggleItemState, toggleChildItemState,
    handleEditItem, handleDeleteItem, blockOnly, hideChildren, isClosedActionShow, handleCenterPinClick, handleBlockOnlySelect,
    handleBlockZoomlevel, handleBlockZoomlevelSave, handleOpenActionPopup, handleItemToggle, handleItemHighlight, handleClosedActionShow, handleChildItemToggle, handleHideChildrenCheck }) => {
    const [isOpen, setIsOpen] = useState(item.id == toggleItemState.lmuId ? toggleItemState.isOpen : false);
    const [isIconUp, setIsIconUp] = useState(item.id == toggleItemState.lmuId ? toggleItemState.isIconUp : false);
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    const [inherentRisksText, setInherentRisksText] = useState('');
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, section: '', itemTypes: itemTypesList, maintenancePlanId: 0, routePath: '' });
    const [inherentRisks, setInherentRisks] = useState(0); const [soilTypePreDominant, setSoilTypePreDominant] = useState(''); const [soilTypePreDominantPer, setSoilTypePreDominantPer] = useState('');
    const [soilTypeOther, setSoilTypeOther] = useState(''); const [soilTypeOtherPer, setSoilTypeOtherPer] = useState('');
    const [showEnterprise, setShowEnterprise] = useState(true); const [showArchiveOption, setShowArchiveOption] = useState(true);
    useEffect(() => {
        setInherentRisksText(item.lmuItems[0].inherentRisksToFreshwaterText);
        setInherentRisks((item.lmuItems[0].inherentRisksToFreshwater == '' || item.lmuItems[0].inherentRisksToFreshwater == null) ? 0 : item.lmuItems[0].inherentRisksToFreshwater.split(',').length);
        setSoilTypePreDominant((item.lmuItems[0].soilTypePredominantText == '' || item.lmuItems[0].soilTypePredominantText == null) ? '' : item.lmuItems[0].soilTypePredominantText);
        setSoilTypePreDominantPer((item.lmuItems[0].soilTypePredominantPercentage == 0 || item.lmuItems[0].soilTypePredominantPercentage == null) ? '' : item.lmuItems[0].soilTypePredominantPercentage);
        setSoilTypeOther((item.lmuItems[0].soilTypeOtherText == '' || item.lmuItems[0].soilTypeOtherText == null) ? '' : item.lmuItems[0].soilTypeOtherText);
        setSoilTypeOtherPer((item.lmuItems[0].soilTypeOtherPercentage == 0 || item.lmuItems[0].soilTypeOtherPercentage == null) ? '' : item.lmuItems[0].soilTypeOtherPercentage);
        setShowArchiveOption(item.showArchiveOption);
        if (enterpriseTypeList.length == 1) {
            if (enterpriseTypeList[0].enterpriseTypeText == 'Kiwifruit') {
                setShowEnterprise(false);
            }
            else {
                if (item.lmuItems[0].enterpriseType.split(',').length == 1) {
                    if (enterpriseTypeList.filter(x => x.value == parseInt(item.lmuItems[0].enterpriseType.split(',')[0]))[0].enterpriseTypeText == 'Kiwifruit') {
                        setShowEnterprise(false);
                    }
                }
                else {
                    setShowEnterprise(true);
                }
            }
        }
        else {
            if (item.lmuItems[0].enterpriseType.split(',').length == 1) {
                if (enterpriseTypeList.filter(x => x.value == parseInt(item.lmuItems[0].enterpriseType.split(',')[0])).length != 0 &&
                    enterpriseTypeList.filter(x => x.value == parseInt(item.lmuItems[0].enterpriseType.split(',')[0]))[0].enterpriseTypeText == 'Kiwifruit') {
                    setShowEnterprise(false);
                }
            }
            else {
                setShowEnterprise(true);
            }
        }
    }, []);    
    const handleItemExpandCollapse = (itemId) => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleItemToggle(itemId, !isOpen, !isIconUp)
    };

    /*Item- MaintenancePlan Popup handle*/  
    const handleOpenMaintenancePlanPopup = (itemId, itemName, itemTypeId, section, maintenancePlanId, routePath) => {
        setMaintenancePlanPopup({
            ...maintenancePlanPopup, showMaintenancePlanPopup: true,
            itemId: itemId, itemName: itemName, itemTypeId: itemTypeId, actionId: 0,
            section: section, maintenancePlanId: maintenancePlanId, routePath: "/"+ routePath
        });
    }

    const handleMaintenancePlanClose = () => {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, section: '', maintenancePlanId: 0, routePath: '' });
    }
    const hasCentrePin = item.lmuItems[0].centerCoordinates == null || item.lmuItems[0].centerCoordinates == '' ? false : true;
    const defaultZoomlevel = blockOnly.itemId == item.id && blockOnly.defaultZoomLevel != null ? blockOnly.defaultZoomLevel : item.lmuItems[0].defaultZoomlevel == null || item.lmuItems[0].defaultZoomlevel == '' ? mapDetails.zoomLevel : item.lmuItems[0].defaultZoomlevel;

    const calculateSizeHa = (itemId) => {

        let size = item.lmuItems[0].sizeHa;

        item.nonContiguousItems?.map(nc => {
            if (itemId === nc.lmuId) {
                size += nc.lmuItems[0].sizeHa
            }
        });

        return +size.toFixed(2);
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    
    if (item != undefined && item.isConnectedToAnotherLMU == false ) {
        const ImagesCount = item.imageList != null ? item.imageList.length : 0;
        let openActionCount = item.actions.filter(action => action.isCloseout != true).length;
        const closedActionCount = item.actions.filter(action => action.isCloseout == true).length;
        const childLus = item.nonContiguousItems;
        if (childLus.length > 0) {
            childLus.map(child => {
                openActionCount = openActionCount + child.actions.filter(action => action.isCloseout != true).length;
            })
        }

        let enterpriseTypes = ''
        if (item?.lmuItems[0].enterpriseType != "" && item?.lmuItems[0].enterpriseType != "0" && item?.lmuItems[0].enterpriseType != null) {
            let enterpriseTypesArray = [];

            enterpriseTypesArray = item?.lmuItems[0].enterpriseType.split(',');

            enterpriseTypeList.map(type => {
                if (enterpriseTypesArray.includes(String(type.value))) {
                    if (enterpriseTypes != '') {
                        enterpriseTypes = enterpriseTypes + ", " + type.label
                    }
                    else {
                        enterpriseTypes = type.label;
                    }
                }
            })
        }

        return (
            <>
                {
                    <Card className="my-3" key={item.id}>
                        <CardHeader className="item-header" onClick={(itemId) => handleItemExpandCollapse(item.id)}>
                            <Row>
                                <Col sm="8">   <a onPointerEnter={(itemId) => handleItemHighlight(item.id)} onPointerLeave={(itemId) => handleItemHighlight(0)}>
                                    {item.mapMarkerType == 'polygon' && <FaDrawPolygon color={item.colour}></FaDrawPolygon>}
                                </a>
                                    {' '}
                                    <span className="text-uppercase fw-bold color-dark-teal">{item.name}</span> {' - '} <span> {calculateSizeHa(item.id)}HA {item.nonContiguousItems.length > 0 ? "(including attached)" : ""}</span>
                                    {inherentRisks != 0 && <>{' | '} <span className="card-header-label-xs">{inherentRisks} Inherent Vulnerabilities</span></>}
                                    {inherentRisks == 0 && <>{' | '} <span className="card-header-label-xs">No Inherent Vulnerability on this LU</span></>}
                                    {' '}
                                    {soilTypePreDominant != '' && <>{' | '} <span className="card-header-label-xs">{soilTypePreDominant}{soilTypePreDominantPer != '' && <>{' ('}{soilTypePreDominantPer}{'%'}{')'}</>}</span></>}
                                    {soilTypeOther != '' && <>{' | '} <span className="card-header-label-xs">{soilTypeOther}{soilTypeOtherPer != '' && <>{' ('}{soilTypeOtherPer}{'%'}{')'}</>}</span></>}
                                    {' '}
                                    {
                                        item.isItemHaveAnyRisk.toLowerCase() == "yes" && !item.isConnectedToAnotherLMU &&
                                        <span className="risk-badge"><Badge color="danger">
                                            Risk
                                        </Badge></span>
                                    }                               </Col>
                                <Col sm="3" className="text-end">
                                    {openActionCount > 0 &&
                                        <span className="risk-badge">
                                            <Badge color="primary">
                                                {openActionCount}
                                            </Badge> <span className="card-header-label-xs">Open Actions</span></span> 
                                    }
                                </Col>
                                <Col sm="1" className="toggleoutline">
                                    {
                                        isIconUp ?
                                            <FaChevronUp /> :
                                            <FaChevronDown />
                                    }
                                </Col>
                            </Row>

                        </CardHeader>
                        <Collapse isOpen={isOpen}>
                            <CardBody>
                                <Row>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="9"><div>{item.description}</div><br /></Col>
                                            <Col sm="3">
                                                {showArchiveOption &&  
                                                    <Button className="btn-items-actions button-delete" id={item.id} color="danger" size="sm" onClick={handleDeleteItem} >
                                                        Delete
                                                    </Button>    
                                                }
                                                <Button className="btn-items-actions button-common" id={item.id} color="warning" size="sm" onClick={handleEditItem}  >
                                                    Edit
                                                </Button></Col>
                                        </Row>
                                        {showEnterprise && <div> Enterprise(s) on this Land Unit : {
                                            item?.lmuItems[0].isLUDoesNotHaveAnyFarmingOrGrowingActivities == true ? <span className="no-item-found-success">No Farming or Growing activities</span> : (item?.lmuItems[0].enterpriseType != "" && item?.lmuItems[0].enterpriseType != "0" && item?.lmuItems[0].enterpriseType != null ?
                                                enterpriseTypes : <span className="no-item-found-success">Not selected</span>)}
                                        </div>}
                                    </Col>
                                    
                                    <Row style={{marginTop:"10px"}}>
                                        <Col sm="5">
                                            {blockOnly.islargeEnterprise == true && !item.isConnectedToAnotherLMU &&
                                                <ListGroup>
                                                    <ListGroupItem>
                                                        LU Centre Pin
                                                        {hasCentrePin ? <FaMapMarkerAlt className="float-end" color={item.colour}></FaMapMarkerAlt>
                                                            : <img
                                                                className="featureIcon float-end"
                                                                src="/images/marker.png"
                                                                onClick={(event) => handleCenterPinClick(event, "marker", item.id)}
                                                            />}

                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        View this LU only
                                                        <Input
                                                            type="checkbox"
                                                            name="isViewBlockOnly"
                                                            id="isViewBlockOnly"
                                                            className="float-end"
                                                            checked={blockOnly.itemId == item.id ? blockOnly.isViewBlockOnly : false}
                                                            onChange={(event, itemid, zoomlevel) => handleBlockOnlySelect(event, item.id, defaultZoomlevel)}
                                                        />
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        LU default zoom level
                                                        <select className="form-control input-sm map-popup-input-text float-end" name="defaultZoomLevel" value={defaultZoomlevel} onChange={(event, itemId) => handleBlockZoomlevel(event, item.id)}>
                                                            {blockOnly.zoomLevelList.map(zoomlist =>
                                                                <option key={uuid()} value={zoomlist.zoomValue}>{zoomlist.zoomText}</option>
                                                            )}
                                                        </select>

                                                        {blockOnly.isViewBlockOnly && blockOnly.itemId == item.id &&

                                                            <Button
                                                                className="float-end btn-zoomlevelSave"
                                                                color="success"
                                                                size="sm"
                                                                onClick={(event, itemid, value) => handleBlockZoomlevelSave(event, item.id, blockOnly.defaultZoomLevel)}>
                                                                Save Zoom Level
                                                            </Button>
                                                        }
                                                    </ListGroupItem>
                                                </ListGroup>
                                            }
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="5">
                                            {childLus.length > 0 &&
                                                <ListGroup className="hide-child-checkbox">
                                                    <ListGroupItem>
                                                        Child LU display
                                                        <select className="form-control input-sm map-popup-input-text float-end" name="childLUDisplay"
                                                            value={hideChildren.lmuId == item.id ? hideChildren.childLUDisplay : 0} onChange={(event, lmuId) => handleHideChildrenCheck(event, item.id)}>
                                                            <option key={uuid()} value={0} >Map Only</option>
                                                            <option key={uuid()} value={1}>Map & Table</option>
                                                        </select>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            }
                                        </Col>
                                    </Row>
                                </Row>
                                <br />
                                {inherentRisksText != '' &&
                                    <Col sm="12">
                                        <span>Inherent Vulnerabilities: <i>{inherentRisksText}</i></span>
                                    </Col>
                                }
                                <br/>
                                {ImagesCount > 0 &&
                                    <Col sm="12">
                                        {/*images*/}
                                        <Row>
                                            {item.imageList.map(image =>
                                                <Col key={uuid()} sm="3" className="style-item-display">
                                                    <img src={image.imageRelativePath} className="image-thumbnail" alt={image.imageName} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, image.imageName, image.imageRelativePath)}></img>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                }
                                {item.nonContiguousItems.length > 0 &&
                                    <Row className="my-3">
                                        <Col>
                                            {
                                                item.nonContiguousItems?.map(childitem =>
                                                    <LmuChildItem
                                                        key={uuid()}
                                                        item={childitem}
                                                        topographyList={topographyList}
                                                        soilTypePredominantList={soilTypePredominantList}
                                                        enterpriseTypeList={enterpriseTypeList}
                                                        hideChildren={hideChildren}
                                                        handleEditItem={handleEditItem}
                                                        handleDeleteItem={handleDeleteItem}
                                                        toggleChildItemState={toggleChildItemState}
                                                        isClosedActionShow={isClosedActionShow}
                                                        handleOpenActionPopup={handleOpenActionPopup}
                                                        handleChildItemToggle={handleChildItemToggle}
                                                        handleItemHighlight={handleItemHighlight}
                                                        handleClosedActionShow={handleClosedActionShow}
                                                    />
                                                )
                                            }
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Collapse>
                    </Card>

                }

                <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                    <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                    <ModalBody>
                        <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                    </ModalFooter>
                </Modal>

                {maintenancePlanPopup.showMaintenancePlanPopup && <MaintenancePlan handleMaintenancePlanClose={handleMaintenancePlanClose}
                    showMaintenancePlanPopupModal={maintenancePlanPopup.showMaintenancePlanPopup}
                    actionId={maintenancePlanPopup.actionId} itemId={maintenancePlanPopup.itemId} itemName={maintenancePlanPopup.itemName} itemTypeId={maintenancePlanPopup.itemTypeId}
                    itemTypeSelect={maintenancePlanPopup.itemTypes} sectionName={maintenancePlanPopup.section} redirectPage={maintenancePlanPopup.routePath} maintenancePlanId={maintenancePlanPopup.maintenancePlanId}>
                </MaintenancePlan>}
            </>
        )
    }
}

export default LmuItem