import React, { useState, useEffect } from "react";
import { Card, CardBody, Table, Button, Spinner } from 'reactstrap';
import "../ExternalOrganisation/ExternalOrganisation.css";
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const FarmSelector = ({ handleWizardFarmChanged }) => {
    const [farmList, setFarmList] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("auth")));
    const [loading, setLoading] = useState(true);

    const getFarms = async () => {
        try {

            const response = await axios.get(
                '/api/settings/getfarms/', {
                params: {}
            });

            const farms = [];

            user.farmList.forEach(farm => {
                farms.push(farm.id);
            })

            if (user.roles?.includes(1)) {
                setFarmList(response.data.content?.farms);
            } else {
                const associatedFarms = response.data.content?.farms.filter(farm => farms.includes(farm.id));
                setFarmList(associatedFarms);
            }

            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    const handleSetFarm = async (e) => {

        const farmId = parseInt(e.target.id);

        try {

            const response = await axios.get(
                '/api/settings/getFarmDetail/', {
                    params: { farmId: farmId }
                }
            );
            
            console.log(response.data);
            

            localStorage.setItem("defaultFarmId", JSON.stringify(parseInt(e.target.id)));
            localStorage.setItem("defaultFarmName", JSON.stringify(response.data.name));
            localStorage.setItem("defaultFarmUniqueId", JSON.stringify(response.data.uniqueIdentifier));
            handleWizardFarmChanged(farmId);
        } catch (err) {
            if (err?.response) {
                toast.error("No server response");
            }
        }
    }

    useEffect(() => {
        getFarms();
    }, []);

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h5 className="card-header d-flex justify-content-between align-items-center">
                    Farm Selector
                </h5>

                <CardBody>
                    {farmList == undefined &&
                        <Spinner color="success"> Loading... </Spinner>
                    }
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Farm Identifier</th>
                                <th>Name</th>
                                <th>Subscription</th>
                                <th>Catchment</th>
                                <th>Supply Company</th>
                                <th>Rural Professional Organisation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {farmList?.map(item => (
                                <tr key={uuid()}>
                                    <td>{item.uniqueIdentifier}</td>
                                    <td>{item.name} </td>
                                    <td>{item.subscriptionText} </td>
                                    <td>{item.catchmentText} </td>
                                    <td>{item.supplyCompanyText} </td>
                                    <td>{item.ruralProfessionalOrganisationText} </td>
                                    <td>
                                        <Button
                                            id={item.id}
                                            color="success"
                                            onClick={handleSetFarm}
                                        >
                                            View Outline
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}

export default FarmSelector;
