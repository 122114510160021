import React, { useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import AuthContext from '../Context/AuthProvider'
import { useNavigate } from "react-router-dom"

const TestAuthOrg = () => {

    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/login');
    }


    return (
        <div>
            <h2>Test Auth Org</h2>
            <Row xs="12">
                <Col>
                    <Card>
                        <CardBody>
                            <p>Org user Authorized!</p>
                            <div className="flexGrow">
                                <button onClick={logout}>Sign Out</button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default TestAuthOrg