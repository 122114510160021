import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Table, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { FaPlus, FaEdit, FaTrash, FaFileExcel } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';
import "../ExternalOrganisation/ExternalOrganisation.css";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useNavigate, useSearchParams } from "react-router-dom";

const User = () => {
    const [userList, setUserList] = useState([]); const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [role, setRole] = useState(0);
    const [roleText, setRoleText] = useState('');
    const [name, setName] = useState('');
    const [userItems, setUserItems] = useState([]);
    const [userTempItems, setUserTempItems] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const tableRef = useRef(null);
    const [sorting, setSorting] = useState({ key: "firstName", ascending: true }); const [searchParams, setSearchParams] = useSearchParams();
    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let roleValue = params.get('role') == null ? 0 : params.get('role');
            let nameValue = params.get('name') == null ? '' : params.get('name');
            let showArchivedValue = params.get('showArchived') == null ? false : (params.get('showArchived') === "true");
            setRole(params.get('role') == null ? 0 : params.get('role'));
            setName(params.get('name') == null ? '' : params.get('name'));
            setShowArchived(params.get('showArchived') == null ? false : (params.get('showArchived') === "true"));
            if (searchParams.has('role')) {
                searchParams.delete('role');
                setSearchParams(searchParams);
            }
            if (searchParams.has('name')) {
                searchParams.delete('name');
                setSearchParams(searchParams);
            }
            if (searchParams.has('showArchived')) {
                searchParams.delete('showArchived');
                setSearchParams(searchParams);
            }
            const response = await axios.get(
                '/api/settings/getusers/', {
                params: {}
            }
            );
            setUserList(response.data.content);
            setUserItems(response.data.content.users.filter(x => x.isActive == !showArchived));
            setUserTempItems(response.data.content.users);
            var roleObj = response.data.content.roleList.filter(x => x.id == parseInt(roleValue));
            if (roleObj.length > 0 && roleObj[0].id != 0) {
                setRoleText(roleObj[0].name);
                if (nameValue != '') {
                    var list = [];
                    list = response.data.content.users.filter(x => (
                        x.firstName.toLowerCase().includes(nameValue.toLowerCase()) || x.surname.toLowerCase().includes(nameValue.toLowerCase())
                        || x.email.toLowerCase().includes(nameValue.toLowerCase()) || x.username.toLowerCase().includes(nameValue.toLowerCase())));
                    list = list.filter(x => (x.roleText.toLowerCase().includes(roleObj[0].name.toLowerCase())));
                    list = list.filter(x => x.isActive == !showArchivedValue)
                    setUserItems(list);
                }
                else {
                    var list = [];
                    list = response.data.content.users.filter(x => (x.roleText.toLowerCase().includes(roleObj[0].name.toLowerCase())));
                    list = list.filter(x => x.isActive == !showArchivedValue)
                    setUserItems(list);
                }
            }
            else {
                setRoleText('All');
                if (nameValue == '') {
                    setUserItems(response.data.content.users.filter(x => x.isActive == !showArchivedValue))
                }
                else {
                    var list = [];
                    list = response.data.content.users.filter(x => (x.name.toLowerCase().includes(nameValue.toLowerCase())));
                    list = list.filter(x => x.isActive == !showArchivedValue);
                    setUserItems(list)
                }
            }
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    function applySorting(key, ascending) {
        setSorting({ key: key, ascending: ascending });
        const currentUsersCopy = [...userItems];

        const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
            return a[key].localeCompare(b[key]);
        });
        setUserItems(
            ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
        );
    }

    const confirmDeleteItemPopup = async () => {
        const response = await axios.get(
            '/api/settings/DeleteUser/', {
            params: { Id: deleteItemId, userId: userId }
        }
        );
        setShowDeleteItemPopup(false);
        populateItemData();
    }

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const handleChangeName = async (event) => {
        setName(event.target.value);
        if (event.target.value == '') {
            if (roleText == 'All') {
                setUserItems(userTempItems.filter(x => x.isActive == !showArchived))
            }
            else {
                var list = [];
                list = userTempItems.filter(x => x.isActive == !showArchived);
                list = list.filter(x => (x.roleText.toLowerCase().includes(roleText.toLowerCase())));
                setUserItems(list)
            }
        }
        else {
            if (roleText != 'All') {
                var list = [];
                list = userTempItems.filter(x => (
                    x.firstName.toLowerCase().includes(event.target.value.toLowerCase()) || x.surname.toLowerCase().includes(event.target.value.toLowerCase())
                    || x.email.toLowerCase().includes(event.target.value.toLowerCase()) || x.username.toLowerCase().includes(event.target.value.toLowerCase())
                ));
                list = list.filter(x => x.isActive == !showArchived)
                list = list.filter(x => (x.roleText.toLowerCase().includes(roleText.toLowerCase())));
                setUserItems(list);
            }
            else {
                var list = [];
                list = userTempItems.filter(x => (
                    x.firstName.toLowerCase().includes(event.target.value.toLowerCase()) || x.surname.toLowerCase().includes(event.target.value.toLowerCase())
                    || x.email.toLowerCase().includes(event.target.value.toLowerCase()) || x.username.toLowerCase().includes(event.target.value.toLowerCase())
                ));
                list = list.filter(x => x.isActive == !showArchived)
                setUserItems(list);
            }
        }
    }

    const changeShowArchived = async (e) => {
        setShowArchived(e.target.checked);
        if (name == '') {
            if (roleText == 'All') {
                setUserItems(userTempItems.filter(x => x.isActive == !e.target.checked))
            }
            else {
                var list = [];
                list = userTempItems.filter(x => x.isActive == !e.target.checked);
                list = list.filter(x => (x.roleText.toLowerCase().includes(roleText.toLowerCase())));
                setUserItems(list)
            }
        }
        else {
            if (roleText != 'All') {
                var list = [];
                list = userTempItems.filter(x => (
                    x.firstName.toLowerCase().includes(name.toLowerCase()) || x.surname.toLowerCase().includes(name.toLowerCase())
                    || x.email.toLowerCase().includes(name.toLowerCase()) || x.username.toLowerCase().includes(name.toLowerCase())
                ));
                list = list.filter(x => x.isActive == !e.target.checked)
                list = list.filter(x => (x.roleText.toLowerCase().includes(roleText.toLowerCase())));
                setUserItems(list);
            }
            else {
                var list = [];
                list = userTempItems.filter(x => (
                    x.firstName.toLowerCase().includes(name.toLowerCase()) || x.surname.toLowerCase().includes(name.toLowerCase())
                    || x.email.toLowerCase().includes(name.toLowerCase()) || x.username.toLowerCase().includes(name.toLowerCase())
                ));
                list = list.filter(x => x.isActive == !e.target.checked)
                setUserItems(list);
            }
        }
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Users',
        sheet: 'Users'
    })

    const handleChangeRole = async (event) => {
        setRole(parseInt(event.target.value));
        setRoleText(event.target.selectedOptions[0].text);
        if (event.target.selectedOptions[0].text == 'All') {
            if (name == '') {
                setUserItems(userTempItems.filter(x => x.isActive == !showArchived))
            }
            else {
                var list = [];
                list = userTempItems.filter(x => (x.name.toLowerCase().includes(name.toLowerCase())));
                list = list.filter(x => x.isActive == !showArchived);
                setUserItems(list)
            }
        }
        else {
            if (name != '') {
                var list = [];
                list = userTempItems.filter(x => (
                    x.firstName.toLowerCase().includes(name.toLowerCase()) || x.surname.toLowerCase().includes(name.toLowerCase())
                    || x.email.toLowerCase().includes(name.toLowerCase()) || x.username.toLowerCase().includes(name.toLowerCase())));
                list = list.filter(x => (x.roleText.toLowerCase().includes(event.target.selectedOptions[0].text.toLowerCase())));
                list = list.filter(x => x.isActive == !showArchived)
                setUserItems(list);
            }
            else {
                var list = [];
                list = userTempItems.filter(x => (x.roleText.toLowerCase().includes(event.target.selectedOptions[0].text.toLowerCase())));
                list = list.filter(x => x.isActive == !showArchived)
                setUserItems(list);
            }
        }
    }

    const open = (id, routePath) => {
        window.location = '/' + routePath + '?id=' + id + '&role=' + role + '&name=' + name + '&showArchived=' + showArchived;
        //navigate('/' + routePath, { state: { id: id } });
    }

    return (
        <div>
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Users
                    <button type="button" onClick={(id, routePath) => open(0, 'createuser')} id="btnUpdate" className="btn btn-sm plusIcon-small">
                        Add
                    </button>
                </h2>
                <CardBody>
                    <Row>
                        <Col md="6"></Col>
                        <Col md="1" className="d-flex justify-content-between align-items-center">
                            <Input type="checkbox" checked={showArchived} onChange={(e) => changeShowArchived(e)} name="showArchived" id="showArchived" />&nbsp;&nbsp;
                            <Label for="name">Show Archived</Label>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Role</Label>
                                {userList.roleList == undefined && <p><em>Loading...</em></p>}
                                {userList.roleList != undefined && userList.roleList != null &&
                                    <Input className="validate" type="select" value={role} onChange={e => handleChangeRole(e)} name="Role" id="Role">
                                        {userList.roleList.map(items =>
                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                        )}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Search</Label>
                                <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col md="1">
                            <br/>
                            <button onClick={onDownload} style={{ marginLeft: "15px", marginTop: "10px" }} type="button" id="btnUpdate" className="btn btn-sm btn-success">
                                <FaFileExcel /> Export
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th onClick={() => applySorting("firstName", !sorting.ascending)}>Firstname</th>
                                        <th onClick={() => applySorting("surname", !sorting.ascending)}>Surname</th>
                                        <th onClick={() => applySorting("email", !sorting.ascending)}>Email</th>
                                        <th onClick={() => applySorting("username", !sorting.ascending)}>Username</th>
                                        <th onClick={() => applySorting("roleText", !sorting.ascending)}>Role</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userItems == undefined &&
                                        <tr><td colSpan="7" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {userItems != undefined && userItems != null && userItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.firstName}</td>
                                            <td>{item.surname} </td>
                                            <td>{item.email} </td>
                                            <td>{item.username} </td>
                                            <td>{item.roleText} </td>
                                            <td>
                                                {<button type="button" onClick={(id, routePath) => open(item.id, 'edituser')}
                                                    id="btnUpdate"
                                                    color="warning"
                                                    className="btn btn-sm btn-edit button-normal">
                                                    <FaEdit />
                                                    </button>}
                                                
                                            </td>
                                            <td>
                                                {
                                                    <button type="button"
                                                        id="btnUpdate"
                                                        className="btn btn-sm btn-danger button-delete"
                                                        onClick={() => openDeleteItemPopup(item)}>
                                                        <FaTrash />
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <table style={{ display: "none" }} ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Role</th>
                                        <th>Certifier</th>
                                        <th>Auditor</th>
                                        <th>Regional Council(s)</th>
                                        <th>Associated Farm(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userItems != undefined && userItems != null && userItems.map(item => (
                                        <tr key={uuid()}>
                                            <td>{item.name} </td>
                                            <td>{item.email} </td>
                                            <td>{item.mobilePhone} </td>
                                            <td>{item.roleText} </td>
                                            <td>{item.isCertifierText} </td>
                                            <td>{item.isAuditorText} </td>
                                            <td>{item.regionalAuthorityText} </td>
                                            <td>{item.userFarms} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Archive the item</ModalHeader>
                <ModalBody>
                    Do you want to archive the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default User;
