import React, { useState, useEffect } from 'react';
import { flushSync } from "react-dom";
import { Row, Col, FormGroup, Label, Input, Button, Card, CardBody, Spinner, CardHeader} from 'reactstrap';
import { FaChevronRight, FaTrash, FaInfoCircle, FaUpload, FaChevronLeft } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import axios from 'axios';
import uuid from 'react-uuid';
import CouncilAndLandInfo from '../Enterprises/CouncilAndLandInfo';
import Contacts from '../Enterprises/Contacts';
import StockNumbers from '../Enterprises/StockNumbers';
import OtherRegulatoryRequirementsCovered from '../Enterprises/OtherRegulatoryRequirementsCovered';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Enterprises = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName");
    const isAddEditItem = localStorage.getItem("isAddEditItem");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [enterprisesList, setEnterprisesList] = useState([]); const [savingEnterprises, setSavingEnterprises] = useState('');
    const [loading, setLoading] = useState(true);
    const [supplyCompany, setSupplyCompany] = useState(0);
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');
    const [supplyCompanyAccess, setSupplyCompanyAccess] = useState(0);
    const [ruralProfessionalOrganisation, setRuralProfessionalOrganisation] = useState(0);
    const [ruralProfessional, setRuralProfessional] = useState('');
    const [ruralProfessionalAccess, setRuralProfessionalAccess] = useState(0);
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [optionalPages_Effluent, setOptionalPages_Effluent] = useState(false);
    const [optionalPages_FreshwaterIrrigation, setOptionalPages_FreshwaterIrrigation] = useState(false);
    const [optionalPages_WinterGrazing, setOptionalPages_WinterGrazing] = useState(false);
    const [optionalPages_Cropping, setOptionalPages_Cropping] = useState(false);
    const [optionalPages_Biodiversity, setOptionalPages_Biodiversity] = useState(false);
    const [optionalPages_GreenhouseGass, setOptionalPages_GreenhouseGass] = useState(false);
    const [farmSystemType, setFarmSystemType] = useState('');
    const [farmingActivities, setFarmingActivities] = useState('');
    const [orchardActivitiesAroundKiwifruitEnterprise, setOrchardActivitiesAroundKiwifruitEnterprise] = useState('');
    const [nonKiwifruitEnterprisesOnThisLand, setNonKiwifruitEnterprisesOnThisLand] = useState(false);
    const [totalArea, setTotalArea] = useState('');
    const [leaseArea, setLeaseArea] = useState('');
    const [irrigatedArea, setIrrigatedArea] = useState('');
    const [savingAssociations, setSavingAssociations] = useState(false);
    const [savingEnterprisesOptions, setSavingEnterprisesOptions] = useState(false);
    const [savingFarmSystem, setSavingFarmSystem] = useState(false);
    const [savingFarmStory, setSavingFarmStory] = useState(false);
    const [enterpriseTypeList, setEnterpriseTypeList] = useState([]);
    const [enterpriseTypeListSelectedValues, setEnterpriseTypeListSelectedValues] = useState([]);
    const [enterpriseTypeSelectedValues, setEnterpriseTypeSelectedValues] = useState('');
    const [enterprises, setEnterprises] = useState({ Section: 'Enterprises', SectionName: 'Enterprise & Property Detail', isWizardCompleted: false });
    const [propertyDetails, setPropertyDetails] = useState({ Name: '', Address1: '', Address2: '', Address3: '', Address4: '', FarmLocation: '',IsLeased:'' });
    const [savingPropertyDetails, setSavingPropertyDetails] = useState(false);
    const [farmStory, setFarmStory] = useState({ FarmStory: '', FarmStoryGoals: '', IntrestingFeatures: '', Concerns: '' });
    const [tellUsAbout, setTellUsAbout] = useState({ RiparianAreas: '', AreasRetired: '', FencingOfWaterways: '', StockPolicy: '', Other: '' });
    const [savingTellUsAbout, setSavingTellUsAbout] = useState(false);
    const [isHaveresponsible, setIsHaveresponsible] = useState(false);
    const [regulatoryRequirementDetails, setRegulatoryRequirementDetails] = useState({ RegulatoryRequirementDetails: '', FWFPToMeetAnyOtherRegulatoryRequirement: 'Yes', IsAnyNewPhsyicalWorks: 'No', AnyNewPhsyicalWorksDetails : '' });
    const [savingRegulatoryRequirementDetails, setSavingRegulatoryRequirementDetails] = useState(false);
    const [enterpriseRows, setEnterpriseRows] = useState([]);
    const [tempEnterpriseRows, setTempEnterpriseRows] = useState([]);
    const [regulatoryRequirementsRows, setRegulatoryRequirementsRows] = useState([]);
    const [enterpriseRowCount, setEnterpriseRowCount] = useState(0);
    const [regulatoryRequirementsRowCount, setRegulatoryRequirementsRowCount] = useState(0);
    const [formErrors, setFormErrors] = useState([]);
    const [uploadType, setUploadType] = useState('NewPhsyicalWork');
    const [documentUploadList, setDocumentUploadList] = useState([]);
    const [deleteDocumentPopup, setDeleteDocumentPopup] = useState({ showDeleteDocumentPopup: false, itemId: 0, date: '', daetails: '', fileName: '' });
    const [enterprisesObj, setEnterprisesObj] = useState({});
    const [enterpriseCategoryList, setEnterpriseCategoryList] = useState([]);
    const [yesNoList, setYesNoList] = useState([]);
    let inputFile = [];
    const [landParcelCompleted, setLandParcelCompleted] = useState(false);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const handleIsLeasedValueChange = (event) => {
        const { name, value, checked, type } = event.target;
        setPropertyDetails({ ...propertyDetails, IsLeased: value });
    }

    useEffect(() => {
        setLoading(true);
        populateItemData();
    }, []);

    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/enterprises/getenterprisedetails/', {
                params: { Id: farmId }
            }
            );
            setEnterprisesList(response.data.content);
            flushSync(() => {
                setEnterprisesList(response.data.content);
            });
            setEnterprisesObj(response.data.content.enterprises);
            if (response.data.content.enterprises.selectedEnterpriseTypeList.length > 0) {
                var setEnterpriseTypeSelectedValues1 = '';
                response.data.content.enterprises.selectedEnterpriseTypeList.forEach(function (element) {
                    if (setEnterpriseTypeSelectedValues1 == '') {
                        setEnterpriseTypeSelectedValues1 = element.value;
                    }
                    else {
                        setEnterpriseTypeSelectedValues1 = setEnterpriseTypeSelectedValues1 + ',' + element.value;
                    }
                });
                setEnterpriseTypeSelectedValues(setEnterpriseTypeSelectedValues1);
                setEnterpriseTypeListSelectedValues(response.data.content.enterprises.selectedEnterpriseTypeList);
            }
            else {
                setEnterpriseTypeListSelectedValues([]);
            }
            setSupplyCompany(response.data.content.enterprises.supplyCompany);
            setSupplyCompanyAccess(response.data.content.enterprises.supplycompanyAccess);
            setUniqueIdentifier(response.data.content.enterprises.uniqueIdentifier);
            setRuralProfessionalOrganisation(response.data.content.enterprises.ruralProfessionalOrganisation);
            setRuralProfessional(response.data.content.enterprises.ruralProfessional);
            setRuralProfessionalAccess(response.data.content.enterprises.ruralProfessionalAccess);
            setOptionalPages_Effluent(response.data.content.enterprises.optionalPages_Effluent);
            setOptionalPages_FreshwaterIrrigation(response.data.content.enterprises.optionalPages_FreshwaterIrrigation);
            setOptionalPages_WinterGrazing(response.data.content.enterprises.optionalPages_WinterGrazing);
            setOptionalPages_Cropping(response.data.content.enterprises.optionalPages_Cropping);
            setOptionalPages_Biodiversity(response.data.content.enterprises.optionalPages_Biodiversity);
            setOptionalPages_GreenhouseGass(response.data.content.enterprises.optionalPages_GreenhouseGass);
            setFarmSystemType(response.data.content.enterprises.farmSystemType);
            setFarmingActivities(response.data.content.enterprises.farmingActivities);
            if (response.data.content.enterprises.orchardActivitiesAroundKiwifruitEnterprise == null) {
                setOrchardActivitiesAroundKiwifruitEnterprise('Nutrient management, agrichemical storage and management, soil management, water takes and storage, water management (irrigation and frost protection), toilets (on site effluent treatment)');
            }
            else {
                setOrchardActivitiesAroundKiwifruitEnterprise(response.data.content.enterprises.orchardActivitiesAroundKiwifruitEnterprise);
            }
            setNonKiwifruitEnterprisesOnThisLand(response.data.content.enterprises.nonKiwifruitEnterprisesOnThisLand);
            setTellUsAbout(response.data.content.enterprises.tellUsAbout);
            setEnterpriseTypeList(response.data.content.enterpriseTypes);
            setEnterprises({ ...enterprises, isWizardCompleted: response.data.content.isFWFPCompleted })
            setTotalArea(response.data.content.totalArea);
            setLeaseArea(response.data.content.leaseArea);
            setIrrigatedArea(response.data.content.irrigatedArea);
            setEnterpriseCategoryList(response.data.content.enterpriseCategoryList);
            setPropertyDetails({
                ...propertyDetails, Name: response.data.content.enterprises.name, Address1: response.data.content.enterprises.address1, Address2: response.data.content.enterprises.address2,
                Address3: response.data.content.enterprises.address3, Address4: response.data.content.enterprises.address4, FarmLocation: response.data.content.enterprises.farmLocation,
                IsLeased: response.data.content.enterprises.isLeased,
            });
            setFarmStory({
                ...farmStory, FarmStory: response.data.content.enterprises.farmStory, FarmStoryGoals: response.data.content.enterprises.farmStoryGoals
                , IntrestingFeatures: response.data.content.enterprises.intrestingFeatures, Concerns: response.data.content.enterprises.concerns
            });
            setTellUsAbout({
                ...tellUsAbout, RiparianAreas: response.data.content.enterprises.riparianAreas, AreasRetired: response.data.content.enterprises.areasRetired
                , FencingOfWaterways: response.data.content.enterprises.fencingOfWaterways, StockPolicy: response.data.content.enterprises.stockPolicy, Other: response.data.content.enterprises.other
            });
            setRegulatoryRequirementDetails({
                ...regulatoryRequirementDetails, FWFPToMeetAnyOtherRegulatoryRequirement: response.data.content.enterprises.fwfpToMeetAnyOtherRegulatoryRequirement,
                RegulatoryRequirementDetails: response.data.content.enterprises.regulatoryRequirementDetails, IsAnyNewPhsyicalWorks: response.data.content.enterprises.isAnyNewPhsyicalWorks,
                AnyNewPhsyicalWorksDetails: response.data.content.enterprises.anyNewPhsyicalWorksDetails
            })
            setEnterpriseRows(response.data.content.farmEnterprises);
            setTempEnterpriseRows(response.data.content.farmEnterprises);
            setRegulatoryRequirementsRows(response.data.content.regulatoryRequirements);
            if (response.data.content.farmEnterprises.length > 0) {
                setEnterpriseRowCount(response.data.content.farmEnterprises.length);
            }
            setYesNoList(response.data.content.yesNoList);
            checkAlreadyIsresponsibleChecked();
            getUploadedDocuments();
            if (response.data.content.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.content.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
            setLoading(false);
        }
    }

    const handleRegulatoryRequirementRowsAddRow = () => {
        const item = {
            regulatoryRequirement: "",
            condition: "", id: 0
        };
        setRegulatoryRequirementsRows([...regulatoryRequirementsRows, item]);
    };

    const handleRegulatoryRequirementRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...regulatoryRequirementsRows]
        tempRows.splice(idx, 1)
        setRegulatoryRequirementsRows(tempRows);
    }

    const checkAlreadyIsresponsibleChecked = async () => {
        let isResponsible = false;
        let isDeveloper = false;
        try {
            const response = await axios.get(
                '/api/enterprises/GetContacts/', {
                params: { Id: farmId }
            }
            );

            for (var i = 0; i < response.data.content.farmContacts.length; i++) {
                if (response.data.content.farmContacts[i].isResponsible == true) {
                    isResponsible = true;
                }
                if (response.data.content.farmContacts[i].isDeveloper == true) {
                    isDeveloper = true;
                }
            }
            setIsHaveresponsible(isResponsible && isDeveloper);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    const getUploadedDocuments = async () => {
        try {
            const response = await axios.get(
                '/api/upload/GetUploadedDocuments/', {
                    params: { Id: farmId, UploadType: uploadType, FarmId: farmId }
            }
            );
            if (response.data.status) {
                setDocumentUploadList(response.data.content);
                let tempErrors = [];
                response.data.content.forEach(function (element) {
                    const error = {
                        fileError: ''
                    };
                    tempErrors.push(error);
                });
                setFormErrors(tempErrors);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const handleRegulatoryRequirementChange = idx => e => {
        const tempRows = [...regulatoryRequirementsRows];
        tempRows[idx].regulatoryRequirement = e.target.value;
        setRegulatoryRequirementsRows(tempRows);
    };

    const handleRegulatoryRequirementConditionChange = idx => e => {
        const tempRows = [...regulatoryRequirementsRows];
        tempRows[idx].condition = e.target.value;
        setRegulatoryRequirementsRows(tempRows);
    };

    const handleEnterpriseTypeChange = idx => e => {
        const tempRows = [...enterpriseRows];
        tempRows[idx].enterpriseType = parseInt(e.target.value);
        setEnterpriseRows(tempRows);
    };

    const handleEnterpriseCategoryChange = idx => e => {
        const tempRows = [...enterpriseRows];
        tempRows[idx].enterpriseCategory = e.target.value;
        setEnterpriseRows(tempRows);
    };

    const handleFarmingActivityChange = idx => e => {
        const tempRows = [...enterpriseRows];
        tempRows[idx].farmingActivity = e.target.value;
        setEnterpriseRows(tempRows);
    };

    const handleOverviewOfFarmingActivitiesChange = idx => e => {
        const tempRows = [...enterpriseRows];
        tempRows[idx].overviewOfFarmingActivities = e.target.value;
        setEnterpriseRows(tempRows);
    };

    const handleEnterpriseRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...enterpriseRows]
        tempRows.splice(idx, 1)
        setEnterpriseRows(tempRows);
    }

    const handleEnterpriseRowsAddRow = () => {
        const item = {
            enterpriseType: 0, enterpriseType: "", overviewOfFarmingActivities:''
        };
        setEnterpriseRows([...enterpriseRows, item]);
    };

    const handleEnterprisesOptionsSubmit = async (e) => {
        e.preventDefault()
        setSavingEnterprisesOptions(true);
        saveFarmDetails();
        window.location.reload(true);
    };

    const handleEnterpriseRowsSubmit = async (e) => {
        e.preventDefault()
        let tempEnterpriseRows = [...enterpriseRows];
        if (tempEnterpriseRows.length > 0) {
            setSavingEnterprises(true);
            let form = {
                FarmId: farmId,
                FarmEnterprises: [],
                UserId: userId,
                OrchardActivitiesAroundKiwifruitEnterprise: orchardActivitiesAroundKiwifruitEnterprise,
                NonKiwifruitEnterprisesOnThisLand: nonKiwifruitEnterprisesOnThisLand
            }
            for (var i = 0; i < tempEnterpriseRows.length; i++) {
                var enterpriseList = tempEnterpriseRows.filter(x => x.enterpriseType == tempEnterpriseRows[i].enterpriseType && x.farmingActivity == tempEnterpriseRows[i].farmingActivity);
                if (enterpriseList.length > 1) {
                    toast.error("You can not add duplicate enterprises!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    setSavingEnterprises(false);
                    return false;
                }
                if (tempEnterpriseRows[i].enterpriseType == 0 || tempEnterpriseRows[i].enterpriseType == null || tempEnterpriseRows[i].enterpriseType == undefined) {
                    toast.error("Select enterprise!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    setSavingEnterprises(false);
                    return false;
                }
                var obj = {};
                obj.Id = tempEnterpriseRows[i].id;
                obj.FarmId = farmId;
                obj.FarmingActivity = tempEnterpriseRows[i].farmingActivity;
                obj.EnterpriseType = tempEnterpriseRows[i].enterpriseType;
                obj.overviewOfFarmingActivities = tempEnterpriseRows[i].overviewOfFarmingActivities;
                form.FarmEnterprises.push(obj);
            }
            const response = await axios.post(
                '/api/enterprises/SaveFarmEnterprises/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("Enterprises & Farming Activities Saved", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setSavingEnterprises(false);
                    setEnterpriseRows(response.data.content);
                    setTempEnterpriseRows(response.data.content);
                    setEnterpriseRowCount(response.data.content.length);
                }
            }
        }
        else {
            setSavingEnterprises(true);
            let form = {
                FarmId: farmId,
                FarmEnterprises: [],
                UserId: userId,
                OrchardActivitiesAroundKiwifruitEnterprise: orchardActivitiesAroundKiwifruitEnterprise,
                NonKiwifruitEnterprisesOnThisLand: nonKiwifruitEnterprisesOnThisLand
            }
            const response = await axios.post(
                '/api/enterprises/SaveFarmEnterprises/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("Enterprises & Farming Activities Saved", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setSavingEnterprises(false);
                    setEnterpriseRows(response.data.content);
                    setTempEnterpriseRows(response.data.content);
                    setEnterpriseRowCount(response.data.content.length);
                }
            }
        }
    };

    const handleFarmStorySubmit = async (e) => {
        e.preventDefault()
        setSavingFarmStory(true);
        saveFarmDetails();
    };

    const handleFarmSystemSubmit = async (e) => {
        e.preventDefault()
        setSavingFarmSystem(true);
        saveFarmDetails();
    };

    const handleAssociationsSubmit = async (e) => {
        e.preventDefault()
        setSavingAssociations(true);
        saveFarmDetails();
    };

    const handlePropertyDetailsSubmit = async (e) => {
        e.preventDefault()
        setSavingPropertyDetails(true);
        saveFarmDetails();
    };

    const handleRegulatoryRequirementDetailsSubmit = async (e) => {
        e.preventDefault()
        setSavingRegulatoryRequirementDetails(true);

        let tempRegulatoryRequirementsRows = [...regulatoryRequirementsRows];
        if (regulatoryRequirementDetails.IsAnyNewPhsyicalWorks == "No") {
            regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails = "";
        }
        let form = {
            FarmId: farmId,
            RegulatoryRequirements: [],
            UserId: userId,
            FWFPToMeetAnyOtherRegulatoryRequirement: regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement,
            IsAnyNewPhsyicalWorks: regulatoryRequirementDetails.IsAnyNewPhsyicalWorks,
            AnyNewPhsyicalWorksDetails: regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails
        }
        for (var i = 0; i < tempRegulatoryRequirementsRows.length; i++) {
            var obj = {};
            obj.Id = tempRegulatoryRequirementsRows[i].id;
            obj.RegulatoryRequirement = tempRegulatoryRequirementsRows[i].regulatoryRequirement;
            obj.Condition = tempRegulatoryRequirementsRows[i].condition;
            form.RegulatoryRequirements.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveRegulatoryRequirements/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                handleRecordRowsSubmit();
                toast.success("Other Regulatory Requirements Saved", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingRegulatoryRequirementDetails(false);
                setRegulatoryRequirementsRows(response.data.content);
            }
        }

    };

    const handleTellUsAboutSubmit = async (e) => {
        e.preventDefault()
        setSavingTellUsAbout(true);
        saveFarmDetails();
    };

    const handlEnterpriseTypeSelectChange = (e) => {
        setEnterpriseTypeSelectedValues('');
        var enterpriseTypeSelectedValues = '';
        setEnterpriseTypeListSelectedValues(e);
        e.forEach(function (element) {
            if (enterpriseTypeSelectedValues == '') {
                enterpriseTypeSelectedValues = element.value;
            }
            else {
                enterpriseTypeSelectedValues = enterpriseTypeSelectedValues + ',' + element.value;
            }
        });
        setEnterpriseTypeSelectedValues(enterpriseTypeSelectedValues);

        if (!optionalPages_Effluent) {
            const selectedValues = enterpriseTypeSelectedValues.toString().includes(',') ? enterpriseTypeSelectedValues.split(',') : enterpriseTypeSelectedValues.toString();
            const DairyList = enterpriseTypeList.filter(enterpriseType => enterpriseType.label.includes("Dairy"));
            if (selectedValues != null && selectedValues != '' && DairyList.length > 0) {
                let isAnyDairyType = false;
                DairyList.map(dairy => {
                    if (Array.isArray(selectedValues)) {
                        isAnyDairyType = selectedValues.filter(type => type == dairy.value) > 0 ? true : isAnyDairyType
                    }
                    else {
                        isAnyDairyType = selectedValues == dairy.value ? true : isAnyDairyType
                    }
                })

                if (isAnyDairyType) {
                    setOptionalPages_Effluent(true);
                }
                else {

                }
            }
        }
    }

    const saveFarmDetails = async () => {
        var noStockOnThisFarm = document.getElementById("noStockOnThisFarm").checked;
        let form = {
            Id: farmId,
            UniqueIdentifier: uniqueIdentifier,
            SupplyCompany: supplyCompany,
            SupplycompanyAccess: supplyCompanyAccess,
            RuralProfessionalOrganisation: ruralProfessionalOrganisation,
            RuralProfessional: ruralProfessional,
            RuralProfessionalAccess: ruralProfessionalAccess,
            OptionalPages_Effluent: optionalPages_Effluent,
            OptionalPages_FreshwaterIrrigation: optionalPages_FreshwaterIrrigation,
            OptionalPages_WinterGrazing: optionalPages_WinterGrazing,
            OptionalPages_Cropping: optionalPages_Cropping,
            OptionalPages_Biodiversity: optionalPages_Biodiversity,
            OptionalPages_GreenhouseGass: optionalPages_GreenhouseGass,
            FarmSystemType: farmSystemType,
            FarmingActivities: farmingActivities,
            IsNoStockOnThisFarm: noStockOnThisFarm,
            Enterprises: enterpriseTypeSelectedValues.toString(),
            UserId: userId,
            Address1: propertyDetails.Address1,
            Address2: propertyDetails.Address2,
            Address3: propertyDetails.Address3,
            Address4: propertyDetails.Address4,
            IsLeased: propertyDetails.IsLeased,
            FarmLocation: propertyDetails.FarmLocation,
            FarmStory: farmStory.FarmStory,
            FarmStoryGoals: farmStory.FarmStoryGoals,
            IntrestingFeatures: farmStory.IntrestingFeatures,
            Concerns: farmStory.Concerns,
            RiparianAreas: tellUsAbout.RiparianAreas,
            AreasRetired: tellUsAbout.AreasRetired,
            FencingOfWaterways: tellUsAbout.FencingOfWaterways,
            StockPolicy: tellUsAbout.StockPolicy,
            Other: tellUsAbout.Other,
            RegulatoryRequirementDetails: regulatoryRequirementDetails.RegulatoryRequirementDetails
        }
        const response = await axios.post(
            '/api/enterprises/Save/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Enterprise & Farm Detail Saved", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingEnterprisesOptions(false);
                setSavingFarmStory(false);
                setSavingFarmSystem(false);
                setSavingAssociations(false);
                setSavingPropertyDetails(false);
                setSavingTellUsAbout(false);
                setSavingRegulatoryRequirementDetails(false);
            }
        }
    }

    const noIsresponsibleError = () => {
        if (!isHaveresponsible) {
            toast.error("Please add a contact with is responsible for implementing plan to move forward ...", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        if (!landParcelCompleted) {
            toast.error("Legal Land titles and parcels field is mandatory", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
    }

    const handleRecordRowsAddRow = () => {
        const item = {
            createdDate: getParsedDate1(new Date()),
            documentDetail: "", id: 0, documentName: '', documentPhysicalName: '', documentPath: '', documentRelativePath: ''
        };
        const error = {
            fileError: ''
        };
        setFormErrors([...formErrors, error]);
        setDocumentUploadList([...documentUploadList, item]);
    };

    const handleRecordRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...documentUploadList]
        const tempErrors = [...formErrors];
        tempRows.splice(idx, 1)
        tempErrors.splice(idx, 1)
        setDocumentUploadList(tempRows);
        setFormErrors(tempErrors);
    }

    const handleDateChange = (idx, date) => {
        const tempRows = [...documentUploadList];
        tempRows[idx].uploadDateText = getParsedDate(date);
        tempRows[idx].uploadDate = getDateFromString(getParsedDate(date));
        setDocumentUploadList(tempRows);
    };

    const getParsedDate1 = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleDetailsChange = idx => e => {
        const tempRows = [...documentUploadList];
        tempRows[idx].documentDetail = e.target.value;
        setDocumentUploadList(tempRows);
    };

    const handleRecordChange = async (e, idx) => {
        e.preventDefault();
        const tempErrors = [...formErrors];
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.fileError = "Only Pdf files can upload!";
            tempErrors[idx].fileError = "Only Pdf files can upload!";
        }
        else {
            tempErrors[idx].fileError = "";
        }
        setFormErrors(tempErrors);
        if (Object.keys(errors).length == 0) {
            if (file.type == 'application/pdf') {
                const formData = new FormData();
                formData.append("FarmId", farmId);
                formData.append("ReferenceId", farmId);
                formData.append("Type", uploadType);
                formData.append("FileName", e.target.files[0].name);
                formData.append("File", e.target.files[0]);
                formData.append("userId", userId);
                fetch('api/uploaddocument/UploadDocument', {
                    method: 'POST',
                    body: formData
                }).then(r => r.json()).then(res => {
                    if (res.status) {
                        const tempRows = [...documentUploadList];
                        tempRows[idx].documentName = res.content.DocumentName;
                        tempRows[idx].documentPhysicalName = res.content.DocumentPhysicalName;
                        tempRows[idx].documentPath = res.content.DocumentPath;
                        tempRows[idx].documentRelativePath = res.content.RelativeFilepath;
                        setDocumentUploadList(tempRows);
                    }
                });
            }
        }
        else {
            e.target.value = null;
        }
    }

    const handleBtnUploadClick = async (e, idx) => {
        e.preventDefault();
        inputFile[idx].click();
        return false;
    }

    const handleRecordRowsSubmit = async (e) => {
        
        let tempRecordUploadList = [...documentUploadList];
        let form = {
            ReferenceId: farmId,
            FarmId: farmId,
            UploadType: uploadType,
            userId: userId,
            Documents: []
        }
        for (var i = 0; i < tempRecordUploadList.length; i++) {
            if (tempRecordUploadList[i].documentName != '' && tempRecordUploadList[i].documentName != null && tempRecordUploadList[i].documentName != undefined) {
                var obj = {};
                obj.Id = tempRecordUploadList[i].id;
                obj.documentDetail = tempRecordUploadList[i].documentDetail;
                obj.documentName = tempRecordUploadList[i].documentName;
                obj.documentPhysicalName = tempRecordUploadList[i].documentPhysicalName;
                obj.documentPath = tempRecordUploadList[i].documentPath;
                obj.UploadDateText = tempRecordUploadList[i].uploadDate != null ? tempRecordUploadList[i].uploadDateText : "";
                form.Documents.push(obj);
            }
        }
        const response = await axios.post(
            '/api/upload/SaveDocuments/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                setTimeout(() => {
                    setDocumentUploadList(response.data.content);
                    let tempErrors = [];
                    setFormErrors(tempErrors);
                    response.data.content.forEach(function (element) {
                        const error = {
                            fileError: ''
                        };
                        tempErrors.push(error);
                    });
                    setFormErrors(tempErrors);
                }, 2000);
            }
        }
    };

    const handleNonKiwifruitEnterprisesOnThisLandChange = (e) => {
        const { checked } = e.target;
        setNonKiwifruitEnterprisesOnThisLand(checked);
        setEnterpriseRows(tempEnterpriseRows);
    };

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{enterprises.SectionName}</h2></Col>
                {enterprises.isWizardCompleted != true && !loading &&
                    <Col>
                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                            <>
                            {(isHaveresponsible == true && landParcelCompleted == true) ?
                                <>
                                    <Button className="float-end button-next" onClick={(section) => handleWizardChanged(enterprises.Section)}>Next <FaChevronRight /> </Button>
                                </>
                                :
                                <>
                                    <Button className="float-end btn-next-page-warning" onClick={noIsresponsibleError}>Next <FaChevronRight /> </Button>
                                </>

                            }
                            </>
                        }
                        {
                            roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                            <>
                                {(isHaveresponsible == true && landParcelCompleted == true) ?
                                    <>
                                        <Button className="float-end button-next" onClick={(section) => handleMoveToNextPage(enterprises.Section)}>Next <FaChevronRight /> </Button>
                                    </>
                                    :
                                    <>
                                        <Button className="float-end btn-next-page-warning" onClick={noIsresponsibleError}>Next <FaChevronRight /> </Button>
                                    </>

                                }
                            </>
                        }
                        <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(enterprises.Section)}><FaChevronLeft /> Back  </Button>
                    </Col>
                }
            </Row>
            {loading ? <SpinnerContainer /> : <>
                <Row xs="2">
                    <Col>
                        {((supplyCompany != 0 && supplyCompany != undefined && supplyCompany != null) || (ruralProfessionalOrganisation != 0 && ruralProfessionalOrganisation != undefined && ruralProfessionalOrganisation != null)) &&
                            <Card className="my-4" style={{ display: "none" }}>
                                <CardHeader className="card-header d-flex align-items-center card-header-dark"><h5>Associations & Approved Program Membership</h5>
                                </CardHeader>
                                <br />
                                <CardBody>
                                    {supplyCompany != 0 && supplyCompany != undefined && supplyCompany != null &&
                                        <>  <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label className="label" for="name">Supply Company</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    {enterprisesList.supplyCompanies == undefined && <p><em>Loading...</em></p>}
                                                    {enterprisesList.supplyCompanies != undefined && enterprisesList.supplyCompanies != null &&
                                                        <Input disabled={true} type="select" value={supplyCompany} onChange={(e) => setSupplyCompany(e.target.value)} name="supplyCompany" id="supplyCompany">
                                                            {enterprisesList.supplyCompanies.map(items =>
                                                                <option key={uuid()} value={items.id}>{items.name}</option>
                                                            )}
                                                        </Input>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="label" for="name">Supply Company Access</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        {enterprisesList.supplyCompanyAccesses == undefined && <p><em>Loading...</em></p>}
                                                        {enterprisesList.supplyCompanyAccesses != undefined && enterprisesList.supplyCompanyAccesses != null &&
                                                            <Input type="select" value={supplyCompanyAccess} onChange={(e) => setSupplyCompanyAccess(e.target.value)} name="supplyCompanyAccess" id="supplyCompanyAccess">
                                                                {enterprisesList.supplyCompanyAccesses.map(items =>
                                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                                )}
                                                            </Input>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {ruralProfessionalOrganisation != 0 && ruralProfessionalOrganisation != undefined && ruralProfessionalOrganisation != null &&
                                        <>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="label" for="name">Rural Professional Organisation</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        {enterprisesList.ruralProfessionalOrganisations == undefined && <p><em>Loading...</em></p>}
                                                        {enterprisesList.ruralProfessionalOrganisations != undefined && enterprisesList.ruralProfessionalOrganisations != null &&
                                                            <Input disabled={true} type="select" value={ruralProfessionalOrganisation} onChange={(e) => setRuralProfessionalOrganisation(e.target.value)} name="ruralProfessionalOrganisation" id="ruralProfessionalOrganisation">
                                                                {enterprisesList.ruralProfessionalOrganisations.map(items =>
                                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                                )}
                                                            </Input>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="label" for="name">Rural Professional</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        {enterprisesList.ruralProfessionals == undefined && <p><em>Loading...</em></p>}
                                                        {enterprisesList.ruralProfessionals != undefined && enterprisesList.ruralProfessionals != null &&
                                                            <Input disabled={true} type="select" value={ruralProfessional} onChange={(e) => setRuralProfessional(e.target.value)} name="ruralProfessional" id="ruralProfessional">
                                                                {enterprisesList.ruralProfessionals.map(items =>
                                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                                )}
                                                            </Input>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="label" for="name">Rural Professional Access</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        {enterprisesList.ruralProfessionalAccesses == undefined && <p><em>Loading...</em></p>}
                                                        {enterprisesList.ruralProfessionalAccesses != undefined && enterprisesList.ruralProfessionalAccesses != null &&
                                                            <Input type="select" value={ruralProfessionalAccess} onChange={(e) => setRuralProfessionalAccess(e.target.value)} name="ruralProfessionalAccess" id="ruralProfessionalAccess">
                                                                {enterprisesList.ruralProfessionalAccesses.map(items =>
                                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                                )}
                                                            </Input>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Row>
                                        <Col>
                                            {savingAssociations ?
                                                <Button style={{ float: "right" }} onClick={handleAssociationsSubmit}
                                                    color="success"
                                                    disabled
                                                >
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Button>
                                                :
                                                <Button style={{ float: "right" }} onClick={handleAssociationsSubmit} color="success" size="sm">
                                                    Save
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                        <Card className="my-4">
                            <CardHeader className="d-flex card-header-dark">
                                <h5><FaInfoCircle data-tooltip-id="my-tooltip-2" />&nbsp;&nbsp;&nbsp;Orchard Details</h5>
                                <ReactTooltip
                                    id="my-tooltip-2" style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    place="top"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>This section is pre-populated with information from the Zespri database and can’t be edited.<br/>If the details are incorrect, please contact us at extension@Zespri.com</p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            </CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Orchard Identifier - System ID</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="text" disabled={true} value={uniqueIdentifier} onChange={(e) => setUniqueIdentifier(e.target.value)} name="uniqueIdentifier" id="uniqueIdentifier" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Orchard,Farm or Business Name, Maori land trust or Maori incorporation</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="text" disabled={true} value={propertyDetails.Name} onChange={(e) => setPropertyDetails({ ...propertyDetails, Name: e.target.value })} name="Name" id="Name" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Address 1</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input disabled={true} type="textarea" value={propertyDetails.Address1} onChange={(e) => setPropertyDetails({ ...propertyDetails, Address1: e.target.value })} name="Address1" id="Address1" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Address 2</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input disabled={true} type="textarea" value={propertyDetails.Address2} onChange={(e) => setPropertyDetails({ ...propertyDetails, Address2: e.target.value })} name="Address2" id="Address2" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Address Town / City</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input disabled={true}type="textarea" value={propertyDetails.Address3} onChange={(e) => setPropertyDetails({ ...propertyDetails, Address3: e.target.value })} name="Address3" id="Address3" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Address Postcode</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input disabled={true} type="text" value={propertyDetails.Address4} onChange={(e) => setPropertyDetails({ ...propertyDetails, Address4: e.target.value })} name="Address4" id="Address4" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label">Is this Property leased?</Label>
                                        </FormGroup>
                                        
                                    </Col>
                                    <Col sm="6">
                                        <Input type="select" disabled onChange={handleIsLeasedValueChange} name="IsLeased" id="IsLeased" value={propertyDetails.IsLeased} >
                                            {yesNoList != undefined && yesNoList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row style={{display:"none"}}>
                                    <Col sm="6">
                                        <FormGroup>
                                            (Please indicate Iwi, Hapū or Rūnanga)<br />
                                            <Label className="label" for="name">Orchard location</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="text" value={propertyDetails.FarmLocation} onChange={(e) => setPropertyDetails({ ...propertyDetails, FarmLocation: e.target.value })} name="FarmLocation" id="FarmLocation" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                {savingPropertyDetails ?
                                                    <Button style={{ float: "right" }} onClick={handlePropertyDetailsSubmit}
                                                        color="success" className="button-save"
                                                        disabled
                                                    >
                                                        <Row>
                                                            <Col sm="8">
                                                                <span>
                                                                    {' '}Saving
                                                                </span>
                                                            </Col>
                                                            <Col sm="4">
                                                                <Spinner size="sm">
                                                                    Saving...
                                                                </Spinner>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    :
                                                    <Button className="button-save" style={{ float: "right" }} onClick={handlePropertyDetailsSubmit} color="success" size="sm">
                                                        Save
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <CouncilAndLandInfo setLandParcelCompleted={setLandParcelCompleted} userId={userId} enterprises={enterprisesObj} regulatoryRequirements={regulatoryRequirementsRows}></CouncilAndLandInfo>
                        
                        
                        <StockNumbers userId={userId}></StockNumbers>   
                    </Col>
                    <Col>
                        <Card className="my-4">
                            <CardHeader className="card-header card-header-dark d-flex justify-content-between align-items-center">
                                <h5 >
                                    Enterprises & Options
                                </h5>
                            </CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Describe The Key Orchard Activities That Could Potentially Impact On Freshwater On This Orchard</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setOrchardActivitiesAroundKiwifruitEnterprise(e.target.value)} value={orchardActivitiesAroundKiwifruitEnterprise} name="orchardActivitiesAroundKiwifruitEnterprise" id="orchardActivitiesAroundKiwifruitEnterprise" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="1">
                                        <Input type="checkbox" checked={nonKiwifruitEnterprisesOnThisLand} onChange={(e) => handleNonKiwifruitEnterprisesOnThisLandChange(e)} name="nonKiwifruitEnterprisesOnThisLand" id="nonKiwifruitEnterprisesOnThisLand" />
                                    </Col>
                                    <Col sm="10">
                                        <Label>Tick If There Are Any Additional (non-kiwifruit) Enterprises On This Land Parcel?</Label>
                                    </Col>
                                </Row>
                                {nonKiwifruitEnterprisesOnThisLand && 
                                    <>
                                        <br />
                                        <Row>
                                        <Col>
                                            {(roleName == 'Zespri Admin' || isAddEditItem == 'true') &&
                                                <>
                                                    <button onClick={handleEnterpriseRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                        Add
                                                    </button>
                                                </>
                                            }
                                            
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>

                                                <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                                                    <thead>
                                                    <tr>
                                                            <th className="text-center"> Category </th>
                                                            <th className="text-center"> Enterprise </th>
                                                            <th className="text-center"> Enterprise Type </th>
                                                            <th className="text-center"> Enterprise / Farming Activities Description </th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {enterpriseRows.map((item, idx) => (
                                                            <tr id="addr0" key={idx}>
                                                                <td style={{ display: "none" }}>{enterpriseRows[idx].id}</td>
                                                                <td style={{ width: "25%" }}>
                                                                    {enterpriseCategoryList == undefined && <p><em>Loading...</em></p>}
                                                                    {enterpriseCategoryList != undefined && enterpriseCategoryList != null &&
                                                                        <Input type="select" value={enterpriseRows[idx].enterpriseCategory} onChange={handleEnterpriseCategoryChange(idx)} name="enterpriseCategory" id="enterpriseCategory">
                                                                            {enterpriseCategoryList.map(items =>
                                                                                <option key={uuid()} value={items.id}>{items.name}</option>
                                                                            )}
                                                                        </Input>
                                                                    }
                                                                </td>
                                                                <td style={{ width: "25%" }}>
                                                                    {enterpriseTypeList == undefined && <p><em>Loading...</em></p>}
                                                                    {enterpriseTypeList != undefined && enterpriseTypeList != null &&
                                                                        <Input type="select" value={enterpriseRows[idx].enterpriseType} onChange={handleEnterpriseTypeChange(idx)} name="enterpriseType" id="enterpriseType">
                                                                            {enterpriseTypeList.filter(x => x.category == enterpriseRows[idx].enterpriseCategory).map(items =>
                                                                                <option key={uuid()} value={items.id}>{items.name}</option>
                                                                            )}
                                                                        </Input>
                                                                    }
                                                                </td>
                                                                <td style={{ width: "25%" }}>
                                                                    <input className="form-control" type="text" value={enterpriseRows[idx].farmingActivity} maxLength="20" onChange={handleFarmingActivityChange(idx)} name="farmingActivity" id="farmingActivity" autoComplete="new-password" />
                                                                </td>
                                                                <td style={{ width: "45%" }}>
                                                                    <textarea name="overviewOfFarmingActivities"
                                                                        value={enterpriseRows[idx].overviewOfFarmingActivities}
                                                                        onChange={handleOverviewOfFarmingActivitiesChange(idx)}
                                                                        className="form-control"></textarea>
                                                                </td>
                                                                <td style={{ width: "5%" }}>
                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                        <>
                                                                            {
                                                                                !enterpriseRows[idx].isLUPresent &&
                                                                                <button style={{ float: "right" }} onClick={handleEnterpriseRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                                                    <FaTrash />
                                                                                </button>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </Col>
                                        </Row>
                                        
                                    </>    
                                }
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                {savingEnterprises ?
                                                    <Button style={{ float: "right" }} onClick={handleEnterpriseRowsSubmit}
                                                        color="success" className="button-save"
                                                        disabled
                                                    >
                                                        <Row>
                                                            <Col sm="8">
                                                                <span>
                                                                    {' '}Saving
                                                                </span>
                                                            </Col>
                                                            <Col sm="4">
                                                                <Spinner size="sm">
                                                                    Saving...
                                                                </Spinner>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    :
                                                    <Button className="button-save" style={{ float: "right" }} onClick={handleEnterpriseRowsSubmit} color="success" size="sm">
                                                        Save
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="my-4" style={{display:"none"}}>
                            <CardHeader className="d-flex card-header-dark">
                                <h5>Optional Pages and Add On's</h5>
                            </CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Row >
                                            <Col>
                                                <Label className="label" for="name">Add-on's and Optional Pages</Label>

                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                <Input type="checkbox" checked={optionalPages_Cropping} onChange={(e) => setOptionalPages_Cropping(!optionalPages_Cropping)} name="optionalPages_Cropping" id="optionalPages_Cropping" />
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Cropping and Cultivation</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                {enterpriseTypeListSelectedValues.filter(enterpriseType => enterpriseType.label.includes("Dairy")).length > 0 ?
                                                                    < Input type="checkbox" checked={optionalPages_Effluent} onChange={(e) => setOptionalPages_Effluent(!optionalPages_Effluent)} name="optionalPages_Effluent" id="optionalPages_Effluent" disabled />
                                                                    : < Input type="checkbox" checked={optionalPages_Effluent} onChange={(e) => setOptionalPages_Effluent(!optionalPages_Effluent)} name="optionalPages_Effluent" id="optionalPages_Effluent" />
                                                                }
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Effluent</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                <Input type="checkbox" checked={optionalPages_Biodiversity} onChange={(e) => setOptionalPages_Biodiversity(!optionalPages_Biodiversity)} name="optionalPages_Biodiversity" id="optionalPages_Biodiversity" />
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Biodiversity</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                <Input type="checkbox" checked={optionalPages_FreshwaterIrrigation} onChange={(e) => setOptionalPages_FreshwaterIrrigation(!optionalPages_FreshwaterIrrigation)} name="optionalPages_FreshwaterIrrigation" id="optionalPages_FreshwaterIrrigation" />
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Irrigation</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                <Input type="checkbox" checked={optionalPages_WinterGrazing} onChange={(e) => setOptionalPages_WinterGrazing(!optionalPages_WinterGrazing)} name="optionalPages_WinterGrazing" id="optionalPages_WinterGrazing" />
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Winter Grazing</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="2">
                                                                <Input type="checkbox" checked={optionalPages_GreenhouseGass} onChange={(e) => setOptionalPages_GreenhouseGass(!optionalPages_GreenhouseGass)} name="optionalPages_GreenhouseGass" id="optionalPages_GreenhouseGass" />
                                                            </Col>
                                                            <Col sm="10">
                                                                <Label>Greenhouse Gasses</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {savingEnterprisesOptions ?
                                            <Button style={{ float: "right" }} onClick={handleEnterprisesOptionsSubmit}
                                                color="success"
                                                disabled
                                            >
                                                <Spinner size="sm">
                                                    Saving...
                                                </Spinner>
                                                <span>
                                                    {' '}Saving
                                                </span>
                                            </Button>
                                            :
                                            <Button style={{ float: "right" }} onClick={handleEnterprisesOptionsSubmit} color="success" size="sm">
                                                Save
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="my-4" style={{ display: "none"}}>
                            <CardHeader className="card-header-dark"><h5>Farm System</h5></CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Farm System Type</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="text" value={farmSystemType} onChange={(e) => setFarmSystemType(e.target.value)} name="farmSystemType" id="farmSystemType" autoComplete="new-password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Predominant Farming Activities</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setFarmingActivities(e.target.value)} value={farmingActivities} name="farmingActivities" id="farmingActivities" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <table style={{ float: "right", marginTop: "10px" }} className="table table-bordered" id="tab_logic">
                                            <thead>
                                                <tr>
                                                    <th className="text-center"> Total Area (ha) </th>
                                                    <th className="text-center"> Lease Area (ha) </th>
                                                    <th className="text-center"> Irrigated Area (ha) </th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "33%" }}>
                                                        <input type="text" disabled name="totalArea" value={totalArea == "0" ? "" : totalArea} className="form-control" />
                                                    </td>
                                                    <td style={{ width: "33%" }}>
                                                        <input type="text" disabled name="leaseArea" value={leaseArea == "0" ? "" : leaseArea} className="form-control" />
                                                    </td>
                                                    <td style={{ width: "33%" }}>
                                                        <input type="text" disabled name="irrigatedArea" value={irrigatedArea == "0" ? "" : irrigatedArea} className="form-control" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {savingFarmSystem ?
                                            <Button style={{ float: "right" }} onClick={handleFarmSystemSubmit}
                                                color="success"
                                                disabled
                                            >
                                                <Spinner size="sm">
                                                    Saving...
                                                </Spinner>
                                                <span>
                                                    {' '}Saving
                                                </span>
                                            </Button>
                                            :
                                            <Button style={{ float: "right" }} onClick={handleFarmSystemSubmit} color="success" size="sm">
                                                Save
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="my-4">
                            <CardHeader className="card-header-dark"><h5>Orchard Story</h5></CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <FaInfoCircle data-tooltip-id="my-tooltip-11" />&nbsp;&nbsp;&nbsp;
                                            <Label className="label" for="name">History</Label>
                                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                id="my-tooltip-11"
                                                place="top"
                                                variant="info"
                                                render={({ content, activeAnchor }) => (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <p style={{ fontWeight: "lighter" }}>
                                                                    Here, provide detail on the backgroud of your property. This provides useful context for those reading your FWFP (ie certifiers).<br/>For example: previously dairy farm, converted to kiwifruit in 2015 when we planted 5ha gold and 3ha green.<br/>Converted 1ha of green to gold in 2021.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setFarmStory({ ...farmStory, FarmStory: e.target.value })} value={farmStory.FarmStory} name="FarmStory" id="FarmStory" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <FaInfoCircle data-tooltip-id="my-tooltip-12" />&nbsp;&nbsp;&nbsp;
                                            <Label className="label" for="name">Potential Goals</Label>
                                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                id="my-tooltip-12"
                                                place="top"
                                                variant="info"
                                                render={({ content, activeAnchor }) => (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <p style={{ fontWeight: "lighter" }}>
                                                                    Here, explain any goals you are working towards on your property. These can be at a high level, or very specific.<br/>For example: Enhance the health of our local waterways by working to restore our wetland area, optimise nitrogen applied to vines while maintaining productivity.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setFarmStory({ ...farmStory, FarmStoryGoals: e.target.value })} value={farmStory.FarmStoryGoals} name="FarmStoryGoals" id="FarmStoryGoals" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <FaInfoCircle data-tooltip-id="my-tooltip-13" />&nbsp;&nbsp;&nbsp;
                                            <Label className="label" for="name">Interesting Features</Label>
                                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                id="my-tooltip-13"
                                                place="top"
                                                variant="info"
                                                render={({ content, activeAnchor }) => (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <p style={{ fontWeight: "lighter" }}>
                                                                    Here, provide detail on any features on your property that you'd like to feature or highlight in your FWFP.<br/>Examples: Peat lake at bottom of basin, small stream runs through property.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setFarmStory({ ...farmStory, IntrestingFeatures: e.target.value })} value={farmStory.IntrestingFeatures} name="IntrestingFeatures" id="IntrestingFeatures" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <FaInfoCircle data-tooltip-id="my-tooltip-14" />&nbsp;&nbsp;&nbsp;
                                            <Label className="label" for="name">Any concerns/worries</Label>
                                            <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                id="my-tooltip-14"
                                                place="top"
                                                variant="info"
                                                render={({ content, activeAnchor }) => (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <p style={{ fontWeight: "lighter" }}>
                                                                    Here, note any areas of potential concern that your FWFP will help address.<br/>For example: steep hillside prone to erosion, during winter bottom block often floods. 
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setFarmStory({ ...farmStory, Concerns: e.target.value })} value={farmStory.Concerns} name="Concerns" id="Concerns" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                {savingFarmStory ?
                                                    <Button style={{ float: "right" }} onClick={handleFarmStorySubmit}
                                                        color="success" className="button-save"
                                                        disabled
                                                    >
                                                        <Row>
                                                            <Col sm="8">
                                                                <span>
                                                                    {' '}Saving
                                                                </span>
                                                            </Col>
                                                            <Col sm="4">
                                                                <Spinner size="sm">
                                                                    Saving...
                                                                </Spinner>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    :
                                                    <Button className="button-save" style={{ float: "right" }} onClick={handleFarmStorySubmit} color="success" size="sm">
                                                        Save
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="my-4">
                            <CardHeader className="card-header-dark"><h5>
                                <FaInfoCircle data-tooltip-id="my-tooltip-15" />&nbsp;&nbsp;&nbsp;
                                Tell us about the work you have already done</h5>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                    id="my-tooltip-15"
                                    place="top"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>
                                                        Here, you can share detailed work you've already done on your property that contributes to healthy freshwater.<br />This can help those reading the report better understand your current state. Enter the examples in the relevant sections.<br />For example: Riparian areas/wetlands -  planted 3/4 of the stream bank with natives in 2019,  Other: installed sediment pond in 2007.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            </CardHeader>
                            <br />
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Riparian areas/Wetlands</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setTellUsAbout({ ...tellUsAbout, RiparianAreas: e.target.value })} value={tellUsAbout.RiparianAreas} name="RiparianAreas" id="RiparianAreas" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Areas that have been retired (QEII)</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setTellUsAbout({ ...tellUsAbout, AreasRetired: e.target.value })} value={tellUsAbout.AreasRetired} name="AreasRetired" id="AreasRetired" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Fencing of Waterways</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setTellUsAbout({ ...tellUsAbout, FencingOfWaterways: e.target.value })} value={tellUsAbout.FencingOfWaterways} name="FencingOfWaterways" id="FencingOfWaterways" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Stock Policy (If any)</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setTellUsAbout({ ...tellUsAbout, StockPolicy: e.target.value })} value={tellUsAbout.StockPolicy} name="StockPolicy" id="StockPolicy" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="label" for="name">Other</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Input type="textarea" onChange={(e) => setTellUsAbout({ ...tellUsAbout, Other: e.target.value })} value={tellUsAbout.Other} name="Other" id="Other" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                {savingTellUsAbout ?
                                                    <Button style={{ float: "right" }} onClick={handleTellUsAboutSubmit}
                                                        color="success" className="button-save"
                                                        disabled
                                                    >
                                                        <Row>
                                                            <Col sm="8">
                                                                <span>
                                                                    {' '}Saving
                                                                </span>
                                                            </Col>
                                                            <Col sm="4">
                                                                <Spinner size="sm">
                                                                    Saving...
                                                                </Spinner>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    :
                                                    <Button className="button-save" style={{ float: "right" }} onClick={handleTellUsAboutSubmit} color="success" size="sm">
                                                        Save
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {/*<Card className="my-4">*/}
                        {/*    <CardHeader className="d-flex card-header-dark">*/}
                        {/*        <h5>Other Regulatory Requirements Covered</h5>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardBody>*/}
                        {/*        <Row>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <Label className="label" for="name">Are you using this Fresh Water Farm plan to meet any other regulatory requirements</Label>*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <Input type="select" value={regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, FWFPToMeetAnyOtherRegulatoryRequirement: e.target.value })} name="FWFPToMeetAnyOtherRegulatoryRequirement" id="FWFPToMeetAnyOtherRegulatoryRequirement">*/}
                        {/*                        <option value="Yes">Yes</option>*/}
                        {/*                        <option value="No">No</option>*/}
                        {/*                    </Input>*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ display: "none" }}>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <span className="label-note">Detail any regulatory requirements which this Fresh Water Farm Plan (when certified) will also meet. Examples include Regional Plan Rules, National Environmental Standards, or Regulations made under section 360 of the act</span>*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <Input type="textarea" value={regulatoryRequirementDetails.RegulatoryRequirementDetails} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, RegulatoryRequirementDetails: e.target.value })} name="RegulatoryRequirementDetails" id="RegulatoryRequirementDetails" autoComplete="new-password" />*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*        {*/}
                        {/*            regulatoryRequirementDetails.FWFPToMeetAnyOtherRegulatoryRequirement == "Yes" && <>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <button onClick={handleRegulatoryRequirementRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm btn-pluse-light">*/}
                        {/*                            <FaPlus />*/}
                        {/*                        </button>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*                <br />*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}

                        {/*                        <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">*/}
                        {/*                            <thead>*/}
                        {/*                                <tr>*/}
                        {/*                                    <th className="text-center"> Regulatory Requirement </th>*/}
                        {/*                                    <th className="text-center"> Condition </th>*/}
                        {/*                                    <th />*/}
                        {/*                                </tr>*/}
                        {/*                            </thead>*/}
                        {/*                            <tbody>*/}
                        {/*                                {regulatoryRequirementsRows != undefined && regulatoryRequirementsRows.map((item, idx) => (*/}
                        {/*                                    <tr id="addr0" key={idx}>*/}
                        {/*                                        <td style={{ display: "none" }}>{regulatoryRequirementsRows[idx].id}</td>*/}
                        {/*                                        <td style={{ width: "25%" }}>*/}
                        {/*                                            <input className="form-control" type="text" value={regulatoryRequirementsRows[idx].regulatoryRequirement} maxLength="1000" onChange={handleRegulatoryRequirementChange(idx)} name="regulatoryRequirement" id="regulatoryRequirement" autoComplete="new-password" />*/}
                        {/*                                        </td>*/}
                        {/*                                        <td style={{ width: "25%" }}>*/}
                        {/*                                            <input className="form-control" type="text" value={regulatoryRequirementsRows[idx].condition} maxLength="1000" onChange={handleRegulatoryRequirementConditionChange(idx)} name="condition" id="condition" autoComplete="new-password" />*/}
                        {/*                                        </td>*/}
                        {/*                                        <td style={{ width: "5%" }}>*/}
                        {/*                                            <button style={{ float: "right" }} onClick={handleRegulatoryRequirementRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">*/}
                        {/*                                                <FaTrash />*/}
                        {/*                                            </button>*/}
                        {/*                                        </td>*/}
                        {/*                                    </tr>*/}
                        {/*                                ))}*/}
                        {/*                            </tbody>*/}
                        {/*                        </table>*/}

                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*        <Row>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <Label className="label" for="name">Are there any new phsyical works being planned for the farm?</Label>*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*            <Col sm="6">*/}
                        {/*                <FormGroup>*/}
                        {/*                    <Input type="select" value={regulatoryRequirementDetails.IsAnyNewPhsyicalWorks} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, IsAnyNewPhsyicalWorks: e.target.value })} name="IsAnyNewPhsyicalWorks" id="IsAnyNewPhsyicalWorks">*/}
                        {/*                        <option value="Yes">Yes</option>*/}
                        {/*                        <option value="No">No</option>*/}
                        {/*                    </Input>*/}
                        {/*                </FormGroup>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*        {*/}
                        {/*            regulatoryRequirementDetails.IsAnyNewPhsyicalWorks == "Yes" && <>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <span className="label-note"> Please detail below and attach relevant document if needed.</span>*/}
                        {/*                        <FormGroup>*/}
                        {/*                            <Input type="textarea" value={regulatoryRequirementDetails.AnyNewPhsyicalWorksDetails} onChange={(e) => setRegulatoryRequirementDetails({ ...regulatoryRequirementDetails, AnyNewPhsyicalWorksDetails: e.target.value })} name="AnyNewPhsyicalWorksDetails" id="AnyNewPhsyicalWorksDetails" />*/}
                        {/*                        </FormGroup>*/}

                        {/*                        <div>*/}
                        {/*                            <Row>*/}
                        {/*                                <Col>*/}
                        {/*                                    <button onClick={handleRecordRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm btn-pluse-light">*/}
                        {/*                                        <FaPlus />*/}
                        {/*                                    </button>*/}
                        {/*                                </Col>*/}
                        {/*                            </Row>*/}
                        {/*                            <br />*/}
                        {/*                            <Row>*/}
                        {/*                                <Col sm="12">*/}
                        {/*                                    <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">*/}
                        {/*                                        <thead>*/}
                        {/*                                            <tr>*/}
                        {/*                                                <th className="text-center"> Date</th>*/}
                        {/*                                                <th className="text-center"> Details </th>*/}
                        {/*                                                <th className="text-center"> Attachment </th>*/}
                        {/*                                                <th />*/}
                        {/*                                            </tr>*/}
                        {/*                                        </thead>*/}
                        {/*                                        <tbody>*/}
                        {/*                                            {documentUploadList.map((item, idx) => (*/}
                        {/*                                                <tr id="records" key={idx}>*/}
                        {/*                                                    <td style={{ display: "none" }}>{documentUploadList[idx].id}</td>*/}
                        {/*                                                    <td style={{ width: "15%" }}>*/}
                        {/*                                                        <DatePicker selected={documentUploadList[idx].uploadDate != null ? getDateFromString(moment(documentUploadList[idx].uploadDate).format("DD/MM/YYYY")) : null} onChange={(date) => handleDateChange(idx, date)} name="uploadDate" dateFormat="dd/MM/yyyy" className="form-control" />*/}
                        {/*                                                    </td>*/}
                        {/*                                                    <td style={{ width: "45%" }}>*/}
                        {/*                                                        <input type="textarea" name="documentDetail" value={documentUploadList[idx].documentDetail} onChange={handleDetailsChange(idx)} className="form-control" />*/}
                        {/*                                                    </td>*/}
                        {/*                                                    <td style={{ width: "15%" }}>*/}
                        {/*                                                        {documentUploadList[idx].documentRelativePath != null && documentUploadList[idx].documentRelativePath != '' ?*/}
                        {/*                                                            <Row>*/}
                        {/*                                                                <Col>*/}
                        {/*                                                                    <a href={documentUploadList[idx].documentRelativePath} target='_blank'>*/}
                        {/*                                                                        <img src="/images/PDF_icon.png" className="PDF_icon" ></img>*/}
                        {/*                                                                    </a>*/}
                        {/*                                                                </Col>*/}
                        {/*                                                                <Col>*/}
                        {/*                                                                    <Button style={{ float: `right` }} color="warning" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>*/}
                        {/*                                                                </Col>*/}
                        {/*                                                            </Row>*/}
                        {/*                                                            :*/}
                        {/*                                                            <Button style={{ float: `right` }} color="success" size="sm" onClick={(e) => handleBtnUploadClick(e, idx)} ><FaUpload /></Button>*/}
                        {/*                                                        }*/}
                        {/*                                                        <br />*/}
                        {/*                                                        <input style={{ float: `right`, display: 'none' }}*/}
                        {/*                                                            ref={input => {*/}
                        {/*                                                                inputFile[idx] = input;*/}
                        {/*                                                            }}*/}
                        {/*                                                            type="file" name="attachment" className="form-control" onChange={(e) => handleRecordChange(e, idx)} />*/}
                        {/*                                                        {formErrors.length > 0 && formErrors[idx] != undefined && formErrors[idx].fileError != undefined &&*/}
                        {/*                                                            <p className="errorMessage">{formErrors[idx].fileError}</p>*/}
                        {/*                                                        }*/}
                        {/*                                                    </td>*/}
                        {/*                                                    <td style={{ width: "5%" }}>*/}
                        {/*                                                        <button style={{ float: "right" }} onClick={handleRecordRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">*/}
                        {/*                                                            <FaTrash />*/}
                        {/*                                                        </button>*/}
                        {/*                                                    </td>*/}
                        {/*                                                </tr>*/}
                        {/*                                            ))}*/}
                        {/*                                        </tbody>*/}
                        {/*                                    </table>*/}
                        {/*                                </Col>*/}

                        {/*                            </Row>*/}
                        {/*                        </div>*/}
                        {/*                    </Col>*/}

                        {/*                </Row>*/}

                        {/*            </>*/}
                        {/*        }*/}
                        {/*        <Row>*/}
                        {/*            <Col>*/}
                        {/*                {savingRegulatoryRequirementDetails ?*/}
                        {/*                    <Button style={{ float: "right" }} onClick={handleRegulatoryRequirementDetailsSubmit}*/}
                        {/*                        color="success" className="button-save"*/}
                        {/*                        disabled*/}
                        {/*                    >*/}
                        {/*                        <Spinner size="sm">*/}
                        {/*                            Saving...*/}
                        {/*                        </Spinner>*/}
                        {/*                        <span>*/}
                        {/*                            {' '}Saving*/}
                        {/*                        </span>*/}
                        {/*                    </Button>*/}
                        {/*                    :*/}
                        {/*                    <Button className="button-save" style={{ float: "right" }} onClick={handleRegulatoryRequirementDetailsSubmit} color="success" size="sm">*/}
                        {/*                        Save*/}
                        {/*                    </Button>*/}
                        {/*                }*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Contacts userId={userId} checkAlreadyIsresponsible={checkAlreadyIsresponsibleChecked}></Contacts>
                    </Col>
                    
                </Row>
            </>
            }
        </div>
    )
}

export default Enterprises;