import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import SpinnerContainer from './components/Utilities/Ui/SpinnerContainer';
import { Route, Routes } from 'react-router-dom';
import OuterLayout from './components/OuterLayout';
import NonWizardLayout from './components/NonWizardLayout';
import Layout from './components/Layout';
import Outline from './components/Outline/Outline';
import CatchmentContext from './components/CatchmentContext/CatchmentContext';
import Enterprises from './components/Enterprises/Enterprises';
import Infrastructure from './components/Infrastructure/Infrastructure';
import ExternalOrganisation from './components/ExternalOrganisation/ExternalOrganisation';
import EditExternalOrganisation from './components/ExternalOrganisation/EditExternalOrganisation';
import CreateExternalOrganisation from './components/ExternalOrganisation/CreateExternalOrganisation';
import User from './components/User/User';
import CreateUser from './components/User/CreateUser';
import EditUser from './components/User/EditUser';
import Farm from './components/Farm/Farm';
import CreateFarm from './components/Farm/CreateFarm';
import EditFarm from './components/Farm/EditFarm';
import UserFarm from './components/UserFarm/UserFarm';
import News from './components/News/News';
import CreateNews from './components/News/CreateNews';
import EditNews from './components/News/EditNews';
import Unauthorized from './components/Utilities/Errors/Unauthorized';
import NotFound from './components/Utilities/Errors/NotFound';
import RequireAuth from './components/Utilities/RequireAuth/RequireAuth';
import Login from './components/Utilities/Auth/Login';
import ResetPassword from './components/Utilities/Auth/ResetPassword';
import ChangePwd from './components/Utilities/Auth/ChangePwd';
import TestAuth from "./components/Utilities/Errors/TestAuth";
import TestAuthOrg from "./components/Utilities/Errors/TestAuthOrg";
import Lmu from './components/Lmu/Lmu';
import Waterways from './components/Waterways/Waterways';
import LandSoil from './components/LandSoil/LandSoil';
import MahingaKai from './components/MahingaKai/MahingaKai';
import FreshWaterIrrigation from './components/FreshWaterIrrigation/FreshWaterIrrigation';
import MyInfo from './components/MyInfo/MyInfo';
import ViewNews from './components/MyInfo/ViewNews';
import FarmSelector from './components/Farm/FarmSelector';
import Nutrients from './components/Nutrients/Nutrients';
import Summary from './components/Summary/Summary';
import Home  from "./components/Home";
import LmuUpload from "./components/Uploads/LmuUpload";
import ShapeUpload from "./components/UserKMLUpload/ShapeUpload";
import AllFarmsSummary from './components/AllFarmsSummary/AllFarmsSummary';
import EnterpriseInsights from './components/EnterpriseInsights/EnterpriseInsights';
import './custom.css';
import './common.css';
import useAuth from "./components/Utilities/Hooks/useAuth";
import RiskSummary from './components/RiskMap/RiskSummary';
import ChangePassword from './components/User/ChangePassword';
import Risk from './components/RiskSummary/RiskSummary';
import Agrichemicals from './components/Agrichemicals/Agrichemicals';
import toast, { Toaster } from 'react-hot-toast';

const ROLES = {
    'Admin': 1,
    'OrgUser': 2,
    'EntityUser': 3,
}

function App() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const [wizardDetails, setWizardDetails] = useState([]);
    const [pageReload, setpageReload] = useState(true);
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        if (auth != null) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
        }
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            window.wizardDetails = [];
            let response = await axios.get(
                '/api/wizard/GetWizardDetails/', {
                params: {
                        farmId: farmId == null ? 0 : farmId
                }
            }
            );
            setLoading(false);
            setWizardDetails(response.data)
            window.wizardDetails = response.data.wizardItemList;
            setpageReload(!pageReload)
            var landingPage = response.data.landingPage;
            response = await axios.get(
                '/api/settings/GetUsers/', {
                params: {}
            }
            );
            var user = {};
            if (response.data != null) {
                user = response.data.content.users.filter(x => x.id == userId);
                if (user != null) {
                }
            }
            if (window.location.pathname == '/' || window.location.pathname.toLowerCase() == '/home') {
                if (user != null) {
                    if (user[0].tcAccepted && user[0].userFarmList.length > 0) {
                        window.open(landingPage, "_self");
                    }
                    else if (user[0].userFarmList.length == 0) {
                        toast.error("Sorry, this user does not have any Orchard allocated to access. Please contact Zespri team", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleWizardChanged = async (section) => {
        let form = {
            farmId: farmId,
            section: section,
            userId: userId,
        }
        try {
            const response = await axios.post(
                '/api/wizard/UpdateWizardDetails/',
                JSON.stringify(form),{
                    params: {
                        farmId: farmId,
                        section: section,
                        userId: userId
                    },
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status == true) {
                    const redirectURL = response.data.content.redirectURL;
                    const wizardModel = response.data.content.wizardModel;
                    setWizardDetails(wizardModel);
                    setpageReload(!pageReload);
                    window.open(redirectURL, "_self");
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }
    const handleWizardChangedBack = async (section) => {
        let form = {
            farmId: farmId,
            section: section,
            userId: userId,
        }
        try {
            const response = await axios.post(
                '/api/wizard/UpdateWizardDetailsBack/',
                JSON.stringify(form), {
                params: {
                    farmId: farmId,
                    section: section,
                    userId: userId
                },
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (response.data != null) {
                if (response.data.status == true) {
                    const redirectURL = response.data.content.redirectURL;
                    const wizardModel = response.data.content.wizardModel;
                    setWizardDetails(wizardModel);
                    setpageReload(!pageReload);
                    window.open(redirectURL, "_self");
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }
    const handleMoveToNextPage = async (section) => {
        let form = {
            farmId: farmId,
            section: section,
            userId: userId,
        }
        try {
            const response = await axios.post(
                '/api/wizard/MoveToNextPage/',
                JSON.stringify(form), {
                params: {
                    farmId: farmId,
                    section: section,
                    userId: userId
                },
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (response.data != null) {
                if (response.data.status == true) {
                    const redirectURL = response.data.content.redirectURL;
                    setpageReload(!pageReload);
                    window.open(redirectURL, "_self");
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }
    const handleWizardFarmChanged = async (farmid) => {

        try {
            const response = await axios.get(
                '/api/wizard/GetWizardDetails/', {
                params: {
                    farmId: farmid
                }
            }
            );
            if (response.data != null) {
                setWizardDetails(response.data)
                if (response.data.isCompleted) {
                    window.open("/summary", "_self");
                }
                else {
                    window.open(response.data.landingPage, "_self");
                }
            }
        } catch (error) {
           
            console.error('Error:', error);
        }

    }

    return (
        loading
            ? <SpinnerContainer />
            : <Routes>
                <Route exact path="/" element={<OuterLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />
                    <Route path="/changepwd" element={<ChangePwd />} />
                    <Route path="/notfound" element={<NotFound />} />
                    <Route element={<Layout wizardDetails={wizardDetails} pageReload={pageReload}/>}>
                        <Route path="/unauthorized" element={<Unauthorized />} />                        
                        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                            <Route path="/testauth" element={<TestAuth />} />
                        </Route>
                        <Route element={<RequireAuth allowedRoles={[ROLES.OrgUser]} />}>
                            <Route path="/testauthorg" element={<TestAuthOrg />} />
                        </Route>
                        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.EntityUser, ROLES.OrgUser]} />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/outline" element={<Outline handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/catchmentcontext" element={<CatchmentContext handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/enterprises" element={<Enterprises handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/infrastructure" element={<Infrastructure handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/waterways" element={<Waterways handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/landsoil" element={<LandSoil handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/freshwaterirrigation" element={<FreshWaterIrrigation handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/mahingakai" element={<MahingaKai handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/lmu" element={<Lmu handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/agrichemicals" element={<Agrichemicals handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/nutrients" element={<Nutrients handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />} />
                            <Route path="/summary" element={<Summary handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />}  />
                            <Route path="/risk" element={<Risk handleMoveToNextPage={handleMoveToNextPage} handleWizardChanged={handleWizardChanged} handleWizardChangedBack={handleWizardChangedBack} />}  />
                            
                        </Route>
                    </Route>
                    <Route element={<NonWizardLayout />}>
                        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.EntityUser, ROLES.OrgUser]} />}>
                            <Route path="/Home" element={<Home />} />
                            <Route path="/farmselector" element={<FarmSelector handleWizardFarmChanged={handleWizardFarmChanged} />} />
                            <Route path="/allfarmssummary" element={<AllFarmsSummary handleWizardFarmChanged={handleWizardFarmChanged} />} />
                            <Route path="/enterpriseinsights" element={<EnterpriseInsights handleWizardFarmChanged={handleWizardFarmChanged} />} />
                        </Route>
                        <Route path="/welcome" element={<Home />} />
                        <Route path="/externalorganisation" element={<ExternalOrganisation />} />
                        <Route path="/editexternalorganisation" element={<EditExternalOrganisation />} />
                        <Route path="/createexternalorganisation" element={<CreateExternalOrganisation />} />
                        <Route path="/user" element={<User />} />
                        <Route path="/createuser" element={<CreateUser />} />
                        <Route path="/edituser" element={<EditUser />} />
                        <Route path="/orchard" element={<Farm />} />
                        <Route path="/createorchard" element={<CreateFarm />} />
                        <Route path="/editorchard" element={<EditFarm />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/createnews" element={<CreateNews />} />
                        <Route path="/editnews" element={<EditNews />} />
                        <Route path="/userorchard" element={<UserFarm />} />
                        <Route path="/myinfo" element={<MyInfo />} />
                        <Route path="/viewnews" element={<ViewNews />} />
                        <Route path="/lmuupload" element={<LmuUpload />} />
                        <Route path="/shapeUpload" element={<ShapeUpload />} />
                        <Route path="/changepassword" element={<ChangePassword />} />
                    </Route>
                </Route>
            </Routes>
    );
}

export default App;
