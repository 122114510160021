import { Row, Col } from 'reactstrap';
import ItemTypeView from '../ItemsView/ItemTypeView';
import ItemTypeListView from '../ItemsView/ItemTypeListView';
import LargeFarmView from './LargeFarmView';
import Documents from '../Uploads/Documents';
import ActionsToConsider from '../FarmApt/ActionsToConsider';
import WholeFarmActionView from './WholeFarmActionView';
import {  FaSlash} from 'react-icons/fa';
const NonMapView = ({ itemModel, handleClearSelection, toggleItemTypeListState, toggleItemTypeState, toggleItemState,
    handleItemClick, handleItemEditPopup, handleItemDeletePopup, handleOpenActionPopup, largeEnterpriseDetails, handleLMUSelectChange,
    handleItemTypeListTogole, handleItemTypeTogole, handleItemTogole, sectionObj, handleItemHighlight, outlineCenter, handleOpenWholeActionPopup, isWizardCompleted }) => {

    const itemList = itemModel.itemList;
    const itemTypesList = itemModel.itemTypes;
    const uniqueItemTypesList = itemModel.uniqueItemTypes;
    const Section = itemModel.Section;
    return (
        <Row>
            <Col>
                {Section != 'Community & Cultural Significance' &&
                    <LargeFarmView Section={Section} largeEnterpriseDetails={largeEnterpriseDetails} handleLMUSelectChange={handleLMUSelectChange}></LargeFarmView>
                }
                {
                    Section != "Community & Cultural Significance" &&
                    <>
                        <ItemTypeListView isWizardCompleted={isWizardCompleted} Section={Section}
                            itemTypesList={itemTypesList}
                            toggleItemTypeListState={toggleItemTypeListState}
                            handleClearSelection={handleClearSelection}
                            handleItemClick={handleItemClick}
                            handleItemTypeListTogole={handleItemTypeListTogole}
                        >
                        </ItemTypeListView>
                        <br />
                    </>
                }
                {sectionObj.isWholeFarmActionRequired && 
                    <>
                    <WholeFarmActionView outlineCenter={outlineCenter} handleOpenWholeActionPopup={handleOpenWholeActionPopup} open={false} showArrow={true}></WholeFarmActionView>
                        <br/>
                    </>
                }
                {uniqueItemTypesList.map(type =>
                    itemList.filter(item => item.itemTypeName == type.name).length != 0 &&
                    <ItemTypeView key={type.uniqueName} itemList={itemList} itemType={type} itemTypesList={itemTypesList}
                        largeEnterpriseDetails={largeEnterpriseDetails}
                        toggleItemTypeState={toggleItemTypeState}
                        toggleItemState={toggleItemState}
                        handleItemEditPopup={handleItemEditPopup}
                        handleItemDeletePopup={handleItemDeletePopup}
                        handleOpenActionPopup={handleOpenActionPopup}
                        handleItemTypeTogole={handleItemTypeTogole}
                        handleItemTogole={handleItemTogole}
                        handleItemHighlight={handleItemHighlight} Section={Section}
                        >
                        </ItemTypeView>
                )}

                {/*Document Upload*/}
                {
                    sectionObj.isDocumentUploadReq && Section != 'Community & Cultural Significance' && <Documents refid={sectionObj.id} uploadType="Section" open={false} showArrow={true}></Documents>
                }

                {/*Actions to Consider*/}
                {
                    sectionObj.isFarmAPTActionsReq && <ActionsToConsider sectionId={sectionObj.id} />
                }
            </Col>
        </Row>
    );
}
export default NonMapView