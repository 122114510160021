import { useState } from 'react';
import {
    Row, Col, Table, Card, CardBody, CardHeader, Button, Input, Collapse,
    Badge, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import './Lmu.css';
import { FaChevronUp, FaChevronDown, FaDrawPolygon, FaPlus } from 'react-icons/fa';
import uuid from 'react-uuid';
import Moment from 'moment';

const LmuChildItem = ({ item, topographyList, soilTypePredominantList, enterpriseTypeList, toggleChildItemState, hideChildren, handleEditItem, handleDeleteItem, isClosedActionShow,
    handleOpenActionPopup, handleItemHighlight, handleClosedActionShow, handleChildItemToggle }) => {

    const [isOpen, setIsOpen] = useState(item.id == toggleChildItemState.lmuId ? toggleChildItemState.isOpen : false);
    const [isIconUp, setIsIconUp] = useState(item.id == toggleChildItemState.lmuId ? toggleChildItemState.isIconUp : false);
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });

    const handleItemExpandCollapse = (itemId) => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleChildItemToggle(itemId, !isOpen, !isIconUp)
    };

    const calculateSizeHa = (itemId) => {

        let size = item.lmuItems[0].sizeHa;
        return +size.toFixed(2);
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    if (item != undefined && item.isConnectedToAnotherLMU == true) {
        const ImagesCount = item.imageList != null ? item.imageList.length : 0;
        const openActionCount = item.actions.filter(action => action.isCloseout != true).length;
        const closedActionCount = item.actions.filter(action => action.isCloseout == true).length;
        if ((hideChildren.lmuId == item.lmuId && hideChildren.ishideChildren == false) || openActionCount > 0) {
            return (
                <>
                    {
                        <Card className="my-3" key={item.id}>
                            <CardHeader className="item-header item_click" onClick={(itemId) => handleItemExpandCollapse(item.id)}>
                                <Row>
                                    <Col sm="6">   <a onPointerEnter={(itemId) => handleItemHighlight(item.id)} onPointerLeave={(itemId) => handleItemHighlight(0)}>
                                        {item.mapMarkerType == 'polygon' && <FaDrawPolygon color={item.colour}></FaDrawPolygon>}
                                    </a>
                                        {' '}
                                        <span className="text-uppercase fw-bold color-dark-teal">{item.name}</span>{' - '} <span> {calculateSizeHa(item.id)}HA</span>{' '}
                                        {
                                            item.isItemHaveAnyRisk.toLowerCase() == "yes" && !item.isConnectedToAnotherLMU ?
                                                <span className="risk-badge"><Badge color="danger">
                                                    Risk
                                                </Badge></span> :
                                                <></>
                                        }                                </Col>
                                    <Col sm="5" className="text-end">
                                        
                                        {openActionCount > 0 ?
                                            <span className="risk-badge">
                                                <Badge color="primary">
                                                    {openActionCount}
                                                </Badge> <span className="card-header-label-xs">Open Actions</span></span> :

                                            <></>
                                        }
                                    </Col>
                                    <Col sm="1" className="toggleoutline">
                                        {
                                            isIconUp ?
                                                <FaChevronUp /> :
                                                <FaChevronDown />
                                        }
                                    </Col>
                                </Row>

                            </CardHeader>
                            <Collapse isOpen={isOpen}>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div>{item.description}</div><br />
                                            {/*<div>Size(ha):*/}
                                            {/*    {*/}
                                            {/*        <span> {calculateSizeHa(item.id)}</span>*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                            {/*<div>Topography: {*/}
                                            {/*    item?.lmuItems[0].topographyId == 0 ? <span className="no-item-found-success">Not selected</span> : (topographyList.find((topography) => {*/}
                                            {/*        return topography.id === item?.lmuItems[0].topographyId*/}
                                            {/*    }))?.value}</div>*/}
                                            <div>Enterprise: {
                                                item?.lmuItems[0].enterpriseType == 0 ? <span className="no-item-found-success">Not selected</span> : (enterpriseTypeList.find((e) => {
                                                    return e.id === item?.lmuItems[0].enterpriseType
                                                }))?.value}</div>
                                        </Col>
                                        <Col>
                                            <Button className="btn-items-actions" id={item.id} color="danger" size="sm" onClick={handleDeleteItem} >
                                                Delete
                                            </Button>
                                            <Button className="btn-items-actions" id={item.id} color="warning"  size="sm"  onClick={handleEditItem} >
                                                 Edit
                                            </Button>
                                            {/*<Button className="btn-items-actions" color="success" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.id, 0)} >*/}
                                            {/*    <FaPlus /> Action*/}
                                            {/*</Button>*/}
                                        </Col>
                                    </Row>
                                    <br />
                                    {ImagesCount > 0 &&
                                        <Col sm="12">
                                            <Row>
                                                {item.imageList.map(image =>
                                                    <Col key={uuid()} sm="3" className="style-item-display">
                                                        <img src={image.imageRelativePath} className="image-thumbnail" alt={image.imageName} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, image.imageName, image.imageRelativePath)}></img>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    }

                                    {
                                        item.nonContiguousItems?.length > 0 ?
                                            <>
                                                <Row className="my-3">
                                                    <Col>
                                                        <h6>Non-contiguous LMU shapes</h6>
                                                        <Table bordered>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Title
                                                                    </th>
                                                                    <th>
                                                                        Size (ha)
                                                                    </th>
                                                                    <th>
                                                                        Topography
                                                                    </th>
                                                                    <th>
                                                                        Soil (predominant)
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">{item.name}</th>
                                                                    <td>{item.lmuItems[0].sizeHa}</td>
                                                                    <td>{(topographyList.find((topography) => {
                                                                        return topography.id === item?.lmuItems[0].topographyId && topography.id != 0
                                                                    }))?.value}</td>
                                                                    <td>{(soilTypePredominantList.find((soilType) => {
                                                                        return soilType.id === item?.lmuItems[0].soilTypePredominantId && soilType.id != 0
                                                                    }))?.value}</td>
                                                                </tr>
                                                                {
                                                                    item.nonContiguousItems.map(item =>
                                                                        <tr key={item.id}>
                                                                            <th scope="row">
                                                                                {item.name}
                                                                            </th>
                                                                            <td>
                                                                                {item?.lmuItems[0].sizeHa}
                                                                            </td>
                                                                            <td>
                                                                                {(topographyList.find((topography) => {
                                                                                    return topography.id === item?.lmuItems[0].topographyId && topography.id != 0
                                                                                }))?.value}
                                                                            </td>
                                                                            <td>
                                                                                {(soilTypePredominantList.find((soilType) => {
                                                                                    return soilType.id === item?.lmuItems[0].soilTypePredominantId && soilType.id != 0
                                                                                }))?.value}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </>
                                            : <></>
                                    }
                                    {/*<Row className="my-3">*/}
                                    {/*    <Col>*/}
                                    {/*        {closedActionCount > 0 ?*/}
                                    {/*            <FormGroup check inline className="float-end">*/}
                                    {/*                <Input*/}
                                    {/*                    type="checkbox"*/}
                                    {/*                    name="isClosedActionShow"*/}
                                    {/*                    id="isClosedActionShow"*/}
                                    {/*                    checked={isClosedActionShow}*/}
                                    {/*                    onChange={handleClosedActionShow}*/}
                                    {/*                />*/}
                                    {/*                <Label htmlFor="isClosedActionShow" check> Show Closed Actions <Badge>{closedActionCount}</Badge></Label>*/}
                                    {/*            </FormGroup>*/}
                                    {/*            : ''}*/}
                                    {/*        {*/}
                                    {/*            item.actions != undefined && item.actions.length != 0 &&*/}
                                    {/*            <>*/}
                                    {/*                <h5 className="full-width-title">Actions</h5>*/}
                                    {/*                <table className="table borderless">*/}
                                    {/*                    <thead></thead>*/}
                                    {/*                    <tbody>*/}


                                    {/*                        <tr className="actions-table" key={uuid()}>*/}
                                    {/*                            <td >*/}
                                    {/*                                <Table className='table borderless '>*/}
                                    {/*                                    <thead >*/}
                                    {/*                                        <tr>*/}
                                    {/*                                            <th> # </th>*/}
                                    {/*                                            <th style={{ textAlign: 'center' }}> Priority </th>*/}
                                    {/*                                            <th style={{ width: '10%' }}> Due </th>*/}
                                    {/*                                            <th style={{ width: '20%' }}> Title  </th>*/}
                                    {/*                                            <th style={{ width: '30%' }}> Details </th>*/}
                                    {/*                                            <th></th>*/}
                                    {/*                                        </tr>*/}
                                    {/*                                    </thead>*/}
                                    {/*                                    <tbody>*/}
                                    {/*                                        {*/}
                                    {/*                                            item.actions.map(action =>*/}

                                    {/*                                                <tr key={action.id} className={"" + (action.isCloseout === true ? "closedAction" : "openAction") + " " + (!isClosedActionShow && action.isCloseout === true ? "closed-hidden" : "")}>*/}
                                    {/*                                                    <td> {action.id} </td>*/}
                                    {/*                                                    <th scope="row" style={{ textAlign: 'center' }}>*/}
                                    {/*                                                        <Badge color="danger">{action.priority}</Badge></th>*/}
                                    {/*                                                    <td> {action.actionType == 46 ? "Ongoing" : Moment(action.dueDate).format('DD/MM/YYYY')} </td>*/}
                                    {/*                                                    <td> {action.title} </td>*/}
                                    {/*                                                    <td> {action.description} </td>*/}
                                    {/*                                                    <td>  {action.isCloseout !== true ?*/}
                                    {/*                                                        <Button color="warning" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.id, action.id)} > Edit </Button> :*/}
                                    {/*                                                        <Button color="primary" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.id, action.id)} > View </Button>}*/}
                                    {/*                                                    </td>*/}
                                    {/*                                                </tr>*/}
                                    {/*                                            )}*/}
                                    {/*                                    </tbody>*/}
                                    {/*                                </Table>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                    </tbody>*/}
                                    {/*                </table>*/}
                                    {/*            </>*/}
                                    {/*        }*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}

                                </CardBody>
                            </Collapse>
                        </Card>

                    }

                    <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                        <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                        <ModalBody>
                            <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </>
            )
        }
    }
}

export default LmuChildItem