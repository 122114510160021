import { useState, useEffect } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const ItemTypeListView = ({ Section, itemTypesList, toggleItemTypeListState, handleClearSelection, handleItemClick, handleItemTypeListTogole, isWizardCompleted }) => {

    const [isOpen, setIsOpen] = useState(toggleItemTypeListState.isOpen);
    const [isIconUp, setIsIconUp] = useState(toggleItemTypeListState.isIconUp);
    const [isIrrigation, setIsIrrigation] = useState(false);
    const [isWaterways, setIsWaterways] = useState(false);
    const [isLandErosion, setIsLandErosion] = useState(false);
    const [isMahingaKai, setIsMahingaKai] = useState(false);
    const [isOther, setIsOther] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleItemTypeListTogole(!isOpen, !isIconUp);
    };
    useEffect(() => {
        if (Section == 'Irrigation Frost Protection') {
            setIsIrrigation(true);
            setIsOther(false);
        }
        else if (Section == 'Land and Erosion') {
            setIsLandErosion(true);
            setIsOther(false);
        }
        else if (Section == 'Waterways') {
            setIsWaterways(true);
            setIsOther(false);
        }
        else if (Section == 'Community & Cultural Significance') {
            setIsMahingaKai(true);
            setIsOther(false);
        }
    }, []);
    return (
        <Card className="card-header-outline" >
            <CardHeader className="header card-header-click" onClick={toggle}>
                <Row className="itemTypeList-header-div">
                    {Section == 'Community & Cultural Significance' &&
                        <Col sm="11"><Row> <span className="text-uppercase fw-bold color-dark-teal">Location and details of Sites and Species of Significance</span></Row>
                            <Row><label className="itemTypeList-header-subtext">Click to expand and show items for adding to map</label></Row> 
                            </Col>    
                    }
                    {
                        Section != 'Community & Cultural Significance' &&
                        <Col sm="11"><Row> <span className="text-uppercase fw-bold color-dark-teal">{Section}</span></Row>
                                <Row><label className="itemTypeList-header-subtext">Click to expand and show items for adding to map</label></Row> 
                        </Col>    
                    }
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                <Row>
                                    <Col>
                                        <label className="outlineLabel para-light-sm ">Click an icon then click on the map to add items</label>
                                        <Button className="button-common float-end" color="warning" size="sm" onClick={handleClearSelection}>
                                            Clear Selection
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {isIrrigation && 
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="6" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                />
                                                                {type.name == 'Irrigation Area' && type.markerType == 'circle' &&
                                                                    <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (circle)</label>
                                                                }
                                                                {type.name == 'Irrigation Area' && type.markerType == 'rectangle' &&
                                                                    <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (rectangle)</label>
                                                                }
                                                                {type.name == 'Irrigation Area' && type.markerType == 'polygon' &&
                                                                    <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (freehand)</label>
                                                                }
                                                                {type.name != 'Irrigation Area' &&
                                                                    <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        {isWaterways &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="6" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                /> 
                                                                {type.name == 'Lake' && type.markerType == 'circle' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (circle)</label>}
                                                                {type.name == 'Lake' && type.markerType == 'polygon' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (freehand)</label>}
                                                                {type.name == 'Pond' && type.markerType == 'circle' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (circle)</label>}
                                                                {type.name == 'Pond' && type.markerType == 'polygon' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (freehand)</label>}
                                                                {type.name != 'Pond' && type.name != 'Lake' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        {isLandErosion &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="6" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                /> {type.name == 'Areas that hold water' && type.markerType == 'circle' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (circle)</label>}
                                                                {type.name == 'Areas that hold water' && type.markerType == 'polygon' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name} (freehand)</label>}
                                                                {type.name != 'Areas that hold water' && <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        {isMahingaKai &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="6" className="style-item-display">
                                                        {type.name == 'Fishing' && 
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-21"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-21" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-21"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter"}}>
                                                                                            Fishing locations could be fishing spots available to the public, fishing spots for family use, trout fishing spot, etc.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        } 
                                                        {type.name == 'Mahinga Kai' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-22"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-22" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-22"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Mahinga Kai is for any locations important for harvesting food, or where food resources are.<br/>Examples include: whitebait fishing spots, areas watercress grows, etc.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        } 
                                                        {type.name == 'Other' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-23"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-23" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-23"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Other is for any sites of significance not covered by the existing categories.<br/>An example is a protected drinking water site.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        } 
                                                        {type.name == 'Species' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-24"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-24" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-24"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Mark approximate locations where species of significance can be found on your orchard.<br />Examples include: Giant Kokopu and shortjaw Kokopu (adult whitebait),  Rainbow and Brown Trout, Porohe (smelt),<br/>Koura (freshwater crayfish), kaeo/kākahi (freshwater mussels), etc.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        } 
                                                        {type.name == 'Historic Site' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-25"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-25" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-25"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Any historic sites on your property should be mapped using this icon.<br />Examples include Pa (fortified village), redoubts, pits/terraces etc.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        } 
                                                        {type.name == 'Marae' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-26"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-26" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-26"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            You can map nearby Maraes on your map using this icon.<br/>To find out your local Marae, follow the link here: https://maorimaps.com/
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {type.name == 'Recreational Site' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-27"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-27" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                                                                        id="my-tooltip-27"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Recreational sites are anywhere the public or family use the water for recreational purposes.<br/>Examples include swimming holes, boating areas, etc.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {type.name == 'Wahi Tapu' &&
                                                            <Row>
                                                                <Col sm="12">
                                                                    <img data-tooltip-id="my-tooltip-28"
                                                                        className="featureIconNew"
                                                                        src={"/images/ItemIcons/" + type.icon}
                                                                        onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                    /> <label data-tooltip-id="my-tooltip-28" onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                                    <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap: 'break-word' }}
                                                                        id="my-tooltip-28"
                                                                        place="top" className="overrideMe"
                                                                        variant="info"
                                                                        render={({ content, activeAnchor }) => (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <p style={{ fontWeight: "lighter" }}>
                                                                                            Wahi Tapu means a place sacred to Maori - any known sites should be included on the map.<br />Examples include burial grounds, battle sites or a place where tapu objects were placed.
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        {isOther &&
                                            <Row>
                                                {itemTypesList.map(type =>
                                                    <Col key={uuid()} sm="6" className="style-item-display">
                                                        <Row>
                                                            <Col sm="12">
                                                                <img
                                                                    className="featureIconNew"
                                                                    src={"/images/ItemIcons/" + type.icon}
                                                                    onClick={(event) => handleItemClick(event, type.id, type.markerType)}
                                                                /> <label onClick={(event) => handleItemClick(event, type.id, type.markerType)} className="itemTypeStyle"> {type.name}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    )
}
export default ItemTypeListView