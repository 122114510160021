import Records from '../ItemPopup/Records';
import Achievements from '../ItemPopup/Achievements';
import Photos from '../Uploads/Photos';
const ItemCommonBottom = ({ itemPopup, itemPopupAchievements, handleItemPopupAchievements, Section
    , showRecordsInfo, infoRecordsMessage, infoRecordsId, showAchievementsInfo, infoAchievementsMessage, infoAchievementsId
    , showPhotosInfo, infoPhotosMessage, infoPhotosId
}) => {
    return (
        <div>
            {Section != 'Community & Cultural Significance' &&
                <>
                <Records showRecordsInfo={showRecordsInfo} infoRecordsMessage={infoRecordsMessage} infoRecordsId={infoRecordsId}
                    Section={Section} refid={itemPopup.itemId}></Records> <br />
                <Achievements showAchievementsInfo={showAchievementsInfo} infoAchievementsMessage={infoAchievementsMessage} infoAchievementsId={infoAchievementsId}
                    Section={Section} itemPopupAchievements={itemPopupAchievements} handleItemPopupAchievements={handleItemPopupAchievements}></Achievements><br />
                <Photos showPhotosInfo={showPhotosInfo} infoPhotosMessage={infoPhotosMessage} infoPhotosId={infoPhotosId}
                    Section={Section} disabledMode={false} refId={itemPopup.itemId} header="Photo" uploadType="Item" ></Photos>
                </>
            }
            {Section == 'Community & Cultural Significance' &&
                <>
                    <Photos disabledMode={false} refId={itemPopup.itemId} header="Photo" uploadType="Item" ></Photos>
                </>
            }
        </div>
    )
}
export default ItemCommonBottom