import { useState, useEffect } from 'react';
import React from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Button, Modal, Nav, NavItem, NavLink, TabContent, TabPane, Input, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Collapse, Spinner } from 'reactstrap';
import { FaCalculator } from 'react-icons/fa';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from 'react-select';
import uuid from 'react-uuid';

const NitrogenBalanceCalc = ({ cardClass, headerClass1, headerClass2, seasonList, nutrientCalculatorDetail, nLossRiskAssessment, riskTypeList, annualRainfallList, setCalcValueChanged }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    if (cardClass === undefined) {
        cardClass = "my-4 card-header-outline";
    }
    if (headerClass1 === undefined) {
        headerClass1 = "";
    }
    if (headerClass2 === undefined) {
        headerClass2 = "card-header d-flex justify-content-between align-items-center";
    }
    const [saving, setSaving] = useState('');
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));

    const [modalACSRedVisible, setModalACSRedVisible] = useState(false);
    const [modalACSGreenVisible, setModalACSGreenVisible] = useState(false);
    const [modalACSGoldVisible, setModalACSGoldVisible] = useState(false);
    const [modalACSOtherVisible, setModalACSOtherVisible] = useState(false);

    const [modalBCSRedVisible, setModalBCSRedVisible] = useState(false);
    const [modalBCSGreenVisible, setModalBCSGreenVisible] = useState(false);
    const [modalBCSGoldVisible, setModalBCSGoldVisible] = useState(false);
    const [modalBCSOtherVisible, setModalBCSOtherVisible] = useState(false);

    const [modalCCSRedVisible, setModalCCSRedVisible] = useState(false);
    const [modalCCSGreenVisible, setModalCCSGreenVisible] = useState(false);
    const [modalCCSGoldVisible, setModalCCSGoldVisible] = useState(false);
    const [modalCCSOtherVisible, setModalCCSOtherVisible] = useState(false);

    const [modalDCSRedVisible, setModalDCSRedVisible] = useState(false);
    const [modalDCSGreenVisible, setModalDCSGreenVisible] = useState(false);
    const [modalDCSGoldVisible, setModalDCSGoldVisible] = useState(false);
    const [modalDCSOtherVisible, setModalDCSOtherVisible] = useState(false);

    

    const toggleACSRed = () => setModalACSRedVisible(!modalACSRedVisible);
    const toggleACSGreen = () => setModalACSGreenVisible(!modalACSGreenVisible);
    const toggleACSGold = () => setModalACSGoldVisible(!modalACSGoldVisible);
    const toggleACSOther = () => setModalACSOtherVisible(!modalACSOtherVisible);

    const toggleBCSRed = () => setModalBCSRedVisible(!modalBCSRedVisible);
    const toggleBCSGreen = () => setModalBCSGreenVisible(!modalBCSGreenVisible);
    const toggleBCSGold = () => setModalBCSGoldVisible(!modalBCSGoldVisible);
    const toggleBCSOther = () => setModalBCSOtherVisible(!modalBCSOtherVisible);

    const toggleCCSRed = () => setModalCCSRedVisible(!modalCCSRedVisible);
    const toggleCCSGreen = () => setModalCCSGreenVisible(!modalCCSGreenVisible);
    const toggleCCSGold = () => setModalCCSGoldVisible(!modalCCSGoldVisible);
    const toggleCCSOther = () => setModalCCSOtherVisible(!modalCCSOtherVisible);

    const toggleDCSRed = () => setModalDCSRedVisible(!modalDCSRedVisible);
    const toggleDCSGreen = () => setModalDCSGreenVisible(!modalDCSGreenVisible);
    const toggleDCSGold = () => setModalDCSGoldVisible(!modalDCSGoldVisible);
    const toggleDCSOther = () => setModalDCSOtherVisible(!modalDCSOtherVisible);
    

    const [inputTotal, setInputTotal] = useState(0);
    const [goldInputTotal, setgoldInputTotal] = useState(0);
    const [greenInputTotal, setgreenInputTotal] = useState(0);
    const [redInputTotal, setredInputTotal] = useState(0);
    const [otherInputTotal, setotherInputTotal] = useState(0);
    const [fertilizerTotal, setFertilizerTotal] = useState(0);
    const [goldfertilizerTotal, setgoldfertilizerTotal] = useState(0);
    const [greenfertilizerTotal, setgreenfertilizerTotal] = useState(0);
    const [redfertilizerTotal, setredfertilizerTotal] = useState(0);
    const [otherfertilizerTotal, setotherfertilizerTotal] = useState(0);
    const [compostTotal, setCompostTotal] = useState(0);
    const [goldcompostTotal, setgoldcompostTotal] = useState(0);
    const [greencompostTotal, setgreencompostTotal] = useState(0);
    const [redcompostTotal, setredcompostTotal] = useState(0);
    const [othercompostTotal, setothercompostTotal] = useState(0);
    const [groundFertTotal, setGroundFertTotal] = useState(0);
    const [goldgroundFertTotal, setgoldgroundFertTotal] = useState(0);
    const [greengroundFertTotal, setgreengroundFertTotal] = useState(0);
    const [redgroundFertTotal, setredgroundFertTotal] = useState(0);
    const [othergroundFertTotal, setothergroundFertTotal] = useState(0);
    const [fertObj, setFertObj] = useState(nutrientCalculatorDetail.foliarFertilizerData);// useState(fertilizerArr);
    const [groundFertObj, setGroundFertObj] = useState(nutrientCalculatorDetail.groundFertilizerData);//useState(groundFertilizer);
    const [compostObj, setCompostObj] = useState(nutrientCalculatorDetail.compostData);//useState(compostArr);
    const [removalTotal, setRemovalTotal] = useState(0);
    const [goldRemovalTotal, setgoldRemovalTotal] = useState(0);
    const [greenRemovalTotal, setgreenRemovalTotal] = useState(0);
    const [redRemovalTotal, setredRemovalTotal] = useState(0);
    const [otherRemovalTotal, setotherRemovalTotal] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [nLossRiskAssessmentObj, setnLossRiskAssessmentObj] = useState(nLossRiskAssessment);
    const [nitrogenDetails, setNitrogenDetails] = useState({
        gold: {
            Id: nutrientCalculatorDetail.nutrientCalculatorData.gold.id, fruit: nutrientCalculatorDetail.nutrientCalculatorData.gold.fruit,
            groundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.gold.groundFertilizer, compost: nutrientCalculatorDetail.nutrientCalculatorData.gold.compost,
            foliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.gold.foliarFertilizer, budBreak: nutrientCalculatorDetail.nutrientCalculatorData.gold.budBreak,
            season: nutrientCalculatorDetail.nutrientCalculatorData.gold.season, totalNAdded: 0, totalNRemoved: 0,
            totalHectaresAppliedGroundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedGroundFertilizer,
            totalHectaresAppliedCompost: nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedCompost,
            totalHectaresAppliedFoliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedFoliarFertilizer,
            fruitPopupChanged: false, groundFertiliserPopupChanged: false, compostPopupChanged: false, foliarFertiliserPopupChanged:false
        },
        green: {
            Id: nutrientCalculatorDetail.nutrientCalculatorData.green.id, fruit: nutrientCalculatorDetail.nutrientCalculatorData.green.fruit,
            groundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.green.groundFertilizer, compost: nutrientCalculatorDetail.nutrientCalculatorData.green.compost,
            foliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.green.foliarFertilizer, budBreak: nutrientCalculatorDetail.nutrientCalculatorData.green.budBreak,
            season: nutrientCalculatorDetail.nutrientCalculatorData.green.season, totalNAdded: 0, totalNRemoved: 0,
            totalHectaresAppliedGroundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedGroundFertilizer,
            totalHectaresAppliedCompost: nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedCompost,
            totalHectaresAppliedFoliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedFoliarFertilizer,
            fruitPopupChanged: false, groundFertiliserPopupChanged: false, compostPopupChanged: false, foliarFertiliserPopupChanged: false
        },
        red: {
            Id: nutrientCalculatorDetail.nutrientCalculatorData.red.id, fruit: nutrientCalculatorDetail.nutrientCalculatorData.red.fruit,
            groundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.red.groundFertilizer, compost: nutrientCalculatorDetail.nutrientCalculatorData.red.compost,
            foliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.red.foliarFertilizer, budBreak: nutrientCalculatorDetail.nutrientCalculatorData.red.budBreak,
            season: nutrientCalculatorDetail.nutrientCalculatorData.red.season, totalNAdded: 0, totalNRemoved: 0,
            totalHectaresAppliedGroundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedGroundFertilizer,
            totalHectaresAppliedCompost: nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedCompost,
            totalHectaresAppliedFoliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedFoliarFertilizer,
            fruitPopupChanged: false, groundFertiliserPopupChanged: false, compostPopupChanged: false, foliarFertiliserPopupChanged: false
        },
        other: {
            Id: nutrientCalculatorDetail.nutrientCalculatorData.other.id, fruit: nutrientCalculatorDetail.nutrientCalculatorData.other.fruit,
            groundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.other.groundFertilizer, compost: nutrientCalculatorDetail.nutrientCalculatorData.other.compost,
            foliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.other.foliarFertilizer, budBreak: nutrientCalculatorDetail.nutrientCalculatorData.other.budBreak,
            season: nutrientCalculatorDetail.nutrientCalculatorData.other.season, totalNAdded: 0, totalNRemoved: 0,
            totalHectaresAppliedGroundFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedGroundFertilizer,
            totalHectaresAppliedCompost: nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedCompost,
            totalHectaresAppliedFoliarFertilizer: nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedFoliarFertilizer,
            fruitPopupChanged: false, groundFertiliserPopupChanged: false, compostPopupChanged: false, foliarFertiliserPopupChanged: false
        }
    });

    const [removalDetails, setRemovalDetails] = useState({
        gold: { rcvdByPackhouse: 0, produced: 0, totalNRemoved: 0 },
        green: { rcvdByPackhouse: 0, produced: 0, totalNRemoved: 0 },
        red: { rcvdByPackhouse: 0, produced: 0, totalNRemoved: 0 },
        other: { rcvdByPackhouse: 0, produced: 0, totalNRemoved: 0 }
    })

    const [calculatorPopupChanged, setCalculatorPopupChanged] = useState({ showPopup: false, season: '', variable: '',value:0 });

    const handlecalculatorPopupChangedClose = () => {
        setCalculatorPopupChanged({
            showPopup: false, season: '', variable: '', value: 0 });
    }

    const handlecalculatorPopupChangedConfirm = () => {
        const regex = /^[0-9\b]+$/;
        let tempSeasonData = nitrogenDetails;
        let seasonData = {};
        let str = '';
        str = calculatorPopupChanged.variable;
        let objStr = '';
        objStr = calculatorPopupChanged.season;
        seasonData = tempSeasonData[objStr];
        seasonData[str] = calculatorPopupChanged.value;
        if (calculatorPopupChanged.variable == 'fruit') {
            seasonData['fruitPopupChanged'] = false;
            var nutrientCalculatorFruitData = nutrientCalculatorDetail.fruitData[objStr];
            nutrientCalculatorFruitData.rcvdByPackhouse = 0;
            nutrientCalculatorFruitData.produced = 0;
            nutrientCalculatorFruitData.totalNRemoved = 0;
        }
        else if (calculatorPopupChanged.variable == 'groundFertilizer') {
            seasonData['groundFertiliserPopupChanged'] = false;
            var nutrientCalculatorGroundFertiliserData = nutrientCalculatorDetail.groundFertilizerData[objStr];
            nutrientCalculatorGroundFertiliserData.forEach((obj) => {
                obj.totalNApplied = 0;
                obj.totalNInProduct = 0;
                obj.totalProduct = 0;
            })
            if (calculatorPopupChanged.season == 'gold') {
                setgoldgroundFertTotal(0);
            }
            else if (calculatorPopupChanged.season == 'green') {
                setgreengroundFertTotal(0);
            }
            else if (calculatorPopupChanged.season == 'red') {
                setredgroundFertTotal(0);
            }
            else if (calculatorPopupChanged.season == 'other') {
                setothergroundFertTotal(0);
            }
        }
        else if (calculatorPopupChanged.variable == 'compost') {
            seasonData['compostPopupChanged'] = false;
            var nutrientCalculatorCompostData = nutrientCalculatorDetail.compostData[objStr];
            nutrientCalculatorCompostData.forEach((obj) => {
                obj.totalTonnesPerHec = 0;
            })
            if (calculatorPopupChanged.season == 'gold') {
                setgoldcompostTotal(0);
            }
            else if (calculatorPopupChanged.season == 'green') {
                setgreencompostTotal(0);
            }
            else if (calculatorPopupChanged.season == 'red') {
                setredcompostTotal(0);
            }
            else if (calculatorPopupChanged.season == 'other') {
                setothercompostTotal(0);
            }
        }
        else if (calculatorPopupChanged.variable == 'foliarFertilizer') {
            seasonData['foliarFertiliserPopupChanged'] = false;
            var nutrientCalculatorFoliarFertiliserData = nutrientCalculatorDetail.foliarFertilizerData[objStr];
            nutrientCalculatorFoliarFertiliserData.forEach((obj) => {
                obj.totalProduct = 0;
            })
            if (calculatorPopupChanged.season == 'gold') {
                setgoldfertilizerTotal(0);
            }
            else if (calculatorPopupChanged.season == 'green') {
                setgreenfertilizerTotal(0);
            }
            else if (calculatorPopupChanged.season == 'red') {
                setredfertilizerTotal(0);
            }
            else if (calculatorPopupChanged.season == 'other') {
                setotherfertilizerTotal(0);
            }
        }
        setNitrogenDetails({
            ...nitrogenDetails,
            [objStr]: seasonData
        });
        calculateTotal(seasonData, calculatorPopupChanged.season);
        setCalculatorPopupChanged({
            showPopup: false, season: '', variable: '', value: 0
        });
    }

    useEffect(() => {
        setRemovalDetails({
            ...removalDetails, gold: nutrientCalculatorDetail.fruitData.gold, green: nutrientCalculatorDetail.fruitData.green
            , red: nutrientCalculatorDetail.fruitData.red, other: nutrientCalculatorDetail.fruitData.other
        })

        let num1 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.gold.groundFertilizer);
        num1 = num1 || 0;
        let num2 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.gold.compost);
        num2 = num2 || 0;
        let num3 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.gold.foliarFertilizer);
        num3 = num3 || 0;
        let num4 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.gold.budBreak);
        num4 = num4 || 0;
        let inputResult = num1 + num2 + num3 + num4;
        inputResult = Math.round(inputResult);
        setgoldInputTotal(inputResult)
        setgoldRemovalTotal(parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.gold.fruit).toFixed(4));

        num1 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.green.groundFertilizer);
        num1 = num1 || 0;
        num2 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.green.compost);
        num2 = num2 || 0;
        num3 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.green.foliarFertilizer);
        num3 = num3 || 0;
        num4 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.green.budBreak);
        num4 = num4 || 0;
        inputResult = num1 + num2 + num3 + num4;
        inputResult = Math.round(inputResult);
        setgreenInputTotal(inputResult)
        setgreenRemovalTotal(parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.green.fruit).toFixed(4));

        num1 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.red.groundFertilizer);
        num1 = num1 || 0;
        num2 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.red.compost);
        num2 = num2 || 0;
        num3 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.red.foliarFertilizer);
        num3 = num3 || 0;
        num4 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.red.budBreak);
        num4 = num4 || 0;
        inputResult = num1 + num2 + num3 + num4;
        setredInputTotal(inputResult)
        inputResult = Math.round(inputResult);
        setredRemovalTotal(parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.red.fruit).toFixed(4));

        num1 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.other.groundFertilizer);
        num1 = num1 || 0;
        num2 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.other.compost);
        num2 = num2 || 0;
        num3 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.other.foliarFertilizer);
        num3 = num3 || 0;
        num4 = parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.other.budBreak);
        num4 = num4 || 0;
        inputResult = num1 + num2 + num3 + num4;
        setotherInputTotal(inputResult)
        inputResult = Math.round(inputResult);
        setotherRemovalTotal(parseFloat(nutrientCalculatorDetail.nutrientCalculatorData.other.fruit).toFixed(4));

        if (nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedGroundFertilizer != 0) {
            setgoldgroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.gold.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedGroundFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.groundFertilizerData.gold.map((item, idx) => {
                total += item.totalNApplied;
            });
            setgoldgroundFertTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedGroundFertilizer != 0) {
            setgreengroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.green.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedGroundFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.groundFertilizerData.green.map((item, idx) => {
                total += item.totalNApplied;
            });
            setgreengroundFertTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedGroundFertilizer != 0) {
            setredgroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.red.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedGroundFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.groundFertilizerData.red.map((item, idx) => {
                total += item.totalNApplied;
            });
            setredgroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.red.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedGroundFertilizer);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedGroundFertilizer != 0) {
            setothergroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.other.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedGroundFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.groundFertilizerData.other.map((item, idx) => {
                total += item.totalNApplied;
            });
            setothergroundFertTotal(nutrientCalculatorDetail.nutrientCalculatorData.other.groundFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedGroundFertilizer);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedCompost != 0) {
            setgoldcompostTotal(nutrientCalculatorDetail.nutrientCalculatorData.gold.compost * nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedCompost);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.compostData.gold.map((item, idx) => {
                total += item.totalTonnesPerHec;
            });
            setgoldcompostTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedCompost != 0) {
            setgreencompostTotal(nutrientCalculatorDetail.nutrientCalculatorData.green.compost * nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedCompost);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.compostData.green.map((item, idx) => {
                total += item.totalTonnesPerHec;
            });
            setgreencompostTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedCompost != 0) {
            setredcompostTotal(nutrientCalculatorDetail.nutrientCalculatorData.red.compost * nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedCompost);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.compostData.red.map((item, idx) => {
                total += item.totalTonnesPerHec;
            });
            setredcompostTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedCompost != 0) {
            setothercompostTotal(nutrientCalculatorDetail.nutrientCalculatorData.other.compost * nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedCompost);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.compostData.other.map((item, idx) => {
                total += item.totalTonnesPerHec;
            });
            setothercompostTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedFoliarFertilizer != 0) {
            setgoldfertilizerTotal(nutrientCalculatorDetail.nutrientCalculatorData.gold.foliarFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.gold.totalHectaresAppliedFoliarFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.foliarFertilizerData.gold.map((item, idx) => {
                total += item.totalProduct;
            });
            setgoldfertilizerTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedFoliarFertilizer != 0) {
            setgreenfertilizerTotal(nutrientCalculatorDetail.nutrientCalculatorData.green.foliarFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.green.totalHectaresAppliedFoliarFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.foliarFertilizerData.green.map((item, idx) => {
                total += item.totalProduct;
            });
            setgreenfertilizerTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedFoliarFertilizer != 0) {
            setredfertilizerTotal(nutrientCalculatorDetail.nutrientCalculatorData.red.foliarFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.red.totalHectaresAppliedFoliarFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.foliarFertilizerData.red.map((item, idx) => {
                total += item.totalProduct;
            });
            setredfertilizerTotal(total);
        }
        if (nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedFoliarFertilizer != 0) {
            setotherfertilizerTotal(nutrientCalculatorDetail.nutrientCalculatorData.other.foliarFertilizer * nutrientCalculatorDetail.nutrientCalculatorData.other.totalHectaresAppliedFoliarFertilizer);
        }
        else {
            var total = 0;
            nutrientCalculatorDetail.foliarFertilizerData.other.map((item, idx) => {
                total += item.totalProduct;
            });
            setotherfertilizerTotal(total);
        }
    }, []);

    const handleInput = async (e, object, variable) => {
        setCalcValueChanged(true);
        const regex = /^[0-9\b]+$/;

        let tempSeasonData = nitrogenDetails;
        let seasonData = {};
        if (variable == 'fruit') {
            if (tempSeasonData[object].fruitPopupChanged) {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable, value: e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value)
                    });
                }
                else {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable
                    });
                }
            }
            else {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    let str = '';
                    str = variable
                    let objStr = '';
                    objStr = object;
                    seasonData = tempSeasonData[objStr];
                    seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value);

                    setNitrogenDetails({
                        ...nitrogenDetails,
                        [objStr]: seasonData
                    });
                    calculateTotal(seasonData, object)
                }
            }
        }
        else if (variable == 'groundFertilizer') {
            if (tempSeasonData[object].groundFertiliserPopupChanged) {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable, value: e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value)
                    });
                }
                else {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable
                    });
                }
            }
            else {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    let str = '';
                    str = variable
                    let objStr = '';
                    objStr = object;
                    seasonData = tempSeasonData[objStr];
                    seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value);

                    setNitrogenDetails({
                        ...nitrogenDetails,
                        [objStr]: seasonData
                    });
                    calculateTotal(seasonData, object)
                }
            }
        }
        else if (variable == 'compost') {
            if (tempSeasonData[object].compostPopupChanged) {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable, value: e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value)
                    });
                }
                else {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable
                    });
                }
            }
            else {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    let str = '';
                    str = variable
                    let objStr = '';
                    objStr = object;
                    seasonData = tempSeasonData[objStr];
                    seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value);

                    setNitrogenDetails({
                        ...nitrogenDetails,
                        [objStr]: seasonData
                    });
                    calculateTotal(seasonData, object)
                }
            }
        }
        else if (variable == 'foliarFertilizer') {
            if (tempSeasonData[object].foliarFertiliserPopupChanged) {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable, value: e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value)
                    });
                }
                else {
                    setCalculatorPopupChanged({
                        showPopup: true, season: object, variable: variable
                    });
                }
            }
            else {
                if (e.target.value === "" || regex.test(e.target.value)) {
                    let str = '';
                    str = variable
                    let objStr = '';
                    objStr = object;
                    seasonData = tempSeasonData[objStr];
                    seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value);

                    setNitrogenDetails({
                        ...nitrogenDetails,
                        [objStr]: seasonData
                    });
                    calculateTotal(seasonData, object)
                }
            }
        }
        else if (variable == 'budBreak') {
            if (e.target.value === "" || regex.test(e.target.value)) {
                let str = '';
                str = variable
                let objStr = '';
                objStr = object;
                seasonData = tempSeasonData[objStr];
                seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : parseInt(e.target.value);

                setNitrogenDetails({
                    ...nitrogenDetails,
                    [objStr]: seasonData
                });
                calculateTotal(seasonData, object)
            }
        }
    };
    const handleTotalHectaresApplied = (e, object, variable) => {
        setCalcValueChanged(true);
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        let seasonData = {};
        seasonData = nitrogenDetails[objStr];
        const regex = new RegExp(/^\d*\.?\d*$/);
        if (regex.test(e.target.value)) {
            seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : e.target.value;
            setNitrogenDetails({
                ...nitrogenDetails,
                [objStr]: seasonData
            })
        }
    }
    const handleGroundFertilizer = (e, index, object, variable) => {
        setCalcValueChanged(true);
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        let seasonData = {};
        seasonData = groundFertObj[objStr];
        const regex = new RegExp(/^\d*\.?\d*$/);
        if (regex.test(e.target.value)) {
            seasonData[index][str] = e.target.value == null || e.target.value == '' ? 0 : e.target.value;
            const result = (seasonData[index]["totalProduct"] / 100) * (seasonData[index]["totalNInProduct"]) * 1000;
            seasonData[index]["totalNApplied"] = Math.round(result);
            let sum = seasonData.reduce(function (pv, cv) {
                return pv + parseFloat(cv.totalNApplied)
            }, 0)
            sum = Math.round(sum);
            if (object == "gold") {
                setgoldgroundFertTotal(sum);
            }
            else if (object == "green") {
                setgreengroundFertTotal(sum);
            }
            else if (object == "red") {
                setredgroundFertTotal(sum);
            }
            else if (object == "other") {
                setothergroundFertTotal(sum);
            }
            setGroundFertObj({
                ...groundFertObj,
                [objStr]: seasonData
            })
        }
    };
    const handleGroundFertilizerProduct = (e, index, object, variable) => {
        setCalcValueChanged(true);
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        let seasonData = {};
        seasonData = groundFertObj[objStr];
        seasonData[index][str] = e.target.value == null || e.target.value == '' ? '' : e.target.value;
        setGroundFertObj({
            ...groundFertObj,
            [objStr]: seasonData
        })

    };
    const handleCompostObj = (e, index, object, variable) => {
        setCalcValueChanged(true);
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        let seasonData = {};
        seasonData = compostObj[objStr];
        const regex = new RegExp(/^\d*\.?\d*$/);
        if (regex.test(e.target.value)) {
            seasonData[index][str] = e.target.value == null || e.target.value == '' ? 0 : e.target.value;
            const result = seasonData[index]["totalTonnesPerHec"] * (seasonData[index]["dryWeight"] / 100) * (seasonData[index]["known_N"] / 100) * 1000
            seasonData[index]["applied_N"] = Math.round(result);
            const avgN = result * (0.25 / seasonData[index]["noOfYears"])
            seasonData[index]["avgN"] = Math.round(avgN);
            let sum = seasonData.reduce(function (pv, cv) {
                return pv + parseFloat(cv.avgN)
            }, 0)
            sum = Math.round(sum);
            if (object == "gold") {
                setgoldcompostTotal(sum);
            }
            else if (object == "green") {
                setgreencompostTotal(sum);
            }
            else if (object == "red") {
                setredcompostTotal(sum);
            }
            else if (object == "other") {
                setothercompostTotal(sum);
            }
            setCompostObj({
                ...compostObj,
                [objStr]: seasonData
            });
        }

    };
    const handlefertilizerObj = (e, index, object, variable) => {
        setCalcValueChanged(true);
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        let seasonData = {};
        seasonData = fertObj[objStr];
        const regex = new RegExp(/^\d*\.?\d*$/);
        if (regex.test(e.target.value)) {
            seasonData[index][str] = e.target.value == null || e.target.value == '' ? 0 : e.target.value;
            const result = seasonData[index]["totalProduct"] * (seasonData[index]["nitrogenInProduct"] / 100);
            seasonData[index]["totalN"] = Math.round(result);
            let sum = seasonData.reduce(function (pv, cv) {
                return pv + parseFloat(cv.totalN)
            }, 0)
            sum = Math.round(sum);
            if (object == "gold") {
                setgoldfertilizerTotal(sum);
            }
            else if (object == "green") {
                setgreenfertilizerTotal(sum);
            }
            else if (object == "red") {
                setredfertilizerTotal(sum);
            }
            else if (object == "other") {
                setotherfertilizerTotal(sum);
            }
            setFertObj({
                ...fertObj,
                [objStr]: seasonData
            });
        }
    };

    const handleNLossRiskAssessmentChange = async (e, object, variable) => {
        setCalcValueChanged(true);
        let tempSeasonData = nLossRiskAssessmentObj;
        let str = '';
        str = variable
        let seasonData = {};
        let objStr = '';
        objStr = object;
        seasonData = tempSeasonData[objStr];
        seasonData[str] = e.target.value;
        var soilTypeRiskPoint = seasonData["soilTypeRiskPoint"];
        var annualRainfallRiskPoint = seasonData["annualRainfallRiskPoint"];
        var nSurplusRiskPoint = seasonData["nSurplusRiskPoint"];
        if (str == 'soilTypeRisk') {
            if (e.target.value == "Low") {
                soilTypeRiskPoint = 1;
            }
            else if (e.target.value == "Medium") {
                soilTypeRiskPoint = 2;
            }
            else if (e.target.value == "High") {
                soilTypeRiskPoint = 3;
            }
            seasonData["soilTypeRiskPoint"] = soilTypeRiskPoint;
        }
        if (str == 'annualRainfall') {
            if (e.target.value == '<1000') {
                seasonData["annualRainfallRisk"] = 'Low';
                annualRainfallRiskPoint = 1;
            }
            else if (e.target.value == '1000-1500') {
                seasonData["annualRainfallRisk"] = 'Medium';
                annualRainfallRiskPoint = 2;
            }
            else if (e.target.value == '>1500') {
                seasonData["annualRainfallRisk"] = 'High';
                annualRainfallRiskPoint = 3;
            }
            seasonData["annualRainfallRiskPoint"] = annualRainfallRiskPoint;
        }
        var totalPoints = soilTypeRiskPoint + annualRainfallRiskPoint + nSurplusRiskPoint;
        var overallRisk = "";
        if (totalPoints <= 3) {
            overallRisk = "Low";
        }
        else if (totalPoints > 3 && totalPoints <= 6) {
            overallRisk = "Medium";
        }
        else if (totalPoints >= 7) {
            overallRisk = "High";
        }
        seasonData["overallRisk"] = overallRisk;
        setnLossRiskAssessmentObj({
            ...nLossRiskAssessmentObj,
            [objStr]: seasonData
        });
    }


    const handleRemovalPopupInput = async (e, object, variable) => {
        setCalcValueChanged(true);
        const regex = new RegExp(/^\d*\.?\d*$/);

        let tempSeasonData = removalDetails;
        let seasonData = {}
        if (regex.test(e.target.value)) {
            let str = '';
            str = variable
            let objStr = '';
            objStr = object;
            seasonData = tempSeasonData[objStr];
            seasonData[str] = e.target.value == null || e.target.value == '' ? 0 : e.target.value;
            const num1 = parseFloat(seasonData.rcvdByPackhouse);
            const num2 = parseFloat(seasonData.produced);
            let result = num2 == 0 ? 0 : (num1 / num2) * 0.0016;
            result = parseFloat(result).toFixed(4);
            seasonData["totalNRemoved"] = result;
            setRemovalDetails({
                ...removalDetails,
                [objStr]: seasonData
            });
        }
    };
    const calculateTotal = (seasonData,season) => {
        setCalcValueChanged(true);
        
        let num1 = parseFloat(seasonData.groundFertilizer);
        num1 = num1 || 0;
        let num2 = parseFloat(seasonData.compost);
        num2 = num2 || 0;
        let num3 = parseFloat(seasonData.foliarFertilizer);
        num3 = num3 || 0;
        let num4 = parseFloat(seasonData.budBreak);
        num4 = num4 || 0;
        let inputResult = num1 + num2 + num3 + num4;
        inputResult = Math.round(inputResult);
        var surplus = inputResult - seasonData.fruit;
        var nSurplusRisk = "";
        var nSurplusRiskPoint = 0;
        var nSurplusRiskScore = "";
        if (surplus < 35) {
            nSurplusRisk = "Low";
            nSurplusRiskPoint = 1;
            nSurplusRiskScore = "<35";
        }
        else if (surplus >= 35 && surplus <= 70) {
            nSurplusRisk = "Medium";
            nSurplusRiskPoint = 2;
            nSurplusRiskScore = "35-70";
        }
        else if (surplus > 70) {
            nSurplusRisk = "High";
            nSurplusRiskPoint = 3;
            nSurplusRiskScore = ">70";
        }

        let tempSeasonData = nLossRiskAssessmentObj;
        let seasonData1 = {};
        let objStr = '';
        objStr = season;
        seasonData1 = tempSeasonData[objStr];
        var soilTypeRiskPoint = seasonData1["soilTypeRiskPoint"];
        var annualRainfallRiskPoint = seasonData1["annualRainfallRiskPoint"];
        var totalPoints = soilTypeRiskPoint + annualRainfallRiskPoint + nSurplusRiskPoint;
        var overallRisk = "";
        if (totalPoints <= 3) {
            overallRisk = "Low";
        }
        else if (totalPoints > 3 && totalPoints <= 6) {
            overallRisk = "Medium";
        }
        else if (totalPoints >= 7) {
            overallRisk = "High";
        }
        seasonData1["nSurplusRisk"] = nSurplusRisk;
        seasonData1["nSurplusRiskPoint"] = nSurplusRiskPoint;
        seasonData1["nSurplusRiskScore"] = nSurplusRiskScore;
        seasonData1["overallRisk"] = overallRisk;
        setnLossRiskAssessmentObj({
            ...nLossRiskAssessmentObj,
            [objStr]: seasonData1
        });

        if (season == 'gold') {
            setgoldInputTotal(inputResult)
            setgoldRemovalTotal(seasonData.fruit);
        }
        else if (season == 'green') {
            setgreenInputTotal(inputResult)
            setgreenRemovalTotal(seasonData.fruit);
        }
        else if (season == 'red') {
            setredInputTotal(inputResult)
            setredRemovalTotal(seasonData.fruit);
        }
        else if (season == 'other') {
            setotherInputTotal(inputResult)
            setotherRemovalTotal(seasonData.fruit);
        }
    };



    const openModalA = (e, object) => {
        e.preventDefault()
        if (object == "red") {
            setModalACSRedVisible(true);
        }
        else if (object == "green") {
            setModalACSGreenVisible(true);
        }
        else if (object == "gold") {
            setModalACSGoldVisible(true)
        }
        else if (object == "other") {
            setModalACSOtherVisible(true)
        }
    };
    const openModalB = (e, object) => {
        e.preventDefault()
        if (object == "red") {
            setModalBCSRedVisible(true);
        }
        else if (object == "green") {
            setModalBCSGreenVisible(true)
        }
        else if (object == "gold") {
            setModalBCSGoldVisible(true)
        }
        else if (object == "other") {
            setModalBCSOtherVisible(true)
        }
    };
    const openModalC = (e, object) => {
        e.preventDefault()
        if (object == "red") {
            setModalCCSRedVisible(true);
        }
        else if (object == "green") {
            setModalCCSGreenVisible(true)
        }
        else if (object == "gold") {
            setModalCCSGoldVisible(true)
        }
        else if (object == "other") {
            setModalCCSOtherVisible(true)
        }
    };
    const openModalD = (e, object) => {
        e.preventDefault()
        if (object == "red") {
            setModalDCSRedVisible(true);
        }
        else if (object == "green") {
            setModalDCSGreenVisible(true);
        }
        else if (object == "gold") {
            setModalDCSGoldVisible(true);
        }
        else if (object == "other") {
            setModalDCSOtherVisible(true);
        }
    };

    const handleSubmit = async (e) => {
        setSaving(true);
        let gold = {};
        let green = {};
        let red = {};
        let other = {};
        let goldNLoss = nLossRiskAssessmentObj.gold;
        let greenNLoss = nLossRiskAssessmentObj.green;
        let redNLoss = nLossRiskAssessmentObj.red;
        let otherNLoss = nLossRiskAssessmentObj.other;
        gold = nitrogenDetails.gold;
        green = nitrogenDetails.green;
        red = nitrogenDetails.red;
        other = nitrogenDetails.other;
        let obj = {};
        obj.RcvdByPackhouse = removalDetails.gold.rcvdByPackhouse;
        obj.Produced = removalDetails.gold.produced;
        obj.TotalNRemoved = removalDetails.gold.totalNRemoved;
        obj.Id = removalDetails.gold.id;
        gold["FruitData"] = obj;
        gold["GroundFertilizerData"] = groundFertObj.gold;
        gold["CompostData"] = compostObj.gold;
        gold["FoliarFertilizerdata"] = fertObj.gold;
        obj = {};
        obj.RcvdByPackhouse = removalDetails.green.rcvdByPackhouse;
        obj.Produced = removalDetails.green.produced;
        obj.TotalNRemoved = removalDetails.green.totalNRemoved;
        obj.Id = removalDetails.green.id;
        green["FruitData"] = obj;
        green["GroundFertilizerData"] = groundFertObj.green;
        green["CompostData"] = compostObj.green;
        green["FoliarFertilizerdata"] = fertObj.green;
        obj = {};
        obj.RcvdByPackhouse = removalDetails.red.rcvdByPackhouse;
        obj.Produced = removalDetails.red.produced;
        obj.TotalNRemoved = removalDetails.red.totalNRemoved;
        obj.Id = removalDetails.red.id;
        red["FruitData"] = obj;
        red["GroundFertilizerData"] = groundFertObj.red;
        red["CompostData"] = compostObj.red;
        red["FoliarFertilizerdata"] = fertObj.red;
        obj = {};
        obj.RcvdByPackhouse = removalDetails.other.rcvdByPackhouse;
        obj.Produced = removalDetails.other.produced;
        obj.TotalNRemoved = removalDetails.other.totalNRemoved;
        obj.Id = removalDetails.other.id;
        other["FruitData"] = obj;
        other["GroundFertilizerData"] = groundFertObj.other;
        other["CompostData"] = compostObj.other;
        other["FoliarFertilizerdata"] = fertObj.other;
        e.preventDefault();
        let form = {
            FarmId: parseInt(farmId),
            Id: '0',
            UserId:userId,
            gold: gold,
            green: green,
            red: red,
            other: other,
            goldNLoss: goldNLoss,
            greenNLoss: greenNLoss,
            redNLoss: redNLoss,
            otherNLoss: otherNLoss,
        }
        try {
            const response = await axios.post(
                '/api/nutrients/SaveNitrogenDetails/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data.status) {
                window.location.reload(true);
            }
            else {
                toast.error("Error while saving records!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }
    const handleSubmitA = async (e, object, variable) => {

        let tempSeasonData = nitrogenDetails;
        let seasonData = {}
        let str = '';
        str = variable
        let objStr = '';
        objStr = object;
        seasonData = tempSeasonData[objStr];
        if (variable == 'groundFertilizer') {
            if (seasonData["totalHectaresAppliedGroundFertilizer"] == null || seasonData["totalHectaresAppliedGroundFertilizer"] == '' || seasonData["totalHectaresAppliedGroundFertilizer"] == undefined || seasonData["totalHectaresAppliedGroundFertilizer"] == '') {
                toast.error("Enter Total hectares applied!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                document.getElementById(object + 'totalHectaresAppliedGroundFertilizer').style.border = '1px solid red';
                return false;
            }
            else {
                document.getElementById(object + 'totalHectaresAppliedGroundFertilizer').style.border = '1px solid #ced4da';
            }
        }
        else if (variable == 'compost') {
            if (seasonData["totalHectaresAppliedCompost"] == null || seasonData["totalHectaresAppliedCompost"] == '' || seasonData["totalHectaresAppliedCompost"] == undefined || seasonData["totalHectaresAppliedCompost"] == '') {
                toast.error("Enter Total hectares applied!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                document.getElementById(object + 'totalHectaresAppliedCompost').style.border = '1px solid red';
                return false;
            }
            else {
                document.getElementById(object + 'totalHectaresAppliedCompost').style.border = '1px solid #ced4da';
            }
        }
        else if (variable == 'foliarFertilizer') {
            if (seasonData["totalHectaresAppliedFoliarFertilizer"] == null || seasonData["totalHectaresAppliedFoliarFertilizer"] == '' || seasonData["totalHectaresAppliedFoliarFertilizer"] == undefined || seasonData["totalHectaresAppliedFoliarFertilizer"] == '') {
                toast.error("Enter Total hectares applied!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                document.getElementById(object + 'totalHectaresAppliedFoliarFertilizer').style.border = '1px solid red';
                return false;
            }
            else {
                document.getElementById(object + 'totalHectaresAppliedFoliarFertilizer').style.border = '1px solid #ced4da';
            }
        }
        
        let data = {};
        if (str == "fruit") {
            data = removalDetails[objStr]
            seasonData[str] = data.totalNRemoved
            seasonData['fruitPopupChanged'] = true;
        }
        else if (str == "groundFertilizer") {
            data = groundFertObj[objStr]
            let sum = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].totalNApplied != null) {
                    sum += parseFloat(data[i].totalNApplied);
                }
            }
            seasonData[str] = sum / seasonData['totalHectaresAppliedGroundFertilizer'];
            seasonData[str] = parseFloat(seasonData[str]).toFixed(2);
            seasonData['groundFertiliserPopupChanged'] = true;
        }
        else if (str == "compost") {

            data = compostObj[objStr]
            const sum = data.reduce(function (pv, cv) {
                return pv + parseFloat(cv.avgN)
            }, 0)
            seasonData[str] = sum / seasonData['totalHectaresAppliedCompost'];
            seasonData[str] = parseFloat(seasonData[str]).toFixed(2);
            seasonData['compostPopupChanged'] = true;
        }
        else {
            data = fertObj[objStr]
            const sum = data.reduce(function (pv, cv) {
                return pv + parseFloat(cv.totalN)
            }, 0)
            seasonData[str] = sum / seasonData['totalHectaresAppliedFoliarFertilizer'];
            seasonData[str] = parseFloat(seasonData[str]).toFixed(2);
            seasonData['foliarFertiliserPopupChanged'] = true;
        }
        setNitrogenDetails({
            ...nitrogenDetails,
            [objStr]: seasonData
        });
        calculateTotal(seasonData, object)
        if (object == "red") {
            if (variable == "fruit") {
                toggleACSRed();
            }
            else if (variable == "groundFertilizer") {
                toggleBCSRed();
            }
            else if (variable == "compost") {
                toggleCCSRed();
            }
            else {
                toggleDCSRed();
            }
        }
        else if(object == "green") {

            if (variable == "fruit") {
                toggleACSGreen();
            }
            else if (variable == "groundFertilizer") {
                toggleBCSGreen();
            }
            else if (variable == "compost") {
                toggleCCSGreen();
            }
            else {
                toggleDCSGreen();
            }
        }
        else if (object == "gold") {

            if (variable == "fruit") {
                toggleACSGold();
            }
            else if (variable == "groundFertilizer") {
                toggleBCSGold();
            }
            else if (variable == "compost") {
                toggleCCSGold();
            }
            else {
                toggleDCSGold();
            }
        }
        else if (object == "other") {

            if (variable == "fruit") {
                toggleACSOther();
            }
            else if (variable == "groundFertilizer") {
                toggleBCSOther();
            }
            else if (variable == "compost") {
                toggleCCSOther();
            }
            else {
                toggleDCSOther();
            }
        }
    }

    function addRow(e, object) {
        setCalcValueChanged(true);
        let seasonData = {};
        let objStr = '';
        objStr = object;
        seasonData = groundFertObj[objStr];
        let obj = { id: 0, product: "", totalNInProduct: null, totalProduct: null, totalNApplied: null, newRow: true, percEditable :false}
        seasonData.push(obj);
        setGroundFertObj({
            ...groundFertObj,
            [objStr]: seasonData
        })
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className={cardClass}>

                <h5 className={headerClass2} >Nitrogen Balance Calculator
                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                        <>
                            {saving ?
                                <Button onClick={handleSubmit} color="success" disabled className="btn float-end margin-right10 button-save">
                                    <Spinner size="sm">
                                        Saving...
                                    </Spinner>
                                    <span>
                                        {' '}Saving
                                    </span>
                                </Button>
                                :
                                <Button onClick={handleSubmit} color="success" className="btn float-end margin-right10 button-save">
                                    Save
                                </Button>
                            }
                        </>
                    }
                </h5>

                <Collapse isOpen={true}>
                    <CardBody>
                        <div>

                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                        {seasonList != undefined && "Red"}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                        {seasonList != undefined && "Green"}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                        {seasonList != undefined && "Gold"}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                        {seasonList != undefined && "Other"}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <br />
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>

                                        <Col sm="12">

                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th style={{ width: "25%" }} className="text-center"> Particulars </th>
                                                        <th rowSpan="2" style={{ width: "15%", overflowWrap: "break-word" }} className="text-center"> Inputs (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "15%" }} className="text-center"> Removals (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "30%" }} className="text-center"> Notes </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr id="records" >
                                                        <td >Fruit</td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalA(e, "red")} />

                                                            <Input id="fruit" className="text-center input-width70 fruitNumber" size="md" type="number" onChange={(e) => handleInput(e, "red", "fruit")} value={nitrogenDetails.red.fruit > 0 ? nitrogenDetails.red.fruit : ""}></Input>

                                                        </td>
                                                        <td ><label className="note-tbl">Use calculator to calculate the value</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Ground-applied mineral fertiliser (incl. fertigation)</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalB(e, "red")} />
                                                            <Input id="groundFertilizer" className="text-center input-width70" type="number" pattern="[0-9]" onChange={(e) => handleInput(e, "red", "groundFertilizer")} value={nitrogenDetails.red.groundFertilizer > 0 ? Math.round(nitrogenDetails.red.groundFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>

                                                        <td ><label className="note-tbl">Enter total N value from fert recommendation or use calculator</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Compost</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalC(e, "red")} />
                                                            <Input id="compost" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "red", "compost")} value={nitrogenDetails.red.compost > 0 ? Math.round(nitrogenDetails.red.compost) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Use calculator. The latest research indicates only 15% of total N is released in the year of application.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Foliar Urea or Nitrate</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalD(e, "red")} />
                                                            <Input id="foliarFertilizer" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "red", "foliarFertilizer")} value={nitrogenDetails.red.foliarFertilizer > 0 ? Math.round(nitrogenDetails.red.foliarFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">kg N/ha = total kgs of product per hectare x N%  (i.e. Urea = 46% N, Krista K = 14% N). Other foliars apply very little N.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Budbreak enhancers</td>
                                                        <td className="text-center" >
                                                            <Input onChange={(e) => handleInput(e, "red", "budBreak")} id="budBreak" className="text-center input-width70" type="number" pattern="[0-9]*"
                                                            value={nitrogenDetails.red.budBreak > 0 ? nitrogenDetails.red.budBreak : ""} ></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Average default value is 10 based on N content of products used. Change to 0 if organic.</label></td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="bold-text , surplus">Total Nitrogen Added (kg/ha) </label>
                                            <Input disabled value={(redInputTotal > 0) ? Number(redInputTotal) : 0}></Input>

                                        </Col>
                                        <Col sm="4" className="bold-text"  >

                                            <label className="surplus" >Total Nitrogen Removed (kg/ha) </label>
                                            <Input disabled value={(redRemovalTotal > 0) ? Number(redRemovalTotal) : 0}></Input>
                                        </Col>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="surplus" >{(redInputTotal - redRemovalTotal > 0) ? "Surplus (kg/ha)" : (redInputTotal - redRemovalTotal != 0) ? "Nitrogen deficit" : ""}

                                            </label>
                                            <Input disabled readOnly="true" value={Math.round(redInputTotal - redRemovalTotal)}></Input>


                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col sm="8">
                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th colSpan="3" className="text-center">N Loss Risk Assessment</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "25%" }} colSpan="1" >Items</th>
                                                        <th style={{ width: "50%" }} colSpan="1" >Values</th>
                                                        <th style={{ width: "25%" }} colSpan="1" >Risk</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Soil Type</td>
                                                        <td>{nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].soilTypes}</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].soilTypeRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "red", "soilTypeRisk")} name="soilTypeRisk" id="soilTypeRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Annual Rainfall (mm)</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].annualRainfall} onChange={(e) => handleNLossRiskAssessmentChange(e, "red", "annualRainfall")} name="annualRainfall" id="annualRainfall">
                                                                <option key={uuid()} value={"<1000"}>&lt;1000</option>
                                                                <option key={uuid()} value={"1000-1500"}>1000-1500</option>
                                                                <option key={uuid()} value={">1500"}>&gt;1500</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].annualRainfallRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "red", "annualRainfallRisk")} name="annualRainfallRisk" id="annualRainfallRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>N surplus (kg/ha)</td>
                                                        <td>{nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].nSurplusRiskScore}</td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].nSurplusRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "red", "nSurplusRisk")} name="nSurplusRisk" id="nSurplusRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="text-center">Overall Risk</td>
                                                        <td className="text-center" style={{ fontWeight: "700" }}>{nLossRiskAssessmentObj["red"] != undefined && nLossRiskAssessmentObj["red"] != null && nLossRiskAssessmentObj["red"].overallRisk}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <label className="note-lbl" >Note - Nitrogen incorporated into the kiwifruit canopy is eventually returned to the soil so a net zero result. So not included above.</label>
                                    <Modal isOpen={modalACSRedVisible}>
                                        <ModalHeader toggle={toggleACSRed} >REMOVALS</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th colSpan="2" className="text-center"> A.Removed in fruit - enter values below to calculate </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td style={{ width: "65%" }} >Total kgs (received by packhouse)</td>
                                                                <td >
                                                                    <Input id="rcvdByPackHouse1" value={removalDetails.red.rcvdByPackhouse == 0 ? '' : removalDetails.red.rcvdByPackhouse} className="text-center " onChange={(e) => handleRemovalPopupInput(e, "red", "rcvdByPackhouse")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Hectares</td>
                                                                <td  >
                                                                    <Input id="producingHectares1" value={removalDetails.red.produced == 0 ? '' : removalDetails.red.produced} className="text-center" onChange={(e) => handleRemovalPopupInput(e, "red", "produced")}></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Total N removed per hectare (kg/ha)</td>
                                                                <td >
                                                                    <Input id="removalTotal1" className="text-center" readOnly="true" onChange={(e) => handleRemovalPopupInput(e, "red", "totalNRemoved")} value={removalDetails.red.totalNRemoved == 0 ? '' : removalDetails.red.totalNRemoved}></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "red", "fruit")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleACSRed}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalBCSRedVisible}>
                                        <ModalHeader toggle={toggleBCSRed} >B. Ground applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center">Product</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total tonnes of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied (across all land units)</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {

                                                                groundFertObj["red"] != undefined && groundFertObj["red"] != null && groundFertObj["red"].map((item, idx) =>
                                                                    !('newRow' in item) ?
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td >{item.product}</td>
                                                                                {item.percEditable && 
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "red", "totalNInProduct")
                                                                                        }} ></Input>
                                                                                    </td>    
                                                                                }
                                                                                {!item.percEditable &&
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        {item.totalNInProduct} %
                                                                                    </td>    
                                                                                }
                                                                                

                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "red", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        ) :
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td ><Input value={item.product} onChange={(e) => {
                                                                                    handleGroundFertilizerProduct(e, idx, "red", "product")
                                                                                }} ></Input></td>

                                                                                <td id="totalNInProduct" className="text-center" >
                                                                                    <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                        handleGroundFertilizer(e, idx, "red", "totalNInProduct")
                                                                                    }} ></Input>
                                                                                </td>

                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "red", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        )
                                                                )

                                                            }


                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input min="0" id="redtotalHectaresAppliedGroundFertilizer" className="text-center" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "red", "totalHectaresAppliedGroundFertilizer")
                                                                    }} value={nitrogenDetails.red.totalHectaresAppliedGroundFertilizer == 0 ? '' : nitrogenDetails.red.totalHectaresAppliedGroundFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={redgroundFertTotal == 0 ? '' : redgroundFertTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button color="primary" onClick={(e) => addRow(e, "red")}>Add Row</Button>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "red", "groundFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleBCSRed}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="xl" isOpen={modalCCSRedVisible}>
                                        <ModalHeader toggle={toggleCCSRed} >C. Compost</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "10%", overflowWrap: "break-word" }} className="text-center">Total tonnes applied across all land units</th>
                                                                <th style={{ width: "10%" }} className="text-center">dry weight % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">N % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                                <th style={{ width: "10%" }} className="text-center">How long since last application (years)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                compostObj["red"] != undefined && compostObj["red"] != null &&  compostObj["red"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input className="text-center " id="totalTonnesPerHec" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "red", "totalTonnesPerHec")
                                                                        }} value={item.totalTonnesPerHec == 0 ? '' : item.totalTonnesPerHec}></Input>
                                                                    </td>
                                                                    <td >
                                                                        <Input className="text-center" type="float" pattern="[0-9]*" value={item.dryWeight} id="dryWeight" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "red", "dryWeight")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" value={item.known_N} id="known_N" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "red", "known_N")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" pattern="[0-9]*" id="greenwaste_TotalN" value={item.applied_N}></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center " type="number" pattern="[0-9]" value={item.noOfYears} id="noOfYears" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "red", "noOfYears")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" readOnly="true" pattern="[0-9]*" value={item.avgN == 0 ? '' : item.avgN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }

                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="redtotalHectaresAppliedCompost" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "red", "totalHectaresAppliedCompost")
                                                                    }} value={nitrogenDetails.red.totalHectaresAppliedCompost == 0 ? '' : nitrogenDetails.red.totalHectaresAppliedCompost}></Input>
                                                                </td>
                                                                <td colSpan="4" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={redcompostTotal == 0 ? '' : redcompostTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    
                                                </Col>
                                                <label className="note-lbl">Note - if your compost quantity is in cubic metres, convert to tonnes per hectare by multiplying by 0.7 e.g. 10m3 x 0.7 T/m3 = 7 T / ha.</label>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "red", "compost")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleCCSRed}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalDCSRedVisible}>
                                        <ModalHeader toggle={toggleDCSRed} >D. Foliar applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "20%", overflowWrap: "break-word" }} className="text-center">Total kgs or litres of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied across all land units</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                fertObj["red"] != undefined && fertObj["red"] != null &&  fertObj["red"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input id="totalProduct" className="text-center" type="float" pattern="[0-9]*" onChange={(e) => {
                                                                            handlefertilizerObj(e, idx, "red", "totalProduct")
                                                                        }} value={item.totalProduct == 0 ? '' : item.totalProduct}></Input>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {item.nitrogenInProduct}
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" value={item.totalN == 0 ? '' : item.totalN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }
                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="redtotalHectaresAppliedFoliarFertilizer" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "red", "totalHectaresAppliedFoliarFertilizer")
                                                                    }} value={nitrogenDetails.red.totalHectaresAppliedFoliarFertilizer == 0 ? '' : nitrogenDetails.red.totalHectaresAppliedFoliarFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={redfertilizerTotal == 0 ? '' : redfertilizerTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "red", "foliarFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleDCSRed}>Cancel</Button>
                                        </ModalFooter>
                                </Modal>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>

                                        <Col sm="12">

                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th style={{ width: "25%" }} className="text-center"> Particulars </th>
                                                        <th rowSpan="2" style={{ width: "15%", overflowWrap: "break-word" }} className="text-center"> Inputs (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "15%" }} className="text-center"> Removals (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "30%" }} className="text-center"> Notes </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr id="records" >
                                                        <td >Fruit</td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalA(e, "green")} />

                                                            <Input id="fruit" className="text-center input-width70 fruitNumber" size="md" type="number" onChange={(e) => handleInput(e, "green", "fruit")} value={nitrogenDetails.green.fruit > 0 ? nitrogenDetails.green.fruit : ""}></Input>

                                                        </td>
                                                        <td ><label className="note-tbl">Use calculator to calculate the value</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Ground-applied mineral fertiliser (incl. fertigation)</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalB(e, "green")} />
                                                            <Input id="groundFertilizer" className="text-center input-width70" type="number" pattern="[0-9]" onChange={(e) => handleInput(e, "green", "groundFertilizer")} value={nitrogenDetails.green.groundFertilizer > 0 ? Math.round(nitrogenDetails.green.groundFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>

                                                        <td ><label className="note-tbl">Enter total N value from fert recommendation or use calculator</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Compost</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalC(e, "green")} />
                                                            <Input id="compost" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "green", "compost")} value={nitrogenDetails.green.compost > 0 ? Math.round(nitrogenDetails.green.compost) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Use calculator. The latest research indicates only 15% of total N is released in the year of application.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Foliar Urea or Nitrate</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalD(e, "green")} />
                                                            <Input id="foliarFertilizer" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "green", "foliarFertilizer")} value={nitrogenDetails.green.foliarFertilizer > 0 ? Math.round(nitrogenDetails.green.foliarFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">kg N/ha = total kgs of product per hectare x N%  (i.e. Urea = 46% N, Krista K = 14% N). Other foliars apply very little N.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Budbreak enhancers</td>
                                                        <td className="text-center" >
                                                            <Input id="budBreak" onChange={(e) => handleInput(e, "green", "budBreak")} className="text-center input-width70" type="number" pattern="[0-9]*" value={nitrogenDetails.green.budBreak > 0 ? nitrogenDetails.green.budBreak : ""} ></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Average default value is 10 based on N content of products used. Change to 0 if organic.</label></td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="bold-text , surplus">Total Nitrogen Added (kg/ha) </label>
                                            <Input disabled value={(greenInputTotal > 0) ? Number(greenInputTotal) : 0}></Input>

                                        </Col>
                                        <Col sm="4" className="bold-text"  >

                                            <label className="surplus" >Total Nitrogen Removed (kg/ha) </label>
                                            <Input disabled value={(greenRemovalTotal > 0) ? Number(greenRemovalTotal) : 0}></Input>
                                        </Col>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="surplus" >{(greenInputTotal - greenRemovalTotal > 0) ? "Surplus (kg/ha)" : (greenInputTotal - greenRemovalTotal != 0) ? "Nitrogen deficit" : ""}

                                            </label>
                                            <Input disabled readOnly="true" value={Math.round(greenInputTotal - greenRemovalTotal)}></Input>


                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm="8">
                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th colSpan="3" className="text-center">N Loss Risk Assessment</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "25%" }} colSpan="1" >Items</th>
                                                        <th style={{ width: "50%" }} colSpan="1" >Values</th>
                                                        <th style={{ width: "25%" }} colSpan="1" >Risk</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Soil Type</td>
                                                        <td>{nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].soilTypes}</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].soilTypeRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "green", "soilTypeRisk")} name="soilTypeRisk" id="soilTypeRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Annual Rainfall (mm)</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].annualRainfall} onChange={(e) => handleNLossRiskAssessmentChange(e, "green", "annualRainfall")} name="annualRainfall" id="annualRainfall">
                                                                <option key={uuid()} value={"<1000"}>&lt;1000</option>
                                                                <option key={uuid()} value={"1000-1500"}>1000-1500</option>
                                                                <option key={uuid()} value={">1500"}>&gt;1500</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].annualRainfallRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "green", "annualRainfallRisk")} name="annualRainfallRisk" id="annualRainfallRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>N surplus (kg/ha)</td>
                                                        <td>{nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].nSurplusRiskScore}</td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].nSurplusRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "green", "nSurplusRisk")} name="nSurplusRisk" id="nSurplusRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="text-center">Overall Risk</td>
                                                        <td className="text-center" style={{ fontWeight: "700" }}>{nLossRiskAssessmentObj["green"] != undefined && nLossRiskAssessmentObj["green"] != null && nLossRiskAssessmentObj["green"].overallRisk}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <label className="note-lbl" >Note - Nitrogen incorporated into the kiwifruit canopy is eventually returned to the soil so a net zero result. So not included above.</label>
                                    <Modal isOpen={modalACSGreenVisible}>
                                        <ModalHeader toggle={toggleACSGreen} >REMOVALS</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th colSpan="2" className="text-center"> A.Removed in fruit - enter values below to calculate </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td style={{ width: "65%" }} >Total kgs (received by packhouse)</td>
                                                                <td >
                                                                    <Input id="rcvdByPackHouse1" value={removalDetails.green.rcvdByPackhouse == 0 ? '' : removalDetails.green.rcvdByPackhouse} className="text-center " onChange={(e) => handleRemovalPopupInput(e, "green", "rcvdByPackhouse")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Hectares</td>
                                                                <td  >
                                                                    <Input id="producingHectares1" value={removalDetails.green.produced == 0 ? '' : removalDetails.green.produced} className="text-center" onChange={(e) => handleRemovalPopupInput(e, "green", "produced")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Total N removed per hectare (kg/ha)</td>
                                                                <td >
                                                                    <Input id="removalTotal1" className="text-center" readOnly="true" onChange={(e) => handleRemovalPopupInput(e, "green", "totalNRemoved")} value={removalDetails.green.totalNRemoved == 0 ? '' : removalDetails.green.totalNRemoved}></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "green", "fruit")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleACSGreen}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalBCSGreenVisible}>
                                        <ModalHeader toggle={toggleBCSGreen} >B. Ground applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center">Product</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total tonnes of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied (across all land units)</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {

                                                                groundFertObj["green"] != undefined && groundFertObj["green"] != null && groundFertObj["green"].map((item, idx) =>
                                                                    !('newRow' in item) ?
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td >{item.product}</td>
                                                                                {item.percEditable &&
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "green", "totalNInProduct")
                                                                                        }} ></Input>
                                                                                    </td>    
                                                                                }
                                                                                {!item.percEditable && 
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        {item.totalNInProduct} %
                                                                                    </td>    
                                                                                }
                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center" 
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "green", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        ) :
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td ><Input value={item.product} onChange={(e) => {
                                                                                    handleGroundFertilizerProduct(e, idx, "green", "product")
                                                                                }} ></Input></td>

                                                                                <td id="totalNInProduct" className="text-center" >
                                                                                    <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                        handleGroundFertilizer(e, idx, "green", "totalNInProduct")
                                                                                    }} ></Input>
                                                                                </td>

                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "green", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        )
                                                                )

                                                            }


                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input min="0" id="greentotalHectaresAppliedGroundFertilizer" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "green", "totalHectaresAppliedGroundFertilizer")
                                                                    }} value={nitrogenDetails.green.totalHectaresAppliedGroundFertilizer == 0 ? '' : nitrogenDetails.green.totalHectaresAppliedGroundFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={greengroundFertTotal == 0 ? '' : greengroundFertTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button color="primary" onClick={(e) => addRow(e, "green")}>Add Row</Button>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "green", "groundFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleBCSGreen}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="xl" isOpen={modalCCSGreenVisible}>
                                        <ModalHeader toggle={toggleCCSGreen} >C. Compost</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "10%", overflowWrap: "break-word" }} className="text-center">Total tonnes applied across all land units</th>
                                                                <th style={{ width: "10%" }} className="text-center">dry weight % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">N % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                                <th style={{ width: "10%" }} className="text-center">How long since last application (years)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                compostObj["green"] != undefined && compostObj["green"] != null && compostObj["green"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input className="text-center " id="totalTonnesPerHec" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "green", "totalTonnesPerHec")
                                                                        }} value={item.totalTonnesPerHec == 0 ? '' : item.totalTonnesPerHec}></Input>
                                                                    </td>
                                                                    <td >
                                                                        <Input className="text-center" type="float" pattern="[0-9]*" value={item.dryWeight} id="dryWeight" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "green", "dryWeight")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" value={item.known_N} id="known_N" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "green", "known_N")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" pattern="[0-9]*" id="greenwaste_TotalN" value={item.applied_N}></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center " type="number" pattern="[0-9]" value={item.noOfYears} id="noOfYears" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "green", "noOfYears")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" readOnly="true" pattern="[0-9]*" value={item.avgN == 0 ? '' : item.avgN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }

                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="greentotalHectaresAppliedCompost" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "green", "totalHectaresAppliedCompost")
                                                                    }} value={nitrogenDetails.green.totalHectaresAppliedCompost == 0 ? '' : nitrogenDetails.green.totalHectaresAppliedCompost}></Input>
                                                                </td>
                                                                <td colSpan="4" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={greencompostTotal == 0 ? '' : greencompostTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <label className="note-lbl">Note - if your compost quantity is in cubic metres, convert to tonnes per hectare by multiplying by 0.7 e.g. 10m3 x 0.7 T/m3 = 7 T / ha.</label>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "green", "compost")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleCCSGreen}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalDCSGreenVisible}>
                                        <ModalHeader toggle={toggleDCSGreen} >D. Foliar applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "20%", overflowWrap: "break-word" }} className="text-center">Total kgs or litres of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied across all land units</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                fertObj["green"] != undefined && fertObj["green"] != null &&  fertObj["green"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input id="totalProduct" className="text-center" onChange={(e) => {
                                                                            handlefertilizerObj(e, idx, "green", "totalProduct")
                                                                        }} value={item.totalProduct == 0 ? '' : item.totalProduct}></Input>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {item.nitrogenInProduct}
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" value={item.totalN == 0 ? '' : item.totalN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }
                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="greentotalHectaresAppliedFoliarFertilizer" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "green", "totalHectaresAppliedFoliarFertilizer")
                                                                    }} value={nitrogenDetails.green.totalHectaresAppliedFoliarFertilizer == 0 ? '' : nitrogenDetails.green.totalHectaresAppliedFoliarFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={greenfertilizerTotal == 0 ? '' : greenfertilizerTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "green", "foliarFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleDCSGreen}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>

                                        <Col sm="12">

                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th style={{ width: "25%" }} className="text-center"> Particulars </th>
                                                        <th rowSpan="2" style={{ width: "15%", overflowWrap: "break-word" }} className="text-center"> Inputs (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "15%" }} className="text-center"> Removals (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "30%" }} className="text-center"> Notes </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr id="records" >
                                                        <td >Fruit</td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalA(e, "gold")} />

                                                            <Input id="fruit" className="text-center input-width70 fruitNumber" size="md" type="number" onChange={(e) => handleInput(e, "gold", "fruit")} value={nitrogenDetails.gold.fruit > 0 ? nitrogenDetails.gold.fruit : ""}></Input>

                                                        </td>
                                                        <td ><label className="note-tbl">Use calculator to calculate the value</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Ground-applied mineral fertiliser (incl. fertigation)</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalB(e, "gold")} />
                                                            <Input id="groundFertilizer" className="text-center input-width70" type="number" pattern="[0-9]" onChange={(e) => handleInput(e, "gold", "groundFertilizer")} value={nitrogenDetails.gold.groundFertilizer > 0 ? Math.round(nitrogenDetails.gold.groundFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>

                                                        <td ><label className="note-tbl">Enter total N value from fert recommendation or use calculator</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Compost</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalC(e, "gold")} />
                                                            <Input id="compost" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "gold", "compost")} value={nitrogenDetails.gold.compost > 0 ? Math.round(nitrogenDetails.gold.compost) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Use calculator. The latest research indicates only 15% of total N is released in the year of application.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Foliar Urea or Nitrate</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalD(e, "gold")} />
                                                            <Input id="foliarFertilizer" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "gold", "foliarFertilizer")} value={nitrogenDetails.gold.foliarFertilizer > 0 ? Math.round(nitrogenDetails.gold.foliarFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">kg N/ha = total kgs of product per hectare x N%  (i.e. Urea = 46% N, Krista K = 14% N). Other foliars apply very little N.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Budbreak enhancers</td>
                                                        <td className="text-center" >
                                                            <Input id="budBreak" onChange={(e) => handleInput(e, "gold", "budBreak")} className="text-center input-width70" type="number" pattern="[0-9]*" value={nitrogenDetails.gold.budBreak > 0 ? nitrogenDetails.gold.budBreak : ""} ></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Average default value is 10 based on N content of products used. Change to 0 if organic.</label></td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="bold-text , surplus">Total Nitrogen Added (kg/ha) </label>
                                            <Input disabled value={(goldInputTotal > 0) ? Number(goldInputTotal) : 0}></Input>

                                        </Col>
                                        <Col sm="4" className="bold-text"  >

                                            <label className="surplus" >Total Nitrogen Removed (kg/ha) </label>
                                            <Input disabled value={(goldRemovalTotal > 0) ? Number(goldRemovalTotal) : 0}></Input>
                                        </Col>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="surplus" >{(goldInputTotal - goldRemovalTotal > 0) ? "Surplus (kg/ha)" : (goldInputTotal - goldRemovalTotal != 0) ? "Nitrogen deficit" : ""}

                                            </label>
                                            <Input disabled readOnly="true" value={Math.round(goldInputTotal - goldRemovalTotal)}></Input>


                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col sm="8">
                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th colSpan="3" className="text-center">N Loss Risk Assessment</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{width:"25%"}} colSpan="1" >Items</th>
                                                        <th style={{ width: "50%" }} colSpan="1" >Values</th>
                                                        <th style={{ width: "25%" }}  colSpan="1" >Risk</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Soil Type</td>
                                                        <td>{nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].soilTypes}</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].soilTypeRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "gold","soilTypeRisk")} name="soilTypeRisk" id="soilTypeRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Annual Rainfall (mm)</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].annualRainfall} onChange={(e) => handleNLossRiskAssessmentChange(e, "gold", "annualRainfall")} name="annualRainfall" id="annualRainfall">
                                                                <option key={uuid()} value={"<1000"}>&lt;1000</option>
                                                                <option key={uuid()} value={"1000-1500"}>1000-1500</option>
                                                                <option key={uuid()} value={">1500"}>&gt;1500</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].annualRainfallRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "gold", "annualRainfallRisk")} name="annualRainfallRisk" id="annualRainfallRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>N surplus (kg/ha)</td>
                                                        <td>{nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].nSurplusRiskScore}</td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].nSurplusRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "gold", "nSurplusRisk")} name="nSurplusRisk" id="nSurplusRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="text-center">Overall Risk</td>
                                                        <td className="text-center" style={{ fontWeight:"700" }}>{nLossRiskAssessmentObj["gold"] != undefined && nLossRiskAssessmentObj["gold"] != null && nLossRiskAssessmentObj["gold"].overallRisk}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                     </Row>

                                    <label className="note-lbl" >Note - Nitrogen incorporated into the kiwifruit canopy is eventually returned to the soil so a net zero result. So not included above.</label>
                                    <Modal isOpen={modalACSGoldVisible}>
                                        <ModalHeader toggle={toggleACSGold} >REMOVALS</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th colSpan="2" className="text-center"> A.Removed in fruit - enter values below to calculate </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td style={{ width: "65%" }} >Total kgs (received by packhouse)</td>
                                                                <td >
                                                                    <Input id="rcvdByPackhouse" value={removalDetails.gold.rcvdByPackhouse == 0 ? '' : removalDetails.gold.rcvdByPackhouse} className="text-center " onChange={(e) => handleRemovalPopupInput(e, "gold", "rcvdByPackhouse")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Hectares</td>
                                                                <td  >
                                                                    <Input id="producingHectares" value={removalDetails.gold.produced == 0 ? '' : removalDetails.gold.produced} className="text-center" onChange={(e) => handleRemovalPopupInput(e, "gold", "produced")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Total N removed per hectare (kg/ha)</td>
                                                                <td >
                                                                    <Input id="removalTotal" className="text-center" readOnly="true" onChange={(e) => handleRemovalPopupInput(e, "gold", "totalNRemoved")} value={removalDetails.gold.totalNRemoved == 0 ? '' : removalDetails.gold.totalNRemoved}></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "gold", "fruit")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleACSGold}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalBCSGoldVisible}>
                                        <ModalHeader toggle={toggleBCSGold} >B. Ground applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center">Product</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total tonnes of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied (across all land units)</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {

                                                                groundFertObj["gold"] != undefined && groundFertObj["gold"] != null && groundFertObj["gold"].map((item, idx) =>

                                                                    !('newRow' in item) ?
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td >{item.product}</td>

                                                                                {item.percEditable && 
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "gold", "totalNInProduct")
                                                                                        }} ></Input>
                                                                                    </td>    
                                                                                }
                                                                                {!item.percEditable &&
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        {item.totalNInProduct} %
                                                                                    </td>    
                                                                                }
                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "gold", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        ) :
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td ><Input value={item.product} onChange={(e) => {
                                                                                    handleGroundFertilizerProduct(e, idx, "gold", "product")
                                                                                }} ></Input></td>

                                                                                <td id="totalNInProduct" className="text-center" >
                                                                                    <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                        handleGroundFertilizer(e, idx, "gold", "totalNInProduct")
                                                                                    }} ></Input>
                                                                                </td>

                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center" 
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "gold", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        )
                                                                    //<tr >
                                                                    //    <td >{item.product}</td>
                                                                    //    <td id="nitogenIntotalProduct" className="text-center" >
                                                                    //        {item.totalNInProduct} %
                                                                    //    </td>
                                                                    //    <td >
                                                                    //        <Input id="totalProduct" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                    //            handleGroundFertilizer(e, idx, "gold", "totalProduct")
                                                                    //        }} value={item.totalProduct}></Input>
                                                                    //    </td>
                                                                    //    <td >
                                                                    //        <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied}></Input>
                                                                    //    </td>

                                                                    //</tr>
                                                                )




                                                            }


                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input min="0" id="goldtotalHectaresAppliedGroundFertilizer" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "gold", "totalHectaresAppliedGroundFertilizer")
                                                                    }} value={nitrogenDetails.gold.totalHectaresAppliedGroundFertilizer == 0 ? '' : nitrogenDetails.gold.totalHectaresAppliedGroundFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={goldgroundFertTotal == 0 ? '' : goldgroundFertTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button color="primary" onClick={(e) => addRow(e, "gold")}>Add Row</Button>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "gold", "groundFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleBCSGold}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="xl" isOpen={modalCCSGoldVisible}>
                                        <ModalHeader toggle={toggleCCSGold} >C. Compost</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "10%", overflowWrap: "break-word" }} className="text-center">Total tonnes applied across all land units</th>
                                                                <th style={{ width: "10%" }} className="text-center">dry weight % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">N % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                                <th style={{ width: "10%" }} className="text-center">How long since last application (years)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                compostObj["gold"] != undefined && compostObj["gold"]  != null && compostObj["gold"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input className="text-center " id="totalTonnesPerHec" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "gold", "totalTonnesPerHec")
                                                                        }} value={item.totalTonnesPerHec == 0 ? '' : item.totalTonnesPerHec}></Input>
                                                                    </td>
                                                                    <td >
                                                                        <Input className="text-center" type="float" pattern="[0-9]*" value={item.dryWeight} id="dryWeight" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "gold", "dryWeight")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" value={item.known_N} id="known_N" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "gold", "known_N")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" pattern="[0-9]*" id="greenwaste_TotalN" value={item.applied_N}></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center " type="number" pattern="[0-9]" value={item.noOfYears} id="noOfYears" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "gold", "noOfYears")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" readOnly="true" pattern="[0-9]*" value={item.avgN == 0 ? '' : item.avgN}></Input>
                                                                    </td>

                                                                </tr>

                                                                )
                                                            }

                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="goldtotalHectaresAppliedCompost" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "gold", "totalHectaresAppliedCompost")
                                                                    }} value={nitrogenDetails.gold.totalHectaresAppliedCompost == 0 ? '' : nitrogenDetails.gold.totalHectaresAppliedCompost}></Input>
                                                                </td>
                                                                <td colSpan="4" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={goldcompostTotal == 0 ? '' : goldcompostTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <label className="note-lbl">Note - if your compost quantity is in cubic metres, convert to tonnes per hectare by multiplying by 0.7 e.g. 10m3 x 0.7 T/m3 = 7 T / ha.</label>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "gold", "compost")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleCCSGold}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalDCSGoldVisible}>
                                        <ModalHeader toggle={toggleDCSGold} >D. Foliar applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "20%", overflowWrap: "break-word" }} className="text-center">Total kgs or litres of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied across all land units</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                fertObj["gold"] != undefined && fertObj["gold"] != null && fertObj["gold"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input id="totalProduct" className="text-center" onChange={(e) => {
                                                                            handlefertilizerObj(e, idx, "gold", "totalProduct")
                                                                        }} value={item.totalProduct == 0 ? '' : item.totalProduct}></Input>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {item.nitrogenInProduct}
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" value={item.totalN == 0 ? '' : item.totalN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }
                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="goldtotalHectaresAppliedFoliarFertilizer" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "gold", "totalHectaresAppliedFoliarFertilizer")
                                                                    }} value={nitrogenDetails.gold.totalHectaresAppliedFoliarFertilizer == 0 ? '' : nitrogenDetails.gold.totalHectaresAppliedFoliarFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={goldfertilizerTotal == 0 ? '' : goldfertilizerTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "gold", "foliarFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleDCSGold}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>

                                        <Col sm="12">

                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th style={{ width: "25%" }} className="text-center"> Particulars </th>
                                                        <th rowSpan="2" style={{ width: "15%", overflowWrap: "break-word" }} className="text-center"> Inputs (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "15%" }} className="text-center"> Removals (Kg/ha) </th>
                                                        <th rowSpan="2" style={{ width: "30%" }} className="text-center"> Notes </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr id="records" >
                                                        <td >Fruit</td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalA(e, "other")} />

                                                            <Input id="fruit" className="text-center input-width70 fruitNumber" size="md" type="number" onChange={(e) => handleInput(e, "other", "fruit")} value={nitrogenDetails.other.fruit > 0 ? nitrogenDetails.other.fruit : ""}></Input>

                                                        </td>
                                                        <td ><label className="note-tbl">Use calculator to calculate the value</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Ground-applied mineral fertiliser (incl. fertigation)</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalB(e, "other")} />
                                                            <Input id="groundFertilizer" className="text-center input-width70" type="number" pattern="[0-9]" onChange={(e) => handleInput(e, "other", "groundFertilizer")} value={nitrogenDetails.other.groundFertilizer > 0 ? Math.round(nitrogenDetails.other.groundFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>

                                                        <td ><label className="note-tbl">Enter total N value from fert recommendation or use calculator</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Compost</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalC(e, "other")} />
                                                            <Input id="compost" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "other", "compost")} value={nitrogenDetails.other.compost > 0 ? Math.round(nitrogenDetails.other.compost) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Use calculator. The latest research indicates only 15% of total N is released in the year of application.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Foliar Urea or Nitrate</td>
                                                        <td className="text-center" >
                                                            <FaCalculator className="calculator-img" onClick={(e) => openModalD(e, "other")} />
                                                            <Input id="foliarFertilizer" className="text-center input-width70" type="number" pattern="[0-9]*" onChange={(e) => handleInput(e, "other", "foliarFertilizer")} value={nitrogenDetails.other.foliarFertilizer > 0 ? Math.round(nitrogenDetails.other.foliarFertilizer) : ""}></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">kg N/ha = total kgs of product per hectare x N%  (i.e. Urea = 46% N, Krista K = 14% N). Other foliars apply very little N.</label></td>
                                                    </tr>
                                                    <tr  >
                                                        <td >Budbreak enhancers</td>
                                                        <td className="text-center" >
                                                            <Input id="budBreak" onChange={(e) => handleInput(e, "other", "budBreak")} className="text-center input-width70" type="number" pattern="[0-9]*" value={nitrogenDetails.other.budBreak > 0 ? nitrogenDetails.other.budBreak : ""} ></Input>

                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td ><label className="note-tbl">Average default value is 10 based on N content of products used. Change to 0 if organic.</label></td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="bold-text , surplus">Total Nitrogen Added (kg/ha) </label>
                                            <Input disabled value={(otherInputTotal > 0) ? Number(otherInputTotal) : 0}></Input>

                                        </Col>
                                        <Col sm="4" className="bold-text"  >

                                            <label className="surplus" >Total Nitrogen Removed (kg/ha) </label>
                                            <Input disabled value={(otherRemovalTotal > 0) ? Number(otherRemovalTotal) : 0}></Input>
                                        </Col>
                                        <Col sm="4" className="bold-text"  >
                                            <label className="surplus" >{(otherInputTotal - otherRemovalTotal > 0) ? "Surplus (kg/ha)" : (otherInputTotal - otherRemovalTotal != 0) ? "Nitrogen deficit" : ""}

                                            </label>
                                            <Input disabled readOnly="true" value={Math.round(otherInputTotal - otherRemovalTotal)}></Input>


                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col sm="8">
                                            <table className="table table-bordered table-hover" id="tab_logic">
                                                <thead className='cal-table-style'>
                                                    <tr>
                                                        <th colSpan="3" className="text-center">N Loss Risk Assessment</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "25%" }} colSpan="1" >Items</th>
                                                        <th style={{ width: "50%" }} colSpan="1" >Values</th>
                                                        <th style={{ width: "25%" }} colSpan="1" >Risk</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Soil Type</td>
                                                        <td>{nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].soilTypes}</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].soilTypeRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "other", "soilTypeRisk")} name="soilTypeRisk" id="soilTypeRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Annual Rainfall (mm)</td>
                                                        <td>
                                                            <Input style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].annualRainfall} onChange={(e) => handleNLossRiskAssessmentChange(e, "other", "annualRainfall")} name="annualRainfall" id="annualRainfall">
                                                                <option key={uuid()} value={"<1000"}>&lt;1000</option>
                                                                <option key={uuid()} value={"1000-1500"}>1000-1500</option>
                                                                <option key={uuid()} value={">1500"}>&gt;1500</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].annualRainfallRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "other", "annualRainfallRisk")} name="annualRainfallRisk" id="annualRainfallRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>N surplus (kg/ha)</td>
                                                        <td>{nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].nSurplusRiskScore}</td>
                                                        <td>
                                                            <Input disabled style={{ fontSize: "13px", lineHeight: "1.15" }} type="select" value={nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].nSurplusRisk} onChange={(e) => handleNLossRiskAssessmentChange(e, "other", "nSurplusRisk")} name="nSurplusRisk" id="nSurplusRisk">
                                                                <option key={uuid()} value={"Low"}>Low</option>
                                                                <option key={uuid()} value={"Medium"}>Medium</option>
                                                                <option key={uuid()} value={"High"}>High</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="text-center">Overall Risk</td>
                                                        <td className="text-center" style={{ fontWeight: "700" }}>{nLossRiskAssessmentObj["other"] != undefined && nLossRiskAssessmentObj["other"] != null && nLossRiskAssessmentObj["other"].overallRisk}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>

                                    <label className="note-lbl" >Note - Nitrogen incorporated into the kiwifruit canopy is eventually returned to the soil so a net zero result. So not included above.</label>
                                    <Modal isOpen={modalACSOtherVisible}>
                                        <ModalHeader toggle={toggleACSOther} >REMOVALS</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th colSpan="2" className="text-center"> A.Removed in fruit - enter values below to calculate </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td style={{ width: "65%" }} >Total kgs (received by packhouse)</td>
                                                                <td >
                                                                    <Input id="rcvdByPackhouse" value={removalDetails.other.rcvdByPackhouse == 0 ? '' : removalDetails.other.rcvdByPackhouse} className="text-center " onChange={(e) => handleRemovalPopupInput(e, "other", "rcvdByPackhouse")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Hectares</td>
                                                                <td  >
                                                                    <Input id="producingHectares" value={removalDetails.other.produced == 0 ? '' : removalDetails.other.produced} className="text-center" onChange={(e) => handleRemovalPopupInput(e, "other", "produced")} ></Input>
                                                                </td>

                                                            </tr>
                                                            <tr >
                                                                <td >Total N removed per hectare (kg/ha)</td>
                                                                <td >
                                                                    <Input id="removalTotal" className="text-center" readOnly="true" onChange={(e) => handleRemovalPopupInput(e, "other", "totalNRemoved")} value={removalDetails.other.totalNRemoved == 0 ? '' : removalDetails.other.totalNRemoved}></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "other", "fruit")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleACSOther}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalBCSOtherVisible}>
                                        <ModalHeader toggle={toggleBCSOther} >B. Ground applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center">Product</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total tonnes of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied (across all land units)</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {

                                                                groundFertObj["other"] != undefined && groundFertObj["other"] != null && groundFertObj["other"].map((item, idx) =>

                                                                    !('newRow' in item) ?
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td >{item.product}</td>

                                                                                {item.percEditable &&
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "other", "totalNInProduct")
                                                                                        }} ></Input>
                                                                                    </td>
                                                                                }
                                                                                {!item.percEditable &&
                                                                                    <td id="totalNInProduct" className="text-center" >
                                                                                        {item.totalNInProduct} %
                                                                                    </td>
                                                                                }
                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "other", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        ) :
                                                                        (
                                                                            <tr key={idx} id={idx} >

                                                                                <td ><Input value={item.product} onChange={(e) => {
                                                                                    handleGroundFertilizerProduct(e, idx, "other", "product")
                                                                                }} ></Input></td>

                                                                                <td id="totalNInProduct" className="text-center" >
                                                                                    <Input value={item.totalNInProduct} onChange={(e) => {
                                                                                        handleGroundFertilizer(e, idx, "other", "totalNInProduct")
                                                                                    }} ></Input>
                                                                                </td>

                                                                                <td >
                                                                                    <Input id="totalProduct" className="text-center"
                                                                                        value={item.totalProduct == 0 ? '' : item.totalProduct}
                                                                                        onChange={(e) => {
                                                                                            handleGroundFertilizer(e, idx, "other", "totalProduct")
                                                                                        }} ></Input>
                                                                                </td>
                                                                                <td >
                                                                                    <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied == 0 ? '' : item.totalNApplied}></Input>
                                                                                </td>

                                                                            </tr>

                                                                        )
                                                                    //<tr >
                                                                    //    <td >{item.product}</td>
                                                                    //    <td id="nitogenIntotalProduct" className="text-center" >
                                                                    //        {item.totalNInProduct} %
                                                                    //    </td>
                                                                    //    <td >
                                                                    //        <Input id="totalProduct" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                    //            handleGroundFertilizer(e, idx, "other", "totalProduct")
                                                                    //        }} value={item.totalProduct}></Input>
                                                                    //    </td>
                                                                    //    <td >
                                                                    //        <Input className="text-center" type="number" pattern="[0-9]*" value={item.totalNApplied}></Input>
                                                                    //    </td>

                                                                    //</tr>
                                                                )




                                                            }


                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input min="0" id="othertotalHectaresAppliedGroundFertilizer" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "other", "totalHectaresAppliedGroundFertilizer")
                                                                    }} value={nitrogenDetails.other.totalHectaresAppliedGroundFertilizer == 0 ? '' : nitrogenDetails.other.totalHectaresAppliedGroundFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={othergroundFertTotal == 0 ? '' : othergroundFertTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button color="primary" onClick={(e) => addRow(e, "other")}>Add Row</Button>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "other", "groundFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleBCSOther}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="xl" isOpen={modalCCSOtherVisible}>
                                        <ModalHeader toggle={toggleCCSOther} >C. Compost</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "10%", overflowWrap: "break-word" }} className="text-center">Total tonnes applied across all land units</th>
                                                                <th style={{ width: "10%" }} className="text-center">dry weight % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">N % (if known)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                                <th style={{ width: "10%" }} className="text-center">How long since last application (years)</th>
                                                                <th style={{ width: "10%" }} className="text-center">Total kgs of N applied across all land units, per year</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                compostObj["other"] != undefined && compostObj["other"] != null && compostObj["other"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input className="text-center " id="totalTonnesPerHec" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "other", "totalTonnesPerHec")
                                                                        }} value={item.totalTonnesPerHec == 0 ? '' : item.totalTonnesPerHec}></Input>
                                                                    </td>
                                                                    <td >
                                                                        <Input className="text-center" type="float" pattern="[0-9]*" value={item.dryWeight} id="dryWeight" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "other", "dryWeight")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" value={item.known_N} id="known_N" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "other", "known_N")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" pattern="[0-9]*" id="greenwaste_TotalN" value={item.applied_N}></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center " type="number" pattern="[0-9]" value={item.noOfYears} id="noOfYears" onChange={(e) => {
                                                                            handleCompostObj(e, idx, "other", "noOfYears")
                                                                        }} ></Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" readOnly="true" pattern="[0-9]*" value={item.avgN == 0 ? '' : item.avgN}></Input>
                                                                    </td>

                                                                </tr>

                                                                )
                                                            }

                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="othertotalHectaresAppliedCompost" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "other", "totalHectaresAppliedCompost")
                                                                    }} value={nitrogenDetails.other.totalHectaresAppliedCompost == 0 ? '' : nitrogenDetails.other.totalHectaresAppliedCompost}></Input>
                                                                </td>
                                                                <td colSpan="4" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={othercompostTotal == 0 ? '' : othercompostTotal} ></Input>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <label className="note-lbl">Note - if your compost quantity is in cubic metres, convert to tonnes per hectare by multiplying by 0.7 e.g. 10m3 x 0.7 T/m3 = 7 T / ha.</label>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "other", "compost")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleCCSOther}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal size="lg" isOpen={modalDCSOtherVisible}>
                                        <ModalHeader toggle={toggleDCSOther} >D. Foliar applied fertilisers</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col>
                                                    <table className="table table-bordered table-hover" id="tab_logic">
                                                        <thead className='cal-table-style'>
                                                            <tr>
                                                                <th style={{ width: "40%" }} className="text-center"> Product </th>
                                                                <th style={{ width: "20%", overflowWrap: "break-word" }} className="text-center">Total kgs or litres of product applied across all land units</th>
                                                                <th style={{ width: "20%" }} className="text-center">Percentage of nitrogen in product weight</th>
                                                                <th style={{ width: "20%" }} className="text-center">Total kgs of N applied across all land units</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                fertObj["other"] != undefined && fertObj["other"] != null && fertObj["other"].map((item, idx) => <tr>
                                                                    <td >{item.product}</td>
                                                                    <td>
                                                                        <Input id="totalProduct" className="text-center" onChange={(e) => {
                                                                            handlefertilizerObj(e, idx, "other", "totalProduct")
                                                                        }} value={item.totalProduct == 0 ? '' : item.totalProduct}></Input>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {item.nitrogenInProduct}
                                                                    </td>
                                                                    <td>
                                                                        <Input className="text-center" type="float" readOnly="true" value={item.totalN == 0 ? '' : item.totalN}></Input>
                                                                    </td>

                                                                </tr>
                                                                )
                                                            }
                                                            <tr >
                                                                <td><b>Total hectares applied to</b></td>
                                                                <td>
                                                                    <Input id="othertotalHectaresAppliedFoliarFertilizer" min="0" className="text-center" type="number" pattern="[0-9]*" onChange={(e) => {
                                                                        handleTotalHectaresApplied(e, "other", "totalHectaresAppliedFoliarFertilizer")
                                                                    }} value={nitrogenDetails.other.totalHectaresAppliedFoliarFertilizer == 0 ? '' : nitrogenDetails.other.totalHectaresAppliedFoliarFertilizer}></Input>
                                                                </td>
                                                                <td colSpan="1" >Total</td>
                                                                <td>
                                                                    <Input className="text-center" readOnly="true" pattern="[0-9]" value={otherfertilizerTotal == 0 ? '' : otherfertilizerTotal} ></Input>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                <Button color="primary" onClick={(e) => handleSubmitA(e, "other", "foliarFertilizer")}>Update</Button>
                                            }
                                            <Button color="danger" onClick={toggleDCSOther}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>

            <Modal isOpen={calculatorPopupChanged.showPopup}>
                <ModalHeader toggle={handlecalculatorPopupChangedClose} >Confirm</ModalHeader>
                <ModalBody>
                    This will clear the calculator values are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handlecalculatorPopupChangedConfirm} color="primary">Yes</Button>{' '}
                    <Button color="danger" onClick={handlecalculatorPopupChangedClose}>No</Button>
                </ModalFooter>
            </Modal>

        </div>
    )

}
export default NitrogenBalanceCalc