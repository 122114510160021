import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Collapse, CardBody, CardHeader, Table, Button, Input, Label } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useDownloadExcel } from 'react-export-table-to-excel';
import uuid from 'react-uuid';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const ActionSummary = ({ riskModel }) => {
    const [isOpen, setIsOpen] = useState(true); const [showFullActionDetail, setShowFullActionDetail] = useState(false); const [showActionsOnly, setShowActionsOnly] = useState(false);
    const [showFullActionWithRisk, setShowFullActionWithRisk] = useState(true); const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [isIconUp, setIsIconUp] = useState(true); const [farmName, setFarmName] = useState(JSON.parse(localStorage.getItem("defaultFarmName"))); const [farmUniqueId, setFarmUniqueId] = useState(JSON.parse(localStorage.getItem("defaultFarmUniqueId")));
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const toggle = (e) => {
        if (e.nativeEvent.srcElement.className === 'card_icon') {
            setIsOpen(true)
            setIsIconUp(true)
        }
        else {
            setIsOpen(!isOpen)
            setIsIconUp(!isIconUp)
        }
    };
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Action Summary',
        sheet: 'ActionSummary'
    })

    const handleActionView = (itemId, actionId, routePath, isWholeFarmAction) => {
        window.open('/' + routePath + '?itemId=' + itemId + '&actionId=' + actionId + '&isWholeFarmAction=' + isWholeFarmAction + '&navigateFrom=Actions', "_blank");
    }

    useEffect(() => {
        
    }, [])
    const handleSetShowFullActionDetail = (e) => {
        setShowFullActionDetail(e.target.checked)
        if (e.target.checked) {
            setShowActionsOnly(false)
            setShowFullActionWithRisk(false)
        }
    }

    const handleSetShowFullActionWithRisk = (e) => {
        setShowFullActionWithRisk(e.target.checked)
        if (e.target.checked) {
            setShowActionsOnly(false)
            setShowFullActionDetail(false)
        }
    }

    const handleSetShowActionsOnly = (e) => {
        setShowActionsOnly(e.target.checked)
        if (e.target.checked) {
            setShowFullActionDetail(false)
            setShowFullActionWithRisk(false)
        }
    }

    const exportActionsToExcel = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportActionsToExcel();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportActionsToExcel = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportOpenActionsExcel/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })

            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "Actions - " + farmName.toString() + " - " + farmUniqueId.toString() + ".xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const exportRiskAndActionsToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleRiskAndActionsToPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleRiskAndActionsToPdf = async (e) => {
        const response = await axios.get(
            '/api/summary/GenerateRiskAndActionsPdf/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "Risks and actions - " + farmName.toString() + " - " + farmUniqueId.toString() + ".pdf";
            document.body.appendChild(link)
            link.click();
        }

    }

    const exportActionsToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleActionsToPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleActionsToPdf = async (e) => {
        const response = await axios.get(
            '/api/summary/GenerateActionsPdf/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "Actions - " + farmName.toString() + " - " + farmUniqueId.toString() + ".pdf";
            document.body.appendChild(link)
            link.click();
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <Card className="my-4">
            <CardHeader className="item-header item_click" onClick={(e) => (toggle(e))}>
                <Row>
                    <Col sm="11"><h5>Risk and Action Management
                        <Button style={{ float: "right", background: "white", border: "white", color: "black" }} onClick={(e) => exportActionsToExcel(e)} value="Export" placeholder="">
                            Actions <img src="/images/Excel_icon.png" className="card_icon" ></img>
                        </Button>
                        <Button style={{ float: "right", background: "white", border: "white", color: "black", marginRight: "20px" }} onClick={(e) => exportActionsToPdf(e)} value="Export" placeholder="">
                            Actions <img src="/images/PDF_icon.png" className="card_icon" ></img>
                        </Button>
                        <Button style={{ float: "right", background: "white", border: "white", color: "black", marginRight: "20px" }} onClick={(e) => exportRiskAndActionsToPdf(e)} value="Export" placeholder="">
                            Risk & Actions <img src="/images/PDF_icon.png" className="card_icon" ></img>
                        </Button>
                    </h5></Col>
                    <Col sm="1" className="toggleoutline" style={{ marginLeft: "auto", marginRight: "20px" }}>{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody className="actionSummary_Card">
                    <Row>
                        <Col sm="4" style={{ alignSelf: "end" }}>
                            <Input type="checkbox" checked={showFullActionWithRisk} onChange={(e) => handleSetShowFullActionWithRisk(e)} name="showFullActionWithRisk" id="showFullActionWithRisk" />&nbsp;&nbsp;&nbsp;
                            <Label>Show Risks and Actions</Label>
                        </Col>
                        <Col sm="4" style={{ alignSelf: "end" }}>
                            <Input type="checkbox" checked={showFullActionDetail} onChange={(e) => handleSetShowFullActionDetail(e)} name="showFullActionDetail" id="showFullActionDetail" />&nbsp;&nbsp;&nbsp;
                            <Label>Show Risks and Actions (show Risk Detail)</Label>
                        </Col>
                        <Col sm="4" style={{ alignSelf: "end" }}>
                            <Input type="checkbox" checked={showActionsOnly} onChange={(e) => handleSetShowActionsOnly(e)} name="showActionsOnly" id="showActionsOnly" />&nbsp;&nbsp;&nbsp;
                            <Label>Actions Only</Label>
                        </Col>
                    </Row>
                    {
                        showFullActionDetail &&  
                        <Row>
                                <Col>
                                    <Table bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                        <tbody style={{ textAlign: 'center' }}>
                                            {riskModel.riskList.filter(x => x.isActive == true).length > 0 &&
                                                riskModel.riskList.filter(x => x.isActive == true).map((risk, pidx) =>
                                                    <>
                                                        {
                                                            <>
                                                                <tr key={uuid()}>
                                                                    <td rowSpan="3" style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#008436', color: 'white', width: "10%" }} className="rotate"> RISK </td>
                                                                    <td style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: 'white', color: 'black', width: "90%", fontSize: "18px" }}> {risk.name} </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <td style={{ wordBreak: "break-word", fontWeight: "300", textAlign: 'left', background: 'white', color: 'black', width: "90%", fontSize: "15px" }}> {risk.description} </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <td>
                                                                        <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                            <thead>
                                                                                <tr style={{ background: '#B6D137' }}>
                                                                                    <th colSpan="1" rowSpan="2" style={{ width: '30%', textAlign: 'center', color: "white" }}> Category </th>
                                                                                    <th colSpan="1" rowSpan="2" style={{ width: '30%', textAlign: 'center', color: "white" }}> Contaminants</th>
                                                                                    <th colSpan="3" rowSpan="1" style={{ width: '40%', textAlign: 'center', color: "white" }}> Land Units</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                <tr>
                                                                                    <td style={{ wordBreak: "break-word", textAlign: 'left', background: 'white', color: 'black', fontSize: "14px" }}>{risk.category}</td>
                                                                                    <td style={{ wordBreak: "break-word", textAlign: 'left', background: 'white', color: 'black', fontSize: "14px" }}>{risk.riskContaminants}</td>
                                                                                    <td colSpan="3">
                                                                                        <div style={{ height: "150px", overflowY: "auto" }}>
                                                                                            <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                                <tr>
                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '50%', textAlign: 'center', color: "white", background: '#008436' }}> Land Units</th>
                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '25%', textAlign: 'center', color: "white", background: '#008436' }}> Significance</th>
                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '25%', textAlign: 'center', color: "white", background: '#008436' }}> Actions</th>
                                                                                                </tr>
                                                                                                <tbody style={{ textAlign: 'center' }}>
                                                                                                    <>
                                                                                                        {riskModel.luRiskList.filter(x => x.riskId == risk.id).length > 0 &&
                                                                                                            riskModel.luRiskList.filter(x => x.riskId == risk.id).map((lu, pidx) =>
                                                                                                                <>
                                                                                                                    {
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.name} </td>
                                                                                                                                {lu.lowRisk &&
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#1fcc65', color: "white" }}> Low </td>
                                                                                                                                }
                                                                                                                                {lu.mediumRisk &&
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#ffc107', color: "white" }}> Medium </td>
                                                                                                                                }
                                                                                                                                {lu.highRisk &&
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left", background: '#dc3545', color: "white" }}> High </td>
                                                                                                                                }
                                                                                                                                {!lu.highRisk && !lu.mediumRisk && !lu.lowRisk &&
                                                                                                                                    <td style={{ wordBreak: "break-word", fontSize: "14px", textAlign: "left" }}></td>
                                                                                                                                }
                                                                                                                                <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.totalAction == 0 ? '' : lu.totalAction} </td>
                                                                                                                            </tr>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </>
                                                                                                            )}
                                                                                                    </>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <td rowSpan="1" style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#FFCE18', color: '#008436', width: "10%" }} className="rotate"> ACTIONS </td>
                                                                    <td style={{ wordBreak: "break-word", textAlign: 'left', background: 'white', color: 'black', width: "100%", fontSize: "14px" }}>
                                                                        <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                            <thead>
                                                                                <tr style={{ background: '#FFCE18' }}>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '5%', textAlign: 'center', color: "black" }}> ID </th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Due Date</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Item</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '24%', textAlign: 'center', color: "black" }}> Action Detail</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '12%', textAlign: 'center', color: "black" }}> Land Units Action Applies To</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '8%', textAlign: 'center', color: "black" }}> Existing Action</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '9%', textAlign: 'center', color: "black" }}> Category</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Regulatory Requirement</th>
                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '6%', textAlign: 'center', color: "black" }}> View</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                <>
                                                                                    {riskModel.actionList.filter(x => x.activityRiskId == risk.id).length > 0 &&
                                                                                        riskModel.actionList.filter(x => x.activityRiskId == risk.id).map((action, pidx) =>
                                                                                            <>
                                                                                                {
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.id} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.dueDateText} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.itemName} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.title} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.landUnits} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.existingActionText} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.categoryText} </td>
                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.regulatoryRequirementDescription} </td>
                                                                                                            <td style={{ textAlign: 'left' }}><button type="button" id={"view" + action.id}
                                                                                                                onClick={(itemId, actionId, routePath, isWholeFarmAction) => handleActionView(action.itemId, action.id, action.routePath, action.isWholeFarmAction)}
                                                                                                                className="btn btn-primary btn-sm">
                                                                                                                View
                                                                                                            </button> </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                </>

                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <td colSpan="2" height="5px" style={{ background: '#B6D137', padding: "0",height:"10px" }}></td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                        </tbody>
                                    </Table>
                                </Col>
                        </Row>
                    }
                    {
                        showFullActionWithRisk &&
                        <Row>
                            <Col>
                                <Table bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                    <tbody style={{ textAlign: 'center' }}>
                                        {riskModel.riskList.filter(x => x.isActive == true).length > 0 &&
                                            riskModel.riskList.filter(x => x.isActive == true).map((risk, pidx) =>
                                                <>
                                                    {
                                                        <>
                                                            <tr key={uuid()}>
                                                                <td rowSpan="2" style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#008436', color: 'white', width: "10%" }} className="rotate"> RISK </td>
                                                                <td style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: 'white', color: 'black', width: "90%", fontSize: "18px" }}> {risk.name} </td>
                                                            </tr>
                                                            <tr key={uuid()}>
                                                                <td style={{ wordBreak: "break-word", fontWeight: "300", textAlign: 'left', background: 'white', color: 'black', width: "90%", fontSize: "15px" }}> {risk.description} </td>
                                                            </tr>
                                                            <tr key={uuid()}>
                                                                <td rowSpan="1" style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#FFCE18', color: '#008436', width: "10%" }} className="rotate"> ACTIONS </td>
                                                                <td style={{ wordBreak: "break-word", textAlign: 'left', background: 'white', color: 'black', width: "100%", fontSize: "14px" }}>
                                                                    <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                        <thead>
                                                                            <tr style={{ background: '#FFCE18' }}>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '5%', textAlign: 'center', color: "black" }}> ID </th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Due Date</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Item</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '24%', textAlign: 'center', color: "black" }}> Action Detail</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '12%', textAlign: 'center', color: "black" }}> Land Units Action Applies To</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '8%', textAlign: 'center', color: "black" }}> Existing Action</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '9%', textAlign: 'center', color: "black" }}> Category</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Regulatory Requirement</th>
                                                                                <th colSpan="1" rowSpan="1" style={{ width: '6%', textAlign: 'center', color: "black" }}> View</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ textAlign: 'center' }}>
                                                                            <>
                                                                                {riskModel.actionList.filter(x => x.activityRiskId == risk.id).length > 0 &&
                                                                                    riskModel.actionList.filter(x => x.activityRiskId == risk.id).map((action, pidx) =>
                                                                                        <>
                                                                                            {
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.id} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.dueDateText} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.itemName} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.title} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.landUnits} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.existingActionText} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.categoryText} </td>
                                                                                                        <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.regulatoryRequirementDescription} </td>
                                                                                                        <td style={{ textAlign: 'left' }}><button type="button" id={"view" + action.id}
                                                                                                            onClick={(itemId, actionId, routePath, isWholeFarmAction) => handleActionView(action.itemId, action.id, action.routePath, action.isWholeFarmAction)}
                                                                                                            className="btn btn-primary btn-sm">
                                                                                                            View
                                                                                                        </button> </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    )}
                                                                            </>

                                                                        </tbody>
                                                                    </Table>
                                                                </td>
                                                            </tr>
                                                            <tr key={uuid()}>
                                                                <td colSpan="2" height="5px" style={{ background: '#B6D137', padding: "0", height: "10px" }}></td>
                                                            </tr>
                                                        </>
                                                    }
                                                </>
                                            )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }
                    {
                        showActionsOnly && 
                        <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr style={{ background: '#FFCE18' }}>
                                    <th colSpan="1" rowSpan="1" style={{ width: '5%', textAlign: 'center', color: "black" }}> ID </th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Due Date</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Item</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '30%', textAlign: 'center', color: "black" }}> Action Detail</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '12%', textAlign: 'center', color: "black" }}> Land Units Action Applies To</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '8%', textAlign: 'center', color: "black" }}> Existing Action</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '9%', textAlign: 'center', color: "black" }}> Category</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center', color: "black" }}> Regulatory Requirement</th>
                                    <th colSpan="1" rowSpan="1" style={{ width: '5%', textAlign: 'center', color: "black" }}> View</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                <>
                                    {riskModel.actionList.length > 0 &&
                                        riskModel.actionList.map((action, pidx) =>
                                            <>
                                                {
                                                    <>
                                                        <tr>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.id} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.dueDateText} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.itemName} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.title} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.landUnits} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.existingActionText} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.categoryText} </td>
                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {action.regulatoryRequirementDescription} </td>
                                                            <td style={{ textAlign: 'left' }}><button type="button" id={"view" + action.id}
                                                                onClick={(itemId, actionId, routePath, isWholeFarmAction) => handleActionView(action.itemId, action.id, action.routePath, action.isWholeFarmAction)}
                                                                className="btn btn-primary btn-sm">
                                                                View
                                                            </button> </td>
                                                        </tr>
                                                    </>
                                                }
                                            </>
                                        )}
                                </>

                            </tbody>
                        </Table>
                    }
                    {/*<table style={{ tableLayout: 'fixed', width: '100%', display: "none" }} ref={tableRef}>*/}
                    {/*    <thead style={{ background: 'darkgrey', textAlign: 'center' }}>*/}
                    {/*        <tr>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Due Date </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Details </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Priority  </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Contaminat(s) </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Page </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Item Type </th>*/}
                    {/*            <th colSpan="1" rowSpan="2" style={{ width: '10%' }}> Cost </th>*/}
                    {/*            <th colSpan="2" style={{ width: '20%' }}> Direct Corelated Against </th>*/}
                    {/*        </tr>*/}
                    {/*        <tr>*/}
                    {/*            <th style={{ width: '10%' }}> Land Units </th>*/}
                    {/*            <th style={{ width: '10%' }}> Farming Activities </th>*/}
                    {/*        </tr>*/}
                    {/*    </thead>*/}
                    {/*    <tbody style={{ textAlign: 'center' }}>*/}
                    {/*        {*/}
                    {/*            actionSummary.map(item =>*/}
                    {/*                <tr key={item.id}>*/}
                    {/*                    <td> {item.dueDate} </td>*/}
                    {/*                    <td> {item.title} </td>*/}
                    {/*                    <td> {item.priority} </td>*/}
                    {/*                    <td> {item.contaminants} </td>*/}
                    {/*                    <td> {item.page} </td>*/}
                    {/*                    <td> {item.itemTypeName} </td>*/}
                    {/*                    <td> {item.actualCost} </td>*/}
                    {/*                    <td> {item.lu} </td>*/}
                    {/*                    <td> {item.farmingActivities} </td>*/}
                    {/*                </tr>*/}
                    {/*            )}*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                </CardBody>
            </Collapse>
        </Card>
    )
}
export default ActionSummary