import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input, Card, CardHeader, CardBody, Collapse, Spinner } from 'reactstrap';
import { flushSync } from "react-dom";
import { FaChevronRight, FaChevronUp, FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import './MahingaKai.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import ItemCommonTop from '../ItemPopup/ItemCommonTop';
import ItemCommonBottom from '../ItemPopup/ItemCommonBottom';
import ItemFooter from '../ItemPopup/ItemFooter';
import Action from '../Action/Action';
import MapView from '../Map/MapView';
import NonMapView from '../ItemsView/NonMapView';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';
import Validate from '../Validate/Validate';
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import { useNavigate, useLocation } from "react-router-dom";
import ReadMoreContent from '../Utilities/Ui/ReadMore';
import ItemTypeListView from '../ItemsView/ItemTypeListView';
import Documents from '../Uploads/Documents';
import ItemTypeListViewForMap from '../ItemsView/ItemTypeListViewForMap';

const MahingaKai = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [isOpen, setIsOpen] = useState(true); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const [recreationalRiskPresent, setRecreationalRiskPresent] = useState(false); const [threatenedSpeciesRiskPresent, setThreatenedSpeciesRiskPresent] = useState(false);
    const [isIconUp, setIsIconUp] = useState(true); const [saving, setSaving] = useState(''); const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };
    const { state } = useLocation();
    const navigate = useNavigate();
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [loading, setLoading] = useState(true);
    const [mahingaKai, setMahingaKai] = useState({
        outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], Section: 'Community & Cultural Significance', SectionName: 'Sites and Species of Significance', SectionObj: {}, isWizardCompleted: false
        , catchmentOverview: {}
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0 })
    const [typeid, setTypeId] = useState(0);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 15, polyType: '', itemsEditable: false, highlighteditemId: 0, isClear: false, showLabel: false
    });
    const [itemPopup, setItemPopup] = useState({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '' });
    const [itemPopupHeader, setItemPopupHeader] = useState({ page: 'Community & Cultural Significance', lmu: '', LMUId: 0, itemTypeName: '', itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", itemTypeDescription: '' });
    const [itemPopupMaptype, setItemPopupMaptype] = useState({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', });

    const [itemPopupBiodiversity, setItemPopupBiodiversity] = useState({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
    const [itemPopupRisk, setItemPopupRisk] = useState({ isRisk: 'No', riskComment: '', isMitigated: false, mitigatedComment: '' });
    const [itemPopupAchievements, setItemPopupAchievements] = useState({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });

    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0 });
    const [actionPopup, setActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });

    const [toggleItemTypeListState, setToggleItemTypeListState] = useState({ isOpen: false, isIconUp: false });
    const [toggleItemTypeState, setToggleItemTypeState] = useState({ itemTypeId: 0, isOpen: false, isIconUp: false });
    const [toggleItemState, setToggleItemState] = useState({ itemId: 0, isOpen: false, isIconUp: false });
    const [culturalValuesDescription, setCulturalValuesDescription] = useState('');
    const [tangataWhenua, setTangataWhenua] = useState('');
    const [significantSpecies, setSignificantSpecies] = useState('');
    const [threatenedSpecies, setThreatenedSpecies] = useState('');
    const [recreationalSites, setRecreationalSites] = useState('');
    const [iwiHapuRunanga, setIwiHapuRunanga] = useState('');
    const [significantSites, setSignificantSites] = useState('');
    const [savingCatchmentContext, setSavingCatchmentContext] = useState('');
    const [yesNoList, setYesNoList] = useState([]);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/mahingakai/GetMahingaKai/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setMahingaKai({
                ...mahingaKai, outlineList: response.data.outlineList, itemList: response.data.itemList, itemTypes: response.data.itemTypes, uniqueItemTypes: response.data.uniqueItemTypes,
                SectionObj: response.data.section, isWizardCompleted: response.data.isFWFPCompleted, catchmentOverview: response.data.catchmentOverview
            });
            setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: !response.data.isFWFPCompleted, isIconUp: !response.data.isFWFPCompleted })
            setCulturalValuesDescription(response.data.catchmentOverview.culturalValues);
            setTangataWhenua(response.data.catchmentOverview.tangataWhenua);
            setSignificantSpecies(response.data.catchmentOverview.significantSpecies);
            setIwiHapuRunanga(response.data.catchmentOverview.iwiHapuRunanga);
            setSignificantSites(response.data.catchmentOverview.significantSites);
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel, polyType: '' });
            setYesNoList(response.data.yesNoList);
            setRecreationalSites(response.data.catchmentOverview.recreationalSites);
            setThreatenedSpecies(response.data.catchmentOverview.threatenedSpecies);
            setRecreationalRiskPresent(response.data.recreationalRiskPresent);
            setThreatenedSpeciesRiskPresent(response.data.threatenedSpeciesRiskPresent);
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            setLoading(false);

            if (state != null) {
                const { itemId, actionId, navigateFrom } = state;
                navigate(state.pathname, { replace: true });
                if (navigateFrom == "Actions") {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
                else {
                    editMapItemFromNavigation(itemId, response.data, navigateFrom)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, mahingaKai) => {
        const itemDetails = mahingaKai.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    function refreshPage() {
        setLoading(true);
        setMahingaKai({ ...mahingaKai, outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [] });
        OnPageload();
    }

    function resetValues() {
        setItemPopup({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '' });
        setItemPopupHeader({ page: 'Community & Cultural Significance', lmu: '', itemTypeName: '', itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", itemTypeDescription: '' });
        setItemPopupMaptype({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', });
        setItemPopupBiodiversity({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
        setItemPopupRisk({ isRisk: 'No', riskComment: '', isMitigated: false, mitigatedComment: '' });
        setItemPopupAchievements({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });
        setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
        setMapDetails({ ...mapDetails, isClear: true, polyType: '' });
    }

    /* Item select  and Clear*/
    const handleItemClick = (event, typeid, polyType) => {
        setTypeId(typeid);
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
    }

    function handleClearSelection() {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
    }

    /*ItemPopup value change Handle*/
    function handleItemPopupHeaderChange(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupHeader({ ...itemPopupHeader, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupBiodiversity(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupRisk(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupAchievements(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupAchievements({ ...itemPopupAchievements, [name]: type == 'checkbox' ? checked : value });
    }

    const handleLMUSelectChange = (event) => {
        const { name, value, checked, type } = event.target;
        setLargeEnterpriseDetails({ ...largeEnterpriseDetails, [name]: type == 'checkbox' ? checked : value });
    }

    /*Item Add and Submit*/
    function addMapItem(mapItem, maptype) {
        let path, area = 0.0, perimeter = 0.0, coordinates = '';
        var selectedItemType = mahingaKai.itemTypes.filter(item => item.id == typeid)[0];
        const ItemTypeName = selectedItemType.name;
        const isBiodiversityReq = selectedItemType.isBiodiversityReq == true ? true : false;
        const lmuId = largeEnterpriseDetails.selectedLMUId;
        if (maptype == 'marker') {
            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);
        }
        else if (maptype == 'polygon') {
            path = mapItem.getPath();
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'polyline') {
            path = mapItem.getPath();
            area = 0;
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: 0, itemId: 0, markertype: maptype });
        setItemPopupMaptype({ ...itemPopupMaptype, markertype: maptype, area: area, perimeter: perimeter, coordinates: coordinates });
        setItemPopupHeader({ ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: typeid, LMUId: lmuId, Name: '', isDragged: false, Description: '', itemTypeDescription: selectedItemType.itemDescription });
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq });
        setItemPopupAchievements({ ...itemPopupAchievements, isExpand: false });
    }

    const handleItemSubmit = async (event) => {
        setSaving(true);
        let form = {
            Id: itemPopup.id,
            MapMarkerType: itemPopup.markertype,
            FarmId: itemPopup.farmId,
            ItemId: itemPopup.itemId,
            Name: itemPopupHeader.Name,
            IsDragged: itemPopupHeader.isDragged,
            Description: itemPopupHeader.Description,
            Colour: itemPopupHeader.color,
            LMUId: itemPopupHeader.LMUId,
            ItemTypeId: itemPopupHeader.itemTypeId,
            Area: itemPopupMaptype.area,
            Length: itemPopupMaptype.perimeter,
            MapCoordinates: itemPopupMaptype.coordinates,
            IsAddsToBioDiversity: itemPopupBiodiversity.isItemToBiodiversity,
            BioDiversityComment: itemPopupBiodiversity.biodiversityComment,
            IsItemHaveAnyRisk: itemPopupRisk.isRisk,
            RiskDescription: itemPopupRisk.riskComment,
            IsMitigated: itemPopupRisk.isMitigated,
            MitigatedDescription: itemPopupRisk.mitigatedComment,
            IsAchievementOfNote: itemPopupAchievements.isAchievementsNotes,
            AchievementOfNoteComments: itemPopupAchievements.achievementsComment,
            userId: userId
        }
        if (!Validate(form)) {
            try {
                const response = await axios.post(
                    '/api/mahingakai/UpdateMahingaKai/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data != null) {
                    await refreshItemDetails();
                    setSaving(false);
                    toast.success("Item details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    handleItemPopupClose();

                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        else {
            setSaving(false);
        }
    }

    const refreshItemDetails = async () => {
        const response = await axios.get(
            '/api/mahingakai/GetMahingaKai/', {
            params: {
                farmId: farmId
            }
        }
        );
        setMahingaKai({
            ...mahingaKai, itemList: response.data.itemList
        });
    }

    function handleItemPopupClose() {
        resetValues();
    }

    /*Item Edit and Submit*/
    function editMapItem(mapItem, itemId, isDragged) {
        const itemDetails = mahingaKai.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = mahingaKai.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = mahingaKai.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;
        if (isDragged) {
            coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
        }
        const isBiodiversityReq = true;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: itemDetails.area, perimeter: itemDetails.length, coordinates: coordinates });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, Name: itemDetails.name, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                isDragged: isDragged, Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });
            setItemPopupRisk({ isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription, isMitigated: itemDetails.isMitigated, mitigatedComment: itemDetails.mitigatedDescription });
            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: false });
        }
    }

    const editMapItemFromNavigation = (itemId, mahingaKai, navigateFrom) => {
        const itemDetails = mahingaKai.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = mahingaKai.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = mahingaKai.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;
        const isBiodiversityReq = true;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: [], farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: itemDetails.area, perimeter: itemDetails.length, coordinates: coordinates });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, Name: itemDetails.name, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });
            setItemPopupRisk({ isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription, isMitigated: itemDetails.isMitigated, mitigatedComment: itemDetails.mitigatedDescription });
            let isExpand = false;
            if (navigateFrom == "Achievements")
                isExpand = true
            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: isExpand });
        }
    }

    function handleItemEditPopup(itemId) {
        editMapItem([], itemId, false)
    }

    /*Item Delete*/
    function handleItemDeletePopup(itemId) {
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }

    function handleItemDeletePopupClose() {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0 });
    }

    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        setItemDeleteSaving(true);
        try {
            const response = await axios.get(
                '/api/mahingakai/DeleteItem/', {
                params: {
                    itemId: itemid, userId: userId
                }
            }
            );
            if (response.data != null) {
                await refreshItemDetails();
                setItemDeleteSaving(false);
                handleItemDeletePopupClose();
                handleItemPopupClose();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /*Item- Action Popup handle*/
    function handleOpenActionPopup(itemId, actionId) {
        const itemDetails = mahingaKai.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    function handleActionClose() {
        setActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*Item- MaintenancePlan Popup handle*/
    function handleOpenMaintenancePlanPopup(itemId) {
        const itemDetails = mahingaKai.itemList.filter(items => items.itemId == itemId)[0];
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: true, itemId: itemId, itemName: itemDetails.name, itemTypeId: itemDetails.itemTypeId, actionId: 0 });
    }

    function handleMaintenancePlanClose() {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*handle Toggle for nonMap View*/
    const handleItemTypeListTogole = (isOpen, isIconUp) => {
        setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: isOpen, isIconUp: isIconUp })
    }

    const handleItemTypeTogole = (itemTypeId, isOpen, isIconUp,) => {
        setToggleItemTypeState({ ...toggleItemTypeState, itemTypeId: itemTypeId, isOpen: isOpen, isIconUp: isIconUp })
    }

    const handleItemTogole = (itemId, isOpen, isIconUp) => {
        setToggleItemState({ ...toggleItemState, itemId: itemId, isOpen: isOpen, isIconUp: isIconUp })
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    /*Avoid unwanted page refresh*/
    const mapView = useMemo(() => {
        return (
            <MapView width={toggleSwitch == 'map' ? '80vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                itemModel={mahingaKai}
                mapDetails={mapDetails}
                addMapItem={addMapItem}
                largeEnterpriseDetails={largeEnterpriseDetails}
                editMapItem={editMapItem} >
            </MapView>
        )
    }, [mahingaKai, mapDetails, largeEnterpriseDetails, toggleSwitch])

    const itemTypeListView = useMemo(() => {

        return (
            <ItemTypeListViewForMap Section={mahingaKai.Section} isWizardCompleted={mahingaKai.isWizardCompleted}
                itemTypesList={mahingaKai.itemTypes}
                toggleItemTypeListState={toggleItemTypeListState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemTypeListTogole={handleItemTypeListTogole}
            >
            </ItemTypeListViewForMap>
        )
    }, [mahingaKai, largeEnterpriseDetails, mahingaKai.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, mahingaKai.outlineCenter])

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }

    const handleCatchmentContextSave = async (e) => {
        e.preventDefault()
        setSavingCatchmentContext(true);
        let form = {
            CulturalValuesDescription: culturalValuesDescription,
            FarmId: farmId,
            UserId: userId,
            TangataWhenua: tangataWhenua,
            SignificantSites: null,
            SignificantSpecies: null,
            IwiHapuRunanga: null,
            ThreatenedSpecies: threatenedSpecies == '' ? 'No' : threatenedSpecies,
            RecreationalSites: recreationalSites == '' ? 'No' : recreationalSites,
        }

        try {
            const response = await axios.post(
                '/api/catchmentContext/SaveCatchmentData/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });

            setTimeout(() => {
                setSavingCatchmentContext(false);
            }, 1000)
            toast.success("Catchment Details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });

        } catch (err) {
            setTimeout(() => {
                setSavingCatchmentContext(false);
            }, 1000)

            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const nonMapView = useMemo(() => {
        return (
            <NonMapView itemModel={mahingaKai} isWizardCompleted={mahingaKai.isWizardCompleted}
                toggleItemTypeListState={toggleItemTypeListState}
                toggleItemTypeState={toggleItemTypeState}
                toggleItemState={toggleItemState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemEditPopup={handleItemEditPopup}
                handleItemDeletePopup={handleItemDeletePopup}
                handleOpenActionPopup={handleOpenActionPopup}
                sectionObj={mahingaKai.SectionObj}
                largeEnterpriseDetails={largeEnterpriseDetails}
                handleLMUSelectChange={handleLMUSelectChange}
                handleItemTypeListTogole={handleItemTypeListTogole}
                handleItemTypeTogole={handleItemTypeTogole}
                handleItemTogole={handleItemTogole}
                handleItemHighlight={handleItemHighlight}
            ></NonMapView>
        )
    }, [mahingaKai, largeEnterpriseDetails, mahingaKai.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState])

    const handleWizardChangedMahingakai = async (section) => {
        let form = {
            CulturalValuesDescription: culturalValuesDescription,
            FarmId: farmId,
            UserId: userId,
            TangataWhenua: tangataWhenua,
            SignificantSites: null,
            SignificantSpecies: null,
            IwiHapuRunanga: null,
            ThreatenedSpecies: threatenedSpecies == '' ? 'No' : threatenedSpecies,
            RecreationalSites: recreationalSites == '' ? 'No' : recreationalSites,
        }
        try {
            const response = await axios.post(
                '/api/catchmentContext/SaveCatchmentData/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.status == 200) {
                handleWizardChanged(section);
            }
        } catch (err) {
            handleWizardChanged(section);
            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
        
    }
    const handleWizardChangedMahingakaiBack = async (section) => {
        let form = {
            CulturalValuesDescription: culturalValuesDescription,
            FarmId: farmId,
            UserId: userId,
            TangataWhenua: tangataWhenua,
            SignificantSites: null,
            SignificantSpecies: null,
            IwiHapuRunanga: null,
            ThreatenedSpecies: threatenedSpecies == '' ? 'No' : threatenedSpecies,
            RecreationalSites: recreationalSites == '' ? 'No' : recreationalSites,
        }
        try {
            const response = await axios.post(
                '/api/catchmentContext/SaveCatchmentData/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.status == 200) {
                handleWizardChangedBack(section);
            }
        } catch (err) {
            handleWizardChangedBack(section);
            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("Saving Catchment Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }

    }
    const handleThreatenedSpeciesValueChange = (event) => {
        if (threatenedSpeciesRiskPresent) {
            toast.error("Sorry, can't change this now as the risk has been created and at least one action raised against it.", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        else {
            const { name, value, checked, type } = event.target;
            setThreatenedSpecies(value);
        }
    }
    const handleRecreationalSitesValueChange = (event) => {
        if (recreationalRiskPresent) {
            toast.error("Sorry, can't change this now as the risk has been created and at least one action raised against it.", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        else {
            const { name, value, checked, type } = event.target;
            setRecreationalSites(value);
        }
    }
    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{mahingaKai.SectionName}</h2></Col>
                {mahingaKai.isWizardCompleted != true && !loading &&
                    <Col>
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedMahingakai(mahingaKai.Section)}>Next <FaChevronRight /> </Button>        
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                                    <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(mahingaKai.Section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedMahingakaiBack(mahingaKai.Section)}><FaChevronLeft /> Back  </Button>
                        </>
                    </Col>
                }
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <Col>
                                <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                            </Col>
                        </div>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col id='mapView'>
                            <Row className="map-layer">
                                <Col>
                                    <Row>
                                        <Col sm="7"></Col>
                                        <Col sm="5">
                                            <div className="map-item-edit-div">
                                                <Label className="map-checkbox-label1">Edit Item</Label>
                                                <Input className="map-item-edit-checkbox1" style={{}} type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Show Label</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                             </div>
                                        </Col>
                                    </Row>
                                    {mapView}
                                </Col>
                            </Row>
                        </Col>
                        <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "20vw" }}>
                            <br />
                            {itemTypeListView}
                            <br />
                        </Col>
                        <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block' }}>
                            <>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <h4>Mapping of Culturally or Community Significant Sites and Species</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label className="info-text">Please indicate on the map any SITES or SPECIES which are significant to tangata whenua and/or threatened, either on the property, or down stream from the property.
                                                    <br />
                                                    These can include Taonga or threatened Species, or sites of high cultural value of community significance such as Wahi Tapu, fish spawning areas, drinking water protection zones, or recreational sites.
                                                </Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <ItemTypeListView Section={mahingaKai.Section}
                                    itemTypesList={mahingaKai.itemTypes}
                                    toggleItemTypeListState={toggleItemTypeListState}
                                    handleClearSelection={handleClearSelection}
                                    handleItemClick={handleItemClick}
                                    handleItemTypeListTogole={handleItemTypeListTogole}
                                >
                                </ItemTypeListView>
                                <br/>
                                {nonMapView}
                                <Card className="my-4">
                                    <CardHeader className="item-header card-header-click card-header-outline" onClick={toggle}>
                                        <Row>
                                            <Col sm="11">
                                                <h5>Sites and Species of Significance</h5>
                                            </Col>
                                            <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen}>
                                        <CardBody>
                                            <Row className="card-container">
                                                <Col sm="12">
                                                    <Row>
                                                        <Col>
                                                            <Label className="info-text" style={{ fontSize: "13px" }}>
                                                                Based on the farming activities carried out on the property, do you believe there is, or has the potential to be.
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginBottom:"10px"}}>
                                                        <Col sm="9">
                                                            <div className="text-container" style={{ }}>
                                                                <Label className="info-text" style={{ fontSize: "12px", marginLeft: "15px" }}>
                                                                    <b>• Does the property have any threatened species and/or species significant to tangata whenua or the community</b>
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col sm="3">
                                                            <Input type="select" onChange={handleThreatenedSpeciesValueChange} name="ThreatenedSpecies" id="ThreatenedSpecies" value={threatenedSpecies} >
                                                                {yesNoList != undefined && yesNoList.map(Option =>
                                                                    <option value={Option.id}>{Option.name}</option>
                                                                )}
                                                            </Input>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="9">
                                                            <div className="text-container" style={{}}>
                                                                <Label className="info-text" style={{ fontSize: "12px", marginLeft: "15px" }}>
                                                                    <b>• Does the property have any recreational site(s) and/or site(s) significant to tangata whenua or the community</b>
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col sm="3">
                                                            <Input type="select" onChange={handleRecreationalSitesValueChange} name="RecreationalSites" id="RecreationalSites" value={recreationalSites} >
                                                                {yesNoList != undefined && yesNoList.map(Option =>
                                                                    <option value={Option.id}>{Option.name}</option>
                                                                )}
                                                            </Input>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col sm="6">
                                                            <div className="text-container" style={{ height: "55px" }}>
                                                                <Label className="info-text" style={{ fontSize: "13px" }}>
                                                                    Name of the relevant Tangata Whenua
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <textarea rows="1" id="tangataWhenua" className="form-control"
                                                                name="tangataWhenua"
                                                                value={tangataWhenua}
                                                                onChange={(e) => setTangataWhenua(e.target.value)}></textarea>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col sm="6">
                                                            <div className="text-container" style={{ height: "55px" }}>
                                                                <Label className="info-text" style={{ fontSize:"13px"}}>
                                                                 Outline the Cultural Significance of the catchment, including Te Ao Maori values where appropriate
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <textarea rows="3" id="culturalValuesDescription" className="form-control"
                                                                name="culturalValuesDescription"
                                                                value={culturalValuesDescription}
                                                                onChange={(e) => setCulturalValuesDescription(e.target.value)}></textarea>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col sm="10">

                                                </Col>
                                                <Col sm="2">
                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                        <>
                                                            {savingCatchmentContext ?
                                                                <Button style={{ float: "right" }} onClick={handleCatchmentContextSave}
                                                                    color="success" className="button-save"
                                                                    disabled
                                                                >
                                                                    <Row>
                                                                        <Col sm="8">
                                                                            <span>
                                                                                {' '}Saving
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <Spinner size="sm">
                                                                                Saving...
                                                                            </Spinner>
                                                                        </Col>
                                                                    </Row>
                                                                </Button>
                                                                :
                                                                <Button className="button-save" style={{ float: "right" }} onClick={handleCatchmentContextSave} color="success" size="sm">
                                                                    Save
                                                                </Button>
                                                            }
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                    
                                </Card>
                                <Documents refid={mahingaKai.SectionObj.id} uploadType="Section"
                                    infoId="my-tooltip-7" showInfo={true} infoMessage="Here, you can attach supporting documents that provide more information on your significant sites. Examples include a property map with significant sites identified, documentation from your council outlining a significant site, documentation from your local iwi, etc."
                                    open={false} showArrow={true}></Documents>
                            </>
                        </Col>
                    </Row>
                </div>
            }

            <Modal size="lg" isOpen={itemPopup.showItemPopup} backdrop={false} className="itemPopup">
                <Form>
                    <ModalHeader toggle={handleItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <ItemCommonTop itemPopupHeader={itemPopupHeader} itemPopupMaptype={itemPopupMaptype} itemPopupBiodiversity={itemPopupBiodiversity} itemPopupRisk={itemPopupRisk}
                            largeEnterpriseDetails={null} Section={mahingaKai.Section}
                            handleItemPopupHeaderChange={handleItemPopupHeaderChange}
                            handleItemPopupBiodiversity={handleItemPopupBiodiversity}
                            handleItemPopupRisk={handleItemPopupRisk}></ItemCommonTop>


                        <ItemCommonBottom itemPopup={itemPopup} itemPopupAchievements={itemPopupAchievements}
                            handleItemPopupAchievements={handleItemPopupAchievements} Section={mahingaKai.Section}></ItemCommonBottom>
                    </ModalBody>

                    <ItemFooter itemPopup={itemPopup} Section={mahingaKai.Section} saving={saving}
                        handleItemDeletePopup={handleItemDeletePopup}
                        handleItemSubmit={handleItemSubmit}
                        handleItemPopupClose={handleItemPopupClose}
                        handleOpenActionPopup={handleOpenActionPopup}
                        handleOpenMaintenancePlanPopup={handleOpenMaintenancePlanPopup}>
                    </ItemFooter>
                </Form>
            </Modal>

            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    {itemDeletesaving ?
                        <>
                            <Button onClick={deleteItem} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={deleteItem} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            {actionPopup.showActionPopup && <Action redirectPage="/mahingakai"
                handleActionClose={handleActionClose} itemTypeId={actionPopup.itemTypeId} sectionName={mahingaKai.Section} sectionId={mahingaKai.itemTypes[0].sectionId} itemId={actionPopup.itemId} itemName={actionPopup.itemName}
                showActionPopupModal={actionPopup.showActionPopup} actionId={actionPopup.actionId} itemTypeSelect={mahingaKai.itemTypes} itemTypeDisabled={true}></Action>}

            {maintenancePlanPopup.showMaintenancePlanPopup && <MaintenancePlan handleMaintenancePlanClose={handleMaintenancePlanClose}
                showMaintenancePlanPopupModal={maintenancePlanPopup.showMaintenancePlanPopup}
                actionId={maintenancePlanPopup.actionId} itemId={maintenancePlanPopup.itemId} itemName={maintenancePlanPopup.itemName} itemTypeId={maintenancePlanPopup.itemTypeId}
                itemTypeSelect={mahingaKai.itemTypes} sectionName={mahingaKai.Section} redirectPage="/mahingakai">
            </MaintenancePlan>}
        </div>
    )
}
export default MahingaKai;