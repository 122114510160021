import { useState, useEffect } from 'react';
import { flushSync } from "react-dom";
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup, Spinner, Badge, Table } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { FaPlus, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../Ghg/Ghg.css';
import Photos from '../Uploads/Photos';
import uuid from 'react-uuid';
import moment from "moment";
import ActivityRiskFromSummary from './ActivityRiskFromSummary';
import './Action.css';
import Draggable, { DraggableCore } from 'react-draggable';
import { Tooltip as ReactTooltip } from "react-tooltip";

const createGroup = (groupName, options, setGmpValue) => {
    return {
        label: (() => {
            return (
                <div
                    onClick={() =>
                        setGmpValue(value =>
                            value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
                        )
                    }
                >
                    {groupName}
                </div>
            );
        })(),
        options: options
    };
};

const Option = (props) => {//Select Significant Sites and Species
    return (
        <div>
            <components.Option {...props}>
                {
                    props.selectProps.placeholder == 'Select Contaminants' && <div>
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />{" "}
                        <label>{props.label}</label>
                        {props.data.isCatchmentContaminant && <Badge style={{ marginLeft: '10px' }} color="warning">CP</Badge>}
                    </div>
                }
                {
                    props.selectProps.placeholder == 'Select Significant Sites and Species' && <div>
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />{" "}
                        <label>{props.label} ({props.data.itemTypeName})</label>
                    </div>
                }
                {
                    props.selectProps.placeholder == 'Select Inherent Risks' && <div>
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />{" "}
                        <label>{props.label}</label>
                    </div>
                }
            </components.Option>
        </div>
    );
};

const ActionFromSummary = ({ width, height, refreshRiskSummary, actionId, itemTypeSelect, showActionPopupModal,pageDisabled, itemTypeDisabled, itemId, itemName, sectionName, itemTypeId, redirectPage, handleActionClose, sectionId, wholeFarmAction, itemList, selectedLUId, isGHGNutrient ,selectedRiskId}) => {
    const roleName = localStorage.getItem("roleName"); const [pagesList, setPagesList] = useState([]);
    const isCreateNewAction = localStorage.getItem("isCreateNewAction");
    const isSubmitEvidenceCloseAction = localStorage.getItem("isSubmitEvidenceCloseAction");
    const [ghgNutrient, setGHGNutrient] = useState(isGHGNutrient == undefined ? false : isGHGNutrient);
    const [loading, setLoading] = useState(true); const [activityRiskId, setActivityRiskId] = useState(0); const [activityRisk, setActivityRisk] = useState('');
    const [lmuId, setLMUId] = useState(0); const [showActivityRisk, setShowActivityRisk] = useState(false);
    const [inherentRisksObj, setInherentRisksObj] = useState({ inherentRisks: [], inherentRisksList: [] })
    const [significantSpeciesObj, setSignificantSpeciesObj] = useState({ significantSpecies: [], significantSpeciesList: [] })
    const [contaminantObj, setContaminantObj] = useState({ contaminants: [], contaminantList: [] })
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("auth")));
    const [formErrors, setFormErrors] = useState({});
    const [actionTypes, setActionTypes] = useState([]);
    const [submitDate, setSubmitDate] = useState(null);
    const [tempId, setTempId] = useState(actionId == undefined ? 0 : actionId);
    const [activityRiskModel, setActivityRiskModel] = useState({});
    const [farmAPTActionList, setFarmAPTActionList] = useState([]);
    const [activityRiskPopup, setActivityRiskPopup] = useState({
        activityId: 0, activityRiskId: 0, activityRiskText: '', recommendedActionId: 0, recommendedActionText: '', recommendedActionDescription: '', showActivityRisk: false, luId: '',
        enterpriseTypeId: 0, naActivityRisk: false
    });
    const [actionPopup, setActionPopup] = useState({
        type: itemTypeId, name: itemName, id: actionId, actionType: 0, title: '', description: '', itemId: itemId,
        regulatoryRequirement: '', catchmentPriority: '', significantEnvironmentBenfit: '', priority: '', estimatedCost: '', financialNotes: '',
        pastTargetOutlineReasons: '', closeOutDetail: '', isCloseout: false, createdByName: '', createdDate: null, activityRiskId: 0, luId: 0, farmEnterpriseId: 0, existingAction: false,
        category: '', recommendedActionId: 0, regulatoryRequirementDescription: '', pageId: sectionId
    }); const [savingAction, setSavingAction] = useState(''); const [savingActionCloseSave, setSavingActionCloseSave] = useState(false);
    let options = [];
    const [gmpOptions, setGmpOptions] = useState([]);
    const [gmpValue, setGmpValue] = useState([]);
    const [showActionPopup, setShowActionPopup] = useState(false);
    const [contaminantList, setContaminantList] = useState([]);
    const [contaminantSelectedValues, setContaminantSelectedValues] = useState('');
    const [contaminantListSelectedValues, setContaminantListSelectedValues] = useState([]);
    const [actionDueDate, setActionDueDate] = useState(null);
    const [uploadReqPopup, setUploadReqPopup] = useState({ showUploadReqPopup: false });
    const [actionCreatedOn, setActionCreatedOn] = useState('');
    const [isOngoingAction, setIsOngoingAction] = useState(false);
    const [isFarmOperational, setIsFarmOperational] = useState(false);
    const [actionDeletePopup, setActionDeletePopup] = useState({ showActionDeletePopup: false, id: 0 });
    const [wholeFarmInherentRisksList, setWholeFarmInherentRisksList] = useState([]);
    const [isDairyActivity, setIsDairyActivity] = useState(false);
    const [showPastTargetOutlineReasons, setShowPastTargetOutlineReasons] = useState(false);
    const OnPageload = async (e) => {
        try {
            actionId = actionId == undefined ? 0 : actionId;
            const response = await axios.get(
                '/api/action/GetActionForSummary/', {
                params: { Id: actionId, farmId: farmId, lmuId: (wholeFarmAction || ghgNutrient) ? selectedLUId.toString() : itemList.filter(x => x.itemId == itemId)[0].lmuIds, ItemAction: (wholeFarmAction || isGHGNutrient) ? false : true }
            }
            );
            if (response.data.status) {
                setPagesList(response.data.content.pages);
                setActionTypes(response.data.content.actionTypes);
                setContaminantList(response.data.content.contaminantList);
                setContaminantListSelectedValues(response.data.content.contaminants);
                setActivityRiskModel(response.data.content.activityRiskModel);
                setFarmAPTActionList(response.data.content.farmAPTActionList);
                setShowActivityRisk(true);
                for (var i = 0; i < response.data.content.goodFarmingPracticeGroups.length; i++) {
                    var goodFarmingPractices = response.data.content.goodFarmingPracticeList.filter(x => x.group1 == response.data.content.goodFarmingPracticeGroups[i].name);
                    options.push(createGroup(response.data.content.goodFarmingPracticeGroups[i].name, goodFarmingPractices, setGmpValue));
                }
                setGmpOptions(options);
                if (actionId != 0) {
                    if (wholeFarmAction) {
                        setActionPopup({
                            ...actionPopup, id: response.data.content.id, type: response.data.content.ghgItemTypeId, name: itemName, actionType: response.data.content.actionType, title: response.data.content.title,
                            description: response.data.content.description, regulatoryRequirement: response.data.content.regulatoryRequirement, catchmentPriority: response.data.content.catchmentPriority,
                            significantEnvironmentBenfit: response.data.content.significantEnvironmentBenfit, priority: response.data.content.priority, estimatedCost: response.data.content.estimatedCost,
                            financialNotes: response.data.content.financialNotes, pastTargetOutlineReasons: response.data.content.pastTargetOutlineReasons, closeOutDetail: response.data.content.closeOutDetail
                            , isCloseout: response.data.content.isCloseout, itemId: itemId, createdByName: response.data.content.createdByName, actualCost: response.data.content.actualCost,
                            actualCostNotes: response.data.content.actualCostNotes, createdDate: response.data.content.createdOn, activityRiskId: response.data.content.activityRiskId, luId: response.data.content.luId, farmEnterpriseId: response.data.content.farmEnterpriseId
                            , existingAction: response.data.content.existingAction, category: response.data.content.category, recommendedActionId: response.data.content.recommendedActionId,
                            regulatoryRequirementDescription: response.data.content.regulatoryRequirementDescription
                        });
                    }
                    else {
                        setActionPopup({
                            ...actionPopup, id: response.data.content.id, type: response.data.content.ghgItemTypeId, name: itemName, actionType: response.data.content.actionType, title: response.data.content.title,
                            description: response.data.content.description, regulatoryRequirement: response.data.content.regulatoryRequirement, catchmentPriority: response.data.content.catchmentPriority,
                            significantEnvironmentBenfit: response.data.content.significantEnvironmentBenfit, priority: response.data.content.priority, estimatedCost: response.data.content.estimatedCost,
                            financialNotes: response.data.content.financialNotes, pastTargetOutlineReasons: response.data.content.pastTargetOutlineReasons, closeOutDetail: response.data.content.closeOutDetail
                            , isCloseout: response.data.content.isCloseout, itemId: itemId, createdByName: response.data.content.createdByName, actualCost: response.data.content.actualCost,
                            actualCostNotes: response.data.content.actualCostNotes, createdDate: response.data.content.createdOn, activityRiskId: response.data.content.activityRiskId, farmEnterpriseId: response.data.content.farmEnterpriseId
                            , existingAction: response.data.content.existingAction, category: response.data.content.category, luId: response.data.content.luId, recommendedActionId: response.data.content.recommendedActionId,
                            regulatoryRequirementDescription: response.data.content.regulatoryRequirementDescription
                        });
                    }
                    var activityRiskObj = response.data.content.activityRiskModel.activityRiskList.filter(x => x.value == response.data.content.activityRiskId)[0];
                    if (activityRiskObj != undefined) {
                        setActivityRiskId(response.data.content.activityRiskId);
                        setActivityRisk(activityRiskObj.label);
                        setActivityRiskPopup({ ...activityRiskPopup, activityRiskId: response.data.content.activityRiskId, luId: response.data.content.luId, showActivityRisk:true })
                    }

                    setActionDueDate(getDateFromString(moment(response.data.content.dueDate).format("DD/MM/YYYY")));
                    if (response.data.content.submitDate != null) {
                        setSubmitDate(getDateFromString(moment(response.data.content.submitDate).format("DD/MM/YYYY")));
                    }
                    setGmpValue(response.data.content.goodFarmingPractices);
                    setActionCreatedOn(moment(response.data.content.createdOn).format("DD/MM/YYYY"));
                    if (response.data.content.actionType == 46) {
                        setIsOngoingAction(true);
                    }
                    else {
                        setIsOngoingAction(false);
                    }
                }
                else {
                    setActionPopup({
                        ...actionPopup, type: itemTypeId, name: itemName, id: actionId, actionType: parseInt(response.data.content.actionTypes[0].id), title: '', description: '', actionType: 0,
                        regulatoryRequirement: 'Yes', catchmentPriority: 'Yes', significantEnvironmentBenfit: 'Yes', priority: '1', estimatedCost: '', financialNotes: '', closeOutDetail: ''
                        , isCloseout: false, itemId: itemId, createdByName: user.fullName, actualCost: '', actualCostNotes: '', existingAction: false, category: '', regulatoryRequirementDescription: '',
                        activityRiskId: selectedRiskId, luId: selectedLUId.toString()
                    });
                    var activityRiskObj = response.data.content.activityRiskModel.activityRiskList.filter(x => x.value == selectedRiskId)[0];
                    if (activityRiskObj != undefined) {
                        setActivityRiskId(selectedRiskId);
                        setActivityRisk(activityRiskObj.label);
                        setActivityRiskPopup({ ...activityRiskPopup, activityRiskId: selectedRiskId, luId: selectedLUId.toString(), showActivityRisk: true })
                    }
                    setActionCreatedOn(getParsedDate1(new Date()));
                }

                setShowActionPopup(showActionPopupModal);
                setIsFarmOperational(response.data.content.isOperational);
                if (document.getElementById('btnActionSave') != undefined) {
                    document.getElementById('btnActionSave').style.display = "block";
                }
                if (document.getElementById('btnActionCloseSave') != undefined) {
                    document.getElementById('btnActionCloseSave').style.display = "block";
                }
            }

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleContaminantsChange = (e) => {
        setContaminantListSelectedValues('');
        var contaminantSelectedValues = '';
        setContaminantListSelectedValues(e);
        e.forEach(function (element) {
            if (contaminantSelectedValues == '') {
                contaminantSelectedValues = element.value;
            }
            else {
                contaminantSelectedValues = contaminantSelectedValues + ',' + element.value;
            }
        });
        setContaminantSelectedValues(contaminantSelectedValues);
    }

    useEffect(() => {
        setLoading(true);
        if (wholeFarmAction || ghgNutrient) {
            setLMUId(selectedLUId);
        }
        else {
            setLMUId(itemList.filter(x => x.itemId == itemId)[0].lmuIds);
        }
        OnPageload();
    }, [])

    function handleInputChange(event) {
        const { name, value, checked, type } = event.target;
        setActionPopup({ ...actionPopup, [name]: type == 'checkbox' ? checked : value });
        if (name == 'actionType') {
            if (value == '45') {
                setIsOngoingAction(false);
            }
            else {
                setIsOngoingAction(true);
            }

        }
    }

    const handleActionSave = async (e) => {
        e.preventDefault();
        setSavingAction(true);
        setFormErrors({});
        var gmpSelectedValues = '';
        gmpValue.forEach(function (element) {
            if (gmpSelectedValues == '') {
                gmpSelectedValues = element.value.toString();
            }
            else {
                gmpSelectedValues = gmpSelectedValues + ',' + element.value.toString();
            }
        });
        if (actionPopup.actionType == 0) {
            actionPopup.actionType = actionTypes[0].id;
        }
        const errors = {};
        if (parseInt(actionPopup.type) == 0) {
            errors.type = "Please select type";
        }
        if (!actionDueDate && actionPopup.actionType == '45') {
            errors.actionDueDate = "Please enter action due date";
        }
        if (!actionPopup.title) {
            errors.title = "Please enter action title";
        }
        if (!actionPopup.description) {
            errors.description = "Please enter action details";
        }
        if (actionPopup.activityRiskId == 0) {
            errors.activityRisk = "Please choose an activity risk";
        }
        if (actionPopup.pageId == 0) {
            errors.page = "Please choose a page";
        }
        if (actionPopup.category == '') {
            errors.category = "This is mandatory";
        }
        else {
            if (actionPopup.category == 'R') {
                if (actionPopup.whatRegulatoryRequirementIsBeingMetWithThisAction == '') {
                    errors.whatRegulatoryRequirementIsBeingMetWithThisAction = "This is mandatory";
                }
            }
        }
        var pageObj = pagesList.filter(x => x.id == actionPopup.pageId);
        if (pageObj.length > 0) {
            var pageName = pageObj[0].name;
            if (pageName == "Nutrients") {
                actionPopup.pageId = null;
            }
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            var section = null;
            if (wholeFarmAction) {
                section = actionPopup.pageId
            }
            var luId = actionPopup.luId;
            let inherentRisks = '';
            if (Array.isArray(inherentRisksObj.inherentRisks)) {
                let inherentRisksArr = [];
                inherentRisksObj.inherentRisks.forEach(risk => {
                    inherentRisksArr.push(risk.value);
                })
                inherentRisks = inherentRisksArr.join(",");
            } else {
                inherentRisks = inherentRisksObj.inherentRisks.toString();
            }

            let significantSpecies = '';
            if (Array.isArray(significantSpeciesObj.significantSpecies)) {
                let significantSpeciesArr = [];
                significantSpeciesObj.significantSpecies.forEach(risk => {
                    significantSpeciesArr.push(risk.value);
                })
                significantSpecies = significantSpeciesArr.join(",");
            } else {
                significantSpecies = significantSpeciesObj.significantSpecies.toString();
            }

            let contaminants = '';
            if (Array.isArray(contaminantObj.contaminants)) {
                let contaminantsArr = [];
                contaminantObj.contaminants.forEach(risk => {
                    contaminantsArr.push(risk.value);
                })
                contaminants = contaminantsArr.join(",");
            } else {
                contaminants = contaminantObj.contaminants.toString();
            }

            let form = {
                Id: actionPopup.id, ItemId: actionPopup.itemId, Title: actionPopup.title, Description: actionPopup.description,
                ActionType: parseInt(actionPopup.actionType), DueDate: actionPopup.actionType == '45' ? getParsedDate(actionDueDate) : null,
                RegulatoryRequirement: actionPopup.regulatoryRequirement, CatchmentPriority: actionPopup.catchmentPriority,
                SignificantEnvironmentBenfit: actionPopup.significantEnvironmentBenfit, Priority: parseInt(actionPopup.priority),
                EstimatedCost: parseFloat(actionPopup.estimatedCost), FinancialNotes: actionPopup.financialNotes, GHGItemTypeId: null,
                ContaminantIds: contaminants, GoodFarmingPracticeIds: gmpSelectedValues, userId: userId, FarmId: farmId, SectionId: section,
                ActivityRiskId: actionPopup.activityRiskId, InherentRisks: inherentRisks, SignificantSitesAndSpecies: significantSpecies, LUId: luId, FarmEnterpriseId: actionPopup.farmEnterpriseId,
                ExistingAction: actionPopup.existingAction, Category: actionPopup.category == null ? '' : actionPopup.category, RecommendedActionId: actionPopup.recommendedActionId,
                RegulatoryRequirementDescription: actionPopup.regulatoryRequirementDescription
            }
            if (actionPopup.actionType == 0) {
                form.ActionType = actionTypes[0].id;
            }
            const response = await axios.post(
                '/api/action/SaveAction/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data.status) {
                if (refreshRiskSummary != undefined) {
                    await refreshRiskSummary();
                    toast.success("Action details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTempId(parseInt(response.data.content));
                    setSavingAction(false);
                    handleActionClose();
                }
                else {
                    toast.success("Action details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTempId(parseInt(response.data.content));
                    setSavingAction(false);
                    handleActionClose();
                    window.location.reload(true);
                }

            }
        }
        else {
            setSavingAction(false);
        }
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "-" + mm + "-" + yyyy;
        return date.toString();
    }

    const getParsedDate1 = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "/" + mm + "/" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }
    const getDateFromString1 = (date) => {
        var dd = date.split('-')[0];
        var mm = date.split('-')[1];
        var yyyy = date.split('-')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleActionCloseSave = async (e) => {
        e.preventDefault();
        setSavingActionCloseSave(true);
        setFormErrors({});
        const errors = {};
        if (!submitDate) {
            errors.submitDate = "Please enter submit date";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            let form = {
                Id: actionPopup.id, SubmitDate: getParsedDate(submitDate), PastTargetOutlineReasons: actionPopup.pastTargetOutlineReasons,
                CloseOutDetail: actionPopup.closeOutDetail, ActualCost: parseFloat(actionPopup.actualCost),
                ActualCostNotes: actionPopup.actualCostNotes, userId: userId
            }
            const response = await axios.post(
                '/api/action/SaveActionClose/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data.status) {
                toast.success("Action details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                await refreshRiskSummary();
                setSavingActionCloseSave(false);
                handleActionClose();
            }
        }
        else {
            setSavingActionCloseSave(false);
        }
    }

    const handleRegulatoryRequirementChange = (e) => {
        var priority = '';
        if (e.target.value == 'Yes') {
            priority = '1';
        }
        else {
            if (actionPopup.catchmentPriority == 'Yes') {
                if (actionPopup.significantEnvironmentBenfit == 'Yes') {
                    priority = '2';
                }
                else {
                    priority = '4';
                }
            }
            else {
                if (actionPopup.significantEnvironmentBenfit == 'Yes') {
                    priority = '3';
                }
                else {
                    priority = '5';
                }
            }
        }
        setActionPopup({ ...actionPopup, regulatoryRequirement: e.target.value, priority: priority });
    }

    const handleCatchmentPriorityChange = (e) => {
        var priority = '';
        if (actionPopup.regulatoryRequirement == 'Yes') {
            priority = '1';
        }
        else {
            if (e.target.value == 'Yes') {
                if (actionPopup.significantEnvironmentBenfit == 'Yes') {
                    priority = '2';
                }
                else {
                    priority = '4';
                }
            }
            else {
                if (actionPopup.significantEnvironmentBenfit == 'Yes') {
                    priority = '3';
                }
                else {
                    priority = '5';
                }
            }
        }
        setActionPopup({ ...actionPopup, catchmentPriority: e.target.value, priority: priority });
    }

    const handleSignificantEnvironmentBenfitChange = (e) => {
        var priority = '';
        if (actionPopup.regulatoryRequirement == 'Yes') {
            priority = '1';
        }
        else {
            if (actionPopup.catchmentPriority == 'Yes') {
                if (e.target.value == 'Yes') {
                    priority = '2';
                }
                else {
                    priority = '4';
                }
            }
            else {
                if (e.target.value == 'Yes') {
                    priority = '3';
                }
                else {
                    priority = '5';
                }
            }
        }
        setActionPopup({ ...actionPopup, significantEnvironmentBenfit: e.target.value, priority: priority });
    }

    const handleActivityRiskPopup = (e) => {
        e.preventDefault();
        setActivityRiskPopup({ ...activityRiskPopup, showActivityRisk: true, activityRiskId: 0, activityRiskText: '', activityId: 0, enterpriseTypeId: 0, luId: '', recommendedActionId: 0 });
    }

    const handleActivityRiskSelect = (e) => {
        e.preventDefault();
        setActivityRiskId(activityRiskPopup.activityRiskId);
        setActivityRisk(activityRiskPopup.activityRiskText);
        setActivityRiskPopup({ ...activityRiskPopup, showActivityRisk: false });
        if (actionPopup.id == 0) {
            if (activityRiskPopup.naActivityRisk) {
                if (wholeFarmAction) {
                    setActionPopup({ ...actionPopup, title: '', description: '', activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
                else if (ghgNutrient) {
                    setActionPopup({ ...actionPopup, title: '', description: '', activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
                else {
                    setActionPopup({ ...actionPopup, title: '', description: '', activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
            }
            else {
                if (wholeFarmAction) {
                    setActionPopup({ ...actionPopup, title: activityRiskPopup.recommendedActionText, description: activityRiskPopup.recommendedActionDescription, activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
                else if (ghgNutrient) {
                    setActionPopup({ ...actionPopup, title: activityRiskPopup.recommendedActionText, description: activityRiskPopup.recommendedActionDescription, activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
                else {
                    setActionPopup({ ...actionPopup, title: activityRiskPopup.recommendedActionText, description: activityRiskPopup.recommendedActionDescription, activityRiskId: activityRiskPopup.activityRiskId, luId: activityRiskPopup.luId, farmEnterpriseId: activityRiskPopup.activityId, recommendedActionId: activityRiskPopup.recommendedActionId })
                }
            }
        }
        else {
            setActionPopup({ ...actionPopup, luId: activityRiskPopup.luId })
        }
    }

    const handleActivityRiskClose = (e) => {
        setActivityRiskPopup({ ...activityRiskPopup, showActivityRisk: false, activityId: 0, luId: 0, activityRiskId: 0, activityRiskText: '', recommendedActionId: 0, recommendedActionText: '', recommendedActionDescription: '' });
    }

    function handleUploadReqPopupNoClose() {
        window.open(redirectPage, "_self");
    }

    const getActionbyId = async (actionId) => {

        const response = await axios.get(
            '/api/action/GetAction/', {
            params: { Id: actionId, sectionId: sectionId, farmId: farmId }
        }
        );
        if (response.data.status) {
            setActionTypes(response.data.content.actionTypes);
            setContaminantList(response.data.content.contaminantList);
            setContaminantListSelectedValues(response.data.content.contaminants);
            for (var i = 0; i < response.data.content.goodFarmingPracticeGroups.length; i++) {
                var goodFarmingPractices = response.data.content.goodFarmingPracticeList.filter(x => x.group1 == response.data.content.goodFarmingPracticeGroups[i].name);
                options.push(createGroup(response.data.content.goodFarmingPracticeGroups[i].name, goodFarmingPractices, setGmpValue));
            }
            setGmpOptions(options);
            if (actionId != 0) {
                flushSync(() => {
                    setActionPopup({
                        ...actionPopup, id: response.data.content.id, type: response.data.content.ghgItemTypeId, name: itemName, actionType: response.data.content.actionType, title: response.data.content.title,
                        description: response.data.content.description, regulatoryRequirement: response.data.content.regulatoryRequirement, catchmentPriority: response.data.content.catchmentPriority,
                        significantEnvironmentBenfit: response.data.content.significantEnvironmentBenfit, priority: response.data.content.priority, estimatedCost: response.data.content.estimatedCost,
                        financialNotes: response.data.content.financialNotes, pastTargetOutlineReasons: response.data.content.pastTargetOutlineReasons, closeOutDetail: response.data.content.closeOutDetail
                        , isCloseout: response.data.content.isCloseout, itemId: itemId, createdByName: response.data.content.createdByName, createdDate: response.data.content.createdOn
                    });
                });
                setActionDueDate(getDateFromString(response.data.content.dueDateText));
                if (response.data.content.submitDateText != '') {
                    setSubmitDate(getDateFromString(response.data.content.submitDateText));
                }
                setIsFarmOperational(response.data.content.isOperational);
                setGmpValue(response.data.content.goodFarmingPractices);
            }
        }
    }

    function handleUploadReqPopupYesClose() {
        getActionbyId(tempId);
        setUploadReqPopup({ showUploadReqPopup: false });
    }

    const handleActionDelete = async (e) => {
        e.preventDefault();
        const id = actionDeletePopup.id;
        try {
            const response = await axios.get(
                '/api/action/DeleteAction/', {
                params: {
                    id: id, userId: userId
                }
            }
            );
            window.location.reload(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleActionDeleteClick = (actionId) => {
        setActionDeletePopup({ ...actionDeletePopup, showActionDeletePopup: true, id: actionId })
    }

    const handleActionDeletePopupClose = () => {
        setActionDeletePopup({ ...actionDeletePopup, showActionDeletePopup: false, id: 0 })
    }

    const handleMultiSelectInherentRiskChange = (e) => {
        setInherentRisksObj({ ...inherentRisksObj, inherentRisks: e });
    }

    const handleMultiSelectSignificantSpeciesChange = (e) => {
        setSignificantSpeciesObj({ ...significantSpeciesObj, significantSpecies: e });
    }

    const handleMultiSelectContaminantChange = (e) => {
        setContaminantObj({ ...contaminantObj, contaminants: e });
    }

    const handleSetActionDueDate = (date) => {
        setActionDueDate(date);
        if (submitDate != undefined && submitDate != null) {
            if (submitDate <= date) {
                setShowPastTargetOutlineReasons(false);
            }
            else {
                setShowPastTargetOutlineReasons(true);
            }
        }
    }
    const handleSetSubmitDate = (date) => {
        setSubmitDate(date);
        if (actionDueDate != undefined && actionDueDate != null) {
            if (date <= actionDueDate) {
                setShowPastTargetOutlineReasons(false);
            }
            else {
                setShowPastTargetOutlineReasons(true);
            }
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>
                <Modal size="lg" style={{ maxWidth: '1250px', width: '100%' }} isOpen={showActionPopupModal}>
                    <ModalHeader toggle={handleActionClose}> Action Add/Edit</ModalHeader>
                    <ModalBody>
                        {!pageDisabled &&  
                            <Row>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label>Choose Action Feature Category</Label>
                                        {pagesList == undefined && <p><em>Loading...</em></p>}
                                        {pagesList != undefined && pagesList != null &&
                                            <Input disabled={pageDisabled} type="select" value={actionPopup.pageId} onChange={handleInputChange} name="pageId" id="pageId">
                                                {pagesList.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                    {formErrors.page != undefined &&
                                        <p className="errorMessage">{formErrors.page}</p>
                                    }
                                </Col>
                            </Row>    
                        }
                        <Row xs="3" style={{ display: "none" }}>
                            <Col>
                                <FormGroup>
                                    <Label>Created</Label>
                                    <Input type="text" name="actionCreatedOn" id="actionCreatedOn" value={actionCreatedOn} readOnly disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Created by</Label>
                                    <Input type="text" name="txtPage" id="txtPage" value={actionPopup.createdByName} readOnly disabled />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label>Id</Label>
                                    <Input type="text" name="txtPage" id="txtPage" value={actionPopup.id} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row xs="3" style={{ display: "none" }}>
                            <Col>
                                <FormGroup>
                                    <Label>Page</Label>
                                    <Input type="text" name="txtPage" id="txtPage" value={sectionName} readOnly disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Type</Label>
                                    {itemTypeSelect == undefined && <p><em>Loading...</em></p>}
                                    {itemTypeSelect != undefined && itemTypeSelect != null &&
                                        <Input disabled={itemTypeDisabled} type="select" value={actionPopup.type} onChange={handleInputChange} name="type" id="type">
                                            {itemTypeSelect.map(items =>
                                                <option key={uuid()} value={items.id}>{items.name}</option>
                                            )}
                                        </Input>
                                    }
                                </FormGroup>
                                {formErrors.type != undefined &&
                                    <p className="errorMessage">{formErrors.type}</p>
                                }
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text" name="txtName" id="txtName" value={actionPopup.name} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        {showActivityRisk && <Row>
                            <Col sm="9">
                                <Label>Select which Risk this action will be mitigating, or helping to mitigate</Label>
                                <Input type="hidden" name="activityRiskId" id="activityRiskId" value={activityRiskId} />
                                <Input type="text" name="activityRisk" id="activityRisk" value={activityRisk} readOnly disabled />
                            </Col>
                            <Col sm="3">
                                <Button style={{ marginTop: `30px`, marginBottom: `10px` }} onClick={handleActivityRiskPopup} color="primary" >{actionPopup.id == 0 ? "Select Risk" : "Edit Risk and LU detail"}</Button>
                            </Col>
                            {formErrors.activityRisk != undefined &&
                                <p className="errorMessage">{formErrors.activityRisk}</p>
                            }
                        </Row>}

                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label>Action Title</Label>
                                    <Input type="text" name="title" id="title" value={actionPopup.title} onChange={handleInputChange} />
                                </FormGroup>
                                {formErrors.title != undefined &&
                                    <p className="errorMessage">{formErrors.title}</p>
                                }
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label>Action Description</Label>
                                    <Input type="textarea" name="description" id="description" value={actionPopup.description} onChange={handleInputChange} />
                                </FormGroup>
                                {formErrors.description != undefined &&
                                    <p className="errorMessage">{formErrors.description}</p>
                                }
                            </Col>

                        </Row>
                        <Row style={{ display: "none" }}>
                            <Col>
                                <FormGroup>
                                    <div className="inherentrisks​-header">Inherent vulnerability​ <i>(Select Inherent vulnerability from this LU which this Action mitigates or manages)</i></div>
                                    <Select
                                        closeMenuOnSelect={false}
                                        className="dropdown"
                                        placeholder="Select Inherent Risks"
                                        value={inherentRisksObj.inherentRisks}
                                        options={inherentRisksObj.inherentRisksList}
                                        onChange={handleMultiSelectInherentRiskChange}
                                        isMulti hideSelectedOptions={false}
                                        isClearable
                                        components={{
                                            Option
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            significantSpeciesObj.significantSpeciesList.length > 0 &&
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <FormGroup>
                                        <div className="sitesspecies-header">Sites and Species of Cultural Significance​ <i>(select items this action can apply to)</i></div>
                                        <Select
                                            closeMenuOnSelect={false}
                                            className="dropdown"
                                            placeholder="Select Significant Sites and Species"
                                            value={significantSpeciesObj.significantSpecies}
                                            options={significantSpeciesObj.significantSpeciesList}
                                            onChange={handleMultiSelectSignificantSpeciesChange}
                                            isMulti hideSelectedOptions={false}
                                            isClearable
                                            components={{
                                                Option
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }

                        <Row style={{ display: "none" }}>
                            <Col>
                                <FormGroup>
                                    <div className="contaminants-header">Contaminants </div>
                                    <Select
                                        closeMenuOnSelect={false}
                                        className="dropdown"
                                        placeholder="Select Contaminants"
                                        value={contaminantObj.contaminants}
                                        options={contaminantObj.contaminantList}
                                        onChange={handleMultiSelectContaminantChange}
                                        isMulti hideSelectedOptions={false}
                                        isClearable
                                        components={{
                                            Option
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm="8">
                                <Row>
                                    <Col sm="4">
                                        <Label><span style={{ color: "red" }}>*</span>&nbsp;&nbsp;<FaInfoCircle data-tooltip-id="my-tooltip-actionCategory1" />&nbsp;&nbsp;&nbsp;Category</Label>
                                        <ReactTooltip
                                            id="my-tooltip-actionCategory1" style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap: 'break-word' }}
                                            place="top" className="overrideMe"
                                            variant="info"
                                            render={({ content, activeAnchor }) => (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <p style={{ fontWeight: "lighter" }}>
                                                                <b>Regulatory</b> - Is this a government or council regulated action to manage this risk? e.g. monitoring water flow for irrigation, which is required under a consented water take
                                                            </p>
                                                            <p style={{ fontWeight: "lighter" }}>
                                                                <b>Catchment</b> - Is this action related to managing a risk in your specific catchment?  e.g. nitrogen, phosphorus, sediment or e-coli are identified as challenges in your catchment and your action will help reduce nitrogen losses by rationalising fertiliser application and completing annual soil tests
                                                            </p>
                                                            <p style={{ fontWeight: "lighter" }}>
                                                                <b>Supplementary</b> - Is this good industry practice to manage this risk? e.g. Zespri GAP requires bunding around fuel tanks
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        />
                                        <Input type="select" value={actionPopup.category} onChange={handleInputChange} name="category" id="category">
                                            <option value="">Select</option>
                                            <option value="R">Regulatory</option>
                                            <option value="C">Catchment</option>
                                            <option value="S">Supplementary</option>
                                        </Input>
                                        {formErrors.category != undefined &&
                                            <p className="errorMessage">{formErrors.category}</p>
                                        }
                                    </Col>
                                    {actionPopup.category == 'R' && <Col sm="4">
                                        <FormGroup>
                                            <Label>Regulatory Requirement (e.g. Rule 15)</Label>
                                            <Input type="text" name="regulatoryRequirementDescription" id="regulatoryRequirementDescription" value={actionPopup.regulatoryRequirementDescription} onChange={handleInputChange} />
                                        </FormGroup>
                                    </Col>}
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Select if this is an Existing Action</Label>&nbsp;&nbsp;&nbsp;
                                            <Input className="" type="checkbox" checked={actionPopup.existingAction} onChange={handleInputChange} name="existingAction" id="existingAction" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Type</Label>
                                            {actionTypes == undefined && <p><em>Loading...</em></p>}
                                            {actionTypes != undefined && actionTypes != null &&
                                                <Input type="select" value={actionPopup.actionType} onChange={handleInputChange} name="actionType" id="actionType">
                                                    {actionTypes.map(items =>
                                                        <option key={uuid()} value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            }
                                        </FormGroup>
                                    </Col>
                                    {!isOngoingAction &&

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label>Action Due</Label>
                                                <DatePicker showYearDropdown yearDropdownItemNumber={15} scrollableYearDropdown style={{ width: `100%` }} selected={actionDueDate} onChange={(date) => handleSetActionDueDate(date)}
                                                    name="actionDueDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                            </FormGroup>
                                            {formErrors.actionDueDate != undefined &&
                                                <p className="errorMessage">{formErrors.actionDueDate}</p>
                                            }
                                        </Col>

                                    }
                                </Row>
                            </Col>
                            <Col sm="4">
                                <Row>
                                    <Col sm="12">
                                        <br />
                                        <div className="smart-description">
                                            <span className="smart-list" style={{ fontSize: "16px", fontWeight: "700" }}>
                                                Use the SMART action planner:
                                            </span>
                                            <span className="smart-list">
                                                <span className="smart-letter">S</span> What is the <strong>S</strong>
                                                pecific outline of the risk and action implemented?
                                            </span>
                                            <span className="smart-list">
                                                <span className="smart-letter">M</span> How is it going to be{" "}
                                                <strong>M</strong>easured?
                                            </span>
                                            <span className="smart-list">
                                                <span className="smart-letter">A</span> Can it be <strong>A</strong>chieved?
                                            </span>
                                            <span className="smart-list">
                                                <span className="smart-letter">R</span> Does the action address the{" "}
                                                <strong>R</strong>isks?
                                            </span>
                                            <span className="smart-list">
                                                <span className="smart-letter">T</span> What is the <strong>T</strong>ime
                                                frame around action?
                                            </span>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ display: "none" }}>
                            <Col sm="3">
                                <Label>Regulatory Requirement</Label>
                                <Input type="select" value={actionPopup.regulatoryRequirement} onChange={handleRegulatoryRequirementChange} name="regulatoryRequirement" id="regulatoryRequirement">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </Col>
                            <Col sm="3">
                                <Label>Catchment Priority</Label>
                                <Input type="select" value={actionPopup.catchmentPriority} onChange={handleCatchmentPriorityChange} name="catchmentPriority" id="catchmentPriority">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </Col>
                            <Col sm="4">
                                <Label>Provide a Significant Environment Benefit</Label>
                                <Input type="select" value={actionPopup.significantEnvironmentBenfit} onChange={handleSignificantEnvironmentBenfitChange} name="significantEnvironmentBenfit" id="significantEnvironmentBenfit">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </Col>
                            <Col sm="2">
                                <Label>Priority</Label>
                                <br />
                                <span className="badge badge-warning" id="SaveGHGAction_Priority">{actionPopup.priority}</span>
                            </Col>
                        </Row>
                        {isDairyActivity &&
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label>Good Farming Practices</Label>
                                        <Select
                                            onChange={option => {
                                                return setGmpValue(option);
                                            }}
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={gmpOptions}
                                            value={gmpValue}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm="3">
                                <FormGroup>
                                    <Label>Estimated Cost</Label>
                                    <Input type="text" onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} name="estimatedCost" id="estimatedCost" value={actionPopup.estimatedCost} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                            <Col sm="9">
                                <FormGroup>
                                    <Label>Financial Notes</Label>
                                    <Input type="textarea" rows="1" name="financialNotes" id="financialNotes" value={actionPopup.financialNotes} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div>
                            <Photos disabledMode={actionPopup.isCloseout} refId={tempId} header="Action Images" uploadType="Action">
                            </Photos>
                            <br />
                        </div>
                        <Row>
                            <Col></Col>
                            <Col sm="6">
                                {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                                    <>
                                        {
                                            tempId != 0 && !actionPopup.isCloseout &&
                                            <div>
                                                {savingAction ?
                                                    <Button className="button-save" style={{ float: `right`, marginLeft: '10px' }} id="btnActionSave" onClick={handleActionSave}
                                                        color="success"
                                                        disabled
                                                    >
                                                        <Row>
                                                            <Col sm="6">
                                                                <span>
                                                                    {' '}Saving
                                                                </span>
                                                            </Col>
                                                            <Col sm="6">
                                                                <Spinner size="sm">
                                                                    Saving...
                                                                </Spinner>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    :
                                                    <Button className="button-save" style={{ float: `right`, marginLeft: '10px', display: 'none' }} id="btnActionSave" onClick={handleActionSave} color="success">
                                                        Save
                                                    </Button>
                                                }
                                            </div>


                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                        {
                            tempId != 0 && <div>
                                <Row style={{ marginTop: `15px` }} xs="2">
                                    <Col>
                                        <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>Action Closeout</u></b></Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label>Submit Date</Label>
                                            <DatePicker showYearDropdown yearDropdownItemNumber={15} scrollableYearDropdown style={{ width: `100%` }} selected={submitDate}
                                                onChange={(date) => handleSetSubmitDate(date)}
                                                name="submitDate" dateFormat="dd/MM/yyyy" className="form-control" />
                                        </FormGroup>
                                        {formErrors.submitDate != undefined &&
                                            <p className="errorMessage">{formErrors.submitDate}</p>
                                        }
                                    </Col>
                                    {
                                        showPastTargetOutlineReasons &&
                                        <Col sm="9">
                                            <FormGroup>
                                                <Label>If Past Target Date Outline Reasons Why?</Label>
                                                <Input type="textarea" name="pastTargetOutlineReasons" id="pastTargetOutlineReasons" onChange={handleInputChange} value={actionPopup.pastTargetOutlineReasons} />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Closeout Detail</Label>
                                            <Input type="textarea" name="closeOutDetail" id="closeOutDetail" onChange={handleInputChange} value={actionPopup.closeOutDetail} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label>Actual Cost</Label>
                                            <Input type="text" onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} name="actualCost" id="actualCost" value={actionPopup.actualCost} onChange={handleInputChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="9">
                                        <FormGroup>
                                            <Label>Actual Cost Notes</Label>
                                            <Input type="textarea" rows="1" name="actualCostNotes" id="actualCostNotes" value={actionPopup.actualCostNotes} onChange={handleInputChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div>
                                    <Photos disabledMode={actionPopup.isCloseout} refId={tempId} header="Action Closeout Images" uploadType="ActionCloseout">
                                    </Photos>
                                    <br />
                                </div>
                            </div>
                        }

                    </ModalBody>
                    <ModalFooter>
                        {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                            <>
                                {
                                    tempId == 0 && !actionPopup.isCloseout &&
                                    <div>
                                        {savingAction ?
                                            <Button className="button-save" style={{ float: `right`, marginLeft: '10px' }} id="btnActionSave" onClick={handleActionSave}
                                                color="success"
                                                disabled
                                            >
                                                <Row>
                                                    <Col sm="6">
                                                        <span>
                                                            {' '}Saving
                                                        </span>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Spinner size="sm">
                                                            Saving...
                                                        </Spinner>
                                                    </Col>
                                                </Row>
                                            </Button>
                                            :
                                            <Button className="button-save" style={{ float: `right`, marginLeft: '10px', display: 'none' }} id="btnActionSave" onClick={handleActionSave} color="success">
                                                Save
                                            </Button>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {(roleName == 'Zespri Admin' || isSubmitEvidenceCloseAction == 'true') &&
                            <>
                                {
                                    tempId != 0 && !actionPopup.isCloseout &&
                                    <div>
                                        {savingActionCloseSave ?
                                            <Button className="button-save" style={{ float: `right`, marginLeft: '10px' }} id="btnActionCloseSave" onClick={handleActionCloseSave} color="success" disabled>
                                                <Row>
                                                    <Col sm="6">
                                                        <span>
                                                            {' '}Saving
                                                        </span>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Spinner size="sm">
                                                            Saving...
                                                        </Spinner>
                                                    </Col>
                                                </Row>
                                            </Button>
                                            :
                                            <Button className="button-save" style={{ float: `right`, marginLeft: '10px', display: 'none' }} id="btnActionCloseSave" onClick={handleActionCloseSave} color="success">
                                                Save & Close Action
                                            </Button>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                            <>
                                {
                                    tempId != 0 && !actionPopup.isCloseout && (!isFarmOperational || (isFarmOperational && moment(actionPopup.createdDate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY"))) &&
                                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={(actionId) => handleActionDeleteClick(actionPopup.id)} color="danger" >Delete</Button>
                                }
                            </>
                        }
                        <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleActionClose} color="primary" >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Draggable>
            <Modal isOpen={uploadReqPopup.showUploadReqPopup}>
                <ModalHeader toggle={handleUploadReqPopupNoClose} >Add Record</ModalHeader>
                <ModalBody>
                    Do you want to upload Photos ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleUploadReqPopupYesClose}>Yes</Button>
                    <Button color="danger" onClick={handleUploadReqPopupNoClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={actionDeletePopup.showActionDeletePopup}>
                <ModalHeader toggle={handleActionDeletePopupClose} >Delete Action</ModalHeader>
                <ModalBody>
                    Do you want to delete this Action ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleActionDelete}>Yes</Button>
                    <Button color="danger" onClick={handleActionDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>
            {activityRiskPopup.showActivityRisk && (wholeFarmAction || ghgNutrient) &&
                <ActivityRiskFromSummary actionPopup={actionPopup} farmAPTActionList={farmAPTActionList} handleActivityRiskSelect={handleActivityRiskSelect} lmuId={lmuId} handleActivityRiskClose={handleActivityRiskClose} activityRiskModel={activityRiskModel} activityRiskPopup={activityRiskPopup} ItemAction={(wholeFarmAction || isGHGNutrient) ? false : true}></ActivityRiskFromSummary>
            }
        </div>
    );
}
export default ActionFromSummary;