import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';
import Select, { components } from 'react-select';
const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
const FreshWaterIrrigationItemSpecView = ({ itemPopupItemField, itemPopupItemSepcQ, methodsLookupList, handleItemSepcValueChange, handleMultiSelectMethodstoManageChange  }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const itemTypeFieldList = itemPopupItemField.itemFieldTypes;
    const itemFieldCount = itemTypeFieldList.length;

    const isOtherMethodReq = itemPopupItemSepcQ.MethodstoManage.filter(x => x.label == 'Other').length > 0 ? true : false;

    itemPopupItemSepcQ.OtherMethodstoManage = isOtherMethodReq == true ? itemPopupItemSepcQ.OtherMethodstoManage : "";
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen)
    };

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        handleHideAllDiv(itemTypeFieldList);
    }, [])

    const handleHideAllDiv = (itemTypeFieldList) => {
        document.querySelectorAll(".item-field").forEach(element => {
            element.style.display = 'none';
        })

        itemTypeFieldList.map(itemTypeField => {
            let className = "." + itemTypeField.className;
            document.querySelectorAll(className).forEach(element => {
                element.style.display = 'block';
            })
        })
    }



    const selectOptions = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" }
    ];

    if (itemFieldCount > 0) {
        return (
            <>
                <Card>
                    {/*<CardHeader className ="card-header-click" onClick={toggle}>*/}
                    {/*    <Row>*/}
                    {/*        <Col sm="11">*/}
                    {/*            <h6>Additional Information</h6>*/}
                    {/*        </Col>*/}
                    {/*        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>*/}
                    {/*    </Row>*/}
                    {/*</CardHeader>*/}
                    <CardBody>
                        {/*<Collapse isOpen={isOpen}>*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field currentlyconsented'>
                                        <Label>Currently consented?</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="Consented" id="Consented" value={itemPopupItemSepcQ.Consented} >
                                            {selectOptions.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.label}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <FormGroup className='item-field methods-manage-water-use'>
                                        <Label>What methods do you use to manage water use efficiency? </Label> {' '}
                                        <Select
                                            closeMenuOnSelect={false}
                                            className="dropdown"
                                            placeholder="Select Method(s) to Manage water use"
                                            defaultValue={itemPopupItemSepcQ.MethodstoManage}
                                            value={itemPopupItemSepcQ.MethodstoManage}
                                            options={methodsLookupList.MethodstoMangeTypes}
                                            onChange={handleMultiSelectMethodstoManageChange}
                                            isMulti hideSelectedOptions={false}
                                            isClearable
                                            components={{
                                                Option
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isOtherMethodReq &&
                                <Row >
                                    <Col>
                                        <FormGroup className='item-field other-methods-manage-water-use'>
                                            <Label>Other water use efficiency methods </Label>
                                            <Input type="textbox" name="OtherMethodstoManage" id="OtherMethodstoManage" value={itemPopupItemSepcQ.OtherMethodstoManage} onChange={handleItemSepcValueChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        {/*</Collapse>*/}
                    </CardBody>
                </Card>
                <br />
            </>
        )
    }

}
export default FreshWaterIrrigationItemSpecView