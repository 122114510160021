import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup, Spinner, Card, CardBody, CardHeader, Table, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown} from 'react-icons/fa';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import MapView from '../Map/MapView';
import KMLUpload from '../ItemsView/KMLUpload';
import uuid from 'react-uuid';
import LmuUploadItemView from './LmuUploadItemView';

const LmuUpload = () => {
    const [saving, setSaving] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const [itemList, setItemList] = useState([]);
    const [lmu, setLmu] = useState({ outlineList: [], itemList: [] });
    const [luUpload, setLuUpload] = useState({ isUpload: false, uploadedShape: [], AreaList: [], PerimeterList: [] });
    const [loading, setLoading] = useState(true);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: true, itemsEditable: false,
        isFarmCenterEditable: false, isFarmOutlineEditable: false, zoomLevel: 5, polytype: '', isLmuUpload: true, highlighteditemId: 0
    });
    const [itemBulkPopup, setItemBulkPopup] = useState({ showBulkItemPopup: false, uploadedShape: [], AreaList: [], PerimeterList: [] });

    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])


    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/lmu/GetLmuforUpload/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setLmu({ ...lmu, outlineList: response.data.outline, itemList: response.data.itemsList, itemTypes: response.data.itemTypeList });
            setItemList(response.data.itemsList);
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel });
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    /* KML Upload*/
    const onFileChange = async (event) => {
        if (event.target.files[0] != undefined) {
            const formData = new FormData();
            formData.append("fileName", event.target.files[0].name);
            formData.append("File", event.target.files[0]);
            try {
                const response = await axios.post(
                    '/api/lmu/UploadKMLFiles/', formData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                handleUploadSave(response.data);

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUploadSave = (uploadedShape) => {
        if (uploadedShape != null) {
            let areaList = [];
            let perimeterList = [];
            let modifiedUploadedShape = []

            let outlineList = lmu.outlineList.filter(type => type.mapType == 'polygon');

            const outlineCoordinates = outlineList[0].coordinates.split(':');
            const outlinepositions = [];

            outlineCoordinates.filter(coor => coor != "").map((Coordinate) => {
                const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                outlinepositions.push(pos);
            })

            const outlinePolygon = new window.google.maps.Polygon({
                paths: outlinepositions
            });


            uploadedShape.map((LUShapes) => {
                const Coordinates = LUShapes.mapCoordinates.split(':');
                const positions = [];

                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }

                   const inside =  window.google.maps.geometry.poly.containsLocation(
                        pos,
                        outlinePolygon
                    ) ? true : false;

                    if (inside == true) {
                        positions.push(pos)

                    }
                })

                if (positions.length > 0) {
                    modifiedUploadedShape.push(LUShapes)

                    const tempPolygon = new window.google.maps.Polygon({
                        paths: positions
                    });

                    let path = tempPolygon.getPath();
                    let area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                    let perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
                    areaList.push(area);
                    perimeterList.push(perimeter);
                }
                
            })
            setLuUpload({ ...luUpload, isUpload: true, uploadedShape: modifiedUploadedShape })

            setItemBulkPopup({ farmId: farmId, showBulkItemPopup: true, uploadedShape: modifiedUploadedShape, AreaList: areaList, PerimeterList: perimeterList })
        }
    }

    const uploadkmlShapeClick = () => {
        document.getElementById('uploadKML').click();
    }

    const HandleBulkItemPopupClose = () => {
        setItemBulkPopup({ farmId: farmId, showBulkItemPopup: false, uploadedShape: [], AreaList: [], PerimeterList: [] });
    }

    const handleBulkItemSubmit = async (event) => {
        setSaving(true);
        const item = itemBulkPopup;

        let form = {
            farmId: item.farmId,
            LUList: item.uploadedShape,
            AreaList: item.AreaList,
            PerimeterList: item.PerimeterList,
            userId: userId
        }
        try {
            const response = await axios.post(
                '/api/lmu/UpdateBulkLU/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            HandleBulkItemPopupClose();
            toast.success("Items Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            setTimeout(() => {
                setSaving(false);
            }, 1000)
            OnPageload();
        } catch (error) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)
            console.error('Error:', error);
        }
    }

    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    const deleteItem = async (event, id) => {
        try {
            const response = await axios.get(
                '/api/lmu/DeleteItem/', {
                params: {
                        itemId: id
                }
            }
            );
            toast.success("Item deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            OnPageload();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const mapView = useMemo(() => {
        return (
            <MapView itemModel={lmu} mapDetails={mapDetails} luUpload={luUpload}>
            </MapView>
        )
    }, [lmu, mapDetails, luUpload])

    const itemView = useMemo(() => {
        return (
            <table className="table borderless">
                <thead></thead>
                <tbody>

                    <tr className="" key={uuid()}>
                        <td >
                            <Table className='table borderless '>
                                <thead >
                                    <tr>
                                        <th style={{ width: '10%' }}> </th>
                                        <th style={{ width: '50%' }}> Name </th>
                                        <th style={{ width: '10%' }}> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemList.map(item =>
                                            <LmuUploadItemView item={item} handleItemHighlight={handleItemHighlight} deleteItem={deleteItem}></LmuUploadItemView>
                                        )}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }, [itemList])

    return (

        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>Land Units Import </h2></Col>

            </Row>
            {loading ? <SpinnerContainer /> :
                <Row xs="2">
                    <Col sm="8">
                        {mapView}
                    </Col>
                    <Col sm="4">
                        <KMLUpload uploadkmlShapeClick={uploadkmlShapeClick} onFileChange={onFileChange} />

                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader className="item-header" onClick={toggle}>
                                        <Row>
                                            <Col sm="11">
                                                <span className="text-uppercase fw-bold color-dark-teal">Land Units items</span>
                                            </Col>
                                            <Col sm="1" className="toggleoutline">
                                                {isIconUp ? <FaChevronUp /> : <FaChevronDown />}
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen}>
                                        <CardBody>
                                            {
                                                lmu.itemList.length != 0 &&
                                                <>
                                                    <Row>
                                                        <Col>
                                                            {itemView}
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            }
            <Modal size="lg" isOpen={itemBulkPopup.showBulkItemPopup} backdrop={false} className="outlineItemPopup">
                <Form>
                    <ModalHeader toggle={HandleBulkItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Land Units Uploaded items</Label>
                                    <label id="outlineDescription" className="control-description">The name, description and color  will fetch from the KML file and you can update sthe from LMU page once it get saved.</label>
                                </FormGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        {saving ?
                            <Button
                                color="success"
                                disabled
                            >
                                <Spinner size="sm">
                                    Saving...
                                </Spinner>
                                <span>
                                    {' '}Saving
                                </span>
                            </Button>
                            :
                            <Button color="success" size="sm" onClick={handleBulkItemSubmit}>
                                Save
                            </Button>
                        }
                        {saving ?
                            <Button color="primary" disabled onClick={HandleBulkItemPopupClose}>Cancel</Button> :
                            <Button color="primary" onClick={HandleBulkItemPopupClose}>Cancel</Button>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
export default LmuUpload;