import React, { Component, useState } from 'react';
import { Navbar } from 'reactstrap';
import Profile from './Profile';
import './Header.css';
import { useEffect } from 'react';
import HeaderBackgroundImage from '../../images/header-background.jpg';
const Header = () => {

    const [defaultFarmName, setDefaultFarmName] = useState();
    const [defaultFarmUniqueId, setDefaultFarmUniqueId] = useState();

    useEffect(() => {
        setDefaultFarmName(JSON.parse(localStorage.getItem("defaultFarmName")))
        setDefaultFarmUniqueId(JSON.parse(localStorage.getItem("defaultFarmUniqueId")))
    })

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" container light
                style={{
                backgroundImage: `url(${HeaderBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <div className="logo-farm-name">
                    <a href="javascript:void(0);"><img className="logo" src="/images/fwfp-logo.png" /></a>
                    <div className="farm-detail">
                    </div>
                </div>
                <Profile />
            </Navbar>
        </header>
    );
}


export default Header
