import {
    Row, Col, Label, Input, FormGroup
} from 'reactstrap';
import './Lmu.css';

const GeneralDetailForm = ({ form, lmuTypesList, handleFormFieldChange }) => {

    return (
        <>
            <Row>
                <Input type="hidden" value={form.mapCoordinates} />
                <Col style={{display:"none"}}>
                    <FormGroup>
                        <Label for="LmuTypeId">Type</Label>
                        <Input
                            type="select"
                            value={form.LmuTypeId}
                            onChange={(e) => handleFormFieldChange(e)}
                            name="LmuTypeId"
                            id="LmuTypeId"
                        >
                            <option value={0} >Select Item Type</option>
                            {lmuTypesList.map(lmuType =>
                                <option key={lmuType.id} value={lmuType.id}>{lmuType.value}</option>
                            )}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for="Name">Name</Label>
                        <Input className="validate"
                            type="text"
                            name="Name"
                            id="Name"
                            bsSize="sm"
                            value={form.Name}
                            onChange={(e) => handleFormFieldChange(e)} />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Label for="itemColour">Colour</Label>
                        <Input
                            type="color"
                            name="itemColour"
                            id="itemColour"
                            bsSize="sm"
                            value={form.itemColour}
                            onChange={(e) => handleFormFieldChange(e)}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Size (ha)</Label>
                        <Input
                            type="text"
                            name="itemSizeHa"
                            id="itemSizeHa"
                            readOnly
                            disabled
                            bsSize="sm"
                            value={form.itemSizeHa} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label for="Description">
                            Description
                        </Label>
                        <Input className="validate"
                            bsSize="sm"
                            id="Description"
                            name="Description"
                            type="textarea"
                            value={form.Description}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                </Col>
            </Row>

            
        </>
    )
}

export default GeneralDetailForm