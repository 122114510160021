import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup, Spinner } from 'reactstrap';
import { FaChevronRight } from 'react-icons/fa';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import './Outline.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import MapView from '../Map/MapView';
import OutlineNonMap from './OutlineNonMap';
import OutlineMapView from './OutlineMapView';
import Geocode from "react-geocode";
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import MapImport from '../Outline/MapImport';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Outline = ({ handleWizardChanged, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [haveFarmCenter, setHaveFarmCenter] = useState(false); const [haveFarmBoundary, setHaveFarmBoundary] = useState(true);
    const [saving, setSaving] = useState(''); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [savingItem, setSavingItem] = useState(''); const [savingItemDelete, setSavingItemDelete] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [itemName, setItemName] = useState('');
    const [isWholeFarmOutline, setIsWholeFarmOutline] = useState(true);
    const [isLargeEnterprise, setIsLargeEnterprise] = useState(false);
    const [outline, setOutline] = useState({ outlineList: [], itemTypes: [], zoomLevelList: [], Section: 'Outline', SectionName: 'My Property', isWizardCompleted: false, farmAddress : '' });
    const [loading, setLoading] = useState(true);
    const [typeid, setTypeId] = useState(0);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: true, isLMUPage: false, isCenterReq: true, itemsEditable: false,
        isFarmCenterEditable: false, isFarmOutlineEditable: false, zoomLevel: 5, polyType: '', highlighteditemId: 0, isFindmyFarm: false, myFarmCenter: '', isClear: false
    });
    const [kmlUpload, setkmlUpload] = useState({ isUpload: false, uploadedShape: [], AreaList: [], PerimeterList: [] });
    const [itemPopup, setItemPopup] = useState({
        showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: '',
        markertype: "", area: 0.0, perimeter: 0.0, name: '',
        coordinates: '', isDragged: false, isEdited: false
    });
    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemdeletePopup: false, itemId: 0 });

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/outline/GetOutline/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setOutline({ ...outline, outlineList: response.data.outlineList, itemTypes: response.data.itemTypes, zoomLevelList: response.data.zoomLevelList, isWizardCompleted: response.data.isFWFPCompleted, farmAddress: response.data.farmAddress });
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel });
            setIsLargeEnterprise(response.data.islargeEnterprise);
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    /*Item Selection */
    const handleItemClick = (event, typeid, polyType) => {

        const outlineList = outline.outlineList;
        const OutlineTypeLength = outlineList.filter(type => type.mapType == 'marker').length;
        if (OutlineTypeLength > 0 && polyType == 'marker') {
            toast.error("Already have one Farm Center", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            handleClearSelection()
        }
        else {
            setTypeId(typeid);
            setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
        }
    };

    const handleClearSelection = () => {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
    }

    const importShapeFilesClick = () => {
        window.open('shapeUpload', "_self");
    }

    /*Value Change */
    const handleNameChange = (event) => {
        setItemName(event.target.value);
    }

    const handleZoomChange = (event) => {
        setMapDetails({ ...mapDetails, zoomLevel: parseInt(event.target.value) });
    }

    const handlelargeEnterpriseChange = (event) => {
        setIsLargeEnterprise(event.target.checked);
    }

    const handleIsWholeFarmOutlineChange = (event) => {
        setIsWholeFarmOutline(event.target.checked);
    }


    /*Item Save and Edit*/
    const addMapItem = (mapItem, maptype) => {
        let name, path, area = 0.0, perimeter = 0.0, coordinates = '';

        if (maptype == 'marker') {
            name = "Orchard Centre";
            setItemName(name);
            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);

        }
        else if (maptype == 'polygon') {
            path = mapItem.getPath();
            setItemName('');
            setIsWholeFarmOutline(true);
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }

        setItemPopup({
            showItemPopup: true, mapitem: mapItem, farmId: farmId, id: 0,
            itemId: typeid, markertype: maptype, area: area,
            perimeter: perimeter, name: name, coordinates: coordinates, isDragged: false, isEdited: false
        });

    }

    const editMapItem = (mapItem, itemId, isDragged) => {
        let id = 0, name, area = 0.0, perimeter = 0.0, coordinates = '', maptype = '', isEdited = false;

        const outlineList = outline.outlineList;
        let selectedItem = outlineList.filter(item => item.itemId == itemId)[0];

        if (selectedItem != null) {

            if (isDragged && selectedItem.mapType == 'marker') {
                coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
                area = selectedItem.area;
                perimeter = selectedItem.perimeter;
            }
            else if (selectedItem.isEdited && selectedItem.mapType == 'polygon')
            {
                coordinates = selectedItem.coordinates;
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else {
                area = selectedItem.area;
                perimeter = selectedItem.perimeter;
                coordinates = selectedItem.coordinates;
            }

            name = selectedItem.name;
            area = area;
            perimeter = perimeter;
            isEdited = selectedItem.isEdited;
            maptype = selectedItem.mapType;
            id = selectedItem.id;
            setItemName(name);
            setIsWholeFarmOutline(true);
            setItemPopup({
                showItemPopup: true, farmId: farmId, id: id,
                itemId: itemId, markertype: maptype, area: area,
                perimeter: perimeter, name: name,
                coordinates: coordinates, isDragged: isDragged, isEdited: isEdited
            });
        }
    }

    const handleItemPopupClose = () => {
        setItemPopup({
            showItemPopup: false, mapitem: [],
            farmId: farmId, id: 0, itemId: '',
            markertype: '', area: 0.0, perimeter: 0.0, name: '',
            coordinates: '', isDragged: false, isEdited: false
        });
        setMapDetails({ ...mapDetails, isClear: true });
    }

    const handleItemSubmit = async (event) => {
        setSavingItem(true);
        const item = itemPopup;

        let form = {
            Id: item.id,
            Maptype: item.markertype,
            ItemId: item.itemId,
            Name: itemName,
            Area: item.area,
            Perimeter: item.perimeter,
            Coordinates: item.coordinates,
            FarmId: item.farmId,
            isDragged: item.isDragged,
            isEdited: item.isEdited,
            userId: userId,
            IsFarmOutline: isWholeFarmOutline
        }
        try {
            const response = await axios.post(
                '/api/outline/UpdateOutline/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                const response = await axios.get(
                    '/api/outline/GetOutline/', {
                    params: {
                        farmId: farmId
                    }
                }
                );
                setOutline({ ...outline, outlineList: response.data.outlineList });
                setTypeId(0);
                setMapDetails({ ...mapDetails, polyType: '', isClear: true });
                setSavingItem(false);
                setItemPopup({
                    showItemPopup: false, mapitem: [],
                    farmId: farmId, id: 0, itemId: '',
                    markertype: '', area: 0.0, perimeter: 0.0, name: '',
                    coordinates: '', isDragged: false, isEdited: false
                });
            }              
        } catch (error) {
            setSavingItem(false);
            console.error('Error:', error);
        }
    }

    const handleMapItemEdited = (itemId, type, strValue) => {
        let name = '';
        let value = '';
        if (type == "polygonBoundsChanged") {
            name = 'coordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }

        const itemList = outline.outlineList.map(item =>
            item.itemId === itemId
                ? { ...item, [name]: value, isEdited: true }
                : item
        )
        setOutline({ ...outline, outlineList: itemList });
    }

     /*Item Delete*/
    const handleItemDelete = () => {
        setItemDeletePopup({ showItemdeletePopup: true, itemId: itemPopup.itemId });
    }

    const HandleItemDeletePopupClose = () => {
        setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
    }

    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        setSavingItemDelete(true);
        try {
            const response = await axios.get(
                '/api/outline/DeleteOutline/', {
                params: {
                    itemId: itemid,
                    userId: userId
                }
            }
            );
            if (response.data != null) {
                const response = await axios.get(
                    '/api/outline/GetOutline/', {
                    params: {
                        farmId: farmId
                    }
                }
                );
                setOutline({ ...outline, outlineList: response.data.outlineList });
                setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
                setItemPopup({
                    showItemPopup: false, mapitem: [],
                    farmId: farmId, id: 0, itemId: '',
                    markertype: '', area: 0.0, perimeter: 0.0, name: '',
                    coordinates: '', isDragged: false, isEdited: false
                });
                setSavingItemDelete(false);
            }      
        } catch (error) {
            setSavingItemDelete(false);
            console.error('Error:', error);
        }
    }

    /*Farm Details Save*/
    const handleFarmDetailSave = async (e) => {
        e.preventDefault()
        setSaving(true);

        try {
            let form = {
                farmId: farmId,
                IslargeEnterprise: isLargeEnterprise,
                selectedZoomlevel: mapDetails.zoomLevel,
                userId: userId
            }
            try {
                const response = await axios.post(
                    '/api/outline/UpdateOutlineFarmDetails/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
            } catch (error) {
                console.error('Error:', error);
            }

            setTimeout(() => {
                setSaving(false);
            }, 1000)
            toast.success("My Farm Details Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });

        } catch (err) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)

            if (!err?.response) {
                toast.error("No server response!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else if (err.response?.status === 400) {
                toast.error("My Farm Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            } else {
                toast.error("My Farm Details Failed!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const hasOutlineCentre = (outlineList) => {
        let farmCenter = false, farmBoundary = false;
        const FarmCenterList = outlineList.filter(type => type.mapType == 'marker');
        var FarmBoundaryList = outlineList.filter(type => type.mapType == 'polygon');
        if (FarmCenterList != null && FarmCenterList.length > 0) {
            farmCenter = true;
        }
        if (FarmBoundaryList != null && FarmBoundaryList.length > 0) {
            farmBoundary = true;
        }
        return (farmCenter && farmBoundary);
    }

    const noFarmCentreError = () => {
        const FarmCenterList = outline.outlineList.filter(type => type.mapType == 'marker');
        var farmBoundary = outline.outlineList.filter(type => type.mapType == 'polygon');
        var haveFarmBoundary = farmBoundary.length > 0 ? true : false;
        var haveFarmCenter = FarmCenterList.length > 0 ? true : false;
        if (!haveFarmBoundary && !haveFarmCenter) {
            toast.error("Please add a Orchard Centre and minimum one boundary to move forward ...", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        else if (!haveFarmCenter){
            toast.error("Please add a Orchard Centre to move forward ...", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        else if (!haveFarmBoundary){
            toast.error("Please add minimum one boundary to move forward ...", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    /*handle edit from Non map side*/
    const handleItemPopup = (itemId) => {
        editMapItem([], itemId,false);
    }

    /*handle delete from Non map side*/
    const handleItemDeletePopup = (itemId) => {
        setItemDeletePopup({ showItemdeletePopup: true, itemId: itemId });
    }

    const findMyFarm = () =>
    {
        Geocode.setApiKey("AIzaSyBYN1ILqaOOYkmN8-rk3T0ImqfQGY07Nfw");
        Geocode.setLanguage("en");
        Geocode.setRegion("nz");
        Geocode.fromAddress(outline.farmAddress).then(
            (response) => {
                const farmlocation = response.results[0].geometry.location;
                console.log(farmlocation);
                setMapDetails({ ...mapDetails, isFindmyFarm: true, zoomLevel: 15, myFarmCenter: farmlocation })
            },
            (error) => {
                toast.error("Invalid address. Please enter a valid Address in Farm settings", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        );
    }

    const mapView = useMemo(() => {
        return (
            <MapView itemModel={outline} mapDetails={mapDetails} kmlUpload={kmlUpload} width={toggleSwitch == 'map' ? '75vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                addMapItem={addMapItem}
                editMapItem={editMapItem}
                handleMapItemEdited={handleMapItemEdited}>
            </MapView>
        )
    }, [outline, mapDetails, kmlUpload, toggleSwitch])

    const nonMapView = useMemo(() => {
        return (
            <OutlineNonMap outline={outline} mapDetails={mapDetails} saving={saving} isLargeEnterprise={isLargeEnterprise}
                handleClearSelection={handleClearSelection} handleItemClick={handleItemClick}
                handlelargeEnterpriseChange={handlelargeEnterpriseChange}
                handleZoomChange={handleZoomChange} handleFarmDetailSave={handleFarmDetailSave}
                importShapeFilesClick={importShapeFilesClick}
                handleItemHighlight={handleItemHighlight}
                handleItemPopup={handleItemPopup}
                handleItemDeletePopup={handleItemDeletePopup}
                findMyFarm={findMyFarm}/>
        )
    }, [outline, saving, isLargeEnterprise, mapDetails])

    const itemTypeListView = useMemo(() => {
        return (
            <OutlineMapView outline={outline} mapDetails={mapDetails} saving={saving} isLargeEnterprise={isLargeEnterprise}
                handleClearSelection={handleClearSelection} handleItemClick={handleItemClick}
                handlelargeEnterpriseChange={handlelargeEnterpriseChange}
                handleZoomChange={handleZoomChange} handleFarmDetailSave={handleFarmDetailSave}
                importShapeFilesClick={importShapeFilesClick}
                findMyFarm={findMyFarm} />
        )
    }, [outline, saving, isLargeEnterprise, mapDetails])

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }

    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }


    return (

        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }}
            />
            <Row>
                <Col><h2>{outline.SectionName}</h2></Col>
                {outline.isWizardCompleted != true && (roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') && !loading &&
                    <Col> {hasOutlineCentre(outline.outlineList) == true ?
                        <Button className="float-end button-next" onClick={(section) => handleWizardChanged(outline.Section)}>Next <FaChevronRight /> </Button> :
                        <Button className="float-end btn-next-page-warning button-next" onClick={noFarmCentreError}>Next <FaChevronRight /> </Button>}
                    </Col>
                }
                {outline.isWizardCompleted != true && roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible && !loading &&
                    <Col> {hasOutlineCentre(outline.outlineList) == true ?
                        <Button className="float-end button-next" onClick={(section) => handleMoveToNextPage(outline.Section)}>Next <FaChevronRight /> </Button> :
                        <Button className="float-end btn-next-page-warning button-next" onClick={noFarmCentreError}>Next <FaChevronRight /> </Button>}
                    </Col>
                }
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <Col>
                                <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                            </Col>
                        </div>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col id='mapView'>
                            <Row className="map-layer">
                                <Col>
                                    <Row>
                                        <Col sm="8"></Col>
                                        <Col sm="4">
                                            <div className="map-item-edit-div">
                                                <Label className="map-checkbox-label1">Edit Item</Label>
                                                <Input className="map-item-edit-checkbox1" type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />
                                            </div>
                                        </Col>
                                    </Row>
                                    {mapView}
                                    <br />
                                    <MapImport importShapeFilesClick={importShapeFilesClick} />
                                </Col>
                            </Row>
                        </Col>
                        <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "25vw" }}>
                            <br />
                            {itemTypeListView}
                            <br />
                        </Col>
                        <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block', }}>
                            {nonMapView}
                        </Col>
                    </Row>
                </div>
            }

            <Modal size="lg" isOpen={itemPopup.showItemPopup} backdrop={false} className="outlineItemPopup">
                <Form>
                    <ModalHeader toggle={handleItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                {itemPopup.markertype == "polygon" ?
                                    <FormGroup>
                                        <Label>Please give this outline a name</Label>
                                        <label id="outlineDescription" className="control-description">If your property only has a single outline generally this can be as simple as "My Property" or the property name. If your property has multiple outlines then name the outlines appropriately such as "Main Orchard" and "Second Orchard" etc.</label>
                                        <Input type="text" name="txtShapeName" id="txtShapeName" value={itemName} onChange={handleNameChange} placeholder="Name" required />
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input type="text" name="txtShapeName" id="txtShapeName" value={itemName} readOnly disabled />
                                    </FormGroup>
                                }
                            </Col>
                        </Row>
                        {itemPopup.markertype == "polygon" &&
                            <Row xs="2" style={{display:"none"}}>
                                <Col>
                                    <FormGroup>
                                        <Label>Is this the whole Farm Outline ? </Label>
                                        {' '}
                                        <input className="isWholeFarmOutline form-check-input" id="isWholeFarmOutline" value={isWholeFarmOutline} checked={isWholeFarmOutline} type="checkbox" onChange={handleIsWholeFarmOutlineChange} />
                                       
                                    </FormGroup>
                                </Col>
                            </Row>           
                        }
                        {itemPopup.markertype == "polygon" ?
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label>Boundary Perimeter (km) : </Label><Label id="lblOutlinePerimeter">{itemPopup.perimeter}</Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Outline Size (ha) : </Label> <Label id="lblOutlinearea">{itemPopup.area}</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>GPS Location : </Label><Label id="lblOutlineloaction">{itemPopup.coordinates}</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') && 
                            <>
                                {itemPopup.id != 0 && itemPopup.markertype == "polygon" ?
                                    <>
                                        <Button onClick={handleItemDelete} color="danger" size="sm">
                                            Delete
                                        </Button>{' '}
                                    </>
                                    : ''}
                                {savingItem ?
                                    <>
                                        <Button onClick={handleItemSubmit} className="button-save" color="success" disabled size="sm">
                                            <Row>
                                                <Col sm="6">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="6">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row></Button>{' '}
                                    </>
                                    :
                                    <>
                                        <Button className="button-save" onClick={handleItemSubmit} color="success" size="sm">
                                            Save
                                        </Button>{' '}
                                    </>
                                }
                            </>    
                        }
                        <Button color="primary" onClick={handleItemPopupClose} size="sm">Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={itemDeletePopup.showItemdeletePopup}>
                <ModalHeader toggle={HandleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                        <>
                            {savingItemDelete ?
                                <>
                                    <Button className="button-save" onClick={deleteItem} color="primary" disabled >
                                        <Row>
                                            <Col sm="6">
                                                <span>
                                                    {' '}Saving
                                                </span>
                                            </Col>
                                            <Col sm="6">
                                                <Spinner size="sm">
                                                    Saving...
                                                </Spinner>
                                            </Col>
                                        </Row></Button>{' '}
                                </>
                                :
                                <>
                                    <Button className="button-save" onClick={deleteItem} color="primary" >
                                        Yes
                                    </Button>{' '}
                                </>
                            }
                        </>
                    }
                    <Button color="danger" onClick={HandleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
}
export default Outline;