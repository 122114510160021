import { useState, useEffect } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardText, Button } from 'reactstrap';
import axios from 'axios';
import uuid from 'react-uuid';
import Moment from 'moment';
import '../MyInfo/News.css';

const ViewNews = ({ }) => {
    const [loading, setLoading] = useState(true); const [news, setNews] = useState({}); const [type, setType] = useState(''); const [backText, setBackText] = useState('');
    const populateItemData = async () => {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let id = params.get('id');
            setBackText('Return To My ' + params.get('type'));
            setType(params.get('type'));
            const response = await axios.get(
                '/api/settings/GetNewsInfo/', {
                    params: { Id: id }
            }
            );
            setNews(response.data.content);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, [loading]);

    const backToUserList = async () => {
        window.open("/myinfo?Type=" + type, "_self");
    }

    return (
        <div>
            <Button color="warning" onClick={backToUserList}>{backText}</Button>
            <Row key={uuid()}>
                <Col sm="9" key={uuid()} style={{ marginTop: '10px' }}>
                    <Card>
                        <Row style={{ marginTop: '10px' }}>
                            <Col sm="9" className= "newsHeader">
                                {news.newsItemName}
                            </Col>
                            <Col sm="3" className = "news-view-editor-date">
                                {news.newsEditorName} <br/>
                                {Moment(news.newsItemDate).format('Do MMMM YYYY')}
                            </Col>
                        </Row>
                        <hr/>
                        <Row key={uuid()}>
                            <Col sm="3">
                                {news.newsItemThumbnailName ? <img alt="Card cap" src={news.newsItemThumbnailRelativePath} width="100%"
                                    onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = "/images/no-image.jpg"
                                    }} /> : <img alt="Card cap" src="/images/news-icon.png" width="100%" /> }
                            </Col>
                            <Col sm="9">
                                <Row>
                                    <Col>
                                        <CardBody>
                                            <CardText>
                                                {news.newsItemDescription}
                                            </CardText>
                                            {news.newsItemAttachementName != null && news.newsItemAttachementName != "" &&
                                                <a className="linkNews" href={news.newsItemAttachementRelativePath} target='_blank' >
                                                    Attachment : <u>{news.newsItemAttachementName}</u> (Click to open)
                                                </a>
                                            }
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default ViewNews;