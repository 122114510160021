import { useState} from 'react';
import { Row, Col, Card, CardBody, CardHeader, Table, Collapse, Button } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaMapMarkerAlt, FaDrawPolygon} from 'react-icons/fa';
import uuid from 'react-uuid';
import './Outline.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';
const OutlineItmes = ({ outline, handleItemHighlight, handleItemPopup, handleItemDeletePopup }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const roleName = localStorage.getItem("roleName");
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    const OutlineList = outline.outlineList;

    return (
        <Row>
            <Col>
                <Card>
                    <CardHeader className="item-header item_click" onClick={toggle}>
                        <Row>
                            <Col sm="11">
                                <h5 className="color-dark-teal"><FaInfoCircle data-tooltip-id="my-tooltip-8" />&nbsp;&nbsp;&nbsp;Outline items</h5>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "25vw", fontWeight: "750", wordBreak: 'normal', overflowWrap:'break-word' }}
                        id="my-tooltip-8"
                        place="bottom"
                        variant="info"
                        render={({ content, activeAnchor }) => (
                            <>
                                <Row>
                                    <Col>
                                        <p style={{ fontWeight: "lighter" }}>To change the name of an outline item click edit. To remove an outline item click delete.</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    />
                            </Col>
                            <Col sm="1" className="toggleoutline">
                                {isIconUp ? <FaChevronUp /> : <FaChevronDown />}
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={isOpen}>
                        <CardBody>
                            {<Row>
                                <Col>
                                    <table className="table borderless">
                                        <thead></thead>
                                        <tbody>
                                            <tr key={uuid()}>
                                                <td >
                                                    <Table className='table borderless '>
                                                        <thead >
                                                            <tr>
                                                                <th style={{ width: '10%' }}> </th>
                                                                <th style={{ width: '70%' }}> Item Name </th>
                                                                <th style={{ width: '10%' }}> </th>
                                                                <th style={{ width: '10%' }}> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                OutlineList.map(item =>

                                                                    <tr key={item.id}>
                                                                        <td>
                                                                            <a onMouseOver={(itemId) => handleItemHighlight(item.itemId)} onMouseOut={(itemId) => handleItemHighlight(0)}>
                                                                                {item.mapType == 'marker' ? <FaMapMarkerAlt color={"Red"}></FaMapMarkerAlt> :
                                                                                    item.mapType == 'polygon' && <FaDrawPolygon color={"Red"}></FaDrawPolygon>}
                                                                            </a>
                                                                        </td>
                                                                        <td> {item.name} </td>
                                                                        <td>
                                                                            <Button color="warning" className="button-common" size="sm" onClick={(itemId) => handleItemPopup(item.itemId)}>Edit</Button>
                                                                        </td>
                                                                        <td>
                                                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') && item.mapType != 'marker' &&
                                                                                <>
                                                                                <Button color="danger" className="button-delete" size="sm" onClick={(itemId) => handleItemDeletePopup(item.itemId)}>Delete</Button>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                            }
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Row>
    );

}
export default OutlineItmes;