import React from 'react'
import { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';
import ItemView from './ItemView';

const ItemTypeView = ({ itemList, itemType, itemTypesList, largeEnterpriseDetails, toggleItemTypeState, toggleItemState, handleItemEditPopup,
    handleItemDeletePopup, handleOpenActionPopup, handleItemTypeTogole, handleItemTogole, handleItemHighlight, Section }) => {

    const [isOpen, setIsOpen] = useState(itemType.uniqueName == toggleItemTypeState.uniqueName ? toggleItemTypeState.isOpen : false);
    const [isIconUp, setIsIconUp] = useState(itemType.uniqueName == toggleItemTypeState.uniqueName ?toggleItemTypeState.isIconUp : false);

    const toggle = (uniqueName) => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleItemTypeTogole(0, !isOpen, !isIconUp, uniqueName);
    };

    if (itemType != undefined) {
        const isLargeEnterprise = largeEnterpriseDetails != null ? largeEnterpriseDetails.isLargeEnterprise : false;
        const selectedLMUId = largeEnterpriseDetails != null ? largeEnterpriseDetails.selectedLMUId : 0;
        let itemListByType = [];
        var LMUList = Section == 'Irrigation Frost Protection' ? largeEnterpriseDetails.filteredLMUList : largeEnterpriseDetails.LMUList;
        if (isLargeEnterprise && selectedLMUId > 0) {
            const selectedLMUList = LMUList.filter(type => type.mapMarkerType == "polygon" && (type.lmuId == selectedLMUId || type.id == selectedLMUId));
            selectedLMUList.map(lmu => {
                let itemListByTypetemp = itemList.filter(item => item.itemTypeName == itemType.name && item.lmuIds.split(',').filter(x => parseInt(x) == lmu.id).length > 0);
                itemListByTypetemp.map(item => {
                    if (itemListByType.filter(x => x.itemId == item.itemId).length == 0) {
                        itemListByType.push(item)
                    }
                })

            })
        }
        else {
            itemListByType = itemList.filter(item => item.itemTypeName == itemType.name);
        }
        const itemCount = itemListByType.length;
        let openActionCount = 0;
        itemListByType.map(item =>
            openActionCount = openActionCount + item.actions.filter(action => action.isCloseout != true).length)

        return (

            itemCount > 0 && 
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={(itemTypeId, uniqueName) => toggle(itemType.uniqueName)}>
                            <Row>
                                <Col sm="11"><span className="text-uppercase fw-bold color-dark-teal">{itemType.name} </span>{' '}<span className="text-uppercase"> {itemCount + ' ITEM(S) '} {openActionCount > 0 ? ' | ' + openActionCount + ' OPEN ACTION(S)' : ''}</span></Col>
                                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={isOpen}>
                            <CardBody>
                                {itemListByType.map(item =>
                                    <ItemView key={uuid()} item={item} itemTypesList={itemTypesList}
                                        toggleItemState={toggleItemState}
                                        handleItemEditPopup={handleItemEditPopup}
                                        handleItemDeletePopup={handleItemDeletePopup}
                                        handleOpenActionPopup={handleOpenActionPopup}
                                        handleItemTogole={handleItemTogole}
                                        handleItemHighlight={handleItemHighlight}
                                        Section={Section}
                                    ></ItemView>
                                )
                                }
                            </CardBody>
                        </Collapse>
                    </Card>
                    <br />
                </Col>
            </Row>
        )

    }
}
export default ItemTypeView