import React, { useState, useEffect } from "react";
import { flushSync } from "react-dom";
import { Row, Col, Card, CardBody, Table, Button, FormGroup, Label, Input } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import uuid from 'react-uuid';
import "../ExternalOrganisation/ExternalOrganisation.css";
import Select, { components, ValueContainerProps } from 'react-select';


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const UserFarm = () => {
    const [userFarmList, setUserFarmList] = useState([]); const [catchments, setCatchments] = useState([]); const [regionalCouncils, setRegionalCouncils] = useState([]);
    const [tempUserFarms, setTempUserFarms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userSelect, setUserSelect] = useState(''); 
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [userFarms, setUserFarms] = useState([]);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [masterCheck, setMasterCheck] = useState(false);
    const [userListFilter, setUserListFilter] = useState({ filteredUserList: [], selectedRole: 0, isOrganisationShow: false, selectedOrganisation: 0, isCertifier: false, isAuditor: false });
    const [name, setName] = useState('');
    const [catchmentSelect, setCatchmentSelect] = useState([]); const [regionalCouncilSelect, setRegionalCouncilSelect] = useState([]);
    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/settings/getuserfarms/', {
                params: {}
            }
            );
            setUserFarmList(response.data.content);
            setCatchments(response.data.content.catchments);
            setRegionalCouncils(response.data.content.regionalCouncils);
            setUserListFilter({ ...userListFilter, filteredUserList: response.data.content.users })
            setUserFarms([]);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const handleUserChange = async (event) => {
        setUserSelect(event);
        if (event != null) {
            userChange(event.value)
        }
        else {
            setUserFarms([]);
            setSaveDisabled(true);
        }
    }
    const handleCatchmentChange = async (event) => {
        setCatchmentSelect(event);
        const tempRegionalCouncils = [...regionalCouncilSelect];
        var farmList = tempUserFarms;
        var farms = [];
        if (event.length == 0) {
            farms = tempUserFarms;
        }
        else {
            event.map((item) => {
                farmList.filter(x => x.catchmentId == item.value).map((obj) => {
                    farms.push(obj);
                });
            }
            )
        }
        var regionalCouncilFarms = farms;
        farms = [];
        if (tempRegionalCouncils.length > 0) {
            tempRegionalCouncils.map((item) => {
                regionalCouncilFarms.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                    farms.push(obj);
                });
            }
            )
            regionalCouncilFarms = farms;
        }
        if (name != '') {
            regionalCouncilFarms = regionalCouncilFarms.filter(x => (x.farmUniqueIdentifier.toLowerCase().includes(name) || x.farmname.toLowerCase().includes(name)));
        }
        setUserFarms(regionalCouncilFarms);
    }
    const handleRegionalCouncilChange = async (event) => {
        setRegionalCouncilSelect(event);
        const tempCatchments = [...catchmentSelect];
        var farmList = tempUserFarms;
        var farms = [];
        if (event.length == 0) {
            farms = tempUserFarms;
        }
        else {
            event.map((item) => {
                farmList.filter(x => x.regionalCouncilId == item.value).map((obj) => {
                    farms.push(obj);
                });
            }
            )
        }

        var catchmentFarms = farms;
        farms = [];
        if (tempCatchments.length > 0) {
            tempCatchments.map((item) => {
                catchmentFarms.filter(x => x.catchmentId == item.value).map((obj) => {
                    farms.push(obj);
                });
            }
            )
            catchmentFarms = farms;
        }

        if (name != '') {
            catchmentFarms = catchmentFarms.filter(x => (x.farmUniqueIdentifier.toLowerCase().includes(name) || x.farmname.toLowerCase().includes(name)));
        }
        setUserFarms(catchmentFarms);
    }
    const userChange = async (userId) => {
        const response = await axios.get(
            '/api/settings/UserChange/', {
                params: { Id: userId }
        }
        );
        if (response.data.status) {
            setUserFarms(response.data.content);
            setTempUserFarms(response.data.content);
            setSaveDisabled(false);
            setName('');
            setRegionalCouncilSelect('');
            setCatchmentSelect('');
        }
    }

    const onItemCheck = async (e, item1) => {
        let tempList = userFarms;
        tempList.map((userFarm) => {
            if (userFarm.farmId === item1.farmId) {
                userFarm.farmAccess = e.target.checked;
            }
            return userFarm;
        });
        
        flushSync(() => {
            setUserFarms(prevUserFarms => {
                const newUserFarms = [...prevUserFarms];
                return newUserFarms;
            });
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSaveDisabled(true);
        let form = {
            UserId: userSelect.value,
            Farms: [],
            ChangedUserId: userId
        }
        for (var i = 0; i < tempUserFarms.length; i++) {
            var obj = {};
            obj.FarmId = tempUserFarms[i].farmId;
            var userFarm = userFarms.filter(x => x.farmId == tempUserFarms[i].farmId)[0];
            obj.FarmAccess = userFarm == undefined ? tempUserFarms[i].farmAccess  : userFarm.farmAccess;
            form.Farms.push(obj);
        }
        const response = await axios.post(
            '/api/settings/SaveUserFarms/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("User to orchards Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSaveDisabled(false);
                userChange(userSelect.value);
                setName('');
                setRegionalCouncilSelect('');
                setCatchmentSelect('');
            }
        }
    }

    const onMasterCheck = async(e) => {
        let tempList = userFarms;
        tempList.map((item) => (item.farmAccess = e.target.checked));
        flushSync(() => {
            setMasterCheck(e.target.checked);
            setUserFarms(prevUserFarms => {
                const newUserFarms = [...prevUserFarms];
                return newUserFarms;
            });
        });
    }

    const handleChangeName = async (event) => {
        setName(event.target.value);
        var list = [];
        list = tempUserFarms;
        list = tempUserFarms.filter(x => (x.farmUniqueIdentifier.toLowerCase().includes(event.target.value.toLowerCase()) || x.farmname.toLowerCase().includes(event.target.value.toLowerCase())));
        setUserFarms(list);
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    User to Orchard
                </h2>
                <CardBody>
                    <Row xs="4">
                        <Col className="d-flex">
                            <FormGroup>
                                <Label className="label" for="userSelect">User</Label>
                                {userListFilter.filteredUserList == undefined && <p><em>Loading...</em></p>}
                                {userListFilter.filteredUserList != undefined && userListFilter.filteredUserList != null &&
                                    <Select
                                    id="userSelect" width="max-content"
                                        name="userSelect"
                                        closeMenuOnSelect={true}
                                        className="dropdown"
                                        placeholder="Select User"
                                        value={userSelect}
                                        options={userListFilter.filteredUserList}
                                        onChange={handleUserChange}
                                        isClearable hideSelectedOptions={false}
                                    />
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm="1">
                        <br/>
                            <Input type="checkbox" checked={masterCheck} id="masterCheck" onChange={(e) => onMasterCheck(e)} disabled={saveDisabled} />&nbsp;&nbsp;&nbsp;&nbsp;Select All
                        </Col>
                        <Col sm="2">
                            <FormGroup>
                                <br />
                                <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="Search farm..." />
                            </FormGroup>
                        </Col>
                        <Col sm="2">
                            <FormGroup>
                                <Label className="label" for="catchmentSelect">Catchment</Label>
                                {catchments == undefined && <p><em>Loading...</em></p>}
                                {catchments != undefined && catchments.length > 0 &&
                                    <Select
                                    id="catchmentSelect" isMulti width="max-content"
                                    name="catchmentSelect"
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Catchment"
                                    value={catchmentSelect}
                                    options={catchments}
                                    onChange={handleCatchmentChange}
                                    isClearable hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    />
                                }
                            </FormGroup>
                        </Col>
                        <Col sm="2">
                            <FormGroup>
                                <Label className="label" for="regionalCouncilSelect">Regional Council</Label>
                                {catchments == undefined && <p><em>Loading...</em></p>}
                                {catchments != undefined && catchments.length > 0 &&
                                    <Select
                                    id="regionalCouncilSelect" isMulti width="max-content"
                                    name="regionalCouncilSelect"
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Regional Council"
                                    value={regionalCouncilSelect}
                                    options={regionalCouncils}
                                    onChange={handleRegionalCouncilChange}
                                    isClearable hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    />
                                }
                            </FormGroup>
                        </Col>
                        <Col>
                            <br />
                            <Button disabled={saveDisabled} id="btnSave" style={{ float: "right" }}  onClick={handleSubmit} className="btn button-save">Save</Button>
                        </Col>
                    </Row>
                    
                    <Row className ="userFarm-tabledetails">
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Orchard Access</th>
                                        <th>Orchard Identifier</th>
                                        <th>Orchard Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userFarms == undefined &&
                                        <tr><td colSpan="3" style={{ textAlign: "center" }}>Loading...</td></tr>
                                    }
                                    {userFarms != undefined && userFarms != null && userFarms.map(item => (
                                        <tr key={uuid()}>
                                            <td><Input type="checkbox" checked={item.farmAccess} key={item.farmId} id={"chk_" + item.farmId} onChange={(e) => onItemCheck(e, item)} /></td>
                                            <td>{item.farmUniqueIdentifier}</td>
                                            <td>{item.farmname}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default UserFarm;
