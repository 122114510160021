import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Table } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';
import "../ExternalOrganisation/ExternalOrganisation.css";
import Validate from '../Validate/Validate';
import { FaChevronLeft, FaUpload, FaTrash } from 'react-icons/fa';
import { useLocation, useSearchParams } from "react-router-dom";

const CreateExternalOrganisation = () => {
    const { state } = useLocation();
    const [externalOrganisationList, setExternalOrganisationList] = useState([]);
    const [externalOrganisation, setExternalOrganisation] = useState({ id: 0, name: '', organisationType: 0, description: '', externalLink: '', showCatchmentItems :false});
    const [logoDetails, setLogoDetails] = useState({ logoFile: '', logoImagePreviewUrl: '', logoName: '', logoPhysicalFilename: '', logoPhysicalFilepath :''});
    const [imageDetails, setImageDetails] = useState({ imageFile: '', imagePreviewUrl: '', imageName: '', imagePhysicalFilename: '', imagePhysicalFilepath: '' });
    const [priorityContaminantList, setPriorityContaminantList] = useState([]);
    const [priorityContaminantListSelectedValues, setPriorityContaminantListSelectedValues] = useState([]);
    const [priorityContaminantSelectedValues, setPriorityContaminantSelectedValues] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [uploadDocumentDetails, setUploadDocumentDetails] = useState({ documentDownloadUrl: '', uploadDocumentName: '', uploadDocumentPhysicalFilename: '', uploadDocumentPhysicalFilepath: ''});
    const [externalOrganisationLinkRows, setExternalOrganisationLinkRows] = useState([]);
    let inputDocumentFile = '';
    let inputLogoFile = '';
    let inputImageFile = ''; const [searchParams, setSearchParams] = useSearchParams(); const [id, setId] = useState(0); const [organisationType, setOrganisationType] = useState(0); const [name, setName] = useState('');

    const populateItemData = async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        setId(params.get('id'));
        setOrganisationType(params.get('organisationType'));
        setName(params.get('name'));
        if (searchParams.has('id')) {
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
        if (searchParams.has('organisationType')) {
            searchParams.delete('organisationType');
            setSearchParams(searchParams);
        }
        if (searchParams.has('name')) {
            searchParams.delete('name');
            setSearchParams(searchParams);
        }
        const response = await axios.get(
            '/api/settings/GetExternalOrganisations/', {
            params: {}
        }
        );
        setExternalOrganisationList(response.data.content);
        setPriorityContaminantList(response.data.content.priorityContaminants);
        setLogoDetails({ ...logoDetails, logoFile: null, logoImagePreviewUrl: null, logoName: null, logoPhysicalFilename: null, logoPhysicalFilepath: null })
        setImageDetails({ ...imageDetails, imageFile: null, imagePreviewUrl: null, imageName: null, imagePhysicalFilename: null, imagePhysicalFilepath: null });
        setExternalOrganisation({ ...externalOrganisation, id: 0, name: '', organisationType: 0, description: '', externalLink: '', showCatchmentItems: false, catchmentInformation: '', culturalSignificance: '', tangataWhenuaIwiHapuRunanga: '', freshwaterValues: '', significantBiodiversity: '' });
        setExternalOrganisationLinkRows([]);
    }

    const handleExternalOrganisationLinkNameChange = idx => e => {
        const tempRows = [...externalOrganisationLinkRows];
        tempRows[idx].name = e.target.value;
        setExternalOrganisationLinkRows(tempRows);
    };
    const handleExternalOrganisationLinkChange = idx => e => {
        const tempRows = [...externalOrganisationLinkRows];
        tempRows[idx].link = e.target.value;
        setExternalOrganisationLinkRows(tempRows);
    };
    const handleExternalOrganisationLinkRowsAddRow = () => {
        const item = {
            name: "", link: "", id: 0
        };
        setExternalOrganisationLinkRows([...externalOrganisationLinkRows, item]);
    };
    const handleExternalOrganisationLinkRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...externalOrganisationLinkRows]
        tempRows.splice(idx, 1)
        setExternalOrganisationLinkRows(tempRows);
    }

    useEffect(() => {
        populateItemData();
    }, [])

    const handleChangeName = async (event) => {
        setExternalOrganisation({ ...externalOrganisation, name: event.target.value });
    }

    const handleChangeOrganisationType = async (event) => {
        if (event.target.selectedOptions[0].text == 'Catchment') {
            setExternalOrganisation({ ...externalOrganisation, showCatchmentItems: true, organisationType: event.target.value });
        }
        else {
            setExternalOrganisation({ ...externalOrganisation, showCatchmentItems: false, organisationType: event.target.value });
            setPriorityContaminantListSelectedValues([]);
            setUploadDocumentDetails({ ...uploadDocumentDetails, documentDownloadUrl: null, uploadDocumentName: null, uploadDocumentPhysicalFilename: null, uploadDocumentPhysicalFilepath: null });
        }
    }

    const handleChangeDescription = async (event) => {
        setExternalOrganisation({ ...externalOrganisation, description: event.target.value });
    }

    const handleChangeExternalLink = async (event) => {
        setExternalOrganisation({ ...externalOrganisation, externalLink: event.target.value });
    }

    const closeExternalOrganisationPopup = async () => {
        window.location = '/externalorganisation?organisationType=' + organisationType + '&name=' + name;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let tempExternalOrganisationLinkRows = [...externalOrganisationLinkRows];
        let form = {
            Id: externalOrganisation.id,
            Name: externalOrganisation.name,
            OrganisationType: externalOrganisation.organisationType,
            Description: externalOrganisation.description,
            LogoName: logoDetails.logoName,
            LogoPhysicalName: logoDetails.logoPhysicalFilename,
            LogoPath: logoDetails.logoPhysicalFilepath,
            ImageName: imageDetails.imageName,
            ImagePhysicalName: imageDetails.imagePhysicalFilename,
            ImagePath: imageDetails.imagePhysicalFilepath,
            ExternalLink: externalOrganisation.externalLink,
            CatchmentInformation: externalOrganisation.catchmentInformation,
            FreshwaterValues: externalOrganisation.freshwaterValues,
            SignificantBiodiversity: externalOrganisation.significantBiodiversity,
            TangataWhenuaIwiHapuRunanga: externalOrganisation.tangataWhenuaIwiHapuRunanga,
            CulturalSignificance: externalOrganisation.culturalSignificance,
            PriorityContaminants: priorityContaminantSelectedValues.toString(),
            UserId: userId,
            CatchmentDocumentName: uploadDocumentDetails.uploadDocumentName,
            CatchmentDocumentPhysicalName: uploadDocumentDetails.uploadDocumentPhysicalFilename,
            CatchmentDocumentPath: uploadDocumentDetails.uploadDocumentPhysicalFilepath,
            ExternalOrganisationLinks:[]
        }
        for (var i = 0; i < tempExternalOrganisationLinkRows.length; i++) {
            var obj = {};
            obj.Id = tempExternalOrganisationLinkRows[i].id;
            obj.Link = tempExternalOrganisationLinkRows[i].link;
            obj.Name = tempExternalOrganisationLinkRows[i].name;
            form.ExternalOrganisationLinks.push(obj);
        }
        if (!Validate(form)) {
            const response = await axios.post(
                '/api/settings/SaveExternalOrganisations/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("External Organisation Created!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.open("/externalorganisation", "_self");
                    }, 1500);
                }
                else if (response.data.message == 'External Organisation already exists in the system') {
                    toast.error("External Organisation already exists in the system!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                }
            }
        }
    }

    const handleLogoChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: "ExternalOrganisation",
                    Base64Image: result
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                setLogoDetails({ ...logoDetails, logoImagePreviewUrl: res.data.content.RelativeFilepath, logoPhysicalFilename: res.data.content.PhysicalFilename })
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            setLogoDetails({ ...logoDetails, logoFile: file, logoImagePreviewUrl: reader.result, logoName: file.name})
        }

        reader.readAsDataURL(file)
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: "ExternalOrganisation",
                    Base64Image: result
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                setImageDetails({ ...imageDetails, imagePreviewUrl: res.data.content.RelativeFilepath, imagePhysicalFilename: res.data.content.PhysicalFilename, imagePhysicalFilepath: res.data.content.PhysicalFilepath });
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            setImageDetails({ ...imageDetails, imageFile: file, imagePreviewUrl: reader.result, imageName: file.name });
        }

        reader.readAsDataURL(file)
    }

    const getBase64 = async (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleChange = (e) => {
        setPriorityContaminantSelectedValues('');
        var priorityContaminantSelectedValues = '';
        setPriorityContaminantListSelectedValues(e);
        e.forEach(function (element) {
            if (priorityContaminantSelectedValues == '') {
                priorityContaminantSelectedValues = element.value;
            }
            else {
                priorityContaminantSelectedValues = priorityContaminantSelectedValues + ',' + element.value;
            }
        });
        setPriorityContaminantSelectedValues(priorityContaminantSelectedValues);
    }

    const handleBtnLogoUploadClick = async (e) => {
        e.preventDefault();
        inputLogoFile.click();
        return false;
    }

    const handleBtnImageUploadClick = async (e) => {
        e.preventDefault();
        inputImageFile.click();
        return false;
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }
    const handleBtnDocumentUploadClick = async (e) => {
        e.preventDefault();
        inputDocumentFile.click();
        return false;
    }
    const handleDocumentChange = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.DocumentName = "Only Pdf files can upload!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            const formData = new FormData();
            formData.append("Type", "Catchment");
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            fetch('api/uploaddocument/UploadDocument', {
                method: 'POST',
                body: formData
            }).then(r => r.json()).then(res => {
                if (res.status) {
                    setUploadDocumentDetails({ ...uploadDocumentDetails, documentDownloadUrl: res.content.RelativeFilepath, uploadDocumentName: res.content.DocumentName, uploadDocumentPhysicalFilename: res.content.DocumentPhysicalName, uploadDocumentPhysicalFilepath: res.content.DocumentPath });
                }
            });
        }
        else {
            setUploadDocumentDetails({ ...uploadDocumentDetails,  uploadDocumentPhysicalFilename: '', uploadDocumentPhysicalFilepath: '' });
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Add Organisation
                </h2>
                <CardBody>
                    <div >
                        <Form onSubmit={handleSubmit}>
                            <Row xs="3">
                                <Col sm="6">
                                    <FormGroup>
                                        <Label className="label" for="name">Name</Label>
                                        <Input className="validate" type="text" value={externalOrganisation.name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label className="label" for="organisationType">Type Of Organisation</Label>
                                        {externalOrganisationList.organisationType == undefined && <p><em>Loading...</em></p>}
                                        {externalOrganisationList.organisationType != undefined && externalOrganisationList.organisationType != null &&
                                            <Input className="validate" type="select" value={externalOrganisation.organisationType} onChange={e => handleChangeOrganisationType(e)} name="OrganisationType" id="OrganisationType">
                                                {externalOrganisationList.organisationType.filter(x => x.name != "Supply Company").map(items =>
                                                    <option key={uuid()}  value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="description">Description</Label>
                                        <Input type="textarea" onChange={handleChangeDescription} value={externalOrganisation.description} name="description" id="description" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="externallink">External Link</Label>
                                        {/*<Input type="textarea" onChange={handleChangeExternalLink} value={externalOrganisation.externalLink} name="externallink" id="externallink" />*/}
                                        <Row>
                                            <Col>
                                                <button onClick={handleExternalOrganisationLinkRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                    Add
                                                </button>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{overflow:"auto",height:"150px"}}>
                                            <Col>
                                                <Table className="table table-bordered table-hover" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                    <thead>
                                                        <th colSpan="1" rowSpan="1" style={{ width: '45%', textAlign: 'center' }}> Name </th>
                                                        <th colSpan="1" rowSpan="1" style={{ width: '45%', textAlign: 'center' }}> Link </th>
                                                        <th />
                                                    </thead>
                                                    <tbody>
                                                        {externalOrganisationLinkRows.map((item, idx) => (
                                                            <tr id="addr0" key={idx}>
                                                                <td style={{ display: "none" }}>{externalOrganisationLinkRows[idx].id}</td>
                                                                <td style={{ width: "45%" }}>
                                                                    <input type="text" name="name" value={externalOrganisationLinkRows[idx].name} onChange={handleExternalOrganisationLinkNameChange(idx)} className="form-control" />
                                                                </td>
                                                                <td style={{ width: "45%" }}>
                                                                    <input type="text" name="link" value={externalOrganisationLinkRows[idx].link} onChange={handleExternalOrganisationLinkChange(idx)} className="form-control" />
                                                                </td>
                                                                <td style={{ width: "5%" }}>
                                                                    <button style={{ float: "right" }} onClick={handleExternalOrganisationLinkRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                                        <FaTrash />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                externalOrganisation.showCatchmentItems &&
                                <div>
                                    <Row xs="2">
                                        <Col>
                                            <FormGroup>
                                                <Label className="label" for="significantspecies">Priority Contaminants</Label>
                                                <Select className="dropdown" placeholder="Select Option" value={priorityContaminantListSelectedValues} options={priorityContaminantList} onChange={handleChange} isMulti isClearable />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                    <Label className="label" for="externallink">Catchment Information</Label>
                                                    <Input type="textarea" onChange={(e) => setExternalOrganisation({ ...externalOrganisation, catchmentInformation: e.target.value })} value={externalOrganisation.catchmentInformation} name="catchmentInformation" id="catchmentInformation" />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                    <Label className="label" for="externallink">Freshwater Values, Priorities, or Outcomes</Label>
                                                    <Input type="textarea" onChange={(e) => setExternalOrganisation({ ...externalOrganisation, freshwaterValues: e.target.value })} value={externalOrganisation.freshwaterValues} name="freshwaterValues" id="freshwaterValues" />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                    <Label className="label" for="externallink">Significant Biodiversity</Label>
                                                    <Input type="textarea" onChange={(e) => setExternalOrganisation({ ...externalOrganisation, significantBiodiversity: e.target.value })} value={externalOrganisation.significantBiodiversity} name="significantBiodiversity" id="significantBiodiversity" />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                    <Label className="label" for="externallink">Name Of The Relevant Tangata Whenua, Iwi, Hapū Or Rūnanga</Label>
                                                    <Input type="textarea" onChange={(e) => setExternalOrganisation({ ...externalOrganisation, tangataWhenuaIwiHapuRunanga: e.target.value })} value={externalOrganisation.tangataWhenuaIwiHapuRunanga} name="tangataWhenuaIwiHapuRunanga" id="tangataWhenuaIwiHapuRunanga" />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                    <Label className="label" for="externallink">Cultural Significance Of The Catchment, Including Te Ao Maori Values Where Appropriate</Label>
                                                    <Input type="textarea" onChange={(e) => setExternalOrganisation({ ...externalOrganisation, culturalSignificance: e.target.value })} value={externalOrganisation.culturalSignificance} name="culturalSignificance" id="culturalSignificance" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Logo</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {logoDetails.logoImagePreviewUrl && <img className="settings-image-style" src={logoDetails.logoImagePreviewUrl} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, logoDetails.logoName, logoDetails.logoImagePreviewUrl)} />}
                                                <br />
                                                {logoDetails.logoImagePreviewUrl ?
                                                    <Button color="warning" size="sm" onClick={handleBtnLogoUploadClick} ><FaUpload /> Modifiy Logo</Button> :
                                                    <Button color="success" size="sm" onClick={handleBtnLogoUploadClick} ><FaUpload /> Upload Logo</Button>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputLogoFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={(e) => handleLogoChange(e)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col style={{display:"none"}}>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Image</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {imageDetails.imagePreviewUrl && <img className="settings-image-style" src={imageDetails.imagePreviewUrl} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, imageDetails.imageName, imageDetails.imagePreviewUrl)} />}
                                                <br />
                                                {imageDetails.imagePreviewUrl ?
                                                    <Button color="warning" size="sm" onClick={handleBtnImageUploadClick} ><FaUpload /> Modifiy Image</Button> :
                                                    <Button color="success" size="sm" onClick={handleBtnImageUploadClick} ><FaUpload /> Upload Image</Button>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputImageFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleImageChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                {externalOrganisation.showCatchmentItems && <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Catchment Document</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {uploadDocumentDetails.documentDownloadUrl && <a href={uploadDocumentDetails.documentDownloadUrl} target='_blank'>
                                                    <img src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img> <span >{uploadDocumentDetails.uploadDocumentName}</span>
                                                    <br />

                                                </a>}
                                                <br />
                                                {uploadDocumentDetails.documentDownloadUrl ?
                                                    <Button color="warning" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Modifiy Document</Button> :
                                                    <Button color="success" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Upload Document</Button>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputDocumentFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleDocumentChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    {formDocumentErrors.DocumentName != undefined &&
                                        <p className="errorMessage">{formDocumentErrors.DocumentName}</p>
                                    }
                                </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    <input type="submit" style={{ float: "right" }} value="Save" size="sm" className="btn button-save" />
                                    <Button className="button-normal" style={{ float: "right", marginRight: "10px" }} color="warning" onClick={closeExternalOrganisationPopup}><FaChevronLeft /> Back To List</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>

            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default CreateExternalOrganisation;