import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, Button, Card, CardBody, Spinner, CardHeader } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { FaPlus, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';

const StockNumbers = ({ userId }) => {

    const [loading, setLoading] = useState(true); const [savingStockNumbers, setSavingStockNumbers] = useState(''); const [stockNumbersRows, setStockNumbersRows] = useState([]); const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [stockNumberTypes, setStockNumberTypes] = useState([]); const [stockNumberClasses, setStockNumberClasses] = useState([]); const [noStockOnThisFarm, setNoStockOnThisFarm] = useState(false);
    const [stockNumberBreeds, setStockNumberBreeds] = useState([]);
    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/enterprises/GetStockNumbers/', {
                params: { Id: farmId }
            }
            );
            setStockNumbersRows(response.data.content.farmStockNumbers);
            setStockNumberTypes(response.data.content.stockNumberTypes);
            setStockNumberClasses(response.data.content.stockNumberClasses);
            setStockNumberBreeds(response.data.content.stockNumberBreeds);
            setNoStockOnThisFarm(response.data.content.enterprises.isNoStockOnThisFarm);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, [loading]);

    const handleStockNumbersTypeChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].type = parseInt(e.target.value);
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersBreedChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].breed = parseInt(e.target.value);
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersClassChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].class = parseInt(e.target.value);
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersOtherTypeChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].otherType = e.target.value;
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersOtherClassChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].otherClass = e.target.value;
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersPeakNumbersChange = idx => e => {
        const tempRows = [...stockNumbersRows];
        tempRows[idx].peakNumbers = e.target.value;
        setStockNumbersRows(tempRows);
    };

    const handleStockNumbersRowsAddRow = () => {
        const item = {
            type: 0, otherType: "",breed:0, otherClass: "", class: 0, peakNumbers: "", id: 0
        };
        setStockNumbersRows([...stockNumbersRows, item]);
    };

    const handleStockNumbersRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...stockNumbersRows]
        tempRows.splice(idx, 1)
        setStockNumbersRows(tempRows);
    }

    const handleStockNumbersRowsSubmit = async (e) => {
        e.preventDefault()
        setSavingStockNumbers(true);
        var noStockOnThisFarmValue = document.getElementById("noStockOnThisFarm").checked;
        let tempStockNumbersRows = [...stockNumbersRows];
        let form = {
            FarmId: farmId,
            IsNoStockOnThisFarm: noStockOnThisFarmValue,
            FarmStockNumbers: [],
            UserId: userId
        }
        for (var i = 0; i < tempStockNumbersRows.length; i++) {
            var obj = {};
            obj.Id = tempStockNumbersRows[i].id;
            obj.FarmId = farmId;
            obj.Type = tempStockNumbersRows[i].type;
            obj.OtherType = tempStockNumbersRows[i].otherType;
            obj.Breed = tempStockNumbersRows[i].breed;
            obj.Class = tempStockNumbersRows[i].class;
            obj.OtherClass = tempStockNumbersRows[i].otherClass;
            if (tempStockNumbersRows[i].peakNumbers != null && tempStockNumbersRows[i].peakNumbers != undefined && tempStockNumbersRows[i].peakNumbers != "") {
                obj.PeakNumbers = tempStockNumbersRows[i].peakNumbers.toString();
            }
            else {
                obj.PeakNumbers = "";
            }
            form.FarmStockNumbers.push(obj);
        }
        const response = await axios.post(
            '/api/enterprises/SaveFarmStockNumbers/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Stock Numbers Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setSavingStockNumbers(false);
                setStockNumbersRows(response.data.content);
            }
        }
    };

    return (
        <Card className="my-4" style={{ display: "none" }}>
            <CardHeader className="card-header card-header-dark d-flex justify-content-between align-items-center">
                <h5 >
                    Stock Numbers 
                </h5>
                <button onClick={handleStockNumbersRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm btn-pluse">
                    Add
                </button>
            </CardHeader>
            
            <CardBody>
                
                <Row>
                    <Col>
                        <Label style={{ float: "right", marginLeft: "10px" }}>No Stock on this farm</Label>
                        <Input style={{ float: "right" }} type="checkbox" checked={noStockOnThisFarm} onChange={(e) => setNoStockOnThisFarm(!noStockOnThisFarm)} name="noStockOnThisFarm" id="noStockOnThisFarm" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm="12">
                        <table style={{ float: "right", marginTop: "-10px" }} className="table table-bordered table-hover" id="tab_logic">
                            <thead>
                                <tr>
                                    <th className="text-center"> Type </th>
                                    <th className="text-center"> If Type Other, specify </th>
                                    <th className="text-center"> Breed </th>
                                    <th className="text-center"> Class </th>
                                    <th className="text-center"> if Class Other, specify </th>
                                    <th className="text-center"> Peak Numbers </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {stockNumbersRows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{ display: "none" }}>{stockNumbersRows[idx].id}</td>
                                        <td style={{ width: "20%" }}>
                                            {stockNumberTypes == undefined && <p><em>Loading...</em></p>}
                                            {stockNumberTypes != undefined && stockNumberTypes != null &&
                                                <Input type="select" value={stockNumbersRows[idx].type} onChange={handleStockNumbersTypeChange(idx)} name="type" id="type">
                                                    {stockNumberTypes.map(items =>
                                                        <option key={uuid()} value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            }
                                        </td>
                                        <td style={{ width: "20%" }}>
                                            <input
                                                type="text"
                                                name="otherType"
                                                value={stockNumbersRows[idx].otherType}
                                                onChange={handleStockNumbersOtherTypeChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "20%" }}>
                                            {stockNumberBreeds == undefined && <p><em>Loading...</em></p>}
                                            {stockNumberBreeds != undefined && stockNumberBreeds != null &&
                                                <Input type="select" value={stockNumbersRows[idx].breed} onChange={handleStockNumbersBreedChange(idx)} name="breed" id="breed">
                                                    {stockNumberBreeds.filter(x => x.stockTypeId == item.type).map(items =>
                                                        <option key={uuid()} value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            }
                                        </td>
                                        <td style={{ width: "20%" }}>
                                            {stockNumberClasses == undefined && <p><em>Loading...</em></p>}
                                            {stockNumberClasses != undefined && stockNumberClasses != null &&
                                                <Input type="select" value={stockNumbersRows[idx].class} onChange={handleStockNumbersClassChange(idx)} name="class" id="class">
                                                    {stockNumberClasses.filter(x => x.stockTypeId == item.type).map(items =>
                                                        <option key={uuid()} value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            }
                                        </td>
                                        <td style={{ width: "20%" }}>
                                            <input
                                                type="text"
                                                name="otherClass"
                                                value={stockNumbersRows[idx].otherClass}
                                                onChange={handleStockNumbersOtherClassChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "15%" }}>
                                            <input
                                                type="text"
                                                name="peakNumbers"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                value={stockNumbersRows[idx].peakNumbers}
                                                onChange={handleStockNumbersPeakNumbersChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td style={{ width: "5%" }}>
                                            <button style={{ float: "right" }} onClick={handleStockNumbersRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {savingStockNumbers ?
                            <Button style={{ float: "right" }} onClick={handleStockNumbersRowsSubmit} color="success" disabled>
                                <Spinner size="sm">Saving...</Spinner><span>{' '}Saving</span>
                            </Button>
                            :
                            <Button style={{ float: "right" }} onClick={handleStockNumbersRowsSubmit} color="success" size="sm">
                                Save
                            </Button>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default StockNumbers;