import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Table, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import Select from 'react-select';
import { FaTrash, FaDownload } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';
import "../ExternalOrganisation/ExternalOrganisation.css";
import Validate from '../Validate/Validate';
import { FaChevronLeft, FaUpload } from 'react-icons/fa';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const EditExternalOrganisation = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [externalOrganisationList, setExternalOrganisationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [showUploadDocumentPopup, setShowUploadDocumentPopup] = useState(false);
    const [id, setId] = useState(0); const [name, setName] = useState('');
    const [organisationType, setOrganisationType] = useState(0);
    const [supplyType, setSupplyType] = useState(0);
    const [description, setDescription] = useState('');
    const [externalLink, setExternalLink] = useState('');
    const [catchmentInformation, setCatchmentInformation] = useState('');
    const [freshwaterValues, setFreshwaterValues] = useState('');
    const [significantBiodiversity, setSignificantBiodiversity] = useState('');
    const [tangataWhenuaIwiHapuRunanga, setTangataWhenuaIwiHapuRunanga] = useState('');
    const [culturalSignificance, setCulturalSignificance] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [logoImagePreviewUrl, setLogoImagePreviewUrl] = useState('');
    const [logoName, setLogoName] = useState('');
    const [logoPhysicalFilename, setLogoPhysicalFilename] = useState('');
    const [logoPhysicalFilepath, setLogoPhysicalFilepath] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [imageName, setImageName] = useState('');
    const [imagePhysicalFilename, setImagePhysicalFilename] = useState('');
    const [imagePhysicalFilepath, setImagePhysicalFilepath] = useState('');
    const [showCatchmentItems, setShowCatchmentItems] = useState(false);
    const [priorityContaminantList, setPriorityContaminantList] = useState([]);
    const [priorityContaminantListSelectedValues, setPriorityContaminantListSelectedValues] = useState([]);
    const [priorityContaminantSelectedValues, setPriorityContaminantSelectedValues] = useState('');
    const [showSupplyCompanyItems, setShowSupplyCompanyItems] = useState(false);
    const [editPopupTitle, setEditPopupTitle] = useState('Add External Organisation');
    const [showUploadDocumentItems, setShowUploadDocumentItems] = useState(false);
    const [uploadDocumentDetail, setUploadDocumentDetail] = useState('');
    const [uploadDocumentName, setUploadDocumentName] = useState('');
    const [uploadDocumentPhysicalFilename, setUploadDocumentPhysicalFilename] = useState('');
    const [uploadDocumentPhysicalFilepath, setUploadDocumentPhysicalFilepath] = useState('');
    const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(0);
    const [documentUploadList, setDocumentUploadList] = useState([]);
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    const [documentDownloadUrl, setDocumentDownloadUrl] = useState('');
    const [uploadDocumentName1, setUploadDocumentName1] = useState('');
    const [formDocumentErrors1, setFormDocumentErrors1] = useState({});
    const [uploadDocumentPhysicalFilename1, setUploadDocumentPhysicalFilename1] = useState('');
    const [uploadDocumentPhysicalFilepath1, setUploadDocumentPhysicalFilepath1] = useState('');
    let inputDocumentFile = '';
    let inputLogoFile = '';
    let inputImageFile = '';
    const [externalOrganisationLinkRows, setExternalOrganisationLinkRows] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams(); const [idValue, setIdValue] = useState(0);
    const [organisationTypeValue, setOrganisationTypeValue] = useState(0); const [nameValue, setNameValue] = useState('');
    const populateItemData = async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        setIdValue(params.get('id'));
        setOrganisationTypeValue(params.get('organisationType'));
        setNameValue(params.get('name'));
        if (searchParams.has('id')) {
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
        if (searchParams.has('organisationType')) {
            searchParams.delete('organisationType');
            setSearchParams(searchParams);
        }
        if (searchParams.has('name')) {
            searchParams.delete('name');
            setSearchParams(searchParams);
        }
        const response = await axios.get(
            '/api/settings/GetExternalOrganisations/', {
            params: {}
        }
        );
        var externalOrganisation = response.data.content.externalOrganisations.filter(x => x.id == params.get('id'));
        setExternalOrganisationList(response.data.content);
        setLoading(false);
        setOrganisationType(response.data.content.organisationType[0].id);
        setPriorityContaminantList(response.data.content.priorityContaminants);
        var item = externalOrganisation[0];
        setDocumentUploadList(item.documentUploadList);
        setEditPopupTitle('Edit External Organisation');
        setExternalOrganisationLinkRows(externalOrganisation[0].externalOrganisationLinks);
        if (item.selectedPriorityContaminantList.length > 0) {
            var priorityContaminantSelectedValues = '';
            item.selectedPriorityContaminantList.forEach(function (element) {
                if (priorityContaminantSelectedValues == '') {
                    priorityContaminantSelectedValues = element.value;
                }
                else {
                    priorityContaminantSelectedValues = priorityContaminantSelectedValues + ',' + element.value;
                }
            });
            setPriorityContaminantSelectedValues(priorityContaminantSelectedValues);
            setPriorityContaminantListSelectedValues(item.selectedPriorityContaminantList);
        }
        else {
            setPriorityContaminantListSelectedValues([]);
        }
        setId(item.id);
        setName(item.name);
        setDescription(item.description);
        setOrganisationType(item.organisationType);
        if (item.supplyType != null) {
            setSupplyType(item.supplyType);
        }
        setExternalLink(item.externalLink);
        setCatchmentInformation(item.catchmentInformation);
        setFreshwaterValues(item.freshwaterValues);
        setSignificantBiodiversity(item.significantBiodiversity);
        setTangataWhenuaIwiHapuRunanga(item.tangataWhenuaIwiHapuRunanga);
        setCulturalSignificance(item.culturalSignificance);
        if (item.organisationTypeText == 'Catchment') {
            setShowCatchmentItems(true);
        }
        else {
            setShowCatchmentItems(false);
        }
        if (item.logoName != null) {
            setLogoName(item.logoName);
            setLogoPhysicalFilename(item.logoPhysicalName);
            setLogoPhysicalFilepath(item.logoPath);
            setLogoImagePreviewUrl(item.logoRelativePath);
        }
        else {
            setLogoName(null);
            setLogoPhysicalFilename(null);
            setLogoPhysicalFilepath(null);
            setLogoImagePreviewUrl(null);
        }
        if (item.imageName != null) {
            setImageName(item.imageName);
            setImagePhysicalFilename(item.imagePhysicalName);
            setImagePhysicalFilepath(item.imagePath);
            setImagePreviewUrl(item.imageRelativePath);
        }
        else {
            setImageName(null);
            setImagePhysicalFilename(null);
            setImagePhysicalFilepath(null);
            setImagePreviewUrl(null);
        }
        if (item.catchmentDocumentName != null && item.catchmentDocumentName != "") {
            setUploadDocumentName1(item.catchmentDocumentName);
            setUploadDocumentPhysicalFilename1(item.catchmentDocumentPhysicalName);
            setUploadDocumentPhysicalFilepath1(item.catchmentDocumentPath);
            setDocumentDownloadUrl(item.catchmentDocumentRelativePath);
        }
        else {
            setUploadDocumentName1(null);
            setUploadDocumentPhysicalFilename1(null);
            setUploadDocumentPhysicalFilepath1(null);
            setDocumentDownloadUrl(null);
        }
        setShowUploadDocumentItems(true);
    }

    const handleExternalOrganisationLinkNameChange = idx => e => {
        const tempRows = [...externalOrganisationLinkRows];
        tempRows[idx].name = e.target.value;
        setExternalOrganisationLinkRows(tempRows);
    };
    const handleExternalOrganisationLinkChange = idx => e => {
        const tempRows = [...externalOrganisationLinkRows];
        tempRows[idx].link = e.target.value;
        setExternalOrganisationLinkRows(tempRows);
    };
    const handleExternalOrganisationLinkRowsAddRow = () => {
        const item = {
            name: "", link: "", id: 0
        };
        setExternalOrganisationLinkRows([...externalOrganisationLinkRows, item]);
    };
    const handleExternalOrganisationLinkRowsRemoveSpecificRow = (idx) => () => {
        const tempRows = [...externalOrganisationLinkRows]
        tempRows.splice(idx, 1)
        setExternalOrganisationLinkRows(tempRows);
    }

    useEffect(() => {
        populateItemData();
    }, [])

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const confirmDeleteItemPopup = async () => {

        const response = await axios.get(
            '/api/upload/DeleteUploadDocument/', {
                params: { Id: deleteItemId, userId: userId }
        }
        );
        if (response.data != null) {
            if (response.data.status) {
                setShowDeleteItemPopup(false);
                setDocumentUploadList(response.data.content);
            }
        }
    }

    const handleChangeName = async (event) => {
        setName(event.target.value);
    }

    const handleChangeDocumentName = async (event) => {
        setUploadDocumentDetail(event.target.value);
    }

    const handleChangeOrganisationType = async (event) => {
        setOrganisationType(parseInt(event.target.value));
        if (event.target.selectedOptions[0].text == 'Catchment') {
            setShowCatchmentItems(true);
        }
        else {
            setShowCatchmentItems(false);
        }
    }

    const handleChangeSupplyType = async (event) => {
        setSupplyType(parseInt(event.target.value));
    }

    const handleChangeDescription = async (event) => {
        setDescription(event.target.value);
    }

    const handleChangeExternalLink = async (event) => {
        setExternalLink(event.target.value);
    }

    const openDocumentUploadPopup = async () => {
        setShowUploadDocumentPopup(true);
    }

    const closeExternalOrganisationPopup = async () => {
        window.location = '/externalorganisation?organisationType=' + organisationTypeValue + '&name=' + nameValue;
    }

    const closeUploadDocumentPopup = async () => {
        setUploadDocumentDetail('');
        setShowUploadDocumentPopup(false)
    }

    const handleUploadDocumentSubmit = async (event) => {
        event.preventDefault();
        let form = {
            ReferenceId: id,
            DocumentName: uploadDocumentName,
            DocumentDetail: uploadDocumentDetail,
            DocumentPhysicalName: uploadDocumentPhysicalFilename,
            DocumentPath: uploadDocumentPhysicalFilepath,
            CreatedBy: userId
        }
        const errors = {};
        Validate(form, errors);
        if (Object.keys(errors).length == 0) {
            const response = await axios.post(
                '/api/settings/SaveUploadDocuments/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    setUploadDocumentDetail('');
                    setDocumentUploadList(response.data.content);
                    setShowUploadDocumentPopup(false);
                }
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let tempExternalOrganisationLinkRows = [...externalOrganisationLinkRows];
        let form = {
            Id: id,
            Name: name,
            OrganisationType: organisationType,
            Description: description,
            LogoName: logoName,
            LogoPhysicalName: logoPhysicalFilename,
            LogoPath: logoPhysicalFilepath,
            ImageName: imageName,
            ImagePhysicalName: imagePhysicalFilename,
            ImagePath: imagePhysicalFilepath,
            ExternalLink: externalLink,
            CatchmentInformation: catchmentInformation,
            FreshwaterValues: freshwaterValues,
            SignificantBiodiversity: significantBiodiversity,
            TangataWhenuaIwiHapuRunanga: tangataWhenuaIwiHapuRunanga,
            CulturalSignificance: culturalSignificance,
            PriorityContaminants: priorityContaminantSelectedValues.toString(),
            SupplyType: supplyType,
            UserId: userId,
            CatchmentDocumentName: uploadDocumentName1, CatchmentDocumentPhysicalName: uploadDocumentPhysicalFilename1, CatchmentDocumentPath: uploadDocumentPhysicalFilepath1,
            ExternalOrganisationLinks: []
        }
        for (var i = 0; i < tempExternalOrganisationLinkRows.length; i++) {
            var obj = {};
            obj.Id = tempExternalOrganisationLinkRows[i].id;
            obj.Link = tempExternalOrganisationLinkRows[i].link;
            obj.Name = tempExternalOrganisationLinkRows[i].name;
            form.ExternalOrganisationLinks.push(obj);
        }
        if (!Validate(form)) {
            const response = await axios.post(
                '/api/settings/SaveExternalOrganisations/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    toast.success("External Organisation Updated!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        window.open("/externalorganisation", "_self");
                    }, 1500);
                }
            }
        }
    }

    const handleLogoChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: "ExternalOrganisation",
                    Base64Image: result
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                setLogoImagePreviewUrl(res.data.content.RelativeFilepath);
                                setLogoPhysicalFilename(res.data.content.PhysicalFilename);
                            }
                        }
                    });
                
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            setLogoName(file.name);
            setLogoFile(file);
            setLogoImagePreviewUrl(reader.result);
        }

        reader.readAsDataURL(file)
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: "ExternalOrganisation",
                    Base64Image: result
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                setImagePreviewUrl(res.data.content.RelativeFilepath);
                                setImagePhysicalFilename(res.data.content.PhysicalFilename);
                                setImagePhysicalFilepath(res.data.content.PhysicalFilepath);
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            setImageName(file.name);
            setImageFile(file);
            setImagePreviewUrl(reader.result);
        }

        reader.readAsDataURL(file)
    }

    const handleDocumentChange = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.DocumentName = "Only Pdf files can upload!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            const formData = new FormData();
            formData.append("Type", "External Organisation");
            formData.append("OrganisationId", id);
            formData.append("Name", uploadDocumentDetail);
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            fetch('api/uploaddocument/UploadDocument', {
                method: 'POST',
                body: formData
            }).then(r => r.json()).then(res => {
                if (res.status) {
                    setUploadDocumentDetail(res.content.DocumentDetail);
                    setUploadDocumentName(res.content.DocumentName);
                    setUploadDocumentPhysicalFilename(res.content.DocumentPhysicalName);
                    setUploadDocumentPhysicalFilepath(res.content.DocumentPath);
                }
            });
        }
        else {
            setUploadDocumentName('');
            setUploadDocumentPhysicalFilename('');
            setUploadDocumentPhysicalFilepath('');
        }
    }

    const getBase64 = async (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleChange = (e) => {
        setPriorityContaminantSelectedValues('');
        var priorityContaminantSelectedValues = '';
        setPriorityContaminantListSelectedValues(e);
        e.forEach(function (element) {
            if (priorityContaminantSelectedValues == '') {
                priorityContaminantSelectedValues = element.value;
            }
            else {
                priorityContaminantSelectedValues = priorityContaminantSelectedValues + ',' + element.value;
            }
        });
        setPriorityContaminantSelectedValues(priorityContaminantSelectedValues);
    }

    const handleBtnLogoUploadClick = async (e) => {
        e.preventDefault();
        inputLogoFile.click();
        return false;
    }

    const handleBtnImageUploadClick = async (e) => {
        e.preventDefault();
        inputImageFile.click();
        return false;
    }

    const handleBtnImageDeleteClick = async (e) => {
        e.preventDefault();
        let formData = {
            SettingName: "External Organisation",
            ImageType: "Image",
            Id: id,
            UserId: userId,
        }
        const response = await axios.post(
            '/api/Settings/DeleteSettingImages/', JSON.stringify(formData),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Image deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                window.location.reload();
            }
            else {
                toast.error("Error while deleting!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } }); 
            }
        }
    }
    const handleBtnLogoDeleteClick = async (e) => {
        e.preventDefault();
        let formData = {
            SettingName: "External Organisation",
            ImageType: "Logo",
            Id: id,
            UserId: userId,
        }
        const response = await axios.post(
            '/api/Settings/DeleteSettingImages/', JSON.stringify(formData),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Logo deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                window.location.reload();
            }
            else {
                toast.error("Error while deleting!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    const handleBtnDocumentUploadClick = async (e) => {
        e.preventDefault();
        inputDocumentFile.click();
        return false;
    }
    const handleDocumentChange1 = async (e) => {
        e.preventDefault();
        setFormDocumentErrors1({});
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.DocumentName = "Only Pdf files can upload!";
        }
        setFormDocumentErrors1(errors);
        if (Object.keys(errors).length == 0) {
            const formData = new FormData();
            formData.append("Type", "Catchment");
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            fetch('api/uploaddocument/UploadDocument', {
                method: 'POST',
                body: formData
            }).then(r => r.json()).then(res => {
                if (res.status) {
                    setUploadDocumentName1(res.content.DocumentName);
                    setUploadDocumentPhysicalFilename1(res.content.DocumentPhysicalName);
                    setUploadDocumentPhysicalFilepath1(res.content.DocumentPath);
                    setDocumentDownloadUrl(res.content.RelativeFilepath)
                }
            });
        }
        else {
            setUploadDocumentPhysicalFilename1('');
            setUploadDocumentPhysicalFilepath1('');
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Edit Organisation
                </h2>
                <CardBody> 
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <Row xs="3">
                                <Col sm="6">
                                    <FormGroup>
                                        <Label className="label" for="name">Name</Label>
                                        <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label className="label" for="organisationType">Type Of Organisation</Label>
                                        {externalOrganisationList.organisationType == undefined && <p><em>Loading...</em></p>}
                                        {externalOrganisationList.organisationType != undefined && externalOrganisationList.organisationType != null &&
                                            <Input disabled className="validate" type="select" value={organisationType} onChange={e => handleChangeOrganisationType(e)} name="OrganisationType" id="OrganisationType">
                                                {externalOrganisationList.organisationType.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="description">Description</Label>
                                        <Input type="textarea" onChange={handleChangeDescription} value={description} name="description" id="description" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">External Link</Label>
                                            </Col>
                                            <Col>
                                                <button onClick={handleExternalOrganisationLinkRowsAddRow} type="button" id="btnUpdate" className="btn btn-sm plusIcon">
                                                    Add
                                                </button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row style={{ overflow: "auto", height: "150px" }}>
                                            <Col>
                                                <Table className="table table-bordered table-hover" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                                    <thead>
                                                        <th colSpan="1" rowSpan="1" style={{ width: '45%', textAlign: 'center' }}> Name </th>
                                                        <th colSpan="1" rowSpan="1" style={{ width: '45%', textAlign: 'center' }}> Link </th>
                                                        <th />
                                                    </thead>
                                                    <tbody>
                                                        {externalOrganisationLinkRows.map((item, idx) => (
                                                            <tr id="addr0" key={idx}>
                                                                <td style={{ display: "none" }}>{externalOrganisationLinkRows[idx].id}</td>
                                                                <td style={{ width: "45%" }}>
                                                                    <input type="text" name="name" value={externalOrganisationLinkRows[idx].name} onChange={handleExternalOrganisationLinkNameChange(idx)} className="form-control" />
                                                                </td>
                                                                <td style={{ width: "45%" }}>
                                                                    <input type="text" name="link" value={externalOrganisationLinkRows[idx].link} onChange={handleExternalOrganisationLinkChange(idx)} className="form-control" />
                                                                </td>
                                                                <td style={{ width: "5%" }}>
                                                                    <button style={{ float: "right" }} onClick={handleExternalOrganisationLinkRowsRemoveSpecificRow(idx)} type="button" id="btnUpdate" className="btn btn-sm btn-danger button-delete">
                                                                        <FaTrash />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                showSupplyCompanyItems &&
                                <div>
                                    <Row xs="2">
                                        <Col>
                                            <FormGroup>
                                                <Label className="label" for="supplyType">Supply Type</Label>
                                                {externalOrganisationList.supplyType == undefined && <p><em>Loading...</em></p>}
                                                {externalOrganisationList.supplyType != undefined && externalOrganisationList.supplyType != null &&
                                                    <Input type="select" value={supplyType} onChange={e => handleChangeSupplyType(e)} name="supplyType" id="supplyType">
                                                        {externalOrganisationList.supplyType.map(items =>
                                                            <option key={uuid()} value={items.id}>{items.name}</option>
                                                        )}
                                                    </Input>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {
                                showCatchmentItems &&
                                <div> 
                                    <Row xs="2">
                                        <Col>
                                            <FormGroup>
                                                <Label className="label" for="significantspecies">Priority Contaminants</Label>
                                                <Select className="dropdown" placeholder="Select Option" value={priorityContaminantListSelectedValues} options={priorityContaminantList} onChange={handleChange} isMulti isClearable />
                                            </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="label" for="externallink">Catchment Information</Label>
                                                    <Input type="textarea" onChange={(e) => setCatchmentInformation(e.target.value)} value={catchmentInformation} name="catchmentInformation" id="catchmentInformation" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="label" for="externallink">Freshwater Values, Priorities, or Outcomes</Label>
                                                    <Input type="textarea" onChange={(e) => setFreshwaterValues(e.target.value)} value={freshwaterValues} name="freshwaterValues" id="freshwaterValues" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="label" for="externallink">Significant Biodiversity</Label>
                                                    <Input type="textarea" onChange={(e) => setSignificantBiodiversity(e.target.value)} value={significantBiodiversity} name="significantBiodiversity" id="significantBiodiversity" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="label" for="externallink">Name Of The Relevant Tangata Whenua, Iwi, Hapū Or Rūnanga</Label>
                                                    <Input type="textarea" onChange={(e) => setTangataWhenuaIwiHapuRunanga(e.target.value)} value={tangataWhenuaIwiHapuRunanga} name="tangataWhenuaIwiHapuRunanga" id="tangataWhenuaIwiHapuRunanga" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="label" for="externallink">Cultural Significance Of The Catchment, Including Te Ao Maori Values Where Appropriate</Label>
                                                    <Input type="textarea" onChange={(e) => setCulturalSignificance(e.target.value)} value={culturalSignificance} name="culturalSignificance" id="culturalSignificance" />
                                                </FormGroup>
                                            </Col>
                                    </Row>
                                </div>
                            }
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Logo</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {logoImagePreviewUrl && <img className="settings-image-style" src={logoImagePreviewUrl} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, logoName, logoImagePreviewUrl)} />}
                                                <br />
                                                {logoImagePreviewUrl ?
                                                    <Button color="warning" className="button-upload" size="sm" onClick={handleBtnLogoUploadClick} ><FaUpload /> Modifiy Logo</Button> :
                                                    <Button color="success" className="button-upload" size="sm" onClick={handleBtnLogoUploadClick} ><FaUpload /> Upload Logo</Button>}
                                                {logoImagePreviewUrl && <> <Button color="warning" className="button-upload" size="sm" onClick={handleBtnLogoDeleteClick} ><FaTrash /> Delete Logo</Button> </>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputLogoFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleLogoChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col style={{ display: "none" }}>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Image</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {imagePreviewUrl && <img className="settings-image-style" src={imagePreviewUrl} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, imageName, imagePreviewUrl)} />}
                                                <br />
                                                {imagePreviewUrl ?
                                                    <Button color="warning" className="button-upload" size="sm" onClick={handleBtnImageUploadClick} ><FaUpload /> Modifiy Image</Button> :
                                                    <Button color="success" className="button-upload" size="sm" onClick={handleBtnImageUploadClick} ><FaUpload /> Upload Image</Button>}
                                                {imagePreviewUrl && <> <Button color="warning" className="button-upload" size="sm" onClick={handleBtnImageDeleteClick} ><FaTrash /> Delete Image</Button> </>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputImageFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleImageChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                {showCatchmentItems && <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Catchment Document</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {documentDownloadUrl && <a href={documentDownloadUrl} target='_blank'>
                                                    <img src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img> <span >{uploadDocumentName1}</span>
                                                    <br />

                                                </a>}
                                                <br />
                                                {documentDownloadUrl ?
                                                    <Button color="warning" className="button-upload" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Modifiy Document</Button> :
                                                    <Button color="success" className="button-upload" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Upload Document</Button>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputDocumentFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleDocumentChange1} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    {formDocumentErrors1.DocumentName != undefined &&
                                        <p className="errorMessage">{formDocumentErrors1.DocumentName}</p>
                                    }
                                </Col>}
                            </Row>
                            {
                                showUploadDocumentItems && showCatchmentItems && false &&
                                <div>
                                    <Row style={{ marginTop: `15px` }} xs="2">
                                        <Col>
                                            <Label className="label" style={{ fontSize: `20px` }} for="significantspecies"><b><u>Document Upload</u></b></Label>
                                        </Col>
                                        <Col><button style={{ float: `right` }} onClick={openDocumentUploadPopup} type="button" id="btnUpdate" className="btn btn-sm btn-primary">
                                            Add Document
                                        </button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: `15px`, marginBottom: `15px` }}>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Name</th>
                                                                <th>Download</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        {documentUploadList.length > 0 &&
                                                            <tbody>
                                                                {documentUploadList.map(item => (
                                                                    <tr>
                                                                        <td>{item.createdOn}</td>
                                                                        <td>{item.documentName}</td>
                                                                        <td>
                                                                            <a href={item.documentRelativePath} target='_blank'>
                                                                                <button type="button"
                                                                                    id="btnUpdate"
                                                                                    className="btn btn-sm btn-warning">
                                                                                    <FaDownload />
                                                                                </button>
                                                                            </a>
                                                                        </td>
                                                                        <td>

                                                                            <button type="button"
                                                                                id="btnUpdate"
                                                                                className="btn btn-sm btn-danger"
                                                                                onClick={() => openDeleteItemPopup(item)}>
                                                                                <FaTrash />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        }
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row>
                                <Col>

                                    <input type="submit" style={{float:"right"}} value="Save" size="sm" className="btn button-save" />
                                    <Button className="button-normal" style={{ float: "right",marginRight:"10px" }} color="warning" onClick={closeExternalOrganisationPopup}><FaChevronLeft /> Back To List</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={showUploadDocumentPopup} backdrop={false}>
                <Form onSubmit={handleUploadDocumentSubmit}>
                    <ModalHeader >Upload Document</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="label" for="documentName">Name</Label>
                                    <Input type="text" value={uploadDocumentDetail} onChange={handleChangeDocumentName} name="documentName" id="documentName" placeholder="" />
                                </FormGroup>
                                {formDocumentErrors.DocumentDetail != undefined &&
                                    <p className="errorMessage">{formDocumentErrors.DocumentDetail}</p>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <input className="fileInput" type="file" onChange={handleDocumentChange} />
                                </FormGroup>
                                {formDocumentErrors.DocumentName != undefined &&
                                    <p className="errorMessage">{formDocumentErrors.DocumentName}</p>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <input type="submit" value="Submit" color="primary" className="btn btn-primary" />
                        <Button color="danger" onClick={closeUploadDocumentPopup}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default EditExternalOrganisation;