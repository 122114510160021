import React, { useRef, useState, useEffect } from 'react';
import useAuth from '../Hooks/useAuth';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody } from 'reactstrap';
import './Login.css';
import MyBackgroundImage from '../../../images/login_bg.jpg';  

const Login = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/outline"; 

    const userRef = useRef();
    const errRef = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
  
    useEffect(() => {
        userRef.current.focus();        
    })

    useEffect(() => {
        setLoginError('');
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                '/api/auth/login/', JSON.stringify(
                    {
                        username, password
                    }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });

            const token = response?.data?.accessToken;
            const refreshToken = response?.data?.refreshToken;
            const guid = response.data.guid;
            const roles = response.data.roles;
            const fullName = response.data.fullName;
            const defaultFarmId = response.data.defaultFarmId == null ? 0 : response.data.defaultFarmId ;
            const farmList = response.data.farmList;
            const loggedInUserId = response.data.id;
            const defaultFarmName = response.data.defaultFarmName;
            const defaultFarmUniqueId = response.data.defaultFarmUniqueId;
            const profilePictureUrl = response.data.profilePictureUrl;
            const roleName = response.data.roleName;
            const isAddArchiveItem = response.data.isAddArchiveItem;
            const isAddEditItem = response.data.isAddEditItem;
            const isCreateNewAction = response.data.isCreateNewAction;
            const isSubmitEvidenceCloseAction = response.data.isSubmitEvidenceCloseAction;
            const isAddDeleteDocument = response.data.isAddDeleteDocument;
            const saveRightsForNonItemFieldsAndTables = response.data.saveRightsForNonItemFieldsAndTables;
            const tcAccepted = response.data.tcAccepted;
            const user = {
                username: username,
                fullName : fullName,
                roles : roles,
                guid : guid,
                token: token,
                refreshToken: refreshToken,
                defaultFarmId: defaultFarmId,
                defaultFarmName: defaultFarmName,
                defaultFarmUniqueId: defaultFarmUniqueId,
                profilePictureUrl: profilePictureUrl,
                farmList: farmList,
                loggedInUserId: loggedInUserId,
                roleName: roleName,
                isAddArchiveItem: isAddArchiveItem, isAddEditItem: isAddEditItem, isCreateNewAction: isCreateNewAction, isSubmitEvidenceCloseAction: isSubmitEvidenceCloseAction,
                isAddDeleteDocument: isAddDeleteDocument, saveRightsForNonItemFieldsAndTables: saveRightsForNonItemFieldsAndTables
            }

            setAuth(user); // store all auth info in auth object (global context)
            
            // save same details in localStorage. TODO: investigate how to save setAuth at once
            localStorage.setItem("auth", JSON.stringify(user));
            localStorage.setItem("defaultFarmId", JSON.stringify(defaultFarmId));
            localStorage.setItem("defaultFarmName", JSON.stringify(defaultFarmName));
            localStorage.setItem("defaultFarmUniqueId", JSON.stringify(defaultFarmUniqueId));
            localStorage.setItem("loggedInUserId", loggedInUserId);
            localStorage.setItem("roleName", roleName);
            localStorage.setItem("isAddArchiveItem", isAddArchiveItem);
            localStorage.setItem("isAddEditItem", isAddEditItem);
            localStorage.setItem("isCreateNewAction", isCreateNewAction);
            localStorage.setItem("isSubmitEvidenceCloseAction", isSubmitEvidenceCloseAction);
            localStorage.setItem("isAddDeleteDocument", isAddDeleteDocument);
            localStorage.setItem("saveRightsForNonItemFieldsAndTables", saveRightsForNonItemFieldsAndTables);
            setUsername('');
            setPassword('');
            if (!tcAccepted) {
                window.open("/home", "_self");
            }
            else {
                handleLandingPage(farmList, defaultFarmId);
            }
                  

        } catch (err) {
            if (!err?.response) {
                setLoginError("No server response!")
            } else if (err.response?.status === 400) {
                console.log(err.response.status);
                setLoginError("Login failed!")
            } else {
                setLoginError("Login failed!")
            }
        }
    }
    const handleLandingPage = async (farmList, defaultFarmId) => {
        try {
            if (farmList.length == 1) {
                // go to outline page if user has one farm associated
                const response = await axios.get(
                    '/api/wizard/GetWizardDetails/', {
                    params: {
                        farmId: defaultFarmId
                    }
                }
                );
                if (response.data != null) {
                    if (response.data.isCompleted) {
                        window.open("/summary", "_self");
                    } else {
                        window.open(response.data.landingPage, "_self");
                    }
                }

            }
            else if (farmList.length > 1) {
                // go to farm selector page if user has more than one farm
                window.open("/allfarmssummary", "_self");
            }
            else {
                // otherwise go to where it came from
                if (defaultFarmId == 0) {
                    window.open("/", "_self");
                } else {
                    navigate(from, { replace: true });
                }
            }


        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <div className="login-flex" style={{
            backgroundImage: `url(${MyBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '110vh'
        }}>
            <div className="login-container">
                <Card className="login-card">
                    <CardBody>
                        <div className="login-logo">
                            <img className="login-logo" src="/images/login-zespri-logo.png"></img>
                        </div>
                        <Form onSubmit={handleSubmit} className="login-form">
                            <h2>Login</h2><br />
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="username">
                                            Enter your username
                                        </Label>
                                        <Input
                                            ref={userRef}
                                            id="username"
                                            name="username"
                                            autoComplete="off"
                                            placeholder="Username"
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="password">
                                            Enter your password
                                        </Label>
                                        <Input
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <p className="float-end forgot">
                                <span className="text-muted">Forgot your password? &nbsp;</span>

                                <a href="/resetpassword">Reset here</a>
                            </p>
                            <Button className="btn-login btn-grad">
                                Login
                            </Button>
                            <div className="login-error mt-3">
                                {loginError}
                            </div>
                        </Form>

                    </CardBody>
                </Card>
            </div>
            </div>
    );
}

export default Login;