import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup, Card, CardBody, CardHeader, Collapse, Tooltip } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';

const AgrichemicalsItemSpecView = ({ itemPopupItemFeild, itemPopupItemSepcQ, lookUpList, handleItemSepcValueChange, itemPopupHeader, handleItemPopupHeaderChange }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const itemTypeFeildList = itemPopupItemFeild.itemFieldTypes;
    const itemFeildCount = itemTypeFeildList.length;

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen)
    };

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        handleHideAllDiv(itemTypeFeildList);

    }, [])

    const handleHideAllDiv = (itemTypeFeildList) => {
        document.querySelectorAll(".item-field").forEach(element => {
            element.style.display = 'none';
        })

        itemTypeFeildList.map(itemTypeFeild => {
            let className = "." + itemTypeFeild.className;
            document.querySelectorAll(className).forEach(element => {
                element.style.display = 'block';
            })
        })
    }

    if (itemFeildCount > 0) {
        return (
            <>
                <Card>
                    {/*<CardHeader className="card-header-click" onClick={toggle}>*/}
                    {/*    <Row>*/}
                    {/*        <Col sm="11">*/}
                    {/*            <h6>Additional Information</h6>*/}
                    {/*        </Col>*/}
                    {/*        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>*/}
                    {/*    </Row>*/}
                    {/*</CardHeader>*/}
                    {/*<Collapse isOpen={isOpen}>*/}
                        <CardBody>

                            {/* Shelter Belt*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field belt-height'>
                                        <Label>Height (metres)</Label>
                                        <Input type="number" name="BeltHeight" id="BeltHeight" value={itemPopupItemSepcQ.BeltHeight} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field belt-thickness'>
                                        <Label>Thickness (metres)</Label>
                                        <Input type="number" name="BeltThickness" id="BeltThickness" value={itemPopupItemSepcQ.BeltThickness} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className='item-field predominant-species'>
                                        <Label>Predominant Species</Label>
                                        <Input type="select" onChange={handleItemSepcValueChange} name="PredominantSpecies" id="PredominantSpecies" value={itemPopupItemSepcQ.PredominantSpecies} >
                                            {lookUpList.predominantSpeciesList != undefined && lookUpList.predominantSpeciesList.map(Option =>
                                                <option value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {itemPopupItemSepcQ.ShowPredominantSpeciesOther && 
                                    <Col sm="6">
                                        <FormGroup className=''>
                                            <Label>Please Specify</Label>
                                            <Input type="text" name="PredominantSpeciesOther" id="PredominantSpeciesOther" value={itemPopupItemSepcQ.PredominantSpeciesOther} onChange={handleItemSepcValueChange} />
                                        </FormGroup>
                                    </Col>    
                                }
                                <Col sm="6">
                                    <FormGroup className='item-field approx-age-of-Shelter'>
                                        <Label>Approx Age Of Shelter (Years)</Label>
                                        <Input type="number" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} name="ApproxAgeOfShelter" id="ApproxAgeOfShelter" value={itemPopupItemSepcQ.ApproxAgeOfShelter} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/*Spray Area*/}
                            <Row >
                                <Col sm="6">
                                    <FormGroup className='item-field buffer-width'>
                                        <Label>Buffer width (metres)</Label>
                                        <Input type="number" name="BufferWidth" id="BufferWidth" value={itemPopupItemSepcQ.BufferWidth} onChange={handleItemSepcValueChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='item-field area-perimeter-buffered'>
                                        <Label>% of area perimeter buffered</Label> <br />
                                        <Input type="select" onChange={handleItemSepcValueChange} name="Areaperimeterbuffered" id="Areaperimeterbuffered" value={itemPopupItemSepcQ.Areaperimeterbuffered} >
                                            {lookUpList.areaPerimeterBufferedList != undefined && lookUpList.areaPerimeterBufferedList.map(Option =>
                                                <option key={uuid()} value={Option.id}>{Option.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    {/*</Collapse>*/}
                </Card>
                <br />
            </>
        )
    }

}
export default AgrichemicalsItemSpecView