import { useState, useEffect } from 'react';
import {
    Row, Col, Table, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Label, Input, Form, FormGroup
} from 'reactstrap';
import Select, { components } from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import './Lmu.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#008436',
    color: 'white',
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    marginBottom: '8px'
};

export interface GroupedOption {
    label: string;
}

const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

const InherentRisks = ({ form, handleMultiSelectChange }) => {
    const [formDocumentErrors, setFormDocumentErrors] = useState({}); const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [txtInherentRisk, setTxtInherentRisk] = useState(''); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [showAddInherentRiskPopup, setShowAddInherentRiskPopup] = useState(false);
    const handleAddInherentRisk = () => {
        setTxtInherentRisk('');
        setShowAddInherentRiskPopup(true);
    }
    const [selectedInherentRisks, setSelectedInherentRisks] = useState([]);
    const saveAddInherentRisk = async() => {
        setFormDocumentErrors({});
        const errors = {};
        if (txtInherentRisk == null || txtInherentRisk == "") {
            errors.inherentRisk = "Inherent Risk can not be blank!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            const response = await axios.get(
                '/api/LMU/AddInherentRisk/', {
                params: {
                        farmId: farmId, inherentRisk: txtInherentRisk, userId: userId
                }
            }
            );
            if (response.data.status) {
                if (response.data.message == 'Success') {
                    toast.success("Inherent Risk Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setShowAddInherentRiskPopup(false);
                    form.inherentRisksList = response.data.content.inherentRisksList;
                    form.inherentRisks.push({
                            value: response.data.content.inherentRiskAdded.value,
                            label: response.data.content.inherentRiskAdded.label
                        })
                }
                else {
                    setFormDocumentErrors({});
                    const errors = {};
                    errors.inherentRisk = response.data.message;
                    setFormDocumentErrors(errors);
                }
            }
            else {
                toast.error("Error while updating reason", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
        
    }

    const closeAddInherentRisk = () => {
        setShowAddInherentRiskPopup(false);
    }

    function handleInherentRiskChange(event) {
        const { value} = event.target;
        setTxtInherentRisk(value);
    }

    useEffect(() => {
        setSelectedInherentRisks(form.inherentRisks);
    }, [])

    return (
        <div className="my-3">
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="inherentRisks">Inherent vulnerability to Freshwater</Label>
                        <Label className="info-text"> Inherent vulnerability is based on the biophysical characteristics and is defined as “risks to freshwater and freshwater ecosystems from the biophysical characteristics of the land."</Label>
                        <Select
                            closeMenuOnSelect={false}
                            className="dropdown"
                            placeholder="Select Inherent Vulnerability(s)"
                            defaultValue={form.inherentRisks}
                            options={form.inherentRisksList}
                            onChange={handleMultiSelectChange}
                            isMulti hideSelectedOptions={false}
                            isClearable
                            components={{
                                Option
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Modal isOpen={showAddInherentRiskPopup}>
                <ModalHeader>Add Inherent Risk</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Inherent Risk</Label>
                                <Input type="text" name="txtInherentRisk" onChange={handleInherentRiskChange} id="txtInherentRisk" value={txtInherentRisk} />
                            </FormGroup>
                            {formDocumentErrors.inherentRisk != undefined &&
                                <p className="errorMessage">{formDocumentErrors.inherentRisk}</p>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={saveAddInherentRisk}>Add</Button>
                    <Button color="danger" onClick={closeAddInherentRisk}>Close</Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default InherentRisks