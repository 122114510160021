import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaTrash } from 'react-icons/fa';
import uuid from 'react-uuid';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import "../ExternalOrganisation/ExternalOrganisation.css";
import Validate from '../Validate/Validate';
import { FaChevronLeft, FaUpload } from 'react-icons/fa';
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

const EditNews = () => {
    const { state } = useLocation(); const navigate = useNavigate();
    const [newsList, setNewsList] = useState([]); const [loading, setLoading] = useState(true);
    const [id, setId] = useState(0);
    const [newsItemName, setNewsItemName] = useState('');
    const [newsEditorName, setNewsEditorName] = useState('');
    const [newsItemDescription, setNewsItemDescription] = useState('');
    const [newsItemDate, setNewsItemDate] = useState(null);
    const [organisation, setOrganisation] = useState(0);
    const [thumbnailFile, setThumbnailFile] = useState('');
    const [thumbnailImagePreviewUrl, setThumbnailImagePreviewUrl] = useState('');
    const [thumbnailName, setThumbnailName] = useState('');
    const [thumbnailPhysicalFilename, setThumbnailPhysicalFilename] = useState('');
    const [thumbnailPhysicalFilepath, setThumbnailPhysicalFilepath] = useState(''); 
    const [uploadDocumentName, setUploadDocumentName] = useState('');
    const [uploadDocumentPhysicalFilename, setUploadDocumentPhysicalFilename] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [uploadDocumentPhysicalFilepath, setUploadDocumentPhysicalFilepath] = useState('');
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [documentDownloadUrl, setDocumentDownloadUrl] = useState('');
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    let inputThumbnailFile = '';
    let inputDocumentFile = '';

    const populateItemData = async () => {
        try {
            if (state != null) {
                const { id } = state;
                const response = await axios.get(
                    '/api/settings/getnews/', {
                    params: {}
                }
                );
                setNewsList(response.data.content);
                setLoading(false);
                var news = response.data.content.news.filter(x => x.id == id);
                setId(news[0].id);
                setOrganisation(news[0].organisation);
                setNewsItemName(news[0].newsItemName);
                setNewsEditorName(news[0].newsEditorName);
                setNewsItemDescription(news[0].newsItemDescription);
                var newsItemDate = getDateFromString(moment(news[0].newsItemDate).format("DD/MM/YYYY"));
                setNewsItemDate(newsItemDate);
                if (news[0].newsItemThumbnailName != null && news[0].newsItemThumbnailName != "") {
                    setThumbnailName(news[0].newsItemThumbnailName);
                    setThumbnailPhysicalFilename(news[0].newsItemThumbnailPhysicalName);
                    setThumbnailPhysicalFilepath(news[0].newsItemThumbnailPath);
                    setThumbnailImagePreviewUrl(news[0].newsItemThumbnailRelativePath);
                }
                else {
                    setThumbnailName(null);
                    setThumbnailPhysicalFilename(null);
                    setThumbnailPhysicalFilepath(null);
                    setThumbnailImagePreviewUrl(null);
                }
                if (news[0].newsItemAttachementName != null && news[0].newsItemAttachementName != "") {
                    setUploadDocumentName(news[0].newsItemAttachementName);
                    setUploadDocumentPhysicalFilename(news[0].newsItemAttachementPhysicalName);
                    setUploadDocumentPhysicalFilepath(news[0].newsItemAttachementPath);
                    setDocumentDownloadUrl(news[0].newsItemAttachementRelativePath);
                }
                else {
                    setUploadDocumentName(null);
                    setUploadDocumentPhysicalFilename(null);
                    setUploadDocumentPhysicalFilepath(null);
                    setDocumentDownloadUrl(null);
                }
            }
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const backToNewsList = async () => {
        window.open("/news", "_self");
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "-" + mm + "-" + yyyy;
        return date.toString();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let form = {
            Id: id, NewsItemName: newsItemName, NewsItemDescription: newsItemDescription, Organisation: parseInt(organisation), NewsItemDate: newsItemDate != null ? getParsedDate(newsItemDate) : "",
            NewsItemThumbnailName: thumbnailName, NewsItemThumbnailPhysicalName: thumbnailPhysicalFilename, NewsItemThumbnailPath: thumbnailPhysicalFilepath,
            NewsItemAttachementName: uploadDocumentName, NewsItemAttachementPhysicalName: uploadDocumentPhysicalFilename, NewsItemAttachementPath: uploadDocumentPhysicalFilepath,
            UserId: userId
        }
        if (!Validate(form)) {
            const response = await axios.post(
                '/api/settings/SaveNews/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    window.open("/news", "_self");
                }
                else if (response.data.message == 'News already exists in the system') {
                    toast.error("News already exists in the system!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                }
            }
        }
    }

    const handleThumbnailChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        getBase64(file)
            .then(result => {
                let form = {
                    FileName: file.name.replace(/\.[^/.]+$/, ""),
                    Type: "News",
                    Base64Image: result
                }
                const response = axios.post(
                    '/api/upload/UploadImage/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }).then(res => {
                        if (res.data != null) {
                            if (res.data.status) {
                                setThumbnailPhysicalFilepath(res.data.content.PhysicalFilepath);
                                setThumbnailImagePreviewUrl(res.data.content.RelativeFilepath);
                                setThumbnailPhysicalFilename(res.data.content.PhysicalFilename);
                            }
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });

        reader.onloadend = () => {
            setThumbnailName(file.name);
            setThumbnailFile(file);
            setThumbnailImagePreviewUrl(reader.result);
        }

        reader.readAsDataURL(file)
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleDocumentChange = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        let reader = new FileReader();
        let file = e.target.files[0];
        const errors = {};
        if (file.type != 'application/pdf') {
            errors.DocumentName = "Only Pdf files can upload!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            const formData = new FormData();
            formData.append("Type", "News");
            formData.append("FileName", e.target.files[0].name);
            formData.append("File", e.target.files[0]);
            fetch('api/uploaddocument/UploadDocument', {
                method: 'POST',
                body: formData
            }).then(r => r.json()).then(res => {
                if (res.status) {
                    setUploadDocumentName(res.content.DocumentName);
                    setUploadDocumentPhysicalFilename(res.content.DocumentPhysicalName);
                    setUploadDocumentPhysicalFilepath(res.content.DocumentPath);
                    setDocumentDownloadUrl(res.content.RelativeFilepath);
                }
            });
        }
        else {
            setUploadDocumentPhysicalFilename('');
            setUploadDocumentPhysicalFilepath('');
            setDocumentDownloadUrl('');
        }
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    const handleBtnThumbnailUploadClick = async (e) => {
        e.preventDefault();
        inputThumbnailFile.click();
        return false;
    }

    const handleBtnDocumentUploadClick = async (e) => {
        e.preventDefault();
        inputDocumentFile.click();
        return false;
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    const handleBtnThumbnailDeleteClick = async (e) => {
        e.preventDefault();
        let formData = {
            SettingName: "News",
            ImageType: "Thumbnail",
            Id: id,
            UserId: userId,
        }
        const response = await axios.post(
            '/api/Settings/DeleteSettingImages/', JSON.stringify(formData),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Thumbnail deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                window.location.reload();
            }
            else {
                toast.error("Error while deleting!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleBtnAttachmentDeleteClick = async (e) => {
        e.preventDefault();
        let formData = {
            SettingName: "News",
            DocumentType: "Attachment",
            Id: id,
            UserId: userId,
        }
        const response = await axios.post(
            '/api/Settings/DeleteSettingDocuments/', JSON.stringify(formData),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                toast.success("Attachment deleted!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                window.location.reload();
            }
            else {
                toast.error("Error while deleting!", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    Edit News
                </h2>
                <CardBody>
                    <div >
                        <Form onSubmit={handleSubmit}>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Organisation</Label>
                                        {newsList.organisations == undefined && <p><em>Loading...</em></p>}
                                        {newsList.organisations != undefined && newsList.organisations != null &&
                                            <Input className="validate" type="select" value={organisation} onChange={(e) => setOrganisation(e.target.value)} name="Organisation" id="Organisation">
                                                {newsList.organisations.map(items =>
                                                    <option key={uuid()} value={items.id}>{items.name}</option>
                                                )}
                                            </Input>
                                        }
                                    </FormGroup>
                                </Col>
                                
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Date</Label>
                                        <DatePicker className="validate form-control" style={{ width: `100%` }}
                                            selected={newsItemDate} onChange={(date) => setNewsItemDate(date)}
                                            name="NewsItemDate" id="NewsItemDate"
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Editor</Label>
                                        <Input type="text" value={newsEditorName} name="Editor" id="Editor" disabled />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="1">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="name">Title</Label>
                                        <Input type="text" className="validate" value={newsItemName} onChange={(e) => setNewsItemName(e.target.value)} name="NewsItemName" id="NewsItemName" autoComplete="new-password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="1">
                                <Col>
                                    <FormGroup>
                                        <Label className="label" for="newsItemDescription">News</Label>
                                        <Input type="textarea" rows ="5" onChange={(e) => setNewsItemDescription(e.target.value)} value={newsItemDescription} name="newsItemDescription" id="newsItemDescription" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="3">
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Thumbnail</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {thumbnailImagePreviewUrl &&
                                                    <img className="settings-image-style" src={thumbnailImagePreviewUrl}
                                                        onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, thumbnailName, thumbnailImagePreviewUrl)}
                                                        onError={(e) => {
                                                            e.target.onerror = null
                                                            e.target.src = "/images/no-image.jpg"
                                                        }} />}
                                                <br />
                                                {thumbnailImagePreviewUrl ?
                                                    <Button color="warning" className="button-upload" size="sm" onClick={handleBtnThumbnailUploadClick} ><FaUpload /> Modifiy Thumbnail</Button> :
                                                    <Button color="success" className="button-upload" size="sm" onClick={handleBtnThumbnailUploadClick} ><FaUpload /> Upload Thumbnail</Button>}
                                                {thumbnailImagePreviewUrl && <> <Button color="warning" size="sm" onClick={handleBtnThumbnailDeleteClick} ><FaTrash /> Delete Thumbnail</Button> </>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputThumbnailFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleThumbnailChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <Label className="label" for="externallink">Attachment</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {documentDownloadUrl && <a href={documentDownloadUrl} target='_blank'>
                                                    <img src="/images/PDF_icon.png" className="PDF_icon settings-image-style" ></img> <span >{uploadDocumentName}</span>
                                                    <br />
                                                </a>}
                                                <br />
                                                {documentDownloadUrl ?
                                                    <Button color="warning" className="button-upload" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Modifiy Document</Button> :
                                                    <Button color="success" className="button-upload" size="sm" onClick={handleBtnDocumentUploadClick} ><FaUpload /> Upload Document</Button>}
                                                {documentDownloadUrl && <> <Button color="warning" size="sm" onClick={handleBtnAttachmentDeleteClick} ><FaTrash /> Delete Document</Button> </>}
                                                <input style={{ display: 'none' }}
                                                    ref={input => {
                                                        inputDocumentFile = input;
                                                    }}
                                                    className="fileInput" id="fileUpload" type="file" onChange={handleDocumentChange} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    {formDocumentErrors.DocumentName != undefined &&
                                        <p className="errorMessage">{formDocumentErrors.DocumentName}</p>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input type="submit" style={{ float: "right" }} value="Save" size="sm" className="btn button-save" />
                                    <Button className="button-normal" style={{ float: "right", marginRight: "10px" }} color="warning" onClick={backToNewsList}><FaChevronLeft /> Back To List</Button>                                  
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>

            <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                <ModalBody>
                    <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditNews;