import { useState } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse,  Table, FormGroup, Label, Input } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import uuid from 'react-uuid';
import OpenActionSummaryItem from '../AllFarmsSummary/OpenActionSummaryItem';
import moment from "moment";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const OpenActionSummary = ({ openActionSupplierList, openActionList, openActionsList, handleFarmIdChange, name, setName }) => {
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    //const [name, setName] = useState('');
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true); const [farmId, setFarmId] = useState(0);
    const toggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const [isGroupBy, setIsGroupBy] = useState(false); const [isExpand, setIsExpand] = useState(false);
    const exportFarmListToExcel = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportExcel();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportExcel = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportOpenActionsExcel/', {
            params: { UserId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })

            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OpenActions.xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const exportFarmListToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportPdf = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportOpenActionsPDF/', {
            params: { userId: userId, farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OpenActions.pdf";
            document.body.appendChild(link)
            link.click()
        }

    }
    
    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>Actions
                                    <Button style={{ float: "right", background: "none", border: "none" }} onClick={(e) => exportFarmListToExcel(e)} value="Export" placeholder="">
                                        <img src="/images/Excel_icon.png" className="card_icon" ></img>
                                    </Button>
                                    {/*<Button style={{ float: "right", background: "none", border: "none", marginRight: "-20px" }} onClick={(e) => exportFarmListToPdf(e)} value="Export" placeholder="">*/}
                                    {/*    <img src="/images/PDF_icon.png" className="card_icon" ></img>*/}
                                    {/*</Button>*/}
                                </h6></Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{ maxHeight: '350px', overflowY: 'auto' }}>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Search</Label>
                                                <Input className="validate" type="text" onChange={handleFarmIdChange} value={name} name="Name" id="Name" placeholder="" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4"></Col>
                                        <Col sm="4">
                                            <br />
                                            <br />
                                            
                                            <Input type="checkbox" checked={isGroupBy} onChange={(e) => setIsGroupBy(!isGroupBy)} name="isGroupBy" id="isGroupBy" />&nbsp;
                                            <Label style={{ textTransform: "none" }} for="name">List Actions Only</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div>
                                            {!isGroupBy &&
                                                openActionSupplierList.map(summary =>
                                                    <OpenActionSummaryItem expand={isExpand} key={summary.id} itemObj={summary} itemObjList={openActionList.filter(x => x.farmId == summary.id)}></OpenActionSummaryItem>
                                                )
                                            }
                                        </div>
                                        <div>
                                            {
                                                isGroupBy &&
                                                <table id="tblDocumentList" className="table borderless table-striped border-top-bottom">
                                                    <thead className="textAlignCenter">
                                                        <tr>
                                                            <th>Orchard Identifier</th>
                                                            <th>Due Date</th>
                                                            <th>Item</th>
                                                            <th>Description</th>
                                                            
                                                        </tr>

                                                    </thead>
                                                    <tbody className="documentList textAlignCenter" id="documentList">
                                                            {openActionsList != undefined && openActionsList.length > 0 && openActionsList.map(summary =>
                                                            <tr key={uuid()}>
                                                                <td>{summary.uniqueIdentifier}</td>
                                                                    <td>{summary.actionTypeName == "Improvement" ? moment(summary.dueDate).format('DD/MM/YYYY') : 'Ongoing / Management'}</td>
                                                                <td>{summary.itemName}</td>
                                                                <td>{summary.description}</td>
                                                            </tr>
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </Row>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default OpenActionSummary;