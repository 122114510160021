import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Table, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, ButtonGroup, Collapse, Badge, Tooltip, UncontrolledTooltip } from 'reactstrap';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import './RiskSummary.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FaEdit, FaChevronDown, FaChevronUp, FaSlash, FaDrawPolygon, FaRegCircle, FaRegSquare, FaTrash, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import uuid from 'react-uuid';
import Select, { components, ValueContainerProps } from 'react-select';
import ReadMoreContentCustom from '../Utilities/Ui/ReadMoreCustom';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const RiskSummary = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName"); const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const navigate = useNavigate();
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [riskList, setRiskList] = useState([]); const [luRiskList, setLURiskList] = useState([]); const [loading, setLoading] = useState(true);
    const [riskSummary, setRiskSummary] = useState({ SectionName: 'Risk Assessment', Section: 'Risk Summary', isWizardCompleted: false, sectionObj: {} });
    const [sectionList, setSectionList] = useState([]); const [list, setList] = useState([{ label: 'Risk', value: 'Risk' }, { label: 'Type', value: 'Type' }, { label: 'LU', value: 'LU' }])
    const [showNonRelevantRisks, setShowNonRelevantRisks] = useState(false);
    const [descriptionPopup, setDescriptionPopup] = useState({
        showPopup:false,description:''
    });
    const [addRiskPopup, setAddRiskPopup] = useState({
        header: 'Create Custom Risk', showPopup: false, id: 0, name: '', description: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], category: [],
        openActions:0,isActive:true
    });
    const [lookupList, setLookupList] = useState({
        enterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [],
        tempEnterpriseTypes: [], categoryList:[]
    });
    const [formDocumentErrors, setFormDocumentErrors] = useState({});
    const [savingRisk, setSavingRisk] = useState(''); const [addingRisk, setAddingRisk] = useState(''); const [deleteRisk, setDeleteRisk] = useState('');
    const [reasonPopup, setReasonPopup] = useState({ showReasonPopup: false, reason: '', reasonItemId: 0, mode: '' });
    const [showRemoveOptOutValidation, setShowRemoveOptOutValidation] = useState(false);
    const [farmingEnterpriseLabel, setFarmingEnterpriseLabel] = useState([]); const [totalAreaLabel, setTotalAreaLabel] = useState('');
    const [totalLUsLabel, setTotalLUsLabel] = useState(''); const [inherentRiskLabel, setInherentRiskLabel] = useState([]);
    const [showEnterprise, setShowEnterprise] = useState(false);
    const [activeCategoryList, setActiveCategoryList] = useState([]);
    const [nonActiveCategoryList, setNonActiveCategoryList] = useState([]);
    const [luPopup, setLUPopup] = useState({ showLUPopup: false, size: '', inherentRisk: '', topography: '', predominantSoilType: '', secondarySoilType: '', name :''})
    const selectNoColor = {
        fontSize: "13px",
        lineHeight: "1.15",
        border: "1px solid #ced4da"
    };
    const selectErrorColor = {
        fontSize: "13px",
        lineHeight: "1.15",
        border: "1px solid red"
    };
    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/Risk/GetRisk/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setSectionList(response.data.section);
            setActiveCategoryList(response.data.activeCategoryList);
            setNonActiveCategoryList(response.data.nonActiveCategoryList);
            setRiskList(response.data.riskList)
            setLURiskList(response.data.luRiskList);
            setRiskSummary({ ...riskSummary, isWizardCompleted: response.data.isFWFPCompleted });
            setLookupList({
                ...lookupList, enterpriseTypes: [], contaminants: response.data.contaminants, riskDataGroups: response.data.riskDataGroups
                , activityGroups: response.data.activityGroups, activitySubGroups: [], tempActivitySubGroups: response.data.activitySubGroups,
                tempEnterpriseTypes: response.data.enterpriseTypes, categoryList: response.data.categoryList
            })
            setFarmingEnterpriseLabel(response.data.farmingEnterpriseLabel); setTotalAreaLabel(response.data.totalAreaLabel);
            setTotalLUsLabel(response.data.totalLUsLabel); setInherentRiskLabel(response.data.inherentRiskLabel);
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            if (response.data.enterpriseTypes.filter(x => x.label == 'Kiwifruit').length > 0) {
                if (response.data.enterpriseTypes.length == 1) {
                    setShowEnterprise(false);
                }
                else {
                    setShowEnterprise(true);
                    setLookupList({
                        ...lookupList, enterpriseTypes: response.data.enterpriseTypes, contaminants: response.data.contaminants, riskDataGroups: response.data.riskDataGroups
                        , activityGroups: response.data.activityGroups, activitySubGroups: [], tempActivitySubGroups: response.data.activitySubGroups, categoryList: response.data.categoryList })
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const handleLURiskLikelyhoodChange = (itemId,riskId,e) => {
        const tempRows = [...luRiskList];
        let row = tempRows.filter(x => x.itemId == itemId && x.riskUId == riskId)[0];
        row.riskLikelyhood = e.target.value;
        const objWithIdIndex = tempRows.findIndex((obj) => obj.itemId === row.itemId && obj.riskUId == riskId);
        if (objWithIdIndex > -1) {
            tempRows.splice(objWithIdIndex, 1);
        }
        let newList = [];
        tempRows.map((item, idx) => {
            newList.push(item);
        });
        newList.push(row);
        setLURiskList(newList);
    };

    const handleLURiskImpactChange = (itemId, riskId, e) => {
        const tempRows = [...luRiskList];
        let row = tempRows.filter(x => x.itemId == itemId && x.riskUId == riskId)[0];
        row.riskImpact = e.target.value;
        const objWithIdIndex = tempRows.findIndex((obj) => obj.itemId === row.itemId && obj.riskUId == riskId);
        if (objWithIdIndex > -1) {
            tempRows.splice(objWithIdIndex, 1);
        }
        let newList = [];
        tempRows.map((item, idx) => {
            newList.push(item);
        });
        newList.push(row);
        setLURiskList(newList);
    };

    const handleDescriptionPopupClose = (e) => {
        setDescriptionPopup({ ...descriptionPopup, showPopup: false });
    }
    const handleDescriptionPopupOpen = (description, e) => {
        e.preventDefault();
        setDescriptionPopup({ ...descriptionPopup, showPopup: true, description: description });
    }
    const openReasonPopup = async (item, mode, e) => {
        e.preventDefault();
        setReasonPopup({ ...reasonPopup, showReasonPopup: true, reason: item.reason == null ? '' : item.reason, reasonItemId: item.id, mode: mode });
    }
    const handleAddRiskPopupClose = (e) => {
        setAddRiskPopup({ ...addRiskPopup, showPopup: false, description: '' });
    }
    const handleRemoveCustomRisk = async  (e) => {
        e.preventDefault();
        var IsActive = !addRiskPopup.isActive;
        var id = addRiskPopup.id;
        setDeleteRisk(true);
        const response = await axios.get(
            '/api/Risk/DeleteCustomRisk/', {
            params: {
                    id: id, IsActive: IsActive, userId: userId, farmId: farmId
            }
        }
        );
        if (response.data.status) {
            setDeleteRisk(false);
            setRiskList(response.data.content.riskList)
            setLURiskList(response.data.content.luRiskList);
            if (addRiskPopup.header == 'Edit Custom Risk') {
                toast.success("Risk details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            }
            else {
                toast.success("Risk Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            }
            setAddRiskPopup({ ...addRiskPopup, showPopup: false });
            window.location.reload(true);
        }
    }
    const handleAddRiskPopupSave = async (e) => {
        e.preventDefault();
        setFormDocumentErrors({});
        var IsActive = true;
        const errors = {};
        var id = addRiskPopup.id;
        if (id == 0) {
            IsActive = true;
        }
        let category = '';
        if (addRiskPopup.category == null || addRiskPopup.category == undefined || addRiskPopup.category == "") {
            errors.category = "This is mandatory!";
        }
        else {
            if (Array.isArray(addRiskPopup.category)) {
                category = addRiskPopup.category[0].label;
            }
            else if (typeof addRiskPopup.category === 'object') {
                category = addRiskPopup.category.label;
            }
        }
        if (addRiskPopup.name == null || addRiskPopup.name == undefined || addRiskPopup.name == "") {
            errors.name = "This is mandatory!";
        }
        if (addRiskPopup.description == null || addRiskPopup.description == undefined || addRiskPopup.description == "") {
            errors.description = "This is mandatory!";
        }
        let enterprises = '';
        if (Array.isArray(addRiskPopup.enterprises)) {
            let enterprisesArr = [];
            addRiskPopup.enterprises.forEach(risk => {
                enterprisesArr.push(risk.value);
            })
            enterprises = enterprisesArr.join(",");
        } else {
            enterprises = addRiskPopup.enterprises.toString();
        }
        if (enterprises == null || enterprises == undefined || enterprises == "") {
            errors.enterprises = "This is mandatory!";
        }
        let contaminants = '';
        if (Array.isArray(addRiskPopup.contaminants)) {
            let contaminantsArr = [];
            addRiskPopup.contaminants.forEach(risk => {
                contaminantsArr.push(risk.value);
            })
            contaminants = contaminantsArr.join(",");
        } else {
            contaminants = addRiskPopup.contaminants.toString();
        }
        if (contaminants == null || contaminants == undefined || contaminants == "") {
            errors.contaminants = "This is mandatory!";
        }
        let riskDataGroups = '';
        if (Array.isArray(addRiskPopup.riskDataGroups)) {
            let riskDataGroupsArr = [];
            addRiskPopup.riskDataGroups.forEach(risk => {
                riskDataGroupsArr.push(risk.value);
            })
            riskDataGroups = riskDataGroupsArr.join(",");
        } else {
            riskDataGroups = addRiskPopup.riskDataGroups.toString();
        }
        if (riskDataGroups == null || riskDataGroups == undefined || riskDataGroups == "") {
            errors.riskDataGroups = "This is mandatory!";
        }
        let activityGroups = '';
        if (Array.isArray(addRiskPopup.activityGroups)) {
            let activityGroupsArr = [];
            addRiskPopup.activityGroups.forEach(risk => {
                activityGroupsArr.push(risk.value);
            })
            activityGroups = activityGroupsArr.join(",");
        } else {
            activityGroups = addRiskPopup.activityGroups.toString();
        }
        if (activityGroups == null || activityGroups == undefined || activityGroups == "") {
            errors.activityGroups = "This is mandatory!";
        }
        let activitySubGroups = '';
        if (Array.isArray(addRiskPopup.activitySubGroups)) {
            let activitySubGroupsArr = [];
            addRiskPopup.activitySubGroups.forEach(risk => {
                activitySubGroupsArr.push(risk.value);
            })
            activitySubGroups = activitySubGroupsArr.join(",");
        } else {
            activitySubGroups = addRiskPopup.activitySubGroups.toString();
        }
        if (activitySubGroups == null || activitySubGroups == undefined || activitySubGroups == "") {
            errors.activitySubGroups = "This is mandatory!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            setAddingRisk(true);
            const response = await axios.get(
                '/api/Risk/SaveCustomRisk/', {
                    params: {
                        farmId: farmId, id: id, name: addRiskPopup.name, description: addRiskPopup.description, enterprises: enterprises, contaminants: contaminants,
                        riskDataGroups: riskDataGroups, activityGroups: activityGroups, activitySubGroups: activitySubGroups, userId: userId, category: category,
                        IsActive: IsActive
                    }
            }
            );
            if (response.data.status) {
                setAddingRisk(false);
                setRiskList(response.data.content.riskList)
                setLURiskList(response.data.content.luRiskList);
                if (addRiskPopup.header == 'Edit Custom Risk') {
                    toast.success("Risk details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                }
                else {
                    toast.success("Risk Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                }
                setAddRiskPopup({ ...addRiskPopup, showPopup: false });
                window.location.reload(true);
            }
        }
    }
    const handlAddRiskPopupOpen = (e) => {
        e.preventDefault();
        var selectedenterprises = [];
        if (lookupList.tempEnterpriseTypes.filter(x => x.label == 'Kiwifruit').length > 0) {
            if (lookupList.tempEnterpriseTypes.length == 1) {
                selectedenterprises = lookupList.tempEnterpriseTypes.filter(x => x.label == 'Kiwifruit');
                setShowEnterprise(false);
            }
            else {
                setShowEnterprise(true);
                setLookupList({ ...lookupList, enterpriseTypes: lookupList.tempEnterpriseTypes })
            }
        }
        setAddRiskPopup({ ...addRiskPopup, header: 'Create Custom Risk', isActive:true,id:0, openActions:0, showPopup: true, name: '', description: '', enterprises: selectedenterprises, activityGroups: [], activitySubGroups: [], contaminants: [], riskDataGroups: [], category: {} });
    }

    const handleRemoveIncludeLURisk = async (e, lu, type) => {
        if (type == 'Remove') {
            e.currentTarget.innerHTML = 'Removing...';
        }
        else {
            e.currentTarget.innerHTML = 'Including...';
        }
        const response = await axios.get(
            '/api/Risk/RemoveIncludeLURisk/', {
            params: {
                    LUId: lu.id, Type: type
            }
        }
        );
        if (response != null) {
            const tempRows = [...luRiskList];
            var idx = tempRows.findIndex(obj => lu.riskId === obj.riskId && lu.itemId === obj.itemId);
            tempRows[idx].isActive = !tempRows[idx].isActive;
            setLURiskList(tempRows);
        }
    }

    const handlSaveRisk = async (e) => {
        e.preventDefault()
        setSavingRisk(true);
        let tempRows = [...luRiskList];
        let form = {
            FarmId: farmId,
            LURisks: [],
            UserId: userId
        }
        var errorRiskList = "";
        var errorCount = 0;
        if (errorRiskList == "") {
            for (var i = 0; i < tempRows.length; i++) {
                var obj = {};
                obj.Id = tempRows[i].id;
                obj.ItemId = tempRows[i].itemId;
                obj.RiskId = tempRows[i].riskId;
                obj.RiskLikelyhood = tempRows[i].riskLikelyhood;
                obj.RiskImpact = tempRows[i].riskImpact;
                obj.LowRisk = tempRows[i].lowRisk;
                obj.MediumRisk = tempRows[i].mediumRisk;
                obj.HighRisk = tempRows[i].highRisk;
                obj.Reason = tempRows[i].reason;
                obj.IsActive = tempRows[i].isActive;
                if (obj.IsActive && obj.LowRisk == false && obj.MediumRisk == false && obj.HighRisk == false) {
                    if (document.getElementById("riskLevel" + tempRows[i].uId + "") != undefined && document.getElementById("riskLevel" + tempRows[i].uId + "") != null) {
                        document.getElementById("riskLevel" + tempRows[i].uId + "").style.border = "1px solid red";
                        errorCount++;
                    }
                }
                else {
                    if (document.getElementById("riskLevel" + tempRows[i].uId + "") != undefined && document.getElementById("riskLevel" + tempRows[i].uId + "") != null) {
                        document.getElementById("riskLevel" + tempRows[i].uId + "").style.border = "1px solid #ced4da";
                    }
                }
                form.LURisks.push(obj);
            }
            if (errorCount == 0) {
                const response = await axios.post(
                    '/api/Risk/SaveLURisks/', JSON.stringify(form),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data != null) {
                    if (response.data.status) {
                        toast.success("Risk Summary Saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                        setSavingRisk(false);
                        window.location.reload(true);
                    }
                }
            }
            else {
                setSavingRisk(false);
                toast.error("Select mandatory fields", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
                return false;
            }
        }
        else {
            setSavingRisk(false);
            toast.error(errorRiskList, { duration: 10000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            return false;
        }
    }
    function handleAddRiskPopupChange(event) {
        const { name, value, checked, type } = event.target;
        setAddRiskPopup({ ...addRiskPopup, [name]: type == 'checkbox' ? checked : value });
    }
    const handleMultiSelectEnterpriseChange = (e) => {
        setAddRiskPopup({ ...addRiskPopup, enterprises: e });
    }
    const handleMultiSelectContaminantChange = (e) => {
        setAddRiskPopup({ ...addRiskPopup, contaminants: e });
    }
    const handleMultiSelectCategoryChange = (e) => {
        setAddRiskPopup({ ...addRiskPopup, category: e });
    }
    const handleMultiSelectRiskDataGroupChange = (e) => {
        setAddRiskPopup({ ...addRiskPopup, riskDataGroups: e });
    }
    const handleMultiSelectActivityGroupChange = (e) => {
        setLookupList({
            ...lookupList, activitySubGroups: []
        })
        var selectedActivitySubGroups = [];
        let myArrayFiltered = [];
        if (addRiskPopup.activitySubGroups != undefined && addRiskPopup.activitySubGroups.length > 0) {
            selectedActivitySubGroups = addRiskPopup.activitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
            myArrayFiltered = lookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        else {
            myArrayFiltered = lookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        setAddRiskPopup({ ...addRiskPopup, activityGroups: e, activitySubGroups: selectedActivitySubGroups });
        setLookupList({
            ...lookupList, activitySubGroups: myArrayFiltered
        })
    }

    const handleMultiSelectActivitySubGroupChange = (e) => {
        setAddRiskPopup({ ...addRiskPopup, activitySubGroups: e });
    }

    const handleReasonTextChange = (e) => {
        setReasonPopup({ ...reasonPopup, reason: e.target.value });
    };
    const openRemoveOptOutValidation = async () => {
        setShowRemoveOptOutValidation(true);
    }
    const confirmReasonPopup = async () => {
        setFormDocumentErrors({});
        const errors = {};
        if (reasonPopup.reason == null || reasonPopup.reason == "") {
            errors.reason = "Reason can not be blank!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            const response = await axios.get(
                '/api/Risk/UpdateReason/', {
                params: {
                    id: reasonPopup.reasonItemId, reason: reasonPopup.reason, userId: userId, farmId: farmId
                }
            }
            );
            if (response.data.status) {
                setReasonPopup({ ...reasonPopup, showReasonPopup: false, mode: '' });
                toast.success("Reason updated!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setTimeout(() => {
                    window.location.reload(true);
                }, 1000);
            }
            else {
                toast.error("Error while updating reason", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }
    const closeReasonPopup = async () => {
        setReasonPopup({ ...reasonPopup, showReasonPopup: false, mode: '' });
    }
    const saveRemoveOptOut = async () => {
        const response = await axios.get(
            '/api/Risk/UpdateReason/', {
            params: {
                id: reasonPopup.reasonItemId, reason: null, userId: userId
            }
        }
        );
        if (response.data.status) {
            setShowRemoveOptOutValidation(false);
            setReasonPopup({ ...reasonPopup, showReasonPopup: false, mode: '' });
            toast.success("Opt out reason is removed!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        }
        else {
            toast.error("Error while removing Opt out", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
    }
    const closeRemoveOptOutValidation = async () => {
        setShowRemoveOptOutValidation(false);
    }

    const exportRiskSummary = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleRiskSummaryReport(e);
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleRiskSummaryReport = async (e) => {
        const response = await axios.get(
            '/api/Summary/GetRiskSummaryExportExcel/', {
            params: { farmId: farmId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "RiskSummary.xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const [showRemovedRisks, setShowRemovedRisks] = useState(false);

    const setRiskIsActive = (idx) => {
        const tempRows = [...riskList];
        tempRows[idx].isActive = !tempRows[idx].isActive;
        setRiskList(tempRows);
        if (!tempRows[idx].isActive) {
            const tempLURows = [...luRiskList];
            tempLURows.filter(x => x.riskId == tempRows[idx].id && x.isAnyOpenAction == false).map((item) => {
                var cidx = tempLURows.findIndex(obj => item.riskId == obj.riskId && item.itemId == obj.itemId);
                tempLURows[cidx].isActive = false;
            });
            setLURiskList(tempLURows);
        }
    }

    const setShowAllLUs = (item) => {
        const tempRows = [...riskList];
        var idx = tempRows.findIndex(obj => item.id === obj.id);
        tempRows[idx].showAllLUs = !tempRows[idx].showAllLUs;
        setRiskList(tempRows);
    }

    const setLURiskIsActive = (item) => {
        const tempRows = [...luRiskList];
        var idx = tempRows.findIndex(obj => item.riskId === obj.riskId && item.itemId === obj.itemId);
        tempRows[idx].isActive = !tempRows[idx].isActive;
        if (!tempRows[idx].isActive) {
            tempRows[idx].riskImpact = null;
            tempRows[idx].riskLikelyhood = null;
        }
        setLURiskList(tempRows);
    }


    const editCustomRisk = (e, id) => {
        e.preventDefault();
        var risk = riskList.filter(x => x.id == id)[0];
        var enterprises = []; var activityGroups = []; var activitySubGroups = []; var contaminants = []; var riskDataGroups = []; var category = {};
        var selectedEnterprises = risk.activityIds.split(',');
        var selectedActivityGroups = risk.activityGroupIds.split(',');
        var selectedActivitySubGroups = risk.activitySubGroupIds.split(',');
        var selectedContaminants = risk.contaminantIds.split(',');
        var selectedRiskDataGroups = risk.riskDataGroupIds.split(',');
        category = lookupList.categoryList.filter(x => x.value == risk.category);
        selectedEnterprises.map((item) => {
            var enterprise = lookupList.tempEnterpriseTypes.filter(x => x.value == parseInt(item));
            if (enterprise != undefined && enterprise.length > 0) {
                enterprises.push(enterprise[0]);
            }
        });
        selectedActivityGroups.map((item) => {
            var activityGroup = lookupList.activityGroups.filter(x => x.value == parseInt(item));
            if (activityGroup != undefined && activityGroup.length > 0) {
                activityGroups.push(activityGroup[0]);
            }
        });
        selectedActivitySubGroups.map((item) => {
            var activitySubGroup = lookupList.tempActivitySubGroups.filter(x => x.value == parseInt(item));
            if (activitySubGroup != undefined && activitySubGroup.length > 0) {
                activitySubGroups.push(activitySubGroup[0]);
            }
        });
        selectedContaminants.map((item) => {
            var contaminant = lookupList.contaminants.filter(x => x.value == parseInt(item));
            if (contaminant != undefined && contaminant.length > 0) {
                contaminants.push(contaminant[0]);
            }
        });
        selectedRiskDataGroups.map((item) => {
            var riskDataGroup = lookupList.riskDataGroups.filter(x => x.value == parseInt(item));
            if (riskDataGroup != undefined && riskDataGroup.length > 0) {
                riskDataGroups.push(riskDataGroup[0]);
            }
        });
        setAddRiskPopup({ ...addRiskPopup, header: 'Edit Custom Risk', isActive:risk.isActive, openActions:risk.openAction, id: risk.id, showPopup: true, name: risk.name, description: risk.description, enterprises: enterprises, activityGroups: activityGroups, activitySubGroups: activitySubGroups, contaminants: contaminants, riskDataGroups: riskDataGroups, category });
    }

    const handleRiskLevel = (e, id, level) => {
        const tempRows = [...luRiskList];
        var lu = tempRows.filter(x => x.uId == id)[0];
        if (level == 'low') {
            lu.lowRisk = true;
            lu.mediumRisk = false;
            lu.highRisk = false;
        }
        else if (level == 'medium') {
            lu.lowRisk = false;
            lu.mediumRisk = true;
            lu.highRisk = false;
        }
        else if (level == 'high') {
            lu.lowRisk = false;
            lu.mediumRisk = false;
            lu.highRisk = true;
        }

        const objWithIdIndex = tempRows.findIndex((obj) => obj.uId === id);
        if (objWithIdIndex > -1) {
            tempRows.splice(objWithIdIndex, 1);
        }
        let newList = [];
        tempRows.map((item, idx) => {
            newList.push(item);
        });
        newList.push(lu);
        newList = [...newList].sort((a, b) => a.itemId - b.itemId);
        setLURiskList(newList);
    }

    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0, whyThisRiskNotRelevant: '' }); const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const [itemAddPopup, setItemAddPopup] = useState({ showItemAddPopup: false, itemId: 0 }); const [itemAddsaving, setItemAddSaving] = useState('');
    function handleItemDeletePopup(e,itemId) {
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }
    function handleItemAddPopup(e, itemId) {
        setItemAddPopup({ showItemAddPopup: true, itemId: itemId });
    }
    function handleItemDeletePopupClose() {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0, whyThisRiskNotRelevant: '' });
    }
    function handleItemAddPopupClose() {
        setItemAddPopup({ showItemAddPopup: false, itemId: 0 });
    }
    const addRisk = async (event) => {
        event.preventDefault()
        setItemAddSaving(true);
        let form = {
            FarmId: farmId,
            UserId: userId,
            RiskId: itemAddPopup.itemId, RiskList: riskList,
            LURiskList: luRiskList,
            ActiveCategoryList: [],
            NonActiveCategoryList: []
        }
        const response = await axios.post(
            '/api/Risk/AddRisk/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data != null) {
            if (response.data.status) {
                setRiskList(response.data.content.riskList);
                setLURiskList(response.data.content.luRiskList);
                setActiveCategoryList(response.data.content.activeCategoryList);
                setNonActiveCategoryList(response.data.content.nonActiveCategoryList);
                toast.success("Risk Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                setItemAddSaving(false);
                setItemAddPopup({ showItemAddPopup: false, itemId: 0 });
                //window.location.reload(true);
            }
        }
    }
    const removeRisk = async (event) => {
        event.preventDefault();
        setFormDocumentErrors({});
        const errors = {};
        if (itemDeletePopup.whyThisRiskNotRelevant == null || itemDeletePopup.whyThisRiskNotRelevant == "") {
            errors.whyThisRiskNotRelevant = "This is mandatory!";
        }
        setFormDocumentErrors(errors);
        if (Object.keys(errors).length == 0) {
            setItemDeleteSaving(true);
            let form = {
                FarmId: farmId,
                UserId: userId,
                RiskId: itemDeletePopup.itemId,
                WhyThisRiskNotRelevant: itemDeletePopup.whyThisRiskNotRelevant,
                RiskList: riskList,
                LURiskList: luRiskList,
                ActiveCategoryList: [],
                NonActiveCategoryList:[]
            }
            const response = await axios.post(
                '/api/Risk/RemoveRisk/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    setRiskList(response.data.content.riskList);
                    setLURiskList(response.data.content.luRiskList);
                    setActiveCategoryList(response.data.content.activeCategoryList);
                    setNonActiveCategoryList(response.data.content.nonActiveCategoryList);
                    toast.success("Risk Removed!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setItemDeleteSaving(false);
                    setItemDeletePopup({ showItemDeletePopup: false, itemId: 0, whyThisRiskNotRelevant: '' });
                    //window.location.reload(true);
                }
            }
        }
    }
    const handleShowNonRelevantRisks = (e) => {
        setShowNonRelevantRisks(!showNonRelevantRisks);
    }
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true); const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };

    const handleItemView = (itemId, routePath) => {
        navigate('/' + routePath, { state: { itemId: itemId, navigateFrom: 'Biodiversity' } });
    }

    const handleWhyThisRiskNotRelevantTextChange = (e) => {
        setItemDeletePopup({ ...itemDeletePopup, whyThisRiskNotRelevant: e.target.value });
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltipOpen = () => setTooltipOpen(!tooltipOpen);

    const showLUPopup = (e, lu) => {
        setLUPopup({ ...luPopup, showLUPopup: true, size: lu.size, topography: lu.topography, predominantSoilType: lu.predominantSoilType, secondarySoilType: lu.secondarySoilType, inherentRisk: lu.inherentRisk, name :lu.name})
    }
    const hideLUPopup = (e) => {
        setLUPopup({ ...luPopup, showLUPopup: false, size: '', inherentRisk: '', topography: '', predominantSoilType: '', secondarySoilType: '', name: '' })
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col sm="6"><h2>{riskSummary.SectionName}</h2></Col>
                {!loading &&
                    <>
                        <Col sm="6">
                                {riskSummary.isWizardCompleted != true && 
                                    <>
                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                            <>
                                                <Button className="float-end btn-next-page" onClick={(section) => handleWizardChanged(riskSummary.Section)}>Next <FaChevronRight /> </Button>
                                            </>
                                        }
                                        {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                            <>
                                                <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(riskSummary.Section)}>Next <FaChevronRight /> </Button>
                                            </>
                                        }
                                        <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(riskSummary.Section)}><FaChevronLeft /> Back  </Button>
                                    </>
                        }
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                                    {savingRisk ?
                                        <Button onClick={(e) => handlSaveRisk(e)} color="success" disabled className="btn float-end margin-right10 button-save">
                                            <Row>
                                                <Col sm="6">
                                                    <span>
                                                        {' '}Saving
                                                    </span>
                                                </Col>
                                                <Col sm="6">
                                                    <Spinner size="sm">
                                                        Saving...
                                                    </Spinner>
                                                </Col>
                                            </Row>
                                        </Button>
                                        :
                                        <Button onClick={(e) => handlSaveRisk(e)} color="success" className="btn float-end margin-right10 button-save">
                                            Save
                                        </Button>
                                    }

                                    <Button type="button" color="primary" id="btnUpdate" className="btn float-end margin-right10" onClick={(e) => handlAddRiskPopupOpen(e)}>Add New</Button>
                                </>
                            }
                            <Button style={{ float: "right", background: "none", border: "none" }} data-toggle="tooltip" data-placement="top" title="Export to Excel" onClick={(e) => exportRiskSummary(e)} value="Export" placeholder="">
                                <img src="/images/Excel_icon.png" className="card_icon" ></img>
                            </Button>
                        </Col>
                    </>
                }
            </Row>
            {
                loading ? <SpinnerContainer /> :
                    <div style={{}}>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Label className="info-textLarge">The farming activities on this property include "{farmingEnterpriseLabel == null ? "" : farmingEnterpriseLabel}". These are performed across a total area of {totalAreaLabel}ha, which is divided into {totalLUsLabel} land units.  The Land Units have some inherent vulnerabilities including {inherentRiskLabel}.</Label>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row className="heading-underline-risk-summary">
                            <Card className="my-4">
                                <CardHeader className="item-header card-header-click card-header-outline" onClick={toggle}>
                                    <Row>
                                        <Col sm="11">
                                            <h5>Joining Together Growing Activities and Land Units to evaluate Risk</h5>
                                        </Col>
                                        <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                                    </Row>
                                </CardHeader>
                                <Collapse isOpen={isOpen}>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <Badge style={{ fontSize: "20px" }} pill color="success">{1}</Badge>&nbsp;&nbsp;&nbsp;<label style={{ fontSize: "20px", color:"#008436",fontWeight:"500"}}>Part 1 - Overview</label>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{marginLeft:"75px"}}>
                                            <Col sm="4">
                                                <Row>
                                                    <Col>
                                                        <label style={{ fontSize: "18px", color: "#008436", fontWeight: "500" }}>Land Use Activities</label>
                                                        <br />
                                                        Activities on this property include.<br/>
                                                        {farmingEnterpriseLabel != undefined && farmingEnterpriseLabel.length > 0 &&
                                                            farmingEnterpriseLabel.map(item =>
                                                                <><label>⁃ {item}</label>
                                                                  <br/>
                                                                </>
                                                        )
                                                        }
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <label style={{ fontSize: "18px", color: "#008436", fontWeight: "500" }}>Risks to Fresh Water</label>
                                                        <br />
                                                        The system has generated a series of Freshwater risks to consider.
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="4">
                                                <img width={325} height={325} src="/images/risk-diagram.jpg" className="rounded" alt="Catchment Image"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = "/images/no-image.jpg"
                                                    }} />
                                            </Col>
                                            <Col sm="4">
                                                <Row>
                                                    <Col>
                                                        <label style={{ fontSize: "18px", color: "#008436", fontWeight: "500" }}>Land Units</label>
                                                        <br />
                                                        There are {totalLUsLabel} land units on this property with a total area of {totalAreaLabel} ha.
                                                        <br />
                                                        Each Land unit has been mapped and the soils, inherent vulnerabilities, and distinct characteristics outlined   
                                                        <br />
                                                        <label style={{ fontSize: "16px", color: "#008436", fontWeight: "300" }}>Inherent Vulnerabilities on this Property</label>
                                                        <br/>
                                                        {inherentRiskLabel != undefined && inherentRiskLabel.length > 0 &&
                                                            inherentRiskLabel.map(item =>
                                                                <><label>• {item}</label>
                                                                    <br />
                                                                </>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{ marginLeft: "75px" }}>
                                            <Col>
                                                As you proceed through the infrastructure mapping you will be able to raise actions and mitigations directly against these risks.<br/>
                                                All risks can be evaluated and removed if they are not relevant to the operations on this property.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>

                            </Card>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col sm="12">
                                <FaInfoCircle style={{height:"2.5vh",width:"2.5vw"}} data-tooltip-id="my-tooltip-17" />&nbsp;&nbsp;&nbsp;<Badge style={{ fontSize: "20px" }} pill color="success">{2}</Badge>&nbsp;&nbsp;&nbsp;<label style={{ fontSize: "20px", color: "#008436", fontWeight: "500" }}>Part 2 - Evaluate and rate the significance of the risk</label>
                                <ReactTooltip style={{ backgroundColor: "rgb(236, 247, 181)", color: "black", maxWidth: "75vw", wordBreak: 'normal', overflowWrap: 'break-word', fontWeight: "750" }}
                                    id="my-tooltip-17"
                                    place="top"
                                    variant="info"
                                    render={({ content, activeAnchor }) => (
                                        <>
                                            <Row>
                                                <Col>
                                                    <img style={{ height: "15vw", width: "65vw" }} src="images/ZespriRiskMatrix.png" className="" alt="Zespri Risk Matrix"></img> 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontWeight: "lighter" }}>
                                                        Use this matrix to help assess the risk for each risk. First consider how likely this risk is to happen,<br />then consider the impact on your property, catchment and community if this risk did occur.<br />The intersection of liklihood and impact on the chart shows your risk level.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                                <br />
                                <label style={{ fontSize: "17px", color: "black", fontWeight: "500", marginLeft: "30px" }}>Some risks have been generated automatically. Go ahead and:</label>
                                <br />
                                <label style={{ fontSize: "16px", color: "black", fontWeight: "300", marginLeft: "30px" }}>a. Archive the risk if not relevant</label><br />
                                <label style={{ fontSize: "16px", color: "black", fontWeight: "300", marginLeft: "30px" }}>b. Check the risks relate to the correct Land unit(s)</label><br />
                                <label style={{ fontSize: "16px", color: "black", fontWeight: "300", marginLeft: "30px" }}>c. Rate the significance of the risk for each land unit, considering the relevant inherent vulnerabilities you identified previously (listed in Part 1 above)</label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col sm="6"></Col>
                                    <Col sm="6">
                                        <label style={{ float: "right" }} className="outlineLabel form-label" htmlFor="isLargeEnterprise">
                                            Show removed risks
                                        </label>
                                        <Input key={uuid()} style={{ float: "right", marginRight: "10px" }} type="checkbox" checked={showNonRelevantRisks} onChange={(e) => handleShowNonRelevantRisks(e)} name="showNonRelevantRisks" id="showNonRelevantRisks" />
                                    </Col>
                                </Row>
                                <br/>
                            </Col>
                            <Col sm="12">
                                {!showNonRelevantRisks &&  
                                    <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                        <tbody style={{ textAlign: 'center' }}>
                                            {activeCategoryList.length > 0 &&
                                                activeCategoryList.map((item, pidx) =>
                                                    <>
                                                        {
                                                            <>
                                                                <tr key={uuid()}>
                                                                    <td style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#FFCE18', color: 'white' }}> {item} </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <Table className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                        <tbody style={{ textAlign: 'center' }}>
                                                                            {riskList.filter(x => x.category == item && x.isActive == true).length > 0 &&
                                                                                riskList.filter(x => x.category == item && x.isActive == true).map((risk, pidx) =>
                                                                                    <>
                                                                                        {
                                                                                            <>
                                                                                                <tr key={uuid()}>
                                                                                                    <td style={{ width: '40%' }}>
                                                                                                        <p style={{ wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>{risk.name}</p>
                                                                                                        <p style={{ wordBreak: "break-word", FontSize: "14px", textAlign: 'left' }}>{risk.description}</p>
                                                                                                    </td>
                                                                                                    <td style={{ width: '50%', wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>
                                                                                                        <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                                            <thead>
                                                                                                                <tr style={{ background: '#B6D137' }}>
                                                                                                                    <th colSpan="2" rowSpan="1" style={{ width: '70%', textAlign: 'center' }}> Rate the Significance of the Risk for each Land Unit
                                                                                                                    </th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '30%', textAlign: 'center' }}>
                                                                                                                        <Input key={uuid()} style={{ marginLeft: "10px" }} type="checkbox" checked={risk.showAllLUs} onChange={(e) => setShowAllLUs(risk)} name="showAllLUs" id="showAllLUs" />
                                                                                                                        <label style={{ marginLeft: "5px" }} className="outlineLabel form-label" htmlFor="showAllLUs">Show all Land Units (including removed)</label>
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                                <tr style={{ background: '#FFCE18' }}>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '60%', textAlign: 'center' }}> Land Units </th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '30%', textAlign: 'center' }}> Level</th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center' }}> Remove/Include</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                                                {risk.showAllLUs && 
                                                                                                                    <>
                                                                                                                    {luRiskList.filter(x => x.riskId == risk.id).length > 0 &&
                                                                                                                        luRiskList.filter(x => x.riskId == risk.id).map((lu, pidx) =>
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.name}
                                                                                                                                                <span>
                                                                                                                                                    <button onClick={(e) => showLUPopup(e, lu)} type="button" id={"view" + lu.id}
                                                                                                                                                        className="btn btn-primary btn-sm float-end">
                                                                                                                                                        View
                                                                                                                                                    </button>
                                                                                                                                                </span>
                                                                                                                                                
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <ButtonGroup id={"riskLevel" + lu.uId}>
                                                                                                                                                    <Button style={lu.lowRisk == true ? { background: '#1fcc65' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'low')} active={lu.lowRisk}>Low</Button>
                                                                                                                                                    <Button style={lu.mediumRisk == true ? { background: '#ffc107' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'medium')} active={lu.mediumRisk}>Med</Button>
                                                                                                                                                    <Button style={lu.highRisk == true ? { background: '#dc3545' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'high')} active={lu.highRisk}>High</Button>
                                                                                                                                                </ButtonGroup>
                                                                                                                                            </td>
                                                                                                                                            <td style={{ wordBreak: "break-word" }}>
                                                                                                                                                {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                            <Button type="button" color="danger" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                            <Button type="button" color="success" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            !lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                            <Button type="button" color="danger" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            !lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                            <Button type="button" color="success" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                }
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>    
                                                                                                                }
                                                                                                                {!risk.showAllLUs &&
                                                                                                                    <>
                                                                                                                        {luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).length > 0 &&
                                                                                                                        luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).map((lu, pidx) =>
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        <>
                                                                                                                                            <tr>
                                                                                                                                            <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.name}
                                                                                                                                                <span>
                                                                                                                                                    <button onClick={(e) => showLUPopup(e, lu)} type="button" id={"view" + lu.id}
                                                                                                                                                        className="btn btn-primary btn-sm float-end">
                                                                                                                                                        View
                                                                                                                                                    </button>
                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                                <td>
                                                                                                                                                    <ButtonGroup id={"riskLevel" + lu.uId}>
                                                                                                                                                    <Button style={lu.lowRisk == true ? { background: '#1fcc65' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'low')} active={lu.lowRisk}>Low</Button>
                                                                                                                                                    <Button style={lu.mediumRisk == true ? { background: '#ffc107' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'medium')} active={lu.mediumRisk}>Med</Button>
                                                                                                                                                    <Button style={lu.highRisk == true ? { background: '#dc3545' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'high')} active={lu.highRisk}>High</Button>
                                                                                                                                                    </ButtonGroup>
                                                                                                                                                </td>
                                                                                                                                                <td style={{ wordBreak: "break-word" }}>
                                                                                                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                                                                        <>
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                        </>
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        )}
                                                                                                                        {
                                                                                                                            luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).length == 0 &&
                                                                                                                            <tr>
                                                                                                                                <td colSpan="3" style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> All Land Units associated with this risk have been removed. Either mark the risk as "Not Relevant" or restore at least 1 Land Unit. </td>
                                                                                                                            </tr>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </Table>
                                                                                                    </td>
                                                                                                    <td style={{ width: '10%', wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>
                                                                                                        {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                            <>
                                                                                                                {risk.showRiskRemoveButton && risk.type == 'Activity' && <div style={{ marginTop: "50%" }}>
                                                                                                                    <Button onClick={(e) => handleItemDeletePopup(e, risk.id)} className="btn float-end btn-risk-summary">Not Relevant<br />(Remove this Risk)</Button>
                                                                                                                </div>}
                                                                                                                {
                                                                                                                    risk.type == 'Custom' && <div style={{ marginTop: "50%" }}>
                                                                                                                        <Button onClick={(e) => editCustomRisk(e, risk.id)} className="btn float-end btn-risk-summary">Edit Custom Risk</Button>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                        </tbody>
                                                                    </Table>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                        </tbody>
                                    </Table>    
                                }
                                {
                                    showNonRelevantRisks &&
                                    <Table className="Risktable" bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                            <tbody style={{ textAlign: 'center' }}>
                                                {nonActiveCategoryList.length > 0 &&
                                                    nonActiveCategoryList.map((item, pidx) =>
                                                    <>
                                                        {
                                                            <>
                                                                <tr key={uuid()}>
                                                                    <td style={{ wordBreak: "break-word", fontWeight: "700", textAlign: 'left', background: '#FFCE18', color: 'white' }}> {item} </td>
                                                                </tr>
                                                                <tr key={uuid()}>
                                                                    <Table className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                        <tbody style={{ textAlign: 'center' }}>
                                                                            {riskList.filter(x => x.category == item && x.isActive == false).length > 0 &&
                                                                                riskList.filter(x => x.category == item && x.isActive == false).map((risk, pidx) =>
                                                                                    <>
                                                                                        {
                                                                                            <>
                                                                                                <tr key={uuid()}>
                                                                                                    <td style={{ width: '40%' }}>
                                                                                                        <p style={{ wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>{risk.name}</p>
                                                                                                        <p style={{ wordBreak: "break-word", FontSize: "14px", textAlign: 'left' }}>{risk.description}</p>
                                                                                                    </td>
                                                                                                    <td style={{ width: '50%', wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>
                                                                                                        <Table bordered className="Risktable" style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                                            <thead>
                                                                                                                <tr style={{ background: '#B6D137' }}>
                                                                                                                    <th colSpan="2" rowSpan="1" style={{ width: '70%', textAlign: 'center' }}> Rate the Significance of the Risk for each Land Unit
                                                                                                                    </th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '30%', textAlign: 'center' }}>
                                                                                                                        <Input key={uuid()} style={{ marginLeft: "10px" }} type="checkbox" checked={risk.showAllLUs} onChange={(e) => setShowAllLUs(risk)} name="showAllLUs" id="showAllLUs" />
                                                                                                                        <label style={{ marginLeft: "5px" }} className="outlineLabel form-label" htmlFor="showAllLUs">Show all Land Units (including removed)</label>
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                                <tr style={{ background: '#FFCE18' }}>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '60%', textAlign: 'center' }}> Land Units </th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '30%', textAlign: 'center' }}> Level</th>
                                                                                                                    <th colSpan="1" rowSpan="1" style={{ width: '10%', textAlign: 'center' }}> Remove/Include</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                                                {risk.showAllLUs &&
                                                                                                                    <>
                                                                                                                        {luRiskList.filter(x => x.riskId == risk.id).length > 0 &&
                                                                                                                            luRiskList.filter(x => x.riskId == risk.id).map((lu, pidx) =>
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        <>
                                                                                                                                            <tr>
                                                                                                                                                <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.name}
                                                                                                                                                    <span>
                                                                                                                                                        <button onClick={(e) => showLUPopup(e, lu)} type="button" id={"view" + lu.id}
                                                                                                                                                            className="btn btn-primary btn-sm float-end">
                                                                                                                                                            View
                                                                                                                                                        </button>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <ButtonGroup id={"riskLevel" + lu.uId}>
                                                                                                                                                        <Button style={lu.lowRisk == true ? { background: '#1fcc65' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'low')} active={lu.lowRisk}>Low</Button>
                                                                                                                                                        <Button style={lu.mediumRisk == true ? { background: '#ffc107' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'medium')} active={lu.mediumRisk}>Med</Button>
                                                                                                                                                        <Button style={lu.highRisk == true ? { background: '#dc3545' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'high')} active={lu.highRisk}>High</Button>
                                                                                                                                                    </ButtonGroup>
                                                                                                                                                </td>
                                                                                                                                                <td style={{ wordBreak: "break-word" }}>
                                                                                                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                                                                        <>
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                        </>
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                }
                                                                                                                {!risk.showAllLUs &&
                                                                                                                    <>
                                                                                                                        {luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).length > 0 &&
                                                                                                                            luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).map((lu, pidx) =>
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        <>
                                                                                                                                            <tr>
                                                                                                                                                <td style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> {lu.name}
                                                                                                                                                    <span>
                                                                                                                                                        <button onClick={(e) => showLUPopup(e, lu)} type="button" id={"view" + lu.id}
                                                                                                                                                            className="btn btn-primary btn-sm float-end">
                                                                                                                                                            View
                                                                                                                                                        </button>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <ButtonGroup id={"riskLevel" + lu.uId}>
                                                                                                                                                        <Button style={lu.lowRisk == true ? { background: '#1fcc65' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'low')} active={lu.lowRisk}>Low</Button>
                                                                                                                                                        <Button style={lu.mediumRisk == true ? { background: '#ffc107' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'medium')} active={lu.mediumRisk}>Med</Button>
                                                                                                                                                        <Button style={lu.highRisk == true ? { background: '#dc3545' } : { background: '#6c757d' }} onClick={(e) => handleRiskLevel(e, lu.uId, 'high')} active={lu.highRisk}>High</Button>
                                                                                                                                                    </ButtonGroup>
                                                                                                                                                </td>
                                                                                                                                                <td style={{ wordBreak: "break-word" }}>
                                                                                                                                                    {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                                                                        <>
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" style={{ backgroundColor: "#dc3545" }} disabled className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && lu.isActive &&
                                                                                                                                                                <Button type="button" color="danger" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Remove')}>Remove</Button>
                                                                                                                                                            }
                                                                                                                                                            {
                                                                                                                                                                !lu.isAnyOpenAction && !lu.isActive &&
                                                                                                                                                                <Button type="button" color="success" id="btnUpdate" className="btn riskSummary" onClick={(e) => handleRemoveIncludeLURisk(e, lu, 'Include')}>Include</Button>
                                                                                                                                                            }
                                                                                                                                                        </>
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        )}
                                                                                                                    {
                                                                                                                        luRiskList.filter(x => x.riskId == risk.id && x.isActive == true).length == 0 &&
                                                                                                                            <tr>
                                                                                                                                <td colSpan="3" style={{ wordBreak: "break-word", textAlign: "left", fontSize: "14px" }}> All Land Units associated with this risk have been removed. Either mark the risk as "Not Relevant" or restore at least 1 Land Unit. </td>
                                                                                                                            </tr>
                                                                                                                    }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </Table>
                                                                                                    </td>
                                                                                                    <td style={{ width: '10%', wordBreak: "break-word", fontWeight: "500", textAlign: 'left' }}>
                                                                                                        <div style={{ marginTop: "50%" }}>
                                                                                                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                                                                                                <>
                                                                                                                    {risk.type == 'Activity' &&
                                                                                                                        <>
                                                                                                                            {risk.whyThisRiskNotRelevant != undefined && risk.whyThisRiskNotRelevant != null && <><label><i>{risk.whyThisRiskNotRelevant}</i></label><br /></>}
                                                                                                                            <Button onClick={(e) => handleItemAddPopup(e, risk.id)} className="button-save btn btn-success">Relevant<br />(Add this Risk)</Button>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        risk.type == 'Custom' && <Button onClick={(e) => editCustomRisk(e, risk.id)} className="btn float-end btn-risk-summary">Edit Custom Risk</Button>
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                        </tbody>
                                                                    </Table>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Row>
                    </div>

            }

            <Modal contentClassName="" size="lg" style={{ maxWidth: '750px', width: '750px' }} isOpen={descriptionPopup.showPopup}>
                <ModalHeader toggle={handleDescriptionPopupClose}>Risk Description</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <p>{descriptionPopup.description}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleDescriptionPopupClose} color="primary" >Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal contentClassName="" size="lg" style={{ maxWidth: '1250px', width: '1250px' }} isOpen={addRiskPopup.showPopup}>
                <ModalHeader toggle={handleAddRiskPopupClose}>{addRiskPopup.header}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <label style={{ color: "black", fontSize: "16px", fontWeight: "25" }} class="itemTypeList-header-subtext">
                            If you have a risk on this property which is not identified it is possible to create a custom risk with the fields below.
                            </label>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <label style={{ color: "black", fontSize: "16px", fontWeight: "25" }} class="">
                                IMPORTANT NOTE: on the following pages it is also possible to create additional risks against particular items when mapped 
                                (e.g. when mappig a piece of infrastruture which itself might have risk.) In this scenario there is no reason to raise a custom risk here, it may be better on the subsequent mapping pages.
                            </label>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Category</Label>
                                <br/>
                                <label class="itemTypeList-header-subtext">Select which category this risk in.</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Category"
                                    value={addRiskPopup.category}
                                    options={lookupList.categoryList}
                                    onChange={handleMultiSelectCategoryChange}
                                    hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formDocumentErrors.category != undefined &&
                                <p className="errorMessage">{formDocumentErrors.category}</p>
                            }
                        </Col>
                        {showEnterprise &&
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Enterprise(s)</Label>
                                    <br />
                                    <label class="itemTypeList-header-subtext">Specify which Activities / Enterprises this risk relates to.<br />
                                        The risk will only be applied to Land Units with the specified activity(s).
                                    </label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        className="dropdown"
                                        placeholder="Select Enterprise(s)"
                                        value={addRiskPopup.enterprises}
                                        options={lookupList.enterpriseTypes}
                                        onChange={handleMultiSelectEnterpriseChange}
                                        isMulti hideSelectedOptions={false}
                                        isClearable
                                        components={{
                                            Option
                                        }}
                                    />
                                </FormGroup>
                                {formDocumentErrors.enterprises != undefined &&
                                    <p className="errorMessage">{formDocumentErrors.enterprises}</p>
                                }
                            </Col>
                        }
                    </Row>
                    <Row style={{marginTop:"10px"}}>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Details</Label>
                                <br />
                                <label class="itemTypeList-header-subtext">Give this Risk a name (generally 5 to 20 words)</label>
                                <Input type="text" name="name" id="name" value={addRiskPopup.name} onChange={handleAddRiskPopupChange} />
                            </FormGroup>
                            {formDocumentErrors.name != undefined &&
                                <p className="errorMessage">{formDocumentErrors.name}</p>
                            }
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Contaminant(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Contaminant(s)"
                                    value={addRiskPopup.contaminants}
                                    options={lookupList.contaminants}
                                    onChange={handleMultiSelectContaminantChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formDocumentErrors.contaminants != undefined &&
                                <p className="errorMessage">{formDocumentErrors.contaminants}</p>
                            }
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <label class="itemTypeList-header-subtext">Description can be a more detailed overview of the risk (50 to 100 words)</label>
                                <textarea name="description"
                                    value={addRiskPopup.description}
                                    onChange={handleAddRiskPopupChange}
                                    className="form-control"></textarea>
                            </FormGroup>
                            {formDocumentErrors.description != undefined &&
                                <p className="errorMessage">{formDocumentErrors.description}</p>
                            }
                        </Col>
                    </Row>                    
                    <Row>
                        <Col>
                            <label style={{ color: "black", fontSize: "18px", fontWeight:"600" }} class="itemTypeList-header-subtext"><u>Ministry for the Environment - data reporting requirements</u></label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        <Col>
                            <label class="itemTypeList-header-subtext">When a FreshWater Plan is certified Actions and Risks and are required to be reported and associated with Risk Data Groups and Activity Groups. For this custom risk please specify which item(s) in the list below best match this risk.</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Mfe Risk Data Group(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Mfe Risk Data Group(s)"
                                    value={addRiskPopup.riskDataGroups}
                                    options={lookupList.riskDataGroups}
                                    onChange={handleMultiSelectRiskDataGroupChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formDocumentErrors.riskDataGroups != undefined &&
                                <p className="errorMessage">{formDocumentErrors.riskDataGroups}</p>
                            }
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Mfe Activity Group(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Mfe Activity Group(s)"
                                    value={addRiskPopup.activityGroups}
                                    options={lookupList.activityGroups}
                                    onChange={handleMultiSelectActivityGroupChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formDocumentErrors.activityGroups != undefined &&
                                <p className="errorMessage">{formDocumentErrors.activityGroups}</p>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto", fontSize: "16px" }}>Mfe Activity sub Group(s)</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    className="dropdown"
                                    placeholder="Select Mfe Activity sub Group(s)"
                                    value={addRiskPopup.activitySubGroups}
                                    options={lookupList.activitySubGroups}
                                    onChange={handleMultiSelectActivitySubGroupChange}
                                    isMulti hideSelectedOptions={false}
                                    isClearable
                                    components={{
                                        Option
                                    }}
                                />
                            </FormGroup>
                            {formDocumentErrors.activitySubGroups != undefined &&
                                <p className="errorMessage">{formDocumentErrors.activitySubGroups}</p>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {addingRisk ?
                        <Button onClick={(e) => handlSaveRisk(e)}
                            color="success" className="button-save" style={{ float: `right`, marginLeft: '10px' }}
                            disabled
                        >
                            <Row>
                                <Col sm="6">
                                    <span>
                                        {' '}Saving
                                    </span>
                                </Col>
                                <Col sm="6">
                                    <Spinner size="sm">
                                        Saving...
                                    </Spinner>
                                </Col>
                            </Row>
                        </Button>
                        :
                        <Button className="button-save" style={{ float: `right`, marginLeft: '10px' }} onClick={(e) => handleAddRiskPopupSave(e)} color="success">
                            Save
                        </Button>
                    }
                    {
                        addRiskPopup.id != 0 && addRiskPopup.openActions == 0 && addRiskPopup.isActive &&
                        <>
                            {deleteRisk ?
                                <Button onClick={(e) => handleRemoveCustomRisk(e)}
                                    color="danger" style={{ float: `right`, marginLeft: '10px' }}
                                    disabled
                                >
                                    <Row>
                                        <Col sm="6">
                                            <span>
                                                {' '}Removing
                                            </span>
                                        </Col>
                                        <Col sm="6">
                                            <Spinner size="sm">
                                                Removing...
                                            </Spinner>
                                        </Col>
                                    </Row>
                                </Button>
                                :
                                <Button style={{ float: `right`, marginLeft: '10px' }} onClick={(e) => handleRemoveCustomRisk(e)} color="danger">
                                    Remove
                                </Button>
                            }
                        </>
                    }
                    {
                        addRiskPopup.id != 0 && addRiskPopup.openActions == 0 && !addRiskPopup.isActive &&
                        <>
                            {deleteRisk ?
                                <Button onClick={(e) => handleRemoveCustomRisk(e)}
                                    color="success" style={{ float: `right`, marginLeft: '10px' }}
                                    disabled
                                >
                                    <Row>
                                        <Col sm="6">
                                            <span>
                                                {' '}Including
                                            </span>
                                        </Col>
                                        <Col sm="6">
                                            <Spinner size="sm">
                                                Including...
                                            </Spinner>
                                        </Col>
                                    </Row>
                                </Button>
                                :
                                <Button style={{ float: `right`, marginLeft: '10px' }} onClick={(e) => handleRemoveCustomRisk(e)} color="success">
                                    Include
                                </Button>
                            }
                        </>
                    }
                    <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleAddRiskPopupClose} color="primary" >Cancel</Button> 
                </ModalFooter>
            </Modal>
            <Modal isOpen={reasonPopup.showReasonPopup}>
                <ModalHeader>Please provide a reason for opting out of this risk. Once opted out this risk will no longer be able to be selected by an action</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto" }}>Reason</Label>
                                <textarea name="reason"
                                    value={reasonPopup.reason}
                                    onChange={handleReasonTextChange}
                                    className="form-control"></textarea>
                            </FormGroup>
                            {formDocumentErrors.reason != undefined &&
                                <p className="errorMessage">{formDocumentErrors.reason}</p>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {reasonPopup.mode == 'Edit' && <Button color="primary" onClick={openRemoveOptOutValidation}>Remove Opt out</Button>}
                    <Button color="primary" onClick={confirmReasonPopup}>Save</Button>
                    <Button color="danger" onClick={closeReasonPopup}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showRemoveOptOutValidation}>
                <ModalHeader>Remove Opt out</ModalHeader>
                <ModalBody>
                    This will re-activate the risk and remove the opt-out reason. Are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={saveRemoveOptOut}>Yes</Button>
                    <Button color="danger" onClick={closeRemoveOptOutValidation}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Do you want to remove the risk ?</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ textTransform: "math-auto" }}>Please give a brief outline of why this risk is not relevant to the property</Label>
                                <textarea name="reason"
                                    value={itemDeletePopup.whyThisRiskNotRelevant}
                                    onChange={handleWhyThisRiskNotRelevantTextChange}
                                    className="form-control"></textarea>
                            </FormGroup>
                            {formDocumentErrors.whyThisRiskNotRelevant != undefined &&
                                <p className="errorMessage">{formDocumentErrors.whyThisRiskNotRelevant}</p>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {itemDeletesaving ?
                        <>
                            <Button onClick={removeRisk} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={removeRisk} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={itemAddPopup.showItemAddPopup}>
                <ModalHeader toggle={handleItemAddPopupClose} >Add Risk</ModalHeader>
                <ModalBody>
                    Do you want to add the risk ?
                </ModalBody>
                <ModalFooter>
                    {itemAddsaving ?
                        <>
                            <Button onClick={addRisk} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={addRisk} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemAddPopupClose}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal centered={true} size="lg" style={{ maxWidth: '1050px', width: '100%' }} aria-labelledby="contained-modal-title-vcenter" isOpen={luPopup.showLUPopup}>
                <ModalHeader toggle={hideLUPopup} >{luPopup.name}</ModalHeader>
                <ModalBody>
                        <Row>
                            <Col sm="6">
                                <b>Size (ha)</b>
                            </Col>
                            <Col sm="6">
                            {luPopup.size}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm="6">
                            <b>Topography</b>
                            </Col>
                            <Col sm="6">
                            {luPopup.topography}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="6">
                            <b>Predominant Soil Type (%)</b>
                            </Col>
                            <Col sm="6">
                            {luPopup.predominantSoilType}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="6">
                            <b>Secondary Soil Type (%)</b>
                            </Col>
                            <Col sm="6">
                            {luPopup.secondarySoilType}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="6">
                            <b>Inherent Vulnerabilities</b>
                            </Col>
                            <Col sm="6">
                            {luPopup.inherentRisk}
                            </Col>
                        </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={hideLUPopup}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default RiskSummary;