import React, { useState,useEffect } from 'react'
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import MapType from './MapType';
import Select, { components } from 'react-select';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const Header = ({ handleLMUChange, itemPopupHeader, itemTypeFieldList, itemPopupItemSepcQ, itemPopupMaptype, largeEnterpriseDetails, formErrors, handleItemPopupHeaderChange, Section }) => {
    let isMulti = itemPopupMaptype.markertype == 'marker' ? false : true;
    const LMUList = largeEnterpriseDetails != null ? largeEnterpriseDetails.LMUList : [];
    let lmuItems = [];
    const selectedLMUId = largeEnterpriseDetails != null ? largeEnterpriseDetails.selectedLMUId : 0;
    const isLargeEnterprise = largeEnterpriseDetails != null ? largeEnterpriseDetails.isLargeEnterprise : false;
    let selectedLMU = [];
    LMUList.forEach(lmu => {
        var obj = {};
        obj.label = lmu.name;
        obj.value = lmu.id;
        lmuItems.push(obj);
    })
    if (itemPopupHeader.LMUIds != null && itemPopupHeader.LMUIds.length > 0) {
        lmuItems.forEach(lmu => {
            itemPopupHeader.LMUIds.split(',').forEach(item => {
                if (lmu.value == parseInt(item)) {
                    var obj = {};
                    obj.label = lmu.label;
                    obj.value = lmu.value;
                    selectedLMU.push(obj);
                }
            })
        });
    }
    else if (selectedLMUId == 0) {
        selectedLMU = [];
    }
    else {
        var lmuItemObj = lmuItems.filter(x => x.value == selectedLMUId);
        if (lmuItemObj.length > 0) {
            var obj = {};
            obj.label = lmuItemObj.label;
            obj.value = lmuItemObj.value;
            selectedLMU.push(obj);
        }
    }

    //selectedLMU = itemPopupHeader.LMUIds != null ? itemPopupHeader.LMUIds : selectedLMUId;
    const LMUParentList = selectedLMUId > 0 ? LMUList.filter(lmu => !lmu.isConnectedToAnotherLMU && lmu.id == selectedLMUId) : LMUList.filter(lmu => !lmu.isConnectedToAnotherLMU);
    const childCount = LMUList.filter(child => child.lmuId == selectedLMU).length;
    const lmustyle = selectedLMUId > 0 && childCount == 0 && selectedLMUId == selectedLMU ? true : false
    let IsFencedReq = false;
    if (itemPopupHeader.page == 'Waterways') {
        IsFencedReq = itemTypeFieldList.filter(x => x.itemFeildName == 'IsFenced').length > 0 ? true : false
        if (IsFencedReq) {
            if (itemPopupItemSepcQ.FencingPercentage != '136') {
                itemPopupHeader.color = '#FFA500';
            }
            else {
                itemPopupHeader.color = '#0000FF';
            }

        }
        else {

        }
    }
    const [showColor, setShowColor] = useState(true);
    useEffect(() => {
        var showcolor = true;
        if (itemPopupHeader.page == 'Community & Cultural Significance') {
            showcolor = false;
        }
        else if (itemPopupHeader.page == 'Infrastructure' && itemPopupMaptype.markertype=='marker') {
            showcolor = false;
        }
        setShowColor(showcolor);
    }, [])
    return (    
        <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Page</Label>
                        <Input type="text" name="page" id="page" value={itemPopupHeader.page} readOnly disabled />
                    </FormGroup>
                </Col>
                {itemPopupHeader.page != 'Community & Cultural Significance' && <Col>
                    <FormGroup>
                        <Label>LU</Label>
                        {LMUList != undefined && LMUList != null &&
                            <Select name="LMUId" id="LMUId"
                                closeMenuOnSelect={false}
                                className="dropdown validate"
                                placeholder="Select LU"
                                value={selectedLMU}
                                options={lmuItems}
                                onChange={handleLMUChange}
                                isMulti={isMulti} hideSelectedOptions={false}
                                isClearable
                                components={{
                                    Option
                                }}
                            />
                        }
                    </FormGroup>
                    {formErrors.luid != undefined &&
                        <p className="errorMessage">{formErrors.luid}</p>
                    }
                </Col>}
                
                <Col>
                    <FormGroup>
                        <Label>Item</Label>
                        <Input type="text" name="itemTypeName" id="itemTypeName" value={itemPopupHeader.itemTypeName} readOnly disabled />
                    </FormGroup>
                </Col>
            </Row>
            {itemPopupHeader.itemTypeDescription != undefined && itemPopupHeader.itemTypeDescription != '' &&
                <Row>
                    <Col>
                        <Label><b><i>{itemPopupHeader.itemTypeDescription}</i></b></Label>
                    </Col>
                </Row>
            }
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>Name</Label>
                        <Input type="text" className="validate" name="Name" id="Name" value={itemPopupHeader.Name} onChange={handleItemPopupHeaderChange} required />
                    </FormGroup>
                </Col>
                {showColor && <Col sm="2">
                    <FormGroup>
                        <Label>Colour</Label>
                        {!IsFencedReq ?
                            <Input type="color" name="color" id="color" value={itemPopupHeader.color} onChange={handleItemPopupHeaderChange} /> :
                            <Input type="color" name="color" id="color" value={itemPopupHeader.color} disabled={true} />
                        }
                    </FormGroup>
                </Col>}
                
                <MapType itemPopupMaptype={itemPopupMaptype}></MapType>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input type="textarea" className="" name="Description" id="Description" value={itemPopupHeader.Description} onChange={handleItemPopupHeaderChange} />
                    </FormGroup>
                </Col>
            </Row>
            

        </div>

)
    
}
export default Header