import React, { Component, useEffect, useState, useCallback } from "react";
import axios from 'axios';
import polyline from '@mapbox/polyline';

export class staticMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            zoomlevel: 15,
            center: {
                lat: - 41.392263, lng: 174.436898
            },
            mapTypeId: 'satellite',
            mapHeight: '60vh',
            mapImage: '',
            farmCenter: '',
            farmOutline: '',
            itemMarkerString: "",
            itemPolygonString: "",
            itemPolygonMarkerString: "",
            itemPolylineString: "",
            itemPolylineMarkerString: "",
            itemRectangleString: "",
            itemRectangleMarkerString: "",
            itemCircleString: "",
            itemCircleMarkerString: "",
            apiKey: this.props.apiKey,
            centerCo: '',
            zoom: 12,
            size: '600,400',
            type: 'sat',
            imagetype: 'jpeg',
            id: ""
        };
    }

    componentDidMount() {
        this.getFarmCentre()
        this.fetchMapImage();
    }

    fetchMapImage = async () => {
        try {
            let mapType = "sat";
            const id = this.props.data.id;
            if (id == "LandUnitsMapTerrainImage")
                mapType = "light";
            const size = "1400,800";
            const outlineList = this.props.data.outlineList;
            const farmCentreReq = this.props.data.farmCentreReq
            const itemList = this.props.data.itemList;
            const zoomLevel = this.props.data.mapDetails.zoomLevel;
            let isLU = false;
            if (id == "LandUnitsMapImage" || id =="LandUnitsMapTerrainImage") {
                isLU = true;
            }

            farmCentreReq == true && staticMap.renderFarmCenter(outlineList, this.state);
            

            staticMap.renderOutline(outlineList, this.state);
            if (id == "AllItemsMapImage" || id == "AllActionsMapImage" || id == "PropertyMapImage"
                || id == "InfrastructureMapImage" || id == "AgrichemicalsMapImage" || id == "LandUnitsMapImage" || id =="LandUnitsMapTerrainImage" || id == "WaterwayMapImage"
                || id == "LandAndErosionMapImage" || id == "FreshwaterIrrigationMapImage" || id == "MahingKaiMapImage" || id =="WaterTestSitesImage2") {
                itemList.length > 0 && staticMap.renderItemDefaultMarker(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemPolygonListDefaultMarker(itemList, this.state, isLU);
                itemList.length > 0 && staticMap.renderItemPolylineListDefaultMarker(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemRectangleListDefaultMarker(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemCircleListDefaultMarker(itemList, this.state);
                let locations = "";
                //if (this.state.farmCenter != "")
                //    locations = this.state.farmCenter;
                if (this.state.itemMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemMarkerString : this.state.itemMarkerString
                if (this.state.itemPolygonMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemPolygonMarkerString : this.state.itemPolygonMarkerString
                if (this.state.itemPolylineMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemPolylineMarkerString : this.state.itemPolylineMarkerString
                if (this.state.itemRectangleMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemRectangleMarkerString : this.state.itemRectangleMarkerString
                if (this.state.itemCircleMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemCircleMarkerString : this.state.itemCircleMarkerString

                var defaultMarker = 'marker-num-FFCE18';
                if (id == 'AllItemsMapImage') {
                    defaultMarker = 'marker-FFCE18';
                }
                const response = await axios.get(
                    `https://www.mapquestapi.com/staticmap/v5/map?key=${this.props.apiKey}&size=${size}&center=${this.state.center.lat},${this.state.center.lng}&zoom=${zoomLevel}&type=${mapType}&locations=${locations}&defaultMarker=${defaultMarker}&shape=${this.state.farmOutline}&shape=${this.state.itemPolygonString}&shape=${this.state.itemPolylineString}&shape=${this.state.itemRectangleString}&shape=${this.state.itemCircleString}`
                );

                this.setState({
                    mapImage: response.request.responseURL, id: id
                });
            }
            else {
                itemList.length > 0 && staticMap.renderItemMarker(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemPolygonList(itemList, this.state, isLU);
                itemList.length > 0 && staticMap.renderItemPolylineList(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemRectangleList(itemList, this.state);
                itemList.length > 0 && staticMap.renderItemCircleList(itemList, this.state);
                let locations = "";
                if (this.state.farmCenter != "")
                    locations = this.state.farmCenter;
                if (this.state.itemMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemMarkerString : this.state.itemMarkerString
                if (this.state.itemPolygonMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemPolygonMarkerString : this.state.itemPolygonMarkerString
                if (this.state.itemPolylineMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemPolylineMarkerString : this.state.itemPolylineMarkerString
                if (this.state.itemRectangleMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemRectangleMarkerString : this.state.itemRectangleMarkerString
                if (this.state.itemCircleMarkerString != "")
                    locations = locations != "" ? locations + "||" + this.state.itemCircleMarkerString : this.state.itemCircleMarkerString

                const response = await axios.get(
                    `https://www.mapquestapi.com/staticmap/v5/map?key=${this.props.apiKey}&size=${size}&center=${this.state.center.lat},${this.state.center.lng}&zoom=${zoomLevel}&type=${mapType}&locations=${locations}&shape=${this.state.farmOutline}&shape=${this.state.itemPolygonString}&shape=${this.state.itemPolylineString}&shape=${this.state.itemRectangleString}&shape=${this.state.itemCircleString}`
                );

                this.setState({
                    mapImage: response.request.responseURL, id: id
                });
            }
            if (id == 'PhysicalWorksMapImage') {
                this.props.setReportButtonVisible(true);
            }
            if (id == 'PropertyMapImage') {
                this.props.setPropertyMapReportButtonVisible(true);    
            }
        } catch (error) {
            console.error('Error fetching map image:', error);
        }
    };

    getFarmCentre = async () => {
        const outlineList = this.props.data.outlineList;
        const mapConfigDetails = this.props.data.mapDetails
        this.state.zoomlevel = mapConfigDetails != null ? mapConfigDetails.zoomLevel : 5;
        /* Farm center*/
        if (outlineList.length > 0) {
            let OutlineTypeLength = outlineList.filter(type => type.mapType == 'marker').length;
            if (OutlineTypeLength > 0) {
                outlineList.filter(type => type.mapType == 'marker').map((item) => {
                    this.state.center = { lat: parseFloat(item.coordinates.split(',')[0]), lng: parseFloat(item.coordinates.split(',')[1]) }
                })
            }
            else {
                OutlineTypeLength = outlineList.filter(type => type.mapType == 'polygon').length;
                if (OutlineTypeLength > 0) {
                    let Polylist = outlineList.filter(type => type.mapType == 'polygon');
                    const Coord = Polylist[0].coordinates.split(':')[0]
                    this.state.center = { lat: parseFloat(Coord.split(',')[0]), lng: parseFloat(Coord.split(',')[1]) }
                }
            }

        }
    }

    static encodeShapeCoordinates(coordinates) {
        return polyline.encode(coordinates);
    }

    static findPolygonCenterPoint = (CoordinatesList) => {
        if (CoordinatesList.length === 0) {
            return null;
        }
        const vertices = staticMap.splitpolygonCoor(CoordinatesList);
        const sumX = vertices.reduce((acc, vertex) => acc + vertex[0], 0);
        const sumY = vertices.reduce((acc, vertex) => acc + vertex[1], 0);

        const centerX = parseFloat((sumX / vertices.length).toFixed(6));
        const centerY = parseFloat((sumY / vertices.length).toFixed(6));

        return [centerX, centerY];
    };

    static splitpolygonCoor = (Coordinates) => {
        if (Coordinates.length === 0) {
            return null;
        }

        let CoordinatesList = [];
        const CoorList = Coordinates.split('|');

        CoorList.filter(coor => coor != "").map((point) => {
            let pos = point.split(',').map(i => Number(i));
            CoordinatesList.push(pos);
        })
        return CoordinatesList;
    };

    static renderOutline(outlineList, Model) {
        return (
            outlineList.filter(type => type.mapType == "polygon").map(FarmOutline => {
                const Coordinates = FarmOutline.coordinates.split(':');
                let positions = "";
                let pos1 = "";

                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    let pos = parseFloat(Coordinate.split(',')[0]) + "," + parseFloat(Coordinate.split(',')[1]);
                    if (positions != "") {
                        positions = positions + "|" + pos;
                    }
                    else {
                        positions = pos;
                        pos1 = pos;
                    }
                })
                positions = positions + "|" + pos1;

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedPolygon = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = 'ff0000'
                Model.farmOutline = Model.farmOutline != '' ? Model.farmOutline + `&shape=border:${borderColor}|cmp|enc:${encodedPolygon}` : `border:${borderColor}|cmp|enc:${encodedPolygon}`;

            })
        );
    }

    static renderFarmCenter(outlineList, Model) {
        return (
            (outlineList.filter(type => type.mapType == "marker").map((FarmCenter) => {
                let farmCenter = parseFloat(FarmCenter.coordinates.split(',')[0]) + "," + parseFloat(FarmCenter.coordinates.split(',')[1]) + "|marker-FFCE18"//-" + FarmCenter.mapLabel;
                Model.farmCenter = farmCenter;
            }
            ))
        );
    }

    static renderItemPolygonList(itemList, Model, isLU) {

        var PolyList = itemList.filter(type => type.mapMarkerType == "polygon").length;

        return (
            itemList.filter(type => type.mapMarkerType == "polygon").map(item => {
                const Coordinates = item.mapCoordinates.split(':');
                let positions = "";
                let pos1 = "";
                const positionsArry = [];

                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    let pos = parseFloat(Coordinate.split(',')[0]) + "," + parseFloat(Coordinate.split(',')[1]);
                    positionsArry.push(pos);
                    if (positions != "") {
                        positions = positions + "|" + pos;
                    }
                    else {
                        positions = pos;
                        pos1 = pos;
                    }
                })
                positions = positions + "|" + pos1;

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedPolygon = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                let fillColor = borderColor.length == 8 ? borderColor.slice(0, -2) + '40' : borderColor.length == 6 ? borderColor + '40' : borderColor;
                Model.itemPolygonString = Model.itemPolygonString != '' ? Model.itemPolygonString + `&shape=fill:${fillColor}|border:${borderColor}|cmp|enc:${encodedPolygon}` : `fill:${fillColor}|border:${borderColor}|cmp|enc:${encodedPolygon}`;
                if (isLU) {
                    if (!item.isConnectedToAnotherLMU) {
                        const centrePoint = staticMap.findPolygonCenterPoint(positions);
                        let itemPolygonMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]) + "|marker-FFCE18-" + item.mapLabel;
                        Model.itemPolygonMarkerString = Model.itemPolygonMarkerString != '' ? Model.itemPolygonMarkerString + "||" + itemPolygonMarker : itemPolygonMarker;
                    }
                }
                else {
                    const centrePoint = staticMap.findPolygonCenterPoint(positions);
                    let itemPolygonMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]) + "|marker-FFCE18-" + item.mapLabel;
                    Model.itemPolygonMarkerString = Model.itemPolygonMarkerString != '' ? Model.itemPolygonMarkerString + "||" + itemPolygonMarker : itemPolygonMarker;
                }

            })
        );
    }

    static renderItemPolylineList(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "polyline").map(item => {
                const Coordinates = item.mapCoordinates.split(':');
                let positions = "";
                let pos1 = "";
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    let pos = parseFloat(Coordinate.split(',')[0]) + "," + parseFloat(Coordinate.split(',')[1]);
                    if (positions != "") {
                        positions = positions + "|" + pos;
                    }
                    else {
                        positions = pos;
                        pos1 = pos;
                    }
                })

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedPolyline = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                Model.itemPolylineString = Model.itemPolylineString != '' ? Model.itemPolylineString + `&shape=border:${borderColor}|cmp|enc:${encodedPolyline}` : `border:${borderColor}|cmp|enc:${encodedPolyline}`;

                let itemPolylineMarker = pos1 + "|marker-FFCE18-" + item.mapLabel;
                Model.itemPolylineMarkerString = Model.itemPolylineMarkerString != '' ? Model.itemPolylineMarkerString + "||" + itemPolylineMarker : itemPolylineMarker;

            })
        );
    }

    static renderItemMarker(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "marker").map((item) => {
                const itemColor = item.colour.replace('#', '');
                let itemMarker = parseFloat(item.mapCoordinates.split(',')[0]) + "," + parseFloat(item.mapCoordinates.split(',')[1]) + "|marker-FFCE18-" + item.mapLabel;
                Model.itemMarkerString = Model.itemMarkerString != '' ? Model.itemMarkerString + "||" + itemMarker : itemMarker;
            }
            )
        );
    }

    static renderItemRectangleList(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "rectangle").map(item => {

                let positions = "";

                const northEastCO = item.mapCoordinates.split(':')[0];
                const southWestCo = item.mapCoordinates.split(':')[1];

                const co1 = parseFloat(northEastCO.split(',')[0]) + "," + parseFloat(northEastCO.split(',')[1]);
                const co2 = parseFloat(southWestCo.split(',')[0]) + "," + parseFloat(northEastCO.split(',')[1]);
                const co3 = parseFloat(northEastCO.split(',')[0]) + "," + parseFloat(southWestCo.split(',')[1]);
                const co4 = parseFloat(southWestCo.split(',')[0]) + "," + parseFloat(southWestCo.split(',')[1]);

                positions = co1 + "|" + co2 + "|" + co4 + "|" + co3 + "|" + co1;

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedRectangle = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                Model.itemRectangleString = Model.itemRectangleString != '' ? Model.itemRectangleString + `&shape=border:${borderColor}|cmp|enc:${encodedRectangle}` : `border:${borderColor}|cmp|enc:${encodedRectangle}`;

                const centrePoint = staticMap.findPolygonCenterPoint(positions);

                let itemRectangleMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]) + "|marker-FFCE18-" + item.mapLabel;
                Model.itemRectangleMarkerString = Model.itemRectangleMarkerString != '' ? Model.itemRectangleMarkerString + "||" + itemRectangleMarker : itemRectangleMarker;

            })
        );
    }

    static renderItemCircleList(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "circle").map(item => {

                const positions = parseFloat(item.mapCoordinates.split(',')[0]) + "," + parseFloat(item.mapCoordinates.split(',')[1]);
                const radius = parseFloat((item.radius / 1000));

                let borderColor = item.colour.replace('#', '');
                Model.itemCircleString = Model.itemCircleString != '' ? Model.itemCircleString + `radius:${radius}km|border:${borderColor}|${positions}` : `radius:${radius}km|border:${borderColor}|${positions}`;

                const centrePoint = staticMap.findPolygonCenterPoint(positions);

                let itemRectangleMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]) + "|marker-FFCE18-" + item.mapLabel;
                Model.itemCircleMarkerString = Model.itemCircleMarkerString != '' ? Model.itemCircleMarkerString + "||" + itemRectangleMarker : itemRectangleMarker;

            })
        );
    }
    static renderItemPolygonListDefaultMarker(itemList, Model, isLU) {

        var PolyList = itemList.filter(type => type.mapMarkerType == "polygon").length;

        return (
            itemList.filter(type => type.mapMarkerType == "polygon").map(item => {
                const Coordinates = item.mapCoordinates.split(':');
                let positions = "";
                let pos1 = "";
                const positionsArry = [];

                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    let pos = parseFloat(Coordinate.split(',')[0]) + "," + parseFloat(Coordinate.split(',')[1]);
                    positionsArry.push(pos);
                    if (positions != "") {
                        positions = positions + "|" + pos;
                    }
                    else {
                        positions = pos;
                        pos1 = pos;
                    }
                })
                positions = positions + "|" + pos1;

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedPolygon = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                let fillColor = borderColor.length == 8 ? borderColor.slice(0, -2) + '40' : borderColor.length == 6 ? borderColor + '40' : borderColor;
                Model.itemPolygonString = Model.itemPolygonString != '' ? Model.itemPolygonString + `&shape=fill:${fillColor}|border:${borderColor}|cmp|enc:${encodedPolygon}` : `fill:${fillColor}|border:${borderColor}|cmp|enc:${encodedPolygon}`;
                if (isLU) {
                    if (!item.isConnectedToAnotherLMU) {
                        const centrePoint = staticMap.findPolygonCenterPoint(positions);
                        let itemPolygonMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]);
                        Model.itemPolygonMarkerString = Model.itemPolygonMarkerString != '' ? Model.itemPolygonMarkerString + "||" + itemPolygonMarker : itemPolygonMarker;
                    }
                }
                else {
                    const centrePoint = staticMap.findPolygonCenterPoint(positions);
                    let itemPolygonMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]);
                    Model.itemPolygonMarkerString = Model.itemPolygonMarkerString != '' ? Model.itemPolygonMarkerString + "||" + itemPolygonMarker : itemPolygonMarker;
                }

            })
        );
    }

    static renderItemPolylineListDefaultMarker(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "polyline").map(item => {
                const Coordinates = item.mapCoordinates.split(':');
                let positions = "";
                let pos1 = "";
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    let pos = parseFloat(Coordinate.split(',')[0]) + "," + parseFloat(Coordinate.split(',')[1]);
                    if (positions != "") {
                        positions = positions + "|" + pos;
                    }
                    else {
                        positions = pos;
                        pos1 = pos;
                    }
                })

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedPolyline = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                Model.itemPolylineString = Model.itemPolylineString != '' ? Model.itemPolylineString + `&shape=border:${borderColor}|cmp|enc:${encodedPolyline}` : `border:${borderColor}|cmp|enc:${encodedPolyline}`;

                let itemPolylineMarker = pos1;
                Model.itemPolylineMarkerString = Model.itemPolylineMarkerString != '' ? Model.itemPolylineMarkerString + "||" + itemPolylineMarker : itemPolylineMarker;

            })
        );
    }

    static renderItemDefaultMarker(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "marker").map((item) => {
                const itemColor = item.colour.replace('#', '');
                let itemMarker = parseFloat(item.mapCoordinates.split(',')[0]) + "," + parseFloat(item.mapCoordinates.split(',')[1]);
                Model.itemMarkerString = Model.itemMarkerString != '' ? Model.itemMarkerString + "||" + itemMarker : itemMarker;
            }
            )
        );
    }

    static renderItemRectangleListDefaultMarker(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "rectangle").map(item => {

                let positions = "";

                const northEastCO = item.mapCoordinates.split(':')[0];
                const southWestCo = item.mapCoordinates.split(':')[1];

                const co1 = parseFloat(northEastCO.split(',')[0]) + "," + parseFloat(northEastCO.split(',')[1]);
                const co2 = parseFloat(southWestCo.split(',')[0]) + "," + parseFloat(northEastCO.split(',')[1]);
                const co3 = parseFloat(northEastCO.split(',')[0]) + "," + parseFloat(southWestCo.split(',')[1]);
                const co4 = parseFloat(southWestCo.split(',')[0]) + "," + parseFloat(southWestCo.split(',')[1]);

                positions = co1 + "|" + co2 + "|" + co4 + "|" + co3 + "|" + co1;

                const vertices = staticMap.splitpolygonCoor(positions);
                const encodedRectangle = staticMap.encodeShapeCoordinates(vertices);

                let borderColor = item.colour.replace('#', '');
                Model.itemRectangleString = Model.itemRectangleString != '' ? Model.itemRectangleString + `&shape=border:${borderColor}|cmp|enc:${encodedRectangle}` : `border:${borderColor}|cmp|enc:${encodedRectangle}`;

                const centrePoint = staticMap.findPolygonCenterPoint(positions);

                let itemRectangleMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]);
                Model.itemRectangleMarkerString = Model.itemRectangleMarkerString != '' ? Model.itemRectangleMarkerString + "||" + itemRectangleMarker : itemRectangleMarker;

            })
        );
    }

    static renderItemCircleListDefaultMarker(itemList, Model) {
        return (
            itemList.filter(type => type.mapMarkerType == "circle").map(item => {

                const positions = parseFloat(item.mapCoordinates.split(',')[0]) + "," + parseFloat(item.mapCoordinates.split(',')[1]);
                const radius = parseFloat((item.radius / 1000));

                let borderColor = item.colour.replace('#', '');
                Model.itemCircleString = Model.itemCircleString != '' ? Model.itemCircleString + `radius:${radius}km|border:${borderColor}|${positions}` : `radius:${radius}km|border:${borderColor}|${positions}`;

                const centrePoint = staticMap.findPolygonCenterPoint(positions);

                let itemRectangleMarker = parseFloat(centrePoint[0]) + "," + parseFloat(centrePoint[1]);
                Model.itemCircleMarkerString = Model.itemCircleMarkerString != '' ? Model.itemCircleMarkerString + "||" + itemRectangleMarker : itemRectangleMarker;

            })
        );
    }

    render() {
        const mapImage = this.state.mapImage;
        return (
            <div>
                <img id={this.state.id} src={mapImage} crossorigin="anonymous" alt="MapQuest Static Map" style={{ width: 1400, height: 800 }} />
            </div >
        );
    }

}
export default staticMap;