import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Modal, ModalHeader, CardTitle, CardText, Button, CardSubtitle, ModalBody, ModalFooter } from 'reactstrap';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import uuid from 'react-uuid';
import Moment from 'moment';
import "../ExternalOrganisation/ExternalOrganisation.css";
import ReadMoreContent from '../Utilities/Ui/ReadMore';
import { useNavigate } from "react-router-dom";

const News = () => {
    const [newsList, setNewsList] = useState([]); const [loading, setLoading] = useState(true); const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(0); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId")); const navigate = useNavigate();

    const populateItemData = async () => {
        try {
            const response = await axios.get(
                '/api/settings/getnews/', {
                params: {}
            }
            );
            setNewsList(response.data.content);
            setLoading(false);
        }
        catch (err) {
            if (!err?.response) {

            } else if (err.response?.status === 400) {

            } else {

            }
        }
    }

    useEffect(() => {
        populateItemData();
    }, []);

    const confirmDeleteItemPopup = async () => {
        const response = await axios.get(
            '/api/settings/DeleteNews/', {
            params: { Id: deleteItemId, userId: userId }
        }
        );
        setShowDeleteItemPopup(false);
        populateItemData();
    }

    const openDeleteItemPopup = async (item) => {
        setShowDeleteItemPopup(true);
        setDeleteItemId(item.id);
    }

    const closeDeleteItemPopup = async () => {
        setShowDeleteItemPopup(false);
    }

    const open = (id, routePath) => {
        navigate('/' + routePath, { state: { id: id } });
    }

    return (
        <div>

            <Card className="my-4">
                <h2 className="card-header d-flex justify-content-between align-items-center settings-card-header">
                    News
                    <button type="button" onClick={(id, routePath) => open(0, 'createnews')} id="btnUpdate" className="btn btn-sm plusIcon-small">
                        Add
                    </button>
                </h2>
                <CardBody>
                    {newsList.news != undefined && newsList.news != null && newsList.news.map(item => (
                        <Row key={uuid()}>
                            <Col key={uuid()} style={{ marginTop: '10px' }}>
                                <Card>
                                    <Row key={uuid()}>
                                        <Col sm="3" className="news-thumbnail-card">
                                            {item.newsItemThumbnailName ?
                                                <img alt="Card cap" src={item.newsItemThumbnailRelativePath} width="50%"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = "/images/no-image.jpg"
                                                    }
                                            } /> : <img alt="Card cap" src="/images/news-icon.png" width="50%" /> }
                                        </Col>
                                        <Col sm="9">
                                            <Row>
                                                <Col>
                                                    <CardBody><CardTitle tag="h5">
                                                        {item.newsItemName}</CardTitle>
                                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                                            Organisation : {item.organisationText} <br/>
                                                            <span className="news-editor-details"> Editor : {item.newsEditorName} on {Moment(item.newsItemDate).format('Do MMMM  YYYY')}</span>
                                                        </CardSubtitle>
                                                    </CardBody>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <CardBody>
                                                        <CardText>
                                                            <ReadMoreContent longText={item.newsItemDescription} />
                                                        </CardText>
                                                        <button type="button" onClick={(id, routePath) => open(item.id, 'editnews')}
                                                            id="btnUpdate"
                                                            color="warning"
                                                            className="btn btn-sm btn-edit button-normal">
                                                            <FaEdit />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button"
                                                            id="btnUpdate"
                                                            className="btn btn-sm btn-danger button-delete"
                                                            onClick={() => openDeleteItemPopup(item)}>
                                                            <FaTrash />
                                                        </button>&nbsp;&nbsp;
                                                        {item.newsItemAttachementName != null && item.newsItemAttachementName != "" &&
                                                            <a className="linkNews" href={item.newsItemAttachementRelativePath} target='_blank' >
                                                                Attachment : <u>{item.newsItemAttachementName}</u> (Click to open)
                                                            </a>
                                                        }
                                                    </CardBody>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                </CardBody>
            </Card>
            
            <Modal isOpen={showDeleteItemPopup}>
                <ModalHeader>Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteItemPopup}>Yes</Button>
                    <Button color="danger" onClick={closeDeleteItemPopup}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default News;