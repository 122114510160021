import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { Container } from 'reactstrap';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Menu  from './Layout/Menu';
import WizardMenu from './WizardMenu/WizardMenu';
import axios from 'axios';
import useAuth from "../components/Utilities/Hooks/useAuth";

const Layout = (wizardDetails, pageReload) => {
    const { auth, setAuth } = useAuth();
    const [showWizard, setShowWizard] = useState(true);
    useEffect(() => {
        validateToken();
    }, [])
    const validateToken = async () => {
        if (auth != null) {
            let form = {
                Token: auth.token
            }
            const response = await axios.post(
                '/api/Token/Validate/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (!response.data) {
                window.open("/login", "_self");
            }
        }
        else {
            window.open("/login", "_self");
        }
    }
    const handleSetShowWizard = async () => {
        setShowWizard(!showWizard);
    }
    return (
        <main>
            <Header />
            <Menu handleSetShowWizard={handleSetShowWizard} />
            <div style={{ height: "82vh", overflow: "auto", marginBottom: "1vh" }}>
                {showWizard && <WizardMenu wizardDetails={wizardDetails} pageReload={pageReload} />}
                <Container className="layout-body">
                    <Outlet />
                </Container>
            </div>
            <div className="footer" style={{ height:"5vh" }}>
                &#169; 2023 Powered by QCONZ
            </div>
        </main>
    );
}

export default Layout