import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Table, Collapse, Button, Modal, Input, ModalHeader, ModalBody, ModalFooter, Label, Spinner } from 'reactstrap';
import './MyFarm.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import { FaChevronUp, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import uuid from 'react-uuid';
import MapView from '../Map/MapView';
import { useNavigate } from "react-router-dom";
import Documents from '../Uploads/Documents';
import { flushSync } from "react-dom";
import Draggable, { DraggableCore } from 'react-draggable';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const MyFarm = ({ quickDetail, reportButtonVisible, generatePropertyMapReport, exportPropertyMapToPdf, propertyMapReportButtonVisible }) => {
    const [showRiskItems, setShowRiskItems] = useState(false); const { height, width } = useWindowDimensions();
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [biodiversity, setBiodiversity] = useState({ outlineList: [], itemList: [], SectionName: 'Biodiversity', Section: 'Biodiversity', isWizardCompleted: false, sectionObj: {} });
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false, isFarmOutlineEditable: false, zoomLevel: 15,
        polytype: '', itemsEditable: false, highlighteditemId: 0, showLabel: false
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0 })
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const navigate = useNavigate();
    const [sectionList, setSectionList] = useState([]);
    const [tempItemList, setTempItemList] = useState([]);
    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/summary/GetMyFarm/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setBiodiversity({
                ...biodiversity, outlineList: response.data.outlineList, itemList: response.data.riskItemList, isWizardCompleted: response.data.isFWFPCompleted, sectionObj: response.data.section
            });
            setItemList(response.data.riskItemList);
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise, LMUList: response.data.lmuItemList })
            setSectionList(response.data.myFarmSections);
            setTempItemList(response.data.riskItemList);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={biodiversity}
                mapDetails={mapDetails}
                largeEnterpriseDetails={largeEnterpriseDetails}
                width={'100%'}>
            </MapView>
        )
    }, [biodiversity, mapDetails, largeEnterpriseDetails])

    const mapView1 = useMemo(() => {
        return (
            <MapView
                itemModel={biodiversity}
                mapDetails={mapDetails}
                largeEnterpriseDetails={largeEnterpriseDetails}
                width={'100%'} height={ "80vh"}>
            </MapView>
        )
    }, [biodiversity, mapDetails, largeEnterpriseDetails])

    const handleItemView = (itemId, routePath) => {
        navigate('/' + routePath, { state: { itemId: itemId, navigateFrom: 'Biodiversity' } });
    }

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const showHideRiskItems = async (e) => {
        setShowRiskItems(e.checked);
        hideItems();
    }

    const showHideRiskMapItem = async (e, item1) => {
        let tempList = sectionList;
        tempList.map((sectionList) => {
            if (sectionList.name === item1.name) {
                sectionList.checked = e.target.checked;
            }
            return sectionList;
        });

        flushSync(() => {
            setSectionList(prevSectionList => {
                const newSectionList = [...prevSectionList];
                return newSectionList;
            });
        });
        hideItems();
    }

    function hideItems() {
        let tempList = sectionList;
        let tempItems = [];
        tempList.forEach((value, key) => {
            if (value.checked) {
                tempItemList.filter(x => x.sectionName == value.displayName).forEach((value1, key1) => {
                    tempItems.push(value1);
                });
            }
        });
        
        if (document.getElementById('showRiskItems').checked) {
            let tempFilteredItems1 = [];
            tempItems.filter(x => x.itemActions.riskResult == "R").forEach((value1, key1) => {
                tempFilteredItems1.push(value1);
            });
            setItemList(tempFilteredItems1);
            setBiodiversity({ ...biodiversity, itemList: tempFilteredItems1 });
        }
        else {
            setItemList(tempItems);
            setBiodiversity({ ...biodiversity, itemList: tempItems });
        }
        
    }

    const [mapViewPopup, setMapViewPopup] = useState({ showMapViewPopup: false });
    const handleMapViewPopupClose = (event) => {
        setMapViewPopup({ showMapViewPopup: false});
    }
    const showFullMap = () => {
        setMapViewPopup({ showMapViewPopup: true });
    }
    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Card className="my-4 ">
                <CardHeader className="item-header item_click" onClick={toggle}>
                    <Row>
                        <Col sm="11"><h5>My Property
                        </h5> 
                        </Col>
                        <Col sm="1" className="toggleoutline" style={{marginLeft:"auto",marginRight:"20px"}}>{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        {
                            loading ? <SpinnerContainer /> :
                                <div>
                                    <Row>
                                        {sectionList != undefined && sectionList != null && sectionList.map(item => (
                                            <Col key={uuid()} sm="4">
                                                <input type="checkbox" className="riskmapCheck" id={"chk_" + item.name} name={"chk_" + item.name} checked={item.checked}
                                                    onChange={(e) => showHideRiskMapItem(e, item)} />{" "}
                                                {item.displayName}
                                            </Col>
                                        ))}
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col sm="4" >
                                        </Col>
                                        <Col sm="4" style={{ display: "none" }}>
                                            <input type="checkbox" className="riskmapCheck" id="showRiskItems" name="showRiskItems" checked={showRiskItems}
                                                onChange={(e) => showHideRiskItems(e)} />{" "}
                                            Show Risk Items Only
                                        </Col>
                                        <Col>
                                            <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                                <thead style={{ background: 'rgb(182, 209, 55)', textAlign: 'center' }}>
                                                    <tr>
                                                        <th style={{ width: '33%' }}> Total Area (ha) </th>
                                                        <th style={{ width: '33%' }}> Irrigated Area (ha) </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr key={1}>
                                                        <td> <Input style={{ textAlign: 'center' }}type="text" disabled={true} value={quickDetail.totalArea} name="totalArea" id="totalArea" autoComplete="new-password" /> </td>
                                                        <td> <Input style={{ textAlign: 'center' }}type="text" disabled={true} value={quickDetail.irrigatedArea} name="farmName" id="farmName" autoComplete="new-password" /> </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="8">
                                        </Col>
                                        <Col sm="2">
                                            {propertyMapReportButtonVisible &&
                                                <>
                                                    {generatePropertyMapReport ?
                                                        <Button style={{ float: `right` }} id="btnSave" onClick={exportPropertyMapToPdf}
                                                            color="primary"
                                                            disabled
                                                        >
                                                            <Row>
                                                                <Col sm="9">
                                                                    <span>
                                                                        {' '}Generating
                                                                    </span>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <Spinner size="sm" style={{ marginRight: "5px" }}>
                                                                        Generating...
                                                                    </Spinner>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                        :
                                                        <Button style={{ float: `right` }} id="btnSave" onClick={exportPropertyMapToPdf} color="primary">
                                                            Print Map&nbsp;&nbsp;&nbsp;<img src="/images/PDF_icon.png" className="card_icon" ></img>
                                                        </Button>
                                                    }
                                                </>
                                            }

                                        </Col>
                                        <Col sm="2">
                                            <Button color="primary" onClick={showFullMap} style={{ float: "right" }}>Show Full Size Map</Button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px' }}>
                                        <Col id='mapView'>

                                            <Row className="map-layer">
                                                <Col>
                                                    <Row>
                                                        <Col sm="8"></Col>
                                                        <Col sm="4">
                                                            <div className="map-item-edit-div">
                                                                <Label className="map-checkbox-label">Show Label</Label>
                                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {mapView}
                                                </Col>
                                            </Row>
                                        
                                        </Col>
                                    </Row>
                                </div>
                        }
                    </CardBody>
                </Collapse>
            </Card>
            {/*<Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>*/}
                
            {/*</Draggable>*/}
            <Modal dialogClassName="modal-height" style={{ maxWidth: '90vw', width: '100%' }} isOpen={mapViewPopup.showMapViewPopup} size="xl">
                <ModalHeader toggle={handleMapViewPopupClose} ></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col id='mapView'>
                            {mapView1}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleMapViewPopupClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default MyFarm;