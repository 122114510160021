import { useState, useEffect } from 'react';
import { flushSync } from "react-dom";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormGroup} from 'reactstrap';
import axios from 'axios';
import uuid from 'react-uuid';
import '../Ghg/Ghg.css';

const MaintenancePlan = ({ actionId, itemId, itemName, itemTypeId, itemTypeSelect, showMaintenancePlanPopupModal, sectionName, redirectPage, maintenancePlanId, handleMaintenancePlanClose }) => {
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [formErrors, setFormErrors] = useState({}); 
    const [maintenancePlanPopup, setMaintenancePlan] = useState({
        id: 0, actionId: actionId, itemId: itemId, title: '', schedule: '', details:''
    });
    const [maintenancePlanDeletePopup, setmaintenancePlanDeletePopup] = useState({ showmaintenancePlanDeletePopup: false, id: 0 });
    const OnPageload = async (e) => {

        setLoading(true);
        if (maintenancePlanId != null && maintenancePlanId != undefined && maintenancePlanId != 0) {

            try {
                const response = await axios.get(
                    '/api/action/GetMaintenancePlanById/', {
                        params: { Id: maintenancePlanId }
                }
                );

                if (response.data.status) {
                    const maintenancePlan = response.data.content;
                    setMaintenancePlan({ ...maintenancePlanPopup, id: maintenancePlan.id, title: maintenancePlan.title, schedule: maintenancePlan.schedule, details: maintenancePlan.details });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.log(err);
            }
        }
        else {
            setMaintenancePlan({ ...maintenancePlanPopup, id: 0, title: '', schedule: '', details: '' });
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    function handleInputChange(event) {
        const { name, value, checked, type } = event.target;
        setMaintenancePlan({ ...maintenancePlanPopup, [name]: type == 'checkbox' ? checked : value });
    }

    const handleMaintenancePlanSave = async (event) => {
        event.preventDefault();
        setFormErrors({});
        let form = {
            Id: maintenancePlanPopup.id, ActionId: maintenancePlanPopup.actionId, ItemId: maintenancePlanPopup.itemId,
            Title: maintenancePlanPopup.title, Schedule: maintenancePlanPopup.schedule, Details: maintenancePlanPopup.details, userId: userId, FarmId: farmId
        }
        const errors = {};
        if (!form.Title) {
            errors.title = "Please enter title";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            const response = await axios.post(
                '/api/action/SaveMaintenancePlan/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            if (response.data != null) {
                if (response.data.status) {
                    window.open(redirectPage, "_self");
                }
            }
        }
    }

    const handleMaintenancePlanDelete = (maintenancePlanId) => {
        setmaintenancePlanDeletePopup({ ...maintenancePlanDeletePopup, showmaintenancePlanDeletePopup: true, id: maintenancePlanId })
    }

    const handleMaintenancePlanDeletePopupClose = (maintenancePlanId) => {
        setmaintenancePlanDeletePopup({ ...maintenancePlanDeletePopup, showmaintenancePlanDeletePopup: false, id: 0 })
    }

    const deleteMaintenancePlanDelete = async (event) => {
        event.preventDefault();
        const id = maintenancePlanDeletePopup.id;
        try {
            const response = await axios.get(
                '/api/action/DeleteMaintenancePlan/', {
                params: {
                        id: id, userId: userId
                }
            }
            );
            window.location.reload(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
       
        <div>
            {
                !loading &&
                <Modal size="lg" style={{ maxWidth: '900px', width: '100%' }} isOpen={showMaintenancePlanPopupModal}>
                    <ModalHeader toggle={handleMaintenancePlanClose}>Add Maintenance Plan</ModalHeader>
                    <ModalBody>
                        <Row xs="4">
                            <Col>
                                <FormGroup>
                                    <Label>Page</Label>
                                    <Input type="text" name="txtPage" id="txtPage" value={sectionName} readOnly disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Type</Label>
                                    {itemTypeSelect == undefined && <p><em>Loading...</em></p>}
                                    {itemTypeSelect != undefined && itemTypeSelect != null &&
                                        <Input disabled={true} type="select" value={itemTypeId} onChange={handleInputChange} name="itemTypeId" id="itemTypeId">
                                            {itemTypeSelect.map(items =>
                                                <option key={uuid()} value={items.id}>{items.name}</option>
                                            )}
                                        </Input>
                                    }
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text" name="txtName" id="txtName" value={itemName} readOnly disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Id</Label>
                                    <Input type="text" name="txtPage" id="txtPage" value={maintenancePlanPopup.id} readOnly disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input type="text" name="title" id="title" value={maintenancePlanPopup.title} onChange={handleInputChange} />
                                </FormGroup>
                                {formErrors.title != undefined &&
                                    <p className="errorMessage">{formErrors.title}</p>
                                }
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label>Schedule</Label>
                                    <Input type="text" name="schedule" id="schedule" value={maintenancePlanPopup.schedule} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Details</Label>
                                    <Input type="textarea" name="details" id="details" value={maintenancePlanPopup.details} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleMaintenancePlanClose} color="warning" >Cancel</Button>
                                <Button style={{ float: `right`, marginLeft: '10px' }} onClick={handleMaintenancePlanSave} color="success" >Save</Button>
                                {maintenancePlanPopup.id > 0 &&
                                        < Button style={{ float: `right`, marginLeft: '10px' }} onClick={(maintenancePlanId) => handleMaintenancePlanDelete(maintenancePlanPopup.id)} color="danger" >Delete</Button>
                                }
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            }

            <Modal isOpen={maintenancePlanDeletePopup.showmaintenancePlanDeletePopup}>
                <ModalHeader toggle={handleMaintenancePlanDeletePopupClose} >Delete the item</ModalHeader>
                    <ModalBody>
                        Do you want to delete the item ?
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={deleteMaintenancePlanDelete}>Yes</Button>
                    <Button color="danger" onClick={handleMaintenancePlanDeletePopupClose}>No</Button>
                    </ModalFooter>
                </Modal>
        </div>
    );

}

export default MaintenancePlan;
