import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input, Label, Spinner } from 'reactstrap';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import './LandSoil.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import ItemCommonTop from '../ItemPopup/ItemCommonTop';
import ItemCommonBottom from '../ItemPopup/ItemCommonBottom';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import ItemFooter from '../ItemPopup/ItemFooter';
import Action from '../Action/Action';
import MapView from '../Map/MapView';
import NonMapView from '../ItemsView/NonMapView';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';
import LandSoilItemSpecView from './LandSoilItemSpecView';
import Validate from '../Validate/Validate';
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import { useNavigate, useLocation } from "react-router-dom";
import RiskSummary from '../ItemsView/RiskSummary';
import moment from "moment";
import ItemTypeListViewForMap from '../ItemsView/ItemTypeListViewForMap';
import Draggable, { DraggableCore } from 'react-draggable';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const LandSoil = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName"); const { height, width } = useWindowDimensions(); const [isThisPageCompleted, setIsThisPageCompleted] = useState(false); const [actionsCount, setActionsCount] = useState(0);
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [searchParams, setSearchParams] = useSearchParams(); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const { state } = useLocation(); const [saving, setSaving] = useState(''); const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const navigate = useNavigate(); const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [loading, setLoading] = useState(true);
    const [landsoil, setLandSoil] = useState({
        outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], Section: 'Land and Erosion', SectionName: 'Land and Erosion',
        itemFieldTypes: [], blockInETSList: [], treeSpeciesList: [], forestryTypeList: [], drainageTypeList: [], SectionObj: {}, isWizardCompleted: false,
        outlineCenter: {}, farmCenterTypeModel: {}, outlineItemTypes: []
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0, isSmallEnterpriseShow: false })
    const [typeid, setTypeId] = useState(0);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 15, polyType: '', itemsEditable: false, highlighteditemId: 0, isLandsoilPage: true, isClear: false, showLabel: false
    });
    const [riskLookupList, setRiskLookupList] = useState({ enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
    const [itemPopup, setItemPopup] = useState({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
    const [itemPopupHeader, setItemPopupHeader] = useState({
        page: 'Land and Erosion', lmu: '', LMUId: 0, itemTypeName: '',
        itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", isEdited: false, itemTypeDescription: '', LMUIds: ''
    });
    const [itemPopupMaptype, setItemPopupMaptype] = useState({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', radius: 0.0 });

    const [itemPopupBiodiversity, setItemPopupBiodiversity] = useState({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
    const [itemPopupRisk, setItemPopupRisk] = useState({
        isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '',
        showEnterprise: false
    });
    const [itemPopupAchievements, setItemPopupAchievements] = useState({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });

    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0 });
    const [actionPopup, setActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [wholeFarmActionPopup, setWholeFarmActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });

    const [itemPopupItemField, setItemPopupItemField] = useState({
        itemTypeId: 0,
        itemFieldTypes: []
    });
    const [itemPopupItemSepcQ, setItemPopupItemSepcQ] = useState({
        DetentionDam: '',
        DebrisDam: '',
        DropFlume: '',
        Dam: '',
        Age: 0,
        PlantedDate: '',
        TreeSpecies: 0,
        IsBeenHarvestedLast10Years: false,
        ForestryType: 0,
        BlockInETS: 0,
        StemCountPerHA: 0,
        DrainageType: 0, IsCSAWithinIntensiveWinterGrazingArea: false,
        Plantings: '',
        Covenentad :false,
        StripDate: '',
        OptimalRange: '',
        OlsenpValueRange: '',
    });

    const [toggleItemTypeListState, setToggleItemTypeListState] = useState({ isOpen: false, isIconUp: false });
    const [toggleItemTypeState, setToggleItemTypeState] = useState({ itemTypeId: 0, isOpen: false, isIconUp: false });
    const [toggleItemState, setToggleItemState] = useState({ itemId: 0, isOpen: false, isIconUp: false });
    const [riskSummaryPopup, setRiskSummaryPopup] = useState({ showriskSummary: false });
    const [gisLayerTopographyDetails, setGISLayerTopographyDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [gisLayerSoilDetails, setGISLayerSoilDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [gisLayerWetlandDetails, setGISLayerWetlandDetails] = useState({ showGISLayer: false, gisLayerList: [] })

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/landsoil/GetLandSoil/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setLandSoil({
                ...landsoil, outlineList: response.data.outlineList, itemList: response.data.itemList, itemTypes: response.data.itemTypes, uniqueItemTypes: response.data.uniqueItemTypes, itemFieldTypes: response.data.itemFieldList
                , blockInETSList: response.data.blockInETSList, treeSpeciesList: response.data.treeSpeciesList,
                forestryTypeList: response.data.forestryTypeList, drainageTypeList: response.data.drainageTypeList, SectionObj: response.data.section, isWizardCompleted: response.data.isFWFPCompleted, outlineCenter: response.data.outlineCenter, farmCenterTypeModel: response.data.farmCenterTypeModel
                , outlineItemTypes: response.data.outlineItemTypes
            });
            setRiskLookupList({
                ...riskLookupList, enterpriseTypes: response.data.riskModel.enterpriseTypes, luenterpriseTypes: [], contaminants: response.data.riskModel.contaminants, riskDataGroups: response.data.riskModel.riskDataGroups
                , activityGroups: response.data.riskModel.activityGroups, activitySubGroups: [], tempActivitySubGroups: response.data.riskModel.activitySubGroups, LUEnterpriseTypes: response.data.riskModel.luEnterpriseTypeList
            })
            setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: !response.data.isFWFPCompleted, isIconUp: !response.data.isFWFPCompleted })
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel, polyType: '' });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise, LMUList: response.data.lmuItemList })
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            if (response.data.wizard.isLandSoilSaved) {
                setIsThisPageCompleted(true);
            }
            else {
                setIsThisPageCompleted(false);
            }
            setActionsCount(response.data.actionCount);
            setLoading(false);
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, gisLayerList: response.data.gisLayerList_TopographyMap });
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, gisLayerList: response.data.gisLayerList_SoilMap });
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, gisLayerList: response.data.gisLayerList_WetlandMap });

            if (state != null) {
                const { itemId, actionId, navigateFrom } = state;
                navigate(state.pathname, { replace: true });
                if (navigateFrom == "Actions") {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
                else {
                    editMapItemFromNavigation(itemId, response.data, navigateFrom)
                }
            }
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let navigateFrom = params.get('navigateFrom');
            if (navigateFrom == 'Actions') {
                let actionId = params.get('actionId');
                let itemId = params.get('itemId');
                let isWholeFarmAction = params.get('isWholeFarmAction');
                if (searchParams.has('actionId')) {
                    searchParams.delete('actionId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('itemId')) {
                    searchParams.delete('itemId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('isWholeFarmAction')) {
                    searchParams.delete('isWholeFarmAction');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('navigateFrom')) {
                    searchParams.delete('navigateFrom');
                    setSearchParams(searchParams);
                }
                if (isWholeFarmAction == 'true') {
                    handleNavigateOpenWholeActionPopup(itemId, actionId, response.data);
                }
                else {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, landsoil) => {
        const itemDetails = landsoil.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleNavigateOpenWholeActionPopup = (itemId, actionId, landsoil) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: landsoil.outlineCenter.itemTypeId, itemName: 'Orchard Centre', actionId: actionId });
    };

    function refreshPage() {
        setLoading(true);
        setRiskLookupList({ ...riskLookupList, enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
        setFormErrors({});
        setLandSoil({ ...landsoil, outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [] });
        OnPageload();
    }

    function resetValues() {
        setItemPopup({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
        setItemPopupHeader({ page: 'Land and Erosion', lmu: '', itemTypeName: '', itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", isEdited: false, itemTypeDescription: '', LMUIds: '' });
        setItemPopupMaptype({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', radius: 0.0 });
        setItemPopupBiodiversity({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
        setItemPopupRisk({
            isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: ''
        });
        setItemPopupAchievements({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });
        setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
        setFormErrors({});
        setMapDetails({ ...mapDetails, isClear: true, polyType: '' });
    }

    /* Item select  and Clear*/
    const handleItemClick = (event, typeid, polyType) => {
        setTypeId(typeid);
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
        const itemFieldTypesList = landsoil.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == typeid);
        setItemPopupItemField({ ...itemPopupItemField, itemTypeId: typeid, itemFieldTypes: itemFieldTypesList });
    }

    function handleClearSelection() {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
        setItemPopupItemField({ ...itemPopupItemField, itemTypeId: 0, itemFieldTypes: [] });
    }

    const handleLMUChange = (event) => {
        var lus = '';
        let luArr = [];
        if (typeof event === 'object' && event.constructor === Object) {
            lus = event.value.toString();
        }
        else {
            event.forEach(lmu => {
                luArr.push(lmu.value);
            })
            lus = luArr.join(",");
        }
        setItemPopupHeader({ ...itemPopupHeader, LMUIds: lus });
    }

    /*ItemPopup value change Handle*/
    function handleItemPopupHeaderChange(event) {
        const { name, value, checked, type } = event.target;
        setFormErrors({});
        setItemPopupHeader({ ...itemPopupHeader, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupBiodiversity(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupRisk(event) {
        const { name, value, checked, type } = event.target;
        if (name == 'isRisk' && value == 'Yes') {
            setFormErrors({});
            const errors = {};
            if (itemPopupHeader.LMUId == 0) {
                errors.luid = "Please select LU";
                setFormErrors(errors);
            }
            else {
                setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else if (name == 'isRisk' && value == 'No') {
            setItemPopupRisk({ ...itemPopupRisk, isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '' });
        }
        else {
            setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
        }
    }
    const handleMultiSelectEnterpriseChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, enterprises: e });
    }

    const handleMultiSelectContaminantChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, contaminants: e });
    }

    const handleMultiSelectRiskDataGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, riskDataGroups: e });
    }

    const handleMultiSelectActivityGroupChange = (e) => {

        setRiskLookupList({
            ...riskLookupList, activitySubGroups: []
        })

        var selectedActivitySubGroups = [];
        let myArrayFiltered = [];
        if (itemPopupRisk.activitySubGroups != undefined && itemPopupRisk.activitySubGroups.length > 0) {
            selectedActivitySubGroups = itemPopupRisk.activitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        else {
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        setRiskLookupList({
            ...riskLookupList, activitySubGroups: myArrayFiltered
        })
        setItemPopupRisk({ ...itemPopupRisk, activityGroups: e, activitySubGroups: selectedActivitySubGroups });
    }

    const handleMultiSelectActivitySubGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, activitySubGroups: e });
    }
    function handleItemPopupAchievements(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupAchievements({ ...itemPopupAchievements, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemSepcValueChange = (event) => {
        const { name, value, checked, type } = event.target;
        setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemDatePlantedDate = (date) => {
        setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, PlantedDate: date });
    }
    const handleItemDateStripDate = (date) => {
        setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, StripDate: date });
    }

    
    const handleLMUSelectChange = (event) => {
        const { name, value, checked, type } = event.target;
        setLargeEnterpriseDetails({ ...largeEnterpriseDetails, [name]: type == 'checkbox' ? checked : value });
    }

    /*Item Add and Submit*/
    function addMapItem(mapItem, maptype) {
        let path, area = 0.0, perimeter = 0.0, coordinates = '', radius = 0.0;
        var selectedItemType = landsoil.itemTypes.filter(item => item.id == typeid)[0];
        const ItemTypeName = selectedItemType.name;
        const isBiodiversityReq = selectedItemType.isBiodiversityReq == true ? true : false;
        let isItemToBiodiversity = false;
        if (selectedItemType.name == 'Indigenous Freshwater Biodiversity or Habitat area')
        {
            isItemToBiodiversity = true;
        }
        let lmuId = largeEnterpriseDetails.selectedLMUId;
        const defaultColour = selectedItemType.defaultColour != null && selectedItemType.defaultColour != '' ? selectedItemType.defaultColour : "#21b546";

        /***********LMU Identification*************/
        if (maptype == 'marker') {
            largeEnterpriseDetails.LMUList.map((obj) => {
                const outlineCoordinates = obj.mapCoordinates.split(':');
                const outlinepositions = [];
                outlineCoordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    outlinepositions.push(pos);
                })
                const outlinePolygon = new window.google.maps.Polygon({
                    paths: outlinepositions
                });
                const pos = { lat: mapItem.getPosition().lat(), lng: mapItem.getPosition().lng() }

                const inside = window.google.maps.geometry.poly.containsLocation(
                    pos,
                    outlinePolygon
                ) ? true : false;
                if (inside) {
                    lmuId = obj.id;
                }
            });
        }
        /***********LMU Identification*************/

        if (maptype == 'marker') {

            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);
        }
        else if (maptype == 'polygon') {
            path = mapItem.getPath();
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'polyline') {
            path = mapItem.getPath();
            area = 0;
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'circle') {
            coordinates = mapItem.getCenter().lat().toFixed(6) + ", " + mapItem.getCenter().lng().toFixed(6);
            radius = mapItem.radius.toFixed(2);
            const Pi = 3.14;
            area = ((radius * radius * Pi) / 10000).toFixed(1);
            perimeter = ((2 * Pi * radius) / 1000).toFixed(1);
        }
        let selectedLUEnterpriseTypes = [];
        if (lmuId != 0) {
            var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == lmuId);
            if (LUEnterpriseTypes.length != 0) {
                let LUEnterpriseTypesArray = [];

                LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                    return LUEnterpriseTypesArray.includes(String(el.value))
                });
                if (selectedLUEnterpriseTypes.length == 0) {
                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return el.label == 'N/A'
                    });
                }
            }
        }

        setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes })

        setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: 0, itemId: 0, markertype: maptype, canArchive: true });
        setItemPopupMaptype({ ...itemPopupMaptype, markertype: maptype, area: area, perimeter: perimeter, coordinates: coordinates, radius: radius });
        setItemPopupHeader({ ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: typeid, LMUId: lmuId, LMUIds: lmuId.toString(), Name: '', isDragged: false, Description: '', color: defaultColour, isEdited: false, itemTypeDescription: selectedItemType.itemDescription });
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: isItemToBiodiversity });
        setItemPopupAchievements({ ...itemPopupAchievements, isExpand: false });
        setItemPopupItemSepcQ({
            ...itemPopupItemSepcQ, DetentionDam: '', DebrisDam: '', DropFlume: '', Dam: '', Age: 0, PlantedDate: '', TreeSpecies: 0, IsBeenHarvestedLast10Years: false, ForestryType: 0, BlockInETS: 0, StemCountPerHA: 0,
            DrainageType: 0, IsCSAWithinIntensiveWinterGrazingArea: false, Plantings: '', Covenentad: false, StripDate: '', OptimalRange: '', OlsenpValueRange: '',
        });
    }

    const handleItemSubmit = async (event) => {
        setFormErrors({});
        setSaving(true);
        const errors = {};
        let enterprises = '';
        let contaminants = '';
        let riskDataGroups = '';
        let activityGroups = '';
        let activitySubGroups = '';

        if (itemPopupRisk.isRisk == "Yes") {
            if (itemPopupRisk.riskComment == null || itemPopupRisk.riskComment == undefined || itemPopupRisk.riskComment == "") {
                errors.riskComment = "This is mandatory!";
            }
            if (Array.isArray(itemPopupRisk.enterprises)) {
                let enterprisesArr = [];
                itemPopupRisk.enterprises.forEach(risk => {
                    enterprisesArr.push(risk.value);
                })
                enterprises = enterprisesArr.join(",");
            } else {
                enterprises = itemPopupRisk.enterprises.toString();
            }
            if (enterprises == null || enterprises == undefined || enterprises == "") {
                errors.enterprises = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.contaminants)) {
                let contaminantsArr = [];
                itemPopupRisk.contaminants.forEach(risk => {
                    contaminantsArr.push(risk.value);
                })
                contaminants = contaminantsArr.join(",");
            } else {
                contaminants = itemPopupRisk.contaminants.toString();
            }
            if (contaminants == null || contaminants == undefined || contaminants == "") {
                errors.contaminants = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.riskDataGroups)) {
                let riskDataGroupsArr = [];
                itemPopupRisk.riskDataGroups.forEach(risk => {
                    riskDataGroupsArr.push(risk.value);
                })
                riskDataGroups = riskDataGroupsArr.join(",");
            } else {
                riskDataGroups = itemPopupRisk.riskDataGroups.toString();
            }
            if (riskDataGroups == null || riskDataGroups == undefined || riskDataGroups == "") {
                errors.riskDataGroups = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.activityGroups)) {
                let activityGroupsArr = [];
                itemPopupRisk.activityGroups.forEach(risk => {
                    activityGroupsArr.push(risk.value);
                })
                activityGroups = activityGroupsArr.join(",");
            } else {
                activityGroups = itemPopupRisk.activityGroups.toString();
            }
            if (activityGroups == null || activityGroups == undefined || activityGroups == "") {
                errors.activityGroups = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.activitySubGroups)) {
                let activitySubGroupsArr = [];
                itemPopupRisk.activitySubGroups.forEach(risk => {
                    activitySubGroupsArr.push(risk.value);
                })
                activitySubGroups = activitySubGroupsArr.join(",");
            } else {
                activitySubGroups = itemPopupRisk.activitySubGroups.toString();
            }
            if (activitySubGroups == null || activitySubGroups == undefined || activitySubGroups == "") {
                errors.activitySubGroups = "This is mandatory!";
            }

            if (itemPopupRisk.likelihood == null || itemPopupRisk.likelihood == undefined || itemPopupRisk.likelihood == "") {
                errors.likelihood = "This is mandatory!";
            }
            if (itemPopupRisk.impact == null || itemPopupRisk.impact == undefined || itemPopupRisk.impact == "") {
                errors.impact = "This is mandatory!";
            }
        }
        if (itemPopupHeader.LMUIds == null || itemPopupHeader.LMUIds == '' || itemPopupHeader.LMUIds == undefined || itemPopupHeader.LMUIds == '0') {
            errors.luid = "This is mandatory!";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            let form = {
                Id: itemPopup.id,
                MapMarkerType: itemPopup.markertype,
                FarmId: itemPopup.farmId,
                ItemId: itemPopup.itemId,
                Name: itemPopupHeader.Name,
                IsDragged: itemPopupHeader.isDragged,
                Description: itemPopupHeader.Description,
                Colour: itemPopupHeader.color,
                LMUId: itemPopupHeader.LMUId,
                ItemTypeId: itemPopupHeader.itemTypeId,
                Area: itemPopupMaptype.area,
                Length: itemPopupMaptype.perimeter,
                MapCoordinates: itemPopupMaptype.coordinates,
                IsAddsToBioDiversity: itemPopupBiodiversity.isItemToBiodiversity,
                BioDiversityComment: itemPopupBiodiversity.biodiversityComment,
                IsItemHaveAnyRisk: itemPopupRisk.isRisk,
                RiskDescription: itemPopupRisk.riskComment,
                IsAchievementOfNote: itemPopupAchievements.isAchievementsNotes,
                AchievementOfNoteComments: itemPopupAchievements.achievementsComment,
                DetentionDam: itemPopupItemSepcQ.DetentionDam,
                DebrisDam: itemPopupItemSepcQ.DebrisDam,
                DropFlume: itemPopupItemSepcQ.DropFlume,
                Dam: itemPopupItemSepcQ.Dam,
                Age: itemPopupItemSepcQ.Age,
                PlantedDateText: (itemPopupItemSepcQ.PlantedDate != '' && itemPopupItemSepcQ.PlantedDate != null) ? getParsedDate(itemPopupItemSepcQ.PlantedDate) : '',
                TreeSpecies: itemPopupItemSepcQ.TreeSpecies,
                IsBeenHarvestedLast10Years: itemPopupItemSepcQ.IsBeenHarvestedLast10Years,
                ForestryType: itemPopupItemSepcQ.ForestryType,
                BlockInETS: itemPopupItemSepcQ.BlockInETS,
                StemCountPerHA: itemPopupItemSepcQ.StemCountPerHA,
                DrainageType: itemPopupItemSepcQ.DrainageType,
                Covenentad: itemPopupItemSepcQ.Covenentad,
                StripDateText: (itemPopupItemSepcQ.StripDate != '' && itemPopupItemSepcQ.StripDate != null) ? getParsedDate(itemPopupItemSepcQ.StripDate) : '',
                OptimalRange: itemPopupItemSepcQ.OptimalRange,
                OlsenpValueRange: itemPopupItemSepcQ.OlsenpValueRange,
                userId: userId,
                isEdited: itemPopupHeader.isEdited,
                radius: itemPopupMaptype.radius,
                IsCSAWithinIntensiveWinterGrazingArea: itemPopupItemSepcQ.IsCSAWithinIntensiveWinterGrazingArea,
                Plantings: itemPopupItemSepcQ.Plantings,
                ActivityIds: enterprises,
                ContaminantIds: contaminants,
                RiskDataGroupIds: riskDataGroups,
                ActivityGroupIds: activityGroups,
                ActivitySubGroupIds: activitySubGroups,
                RiskLikelihood: itemPopupRisk.likelihood,
                RiskImpact: itemPopupRisk.impact,
                LMUIds: itemPopupHeader.LMUIds
            }
            if (!Validate(form)) {
                try {
                    const response = await axios.post(
                        '/api/landsoil/UpdateLandSoil/', JSON.stringify(form),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        });
                    if (response.data != null) {
                        await refreshItemDetails();
                        setSaving(false);
                        toast.success("Item details saved!", {duration: 5000,style: {border: '1px solid #B6D137',padding: '16px',color: 'black',fontSize:"18px"},iconTheme: {primary: '#B6D137',secondary: '#FFFAEE',}});
                        handleItemPopupClose();
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            else {
                setSaving(false);
            }
        }
        else {
            setSaving(false);
        }
    }

    const refreshItemDetails = async () => {
        const response = await axios.get(
            '/api/landsoil/GetLandSoil/', {
            params: {
                farmId: farmId
            }
        }
        );
        setLandSoil({
            ...landsoil, itemList: response.data.itemList, outlineCenter: response.data.outlineCenter
        });
        if (response.data.wizard.isLandSoilSaved) {
            setIsThisPageCompleted(true);
        }
        else {
            setIsThisPageCompleted(false);
        }
        setActionsCount(response.data.actionCount);
    }

    function handleItemPopupClose() {
        resetValues();
    }

    const handleMapItemEdited = (itemId, type, strValue) => {
        let name = '';
        let value = '';
        if (type == "cricleCenterChanges") {
            name = 'mapCoordinates';
            value = strValue.lat().toFixed(6) + ", " + strValue.lng().toFixed(6);
        }
        else if (type == "cricleRadiusChanges") {
            name = 'radius';
            value = parseFloat(strValue.toFixed(2));

        }
        else if (type == "polygonBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }
        else if (type == "polylineBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }

        const itemList = landsoil.itemList.map(item =>
            item.itemId === itemId
                ? { ...item, [name]: value, isEdited: true }
                : item
        )
        setLandSoil({ ...landsoil, itemList: itemList });
    }

    /*Item Edit and Submit*/
    function editMapItem(mapItem, itemId, isDragged) {
        const itemDetails = landsoil.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;
        if (isDragged) {
            coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
        }

        let area = 0.0, perimeter = 0.0;
        if (itemDetails.isEdited == true) {
            if (itemDetails.mapMarkerType == "circle") {
                const Pi = 3.14;
                area = ((itemDetails.radius * itemDetails.radius * Pi) / 10000).toFixed(1);
                perimeter = ((2 * Pi * itemDetails.radius) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polygon") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polyline") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                const tempPolyline = new window.google.maps.Polyline({
                    paths: positions
                });

                let path = tempPolyline.paths;
                area = itemDetails.area;
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.length;
        }

        const isBiodiversityReq = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: area, perimeter: perimeter, coordinates: coordinates, radius: itemDetails.radius });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, Name: itemDetails.name, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                isDragged: isDragged, Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });

            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))
                    });
                    if (selectedLUEnterpriseTypes.length == 0) {
                        selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                            return el.label == 'N/A'
                        });
                    }
                }
            }

            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: false });

            const itemFieldTypesList = landsoil.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            setItemPopupItemField({ ...itemPopupItemField, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList });

            setItemPopupItemSepcQ({
                DetentionDam: itemDetails.detentionDam,
                DebrisDam: itemDetails.debrisDam,
                DropFlume: itemDetails.dropFlume,
                Dam: itemDetails.dam,
                Age: itemDetails.age,
                PlantedDate: itemDetails.plantedDate != null ? getDateFromString(moment(itemDetails.plantedDate).format("DD/MM/YYYY")) : '',
                TreeSpecies: itemDetails.treeSpecies,
                IsBeenHarvestedLast10Years: itemDetails.isBeenHarvestedLast10Years,
                ForestryType: itemDetails.forestryType,
                BlockInETS: itemDetails.blockInETS,
                StemCountPerHA: itemDetails.stemCountPerHA,
                DrainageType: itemDetails.drainageType,
                IsCSAWithinIntensiveWinterGrazingArea: itemDetails.isCSAWithinIntensiveWinterGrazingArea,
                Plantings: itemDetails.plantings,
                Covenentad: itemDetails.covenentad,
                StripDate: itemDetails.stripDate != null ? getDateFromString(moment(itemDetails.stripDate).format("DD/MM/YYYY")) : '', 
                OptimalRange: itemDetails.optimalRange,
                OlsenpValueRange: itemDetails.olsenpValueRange,
            });
        }
    }

    const editMapItemFromNavigation = (itemId, landsoil, navigateFrom) => {
        const itemDetails = landsoil.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;

        let area = 0.0, perimeter = 0.0;
        if (itemDetails.isEdited == true) {
            if (itemDetails.mapMarkerType == "circle") {
                const Pi = 3.14;
                area = ((itemDetails.radius * itemDetails.radius * Pi) / 10000).toFixed(1);
                perimeter = ((2 * Pi * itemDetails.radius) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polygon") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polyline") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                const tempPolyline = new window.google.maps.Polyline({
                    paths: positions
                });

                let path = tempPolyline.paths;
                area = itemDetails.area;
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.length;
        }

        const isBiodiversityReq = landsoil.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: [], farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: area, perimeter: perimeter, coordinates: coordinates, radius: itemDetails.radius });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, Name: itemDetails.name, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId,
                Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });

            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))
                    });
                    if (selectedLUEnterpriseTypes.length == 0) {
                        selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                            return el.label == 'N/A'
                        });
                    }
                }
            }

            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            let isExpand = false;
            if (navigateFrom == "Achievements")
                isExpand = true
            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: isExpand });

            const itemFieldTypesList = landsoil.itemFieldList.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            setItemPopupItemField({ ...itemPopupItemField, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList });

            setItemPopupItemSepcQ({
                DetentionDam: itemDetails.detentionDam,
                DebrisDam: itemDetails.debrisDam,
                DropFlume: itemDetails.dropFlume,
                Dam: itemDetails.dam,
                Age: itemDetails.age,
                PlantedDate: itemDetails.plantedDate != null ? getDateFromString(moment(itemDetails.plantedDate).format("DD/MM/YYYY")) : '',
                TreeSpecies: itemDetails.treeSpecies,
                IsBeenHarvestedLast10Years: itemDetails.isBeenHarvestedLast10Years,
                ForestryType: itemDetails.forestryType,
                BlockInETS: itemDetails.blockInETS,
                StemCountPerHA: itemDetails.stemCountPerHA,
                DrainageType: itemDetails.drainageType,
                IsCSAWithinIntensiveWinterGrazingArea: itemDetails.isCSAWithinIntensiveWinterGrazingArea,
                Plantings: itemDetails.plantings,
                Covenentad: itemDetails.covenentad,
                StripDate: itemDetails.stripDate,
                OptimalRange: itemDetails.optimalRange,
                OlsenpValueRange: itemDetails.olsenpValueRange,
            });
        }
    }

    function handleItemEditPopup(itemId) {
        editMapItem([], itemId, false)
    }

    /*Item Delete*/
    function handleItemDeletePopup(itemId) {
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }

    function handleItemDeletePopupClose() {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0 });
    }

    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        setItemDeleteSaving(true);
        try {
            const response = await axios.get(
                '/api/landsoil/DeleteItem/', {
                params: {
                    itemId: itemid, userId: userId
                }
            }
            );
            if (response.data != null) {
                await refreshItemDetails();
                setItemDeleteSaving(false);
                handleItemDeletePopupClose();
                handleItemPopupClose();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /*Item- Action Popup handle*/
    function handleOpenActionPopup(itemId, actionId) {
        const itemDetails = landsoil.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleOpenWholeActionPopup = (itemId, actionId) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: landsoil.outlineCenter.itemTypeId, itemName: 'NA', actionId: actionId });
    };

    function handleActionClose() {
        setActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const handleWholeActionClose = () => {
        setWholeFarmActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*Item- MaintenancePlan Popup handle*/
    function handleOpenMaintenancePlanPopup(itemId) {
        const itemDetails = landsoil.itemList.filter(items => items.itemId == itemId)[0];
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: true, itemId: itemId, itemName: itemDetails.name, itemTypeId: itemDetails.itemTypeId, actionId: 0 });
    }

    function handleMaintenancePlanClose() {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const getParsedDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + "-" + mm + "-" + yyyy;
        return date.toString();
    }

    const getDateFromString = (date) => {
        var dd = date.split('/')[0];
        var mm = date.split('/')[1];
        var yyyy = date.split('/')[2];
        var newDate = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
        return newDate;
    }

    /*handle Toggle for nonMap View*/
    const handleItemTypeListTogole = (isOpen, isIconUp) => {
        setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: isOpen, isIconUp: isIconUp })
    }

    const handleItemTypeTogole = (itemTypeId, isOpen, isIconUp,) => {
        setToggleItemTypeState({ ...toggleItemTypeState, itemTypeId: itemTypeId, isOpen: isOpen, isIconUp: isIconUp })
    }

    const handleItemTogole = (itemId, isOpen, isIconUp) => {
        setToggleItemState({ ...toggleItemState, itemId: itemId, isOpen: isOpen, isIconUp: isIconUp })
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    const handleRiskSummaryClose = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: false })
        window.location.reload(true);
    }

    const handleRiskSummaryOpen = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: true })
    }

    /*Avoid unwanted page refresh*/
    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={landsoil} width={toggleSwitch == 'map' ? '80vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                mapDetails={mapDetails}
                addMapItem={addMapItem}
                largeEnterpriseDetails={largeEnterpriseDetails}
                editMapItem={editMapItem}
                handleMapItemEdited={handleMapItemEdited}
                gisLayerSoilDetails={gisLayerSoilDetails}
                gisLayerTopographyDetails={gisLayerTopographyDetails}
                gisLayerWetlandDetails={gisLayerWetlandDetails}
            ></MapView>
        )
    }, [landsoil, mapDetails, largeEnterpriseDetails, gisLayerSoilDetails, gisLayerTopographyDetails, toggleSwitch])

    const itemTypeListView = useMemo(() => {

        return (
            <ItemTypeListViewForMap Section={landsoil.Section} isWizardCompleted={landsoil.isWizardCompleted}
                itemTypesList={landsoil.itemTypes}
                toggleItemTypeListState={toggleItemTypeListState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemTypeListTogole={handleItemTypeListTogole}
            >
            </ItemTypeListViewForMap>
        )
    }, [landsoil, largeEnterpriseDetails, landsoil.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, landsoil.outlineCenter])

    const nonMapView = useMemo(() => {
        return (
            <NonMapView itemModel={landsoil} isWizardCompleted={landsoil.isWizardCompleted}
                toggleItemTypeListState={toggleItemTypeListState}
                toggleItemTypeState={toggleItemTypeState}
                toggleItemState={toggleItemState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemEditPopup={handleItemEditPopup}
                handleItemDeletePopup={handleItemDeletePopup}
                handleOpenActionPopup={handleOpenActionPopup}
                sectionObj={landsoil.SectionObj}
                largeEnterpriseDetails={largeEnterpriseDetails}
                handleLMUSelectChange={handleLMUSelectChange}
                handleItemTypeListTogole={handleItemTypeListTogole}
                handleItemTypeTogole={handleItemTypeTogole}
                handleItemTogole={handleItemTogole} handleOpenWholeActionPopup={handleOpenWholeActionPopup}
                handleItemHighlight={handleItemHighlight} outlineCenter={landsoil.outlineCenter}
            ></NonMapView>
        )
    }, [landsoil, largeEnterpriseDetails, landsoil.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, landsoil.outlineCenter])

    const handleGISLayerTopographyDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: true })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
        }
        else {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
        }
        
    }

    const handleGISLayerSoilDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: true })
        }
        else {
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
        }

    }

    const handleGISLayerWetlandDetails = (e) => {
        if (e.target.checked) {
            setGISLayerTopographyDetails({ ...gisLayerTopographyDetails, showGISLayer: false })
            setGISLayerSoilDetails({ ...gisLayerSoilDetails, showGISLayer: false })
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: true })
        }
        else {
            setGISLayerWetlandDetails({ ...gisLayerWetlandDetails, showGISLayer: false })
        }

    }

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('googleMap').style.height = '80vh';
            document.getElementById('googleMap').style.width = '80vw';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }
    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }

    const [checkActionPopup, setCheckActionPopup] = useState({ showPopup: false, section: '', method: '' });
    const handleCheckActionPopupClose = () => {
        setCheckActionPopup({ showPopup: false, section: '', method: '' });
    }
    const handleCheckCalculatorDataPopupConfirm = () => {
        if (checkActionPopup.method == 'handleWizardChanged') {
            handleWizardChanged(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleMoveToNextPage') {
            handleMoveToNextPage(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleWizardChangedBack') {
            handleWizardChangedBack(checkActionPopup.section);
        }
    }
    function checkAction(section, method) {
        if (!isThisPageCompleted && actionsCount == 0) {
            setCheckActionPopup({ showPopup: true, section: section, method: method });
        }
        else {
            if (method == 'handleWizardChanged') {
                handleWizardChanged(section);
            }
            else if (method == 'handleMoveToNextPage') {
                handleMoveToNextPage(section);
            }
            else if (method == 'handleWizardChangedBack') {
                handleWizardChangedBack(section);
            }
        }
    }

    return (
        <div>

            <Modal isOpen={checkActionPopup.showPopup}>
                <ModalHeader toggle={handleCheckActionPopupClose} >Confirm</ModalHeader>
                <ModalBody>
                    You have not created any actions on this page. Are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleCheckCalculatorDataPopupConfirm} color="primary">
                        Yes
                    </Button>{' '}
                    <Button color="danger" onClick={handleCheckActionPopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{landsoil.SectionName}</h2></Col>
                <Col>
                    {landsoil.isWizardCompleted != true && !loading &&
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => checkAction(landsoil.Section, 'handleWizardChanged')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(landsoil.Section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                        <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(landsoil.Section)}><FaChevronLeft /> Back  </Button>
                        </>
                        
                    }
                    {
                        !loading && <Button onClick={() => handleRiskSummaryOpen()} className="btn float-end btn-risk-summary">Risk and Actions summary</Button>
                    }
                </Col>
                
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <Col>
                                <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                            </Col>
                        </div>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col id='mapView'>
                            <Row className="map-layer">
                                <Col>
                                    <Row>
                                        <Col sm="4"></Col>
                                        <Col sm="8">
                                            <div className="map-item-edit-div">
                                                <Label className="map-checkbox-label">Show LU layer</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={largeEnterpriseDetails.isSmallEnterpriseShow} onChange={(e) => setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isSmallEnterpriseShow: !largeEnterpriseDetails.isSmallEnterpriseShow })} name="isSmallEnterpriseShow" id="isSmallEnterpriseShow" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Edit Item</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Show Label</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                             </div>
                                        </Col>
                                    </Row>
                                    {mapView}
                                </Col>
                            </Row>
                        </Col>
                        <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "20vw" }}>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Label className="external-map-title">External Map Layers</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerTopographyDetails.showGISLayer} onChange={handleGISLayerTopographyDetails} name="gisLayerTopographyDetails" id="gisLayerTopographyDetails" />
                                    {'   '}<Label className="">Show Topography map Layer</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerSoilDetails.showGISLayer} onChange={handleGISLayerSoilDetails} name="gisLayerSoilDetails" id="gisLayerSoilDetails" />
                                    {'   '}<Label className="">Show Soil map Layer</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerWetlandDetails.showGISLayer} onChange={handleGISLayerWetlandDetails} name="gisLayerWetlandDetails" id="gisLayerWetlandDetails" />
                                    {'   '}<Label className="">Show Wetland map Layer</Label>
                                </Col>
                            </Row>
                            <br />
                            {itemTypeListView}
                            <br />
                        </Col>
                        <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block', }}>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Label className="external-map-title">External Map Layers</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerTopographyDetails.showGISLayer} onChange={handleGISLayerTopographyDetails} name="gisLayerTopographyDetails" id="gisLayerTopographyDetails" />
                                    {'   '}<Label className="">Show Topography map Layer</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerSoilDetails.showGISLayer} onChange={handleGISLayerSoilDetails} name="gisLayerSoilDetails" id="gisLayerSoilDetails" />
                                    {'   '}<Label className="">Show Soil map Layer</Label>
                                </Col>
                            </Row>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <Input className="" type="checkbox" checked={gisLayerWetlandDetails.showGISLayer} onChange={handleGISLayerWetlandDetails} name="gisLayerWetlandDetails" id="gisLayerWetlandDetails" />
                                    {'   '}<Label className="">Show Wetland map Layer</Label>
                                </Col>
                            </Row>
                            {nonMapView}
                        </Col>
                    </Row>
                </div>
            }
            <Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>
                <Modal size="lg" isOpen={itemPopup.showItemPopup} backdrop={false} className="itemPopup">
                <Form>
                    <ModalHeader toggle={handleItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <ItemCommonTop Section={landsoil.Section} handleLMUChange={handleLMUChange} itemPopupHeader={itemPopupHeader} itemPopupMaptype={itemPopupMaptype} itemPopupBiodiversity={itemPopupBiodiversity} itemPopupRisk={itemPopupRisk}
                            largeEnterpriseDetails={largeEnterpriseDetails} formErrors={formErrors} riskLookupList={riskLookupList}
                            handleItemPopupHeaderChange={handleItemPopupHeaderChange}
                            handleItemPopupBiodiversity={handleItemPopupBiodiversity}
                            handleItemPopupRisk={handleItemPopupRisk}
                            handleMultiSelectEnterpriseChange={handleMultiSelectEnterpriseChange}
                            handleMultiSelectContaminantChange={handleMultiSelectContaminantChange}
                            handleMultiSelectRiskDataGroupChange={handleMultiSelectRiskDataGroupChange}
                            handleMultiSelectActivityGroupChange={handleMultiSelectActivityGroupChange}
                            handleMultiSelectActivitySubGroupChange={handleMultiSelectActivitySubGroupChange}></ItemCommonTop>

                        <LandSoilItemSpecView handleItemDatePlantedDate={handleItemDatePlantedDate} itemPopupItemField={itemPopupItemField} itemPopupItemSepcQ={itemPopupItemSepcQ}
                            handleItemSepcValueChange={handleItemSepcValueChange} treeSpeciesList={landsoil.treeSpeciesList} forestryTypeList={landsoil.forestryTypeList}
                            drainageTypeList={landsoil.drainageTypeList} blockInETSList={landsoil.blockInETSList} handleItemDateStripDate={handleItemDateStripDate}>
                        </LandSoilItemSpecView>

                        <ItemCommonBottom itemPopup={itemPopup} itemPopupAchievements={itemPopupAchievements}
                            handleItemPopupAchievements={handleItemPopupAchievements}></ItemCommonBottom>
                    </ModalBody>

                    <ItemFooter saving={saving} itemPopup={itemPopup} Section={landsoil.Section}
                        handleItemDeletePopup={handleItemDeletePopup}
                        handleItemSubmit={handleItemSubmit}
                        handleItemPopupClose={handleItemPopupClose}
                        handleOpenActionPopup={handleOpenActionPopup}
                        handleOpenMaintenancePlanPopup={handleOpenMaintenancePlanPopup}>
                    </ItemFooter>
                </Form>
                </Modal>
            </Draggable>
            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    {itemDeletesaving ?
                        <>
                            <Button onClick={deleteItem} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={deleteItem} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            {actionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={landsoil.itemList} redirectPage="/landsoil" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleActionClose} itemTypeId={actionPopup.itemTypeId} sectionName={landsoil.Section} sectionId={landsoil.itemTypes[0].sectionId} itemId={actionPopup.itemId} itemName={actionPopup.itemName}
                showActionPopupModal={actionPopup.showActionPopup} actionId={actionPopup.actionId} itemTypeSelect={landsoil.itemTypes} itemTypeDisabled={true}></Action>}

            {wholeFarmActionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={landsoil.itemList} redirectPage="/landsoil" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleWholeActionClose} itemTypeId={wholeFarmActionPopup.itemTypeId} sectionName={landsoil.Section} sectionId={landsoil.itemTypes[0].sectionId} itemId={wholeFarmActionPopup.itemId} itemName={wholeFarmActionPopup.itemName}
                showActionPopupModal={wholeFarmActionPopup.showActionPopup} actionId={wholeFarmActionPopup.actionId} itemTypeSelect={landsoil.outlineItemTypes} itemTypeDisabled={true} wholeFarmAction={true}></Action>}

            {maintenancePlanPopup.showMaintenancePlanPopup && <MaintenancePlan handleMaintenancePlanClose={handleMaintenancePlanClose}
                showMaintenancePlanPopupModal={maintenancePlanPopup.showMaintenancePlanPopup}
                actionId={maintenancePlanPopup.actionId} itemId={maintenancePlanPopup.itemId} itemName={maintenancePlanPopup.itemName} itemTypeId={maintenancePlanPopup.itemTypeId}
                itemTypeSelect={landsoil.itemTypes} sectionName={landsoil.Section} redirectPage="/landsoil">
            </MaintenancePlan>}

            {riskSummaryPopup.showriskSummary && <RiskSummary section={landsoil.SectionObj} width={width} height={height} sectionId={landsoil.itemTypes[0].sectionId} riskSummaryPopup={riskSummaryPopup} handleRiskSummaryClose={handleRiskSummaryClose}></RiskSummary>}
        </div>
    )
}
export default LandSoil;