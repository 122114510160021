import {
    Row, Col,  Label, Input, FormGroup,
} from 'reactstrap';
import './Lmu.css';

const NonContiguousForm = ({ form, masterLmuList, handleFormFieldChange, isNonContiguous }) => {

    return (
        <>
            {
                masterLmuList.length > 0 &&
                <>
                    <Row>
                        <Col sm="12">
                            <Label for="inherentRisks">Non-Contiguous Land Units</Label>
                        </Col>
                        <Col sm="12">
                            <Label className="info-text">It is possible to join land units together if non-contiguous (e.g. physically seperate). Select Non-contiguous, then select which other Land Unit this Land Unit will be connected with </Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Row>
                                <Col sm="12">
                                    <FormGroup check>
                                        <Label
                                            check
                                            for="isNonContiguousItem"
                                        >
                                            Non-contiguous?
                                        </Label>
                                        <Input
                                            defaultChecked={form.isNonContiguousItem}
                                            id="isNonContiguousItem"
                                            name="isNonContiguousItem"
                                            type="checkbox"
                                            value={form.isNonContiguousItem}
                                            onChange={handleFormFieldChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        {
                            isNonContiguous &&
                            <Col sm="6">
                                <FormGroup>
                                    {/*<Label for="masterLmuId">*/}
                                    {/*    Parent LU*/}
                                    {/*</Label>*/}
                                        <Input bsSize="sm"
                                        type="select"
                                        value={form.masterLmuId}
                                        onChange={handleFormFieldChange}
                                        name="masterLmuId"
                                        id="masterLmuId"
                                    >
                                        <option value={0} >Select Land Unit to connect this Land Unit to</option>
                                        {masterLmuList.map(c =>
                                            <option key={c.id} value={c.id}>{c.value}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {
                            isNonContiguous &&
                            <Col sm="12">
                                <Label className="info-text">When land units are joined as non-contiguous they are usually managed as a single Land Unit with only a single set of detail. If needed these can be seperated
                                    however select below to be able to specify specifies for this land unit only.
                                </Label>
                            </Col>    
                        }
                        
                        {
                            isNonContiguous &&
                            <>
                                <Col sm="12" className="same-as-master">
                                    <FormGroup check>
                                        <Label
                                            check
                                            for="isSameAsMaster"
                                        >
                                            The land unit has the same detail as the  connected land unit
                                        </Label>
                                        <Input
                                            defaultChecked={form.isSameAsMaster}
                                            checked={form.isSameAsMaster}
                                            id="isSameAsMaster"
                                            name="isSameAsMaster"
                                            type="checkbox"
                                            value={form.isSameAsMaster}
                                            onChange={handleFormFieldChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </>

                        }
                    </Row>
                </>

                    
            }
        </>
    )
}

export default NonContiguousForm