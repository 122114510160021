import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';

const GFPSummaryReport = () => {

    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId")); const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
    }, [])

    const exportGFPReport = async (e, type) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleGFPReport(e, type);
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleGFPReport = async (e, type) => {
        if (type == "Pdf") {
            const response = await axios.get(
                '/api/EnterpriseInsights/ExportGFPFarmReportDataPDF/', {
                    params: { UserId: userId }
            })
            if (response.data.status) {
                var buffer = base64ToArrayBuffer(response.data.content)
                var file = new Blob([buffer], { type: "application/pdf" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "GFPSummaryReport.pdf";
                document.body.appendChild(link)
                link.click()
            }
        }
        else {
            const response = await axios.get(
                '/api/EnterpriseInsights/ExportGFPFarmReportDataExcel/', {
                    params: { UserId: userId }
            })
            if (response.data.status) {
                var buffer = base64ToArrayBuffer(response.data.content)
                var file = new Blob([buffer], { type: "application/vnd.ms-excel" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "GFPSummaryReport.xlsx";
                document.body.appendChild(link)
                link.click()
            }
        }
    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <Card className="my-4">
            <CardHeader className="card-header-dark-click" onClick={toggle}>
                <Row>
                    <Col sm="11"><h6>GFP Summary Report</h6></Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody >
                    <div class="row">
                        <div class="col-md-8">
                            <h6 className= "gmp-export-text">Good Farming Practice Summary</h6>
                        </div>
                        <div class="col-md-4">
                            <Row>
                            <Button className= "gmp-export-button" onClick={(e) => exportGFPReport(e, 'Excel')} value="Create Report Excel" placeholder="">
                                Export to <img src="/images/Excel_icon.png" className="card_icon" ></img>
                            </Button>
                            </Row>
                            <Row>
                            <Button className= "gmp-export-button" onClick={(e) => exportGFPReport(e, 'Pdf')} value="Create Report PDF" placeholder="">
                                Export to <img src="/images/PDF_icon.png" className="card_icon" ></img>
                                </Button> 
                            </Row>
                        </div>
                    </div>

                </CardBody>
            </Collapse>
        </Card>
    )
}
export default GFPSummaryReport