import { useState, useEffect } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Button, Collapse, Table, FormGroup, Label, Input } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaChevronRight, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import uuid from 'react-uuid';
import moment from "moment";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import TotalFarmDetails from '../AllFarmsSummary/TotalFarmDetails';
const FarmList = ({ farmsList, tempFarmsList, totalFarmDetails, itemModel, handleWizardFarmChanged, userId, handleChangeName,name,setName }) => {
    
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true); 
    const [farmItems, setFarmItems] = useState(farmsList);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const [currentItems, setCurrentitems] = useState(farmItems.slice(indexOfFirstItem, indexOfLastItem));
    
    const toggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    useEffect(() => {
        
    })

    const handleSetFarm = async (e, id) => {
        e.preventDefault();
        const farmId = parseInt(id);
        try {
            const response = await axios.get(
                '/api/settings/getFarmDetail/', {
                params: { farmId: farmId }
            }
            );
            localStorage.setItem("defaultFarmId", JSON.stringify(id));
            localStorage.setItem("defaultFarmName", JSON.stringify(response.data.name));
            localStorage.setItem("defaultFarmUniqueId", JSON.stringify(response.data.uniqueIdentifier));
            handleWizardFarmChanged(farmId);
        } catch (err) {
            if (err?.response) {
                toast.error("No server response");
            }
        }
    }

    const exportFarmListToExcel = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportExcel();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportExcel = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportFarmListExcel/', {
                params: { UserId: userId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })

            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OrchardList.xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const exportFarmListToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportPdf = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportFarmListPDF/', {
                params: { userId: userId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OrchardList.pdf";
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={toggle}>
                            <Row>
                                <Col sm="11">
                                    <h6>Orchard List
                                        <Button style={{ float: "right", background: "none", border: "none" }} onClick={(e) => exportFarmListToExcel(e)} value="Export" placeholder="">
                                            <img src="/images/Excel_icon.png" className="card_icon" ></img>
                                        </Button>
                                        
                                        {/*<Button style={{ float: "right", background: "none", border: "none", marginRight: "-20px" }} onClick={(e) => exportFarmListToPdf(e)} value="Export" placeholder="">*/}
                                        {/*    <img src="/images/PDF_icon.png" className="card_icon" ></img>*/}
                                        {/*</Button>*/}
                                    </h6>
                                </Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div> 
                                    
                                    <Row style={{ marginTop:"10px" }}>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label style={{ textTransform: "none", fontSize: "medium" }}>Search</Label>
                                                <Input className="validate" type="text" value={name} onChange={handleChangeName} name="Name" id="Name" placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>ID</th>
                                                {/*<th>Created On</th>*/}
                                                <th>Operator</th>
                                                <th>Catchment</th>
                                                <th>Regional Council</th>
                                                <th>Last Updated On</th>
                                                <th>Status</th>
                                                <th width= "10%">View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {farmsList != undefined && farmsList != null &&
                                                farmsList.slice(indexOfFirstItem, indexOfLastItem) != undefined && farmsList.slice(indexOfFirstItem, indexOfLastItem) != null &&
                                                farmsList.slice(indexOfFirstItem, indexOfLastItem).map(item => (
                                                <tr key={uuid()}>
                                                    <td>{item.name} </td>
                                                    <td>{item.uniqueIdentifier}</td>
                                                    <td>{item.operator}</td>
                                                    <td style={{fontSize:"14px"}}>{item.catchmentText}</td>
                                                    <td style={{ fontSize: "14px" }}>{item.regionalCouncilText}</td>
                                                    <td>{item.fepLastUpdatedDate != null ? moment(item.fepLastUpdatedDate).format("DD/MM/YYYY") : ""}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <Button id={item.id} className="button-next" color="success" onClick={(e) => handleSetFarm(e, item.id)}> <FaChevronRight /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <PaginationItem disabled={currentPage === 1}>
                                            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                        </PaginationItem>
                                        {[...Array(Math.ceil(farmsList.length / itemsPerPage))].map((item, index) => (
                                            <PaginationItem key={index} active={currentPage === index + 1}>
                                                <PaginationLink onClick={() => handlePageChange(index + 1)}>{index + 1}</PaginationLink>
                                            </PaginationItem>
                                        ))}

                                        <PaginationItem disabled={currentPage === Math.ceil(farmsList.length / itemsPerPage)}>
                                            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default FarmList;