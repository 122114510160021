import React, { useState } from 'react';
import Moment from 'moment';
import './ItemView.css';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge, FormGroup, Input, Label } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaMapMarkerAlt, FaDrawPolygon, FaSlash, FaRegCircle, FaRegSquare, FaPencilAlt,FaPlus} from 'react-icons/fa';
import uuid from 'react-uuid';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';

const ItemView = ({ item, itemTypesList, toggleItemState, handleItemEditPopup, handleItemDeletePopup, handleOpenActionPopup, handleItemTogole, handleItemHighlight, Section }) => {
    const [isOpen, setIsOpen] = useState(item.itemId == toggleItemState.itemId ? toggleItemState.isOpen : false);
    const [isIconUp, setIsIconUp] = useState(item.itemId == toggleItemState.itemId ? toggleItemState.isIconUp : false);
    const [isClosedActionShow, setIsClosedActionShow] = useState(false);
    const [maintenancePlanSummaryPopup, setMaintenancePlanSummaryPopup] = useState({ showMaintenancePlanSummaryPopup: false });
    const [imageViewPopup, setImageViewPopup] = useState({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, section: '', itemTypes: itemTypesList, maintenancePlanId: 0 , routePath : '' });
    const roleName = localStorage.getItem("roleName");
    const isAddArchiveItem = localStorage.getItem("isAddArchiveItem");
    const isCreateNewAction = localStorage.getItem("isCreateNewAction");
    const toggle = (itemId) => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
        handleItemTogole(itemId, !isOpen, !isIconUp)
    };

    const handleClosedActionShow = (event) => {
        setIsClosedActionShow(event.target.checked);
    }

    const handleMaintenancePlanSummaryPopup = (event) => {
        event.stopPropagation()
        setMaintenancePlanSummaryPopup({ showMaintenancePlanSummaryPopup: true });
    }

    const handleMaintenancePlanSummaryPopupClose = (event) => {
        setMaintenancePlanSummaryPopup({ showMaintenancePlanSummaryPopup: false });
    }

    const handleImageViewPopup = (event, imageName, imageUrl) => {
        setImageViewPopup({ showImageViewPopup: true, imageName: imageName, imageUrl: imageUrl });
    }

    const handleImageViewPopupClose = (event) => {
        setImageViewPopup({ showImageViewPopup: false, imageName: '', imageUrl: '' });
    }

    /*Item- MaintenancePlan Popup handle*/
    const handleOpenMaintenancePlanPopup = (itemId, itemName, itemTypeId, section, maintenancePlanId, routePath) => {
        setMaintenancePlanPopup({
            ...maintenancePlanPopup, showMaintenancePlanPopup: true,
            itemId: itemId, itemName: itemName, itemTypeId: itemTypeId, actionId: 0,
            section: section, maintenancePlanId: maintenancePlanId, routePath: "/"+ routePath
        });
    }

    const handleMaintenancePlanClose = () => {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0, section: '', maintenancePlanId: 0 });
    }

    if (item != undefined) {
        const openActionCount = item.actions.filter(action => action.isCloseout != true).length;
        const closedActionCount = item.actions.filter(action => action.isCloseout == true).length;
        const hasRisk = item.isItemHaveAnyRisk == 'Yes' ? true : openActionCount > 0 ? true : false;
        const ImagesCount = item.imageList != null ? item.imageList.length : 0;
        return (
            <div>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="item-header" onClick={(itemId) => toggle(item.itemId)}>
                                <Row>
                                    <Col sm="6">
                                        <a onMouseOver={(itemId) => handleItemHighlight(item.itemId)} onMouseOut={(itemId) => handleItemHighlight(0)}>
                                            {item.mapMarkerType == 'marker' ? <FaMapMarkerAlt color={item.colour}></FaMapMarkerAlt> :
                                                item.mapMarkerType == 'polyline' ? <FaSlash color={item.colour}></FaSlash> :
                                                    item.mapMarkerType == 'polygon' ? <FaDrawPolygon color={item.colour}></FaDrawPolygon> :
                                                        item.mapMarkerType == 'circle' ? <FaRegCircle color={item.colour}></FaRegCircle> :
                                                            item.mapMarkerType == 'rectangle' && <FaRegSquare color={item.colour}></FaRegSquare>}
                                        </a>
                                        {' '} <span className="color-dark-teal">{item.name}</span>
                                        {item.isItemHaveAnyRisk == 'Yes' && <span className="risk-badge"><Badge color="danger">
                                            Risk
                                        </Badge></span>}
                                    </Col>
                                    <Col sm="5" className="text-end">
                                        {
                                            openActionCount > 0 ?
                                                <span className="risk-badge">
                                                    <Badge color="primary">
                                                        {openActionCount}
                                                    </Badge> <span className="card-header-label-xs">Open Actions</span>
                                                </span> :
                                                <></>
                                        }
                                    </Col>
                                    <Col sm="1" className="toggleoutline">
                                        {isIconUp ? <FaChevronUp /> : <FaChevronDown />}

                                    </Col>
                                    </Row>
                            </CardHeader>
                            <Collapse isOpen={isOpen}>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                                                <Row>
                                                    <Col sm="4">
                                                        <label className="item-desc para-light-sm ">{item.description}</label>
                                                    </Col>
                                                    {item.page != undefined && item.page == 'Irrigation' && item.itemTypeName != 'Water Take' && Section != 'Community & Cultural Significance' &&
                                                        <Col className="text-end" sm="8">
                                                            {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                                                                <>
                                                                    {item.canArchive &&
                                                                        <Button className="btn-items-actions" color="danger" size="sm" onClick={(itemId) => handleItemDeletePopup(item.itemId)} >
                                                                            Delete
                                                                        </Button>
                                                                    }
                                                                </>
                                                            }
                                                            <Button className="btn-items-actions" color="warning" size="sm" onClick={(itemId) => handleItemEditPopup(item.itemId)}>
                                                                Edit
                                                            </Button>
                                                            {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                                                                <>
                                                                    <Button className="btn-items-actions" color="success" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.itemId, 0)} >
                                                                        Add Action
                                                                    </Button>
                                                                </>
                                                            }
                                                            
                                                        </Col>
                                                    }
                                                    {item.page == undefined && Section != 'Community & Cultural Significance' &&
                                                        <Col className="text-end" sm="8">
                                                            {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                                                                <>
                                                                    {item.canArchive &&
                                                                        <Button className="btn-items-actions" color="danger" size="sm" onClick={(itemId) => handleItemDeletePopup(item.itemId)} >
                                                                            Delete
                                                                        </Button>
                                                                    }
                                                                </>
                                                            }
                                                            <Button className="btn-items-actions" color="warning" size="sm" onClick={(itemId) => handleItemEditPopup(item.itemId)}>
                                                                Edit
                                                            </Button>
                                                            {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                                                                <>
                                                                <Button className="btn-items-actions" color="success" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.itemId, 0)} >
                                                                    Add Action
                                                                </Button>
                                                                </>
                                                            }
                                                        </Col>
                                                    }
                                                    {item.page == undefined && Section == 'Community & Cultural Significance' &&
                                                        <Col className="text-end" sm="8">
                                                            {(roleName == 'Zespri Admin' || isAddArchiveItem == 'true') &&
                                                                <>
                                                                    {item.canArchive &&
                                                                        <Button className="btn-items-actions" color="danger" size="sm" onClick={(itemId) => handleItemDeletePopup(item.itemId)} >
                                                                            Delete
                                                                        </Button>
                                                                    }
                                                                </>
                                                            }
                                                            <Button className="btn-items-actions" color="warning" size="sm" onClick={(itemId) => handleItemEditPopup(item.itemId)}>
                                                                Edit
                                                            </Button>
                                                        </Col>
                                                    }
                                                    
                                                    {ImagesCount > 0 &&
                                                        <Col sm="12">
                                                            <Row>
                                                                {item.imageList.map(image =>
                                                                    <Col key={uuid()} sm="3" className="style-item-display">
                                                                        <img src={image.imageRelativePath} className="image-thumbnail" alt={image.imageName} onClick={(event, imageName, imageUrl) => handleImageViewPopup(event, image.imageName, image.imageRelativePath)}></img>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row className="my-3">
                                                    <Col>
                                                        {
                                                            closedActionCount > 0 ?
                                                                <FormGroup check inline className="float-end">
                                                                    <Input
                                                                        type="checkbox"
                                                                        name="isClosedActionShow"
                                                                        id="isClosedActionShow"
                                                                        checked={isClosedActionShow}
                                                                        onChange={handleClosedActionShow}
                                                                    />
                                                                    <Label htmlFor="isClosedActionShow" check> Show Closed Actions <Badge>{closedActionCount}</Badge></Label>
                                                                </FormGroup> : ''
                                                        }

                                                        {
                                                            item.actions.length != 0 &&
                                                                <>
                                                                    <h5 className="full-width-title">Actions</h5>
                                                                    <table className="table borderless">
                                                                        <thead></thead>
                                                                        <tbody>

                                                                            <tr className="actions-table" key={uuid()}>
                                                                                <td >
                                                                                    <Table className='table borderless '>
                                                                                        <thead >
                                                                                            <tr>
                                                                                                <th> # </th>
                                                                                                <th style={{ textAlign: 'center' }}> Category </th>
                                                                                                <th style={{ width: '10%' }}> Due </th>
                                                                                                <th style={{ width: '20%' }}> Risk </th>
                                                                                                <th style={{ width: '20%' }}> Title  </th>
                                                                                                <th style={{ width: '30%' }}> Details </th>
                                                                                                <th></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                item.actions.map(action =>

                                                                                                    <tr key={action.id} className={"" + (action.isCloseout === true ? "closedAction" : "openAction") + " " + (!isClosedActionShow && action.isCloseout === true ? "closed-hidden" : "")}>
                                                                                                        <td> {action.id} </td>
                                                                                                        <th scope="row" style={{ textAlign: 'center' }}>{action.category == null ? "" : <Badge color="danger">{action.category}</Badge>}  </th>
                                                                                                        <td> {action.actionType == 46 ? "Ongoing / Management" : Moment(action.dueDate).format('DD/MM/YYYY')} </td>
                                                                                                        <td> {action.riskName} </td>
                                                                                                        <td> {action.title} </td>
                                                                                                        <td> {action.description} </td>
                                                                                                        <td> {action.isCloseout !== true ?
                                                                                                            <Button color="warning" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.itemId, action.id)} > Edit </Button> :
                                                                                                            <Button color="primary" size="sm" onClick={(itemId, actionId) => handleOpenActionPopup(item.itemId, action.id)} > View </Button>}
                                                                                                                                                                                                                   </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                        }
                                                    </Col>

                                                </Row>

                                                <br />

                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <br />
                    </Col>
                </Row>

                <Modal isOpen={imageViewPopup.showImageViewPopup} size="lg" className="imageViewPopup">
                    <ModalHeader toggle={handleImageViewPopupClose} >{imageViewPopup.imageName}</ModalHeader>
                    <ModalBody>
                        <img src={imageViewPopup.imageUrl} className="item-image-popup" alt={imageViewPopup.imageName} ></img>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleImageViewPopupClose}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }



}
export default ItemView