import { Spinner } from 'reactstrap';

const SpinnerContainer = () => {
    return (
        <div className="spinner-container">
            <Spinner color="success" />
        </div>
    )
}

export default SpinnerContainer;