import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
const KMLUpload = ({ uploadkmlShapeClick, onFileChange }) => {
    return (
        <Card>
            <CardHeader className="item-header">
                <h5 className="color-dark-teal">Map Import</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <div className="panel-group" id="accordion-Edit" role="tablist" aria-multiselectable="true">
                            <Row>
                                <Col sm="8">
                                    <label className="kmlLabel para-light-sm"> If you have an existing maps of the farm it is easy to import.</label> <br /><br />
                                    <label className="kmlLabel para-light-sm"> Any number of layers can be imported including</label><br />
                                    <label className="kmlAdvanceLabel">  - Boundary/Outline</label><br />
                                    <label className="kmlAdvanceLabel">  - Land Units</label><br />
                                    <label className="kmlAdvanceLabel">  - Infrastructure</label><br />
                                    <label className="kmlAdvanceLabel">  - Waterways</label>
                                </Col>
                                <Col sm="4">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-outline-primary uploadkmlShape"
                                        name="uploadkmlShape"
                                        onClick={uploadkmlShapeClick}>
                                        Upload KML File
                                    </button> &nbsp;&nbsp;
                                    <label id="selectedkmlName"></label>
                                    <label className="kmlAdvanceLabel">  Maps must be KML format to upload.Other files types can be easily converted. Please contact support if you need help.</label>
                                </Col>
                            </Row>
                            <input type="file" className="clsuploadKML" name="uploadKML" id="uploadKML" onChange={(event) => onFileChange(event)} /><br />
                            <label className="kmlLabel para-light-sm">  Once imported the shapes and items are independent from the source and can be managed from the FWFP system.</label>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
export default KMLUpload