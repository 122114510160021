import { FaDrawPolygon} from 'react-icons/fa';
import { Button} from 'reactstrap';
const LmuUploadItemView = ({ item, handleItemHighlight, deleteItem }) => {
    return (
        <tr key={item.id}>
            <td>
                <a onPointerEnter={(itemId) => handleItemHighlight(item.id)} onPointerLeave={(itemId) => handleItemHighlight(0)}>
                    {item.mapMarkerType == 'polygon' && <FaDrawPolygon color={item.colour}></FaDrawPolygon>}
                </a>
            </td>
            <td> {item.name} </td>
            <td>
                <Button color="danger" size="sm" onClick={(event, id) => deleteItem(event, item.id)}>Delete</Button>
            </td>
        </tr>
    )
}
export default LmuUploadItemView;