import React, { useState } from "react";
import "./ReadMore.css";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <>
            {
                text != null &&
                    text.length > 88 ?
                    <p>

                        {isReadMore ? text.slice(0, 88) : text}

                        <span onClick={toggleReadMore} className="read-or-hide">
                            {isReadMore ? "...Read more" : " Show less"}
                        </span>
                    </p>
                    : <p>{text}</p>
            }
        </>
    );
}

const ReadMoreContent = ({ longText }) => {

    return (
        <div className="text-container">
            <p>
                {
                    <ReadMore>
                        {longText}
                    </ReadMore>
                }
            </p>
        </div>
    );
};

export default ReadMoreContent;
