import { Row, Col, Card, CardBody } from 'reactstrap';

const TestAuth = () => {

    return (
        <div>
            <h2>TestAuth</h2>
            <Row xs="12">
                <Col>
                    <Card>
                        <CardBody>
                            <p>Admin Authorized!</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default TestAuth