import { useState, useMemo } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Button, Collapse, Table, FormGroup, Label, Input } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaChevronRight, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import uuid from 'react-uuid';
import moment from "moment";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import TotalFarmDetails from '../AllFarmsSummary/TotalFarmDetails';
import MapView from '../Map/MapView';

const BoundarySummary = ({ farmsList, tempFarmsList, totalFarmDetails, itemModel, handleWizardFarmChanged, userId }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const [name, setName] = useState('');
    const [farmItems, setFarmItems] = useState(farmsList);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = farmItems.slice(indexOfFirstItem, indexOfLastItem);
    const [isHidePin, setIsHidePin] = useState(false); 
    const toggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: true, isFarmCenterEditable: false, isAllFarmDetails: true,
        isFarmOutlineEditable: false, zoomLevel: 5, polytype: '', itemsEditable: false, highlighteditemId: 0, isAllFarmSummary: true, isClear:false
    });
    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={itemModel}
                mapDetails={mapDetails}
            ></MapView>
        )
    }, [itemModel, mapDetails])
    const handleSetFarm = async (e, id) => {
        e.preventDefault();
        const farmId = parseInt(id);
        try {
            const response = await axios.get(
                '/api/settings/getFarmDetail/', {
                params: { farmId: farmId }
            }
            );
            localStorage.setItem("defaultFarmId", JSON.stringify(id));
            localStorage.setItem("defaultFarmName", JSON.stringify(response.data.name));
            localStorage.setItem("defaultFarmUniqueId", JSON.stringify(response.data.uniqueIdentifier));
            handleWizardFarmChanged(farmId);
        } catch (err) {
            if (err?.response) {
                toast.error("No server response", { duration: 5000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
            }
        }
    }

    const handleChangeName = async (event) => {
        setName(event.target.value);
        if (event.target.value == '') {
            setFarmItems(tempFarmsList);
        }
        else {
            var list = [];
            list = tempFarmsList.filter(x => (x.name.toLowerCase().includes(event.target.value.toLowerCase()) || x.uniqueIdentifier.toLowerCase().includes(event.target.value.toLowerCase())));
            setFarmItems(list);
        }
    }

    const exportFarmListToExcel = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportExcel();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportExcel = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportFarmListExcel/', {
            params: { UserId: userId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/vnd.ms-excel" })

            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OrchardList.xlsx";
            document.body.appendChild(link)
            link.click()
        }

    }

    const exportFarmListToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleExportReportPdf();
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleExportReportPdf = async (e) => {
        const response = await axios.get(
            '/api/allFarmsSummary/GenerateReportFarmListPDF/', {
            params: { userId: userId }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "OrchardList.pdf";
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleHidePin = (e) => {
        setIsHidePin(!isHidePin)
        setMapDetails({ ...mapDetails, isCenterReq: !e.target.checked, isClear: true })
    };

    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={toggle}>
                            <Row>
                                <Col sm="11">
                                    <h6>Boundary Summary
                                        <Button style={{ float: "right", background: "none", border: "none" }} onClick={(e) => exportFarmListToExcel(e)} value="Export" placeholder="">
                                            <img src="/images/Excel_icon.png" className="card_icon" ></img>
                                        </Button>

                                        <Button style={{ float: "right", background: "none", border: "none", marginRight: "-20px" }} onClick={(e) => exportFarmListToPdf(e)} value="Export" placeholder="">
                                            <img src="/images/PDF_icon.png" className="card_icon" ></img>
                                        </Button>
                                    </h6>
                                </Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    <Row>
                                        <Col sm="8">
                                            <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th># Total Orchards</th>
                                                        <th>Total HA</th>
                                                        <th>Total KM perimeter</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr key={uuid()}>
                                                        <td>{totalFarmDetails.totalFarms} </td>
                                                        <td>{totalFarmDetails.totalFarmArea.toFixed(2)}</td>
                                                        <td>{totalFarmDetails.totalFarmPerimeter.toFixed(2)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col sm="2">

                                        </Col>
                                        <Col sm="2">
                                            <br />
                                            <br />
                                            <Input type="checkbox" checked={isHidePin} onChange={(e) => handleHidePin(e)} name="isHidePin" id="isHidePin" />&nbsp;
                                            <Label for="name">Hide Pin</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {mapView}
                                    </Row>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default BoundarySummary;