import React, { useState } from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import './Lmu.css';

const AdditionalInformation = ({ form, isSameAsMaster, handleFormFieldChange, }) => {

    return (

        <div>
            <Row className="my-3">
                {!isSameAsMaster && 
                    <Col sm="6">
                        <FormGroup>
                            <Label
                                for="topographyId"
                            >
                                Topography
                            </Label>
                            <Input
                                bsSize="sm"
                                type="select"
                                value={form.topographyId}
                                onChange={handleFormFieldChange}
                                name="topographyId"
                                id="topographyId"
                            >
                                <option value={0} >Select Topography</option>
                                {form.topographyList.map(c =>
                                    <option key={c.id} value={c.id}>{c.value}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>    
                }
                <Col sm="6">
                    <FormGroup>
                        <Label
                            for="topographyId"
                        >
                            Irrigation
                        </Label>
                        <br />
                        <Input style={{ marginRight: "10px" }}
                            defaultChecked={form.isReceivesFreshWaterIrrigation}
                            checked={form.isReceivesFreshWaterIrrigation}
                            id="isReceivesFreshWaterIrrigation"
                            name="isReceivesFreshWaterIrrigation"
                            type="checkbox"
                            value={form.isReceivesFreshWaterIrrigation}
                            onChange={handleFormFieldChange}
                        /> If this Land Unit has irrigation, please select
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label
                            for="topographyId"
                        >
                            Frost Protection 
                        </Label>
                        <br />
                        <Input style={{ marginRight: "10px" }}
                            defaultChecked={form.useWaterForFrostProtection}
                            checked={form.useWaterForFrostProtection}
                            id="useWaterForFrostProtection"
                            name="useWaterForFrostProtection"
                            type="checkbox"
                            value={form.useWaterForFrostProtection}
                            onChange={handleFormFieldChange}
                        /> Does this land unit use water for frost protection?
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label
                            for="topographyId"
                        >
                            Agrichemical Spraying
                        </Label>
                        <br />
                        <Input style={{ marginRight: "10px" }}
                            defaultChecked={form.applyAgrichemicalsToLandUnit}
                            checked={form.applyAgrichemicalsToLandUnit}
                            id="applyAgrichemicalsToLandUnit"
                            name="applyAgrichemicalsToLandUnit"
                            type="checkbox"
                            value={form.applyAgrichemicalsToLandUnit}
                            onChange={handleFormFieldChange}
                        /> Do you apply agrichmicals to this land unit?
                    </FormGroup>
                </Col>
            </Row>
            {!isSameAsMaster && <>
                <Row>
                    <Col sm="4">
                        <FormGroup>
                            <Label
                                for="soilTypePredominantId"
                            >
                                Soil Type (Predominant)
                            </Label>
                            <Input
                                bsSize="sm"
                                type="select"
                                value={form.soilTypePredominantId}
                                onChange={handleFormFieldChange}
                                name="soilTypePredominantId"
                                id="soilTypePredominantId"
                            >
                                <option value={0} >Select Soil Type</option>
                                {form.soilTypePredominantList.map(c =>
                                    <option key={c.id} value={c.id}>{c.value}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="soilTypePredominantPercentage"> Soil Type % (Predominant) </Label>
                            <input className="form-control-sm form-control"
                                type="text"
                                value={form.soilTypePredominantPercentage}
                                onChange={handleFormFieldChange}
                                name="soilTypePredominantPercentage"
                                id="soilTypePredominantPercentage"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="drainabilityPredominant">Drainability</Label>
                            <Input
                                bsSize="sm"
                                value={form.drainabilityPredominant}
                                onChange={handleFormFieldChange}
                                id="drainabilityPredominant"
                                name="drainabilityPredominant"
                                type="select"
                            >
                                <option value={0} >Select Drainability</option>
                                {form.drainabilityPredominantList?.map(c =>
                                    <option key={c.id} value={c.id}>{c.value}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <FormGroup>
                            <Label
                                for="soilTypeOtherId"
                            >
                                Soil Type (Other)
                            </Label>
                            <Input
                                bsSize="sm"
                                type="select"
                                value={form.soilTypeOtherId}
                                onChange={handleFormFieldChange}
                                name="soilTypeOtherId"
                                id="soilTypeOtherId"
                            >
                                <option value={0} >Select Soil Type</option>
                                {form.soilTypeOtherList.map(c =>
                                    <option key={c.id} value={c.id}>{c.value}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="soilTypeOtherPercentage" > Soil Type % (Other) </Label>
                            <input className="form-control-sm form-control"
                                type="text"
                                value={form.soilTypeOtherPercentage}
                                onChange={handleFormFieldChange}
                                name="soilTypeOtherPercentage"
                                id="soilTypeOtherPercentage"
                            />
                            {/*<Input*/}
                            {/*    type="text"*/}
                            {/*    name="soilTypeOtherPercentage"*/}
                            {/*    id="soilTypeOtherPercentage"*/}
                            {/*    bsSize="sm" value={form.soilTypeOtherPercentage}*/}
                            {/*    onChange={handleFormFieldChange}*/}
                            {/*/>*/}
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="drainabilityOther">Drainability</Label>
                            <Input
                                bsSize="sm"
                                value={form.drainabilityOther}
                                onChange={handleFormFieldChange}
                                id="drainabilityOther"
                                name="drainabilityOther"
                                type="select"
                            >
                                <option value={0} >Select Drainability</option>
                                {form.drainabilityOtherList.map(c =>
                                    <option key={c.id} value={c.id}>{c.value}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ display: "none" }}>
                    <Col sm="6">
                        <FormGroup check>
                            <Label
                                check
                                for="isNonEffectiveArea"
                            >
                                Non-effective area?
                            </Label>
                            <Input
                                defaultChecked={form.isNonEffectiveArea}
                                id="isNonEffectiveArea"
                                name="isNonEffectiveArea"
                                type="checkbox"
                                value={form.isNonEffectiveArea}
                                onChange={(e) => handleFormFieldChange(e)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        {!form.isNonEffectiveArea &&
                            <FormGroup>
                                <Label for="effectiveArea">Effective (ha)</Label>
                                <Input
                                    disabled={false}
                                    type="text"
                                    name="effectiveArea"
                                    id="effectiveArea"
                                    bsSize="sm"
                                    value={form.effectiveArea}
                                    onChange={(e) => handleFormFieldChange(e)}
                                />
                            </FormGroup>
                        }
                    </Col>
                </Row>
                <Row style={{ display: "none" }}>
                    <Col sm="6">
                        <FormGroup check>
                            <Label
                                check
                                for="isLeasedBlock"
                            >
                                Leased or contains leased area?
                            </Label>
                            <Input
                                defaultChecked={form.isLeasedBlock}
                                id="isLeasedBlock"
                                name="isLeasedBlock"
                                type="checkbox"
                                value={form.isLeasedBlock}
                                onChange={(e) => handleFormFieldChange(e)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        {form.isLeasedBlock == true &&
                            <FormGroup>
                                <Label for="leasedAreaHa">Leased area (ha)</Label>
                                <Input
                                    disabled={false}
                                    type="text"
                                    name="leasedAreaHa"
                                    id="leasedAreaHa"
                                    bsSize="sm"
                                    value={form.leasedAreaHa}
                                    onChange={(e) => handleFormFieldChange(e)}
                                />
                            </FormGroup>
                        }
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm="6" style={{ display: "none" }}>
                        <FormGroup check>
                            <Label
                                check
                                for="isReceivesLiquidEffluent"
                            >
                                Receives Liquid Effluent?
                            </Label>
                            <Input
                                defaultChecked={form.isReceivesLiquidEffluent}
                                checked={form.isReceivesLiquidEffluent}
                                id="isReceivesLiquidEffluent"
                                name="isReceivesLiquidEffluent"
                                type="checkbox"
                                value={form.isReceivesLiquidEffluent}
                                onChange={handleFormFieldChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>}
            

            {isSameAsMaster != true &&
                <>
                    <Row>
                        <Col sm="4" style={{ display: "none" }}>
                            <FormGroup>
                                <Label
                                    for="stemCountPerHa"
                                >
                                    Stem Count per Ha
                                </Label>
                                <Input
                                    type="text"
                                    name="stemCountPerHa"
                                    id="stemCountPerHa"
                                    bsSize="sm"
                                    value={form.stemCountPerHa}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    
                </>
            }
        </div>
    )
}

export default AdditionalInformation