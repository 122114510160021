import { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, Spinner } from 'reactstrap';
import { FaChevronRight, FaSlash, FaChevronLeft } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import './Waterways.css';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import ItemCommonTop from '../ItemPopup/ItemCommonTop';
import ItemCommonBottom from '../ItemPopup/ItemCommonBottom';
import ItemFooter from '../ItemPopup/ItemFooter';
import Action from '../Action/Action';
import MapView from '../Map/MapView';
import NonMapView from '../ItemsView/NonMapView';
import MaintenancePlan from '../MaintenancePlan/MaintenancePlan';
import WaterwaysItemSpecView from './WaterwaysItemSpecView'
import Validate from '../Validate/Validate';
import ToggleSwitch from '../ThreeStateToggle/ToggleSwitch';
import { useNavigate, useLocation } from "react-router-dom";
import RiskSummary from '../ItemsView/RiskSummary';
import ItemTypeListViewForMap from '../ItemsView/ItemTypeListViewForMap';
import Draggable, { DraggableCore } from 'react-draggable';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Waterways = ({ handleWizardChanged, handleWizardChangedBack, handleMoveToNextPage }) => {
    const roleName = localStorage.getItem("roleName"); const { height, width } = useWindowDimensions(); const [isThisPageCompleted, setIsThisPageCompleted] = useState(false); const [actionsCount, setActionsCount] = useState(0);
    const saveRightsForNonItemFieldsAndTables = localStorage.getItem("saveRightsForNonItemFieldsAndTables");
    const [searchParams, setSearchParams] = useSearchParams(); const [toggleSwitch, setToggleSwitch] = useState('map & details');
    const { state } = useLocation(); const [saving, setSaving] = useState(''); const [itemDeletesaving, setItemDeleteSaving] = useState('');
    const navigate = useNavigate(); const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [formErrors, setFormErrors] = useState({ FencingPercentage: '' });
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [loading, setLoading] = useState(true);
    const [waterway, setWaterway] = useState({
        outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], Section: 'Waterways', SectionName: 'Waterways',
        itemFieldTypes: [], waterwayWidthList: [], percentageFencedList: [], averageRiparianMarginPlantedList: [], SectionObj: {}, isWizardCompleted: false, percentageOfLengthRiparianPlantedList: [],
        outlineCenter: {}, farmCenterTypeModel: {}, outlineItemTypes: [], riparianPlantingList:[]
    });
    const [largeEnterpriseDetails, setLargeEnterpriseDetails] = useState({ isLargeEnterprise: false, LMUList: [], selectedLMUId: 0, isSmallEnterpriseShow: false })
    const [gisLayerDetails, setGISLayerDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [gisLayerStockExclusionLandSlopeDetails, setGISLayerStockExclusionLandSlopeDetails] = useState({ showGISLayer: false, gisLayerList: [] })
    const [typeid, setTypeId] = useState(0);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: false, isFarmCenterEditable: false,
        isFarmOutlineEditable: false, zoomLevel: 15, polyType: '', itemsEditable: false, highlighteditemId: 0, isClear: false, showLabel: false
    });
    const [riskLookupList, setRiskLookupList] = useState({ enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
    const [itemPopup, setItemPopup] = useState({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
    const [itemPopupHeader, setItemPopupHeader] = useState({
        page: 'Waterways', lmu: '', LMUId: 0, itemTypeName: '',
        itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", isEdited: false, itemTypeDescription: '', LMUIds: ''
    });
    const [itemPopupMaptype, setItemPopupMaptype] = useState({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', radius: 0.0 });
    const [alertPopup, setAlertPopup] = useState({ showAlertPopup: false, message: '', alertId: 0, isDisregarded: false, disregardDescription: '', alertName: '' });
    const [alertList, setAlertList] = useState([]);
    const [itemPopupBiodiversity, setItemPopupBiodiversity] = useState({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
    const [itemPopupRisk, setItemPopupRisk] = useState({
        isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '',
        showEnterprise: false
    });
    const [itemPopupAchievements, setItemPopupAchievements] = useState({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });

    const [itemDeletePopup, setItemDeletePopup] = useState({ showItemDeletePopup: false, itemId: 0 });
    const [actionPopup, setActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [wholeFarmActionPopup, setWholeFarmActionPopup] = useState({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    const [maintenancePlanPopup, setMaintenancePlanPopup] = useState({ showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });

    const [itemPopupItemField, setItemPopupItemField] = useState({
        itemTypeId: 0,
        itemFieldTypes: [],itemTypeName:''
    });
    const [itemPopupItemSepcQ, setItemPopupItemSepcQ] = useState({
        IsBoundaryPresent: false,
        IsFenced: false,
        FencingPercentage: 0,
        IsRiparianPlanted: false,
        RiprianAvg: 0,
        WaterwayWidth: 0,
        BridgedWaterCrossingsControls: '',
        CulvertedCrossingsControls: '',
        IsFreshwaterSiteofSignificance: false,
        StemCountPerHA: 0, IsFishScreen: false, PercentageOfLengthRiparianPlanted: 0, RiparianPlanting:''
    });

    const [toggleItemTypeListState, setToggleItemTypeListState] = useState({ isOpen: false, isIconUp: false });
    const [toggleItemTypeState, setToggleItemTypeState] = useState({ itemTypeId: 0, isOpen: false, isIconUp: false, uniqueItemTypeName: "" });
    const [toggleItemState, setToggleItemState] = useState({ itemId: 0, isOpen: false, isIconUp: false });
    const [riskSummaryPopup, setRiskSummaryPopup] = useState({ showriskSummary: false });

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/waterways/GetWaterway/', {
                params: {
                    farmId: farmId
                }
            }
            );
            setWaterway({
                ...waterway, outlineList: response.data.outlineList, itemList: response.data.itemList, itemTypes: response.data.itemTypes, uniqueItemTypes: response.data.uniqueItemTypes, itemFieldTypes: response.data.itemFieldList
                , waterwayWidthList: response.data.waterwayWidthList, riparianPlantingList: response.data.riparianPlantingList,
                percentageFencedList: response.data.percentageFencedList, averageRiparianMarginPlantedList: response.data.averageRiparianMarginPlantedList,
                SectionObj: response.data.section, isWizardCompleted: response.data.isFWFPCompleted, percentageOfLengthRiparianPlantedList: response.data.percentageOfLengthRiparianPlantedList, outlineCenter: response.data.outlineCenter, farmCenterTypeModel: response.data.farmCenterTypeModel
                , outlineItemTypes: response.data.outlineItemTypes
            });
            setRiskLookupList({
                ...riskLookupList, enterpriseTypes: response.data.riskModel.enterpriseTypes, luenterpriseTypes: [], contaminants: response.data.riskModel.contaminants, riskDataGroups: response.data.riskModel.riskDataGroups
                , activityGroups: response.data.riskModel.activityGroups, activitySubGroups: [], tempActivitySubGroups: response.data.riskModel.activitySubGroups, LUEnterpriseTypes: response.data.riskModel.luEnterpriseTypeList
            })
            setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: !response.data.isFWFPCompleted, isIconUp: !response.data.isFWFPCompleted })
            setGISLayerDetails({ ...gisLayerDetails, gisLayerList: response.data.gisLayerList });
            setGISLayerStockExclusionLandSlopeDetails({ ...gisLayerStockExclusionLandSlopeDetails, gisLayerList: response.data.gisLayerList_StockExclusionLandSlope });
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel, polyType: '' });
            setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isLargeEnterprise: response.data.islargeEnterprise, LMUList: response.data.lmuItemList })
            if (response.data.section != null) {
                var section = window.wizardDetails.filter(x => x.controllerName == response.data.section.routePath);
                if (section.length > 0 != null) {
                    setIsNextButtonVisible(section[0].isSectionCompleted);
                }
            }
            if (response.data.wizard.isWaterwaysCrossingsSaved) {
                setIsThisPageCompleted(true);
            }
            else {
                setIsThisPageCompleted(false);
            }
            setActionsCount(response.data.actionCount);
            setLoading(false);

            if (state != null) {
                const { itemId, actionId, navigateFrom } = state;
                navigate(state.pathname, { replace: true });
                if (navigateFrom == "Actions") {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
                else {
                    editMapItemFromNavigation(itemId, response.data, navigateFrom)
                }
            }
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let navigateFrom = params.get('navigateFrom');
            if (navigateFrom == 'Actions') {
                let actionId = params.get('actionId');
                let itemId = params.get('itemId');
                let isWholeFarmAction = params.get('isWholeFarmAction');
                if (searchParams.has('actionId')) {
                    searchParams.delete('actionId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('itemId')) {
                    searchParams.delete('itemId');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('isWholeFarmAction')) {
                    searchParams.delete('isWholeFarmAction');
                    setSearchParams(searchParams);
                }
                if (searchParams.has('navigateFrom')) {
                    searchParams.delete('navigateFrom');
                    setSearchParams(searchParams);
                }
                if (isWholeFarmAction == 'true') {
                    handleNavigateOpenWholeActionPopup(itemId, actionId, response.data);
                }
                else {
                    handleNavigateOpenActionPopup(itemId, actionId, response.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleNavigateOpenActionPopup = (itemId, actionId, waterway) => {
        const itemDetails = waterway.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleNavigateOpenWholeActionPopup = (itemId, actionId, waterway) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: waterway.outlineCenter.itemTypeId, itemName: 'Orchard Centre', actionId: actionId });
    };

    function refreshPage() {
        setLoading(true);
        setRiskLookupList({ ...riskLookupList, enterpriseTypes: [], luenterpriseTypes: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], tempActivitySubGroups: [], LUEnterpriseTypes: [] });
        setFormErrors({});
        setWaterway({ ...waterway, outlineList: [], itemList: [], itemTypes: [], uniqueItemTypes: [], itemFieldTypes: [], waterwayWidthList: [], riparianPlantingList: [], averageRiparianMarginPlantedList: [], percentageFencedList: [], percentageOfLengthRiparianPlantedList: [] });
        OnPageload();
    }

    function resetValues() {
        setItemPopup({ showItemPopup: false, mapitem: [], farmId: farmId, id: 0, itemId: 0, markertype: '', canArchive: true });
        setItemPopupHeader({ page: 'Waterways', lmu: '', itemTypeName: '', itemTypeId: 0, Name: '', isDragged: false, Description: '', color: "#21b546", itemTypeDescription: '', LMUIds: '' });
        setItemPopupMaptype({ markertype: '', area: 0.0, perimeter: 0.0, coordinates: '', radius: 0.0 });
        setItemPopupBiodiversity({ isVisible: false, isItemToBiodiversity: false, biodiversityComment: '' });
        setItemPopupRisk({
            isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: ''
        });
        setItemPopupAchievements({ isAchievementsNotes: false, achievementsComment: '', isExpand: false });
        setItemDeletePopup({ showItemdeletePopup: false, itemId: 0 });
        setFormErrors({});
        setMapDetails({ ...mapDetails, isClear: true, polyType: '' });
    }

    /* Item select  and Clear*/
    const handleItemClick = (event, typeid, polyType) => {
        setTypeId(typeid);
        setMapDetails({ ...mapDetails, polyType: polyType, isClear: false });
        const itemFieldTypesList = waterway.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == typeid);
        var itemTypeName = '';
        if (waterway.itemTypes.filter(x => x.id == typeid).length > 0) {
            itemTypeName = waterway.itemTypes.filter(x => x.id == typeid)[0].name;
        }
        setItemPopupItemField({ ...itemPopupItemField, itemTypeId: typeid, itemFieldTypes: itemFieldTypesList, itemTypeName: itemTypeName });
    }

    function handleClearSelection() {
        setTypeId(0);
        setMapDetails({ ...mapDetails, polyType: '', isClear: true });
        setItemPopupItemField({ ...itemPopupItemField, itemTypeId: 0, itemFieldTypes: [], itemTypeName: '' });
    }

    const handleLMUChange = (event) => {
        var lus = '';
        let luArr = [];
        if (typeof event === 'object' && event.constructor === Object) {
            lus = event.value.toString();
        }
        else {
            event.forEach(lmu => {
                luArr.push(lmu.value);
            })
            lus = luArr.join(",");
        }
        setItemPopupHeader({ ...itemPopupHeader, LMUIds: lus });
    }

    /*ItemPopup value change Handle*/
    function handleItemPopupHeaderChange(event) {
        const { name, value, checked, type } = event.target;
        setFormErrors({});
        setItemPopupHeader({ ...itemPopupHeader, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupBiodiversity(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, [name]: type == 'checkbox' ? checked : value });
    }

    function handleItemPopupRisk(event) {
        const { name, value, checked, type } = event.target;
        if (name == 'isRisk' && value == 'Yes') {
            setFormErrors({});
            const errors = {};
            if (itemPopupHeader.LMUId == 0) {
                errors.luid = "Please select LU";
                setFormErrors(errors);
            }
            else {
                setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
            }
        }
        else if (name == 'isRisk' && value == 'No') {
            setItemPopupRisk({ ...itemPopupRisk, isRisk: 'No', riskComment: '', enterprises: [], contaminants: [], riskDataGroups: [], activityGroups: [], activitySubGroups: [], likelihood: '', impact: '' });
        }
        else {
            setItemPopupRisk({ ...itemPopupRisk, [name]: type == 'checkbox' ? checked : value });
        }
    }

    const handleMultiSelectEnterpriseChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, enterprises: e });
    }

    const handleMultiSelectContaminantChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, contaminants: e });
    }

    const handleMultiSelectRiskDataGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, riskDataGroups: e });
    }

    const handleMultiSelectActivityGroupChange = (e) => {

        setRiskLookupList({
            ...riskLookupList, activitySubGroups: []
        })

        var selectedActivitySubGroups = [];
        let myArrayFiltered = [];
        if (itemPopupRisk.activitySubGroups != undefined && itemPopupRisk.activitySubGroups.length > 0) {
            selectedActivitySubGroups = itemPopupRisk.activitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        else {
            myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                return e.some((f) => {
                    return f.value === el.parentId;
                });
            });
        }
        setRiskLookupList({
            ...riskLookupList, activitySubGroups: myArrayFiltered
        })
        setItemPopupRisk({ ...itemPopupRisk, activityGroups: e, activitySubGroups: selectedActivitySubGroups });
    }

    const handleMultiSelectActivitySubGroupChange = (e) => {
        setItemPopupRisk({ ...itemPopupRisk, activitySubGroups: e });
    }

    function handleItemPopupAchievements(event) {
        const { name, value, checked, type } = event.target;
        setItemPopupAchievements({ ...itemPopupAchievements, [name]: type == 'checkbox' ? checked : value });
    }

    const handleItemSepcValueChange = (event) => {
        const { name, value, checked, type } = event.target;
        setItemPopupItemSepcQ({ ...itemPopupItemSepcQ, [name]: type == 'checkbox' ? checked : value });
        if (name == 'FencingPercentage' && value == '136') {
            setItemPopupHeader({
                ...itemPopupHeader, color: '#0000FF'
            });
        }
    }

    const handleLMUSelectChange = (event) => {
        const { name, value, checked, type } = event.target;
        setLargeEnterpriseDetails({ ...largeEnterpriseDetails, [name]: type == 'checkbox' ? checked : value });
    }

    /*Item Add and Submit*/
    function addMapItem(mapItem, maptype) {
        let path, area = 0.0, perimeter = 0.0, coordinates = '', radius = 0.0;
        var selectedItemType = waterway.itemTypes.filter(item => item.id == typeid)[0];
        const ItemTypeName = selectedItemType.name;
        const isBiodiversityReq = selectedItemType.isBiodiversityReq == true ? true : false;
        let lmuId = largeEnterpriseDetails.selectedLMUId;
        const defaultColour = selectedItemType.defaultColour != null && selectedItemType.defaultColour != '' ? selectedItemType.defaultColour : "#21b546";

        /***********LMU Identification*************/
        if (maptype == 'marker') {
            largeEnterpriseDetails.LMUList.map((obj) => {
                const outlineCoordinates = obj.mapCoordinates.split(':');
                const outlinepositions = [];
                outlineCoordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    outlinepositions.push(pos);
                })
                const outlinePolygon = new window.google.maps.Polygon({
                    paths: outlinepositions
                });
                const pos = { lat: mapItem.getPosition().lat(), lng: mapItem.getPosition().lng() }

                const inside = window.google.maps.geometry.poly.containsLocation(
                    pos,
                    outlinePolygon
                ) ? true : false;
                if (inside) {
                    lmuId = obj.id;
                }
            });
        }
        /***********LMU Identification*************/

        if (maptype == 'marker') {

            coordinates = mapItem.getPosition().lat().toFixed(6) + ", " + mapItem.getPosition().lng().toFixed(6);
        }
        else if (maptype == 'polygon') {
            path = mapItem.getPath();
            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'polyline') {
            path = mapItem.getPath();
            area = 0;
            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            var PolygonCoords = "";
            path.getArray().map((pointCo) => {

                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            coordinates = PolygonCoords;
        }
        else if (maptype == 'circle') {
            coordinates = mapItem.getCenter().lat().toFixed(6) + ", " + mapItem.getCenter().lng().toFixed(6);
            radius = mapItem.radius.toFixed(2);
            const Pi = 3.14;
            area = ((radius * radius * Pi) / 10000).toFixed(1);
            perimeter = ((2 * Pi * radius) / 1000).toFixed(1);
        }
        let selectedLUEnterpriseTypes = [];
        if (lmuId != 0) {
            var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == lmuId);
            if (LUEnterpriseTypes.length != 0) {
                let LUEnterpriseTypesArray = [];

                LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                    return LUEnterpriseTypesArray.includes(String(el.value))
                });
                if (selectedLUEnterpriseTypes.length == 0) {
                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return el.label == 'N/A'
                    });
                }
            }
        }

        setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes })

        setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: 0, itemId: 0, markertype: maptype, canArchive: true });
        setItemPopupMaptype({ ...itemPopupMaptype, markertype: maptype, area: area, perimeter: perimeter, coordinates: coordinates, radius: radius });
        setItemPopupHeader({ ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: typeid, LMUId: lmuId, LMUIds: lmuId.toString(), Name: '', isDragged: false, Description: '', color: defaultColour, isEdited: false, itemTypeDescription: selectedItemType.itemDescription });
        setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq });
        setItemPopupAchievements({ ...itemPopupAchievements, isExpand: false });
        setItemPopupItemSepcQ({
            ...itemPopupItemSepcQ, IsBoundaryPresent: false, IsFenced: false, FencingPercentage: 0, IsRiparianPlanted: false, RiprianAvg: 0, WaterwayWidth: 0, BridgedWaterCrossingsControls: '',
            CulvertedCrossingsControls: '', IsFreshwaterSiteofSignificance: false, StemCountPerHA: 0, IsFishScreen: false, PercentageOfLengthRiparianPlanted:0
        });
    }

    const handleItemSubmit = async (event) => {
        setFormErrors({});
        setSaving(true);
        const errors = {};
        let enterprises = '';
        let contaminants = '';
        let riskDataGroups = '';
        let activityGroups = '';
        let activitySubGroups = '';

        if (itemPopupRisk.isRisk == "Yes") {
            if (itemPopupRisk.riskComment == null || itemPopupRisk.riskComment == undefined || itemPopupRisk.riskComment == "") {
                errors.riskComment = "This is mandatory!";
            }
            if (Array.isArray(itemPopupRisk.enterprises)) {
                let enterprisesArr = [];
                itemPopupRisk.enterprises.forEach(risk => {
                    enterprisesArr.push(risk.value);
                })
                enterprises = enterprisesArr.join(",");
            } else {
                enterprises = itemPopupRisk.enterprises.toString();
            }
            if (enterprises == null || enterprises == undefined || enterprises == "") {
                errors.enterprises = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.contaminants)) {
                let contaminantsArr = [];
                itemPopupRisk.contaminants.forEach(risk => {
                    contaminantsArr.push(risk.value);
                })
                contaminants = contaminantsArr.join(",");
            } else {
                contaminants = itemPopupRisk.contaminants.toString();
            }
            if (contaminants == null || contaminants == undefined || contaminants == "") {
                errors.contaminants = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.riskDataGroups)) {
                let riskDataGroupsArr = [];
                itemPopupRisk.riskDataGroups.forEach(risk => {
                    riskDataGroupsArr.push(risk.value);
                })
                riskDataGroups = riskDataGroupsArr.join(",");
            } else {
                riskDataGroups = itemPopupRisk.riskDataGroups.toString();
            }
            if (riskDataGroups == null || riskDataGroups == undefined || riskDataGroups == "") {
                errors.riskDataGroups = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.activityGroups)) {
                let activityGroupsArr = [];
                itemPopupRisk.activityGroups.forEach(risk => {
                    activityGroupsArr.push(risk.value);
                })
                activityGroups = activityGroupsArr.join(",");
            } else {
                activityGroups = itemPopupRisk.activityGroups.toString();
            }
            if (activityGroups == null || activityGroups == undefined || activityGroups == "") {
                errors.activityGroups = "This is mandatory!";
            }

            if (Array.isArray(itemPopupRisk.activitySubGroups)) {
                let activitySubGroupsArr = [];
                itemPopupRisk.activitySubGroups.forEach(risk => {
                    activitySubGroupsArr.push(risk.value);
                })
                activitySubGroups = activitySubGroupsArr.join(",");
            } else {
                activitySubGroups = itemPopupRisk.activitySubGroups.toString();
            }
            if (activitySubGroups == null || activitySubGroups == undefined || activitySubGroups == "") {
                errors.activitySubGroups = "This is mandatory!";
            }

            if (itemPopupRisk.likelihood == null || itemPopupRisk.likelihood == undefined || itemPopupRisk.likelihood == "") {
                errors.likelihood = "This is mandatory!";
            }
            if (itemPopupRisk.impact == null || itemPopupRisk.impact == undefined || itemPopupRisk.impact == "") {
                errors.impact = "This is mandatory!";
            }
        }
        if (itemPopupHeader.LMUIds == null || itemPopupHeader.LMUIds == '' || itemPopupHeader.LMUIds == undefined || itemPopupHeader.LMUIds == '0') {
            errors.luid = "This is mandatory!";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            let form = {
                Id: itemPopup.id,
                MapMarkerType: itemPopup.markertype,
                FarmId: itemPopup.farmId,
                ItemId: itemPopup.itemId,
                Name: itemPopupHeader.Name,
                IsDragged: itemPopupHeader.isDragged,
                Description: itemPopupHeader.Description,
                Colour: itemPopupHeader.color,
                LMUId: itemPopupHeader.LMUId,
                ItemTypeId: itemPopupHeader.itemTypeId,
                Area: itemPopupMaptype.area,
                Length: itemPopupMaptype.perimeter,
                MapCoordinates: itemPopupMaptype.coordinates,
                IsAddsToBioDiversity: itemPopupBiodiversity.isItemToBiodiversity,
                BioDiversityComment: itemPopupBiodiversity.biodiversityComment,
                IsItemHaveAnyRisk: itemPopupRisk.isRisk,
                RiskDescription: itemPopupRisk.riskComment,
                IsAchievementOfNote: itemPopupAchievements.isAchievementsNotes,
                AchievementOfNoteComments: itemPopupAchievements.achievementsComment,
                IsBoundaryPresent: itemPopupItemSepcQ.IsBoundaryPresent,
                IsFenced: itemPopupItemSepcQ.IsFenced,
                FencingPercentage: itemPopupItemSepcQ.FencingPercentage,
                IsRiparianPlanted: itemPopupItemSepcQ.IsRiparianPlanted,
                RiprianAvg: itemPopupItemSepcQ.RiprianAvg,
                WaterwayWidth: itemPopupItemSepcQ.WaterwayWidth,
                BridgedWaterCrossingsControls: itemPopupItemSepcQ.BridgedWaterCrossingsControls,
                CulvertedCrossingsControls: itemPopupItemSepcQ.CulvertedCrossingsControls,
                IsFreshwaterSiteofSignificance: itemPopupItemSepcQ.IsFreshwaterSiteofSignificance,
                StemCountPerHA: itemPopupItemSepcQ.StemCountPerHA,
                userId: userId,
                IsEdited: itemPopupHeader.isEdited,
                radius: itemPopupMaptype.radius,
                IsFishScreen: itemPopupItemSepcQ.IsFishScreen,
                PercentageOfLengthRiparianPlanted: itemPopupItemSepcQ.PercentageOfLengthRiparianPlanted,
                RiparianPlanting: itemPopupItemSepcQ.RiparianPlanting,
                ActivityIds: enterprises,
                ContaminantIds: contaminants,
                RiskDataGroupIds: riskDataGroups,
                ActivityGroupIds: activityGroups,
                ActivitySubGroupIds: activitySubGroups,
                RiskLikelihood: itemPopupRisk.likelihood,
                RiskImpact: itemPopupRisk.impact,
                LMUIds: itemPopupHeader.LMUIds
            }
            const errors = {};
            var itemType = waterway.itemTypes.filter(x => x.id == itemPopupHeader.itemTypeId);
            //if (itemType[0].alertList.length > 0) {
            //    for (var i = 0; i < itemType[0].alertList.length; i++) {
            //        var alert = itemType[0].alertList[i];
            //        var savedAlert = alertList.filter(x => x.alertId == alert.id && x.itemId == itemPopup.itemId);
            //        var itemField = waterway.itemFieldTypes.filter(x => x.itemFeild == alert.itemFieldId && x.itemTypeId == itemPopupHeader.itemTypeId);
            //        if (savedAlert.length == 0) {
            //            if (alert.condition == 'NOT EQUAL TO') {
            //                if (itemField[0].itemFeildName == 'FencingPercentage') {
            //                    if (form["IsFenced"]) {
            //                        var percentageFenced = waterway.percentageFencedList.filter(x => x.id == form["FencingPercentage"]);
            //                        if (percentageFenced[0].name.replace('%', '').replace('<', '').replace('>', '') != alert.value) {
            //                            setFormErrors({ ...formErrors, FencingPercentage: alert.message });
            //                            errors.FencingPercentage = alert.message;
            //                        }
            //                        else {
            //                            setFormErrors({ ...formErrors, FencingPercentage: '' });
            //                        }
            //                    }
            //                }
            //            }
            //        }
            //        else {
            //            setFormErrors({ ...formErrors, FencingPercentage: '' });
            //        }
            //    }
            //}
            if (Object.keys(errors).length == 0 && !Validate(form)) {
                try {
                    const response = await axios.post(
                        '/api/waterways/UpdateWaterway/', JSON.stringify(form),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        });
                    if (response.data != null) {
                        if (response.data.message == 'Added') {
                            await refreshItemDetails();
                            setSaving(false);
                            toast.success("Item details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                            handleItemPopupClose();
                        }
                        else {
                            await refreshItemDetails();
                            setSaving(false);
                            toast.success("Item details saved!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                            handleItemPopupClose();
                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            else {
                setSaving(false);
            }
        }
        else {
            setSaving(false);
        }
    }

    const refreshItemDetails = async () => {
        const response = await axios.get(
            '/api/waterways/GetWaterway/', {
            params: {
                farmId: farmId
            }
        }
        );
        setWaterway({
            ...waterway, itemList: response.data.itemList, outlineCenter: response.data.outlineCenter
        });
        if (response.data.wizard.isWaterwaysCrossingsSaved) {
            setIsThisPageCompleted(true);
        }
        else {
            setIsThisPageCompleted(false);
        }
        setActionsCount(response.data.actionCount);
    }

    function handleItemPopupClose() {
        resetValues();
    }

    const handleMapItemEdited = (itemId, type, strValue) => {
        let name = '';
        let value = '';
        if (type == "cricleCenterChanges") {
            name = 'mapCoordinates';
            value = strValue.lat().toFixed(6) + ", " + strValue.lng().toFixed(6);
        }
        else if (type == "cricleRadiusChanges") {
            name = 'radius';
            value = parseFloat(strValue.toFixed(2));

        }
        else if (type == "polygonBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }
        else if (type == "polylineBoundsChanged") {
            name = 'mapCoordinates';
            var PolygonCoords = "";
            strValue.getArray().map((pointCo) => {
                PolygonCoords = PolygonCoords + pointCo.toString().replace('(', '').replace(')', '') + ":";
            })
            value = PolygonCoords;
        }

        const itemList = waterway.itemList.map(item =>
            item.itemId === itemId
                ? { ...item, [name]: value, isEdited: true }
                : item
        )
        setWaterway({ ...waterway, itemList: itemList });
    }

    /*Item Edit and Submit*/
    function editMapItem(mapItem, itemId, isDragged) {
        const itemDetails = waterway.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;
        if (isDragged) {
            coordinates = mapItem.latLng.lat().toFixed(6) + ", " + mapItem.latLng.lng().toFixed(6);
        }

        let area = 0.0, perimeter = 0.0;
        if (itemDetails.isEdited == true) {
            if (itemDetails.mapMarkerType == "circle") {
                const Pi = 3.14;
                area = ((itemDetails.radius * itemDetails.radius * Pi) / 10000).toFixed(1);
                perimeter = ((2 * Pi * itemDetails.radius) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polygon") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polyline") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                const tempPolyline = new window.google.maps.Polyline({
                    paths: positions
                });

                let path = tempPolyline.paths;
                area = itemDetails.area;
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.length;
        }

        const isBiodiversityReq = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: mapItem, farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: area, perimeter: perimeter, coordinates: coordinates, radius: itemDetails.radius });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId
                , Name: itemDetails.name, isDragged: isDragged, Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });


            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))
                    });
                    if (selectedLUEnterpriseTypes.length == 0) {
                        selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                            return el.label == 'N/A'
                        });
                    }
                }
            }

            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: false });

            const itemFieldTypesList = waterway.itemFieldTypes.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            var itemTypeName = '';
            if (waterway.itemTypes.filter(x => x.id == typeid).length > 0) {
                itemTypeName = waterway.itemTypes.filter(x => x.id == typeid)[0].name;
            }
            setItemPopupItemField({ ...itemPopupItemField, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList, itemTypeName: itemTypeName });

            setItemPopupItemSepcQ({
                IsBoundaryPresent: itemDetails.isBoundaryPresent,
                IsFenced: itemDetails.isFenced,
                FencingPercentage: itemDetails.fencingPercentage,
                IsRiparianPlanted: itemDetails.isRiparianPlanted,
                RiprianAvg: itemDetails.riprianAvg,
                WaterwayWidth: itemDetails.waterwayWidth,
                BridgedWaterCrossingsControls: itemDetails.bridgedWaterCrossingsControls,
                CulvertedCrossingsControls: itemDetails.culvertedCrossingsControls,
                IsFreshwaterSiteofSignificance: itemDetails.isFreshwaterSiteofSignificance,
                StemCountPerHA: itemDetails.stemCountPerHA,
                IsFishScreen: itemDetails.isFishScreen,
                PercentageOfLengthRiparianPlanted: itemDetails.percentageOfLengthRiparianPlanted,
                RiparianPlanting: itemDetails.riparianPlanting,
            });
            setAlertList(itemDetails.alerts);
        }
    }

    const editMapItemFromNavigation = (itemId, waterway, navigateFrom) => {
        const itemDetails = waterway.itemList.filter(items => items.itemId == itemId)[0];
        const ItemTypeName = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].name;
        const ItemDescription = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].itemDescription;
        let coordinates = itemDetails.mapCoordinates;

        let area = 0.0, perimeter = 0.0;
        if (itemDetails.isEdited == true) {
            if (itemDetails.mapMarkerType == "circle") {
                const Pi = 3.14;
                area = ((itemDetails.radius * itemDetails.radius * Pi) / 10000).toFixed(1);
                perimeter = ((2 * Pi * itemDetails.radius) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polygon") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })
                const tempPolygon = new window.google.maps.Polygon({
                    paths: positions
                });

                let path = tempPolygon.getPath();
                area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
            else if (itemDetails.mapMarkerType == "polyline") {
                const Coordinates = coordinates.split(':');
                const positions = [];
                Coordinates.filter(coor => coor != "").map((Coordinate) => {
                    const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                    positions.push(pos);
                })

                const tempPolyline = new window.google.maps.Polyline({
                    paths: positions
                });

                let path = tempPolyline.paths;
                area = itemDetails.area;
                perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
            }
        }
        else {
            area = itemDetails.area;
            perimeter = itemDetails.length;
        }

        const isBiodiversityReq = waterway.itemTypes.filter(item => item.id == itemDetails.itemTypeId)[0].isBiodiversityReq == true ? true : false;
        if (itemDetails != null) {
            setItemPopup({ ...itemPopup, showItemPopup: true, mapitem: [], farmId: farmId, id: itemDetails.id, itemId: itemDetails.itemId, markertype: itemDetails.mapMarkerType, canArchive: itemDetails.canArchive });
            setItemPopupMaptype({ ...itemPopupMaptype, markertype: itemDetails.mapMarkerType, area: area, perimeter: perimeter, coordinates: coordinates, radius: itemDetails.radius });
            setItemPopupHeader({
                ...itemPopupHeader, itemTypeName: ItemTypeName, itemTypeId: itemDetails.itemTypeId, LMUId: itemDetails.lmuId == null ? 0 : itemDetails.lmuId
                , Name: itemDetails.name, Description: itemDetails.description, color: itemDetails.colour, isEdited: itemDetails.isEdited, itemTypeDescription: ItemDescription, LMUIds: itemDetails.lmuIds == null ? '' : itemDetails.lmuIds
            });
            setItemPopupBiodiversity({ ...itemPopupBiodiversity, isVisible: isBiodiversityReq, isItemToBiodiversity: itemDetails.isAddsToBioDiversity, biodiversityComment: itemDetails.bioDiversityComment });

            let enterpriseTypes = []
            if (itemDetails.activityIds != "" && itemDetails.activityIds != null) {
                let enterpriseTypesArray = [];

                enterpriseTypesArray = itemDetails.activityIds.split(',');

                riskLookupList.enterpriseTypes.map(type => {
                    if (enterpriseTypesArray.includes(String(type.value))) {
                        enterpriseTypes.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let ContaminantIds = []
            if (itemDetails.contaminantIds != "" && itemDetails.contaminantIds != null) {
                let ContaminantIdsArray = [];

                ContaminantIdsArray = itemDetails.contaminantIds.split(',');

                riskLookupList.contaminants.map(type => {
                    if (ContaminantIdsArray.includes(String(type.value))) {
                        ContaminantIds.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let riskDataGroups = []
            if (itemDetails.riskDataGroupIds != "" && itemDetails.riskDataGroupIds != null) {
                let RiskDataGroupIdsArray = [];

                RiskDataGroupIdsArray = itemDetails.riskDataGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (RiskDataGroupIdsArray.includes(String(type.value))) {
                        riskDataGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            let activityGroups = []
            if (itemDetails.activityGroupIds != "" && itemDetails.activityGroupIds != null) {
                let ActivityGroupIdsArray = [];

                ActivityGroupIdsArray = itemDetails.activityGroupIds.split(',');

                riskLookupList.riskDataGroups.map(type => {
                    if (ActivityGroupIdsArray.includes(String(type.value))) {
                        activityGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setRiskLookupList({
                ...riskLookupList, activitySubGroups: []
            });

            let myArrayFiltered = [];
            if (activityGroups != undefined && activityGroups.length > 0) {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }
            else {
                myArrayFiltered = riskLookupList.tempActivitySubGroups.filter((el) => {
                    return activityGroups.some((f) => {
                        return f.value === el.parentId;
                    });
                });
            }

            let activitySubGroups = []
            if (itemDetails.activitySubGroupIds != "" && itemDetails.activitySubGroupIds != null) {
                let activitySubGroupIdsArray = [];

                activitySubGroupIdsArray = itemDetails.activitySubGroupIds.split(',');

                myArrayFiltered.map(type => {
                    if (activitySubGroupIdsArray.includes(String(type.value))) {
                        activitySubGroups.push({
                            value: type.value,
                            label: type.label
                        })
                    }
                })
            }

            setItemPopupRisk({
                isRisk: itemDetails.isItemHaveAnyRisk, riskComment: itemDetails.riskDescription,
                enterprises: enterpriseTypes, contaminants: ContaminantIds, riskDataGroups: riskDataGroups,
                activityGroups: activityGroups, activitySubGroups: activitySubGroups, likelihood: itemDetails.riskLikelihood, impact: itemDetails.riskImpact,
            });

            let selectedLUEnterpriseTypes = [];
            if (itemDetails.lmuId != 0) {
                var LUEnterpriseTypes = riskLookupList.LUEnterpriseTypes.filter(lu => lu.luId == itemDetails.lmuId);
                if (LUEnterpriseTypes.length != 0) {
                    let LUEnterpriseTypesArray = [];

                    LUEnterpriseTypesArray = LUEnterpriseTypes[0].enterpriseTypeList.split(',');

                    selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                        return LUEnterpriseTypesArray.includes(String(el.value))
                    });
                    if (selectedLUEnterpriseTypes.length == 0) {
                        selectedLUEnterpriseTypes = riskLookupList.enterpriseTypes.filter((el) => {
                            return el.label == 'N/A'
                        });
                    }
                }
            }

            setRiskLookupList({ ...riskLookupList, luenterpriseTypes: selectedLUEnterpriseTypes, activitySubGroups: myArrayFiltered })

            let isExpand = false;
            if (navigateFrom == "Achievements")
                isExpand = true
            setItemPopupAchievements({ ...itemPopupAchievements, isAchievementsNotes: itemDetails.isAchievementOfNote, achievementsComment: itemDetails.achievementOfNoteComments, isExpand: isExpand });

            const itemFieldTypesList = waterway.itemFieldList.filter(fieldType => fieldType.itemTypeId == itemDetails.itemTypeId);
            var itemTypeName = '';
            if (waterway.itemTypes.filter(x => x.id == typeid).length > 0) {
                itemTypeName = waterway.itemTypes.filter(x => x.id == typeid)[0].name;
            }
            setItemPopupItemField({ ...itemPopupItemField, itemTypeId: itemDetails.itemTypeId, itemFieldTypes: itemFieldTypesList, itemTypeName: itemTypeName });

            setItemPopupItemSepcQ({
                IsBoundaryPresent: itemDetails.isBoundaryPresent,
                IsFenced: itemDetails.isFenced,
                FencingPercentage: itemDetails.fencingPercentage,
                IsRiparianPlanted: itemDetails.isRiparianPlanted,
                RiprianAvg: itemDetails.riprianAvg,
                WaterwayWidth: itemDetails.waterwayWidth,
                BridgedWaterCrossingsControls: itemDetails.bridgedWaterCrossingsControls,
                CulvertedCrossingsControls: itemDetails.culvertedCrossingsControls,
                IsFreshwaterSiteofSignificance: itemDetails.isFreshwaterSiteofSignificance,
                StemCountPerHA: itemDetails.stemCountPerHA,
                IsFishScreen: itemDetails.isFishScreen,
                PercentageOfLengthRiparianPlanted: itemDetails.percentageOfLengthRiparianPlanted,
                RiparianPlanting: itemDetails.riparianPlanting,
            });
            setAlertList(itemDetails.alerts);
        }
    }

    function handleItemEditPopup(itemId) {
        editMapItem([], itemId, false)
    }

    /*Item Delete*/
    function handleItemDeletePopup(itemId) {
        setItemDeletePopup({ showItemDeletePopup: true, itemId: itemId });
    }

    function handleItemDeletePopupClose() {
        setItemDeletePopup({ showItemDeletePopup: false, itemId: 0 });
    }

    function handleAlertPopupOpen(alertName, message) {
        var itemType = waterway.itemTypes.filter(x => x.id == itemPopupHeader.itemTypeId);
        var alert = itemType[0].alertList.filter(x => x.itemFieldName == alertName && x.itemTypeId == itemPopupHeader.itemTypeId);
        setAlertPopup({ ...alertPopup, showAlertPopup: true, message: message, isDisregarded: false, disregardDescription: '', alertId: alert[0].id, alertName: alertName });
    }

    function handleAlertPopupClose() {
        setAlertPopup({ ...alertPopup, showAlertPopup: false });
    }

    const handleAlertPopupSave = async () => {
        var itemId = itemPopup.itemId;
        var alertObj = {
            id: 0, alertId: alertPopup.alertId, itemId: itemId, isDisregarded: alertPopup.isDisregarded, disregardDescription: alertPopup.disregardDescription
        };
        if (itemId != 0) {
            try {
                const response = await axios.post(
                    '/api/waterways/SaveAlert/', JSON.stringify(alertObj),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data != null) {
                    setFormErrors({ ...formErrors, [alertPopup.alertName]: '' });
                    setAlertList(response.data.content);
                    setAlertPopup({ ...alertPopup, showAlertPopup: false });
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        else {
            setFormErrors({ ...formErrors, [alertPopup.alertName]: '' });
            var alertList = [];
            var alert = {
                alertId: alertPopup.alertId, disregardDescription: alertPopup.disregardDescription, id: 0, isDisregarded: alertPopup.isDisregarded, itemId:0
            };
            alertList.push(alert);
            setAlertList(alertList);
            setAlertPopup({ ...alertPopup, showAlertPopup: false });
        }

    }

    const deleteItem = async (event) => {
        const itemid = itemDeletePopup.itemId;
        setItemDeleteSaving(true);
        try {
            const response = await axios.get(
                '/api/waterways/DeleteItem/', {
                params: {
                    itemId: itemid, userId: userId
                }
            }
            );
            if (response.data != null) {
                await refreshItemDetails();
                setItemDeleteSaving(false);
                handleItemDeletePopupClose();
                handleItemPopupClose();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /*Item- Action Popup handle*/
    function handleOpenActionPopup(itemId, actionId) {
        const itemDetails = waterway.itemList.filter(items => items.itemId == itemId)[0];
        setActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: itemDetails.itemTypeId, itemName: itemDetails.name, actionId: actionId });
    };

    const handleOpenWholeActionPopup = (itemId, actionId) => {
        setWholeFarmActionPopup({ showActionPopup: true, itemId: itemId, itemTypeId: waterway.outlineCenter.itemTypeId, itemName: 'NA', actionId: actionId });
    };

    function handleActionClose() {
        setActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    const handleWholeActionClose = () => {
        setWholeFarmActionPopup({ showActionPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*Item- MaintenancePlan Popup handle*/
    function handleOpenMaintenancePlanPopup(itemId) {
        const itemDetails = waterway.itemList.filter(items => items.itemId == itemId)[0];
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: true, itemId: itemId, itemName: itemDetails.name, itemTypeId: itemDetails.itemTypeId, actionId: 0 });
    }

    function handleMaintenancePlanClose() {
        setMaintenancePlanPopup({ ...maintenancePlanPopup, showMaintenancePlanPopup: false, itemId: 0, itemTypeId: 0, itemName: '', actionId: 0 });
    }

    /*handle Toggle for nonMap View*/
    const handleItemTypeListTogole = (isOpen, isIconUp) => {
        setToggleItemTypeListState({ ...toggleItemTypeListState, isOpen: isOpen, isIconUp: isIconUp })
    }

    const handleItemTypeTogole = (itemTypeId, isOpen, isIconUp, uniqueItemTypeName) => {
        setToggleItemTypeState({ ...toggleItemTypeState, itemTypeId: itemTypeId, isOpen: isOpen, isIconUp: isIconUp, uniqueItemTypeName: uniqueItemTypeName })
    }

    const handleItemTogole = (itemId, isOpen, isIconUp) => {
        setToggleItemState({ ...toggleItemState, itemId: itemId, isOpen: isOpen, isIconUp: isIconUp })
    }

    /*handle Item highlight*/
    const handleItemHighlight = (itemId) => {
        setMapDetails({ ...mapDetails, highlighteditemId: itemId })
    }

    /*Avoid unwanted page refresh*/

    const handleRiskSummaryClose = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: false })
        window.location.reload(true);
    }

    const handleRiskSummaryOpen = () => {
        setRiskSummaryPopup({ ...riskSummaryPopup, showriskSummary: true })
    }

    const mapView = useMemo(() => {
        return (
            <MapView
                itemModel={waterway} width={toggleSwitch == 'map' ? '80vw' : '50vw'}
                height={toggleSwitch == 'map' ? '80vh' : '60vh'}
                mapDetails={mapDetails}
                gisLayerDetails={gisLayerDetails}
                gisLayerStockExclusionLandSlopeDetails={gisLayerStockExclusionLandSlopeDetails}
                addMapItem={addMapItem}
                largeEnterpriseDetails={largeEnterpriseDetails}
                editMapItem={editMapItem}
                handleMapItemEdited={handleMapItemEdited}>
            </MapView>
        )
    }, [waterway, mapDetails, largeEnterpriseDetails, gisLayerDetails, gisLayerStockExclusionLandSlopeDetails, toggleSwitch])

    const itemTypeListView = useMemo(() => {

        return (
            <ItemTypeListViewForMap Section={waterway.Section} isWizardCompleted={waterway.isWizardCompleted}
                itemTypesList={waterway.itemTypes}
                toggleItemTypeListState={toggleItemTypeListState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemTypeListTogole={handleItemTypeListTogole}
            >
            </ItemTypeListViewForMap>
        )
    }, [waterway, largeEnterpriseDetails, waterway.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, waterway.outlineCenter])

    const nonMapView = useMemo(() => {
        return (
            <NonMapView itemModel={waterway} isWizardCompleted={waterway.isWizardCompleted}
                toggleItemTypeListState={toggleItemTypeListState}
                toggleItemTypeState={toggleItemTypeState}
                toggleItemState={toggleItemState}
                handleClearSelection={handleClearSelection}
                handleItemClick={handleItemClick}
                handleItemEditPopup={handleItemEditPopup}
                handleItemDeletePopup={handleItemDeletePopup}
                handleOpenActionPopup={handleOpenActionPopup}
                sectionObj={waterway.SectionObj}
                largeEnterpriseDetails={largeEnterpriseDetails}
                handleLMUSelectChange={handleLMUSelectChange}
                handleItemTypeListTogole={handleItemTypeListTogole}
                handleItemTypeTogole={handleItemTypeTogole}
                handleItemTogole={handleItemTogole} handleOpenWholeActionPopup={handleOpenWholeActionPopup}
                handleItemHighlight={handleItemHighlight} outlineCenter={waterway.outlineCenter}
            ></NonMapView>
        )
    }, [waterway, largeEnterpriseDetails, waterway.SectionObj, toggleItemTypeListState, toggleItemTypeState, toggleItemState, waterway.outlineCenter])

    const handleGISLayerDetails = (e) => {
        if (e.target.checked) {
            setGISLayerDetails({ ...gisLayerDetails, showGISLayer: true })
            setGISLayerStockExclusionLandSlopeDetails({ ...gisLayerStockExclusionLandSlopeDetails, showGISLayer: false })
        }
        else {
            setGISLayerDetails({ ...gisLayerDetails, showGISLayer: false })
        }

    }

    const handleGISLayerStockExclusionLandSlopeDetails = (e) => {
        if (e.target.checked) {
            setGISLayerDetails({ ...gisLayerDetails, showGISLayer: false })
            setGISLayerStockExclusionLandSlopeDetails({ ...gisLayerStockExclusionLandSlopeDetails, showGISLayer: true })
        }
        else {
            setGISLayerStockExclusionLandSlopeDetails({ ...gisLayerStockExclusionLandSlopeDetails, showGISLayer: false })
        }

    }

    const toggleSwitchChange = (e) => {
        setToggleSwitch(e)
        if (e == 'map') {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'none';
            document.getElementById('googleMap').style.height = '80vh';
            document.getElementById('googleMap').style.width = '80vw';
            document.getElementById('itemTypeListView').style.height = '70vh';
        }
        else if (e == 'details') {
            document.getElementById('mapView').style.display = 'none';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
        else {
            document.getElementById('mapView').style.display = 'block';
            document.getElementById('nonMapView').style.display = 'block';
            document.getElementById('itemTypeListView').style.display = 'none';
            document.getElementById('googleMap').style.height = '60vh';
            document.getElementById('googleMap').style.width = '50vw';
            document.getElementById('nonMapView').style.width = '50vw';
        }
    }
    const handleEditItemClick = (e) => {
        if (e.target.checked) {
            toast.error("ALERT: when editing a shape remeber to click inside the shape when finished to save any changes.", { duration: 3000, style: { border: '1px solid #f76054', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#E82300', secondary: '#FFFAEE', } });
        }
        setMapDetails({ ...mapDetails, isFarmCenterEditable: !mapDetails.itemsEditable, isFarmOutlineEditable: !mapDetails.itemsEditable, itemsEditable: !mapDetails.itemsEditable })
    }

    const [checkActionPopup, setCheckActionPopup] = useState({ showPopup: false, section: '', method: '' });
    const handleCheckActionPopupClose = () => {
        setCheckActionPopup({ showPopup: false, section: '', method: '' });
    }
    const handleCheckCalculatorDataPopupConfirm = () => {
        if (checkActionPopup.method == 'handleWizardChanged') {
            handleWizardChanged(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleMoveToNextPage') {
            handleMoveToNextPage(checkActionPopup.section);
        }
        else if (checkActionPopup.method == 'handleWizardChangedBack') {
            handleWizardChangedBack(checkActionPopup.section);
        }
    }
    function checkAction(section, method) {
        if (!isThisPageCompleted && actionsCount == 0) {
            setCheckActionPopup({ showPopup: true, section: section, method: method });
        }
        else {
            if (method == 'handleWizardChanged') {
                handleWizardChanged(section);
            }
            else if (method == 'handleMoveToNextPage') {
                handleMoveToNextPage(section);
            }
            else if (method == 'handleWizardChangedBack') {
                handleWizardChangedBack(section);
            }
        }
    }

    return (
        <div>

            <Modal isOpen={checkActionPopup.showPopup}>
                <ModalHeader toggle={handleCheckActionPopupClose} >Confirm</ModalHeader>
                <ModalBody>
                    You have not created any actions on this page. Are you sure you wish to continue?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleCheckCalculatorDataPopupConfirm} color="primary">
                        Yes
                    </Button>{' '}
                    <Button color="danger" onClick={handleCheckActionPopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col><h2>{waterway.SectionName}</h2></Col>
                <Col>
                    {waterway.isWizardCompleted != true && !loading &&
                        <>
                            {(roleName == 'Zespri Admin' || saveRightsForNonItemFieldsAndTables == 'true') &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => checkAction(waterway.Section, 'handleWizardChanged')}>Next <FaChevronRight /> </Button>
                                </>
                            }
                            {roleName != 'Zespri Admin' && saveRightsForNonItemFieldsAndTables == 'false' && isNextButtonVisible &&
                                <>
                            <Button className="float-end btn-next-page" onClick={(section) => handleMoveToNextPage(waterway.Section)}>Next <FaChevronRight /> </Button>
                                </>
                            }
                        <Button className="float-end btn-next-page" onClick={(section) => handleWizardChangedBack(waterway.Section)}><FaChevronLeft /> Back  </Button>
                        </>
                    }
                    {
                        !loading && <Button onClick={() => handleRiskSummaryOpen()} className="btn float-end btn-risk-summary">Risk and Actions summary</Button>
                    }
                </Col>
                
            </Row>
            {loading ? <SpinnerContainer /> :
                <div>
                    <Row>
                        <div style={{ textAlign:'-webkit-center'}}>
                            <Col>
                                <ToggleSwitch id="toggleSwitchId" onChange={toggleSwitchChange} values={['map', 'map & details', 'details']} selected={toggleSwitch} />
                            </Col>
                        </div>
                    </Row>
                    <Row style={{ marginTop: '35px' }}>
                        <Col id='mapView'>
                            <Row className="map-layer">
                                <Col>
                                    <Row>
                                        <Col sm="4"></Col>
                                        <Col sm="8">
                                            <div className="map-item-edit-div">
                                                <Label className="map-checkbox-label">Show LU layer</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={largeEnterpriseDetails.isSmallEnterpriseShow} onChange={(e) => setLargeEnterpriseDetails({ ...largeEnterpriseDetails, isSmallEnterpriseShow: !largeEnterpriseDetails.isSmallEnterpriseShow })} name="isSmallEnterpriseShow" id="isSmallEnterpriseShow" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Edit Item</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.itemsEditable} onChange={(e) => handleEditItemClick(e)} name="isItemEdit" id="isItemEdit" />&nbsp;&nbsp;
                                                <Label className="map-checkbox-label">Show Label</Label>
                                                <Input className="map-item-edit-checkbox" style={{}} type="checkbox" checked={mapDetails.showLabel} onChange={(e) => setMapDetails({ ...mapDetails, showLabel: !mapDetails.showLabel })} name="showLabel" id="showLabel" />&nbsp;&nbsp;
                                             </div>
                                        </Col>
                                    </Row>
                                    {mapView}
                                </Col>
                            </Row>
                        </Col>
                        <Col id="itemTypeListView" style={{ display: toggleSwitch == 'map' ? 'block' : 'none', height: "70vh", overflowY: "scroll", width: "20vw" }}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Label className="external-map-title">External Map Layers</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input className="" type="checkbox" checked={gisLayerDetails.showGISLayer} onChange={handleGISLayerDetails} name="gisLayerDetails" id="gisLayerDetails" />
                                            {'   '}<Label className="">Show External Waterways Layer</Label>
                                        </Col>
                                    </Row>
                                    <Row style={{display:"none"}}>
                                        <Col>
                                            <Input className="" type="checkbox" checked={gisLayerStockExclusionLandSlopeDetails.showGISLayer} onChange={handleGISLayerStockExclusionLandSlopeDetails} name="gisLayerStockExclusionLandSlopeDetails" id="gisLayerStockExclusionLandSlopeDetails" />
                                            {'   '}<Label className="">Show Stock Exclusion Low Slope Layer</Label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Label className="external-map-title">Waterway MAP Key</Label>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col><FaSlash color='#0000FF'></FaSlash> <span>  = 100% Fenced</span></Col>
                                    </Row>
                                    <Row >
                                        <Col><FaSlash color='#FFA500'></FaSlash> <span>  = Not Fully Fenced</span> </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            {itemTypeListView}
                            <br />
                        </Col>
                        <Col id='nonMapView' style={{ width: "50vw", display: toggleSwitch == 'map' ? 'none' : 'block', }}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Label className="external-map-title">External Map Layers</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input className="" type="checkbox" checked={gisLayerDetails.showGISLayer} onChange={handleGISLayerDetails} name="gisLayerDetails" id="gisLayerDetails" />
                                            {'   '}<Label className="">Show External Waterways Layer</Label>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: "none" }}>
                                        <Col>
                                            <Input className="" type="checkbox" checked={gisLayerStockExclusionLandSlopeDetails.showGISLayer} onChange={handleGISLayerStockExclusionLandSlopeDetails} name="gisLayerStockExclusionLandSlopeDetails" id="gisLayerStockExclusionLandSlopeDetails" />
                                            {'   '}<Label className="">Show Stock Exclusion Low Slope Layer</Label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Label className="external-map-title">Waterway MAP Key</Label>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col><FaSlash color='#0000FF'></FaSlash> <span>  = 100% Fenced</span></Col>
                                    </Row>
                                    <Row >
                                        <Col><FaSlash color='#FFA500'></FaSlash> <span>  = Not Fully Fenced</span> </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div style={{ marginTop:"10px" }}>
                                {nonMapView}
                            </div>
                            
                        </Col>
                    </Row>
                </div>
                
            }
            <Draggable bounds={{ left: -(width / 2 - 100), top: -(height / 2 - 100), right: (width / 2 - 100), bottom: (height / 2 - 100) }}>
                <Modal size="lg" isOpen={itemPopup.showItemPopup} backdrop={false} className="itemPopup">
                <Form>
                    <ModalHeader toggle={handleItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>
                        <ItemCommonTop Section={waterway.Section} handleLMUChange={handleLMUChange} itemPopupHeader={itemPopupHeader} itemTypeFieldList={itemPopupItemField.itemFieldTypes} itemPopupItemSepcQ={itemPopupItemSepcQ} itemPopupMaptype={itemPopupMaptype} itemPopupBiodiversity={itemPopupBiodiversity} itemPopupRisk={itemPopupRisk}
                            largeEnterpriseDetails={largeEnterpriseDetails} formErrors={formErrors} riskLookupList={riskLookupList}
                            handleItemPopupHeaderChange={handleItemPopupHeaderChange}
                            handleItemPopupBiodiversity={handleItemPopupBiodiversity}
                            handleItemPopupRisk={handleItemPopupRisk}
                            handleMultiSelectEnterpriseChange={handleMultiSelectEnterpriseChange}
                            handleMultiSelectContaminantChange={handleMultiSelectContaminantChange}
                            handleMultiSelectRiskDataGroupChange={handleMultiSelectRiskDataGroupChange}
                            handleMultiSelectActivityGroupChange={handleMultiSelectActivityGroupChange}
                            handleMultiSelectActivitySubGroupChange={handleMultiSelectActivitySubGroupChange}></ItemCommonTop>

                        <WaterwaysItemSpecView handleAlertPopupOpen={handleAlertPopupOpen} formErrors={formErrors} itemPopupItemField={itemPopupItemField} itemPopupItemSepcQ={itemPopupItemSepcQ}
                            handleItemSepcValueChange={handleItemSepcValueChange} waterwayWidthList={waterway.waterwayWidthList} riparianPlantingList={waterway.riparianPlantingList} percentageFencedList={waterway.percentageFencedList} averageRiparianMarginPlantedList={waterway.averageRiparianMarginPlantedList}
                            percentageOfLengthRiparianPlantedList={waterway.percentageOfLengthRiparianPlantedList}
                        >
                        </WaterwaysItemSpecView>
                        <ItemCommonBottom itemPopup={itemPopup} itemPopupAchievements={itemPopupAchievements}
                            handleItemPopupAchievements={handleItemPopupAchievements}></ItemCommonBottom>
                    </ModalBody>

                    <ItemFooter saving={saving} itemPopup={itemPopup} Section={waterway.Section}
                        handleItemDeletePopup={handleItemDeletePopup}
                        handleItemSubmit={handleItemSubmit}
                        handleItemPopupClose={handleItemPopupClose}
                        handleOpenActionPopup={handleOpenActionPopup}
                        handleOpenMaintenancePlanPopup={handleOpenMaintenancePlanPopup}>
                    </ItemFooter>
                </Form>
                </Modal>
            </Draggable>
            <Modal isOpen={itemDeletePopup.showItemDeletePopup}>
                <ModalHeader toggle={handleItemDeletePopupClose} >Delete the item</ModalHeader>
                <ModalBody>
                    Do you want to delete the item ?
                </ModalBody>
                <ModalFooter>
                    {itemDeletesaving ?
                        <>
                            <Button onClick={deleteItem} color="primary" disabled>
                                <Row>
                                    <Col sm="6">
                                        <span>
                                            {' '}Saving
                                        </span>
                                    </Col>
                                    <Col sm="6">
                                        <Spinner size="sm">
                                            Saving...
                                        </Spinner>
                                    </Col>
                                </Row></Button>{' '}
                        </>
                        :
                        <>
                            <Button onClick={deleteItem} color="primary">
                                Yes
                            </Button>{' '}
                        </>
                    }
                    <Button color="danger" onClick={handleItemDeletePopupClose}>No</Button>
                </ModalFooter>
            </Modal>

            {actionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={waterway.itemList} redirectPage="/waterways" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleActionClose} itemTypeId={actionPopup.itemTypeId} sectionName={waterway.Section} sectionId={waterway.itemTypes[0].sectionId} itemId={actionPopup.itemId} itemName={actionPopup.itemName}
                showActionPopupModal={actionPopup.showActionPopup} actionId={actionPopup.actionId} itemTypeSelect={waterway.itemTypes} itemTypeDisabled={true}></Action>}

            {wholeFarmActionPopup.showActionPopup && <Action width={width} height={height} refreshItemDetails={refreshItemDetails} itemList={waterway.itemList} redirectPage="/waterways" selectedLUId={largeEnterpriseDetails.selectedLMUId}
                handleActionClose={handleWholeActionClose} itemTypeId={wholeFarmActionPopup.itemTypeId} sectionName={waterway.Section} sectionId={waterway.itemTypes[0].sectionId} itemId={wholeFarmActionPopup.itemId} itemName={wholeFarmActionPopup.itemName}
                showActionPopupModal={wholeFarmActionPopup.showActionPopup} actionId={wholeFarmActionPopup.actionId} itemTypeSelect={waterway.outlineItemTypes} itemTypeDisabled={true} wholeFarmAction={true}></Action>}

            {maintenancePlanPopup.showMaintenancePlanPopup && <MaintenancePlan handleMaintenancePlanClose={handleMaintenancePlanClose}
                showMaintenancePlanPopupModal={maintenancePlanPopup.showMaintenancePlanPopup}
                actionId={maintenancePlanPopup.actionId} itemId={maintenancePlanPopup.itemId} itemName={maintenancePlanPopup.itemName} itemTypeId={maintenancePlanPopup.itemTypeId}
                itemTypeSelect={waterway.itemTypes} sectionName={waterway.Section} redirectPage="/waterways">
            </MaintenancePlan>}

            {riskSummaryPopup.showriskSummary && <RiskSummary section={waterway.SectionObj} width={width} height={height} sectionId={waterway.itemTypes[0].sectionId} riskSummaryPopup={riskSummaryPopup} handleRiskSummaryClose={handleRiskSummaryClose}></RiskSummary>}

            <Modal isOpen={alertPopup.showAlertPopup}>
                <ModalHeader toggle={handleAlertPopupClose} >Alert</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label className="label">{alertPopup.message}</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input type="checkbox" checked={alertPopup.isDisregarded} onChange={(e) => setAlertPopup({ ...alertPopup, isDisregarded: !alertPopup.isDisregarded })} name="isDisregarded" id="isDisregarded" />
                            <Label style={{ marginLeft: "10px" }}>Disregard this alert</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="disregardDescription">Why disregard this alert</Label>
                            <Input type="textarea" onChange={(e) => setAlertPopup({ ...alertPopup, disregardDescription: e.target.value })} value={alertPopup.disregardDescription} name="disregardDescription" id="disregardDescription" />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {
                        alertPopup.isDisregarded && <Button color="success" onClick={handleAlertPopupSave}>Ok</Button>
                    }
                </ModalFooter>
            </Modal>

        </div>
    )
}
export default Waterways;