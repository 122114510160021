import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Card, CardBody, CardHeader,Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown} from 'react-icons/fa';
import uuid from 'react-uuid';
import toast, { Toaster } from 'react-hot-toast';

const AllFarmRiparianPlantingSummary = () => {
    const [farmId, setFarmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId, setUserId] = useState(localStorage.getItem("loggedInUserId"));
    const [loading, setLoading] = useState(true);
    const [riparianPlantingWaterway, setRiparianPlantingWaterway] = useState({ riparianPlantedWaterwayList: [], waterwayFencingList:[] });
    const [isOpen, setIsOpen] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])

    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/EnterpriseInsights/GetAllFarmRiparianPlantedWaterwayDetails/', {
                params: {
                        UserId: userId
                }
            }
            );
            setRiparianPlantingWaterway({
                ...riparianPlantingWaterway, riparianPlantedWaterwayList: response.data.riparianPlantedWaterwayList, waterwayFencingList: response.data.waterwayFencingList,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const exportRiparianPlantingSummary = async (e,type) => {
        e.stopPropagation();
        e.preventDefault();
        const response = handleRiparianPlantingSummaryReport(e, type);
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleRiparianPlantingSummaryReport = async (e, type) => {
        const response = await axios.get(
            '/api/Summary/GenerateReportRiparianPlantingSummary/', {
            params: { farmId: farmId,type : type }
        })

        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            if (type == "Pdf") {
                var file = new Blob([buffer], { type: "application/pdf" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "RiparianPlantingSummary.pdf";
            }
            else {
                var file = new Blob([buffer], { type: "application/vnd.ms-excel" })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(file)
                link.download = "RiparianPlantingSummary.xlsx";
            }
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <Card className="my-4">
            <CardHeader className="item-header item_click" onClick={toggle}>
                <Row>
                    <Col sm="11"><h6>Waterway and Riparian Planting Summary
                        {/*<Button style={{ float: "right", background: "none", border: "none" }} onClick={(e) => exportRiparianPlantingSummary(e,'Excel')} value="Export" placeholder="">*/}
                        {/*    <img src="/images/Excel_icon.png" className="card_icon" ></img>*/}
                        {/*</Button>*/}
                        {/*<Button style={{ float: "right", background: "none", border: "none", marginRight: "-20px" }} onClick={(e) => exportRiparianPlantingSummary(e,'Pdf')} value="Export" placeholder="">*/}
                        {/*    <img src="/images/PDF_icon.png" className="card_icon" ></img>*/}
                        {/*</Button>*/}
                    </h6></Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody >
                    <div className="row mb-3">
                        <div className="col-md-12" style={{ background: "#f4fcb9" }}>
                            <span className="sub-header"> Waterway Fencing</span>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <table id="tblWaterwayFencingSummary" className="table borderless table-striped border-top-bottom">
                                <thead className="textAlignCenter">
                                    <tr>
                                        <th rowSpan="2">Type</th>
                                        <th colSpan="1">Length or Perimeter</th>
                                        <th colSpan="2">Fenced</th>
                                        <th colSpan="2">UnFenced</th>
                                    </tr>
                                    <tr>
                                        <th>km</th>
                                        <th>km</th>
                                        <th>%</th>
                                        <th>km</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody className="waterwayFencingSummaryList textAlignCenter" id="waterwayFencingSummaryList">
                                    {riparianPlantingWaterway.waterwayFencingList.map(summary =>

                                        <tr key={uuid()}>
                                            <td>{summary.type}</td>
                                            <td>{summary.totalKm == 0 ? "" : summary.totalKm}</td>
                                            <td>{summary.fencedKm == 0 ? "" : summary.fencedKm}</td>
                                            <td>{summary.fencedPerc == 0 ? "" : summary.fencedPerc}</td>
                                            <td>{summary.unFencedKm == 0 ? "" : summary.unFencedKm}</td>
                                            <td>{summary.unFencedPerc == 0 ? "" : summary.unFencedPerc}</td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </table>
                            
                        </Col>
                    </Row>

                    <br />

                    <div className="row mb-3">
                        <div className="col-md-12" style={{ background: "#f4fcb9" }}>
                            <span className="sub-header"> Riparian Planting</span>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <table id="tblRiparianPlantingSummaryList" className="table borderless table-striped border-top-bottom">
                                <thead className="textAlignCenter">
                                    <tr>
                                        <th rowSpan="2">Size</th>
                                        <th colSpan="2">Length or Perimeter</th>
                                    </tr>
                                    <tr>
                                        <th>km</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody className="riparianPlantingSummaryList textAlignCenter" id="riparianPlantingSummaryList">
                                    {riparianPlantingWaterway.riparianPlantedWaterwayList.map(summary =>

                                        <tr key={uuid()}>
                                            <td>{summary.size}</td>
                                            <td>{summary.lengthKm == 0 ? "" : summary.lengthKm}</td>
                                            <td>{summary.lengthPerc == 0 ? "" : summary.lengthPerc}</td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </CardBody>
            </Collapse>
        </Card>
    )
}
export default AllFarmRiparianPlantingSummary