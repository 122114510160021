import { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, Spinner, Card, CardBody, CardHeader, Table, Collapse } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaExclamationTriangle } from 'react-icons/fa';
import SpinnerContainer from '../Utilities/Ui/SpinnerContainer';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import MapView from '../Map/MapView';
import KMLUpload from '../ItemsView/KMLUpload';
import CheckboxTree from "./CheckboxTree";
import './ShapeUpload.css';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

const ShapeUpload = () => {
    const [saving, setSaving] = useState('');
    const [farmId] = useState(localStorage.getItem("defaultFarmId"));
    const [userId] = useState(localStorage.getItem("loggedInUserId"));
    const [outline, setOutline] = useState({ outlineList: [], itemTypes :[], sectionList :[]});
    const [shapeUpload, setShapeUpload] = useState({ isUpload: false, uploadedShape: [], selectedKey: [] });
    const [loading, setLoading] = useState(true);
    const [mapDetails, setMapDetails] = useState({
        isOutlinePage: false, isLMUPage: false, isCenterReq: true, itemsEditable: false,
        isFarmCenterEditable: false, isFarmOutlineEditable: false, zoomLevel: 5, polytype: '',
        isLmuUpload: false, highlighteditemId: 0, isShapeUpload: true});
    const [itemBulkPopup, setItemBulkPopup] = useState({ showBulkItemPopup: false, uploadedShape: [] });
    const [uploadErrorPopup, setIploadErrorPopup] = useState({ showUploadErrorPopup: false });
    const [isOpen, setIsOpen] = useState(true);
    const [isIconUp, setIsIconUp] = useState(true);
    const [nodes, setNodes] = useState([]);
    const [loadedShape, setLoadedShape] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const navigate = useNavigate();

    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };

    let editableKeyToFocus = useRef(null);

    useEffect(() => {
        setLoading(true);
        OnPageload();
    }, [])


    const OnPageload = async (e) => {
        try {
            const response = await axios.get(
                '/api/shapesUpload/GetOutline/', {
                params: {
                    farmId: farmId
                }
            }
            );

            setOutline({ ...outline, outlineList: response.data.outlineList, itemTypes: response.data.itemTypes, sectionList: response.data.sectionList });
            setMapDetails({ ...mapDetails, zoomLevel: response.data.selectedZoomlevel });
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    /* KML Upload*/
    const onFileChange = async (event) => {
        if (event.target.files[0] != undefined) {
            const formData = new FormData();
            formData.append("fileName", event.target.files[0].name);
            formData.append("File", event.target.files[0]);
            try {
                const response = await axios.post(
                    '/api/shapesUpload/UploadKMLShapeFiles/', formData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                if (response.data == '' || response.data == null) {
                    setIploadErrorPopup({ showUploadErrorPopup: true });
                }
                else {
                    setLoadedShape(response.data);
                    handleUploadSave(response.data);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUploadSave = (uploadedShape) => {
        if (uploadedShape != null) {
            let nodes = [];
            let selectedKeys = [];
            let expandedKeys = [];
            uploadedShape.map((shapes) => {

                let childNodes = [];
                shapes.shapeItems.map((shapeItem) => {
                    let childNode = {
                        value: shapeItem.key,
                        label: shapeItem.name + ' (' + shapeItem.mapType + ')'
                    };
                    childNodes.push(childNode);
                    selectedKeys.push(shapeItem.key);
                });
                let node = {
                    value: shapes.key,
                    label: shapes.name,
                    children: childNodes
                }
                selectedKeys.push(shapes.key);
                expandedKeys.push(shapes.key);
                nodes.push(node);
            });

            setNodes(nodes);
            setSelectedKeys(selectedKeys);
            setExpandedKeys(expandedKeys);

            let modifiedUploadedShape = []

            uploadedShape.map((Shapes) => {
                Shapes.shapeItems.map((items) => {
                    modifiedUploadedShape.push(items)
                })
            })
            setShapeUpload({ ...shapeUpload, isUpload: true, uploadedShape: modifiedUploadedShape, selectedKey: selectedKeys })

        }
    }

    const onSelectedKeysChange = (selectedKeys) => {
        setShapeUpload({ ...shapeUpload, selectedKey: selectedKeys })
        setSelectedKeys(selectedKeys);

    }

    const onExpandKeysChange = (expandedKeys) => {
        setExpandedKeys(expandedKeys);

    }

    const uploadkmlShapeClick = () => {
        document.getElementById('uploadKML').click();
    }

    const HandleBulkItemPopupClose = () => {
        setItemBulkPopup({ farmId: farmId, showBulkItemPopup: false, uploadedShape: [] });
    }

    const HandleShapeUploadPopupClose = () => {
        setIploadErrorPopup({ showUploadErrorPopup: false });
    }

    const handleBulkItemSubmit = async (event) => {
        setSaving(true);
        const item = itemBulkPopup.uploadedShape;

        const form = {
            farmId: farmId,
            userId: userId,
            modifiedData: item
        };

        try {
            const response = await axios.post(
                '/api/shapesUpload/UpdateShapes/', JSON.stringify(form),
                {
                    headers: { 'Content-Type': 'application/json' },
                });
            if (response.data != null) {
                if (response.data.message == 'Added') {
                    HandleBulkItemPopupClose();
                    if (response.data.content != null) {
                        const insertedKey = response.data.content;
                        let new_nodes = [];
                        nodes.map((node) => {
                            let new_node = {
                                value: node.value,
                                label: node.label,
                                children: node.children.filter(child => !insertedKey.includes(child.value))
                            }
                            new_nodes.push(new_node);
                        })
                        setNodes(new_nodes);
                        let new_selectedKeys = selectedKeys.filter(key => !insertedKey.includes(key));
                        setSelectedKeys(new_selectedKeys);
                        let new_expandedKeys = expandedKeys.filter(key => !insertedKey.includes(key));
                        setExpandedKeys(new_expandedKeys);
                        let new_uploadedShape = loadedShape.filter(shape => !insertedKey.includes(shape.key));
                        let modifiedUploadedShape = [];

                        new_uploadedShape.map((Shapes) => {
                            Shapes.shapeItems.map((items) => {
                                modifiedUploadedShape.push(items)
                            })
                        })
                        setShapeUpload({ ...shapeUpload, uploadedShape: modifiedUploadedShape, selectedKey: new_selectedKeys })
                    }
                    toast.success("Items Added!", { duration: 5000, style: { border: '1px solid #B6D137', padding: '16px', color: 'black', fontSize: "18px" }, iconTheme: { primary: '#B6D137', secondary: '#FFFAEE', } });
                    setTimeout(() => {
                        setSaving(false);
                    }, 1000)
                    OnPageload();
                }
            }
        } catch (error) {
            setTimeout(() => {
                setSaving(false);
            }, 1000)
            console.error('Error:', error);
        }
    }

    const handleItemSave = () =>
    {
        let modifiedUploadedShape = []
        loadedShape.map((Shapes) => {
            if (selectedKeys.includes(Shapes.key)) {
                let childNodes = [];
                let Maptype = '';
                Shapes.shapeItems.map((items) => {
                    if (selectedKeys.includes(items.key)) {
                        let area = 0.0;
                        let perimeter = 0.0;
                        const Coordinates = items.coordinates.split(':');
                        const positions = [];

                        Coordinates.filter(coor => coor != "").map((Coordinate) => {
                            const pos = { lat: parseFloat(Coordinate.split(',')[0]), lng: parseFloat(Coordinate.split(',')[1]) }
                            positions.push(pos);
                        })
                        Maptype = items.mapType;
                        if (items.mapType == "polygon") {
                            const tempPolygon = new window.google.maps.Polygon({
                                paths: positions
                            });

                            let path = tempPolygon.getPath();
                            area = (window.google.maps.geometry.spherical.computeArea(path) / 10000).toFixed(1);
                            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);
                        }
                        else if (items.mapType == "polyline") {
                            const tempPolyline = new window.google.maps.Polyline({
                                paths: positions
                            });

                            let path = tempPolyline.paths;
                            area = 0;
                            perimeter = (parseFloat(window.google.maps.geometry.spherical.computeLength(path)) / 1000).toFixed(1);

                        }

                        let childNode = {
                            Key: items.key,
                            Name: items.name,
                            MapType: items.mapType,
                            Area: area,
                            Perimeter: perimeter,
                            Coordinates: items.coordinates
                        };
                        childNodes.push(childNode)
                    }
                })

                let modifiedShape = {
                    Header_Key: Shapes.key,
                    Header_Name: Shapes.name,
                    Header_MapType: Maptype,
                    SectionId: 0,
                    ItemTypeId: 0,
                    Children: childNodes
                };
                if (childNodes.length > 0) {
                    modifiedUploadedShape.push(modifiedShape);
                }
            }
        })
        setItemBulkPopup({ farmId: farmId, showBulkItemPopup: true, uploadedShape: modifiedUploadedShape })
    }

    const handleFieldChange = (e, index) => {
        const name = e.target.name;
        const shapeToUpdate = itemBulkPopup.uploadedShape;
        shapeToUpdate[index][name] = e.target.value;
        setItemBulkPopup({...itemBulkPopup, uploadedShape : shapeToUpdate});
    };

    const getItemTypeList = (sectionId, mapType) => {
        const filteredSoilOrderList = outline.itemTypes.filter(item => item.sectionId == sectionId && item.markerType == mapType);
        return filteredSoilOrderList;
    }

    const mapView = useMemo(() => {
        return (
            <MapView width={'65vw'}  itemModel={outline} mapDetails={mapDetails} shapeUpload={shapeUpload}>
            </MapView>
        )
    }, [outline, mapDetails, shapeUpload])

    return (
        <div>
            <Toaster position="top-center" containerStyle={{ top: '50%' }} />
            <Row>
                <Col>
                    <Button onClick={() => navigate(-1)} color="warning"><FaChevronLeft /> Return to Plan</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col><h2>KML Shapes Import </h2></Col>

            </Row>
            {loading ? <SpinnerContainer /> :
                <Row xs="2">
                    <Col sm="8">
                        {mapView}
                    </Col>
                    <Col sm="4">
                        <KMLUpload uploadkmlShapeClick={uploadkmlShapeClick} onFileChange={onFileChange} />
                        <br/>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader className="item-header" onClick={toggle}>
                                        <Row>
                                            <Col sm="11">
                                                <span className="text-uppercase fw-bold color-dark-teal">Shapes List</span>
                                            </Col>
                                            <Col sm="1" className="toggleoutline">
                                                {isIconUp ? <FaChevronUp /> : <FaChevronDown />}
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen}>
                                        <CardBody>
                                            {nodes.length > 0 && <>
                                                <Button color="success" size="sm" onClick={handleItemSave} className= "float-end">
                                                    Save
                                                </Button>
                                            
                                            <CheckboxTree nodes={nodes} selectedKeys={selectedKeys} expandedKeys={expandedKeys}
                                                onSelectedKeysChange={(selectedKeys) => onSelectedKeysChange(selectedKeys)}
                                                    onExpandKeysChange={(expandedKeys) => onExpandKeysChange(expandedKeys)} />
                                            </>
                                            }
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            }
            <Modal size="lg" isOpen={itemBulkPopup.showBulkItemPopup} backdrop={false} className="ShapeUploadPopup">
                <Form>
                    <ModalHeader toggle={HandleBulkItemPopupClose} >Add/Edit Item</ModalHeader>
                    <ModalBody>


                        <Row className="my-2">
                            <Col sm="12">
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Map Type
                                            </th>
                                            <th>
                                                No. of Items
                                            </th>
                                            <th>
                                                Section
                                            </th>
                                            <th>
                                                Item Type
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemBulkPopup.uploadedShape.map((shapes, idx) =>
                                                <tr key={uuid()}>
                                                    <td>
                                                        <Input
                                                            bsSize="sm"
                                                            type="text"
                                                            name="name"
                                                            value={itemBulkPopup.uploadedShape[idx].Header_Name} readOnly disabled/>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            bsSize="sm"
                                                            type="text"
                                                            name="count"
                                                            value={itemBulkPopup.uploadedShape[idx].Header_MapType}
                                                            readOnly disabled />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            bsSize="sm"
                                                            type="text"
                                                            name="count"
                                                            value={itemBulkPopup.uploadedShape[idx].Children.length}
                                                            readOnly disabled />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            bsSize="sm"
                                                            id="SectionId"
                                                            name="SectionId"
                                                            type="select"
                                                            value={itemBulkPopup.uploadedShape[idx].SectionId}
                                                            onChange={(e) => {
                                                                editableKeyToFocus.current = idx + 'SectionId';
                                                                handleFieldChange(e, idx)
                                                            }}
                                                            autoFocus={idx + 'SectionId' === editableKeyToFocus.current}
                                                        >
                                                            <option value={0} >Select Section</option>
                                                            {outline.sectionList?.map(c =>
                                                                <option key={c.id} value={c.id}>{c.name}</option>
                                                            )}

                                                        </Input>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            bsSize="sm"
                                                            id="ItemTypeId"
                                                            name="ItemTypeId"
                                                            type="select"
                                                            value={itemBulkPopup.uploadedShape[idx].ItemTypeId}
                                                            onChange={(e) => {
                                                                editableKeyToFocus.current = idx + 'ItemTypeId';
                                                                handleFieldChange(e, idx)
                                                            }}
                                                            autoFocus={idx + 'ItemTypeId' === editableKeyToFocus.current}
                                                        >
                                                            <option value={0} >Select Item Type</option>
                                                            {itemBulkPopup.uploadedShape[idx].SectionId > 0 &&
                                                                getItemTypeList(itemBulkPopup.uploadedShape[idx].SectionId, itemBulkPopup.uploadedShape[idx].Header_MapType)?.map(c =>
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )}
                                                        </Input>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {saving ?
                            <Button
                                color="success"
                                disabled
                            >
                                <Spinner size="sm">
                                    Saving...
                                </Spinner>
                                <span>
                                    {' '}Saving
                                </span>
                            </Button>
                            :
                            <Button color="success" size="sm" onClick={handleBulkItemSubmit}>
                                Save
                            </Button>
                        }
                        {saving ?
                            <Button color="primary" disabled onClick={HandleBulkItemPopupClose}>Cancel</Button> :
                            <Button color="primary" onClick={HandleBulkItemPopupClose}>Cancel</Button>
                        }
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal size="lg" isOpen={uploadErrorPopup.showUploadErrorPopup} backdrop={false} className="ShapeUploadErrorPopup">
                <Form>
                    <ModalHeader toggle={HandleShapeUploadPopupClose} > <FaExclamationTriangle className="errorColor" /> Upload Error</ModalHeader>
                    <ModalBody>
                        <Row className="my-2">
                            <Col sm="12">
                                <label className="uploadErrorStyle">Sorry this KML file is not compatible with the import process. Please ensure the file has no multiple geometry sections or colours within the file.
                                    It can sometimes be easiest to review the file in Google Earth and remove elements not needed, then save a fresh copy.
                                    If you are unable to resolve the issue please get in touch with <a href="mailto:itsupport@qconz.co.nz"> itsupport@qconz.co.nz</a> for assistance.</label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={HandleShapeUploadPopupClose}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
export default ShapeUpload;