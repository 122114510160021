import { useState, useEffect } from 'react';
import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup, Spinner, Collapse, Table } from 'reactstrap';
import { FaChevronUp, FaChevronDown, FaFilePdf } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProgrammeSummary = ({ programmeSummary, userId }) => {
    const [isOpen, setIsOpen] = useState(true); const [isIconUp, setIsIconUp] = useState(true);
    const toggle = () => {
        setIsOpen(!isOpen)
        setIsIconUp(!isIconUp)
    };
    var farmdata = [programmeSummary.totalFEPNotInPlace == 0 ? "" : programmeSummary.totalFEPNotInPlace,
        programmeSummary.totalInProgressFEP == 0 ? "" : programmeSummary.totalInProgressFEP,
        programmeSummary.totalFEPReviewedRecently == 0 ? "" : programmeSummary.totalFEPReviewedRecently,
        programmeSummary.totalFEPReviewedMoreThanYearAgo == 0 ? "" : programmeSummary.totalFEPReviewedMoreThanYearAgo];
    const pieOptions = {
        legend: {
            display: false,
            position: "right",
            legendCallback: function (chart) {
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    };
    const chartData = {
        labels: ['No ZespriFWFP in place', 'In Progress ZespriFWFP', 'ZespriFWFP Reviewed Recently (last 12 months)','ZespriFWFP Last Reviewed more than a year ago'],
        datasets: [{
            label: 'Farm Summary',
            data: farmdata,
            backgroundColor: [
                'rgb(233, 81, 14, 0.8)',
                'rgb(0, 127, 73, 0.8)',
                'rgb(247, 164, 0, 0.8)',
                'rgb(136, 218, 219, 0.8)'
            ],
            hoverOffset: 4
        }]
    };
    const exportProgrammeSummaryToPdf = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (document.readyState === 'complete') {
            var canvasDataURL = document.getElementById('chartProgrammeSummary').toDataURL("image/PNG", 1.0);
            var imageBaseSixtyFour = canvasDataURL.split(',')[1];
        }
        const response = handleProgrammeSummaryToPdf(imageBaseSixtyFour);
        toast.promise(response, {
            loading: 'Generating report...',
            success: 'Report generated!',
            error: 'Error when generating report',
        });
    }

    const handleProgrammeSummaryToPdf = async (imageBaseSixtyFour) => {
        let form = {
            userId: userId, imageUrl: imageBaseSixtyFour
        };
        const response = await axios.post(
            '/api/allFarmsSummary/ExportProgrammeSummaryPdf/', JSON.stringify(form),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        if (response.data.status) {
            var buffer = base64ToArrayBuffer(response.data.content)
            var file = new Blob([buffer], { type: "application/pdf" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = "ProgrammeSummary.pdf";
            document.body.appendChild(link)
            link.click()
        }

    }

    const base64ToArrayBuffer = (data) => {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    return (
        <div>
            <Row style={{ marginTop: `15px` }}>
                <Col>
                    <Card>
                        <CardHeader className="item-header item_click" onClick={toggle}>
                            <Row>
                                <Col sm="11"><h6>Programme Summary
                                    <Button style={{ float: "right", background: "none", border: "none", marginRight: "-10px" }} onClick={(e) => exportProgrammeSummaryToPdf(e)} value="Export" placeholder="">
                                        <img src="/images/PDF_icon.png" className="card_icon" ></img>
                                    </Button>
                                </h6></Col>
                                <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <span className="sub-header"> Overall Farm Environment Plan progress</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            Total Suppliers &nbsp; <span className="c-number number-highlight">{programmeSummary.totalSuppliers}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="area-checklist-border">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                                        <table style={{ width: `100%` }} className="fep-table-1">
                                                            <thead>
                                                                <tr>
                                                                    <td>ZespriFWFP Reviewed Recently (last 12 months)</td>
                                                                    <td className="text-right">{programmeSummary.totalFEPReviewedRecently == 0 ? "" : programmeSummary.totalFEPReviewedRecently}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>ZespriFWFP Last Reviewed more than a year ago</td>
                                                                    <td className="text-right">{programmeSummary.totalFEPReviewedMoreThanYearAgo == 0 ? "" : programmeSummary.totalFEPReviewedMoreThanYearAgo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>In Progress ZespriFWFP</td>
                                                                    <td>{programmeSummary.totalInProgressFEP == 0 ? "" : programmeSummary.totalInProgressFEP}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>No ZespriFWFP in place</td>
                                                                    <td className="text-right">{programmeSummary.totalFEPNotInPlace == 0 ? "" : programmeSummary.totalFEPNotInPlace}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                                        <Pie data={chartData} id="chartProgrammeSummary" options={pieOptions } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="area-checklist-border">
                                                <div className="row mb-3">
                                                    <span className="sub-header">Action Summary</span>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <table style={{ width: `100%` }} className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2"></th>
                                                                    <th colSpan="2" style={{ textAlign: `center` }}>Improvement Actions</th>
                                                                    <th rowSpan="2">Ongoing Actions</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Open</th>
                                                                    <th>Closed</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td>{programmeSummary.total_Open_Improvement_Actions == 0 ? "" : programmeSummary.total_Open_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.total_Closed_Improvement_Actions == 0 ? "" : programmeSummary.total_Closed_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.total_Ongoing_Actions == 0 ? "" : programmeSummary.total_Ongoing_Actions}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Per Farm Avg</td>
                                                                    <td>{programmeSummary.avg_Open_Improvement_Actions == 0 ? "" : programmeSummary.avg_Open_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.avg_Closed_Improvement_Actions == 0 ? "" : programmeSummary.avg_Closed_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.avg_Ongoing_Actions == 0 ? "" : programmeSummary.avg_Ongoing_Actions}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Overdue</td>
                                                                    <td>{programmeSummary.overdue_Open_Improvement_Actions == 0 ? "" : programmeSummary.overdue_Open_Improvement_Actions}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Last 12 months activity</td>
                                                                    <td>{programmeSummary.last12Months_Open_Improvement_Actions == 0 ? "" : programmeSummary.last12Months_Open_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.last12Months_Closed_Improvement_Actions == 0 ? "" : programmeSummary.last12Months_Closed_Improvement_Actions}</td>
                                                                    <td>{programmeSummary.last12Months_Ongoing_Actions == 0 ? "" : programmeSummary.last12Months_Ongoing_Actions}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default ProgrammeSummary;