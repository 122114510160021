import React, { useState } from 'react';
import { Row, Col, Label, Input, FormGroup, Button, Card, CardBody, Badge, Table, CardHeader, Collapse } from 'reactstrap';
import uuid from 'react-uuid';
import { FaPlus, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Moment from 'moment';

const WholeFarmActionView = ({ handleOpenWholeActionPopup, outlineCenter, open, showArrow }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [isIconUp, setIsIconUp] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsIconUp(!isIconUp);
    };
    const openActionCount = outlineCenter.actions.filter(action => action.isCloseout != true).length;
    const closedActionCount = outlineCenter.actions.filter(action => action.isCloseout == true).length;
    const [isClosedActionShow, setIsClosedActionShow] = useState(false);
    const handleClosedActionShow = (event) => {
        setIsClosedActionShow(event.target.checked);
    }
    const roleName = localStorage.getItem("roleName");
    const isCreateNewAction = localStorage.getItem("isCreateNewAction");
    return (
        <Card className="card-header-outline">
            {showArrow && <CardHeader className="card-header-click" onClick={toggle}>
                    <Row>
                        <Col sm="11">
                        <h5>Actions
                            {openActionCount > 0 ?
                                <span className="risk-badge">
                                | <Badge color="primary">
                                        {openActionCount}
                                    </Badge> <span className="card-header-label-xs">Open Action(s)</span>
                                </span> :
                                <></>}
                        </h5>
                        </Col>
                    <Col sm="1" className="toggleoutline">{isIconUp ? <FaChevronUp /> : <FaChevronDown />}</Col>
                    </Row>
                </CardHeader>}
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <Row>
                            <Col sm="8">
                            </Col>
                        <Col sm="4">
                            {(roleName == 'Zespri Admin' || isCreateNewAction == 'true') &&
                                <>
                                <Button className="btn-items-actions" color="success" size="sm" onClick={(itemId, actionId) => handleOpenWholeActionPopup(outlineCenter.itemId, 0)} >
                                    Add Action
                                </Button>
                                </>
                            }
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                {
                                    closedActionCount > 0 ?
                                        <FormGroup check inline className="float-end">
                                            <Input
                                                type="checkbox"
                                                name="isClosedActionShow"
                                                id="isClosedActionShow"
                                                checked={isClosedActionShow}
                                                onChange={handleClosedActionShow}
                                            />
                                            <Label htmlFor="isClosedActionShow" check> Show Closed Actions <Badge>{closedActionCount}</Badge></Label>
                                        </FormGroup> : ''
                                }

                                {
                                    outlineCenter.actions.length != 0 &&
                                    <>
                                        <h5 className="full-width-title">Actions</h5>
                                        <table className="table borderless">
                                            <thead></thead>
                                            <tbody>
                                                <tr className="actions-table" key={uuid()}>
                                                    <td >
                                                        <Table className='table borderless '>
                                                            <thead >
                                                                <tr>
                                                                    <th> # </th>
                                                                    <th style={{ textAlign: 'center' }}> Category </th>
                                                                    <th style={{ width: '10%' }}> Due </th>
                                                                    <th style={{ width: '20%' }}> Risk </th>
                                                                    <th style={{ width: '20%' }}> Title  </th>
                                                                    <th style={{ width: '30%' }}> Details </th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    outlineCenter.actions.map(action =>

                                                                        <tr key={action.id} className={"" + (action.isCloseout === true ? "closedAction" : "openAction") + " " + (!isClosedActionShow && action.isCloseout === true ? "closed-hidden" : "")}>
                                                                            <td> {action.id} </td>
                                                                            <th scope="row" style={{ textAlign: 'center' }}>{action.category == null ? "" : <Badge color="danger">{action.category}</Badge>}  </th>
                                                                            <td> {action.actionType == 46 ? "Ongoing / Management" : Moment(action.dueDate).format('DD/MM/YYYY')} </td>
                                                                            <td> {action.riskName}</td>
                                                                            <td> {action.title} </td>
                                                                            <td> {action.description} </td>
                                                                            <td> {action.isCloseout !== true ?
                                                                                <Button color="warning" size="sm" onClick={(itemId, actionId) => handleOpenWholeActionPopup(outlineCenter.itemId, action.id)} > Edit </Button> :
                                                                                <Button color="primary" size="sm" onClick={(itemId, actionId) => handleOpenWholeActionPopup(outlineCenter.itemId, action.id)} > View </Button>}
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
    )
}
export default WholeFarmActionView